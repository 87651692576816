<template>
    <div>
        <div class="row">
            <div class="col-lg-12">
                <span class="text-danger">
                    Kindly fill the form below to create a new account, after successful submission
                    you will wait for approval by the MDCN before proceeding to complete registration...
                </span>
                <hr>
            </div>
        </div>

        <div class="row" v-if="isLoadingBlade && ! showErrorStatus">
            <div class="col-lg-12">
                <div class="d-flex justify-content-center">
                    <img :src="loadingBladeUrl" />
                </div>
            </div>
        </div>

        <div class="row" v-if="! isLoadingBlade">

            <div class="col-lg-4">
                <base-input alternative=""
                    label="First Name"
                    placeholder="Enter First Name"
                    input-classes="form-control-alternative"
                    v-model="record.first_name"
                    required
                />
            </div>

            <div class="col-lg-4">
                <base-input alternative=""
                    label="Last Name (Surname)"
                    placeholder="Enter Last Name (Surname)"
                    input-classes="form-control-alternative"
                    v-model="record.last_name"
                    required
                />
            </div>

            <div class="col-lg-4">
                <base-input alternative=""
                    label="Other Names"
                    placeholder="Enter Other Names"
                    input-classes="form-control-alternative"
                    v-model="record.other_name"
                />
            </div>

            <div class="col-lg-3">
                <base-input alternative=""
                    label="Email Address"
                    placeholder="Enter  Valid Email Address"
                    input-classes="form-control-alternative"
                    v-model="record.email"
                    required
                />
            </div>

            <div class="col-lg-3">
                <base-input alternative=""
                    label="Phone Number"
                    placeholder="Phone Number"
                    input-classes="form-control-alternative"
                >
                <!-- v-model="profile.phone"  -->
                    <VuePhoneNumberInput
                        v-model="tel"
                        @update="updatePhoneNumber"
                        default-country-code="NG"
                        :no-example="true"
                        :clearable="true"
                    />
                </base-input>
            </div>

            <div class="col-lg-3">
                <base-input alternative=""
                    label="Practice Type"
                    required
                >
                    <select
                        class="form-control form-control-alternative"
                        v-model="record.doctor_type_id"
                    >
                        <option :value="null" disabled selected>--- Select One ---</option>
                        <option value="1">Medical Registration</option>
                        <option value="2">Dental Registration</option>
                        <!-- <option value="3">Alternative Registration</option> -->
                    </select>
                </base-input>
            </div>

            <div class="col-lg-3">
                <base-input alternative=""
                    label="Registration Duration Type"
                    required
                >
                    <select
                        class="form-control form-control-alternative"
                        v-model="record.sub_registration_type_id"
                    >
                        <option :value="null" disabled selected>--- Select Registration Duration Type ---</option>
                        <option value="7">Temporary Registration (Short Stay - less than 3 months)</option>
                <option value="8">Temporary Registration (Long Stay - above 3 months to 2 years)</option>
                    </select>
                </base-input>
            </div>

            <div class="col-lg-12 text-center" v-if='showErrorStatus'>
                <base-alert :type="errorType">
                    <!-- <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span> -->
                    <span class="alert-inner--text">
                        <h2 class="text-white">{{errorMsg}}</h2>
                    </span>
                </base-alert>
            </div>

            <div class="col-lg-12 text-center" v-if="! isNotRobot">
                <vue-recaptcha
                    :sitekey="recaptchaKey"
                    :loadRecaptchaScript="true"
                    @verify="proceedSubmit"
                ></vue-recaptcha>
            </div>

        </div>

        <div class="col-lg-12" v-if="! isLoadingBlade">
            <hr>
            <base-button
                type="danger"
                @click="hidePolicyPage(false)"
            >
                <i class="fa fa-arrow-left"></i>
                Go Back
            </base-button>

            <base-button
                type="success"
                class="right"
                @click="createAccount()"
                :disabled="! isNotRobot"
            >
                {{ isloading ? 'Please Wait...' : 'Proceed Now'}}
                <i class="fa fa-arrow-right"></i>
            </base-button>
        </div>
        <br><br><br>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import VueRecaptcha from 'vue-recaptcha';
    import VuePhoneNumberInput from 'vue-phone-number-input';
    import 'vue-phone-number-input/dist/vue-phone-number-input.css';
    import swal from 'sweetalert2';// import sweet alert

    export default {
        name: 'TempRenewPage',
        props: ['hidePolicyPage'],
        components: {
            VueRecaptcha,
            VuePhoneNumberInput
        },
        data() {
            return {
                isLoadingBlade: false,
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                record: {
                    first_name: null,
                    last_name: null,
                    other_name: null,
                    email: null,
                    doctor_type_id: null,
                    sub_registration_type_id: null,
                    phone: null,
                    phone_code: null
                },
                tel: null,
                isNotRobot: true,
                isloading: false
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl', 'recaptchaKey']),
        },
        methods: {
            ...mapActions(['getRemitaPaymentStatus', 'createTempDocSignup']),
            updatePhoneNumber(event) {
                if(event && event.isValid && event.e164) {
                    this.record.phone = event.formattedNumber;
                    this.record.phone_code = event.countryCode+'-'+event.phoneNumber;
                }
            },
            createAccount() {
                let status = this.validatedInputs();
                if(status && this.isNotRobot) {
                    this.preloader(); // show loader
                    this.isLoading = true;
                    this.record.is_temp_renewal = 'yes';
                    this.createTempDocSignup(this.record).then((res) => {
                        this.isLoadingBlade = false;
                        if(res.status) {// store token and login user
                            swal.fire("Successful!", res.msg, "success");
                            setTimeout(function() { window.location.href = '/doctor-login'; }, 3000);
                        } else {//show error
                            swal.fire("Error Occurred!", res.msg, "warning");
                        }
                    }).
                    catch(() => {
                        let msg = "Network Failure!, kindly reload the page...";
                        swal.fire("Error Ocurred!", msg, "warning");
                    });
                }
            },
            validatedInputs() {
                let data = this.record;
                let record = { first_name: null, last_name: null, email: null, doctor_type_id: null,
                    sub_registration_type_id: null };
                let testEmail = false;
                record.sub_registration_type_id  = data.sub_registration_type_id == null ?
                    this.showInuptError('Registration Duration Type') : data.sub_registration_type_id;
                record.doctor_type_id  = data.doctor_type_id == null ?
                    this.showInuptError('Practice Type') : data.doctor_type_id;
                testEmail = this.validEmail(data.email) ? true : this.showInuptError('Email Address');
                record.email  = data.email == null ?
                    this.showInuptError('Email Address') : data.email;
                record.last_name  = data.last_name == null ?
                    this.showInuptError('Last Name') : data.last_name;
                record.first_name  = data.first_name == null ?
                    this.showInuptError('First Name') : data.first_name;
                return this.isEmpty(record) && testEmail ? true : false;
            },
            isEmpty(obj) {// check for empty field
                return ! Object.keys(obj).some(k => !obj[k]);
            },
            validEmail(email) {// eslint-disable-next-line
                var regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return regEmail.test(email);
            },
            showInuptError(name) {
                this.isloading = false;
                this.showErrorStatus = true
                this.errorType = 'warning'
                this.errorMsg = 'Invalid ' + name + ' has been entered';
                var self = this;
                setTimeout(function() { self.showErrorStatus = false; }, 5000);
            },
            proceedSubmit(res) {
                if(res) {
                    return setTimeout(() => {
                        this.isNotRobot = ! this.isNotRobot;
                    }, 1000);
                } else {
                    swal.fire("Error Occurred", "An error occurred while verifying, kindly try again", "warning");
                }
            },
            preloader() {
                return  swal.fire({
                    title: 'Creating account...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        }
    }
</script>

<style scoped>
    .no-padding-bottom {
        padding-bottom: 0rem !important
    }
    .right {
        float: right;
    }
</style>

<style>
    .form-control-label {
        width: 100%;
    }
    .height-50 {
        height: 50px;
    }
    .borderLine {
        border: 1px solid #ededed;
    }
</style>