<template>
    <div>
        <div v-if="! isTempReg && validLicenseYear && ! isLoadingBlade">
            <div class="row" v-if="application && application.application_type">
                <div class="col-sm-12 text-center">
                    <br>
                    <h3 class="text-danger">
                        Note: If you want to make payment for previous year licenses before the year
                        <em class="text-primary">{{ application.license.license_year }}</em>, kindly click on the
                        <em class="text-primary">Previous Licenses Purchase</em> button above.
                    </h3>

                    <hr class="my-4"/>

                    <h3 class="text-danger"
                        v-if="application.license.surcharge"
                    >
                        Note: If you make payment on or before <em class="text-primary"> 31st Decemeber,
                        {{ application.license.license_year - 1 }}</em>,
                        and you have the RRR number, kindly proceed to add the RRR payment. As the surge amount will
                        not be applicable to you.
                    </h3>

                    <hr class="my-4" v-if="application.license.surcharge" />
                    <br>
                    <h3>
                        {{ application.application_type.name }}
                        <br><br>
                        <span>License Year: </span> &nbsp;
                        <em class="font-weight-light">
                            {{ application.license.license_year }}
                        </em>
                        <br><br>
                        <span>Amount: </span> &nbsp;
                        <em class="font-weight-light">
                            {{ application.application_type.has_payment.amount | formatPrice }}
                        </em>

                        <span v-if="application.license.surcharge">
                            <br><br>
                            <span class="text-danger">Surcharge: </span> &nbsp;
                            <em class="font-weight-light text-danger">
                                {{ application.license.surcharge_amount | formatPrice }}
                            </em><br>
                            <em class="font-weight-light text-danger">
                                (It is only applicable to late payment)
                            </em>
                        </span>

                        <br><br>
                        <span>Total Amount: </span> &nbsp;
                        <em class="font-weight-light">
                            {{ application.application_type.has_payment.amount | formatPrice }}
                        </em>
                    </h3>
                </div>

                <div class="col-sm-12 text-center">
                    <hr>
                    <router-link
                        :to="'/new-application/'+application.application_type.code"
                        class="btn btn-lg btn-success"
                    >
                        <i class="fa fa-share-square"></i> Proceed Now
                    </router-link>
                </div>
            </div>

            <div class="col-sm-12 text-center">
                <base-alert :type="errorType" v-if='showErrorStatus'>
                    <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                    <span class="alert-inner--text">
                        {{errorMsg}}
                    </span>
                </base-alert>
            </div>
        </div>

        <div class="col-lg-12" v-if="isLoadingBlade">
            <div class="d-flex justify-content-center">
                <img :src="loadingBladeUrl" />
            </div>
        </div>

        <div class="col-sm-12 text-center" v-if="! validLicenseYear && ! isLoadingBlade">
            <h2 class="text-danger">
                Your License is up-to-date, kindly wait for the 
                <em class="text-primary">1st of January of {{ maximunYear }}</em>, <br>
                to purchase License for 
                <em class="text-primary">{{ maximunYear + 1 }}</em>
                
            </h2>
            <br><br>
            <router-link
                to="/"
                class="btn btn-lg btn-primary"
            >
                <i class="fa fa-home"></i>
                Dashboard
            </router-link>
        </div>

        <div class="col-sm-12 text-center" v-if="isTempReg && validLicenseYear && ! isLoadingBlade">
            <h2 class="text-danger">
                Kindly click on the button below to Renew Your License
            </h2>
            <br><br>
            <router-link
                :to="getTempRegUrl"
                class="btn btn-lg btn-primary"
            >
                <i class="fa fa-history"></i>
                Renew License
            </router-link>
        </div>
    </div>
</template>
<script>
    import { mapGetters, mapActions } from 'vuex';
    import moment from 'moment';

    export default {
        name: 'PurchaseLicense',
        props: ['isTempReg', 'getTempRegUrl'],
        filters: {
            formatPrice(value) {
                if(value) {
                    let val = (value/1).toFixed(2).replace(',', '.');
                    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' NGN';
                } else {
                    return 'N/A';
                }
            }
        },
        data() {
            return {
                application: null,
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isLoadingBlade: true
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl']),
            licenseYear() {
                return this.application && this.application.license ? 
                    this.application.license.license_year : false;
            },
            maximunYear() {// get maixmum year for license purchase
                return (moment().year() + 1);
            },
            validLicenseYear() {
                return this.licenseYear ? parseInt(this.licenseYear) <= this.maximunYear : false
            }
        },
        methods: {
            ...mapActions(['getAnnualLicenseDetails']),
            loadAnnualLicenseDetails() {
                this.getAnnualLicenseDetails().then((res) => {// get records
                    this.application = res;
                    this.isLoadingBlade = false;
                }).
                catch(() => {
                    this.isLoadingBlade = false;
                    this.showErrorStatus = true;
                    this.errorMsg = "Network Failure!, kindly reload the page...";
                    this.errorType = 'danger';
                });
            }
        },
        created() {
            this.loadAnnualLicenseDetails();// annual license details
        },
    }
</script>