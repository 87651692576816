<template>
    <div class="col-xl-12 order-xl-1">
        <card shadow type="secondary">

            <div class="pl-lg-4">
                <h6 class="heading-small text-danger mb-4">
                    Confirm if your transaction actually went through before attempting to make another payment, 
                    you can click on <em class="text-primary">Re-Query button</em> below
                    <br><br>
                    <span v-if="! hasVaildIndemnity">
                        You can only proceed if payment was successful/ You have Full Registration Certificate...
                    </span>
                </h6>

                <hr class="my-4" />

                <!-- import edu records details template here -->
                <IndemnityData
                    v-bind:insuranceRecords="insuranceRecords"
                    v-bind:showAddRecord="showAddRecord"
                    v-bind:isLoadingBlade="isLoadingBlade"
                    v-bind:pagination="pagination"
                    v-bind:hasPagination="hasPagination"
                    v-bind:isloading="isloading"
                    v-bind:selectedRowNo="selectedRowNo"
                    v-bind:paginate="paginate"
                    v-on:reQueryTransaction="reQueryTransaction"
                />

                <hr class="my-4" v-if="! hasVaildIndemnity" />

                <div class="col-sm-12 text-center">
                    <base-alert :type="errorType" v-if='showErrorStatus'>
                        <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                        <span class="alert-inner--text">
                            {{errorMsg}}
                        </span>
                    </base-alert>
                </div>

                <div class="text-center"
                    v-if="! hasVaildIndemnity"
                >
                    <base-button
                        :type="showNewPayment ? 'danger' : 'primary'"
                        @click="showNewPaymentBlade()"
                    >
                        <i class="fa fa-arrow-down" v-if="! showNewPayment"></i>
                        <i class="fa fa-times" v-if="showNewPayment"></i>
                        {{ showNewPayment ? 'Close New Insurance Payment' : 'Click Here for New Insurance Payment' }}
                    </base-button>
                </div>

                <hr class="my-4" v-if="! hasVaildIndemnity && showNewPayment" />

                <div
                    class="row align-items-center"
                    v-if="! hasVaildIndemnity && showNewPayment"
                >
                    <div class="col-lg-12">
                        <base-alert :type="errorType" dismissible v-if='showInputErrorStatus'>
                            <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                            <span class="alert-inner--text">
                                {{errorMsg}}
                            </span>
                            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </base-alert>
                    </div>

                    <div class="col-lg-6">
                        <base-input alternative=""
                            label="Beneficiary of the Life Insurance Benefit (Next of Kin)"
                            placeholder="Enter Beneficiary of the Life Insurance Benefit (Next of Kin)"
                            input-classes="form-control-alternative"
                            v-model="records.beneficiary_name"
                            required
                        />
                    </div>
                    <div class="col-lg-6">
                        <!-- <base-input alternative=""
                            label="Contact Telephone of Beneficiary (Next of Kin)"
                            placeholder="Enter Contact Telephone of Beneficiary (Next of Kin)"
                            input-classes="form-control-alternative"
                            v-model="records.beneficiary_phone"
                            required
                        /> -->
                        <base-input alternative=""
                            label="Contact Telephone of Beneficiary (Next of Kin)"
                            input-classes="form-control-alternative"
                            required
                        >
                        <!-- v-model="profile.phone"  -->
                            <VuePhoneNumberInput
                                v-model="tel"
                                @update="updatePhoneNumber"
                                default-country-code="NG"
                                :no-example="false"
                                :clearable="true"
                            />
                        </base-input>
                    </div>
                    <div class="col-lg-6">
                        <base-input alternative=""
                            label="Relationship (Next of Kin)"
                            placeholder="Enter Relationship (Next of Kin)"
                            input-classes="form-control-alternative"
                            v-model="records.beneficiary_relationship"
                            required
                        />
                    </div>

                    <div class="col-lg-12"></div>
                    <div class="pl-lg-4">
                        <base-button
                            type="success"
                            @click="purchaseInsurance()"
                        >
                            <i class="fa fa-save"></i>
                            {{isloading ? 'Please Wait...' : 'Purchase Now'}}
                        </base-button>
                    </div>
                </div>

                <hr class="my-4" v-if="showNewPayment" />


                <hr class="my-4"/>

                <div class="row text-center">
                    <div class="col-lg-7">
                        <h2>
                            Do you have a Full Registration Certificate, so you can skip indemnity?
                        </h2>
                    </div>
                    <div class="col-lg-2">
                        <base-switch
                            v-if="! skipIndemnity"
                            :value="false"
                            @click="checkToSkipIndemnity(true)"
                        ></base-switch>
                        <base-switch
                            v-if="skipIndemnity"
                            :value="true"
                            @click="checkToSkipIndemnity(false)"
                        ></base-switch>
                    </div>
                </div>

                <hr class="my-4"/>

                <div class="pl-lg-4">
                    <base-button
                        type="danger"
                        @click="showPreviousPage()"
                    >
                        <i class="fa fa-arrow-left"></i>
                        Previous
                    </base-button>
                    <div class="right">
                        <base-button
                            type="success"
                            @click="showNextPage()"
                            v-if="hasVaildIndemnity"
                        >
                            Proceed
                            <i class="fa fa-arrow-right"></i>
                        </base-button>

                        <base-button
                            type="success"
                            @click="showNextPage()"
                            v-if="! hasVaildIndemnity && isOldDoctor && ! skipIndemnity"
                        >
                            Skip &amp; Proceed
                            <i class="fa fa-arrow-right"></i>
                        </base-button>

                        <base-button
                            type="success"
                            @click="showNextPage()"
                            v-if="skipIndemnity"
                        >
                            Skip &amp; Proceed
                            <i class="fa fa-arrow-right"></i>
                        </base-button>
                    </div>
                </div>

            </div>

        </card>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import IndemnityData from './IndemnityData.vue';
    import moment from 'moment';
    import VuePhoneNumberInput from 'vue-phone-number-input';
    import 'vue-phone-number-input/dist/vue-phone-number-input.css';
    import swal from 'sweetalert2';// import sweet alert

    export default {
        components: {
            IndemnityData,
            VuePhoneNumberInput
        },
        name: 'Insurance',
        props: ['displayInternship', 'displayAddQual'],
        data() {
            return {
                insuranceRecords: [],
                paginatedItems: [],
                records: {
                    beneficiary_name: null,
                    beneficiary_phone: null,
                    beneficiary_relationship: null
                },
                tel: null,
                pagination: {
                    total: null,
                    from: null,
                    number: 1,
                    perPage: this.$store.state.user.perPage // get per page count
                },
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isloading: false,
                selectedRowNo: null,
                showAddRecord: false,
                showInputErrorStatus: false,
                isLoadingBlade: true,
                validInsurance: false,
                showNewPayment: false,
                skipIndemnity: false
            };
        },
        computed: {
            ...mapGetters(['user']),
            hasPagination() {
                return this.paginatedItems && (this.paginatedItems.length > this.pagination.perPage)
                    ? true : false;
            },
            isOldDoctor() {// check if user is old doctor
                let year = this.user && this.user.basic_profile ? this.user.basic_profile.year_of_graduation : false;
                return (moment().diff(year, 'years') > 9);
            },
            hasVaildIndemnity() {// get valid indemnity
                return this.validInsurance;
            }
        },
        methods: {
            ...mapActions(['getUserInsuranceRecords', 'getBasicProfile', 'reQueryInsurance',
                'addUserInsurance', 'deleteUserEduRecord', 'getUserValidInsurance']),
            updatePhoneNumber(event) {
                if(event && event.isValid && event.e164) {
                    this.records.beneficiary_phone = event.formattedNumber;
                }
            },
            checkToSkipIndemnity(value) {// check to skip indemnity for license applicants only
                return this.skipIndemnity = value;
            },
            showPreviousPage() {// go to next page
                return this.displayInternship(true);
            },
            showNextPage() {// go to next page
                return this.displayAddQual(true);
            },
            showNewPaymentBlade() {// show new payemnt template
                this.showNewPayment = ! this.showNewPayment;
            },
            getValidInsurance() {
                let id = this.$store.state.user.basicProfile.id;// get user id
                this.getUserValidInsurance({'id' : id}).then((res) => {// get insurance records
                    return this.validInsurance = res.status;
                }).
                catch(() => {
                    return this.validInsurance = false;
                });
            },
            emptyInsuranceRecordsMsg() {
                return this.insuranceRecords && this.insuranceRecords.length == 0 && (!this.showAddRecord)
                    ? this.showEmptyMsg() : false;
            },
            showEmptyMsg() {// show empty msg
                this.showErrorStatus = true;
                this.errorType = 'warning';
                this.errorMsg = 'No Insurance Records Yet!, kindly purchase one';
            },
            loadInsuranceRecords() {
                this.getBasicProfile().then((res) => {// get user Id
                    const userId = res.id;
                    this.getUserInsuranceRecords({'id' : userId}).then((res) => {// get insurance records
                        this.setPages(res);
                        this.isLoadingBlade = false;
                        this.emptyInsuranceRecordsMsg();//show empty msg
                    }).
                    catch(() => {
                        this.showErrorStatus = true;
                        this.isLoadingBlade = false;
                        this.errorMsg = "Network Failure!", "Kindly reload the page...";
                        this.errorType = 'danger';
                    });
                }).
                catch(() => {
                    this.showErrorStatus = true;
                    this.isLoadingBlade = false;
                    this.errorMsg = "Network Failure!", "Kindly reload the page...";
                    this.errorType = 'danger';
                });
            },
            setPages(res) {// set paginated function
                this.insuranceRecords = [];
                this.paginatedItems = res;
                this.pagination.total = this.paginatedItems.length; // get items total
                let perPage = this.pagination.perPage; // get per page count
                if(this.paginatedItems.length > perPage) {// paginate
                    let numberOfPages = Math.ceil(this.paginatedItems.length / perPage);
                    for (let index = 1; index <= numberOfPages; index++) {
                        this.insuranceRecords.push(this.paginatedItems[index]);
                    }
                } else {
                    this.insuranceRecords = this.paginatedItems;
                }
            },
            paginate() {// paginate function
                this.insuranceRecords = [];
                this.pagination.from = null;
                let page = this.pagination.number;
                let perPage = this.pagination.perPage;
                let from = (page * perPage) - perPage;
                let to = (page * perPage);
                this.pagination.from = from;//get first item number
                return this.insuranceRecords = this.paginatedItems.slice(from, to);
            },
            emptyInsuranceRecords() {
                this.records.beneficiary_name = null;
                this.records.beneficiary_phone = null;
                this.records.beneficiary_relationship = null;
            },
            displayNewRecord() {// show new record
                this.emptyInsuranceRecords();// empty edu records
                if(this.insuranceRecords && this.insuranceRecords.length == 0) {// hide empty msg
                    this.showEmptyMsg();
                    this.showErrorStatus = this.showAddRecord;
                }
                return this.showAddRecord = ! this.showAddRecord;
            },
            purchaseInsurance() {// save new edu records
                this.isloading = true;
                let data = this.validatedInputs();// validate inputs
                if(data) {// save edu records
                    data.user_data_id = this.$store.state.user.basicProfile.id;// get user id
                    data.trans_no = this.genRandomString();// generate trans no
                    this.addUserInsurance(data).then((res) => {// add insurance
                        if(res.status) {// redirect url
                            return window.location.href='/new-account/purchase-insurance/'
                                +res.record.trans_no;
                        } else {
                            this.isloading = false;
                            this.loadInsuranceRecords();
                            this.showErrorMsg();
                            this.emptyInsuranceRecords();
                        }
                    }).
                    catch(() => {
                        this.isloading = false;
                        this.loadInsuranceRecords();
                        this.emptyInsuranceRecords();
                        this.showErrorMsg();
                    });
                } else {
                    this.isloading = false;
                    this.loadInsuranceRecords();
                }
            },
            genRandomString() {
                var length = 12;
                var text = '';
                var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
                for (var i=0; i < length; i++) {
                    text += possible.charAt(Math.floor(Math.random() * possible.length));
                }
                return text;
            },
            showErrorMsg() {
                this.showInputErrorStatus = true
                this.errorType = 'warning'
                this.errorMsg = 'An error occurred, kindly reload page and try again';
                var self = this;
                setTimeout(function() { self.showInputErrorStatus = false; }, 5000);
            },
            validatedInputs() {
                let data = this.records;
                let insuranceRecord = { beneficiary_name: null, beneficiary_phone: null,
                    beneficiary_relationship: null };
                insuranceRecord.beneficiary_name = data.beneficiary_name == null ?
                    this.showInuptError('Beneficiary Name') : data.beneficiary_name;
                insuranceRecord.beneficiary_phone  = data.beneficiary_phone == null ?
                    this.showInuptError('Beneficiary Phone Number') : data.beneficiary_phone;
                insuranceRecord.beneficiary_relationship  = data.beneficiary_relationship == null ?
                    this.showInuptError('Beneficiary Relationship') : data.beneficiary_relationship;
                return this.isEmpty(insuranceRecord) ? insuranceRecord : false;
            },
            isEmpty(obj) {// check for empty field
                return ! Object.keys(obj).some(k => !obj[k]);
            },
            showInuptError(name) {
                this.isloading = false;
                this.showInputErrorStatus = true
                this.errorType = 'warning'
                this.errorMsg = 'Invalid ' + name + ' has been entered';
                var self = this;
                setTimeout(function() { self.showInputErrorStatus = false; }, 5000);
            },
            reQueryTransaction(number) {// re-query payment function
                this.preloader();// show loading
                this.isloading = true;
                this.selectedRowNo = number;
                this.reQueryInsurance(number).then((res) => {// add insurance
                    this.selectedRowNo = null;
                    if(res.status) {// success
                        this.isloading = false;
                        swal.fire('Payment Successful!', res.msg, 'success');
                        this.loadInsuranceRecords();// reload page
                    } else {
                        this.isloading = false;
                        let msg = res.msg ? res.msg : 'Insurance Indemnity payment was not successful';
                        swal.fire('Error Occurred!', msg, 'warning');
                        this.loadInsuranceRecords();// reload page
                    }
                }).
                catch(() => {
                    this.isloading = false;
                    let msgBody = 'An error occurred while verifying, try again';
                    swal.fire('Error Occurred!', msgBody, 'warning');
                    this.loadInsuranceRecords();// reload page
                });
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        mounted() {
            this.loadInsuranceRecords();// load educational records
            this.getValidInsurance();
        },
    }
</script>

<style scoped>
    .float-right {
        float: right;
    }
</style>