<template>
    <form @submit.prevent>

        <div class="col-lg-12" v-if="isLoadingBlade">
            <div class="d-flex justify-content-center">
                <img :src="loadingBladeUrl" />
            </div>
        </div>

        <div class="pl-lg-4" v-if="profile && displayPage">
            <!-- <h3 class="heading text-muted mb-4">Bio information</h3> -->
            <div class="row">
                <div class="col-lg-4">
                    <base-input alternative=""
                        label="First Name"
                        placeholder="First Name"
                        input-classes="form-control-alternative text-capitalize"
                        v-model="profile.first_name"
                        required
                    />
                </div>
                <div class="col-lg-4">
                    <base-input alternative=""
                        label="Last name"
                        placeholder="Last name"
                        input-classes="form-control-alternative text-capitalize"
                        v-model="profile.last_name"
                        required
                    />
                </div>
                <div class="col-lg-4">
                    <base-input alternative=""
                        label="Other Names"
                        placeholder="Other Names"
                        input-classes="form-control-alternative text-capitalize"
                        v-model="profile.other_name"
                    />
                </div>
                <div class="col-md-4">
                    <base-input alternative=""
                        label="Gender"
                        required
                        >
                        <select
                            class="form-control form-control-alternative"
                            v-model="profile.gender"
                        >
                            <option value="null" disabled>--- Select One ---</option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                        </select>
                    </base-input>
                </div>
                <div class="col-md-4">
                    <base-input
                        label="Date of Your Birth (Date you were born/you were given birth to)"
                        addon-left-icon="ni ni-calendar-grid-58"
                        required

                    >
                        <flat-picker
                            slot-scope="{focus, blur}"
                            @on-open="focus"
                            @on-close="blur"
                            :config="{allowInput: true}"
                            class="form-control datepicker"
                            v-model="profile.date_of_birth"
                        >
                        </flat-picker>
                    </base-input>
                </div>
                <div class="col-md-4">
                    <base-input alternative=""
                        label="Previous Names"
                        placeholder="Previous Names"
                        input-classes="form-control-alternative text-capitalize"
                        v-model="profile.previous_names"
                    />
                </div>
                <div class="col-md-4">
                    <base-input alternative=""
                        label="Phone Number"
                        placeholder="Phone Number"
                        input-classes="form-control-alternative"
                    >
                    <!-- v-model="profile.phone"  -->
                        <VuePhoneNumberInput
                            v-model="tel.phone"
                            @update="updatePhoneNumber"
                            :default-country-code="tel.code"
                            :no-example="false"
                            :clearable="true"
                        />
                    </base-input>
                </div>
                <div class="col-md-4">
                    <base-input alternative=""
                        label="Place of Birth"
                        placeholder="Place of Birth"
                        input-classes="form-control-alternative text-capitalize"
                        v-model="profile.place_of_birth"
                        required
                    />
                </div>
                <div class="col-md-4">
                    <base-input alternative=""
                        label="Marital Status"
                        required
                    >
                        <select
                            class="form-control form-control-alternative"
                            v-model="profile.marital_status"
                        >
                            <option value="null" disabled>--- Select One ---</option>
                            <option value="single">Single</option>
                            <option value="married">Married</option>
                            <option value="divorced">Divorced</option>
                            <option value="widow">Widow</option>
                        </select>
                    </base-input>
                </div>
                <div class="col-md-4">
                    <base-input alternative=""
                        label="Nationality"
                        required
                    >
                        <autocomplete
                            v-if="locations.countries && locations.countries.length > 0"
                            input-class="form-control form-control-alternative"
                            placeholder="Type to Search for Nationality"
                            showNoResults
                            :source="locations.countries"
                            v-model="profile.nationality"
                            :initialDisplay="(hasCountry ? hasCountry.name : '')"
                            @selected="changeCountry"
                        >
                        </autocomplete>
                    </base-input>
                </div>

                <div class="col-md-4" v-if="showForeignState">
                    <base-input alternative=""
                        label="State/Province of Origin"
                        required
                    >
                        <autocomplete
                            :disableInput="cities && cities.length == 0"
                            input-class="form-control form-control-alternative"
                            placeholder="Type to Search State/Province of Origin"
                            showNoResults
                            :source="cities"
                            :initialValue="profile.foreign_state"
                            :initialDisplay="profile.foreign_state"
                            resultsValue="name"
                            @selected="changeCity"
                        >
                        </autocomplete>
                    </base-input>
                </div>

                <div class="col-md-4" v-if="showForeignState && displayOtherState">
                    <base-input alternative=""
                        label="Other State/Province"
                        placeholder="Enter Other State/Province"
                        input-classes="form-control-alternative"
                        v-model="other_foreign_state"
                        required
                    />
                </div>

                <div class="col-md-4" v-if="! showForeignState">
                    <base-input alternative=""
                        label="State of Origin"
                        required
                    >
                        <autocomplete
                            v-if="locations.states && locations.states.length > 0"
                            input-class="form-control form-control-alternative"
                            placeholder="Type to Search for State of Origin"
                            showNoResults
                            :source="locations.states"
                            v-model="profile.state_origin"
                            :initialDisplay="(hasState ? hasState.name : '')"
                            @selected="getStateLgas"
                        >
                        </autocomplete>
                    </base-input>
                </div>

                <div class="col-md-4"
                    v-if="profile.state_origin && ! showForeignState"
                >
                    <base-input alternative=""
                        label="LGA of Origin"
                        required
                    >
                        <autocomplete
                            v-if="lgas && lgas.length > 0"
                            input-class="form-control form-control-alternative"
                            placeholder="Type to Search for LGA of Origin"
                            showNoResults
                            :source="lgas"
                            v-model="profile.lga_origin"
                            :initialDisplay="(hasLga ? hasLga.name : '')"
                        >
                        </autocomplete>
                    </base-input>
                </div>
                <div class="col-md-4">
                    <base-input alternative=""
                        label="HomeTown"
                        placeholder="HomeTown"
                        input-classes="form-control-alternative text-capitalize"
                        v-model="profile.hometown"
                        required
                    />
                </div>

                <div class="col-lg-4">
                    <base-input alternative=""
                        label="Year of Graduation"
                        required
                    >
                        <select
                            class="form-control form-control-alternative"
                            v-model="profile.year_of_graduation"
                        >
                            <option value="null" disabled>--- Select One ---</option>
                            <option
                                v-for="year in years"
                                :value="year"
                                :key="year"
                            >
                                {{year}}
                            </option>
                        </select>
                    </base-input>
                </div>

                <div class="col-lg-4">
                    <base-input alternative=""
                        label="Employment Status"
                        required
                    >
                        <select
                            class="form-control form-control-alternative"
                            v-model="profile.employ_status"
                        >
                            <option value="null" disabled>--- Select One ---</option>
                            <option value="employed">Employed</option>
                            <option value="unemployed">Unemployed</option>
                        </select>
                    </base-input>
                </div>

                <div class="col-lg-4" v-if="showEmploymentSector">
                    <base-input alternative=""
                        label="Employment Sector"
                    >
                        <select
                            class="form-control form-control-alternative"
                            v-model="profile.employ_sector"
                        >
                            <option value="null" disabled>--- Select One ---</option>
                            <option value="private">Private</option>
                            <option value="government">Government</option>
                        </select>
                    </base-input>
                </div>

                <div class="col-lg-4" v-if="showLevelofGovernment">
                    <base-input alternative=""
                        label="Level of Government (Employment)"
                    >
                        <select
                            class="form-control form-control-alternative"
                            v-model="profile.level_of_govt"
                        >
                            <option value="null" disabled>--- Select One ---</option>
                            <option value="federal-government">Federal Government</option>
                            <option value="state-government">State Government</option>
                            <option value="local-government">Local Government</option>
                        </select>
                    </base-input>
                </div>

                <div class="col-lg-4" v-if="showServiceStatus">
                    <base-input alternative=""
                        label="Employment Service Status"
                    >
                        <select
                            class="form-control form-control-alternative"
                            v-model="profile.service_status"
                        >
                            <option value="null" disabled>--- Select One ---</option>
                            <option value="retired">Retired</option>
                            <option value="in-service">In-service</option>
                        </select>
                    </base-input>
                </div>

                <div class="col-lg-4" v-if="showOtherEmploymentDetails">
                    <base-input alternative=""
                        label="Level Of Care (Employment)"
                    >
                        <select
                            class="form-control form-control-alternative"
                            v-model="profile.level_of_care"
                        >
                            <option value="null" disabled>--- Select One ---</option>
                            <option value="tertiary">Tertiary</option>
                            <option value="secondary">Secondary</option>
                            <option value="primary">Primary</option>
                            <option value="not-applicable">Not Applicable</option>
                        </select>
                    </base-input>
                </div>

                <div class="col-lg-4" v-if="showOtherEmploymentDetails">
                    <base-input alternative=""
                        label="Practice Geo-Location (Employment)"
                    >
                        <select
                            class="form-control form-control-alternative"
                            v-model="profile.practice_geolocation"
                        >
                            <option value="null" disabled>--- Select One ---</option>
                            <option value="urban">Urban</option>
                            <option value="rural">Rural</option>
                            <option value="sub-urban">Sub-Urban</option>
                        </select>
                    </base-input>
                </div>

                <div class="col-lg-4" v-if="showOtherEmploymentDetails">
                    <base-input alternative=""
                        label="Category Of Practice (Employment)"
                    >
                        <select
                            class="form-control form-control-alternative"
                            v-model="profile.category_of_practice"
                        >
                            <option value="null" disabled>--- Select One ---</option>
                            <option value="clinical">Clinical</option>
                            <option value="non-clinical">Non-Clinical</option>
                        </select>
                    </base-input>
                </div>

                <div class="col-lg-4" v-if="showClinicalType">
                    <base-input alternative=""
                        label="Clinical Type (Employment)"
                    >
                        <select
                            class="form-control form-control-alternative"
                            v-model="profile.clinical_type"
                        >
                            <option value="null" disabled>--- Select One ---</option>
                            <option value="clinic">Clinic</option>
                            <option value="hospital">Hospital</option>
                        </select>
                    </base-input>
                </div>

                <div class="col-lg-4" v-if="showNonClinicalType">
                    <base-input alternative=""
                        label="Non-Clinical Type (Employment)"
                    >
                        <select
                            class="form-control form-control-alternative"
                            v-model="profile.non_clinical_type"
                        >
                            <option value="null" disabled>--- Select One ---</option>
                            <option value="academia">Academia</option>
                            <option value="ngo">NGO</option>
                            <option value="others">Others</option>
                        </select>
                    </base-input>
                </div>

                <div class="col-lg-4">
                    <base-input alternative=""
                        label="Country of Practice"
                        required
                    >
                        <autocomplete
                            v-if="locations.countries && locations.countries.length > 0"
                            input-class="form-control form-control-alternative"
                            placeholder="Type to Search for Country of Practice"
                            showNoResults
                            :source="locations.countries"
                            v-model="profile.country_of_practice"
                            :initialDisplay="(hasPracticeCountry ? hasPracticeCountry.name : '')"
                            @selected="changeCountry"
                        >
                        </autocomplete>
                    </base-input>
                </div>
                <div class="col-lg-4" v-if="showLocalStateOfPractice">
                    <base-input alternative=""
                        label="State of Practice"
                    >
                        <select
                            class="form-control form-control-alternative"
                            v-model="profile.state_of_practice"
                            @change="getPracticeStateLgas(profile.state_of_practice)"
                        >
                            <option value="null" disabled>--- Select One ---</option>
                            <option
                                v-for="state in locations.states"
                                :value="state.id"
                                :key="state.id"
                            >
                                {{state.name}}
                            </option>
                        </select>
                    </base-input>
                </div>
                <div class="col-lg-4"
                    v-if="showLocalStateOfPractice && profile.state_of_practice"
                >
                    <base-input alternative=""
                        label="LGA of Practice"
                    >
                        <select
                            class="form-control form-control-alternative"
                            v-model="profile.lga_of_practice"
                            v-if="practiceLgas"
                        >
                            <option value="null" disabled>--- Select One ---</option>
                            <option
                                v-for="lga in practiceLgas"
                                v-show="locations && practiceLgas && practiceLgas.length > 0"
                                :value="lga.id"
                                :key="lga.id"
                            >
                                {{lga.name}}
                            </option>
                        </select> 
                    </base-input>
                </div>

                <div class="col-lg-4" v-if="! showLocalStateOfPractice && profile.country_of_practice">
                    <base-input alternative=""
                        label="State / Province of Practice"
                        required
                    >
                        <autocomplete
                            :disableInput="cities && cities.length == 0"
                            input-class="form-control form-control-alternative"
                            placeholder="Type to Search State / Province of Practice"
                            showNoResults
                            :source="cities"
                            :initialValue="profile.foreign_state_practice"
                            :initialDisplay="profile.foreign_state_practice"
                            resultsValue="name"
                            @selected="changePracticeCity"
                        >
                        </autocomplete>
                    </base-input>
                </div>

                <div class="col-lg-4" v-if="! showLocalStateOfPractice && displayOtherPracticeState">
                    <base-input alternative=""
                        label="Other State / Province of Practice"
                        placeholder="Other State / Province of Practice"
                        input-classes="form-control-alternative text-capitalize"
                        v-model="other_foreign_state_practice"
                    />
                </div>

                <div class="col-lg-4">
                    <base-input alternative=""
                        label="Hospital of Practice/Residence (Find your Hospital first, before choosing Others)" 
                    >
                        <autocomplete
                            v-if="hospitals && hospitals.length > 0"
                            input-class="form-control form-control-alternative"
                            placeholder="Type to Search for Hospital"
                            showNoResults
                            :source="hospitals"
                            :initialDisplay="(hasPracticeHospital ? hasPracticeHospital.name : '')"
                            v-model="profile.teaching_hospital_id"
                        >
                        </autocomplete>
                    </base-input>
                </div>

                <div class="col-lg-4"
                    v-if="(profile.teaching_hospital_id == 0)"
                >
                    <base-input alternative=""
                        label="Other Hospital of Practice/Residence"
                        placeholder="Enter the Name of the Hospital "
                        input-classes="form-control-alternative text-capitalize"
                        v-model="profile.hospital_practice"
                    />
                </div>


                <div class="col-lg-4">
                    <base-input alternative=""
                        label="Registration Type"
                        required
                    >
                        <select
                            class="form-control form-control-alternative"
                            @change="getSubRegistrationTypes()"
                            v-model="selectedRegType"
                        >
                            <option value="null" selected disabled>
                                --- Select One ---
                            </option>
                            <option
                                v-for="reg in registrationTypes"
                                @if="registrationTypes && registrationTypes.length > 0"
                                :key="reg.id"
                                :value="reg.id"
                            >
                                {{ reg.name }}
                            </option>
                        </select>
                    </base-input>
                </div>

                <div class="col-lg-4"
                    v-if="profile.sub_registration_type_id || (selectedRegType &&
                        (subRegTypes && subRegTypes.length > 0))"
                >
                    <base-input alternative=""
                        label="Sub Registration Type"
                    >
                        <select
                            class="form-control form-control-alternative"
                            v-model="profile.sub_registration_type_id"
                        >
                            <option value="null" selected disabled>
                                --- Select One ---
                            </option>
                            <option
                                v-for="reg in subRegTypes"
                                @if="subRegTypes && subRegTypes.length > 0"
                                :key="reg.id"
                                :value="reg.id"
                            >
                                {{ reg.name }}
                            </option>
                        </select>
                    </base-input>
                </div>

                <div class="col-lg-12">
                    <div class="form-group">
                        <base-input alternative=""
                            label="Permanent Address"
                            required
                        >
                            <textarea
                                rows="2"
                                class="form-control form-control-alternative"
                                placeholder="Enter Permanent Address"
                                v-model="profile.permanent_address"
                            ></textarea>
                        </base-input>
                    </div>
                    <div class="form-group">
                        <base-input alternative=""
                            label="Postal Address (If any)">
                            <textarea
                                rows="2"
                                class="form-control form-control-alternative"
                                placeholder="Enter Permanent Address"
                                v-model="profile.postal_address"
                            ></textarea>
                        </base-input>
                    </div>
                </div>

            </div>
        </div>
        <hr class="my-4" />

        <base-alert :type="errorType" v-if='showErrorStatus'>
            <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
            <span class="alert-inner--text">
                {{errorMsg}}
            </span>
        </base-alert>

        <div class="pl-lg-4" v-if="displayPage">
            <base-button type="success" @click="saveBioData()">
                <i class="fa fa-save" @if='! isloading'></i>
                {{isloading ? 'Please Wait' : 'Save Changes'}}
            </base-button>

            <div class="right" v-if="bioDataIsEmpty && saveButton">
                <base-button type="success" @click="showNextPage()">
                    Next
                    <i class="fa fa-arrow-right"></i>
                </base-button>
            </div>
        </div>
    </form>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import moment from 'moment';
    import swal from 'sweetalert2';// import sweet alert
    import flatPicker from "vue-flatpickr-component";
    import "flatpickr/dist/flatpickr.css";
    import VuePhoneNumberInput from 'vue-phone-number-input';
    import 'vue-phone-number-input/dist/vue-phone-number-input.css';
    import Autocomplete from 'vuejs-auto-complete';
    import states from '../../../states.json';

    export default {
        name: 'BioData',
        components: {
            flatPicker,
            VuePhoneNumberInput,
            Autocomplete
        },
        props: ['displayEduRecords'],
        data() {
            return {
                locations: [],
                profile: {},
                lgas: [],
                practiceLgas: [],
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                registrationTypes: [],
                selectedRegType: null,
                subRegTypes: [],
                isloading: false,
                isLoadingBlade: true,
                displayPage: false,
                saveButton: false,
                tel: {
                    phone: null,
                    code: 'NG',
                },
                states: states,
                cities: [],
                other_foreign_state: null,
                other_foreign_state_practice: null,
                displayOtherState: false,
                displayOtherPracticeState: false
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl', 'hospitals', 'user']),
            hasCountry() {
                return this.locations.countries.filter(item => item.id == this.profile.nationality)[0];
            },
            hasPracticeCountry() {
                return this.locations.countries.filter(item => item.id == this.profile.country_of_practice)[0];
            },
            hasState() {
                return this.locations.states.filter(item => item.id == this.profile.state_origin)[0];
            },
            hasLga() {
                return this.locations.lgas.filter(item => item.id == this.profile.lga_origin)[0];
            },
            showForeignState() {
                return this.profile.nationality == 164 ? false : true;
            },
            years() {
                let startYear = moment().format('YYYY') - 119;// start from 1900
                let currentYear = parseInt(moment().format('YYYY'));
                let years = [];
                for (let i = currentYear; i > startYear; i-- ) {
                    years.push(i);
                }
                return years;
            },
            showLocalStateOfPractice() {//check if country is Nigeria (id = 164)
                return this.profile.country_of_practice == 164 ? true : false;
            },
            showEmploymentSector() {// check if user is employed
                return (this.profile.employ_status == "employed") ? true : false;
            },
            showLevelofGovernment() {// display the level of govt
                return this.showEmploymentSector && (this.profile.employ_sector == "government") ? true : false;
            },
            showServiceStatus() {// display employment service status
                return this.showLevelofGovernment && (this.profile.level_of_govt != null) ? true : false;
            },
            showClinicalType() {// display clinical type
                return this.showEmploymentSector && (this.profile.category_of_practice == 'clinical') ? true : false;
            },
            showNonClinicalType() {// display non-clinical type
                return this.showEmploymentSector && (this.profile.category_of_practice == 'non-clinical') ? true : false;
            },
            showOtherEmploymentDetails() {// display other employment details
                return this.showEmploymentSector && this.profile.employ_sector ? true : false;
            },
            bioDataIsEmpty() {
                return this.getRequiredInputs();// get required inputs
            },
            hasPracticeHospital() {// get initial hospital 
                return this.hospitals.filter(item => item.id == this.profile.teaching_hospital_id)[0];
            },
        },
        methods: {
            ...mapActions(['getLocations', 'getRegistrationTypes', 'updateBioData', 'getBioData', 'fetchUserProfile', 'getUserSubRegistrationTypes']),
            changePracticeCity(e) {
                this.displayOtherPracticeState = false;
                let data = e.selectedObject;
                this.other_foreign_state_practice = null;
                if(data.id == 0) {
                    this.profile.foreign_state_practice = null;
                    this.displayOtherPracticeState = true;
                } else {
                    this.displayOtherPracticeState = false;
                    this.profile.foreign_state_practice = data.name;
                }
            },
            changeCity(e) {
                this.displayOtherState = false;
                let data = e.selectedObject;
                this.other_foreign_state = null;
                if(data.id == 0) {
                    this.profile.foreign_state = null;
                    this.displayOtherState = true;
                } else {
                    this.displayOtherState = false;
                    this.profile.foreign_state = data.name;
                }
            },
            changeCountry(e) {
                this.displayOtherState = false;
                this.displayOtherPracticeState = false;
                this.cities = [];
                let data = e.selectedObject;
                let other = { id : 0, name: 'Others (Specify)'};
                this.cities = this.states.filter(item => item.country_code == data.code);
                this.cities.push(other);
            },
            updatePhoneNumber(event) {
                if(event && event.isValid && event.e164) {
                    this.profile.phone = event.formattedNumber;
                    this.profile.phone_code = event.countryCode+'-'+event.phoneNumber;
                }
            },
            loadPhoneNumber() {// update phone no
                let data = this.profile.phone_code;
                let exploded = data ? data.split('-') : false;
                this.tel.code = exploded && exploded[0] ? exploded[0] : 'NG';
                this.tel.phone = exploded && exploded[1] ? exploded[1] : null;
            },
            checkRegistrationType(id) {// get registration type from sub Reg type
                this.selectedRegType = null;
                for(let type of this.subRegTypes) {
                    if(type.id == id) {
                        this.selectedRegType = type.registration_type_id
                    }
                }
            },
            loadBioData() {
                this.getBioData().then((res) => {
                    this.isLoadingBlade = false;
                    this.displayPage = true;
                    this.profile = res.basic_profile;// get user profile
                    this.loadLocations();// load lgas of origin
                    this.loadRegistrationTypes();// load reg types
                    this.loadSubRegTypes(this.profile.sub_registration_type_id);// load sub reg types
                    this.loadPhoneNumber();// update phone no
                }).
                catch(() => {
                    this.showErrorStatus = true;
                    this.isLoadingBlade = false;
                    this.displayPage = false;
                    this.errorMsg = "Network Failure!, kindly reload the page...";
                    this.errorType = 'danger';
                });
            },
            loadSubRegTypes(id) {// get sub reg types
                this.getUserSubRegistrationTypes().then((res) => {
                    this.subRegTypes = res;
                    this.checkRegistrationType(id);
                });
            },
            loadLocations() {// get locations
                this.getLocations().then((res) => {
                    this.locations = res;
                    this.getStateLgas();// load lgas of origin
                    this.getPracticeStateLgas();//load lgas of practice
                });
            },
            getStateLgas(e) {// get state's lgas
                let lgas = [];
                let stateId = e ? e.value : this.profile.state_origin;
                if(stateId && this.locations && this.locations.lgas) {
                    for(let lga of this.locations.lgas) {
                        if (lga.state_id == stateId) {
                            lgas.push(lga);
                        }
                    }
                    return this.lgas = lgas;
                }

            },
            getPracticeStateLgas(value) {// practice lgas
                let practiceLgas = [];
                let stateId = value ? value : this.profile.state_of_practice;
                if(stateId && this.locations && this.locations.lgas) {
                    for(let lga of this.locations.lgas) {
                        if (lga.state_id == stateId) {
                            practiceLgas.push(lga);
                        }
                    }
                    return this.practiceLgas = practiceLgas;
                }
            },
            loadRegistrationTypes() {// load reg types
                this.getRegistrationTypes().then((res) => {
                    let regTypes = [];
                    let doctorType = this.profile.doctor_type_id;
                    if(doctorType == 3) {
                        for(let reg of res) {// check if doctor is alternative
                            if(doctorType == 3 && reg.id == 2) {
                                regTypes.push(reg);
                            }
                        }
                        return this.registrationTypes = regTypes;
                    } else {
                        return this.registrationTypes = res;
                    }
                });
            },
            getSubRegistrationTypes() {// get reg sub types
                this.subRegTypes = [];
                this.profile.sub_registration_type_id = null;
                for(let reg of this.registrationTypes) {
                    if(reg.id == this.selectedRegType) {
                        let types = [];
                        let doctorType = this.profile.doctor_type_id;
                        if(doctorType == 3) {// check if doctor is alternative
                            for(let subType of reg.has_sub_registrations) {
                                if(doctorType == 3 && subType.id == 9) {
                                    types.push(subType);
                                }
                            }

                        } else {
                            for(let subType of reg.has_sub_registrations) {
                                if(subType.id != 9) {
                                    types.push(subType);
                                }
                            }
                        }
                        return this.subRegTypes = types;
                    }
                }
            },
            showNextPage() {// go to next page
                return this.displayEduRecords(true);
            },
            updateNationality() {
                if(this.profile.nationality == 164) {
                    this.profile.foreign_state = null;
                } else {
                    this.profile.state_origin = null;
                    this.profile.lga_origin = null;
                }
                if(this.profile.country_of_practice == 164) {// update practice state
                    this.profile.foreign_state_practice = null;
                } else {
                    this.profile.lga_of_practice = null;
                    this.profile.state_of_practice = null;
                }
            },
            saveBioData() {
                this.isloading = true;
                let status = this.getRequiredInputs();// valid input data
                if(status) {// add user id
                    this.updateNationality();// update nation
                    let data = this.profile;
                    data.foreign_state = data.foreign_state ? data.foreign_state : this.other_foreign_state;// update foreign state
                    data.foreign_state_practice = data.foreign_state_practice ? data.foreign_state_practice
                                                    : this.other_foreign_state_practice;// update foreign Practice state
                    data.id = this.$store.state.user.userId;
                    this.updateBioData(data).then((res) => {
                        this.fetchUserProfile();// load bio data
                        this.isloading = false;
                        if(res.status) {// load updated
                            this.saveButton = true;
                            swal.fire("Record Updated!", "Bio Data was updated successfully", "success");
                        } else {
                            this.showUpdateMsg('An error occurred while updating, try again', 'warning');
                        }
                    });
                } else {
                    this.isloading = false;
                    this.showUpdateMsg('Empty Fields!, kindly fill all fields to proceed', 'warning');
                }
            },
            getRequiredInputs() {// get required inputs
                let data = {};
                data.first_name = this.profile.first_name;
                data.last_name = this.profile.last_name;
                data.gender = this.profile.gender;
                data.date_of_birth = this.profile.date_of_birth;
                data.place_of_birth = this.profile.place_of_birth;
                data.marital_status = this.profile.marital_status;
                data.nationality = this.profile.nationality;
                data.hometown = this.profile.hometown;
                data.year_of_graduation = this.profile.year_of_graduation;
                // data.employ_status = this.profile.employ_status;
                data.country_of_practice = this.profile.country_of_practice;
                data.sub_registration_type_id = this.profile.sub_registration_type_id;
                if(this.profile.country_of_practice && this.profile.country_of_practice != 164) {// if nigeria, delete foreign
                    data.foreign_state_practice = this.profile.foreign_state_practice;
                } else {// delete state & lga
                    data.lga_of_practice = this.profile.lga_of_practice;
                    data.state_of_practice = this.profile.state_of_practice;
                }
                if((this.profile.teaching_hospital_id == 0) || (this.profile.teaching_hospital_id == null)) {// delete hospital of practice
                    data.hospital_practice = this.profile.hospital_practice;
                } else {// delete teaching_hospital_id
                    data.teaching_hospital_id = this.profile.teaching_hospital_id;
                }
                // if(this.profile.nationality && this.profile.nationality != 164) {// if nigeria, delete foreign
                //     data.foreign_state = this.profile.foreign_state;
                // } else {// delete state & lga
                //     data.state_origin = this.profile.state_origin;
                //     data.lga_origin = this.profile.lga_origin;
                // }
                let employmentStatus = this.checkEmploymentDetails(this.profile);// remove employment status
                return this.isEmpty(data) && employmentStatus;
            },
            isEmpty(obj) {// check for empty field
                return ! Object.keys(obj).some(k => !obj[k]);
            },
            showUpdateMsg(msg, type) {
                this.showErrorStatus = true;
                this.errorType = type;
                this.errorMsg = msg;
                var self = this;
                setTimeout(function() { self.showErrorStatus = false; }, 5000);
            },
            checkEmploymentDetails(record) {// check if all employment details are filled
                if(record.employ_status == 'employed') {
                    let independentFields = record.employ_sector && record.category_of_practice && record.level_of_care && record.practice_geolocation;
                    let categoryType = (record.category_of_practice == 'clinical') ? record.clinical_type : record.non_clinical_type;
                    let privateFields = (record.level_of_govt && record.service_status) || (record.employ_sector == 'private');
                    return independentFields && categoryType && privateFields;
                }
                return record.employ_status ? true : false;
            }
        },
        created() {
            this.loadBioData();// load bio data
        },
    }
</script>

<style scoped>
.right {
    float: right;
}
</style>