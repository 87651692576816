<template>
    <div>
        <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center no-padding-bottom">
            <span class="mask bg-gradient-danger opacity-8"></span>
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col-xl-12 order-xl-1">
                    <br><br><br>
                    <card shadow type="secondary">
                        
                        <RemediationDashboard 
                            v-if="code && code == 'programme'"
                        />

                        <RemediationGenerate 
                            v-if="code && code == 'generate'"
                        />

                        <RemediationCheckPayment
                            v-if="code && code == 'checkPayment'"
                        />

                        <RemediationPaymentCallback
                            v-if="code && code == 'callback'"
                        />

                        <RemediationForm
                            v-if="code && code == 'applicationForm'"
                        />

                    </card>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import RemediationDashboard from './RemediationDashboard/RemediationDashboard.vue'
    import RemediationGenerate from './RemediationPayment/RemediationGenerate.vue'
    import RemediationCheckPayment from './RemediationPayment/RemediationCheckPayment.vue'
    import RemediationPaymentCallback from './RemediationPayment/RemediationPaymentCallback.vue'
    import RemediationForm from './RemediationForm/RemediationForm.vue'

    export default {
        name: 'Remediation',
        components: {
            RemediationDashboard,
            RemediationGenerate,
            RemediationCheckPayment,
            RemediationPaymentCallback,
            RemediationForm
        },
        data() {
            return {
                code: this.$route.params.code
            }
        }
    }
</script>

<style scoped>
    .no-padding-bottom {
        padding-bottom: 0rem !important
    }
    .right {
        float: right;
    }
</style>

<style>
    .form-control-label {
        width: 100%;
    }
    .height-90 {
        height: 90px;
    }
    .borderLine {
        border: 1px solid #ededed;
    }
</style>