<template>
    <div class="row justify-content-center">
        <div class="col-lg-5 col-md-7">
            <div class="card bg-secondary shadow border-0">
                <!-- <div class="card-header bg-transparent pb-5">
                    <div class="text-muted text-center mt-2 mb-3">
                        <small>Sign up with</small>
                    </div>
                    <div class="btn-wrapper text-center">
                        <a href="#" class="btn btn-neutral btn-icon">
                            <span class="btn-inner--icon"><img src="img/icons/common/github.svg"></span>
                            <span class="btn-inner--text">Github</span>
                        </a>
                        <a href="#" class="btn btn-neutral btn-icon">
                            <span class="btn-inner--icon"><img src="img/icons/common/google.svg"></span>
                            <span class="btn-inner--text">Google</span>
                        </a>
                    </div>
                </div> -->
                <div class="card-body px-lg-5 py-lg-5">
                    <div class="text-center text-danger mb-4">
                        <span>
                            Note: For email address (Gmail is preferrable),
                            kindly enter valid credentials below
                        </span>
                    </div>

                    <base-alert :type="type" v-if="showMsgBlade">
                        <span class="alert-inner--icon">
                            <i class="ni ni-sound-wave"></i>
                        </span>&nbsp;
                        <span class="alert-inner--text" v-text="msg"></span>
                    </base-alert>

                    <form role="form" @submit.prevent="createAccount">

                        <base-input class="input-group-alternative mb-3"
                                    placeholder="Email"
                        >
                            <select
                                class="form-control form-control-alternative"
                                v-model="model.doctor_type_id"
                                @change="updateFolioPrefix"
                            >
                                <option value="null" selected disabled>
                                    Select Practice Type
                                </option>
                                <option value="1">Medical Registration</option>
                                <option value="2">Dental Registration</option>
                                <option value="3">Alternative Registration</option>
                            </select>
                        </base-input>

                        <div class="row">
                            <div class="col-lg-5">
                                <base-input class="input-group-alternative mb-3"
                                            :placeholder="model.folio_prefix"
                                            readonly
                                >
                                </base-input>
                            </div>

                            <div class="col-lg-7">
                                <base-input class="input-group-alternative mb-3"
                                            placeholder="Enter Folio Number"
                                            type="number"
                                            v-model="model.folio_number"
                                            :valid="! enableSubmit"
                                            @change="getFolioDetails()"
                                            >
                                </base-input>
                            </div>
                        </div>

                        <base-input class="input-group-alternative mb-3"
                                    placeholder="First Name"
                                    addon-left-icon="ni ni-hat-3"
                                    v-model="model.first_name">
                        </base-input>

                        <base-input class="input-group-alternative mb-3"
                                    placeholder="Last Name (Surname)"
                                    addon-left-icon="ni ni-hat-3"
                                    v-model="model.last_name">
                        </base-input>

                        <base-input class="input-group-alternative mb-3"
                                    placeholder="Other Names"
                                    addon-left-icon="ni ni-hat-3"
                                    v-model="model.other_name">
                        </base-input>

                        <base-input class="input-group-alternative mb-3"
                                    placeholder="Email"
                                    addon-left-icon="ni ni-email-83"
                                    v-model="model.email">
                        </base-input>



                        <!-- <div class="text-muted font-italic">
                            <small>password strength: <span class="text-success font-weight-700">strong</span></small>
                        </div> -->

                        <!-- <div class="row my-4">
                            <div class="col-12">
                                <base-checkbox class="custom-control-alternative">
                                    <span class="text-muted">I agree with the <a href="#!">Privacy Policy</a></span>
                                </base-checkbox>
                            </div>
                        </div> -->
                        <base-alert :type="type" v-if="showMsgBlade">
                            <span class="alert-inner--icon">
                                <i class="ni ni-sound-wave"></i>
                            </span>&nbsp;
                            <span class="alert-inner--text" v-text="msg"></span>
                        </base-alert>

                        <div class="text-center" v-if="! isNotRobot">
                            <vue-recaptcha
                                :sitekey="recaptchaKey"
                                :loadRecaptchaScript="true"
                                @verify="proceedSubmit"
                            ></vue-recaptcha>
                        </div>

                        <div class="text-center">
                            <base-button
                                type="success" class="my-4"
                                nativeType="submit"
                                :disabled="enableSubmit && ! isNotRobot"
                                v-if="! isLoading"
                            >
                                Create Account
                            </base-button>
                            <button class="btn btn-success" type="button" disabled v-if="isLoading">
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                Please Wait...
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-6">
                    <router-link to="/password-reset" class="text-white">
                        <span class="font-weight-bolder">
                            Forgot Password?
                        </span>
                    </router-link>
                </div>
                <div class="col-6 text-right">
                    <router-link to="/doctor-login" class="text-white">
                        <span class="font-weight-bolder">
                            Login Here
                        </span>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import axios from 'axios';
    import VueRecaptcha from 'vue-recaptcha';
    import swal from 'sweetalert2';// import sweet alert

    export default {
        name: 'DoctorRegister',
        components: {
            VueRecaptcha
        },
        data() {
            return {
                model: {
                first_name: null,
                last_name: null,
                other_name: null,
                email: null,
                doctor_type_id: null,
                folio_number: null,
                folio_prefix: 'MDCN/R/'
                },
                isLoading: false,
                showMsgBlade: false,
                msg: null,
                type: null,
                enableSubmit: true,
                isNotRobot: false
            }
        },
        computed: {
            ...mapGetters(['getRegisterUserUrl', 'getAuthorizationToken', 'recaptchaKey'])
        },
        methods: {
            ...mapActions(['validatingFolio']),
            getFolioDetails() {// check for valid folio number
                this.preloader(true);
                this.isloading = true;
                let folio = this.model.folio_prefix + this.model.folio_number;
                this.validatingFolio(folio).then((res) => {
                    this.isloading = false;
                    if(res.status) {
                        this.enableSubmit = false;
                        swal.close();
                    } else {
                        this.enableSubmit = true;
                        swal.fire('Validation Completed!', res.msg, 'warning');
                    }
                }).
                catch(() => {
                    this.isloading = false;
                    this.enableSubmit = true;
                    let message = 'An error occurred while verying folio number, check your network and try again';
                    swal.fire('Network Failure!', message, 'warning');
                });
            },
            createAccount() {// create account
                this.isLoading = true;
                let status = this.validatedInputs();
                if(status && ! this.enableSubmit && this.isNotRobot) {
                    let validEmail = this.validEmail(this.model.email);
                    if(validEmail) {
                        this.preloader(); // show loader
                        this.isLoading = false;
                        const header = {Authorization: `Bearer ${this.getAuthorizationToken}`};
                        axios.post(this.getRegisterUserUrl, this.model, { headers: header })
                        .then((res) => {
                            if(res.data.status) {// store token and login user
                                swal.fire("Successful!", res.data.msg, "success");
                                return window.location.href = '/doctor-login';
                            } else {//show error
                                swal.fire("Error Occurred!", res.data.msg, "warning");
                            }
                        })
                        .catch(() => {//check for failure
                            swal.fire("Network Failure!", 'Network Failure! Kindly try again', "warning");
                        });
                    } else {
                        this.isLoading = false;
                        this.showInuptError('Email Address');
                    }
                } else {
                    this.isLoading = false;
                }
            },
            validatedInputs() {
                let data = this.model;
                let record = { first_name: null, last_name: null, email: null,
                    doctor_type_id: null, folio_number: null };
                record.email  = data.email == null ?
                    this.showInuptError('Email Address') : data.email;
                record.last_name  = data.last_name == null ?
                    this.showInuptError('Last Name') : data.last_name;
                record.first_name  = data.first_name == null ?
                    this.showInuptError('First Name') : data.first_name;
                record.folio_number = data.folio_number == null ?
                    this.showInuptError('Folio Number') : data.folio_number;
                record.doctor_type_id  = data.doctor_type_id == null ?
                    this.showInuptError('Practice Type') : data.doctor_type_id;
                return this.isEmpty(record) ? true : false;
            },
            isEmpty(obj) {// check for empty field
                return ! Object.keys(obj).some(k => !obj[k]);
            },
            validEmail(email) {// eslint-disable-next-line
                var regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return regEmail.test(email);
            },
            showInuptError(name) {
                this.isloading = false;
                this.showMsgBlade = true
                this.type = 'warning'
                this.msg = 'Invalid ' + name + ' has been entered';
                var self = this;
                setTimeout(function() { self.showMsgBlade = false; }, 5000);
            },
            updateFolioPrefix(event) {// change prefix
                let type = event.target.value;
                if(type == 1 || type == 2) {
                    this.model.folio_prefix =  'MDCN/R/';
                } else {
                    this.model.folio_prefix =  'MDCN/ALT/R/';
                }
            },
            proceedSubmit(res) {
                if(res) {
                    return setTimeout(() => {
                        this.isNotRobot = ! this.isNotRobot;
                    }, 1000);
                } else {
                    swal.fire("Error Occurred", "An error occurred while verifying, kindly try again", "warning");
                }
            },
            preloader(status = false) {
                return  swal.fire({
                    title: status ? 'Validating Folio Number' : 'Creating account...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
    }
</script>

<style>
    .bg-default {
        background-color: #f5365c !important;
    }
    .bg-gradient-danger {
        background: linear-gradient(100deg, #f7fafc 0, #96e7c4 300%) !important
         /* linear-gradient(87deg, #f7fafc 0, #96e7c4 70%) !important; */
    }
    .fill-default {
        fill: #f5365c;
    }
    .navbar-horizontal .navbar-brand img {
        height: 110px;
    }
    .navbar-dark .navbar-nav .nav-link {
        color: rgb(50, 50, 93);
    }
</style>
