<template>
    <div>
        <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center no-padding-bottom">
            <span class="mask bg-gradient-danger opacity-8"></span>
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col-xl-12 order-xl-1">
                    <br><br><br>
                    <card shadow type="secondary">
                        <div slot="header" class="bg-white border-0">
                            <div class="row align-items-center">

                                <div class="col-8">
                                    <h3 class="mb-0 text-uppercase">
                                        Assessment Exam Registration Dashboard
                                    </h3>
                                    <br>
                                    <span class="text-danger">
                                        Stay up-to-date with your application status here...
                                    </span>
                                </div>
                                <div class="col-4 text-right">
                                    <a :href="websiteUrl" class="btn btn-sm btn-danger text-white">
                                       <i class="fa fa-reply"></i> Home
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div class="row" v-if='! showErrorStatus && ! isLoadingBlade'>
                            <div class="col-lg-8">
                                <h1 class="text-uppercase">
                                    <em class="text-danger">{{ personal.fullname }}</em>
                                    'S ASSESSMENT EXAM DASHBOARD
                                </h1>
                                <span class="text-uppercase">
                                    <strong>RRR:</strong> {{ rrr }}
                                </span><br>
                                <span class="text-uppercase">
                                    <strong>Application Status: </strong>
                                    <badge
                                        :type="(applicant.status == 'approved') ? 'success' : 'warning'"
                                        v-if="applicant"
                                    >
                                        {{ applicant.status }}
                                    </badge>
                                    <br>
                                </span>
                                <span class="text-uppercase">
                                    <strong>Application Date:</strong> {{ applicant.reg_date | getDateFormat }}
                                </span><br>
                                <span class="text-uppercase" v-if="(applicant.status == 'rejected' || applicant.status == 'approved')">
                                    <strong>{{ applicant.status }} Date:</strong> {{ applicant.approved_date | getDateFormat }}
                                    <br>
                                </span>
                                <span v-if="(applicant.status == 'rejected')">
                                    <strong>Reject Reason:</strong> {{ applicant.reject_reason }}
                                    <br>
                                </span>


                                <span class="text-uppercase" v-if="applicant && applicant.batch">
                                    <strong>Assessment Batch:</strong> {{ applicant.batch.title + ' (' + applicant.batch.year + ')' }}
                                </span>
                                <br><br>
                            </div>

                            <div class="col-lg-4 text-right">
                                <img :src="photo" class="photo-class"/>
                                <br><br>
                            </div>

                            <div class="table-responsive">
                                <table class="table table-bordered">
                                    <tbody>
                                        <td>
                                            <h4>Surname</h4>
                                        </td>
                                        <td>
                                            <h4 class="text-capitalize font-weight-light">
                                                {{ personal.surname ? personal.surname : 'N/A' }}
                                            </h4>
                                        </td>
                                        <td>
                                            <h4>First Name</h4>
                                        </td>
                                        <td>
                                            <h4 class="text-capitalize font-weight-light">
                                                {{ personal.first_name ? personal.first_name : 'N/A' }}
                                            </h4>
                                        </td>
                                        <td>
                                            <h4>Date of Birth</h4>
                                        </td>
                                        <td>
                                            <h4 class="text-capitalize font-weight-light">
                                                {{ personal.date_of_birth | getDateFormat }}
                                            </h4>
                                        </td>
                                    </tbody>
                                    <tbody>
                                        <td>
                                            <h4>Gender</h4>
                                        </td>
                                        <td>
                                            <h4 class="text-capitalize font-weight-light">
                                                {{ personal.gender ? personal.gender : 'N/A' }}
                                            </h4>
                                        </td>
                                        <td>
                                            <h4>Marital Status</h4>
                                        </td>
                                        <td>
                                            <h4 class="text-capitalize font-weight-light">
                                                {{ personal.marital_status ? personal.marital_status : 'N/A' }}
                                            </h4>
                                        </td>
                                        <td>
                                            <h4>Nationality</h4>
                                        </td>
                                        <td>
                                            <h4 class="text-capitalize font-weight-light">
                                                {{ personal.has_country ? personal.has_country.name : 'N/A' }}
                                            </h4>
                                        </td>
                                    </tbody>
                                </table>
                            </div>
                            <div class="col-lg-12">
                                <hr class="my-4" />
                            </div>

                            <div class="col-lg-12">
                                <form
                                    :action="getRootUrl+'/print-assessment-card'"
                                    method="POST"
                                    target="_blank"
                                >
                                    <input type="hidden" name="id" :value="applicant.id" />
                                    <input type="hidden" name="authorization_token" :value="getAuthorizationToken" />

                                    <router-link
                                        to="/assessment-exam"
                                        class="btn btn-danger"
                                    >
                                        <i class="fa fa-arrow-left"></i>
                                        Back
                                    </router-link>

                                    <base-button
                                        type="success"
                                        class="right"
                                        nativeType="submit"
                                        v-if="applicant.status == 'approved'"
                                    >
                                        <i class="fa fa-print"></i>
                                        Print Exam Card
                                    </base-button>
                                </form>
                            </div>
                        </div>

                        <div class="col-lg-12" v-if="isLoadingBlade">
                            <div class="d-flex justify-content-center">
                                <img :src="loadingBladeUrl" />
                            </div>
                        </div>

                        <div class="col-lg-12 text-center" v-if='showErrorStatus && ! isLoadingBlade'>
                            <base-alert :type="errorType">
                                <!-- <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span> -->
                                <span class="alert-inner--text">
                                    <h2 class="text-white">{{errorMsg}}</h2>
                                </span>
                            </base-alert>
                        </div>

                    </card>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import { mapActions, mapGetters } from 'vuex';
    import moment from 'moment';

    export default {
        name: 'ApplicantDashboard',
        filters: {
            getDateFormat(value) {
                return moment(value).format('Do MMM, YYYY');
            }
        },
        data() {
            return {
                applicant: null,
                isLoadingBlade: false,
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                id: localStorage.getItem('applicantId'),
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl', 'getRootUrl', 'getAuthorizationToken', 'websiteUrl']),
            personal() {
                return this.applicant.personal_data;
            },
            rrr() {
                return this.applicant.rrr;
            },
            photo() {
                return this.applicant.photo;
            }
        },
        methods: {
            ...mapActions(['getApplicantInfo']),
            loadApplicantInfo() {
                this.isLoadingBlade = true;
                this.applicant = null;
                this.getApplicantInfo(this.id).then((res) => {
                    this.isLoadingBlade = false;
                    if(res.status) {
                        this.showErrorStatus = false;
                        this.applicant = res.applicant;
                    } else {
                        this.showErrorStatus = true;
                        this.errorMsg = res.msg;
                        this.errorType = 'danger';
                    }
                }).
                catch(() => {
                    this.showErrorStatus = true;
                    this.isLoadingBlade = false;
                    this.errorMsg = "Network Failure!, kindly reload the page...";
                    this.errorType = 'danger';
                });
            }
        },
        created() {
            this.loadApplicantInfo();
        }
    }
</script>

<style scoped>
    .no-padding-bottom {
        padding-bottom: 0rem !important
    }
    .photo-class {
        width: 200px;
        height: 200px;
    }
</style>

<style>
    .form-control-label {
        width: 100%;
    }
    .height-50 {
        height: 50px;
    }
    .borderLine {
        border: 1px solid #ededed;
    }
</style>