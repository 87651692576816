<template>
    <div class="col-xl-12 order-xl-1">
        <card shadow type="secondary">

            <div class="col-lg-12" v-if="isLoadingBlade">
                <div class="d-flex justify-content-center">
                    <img :src="loadingBladeUrl" />
                </div>
            </div>

            <h3 class="text-center text-danger" v-if="! hasPaidRegistration && ! isTempRenwal && ! isLoadingBlade">
                Note: If you want to renew your Temporary Practicing license, click on 
                <span class="text-primary">Renew License</span> button below,
                else for New Temporary Registration kindly click on the 
                <span class="text-info">Other button</span> below 
                <!-- <hr> -->
            </h3>

            <h3 class="text-center text-danger" v-if="isTempRenwal && ! isLoadingBlade">
                Note: If you want to renew your Temporary Practicing license, click on 
                <span class="text-primary">Renew License</span> button below,
                Note you can skip if you make payment and added it online already by clicking on 
                <em class="text-success">Dashboard Now</em> button below
            </h3>

            <div class="pl-lg-4" v-if="! isLoadingBlade && ! isTempRenwal">

                <!-- Import Registration payment data blade here -->
                <RegistrationPaymentData
                    v-bind:applications="application.previous_applications"
                    v-on:reQueryTransaction="reQueryTransaction"
                    v-on:addPayment="addPayment"
                    v-if="! hasPaidRegistration && ! showAddPayment && ! showNewPayment && ! isLoadingBlade"
                />

                <div class="text-center"
                    v-if="! hasPaidRegistration && application && application.previous_applications &&
                        application.previous_applications.length > 0 && ! showAddPayment && ! isLoadingBlade"
                >
                    <base-button
                        :type="showNewPayment ? 'danger' : 'info'"
                        size="lg"
                        @click="showNewPaymentBlade()"
                    >
                        <i class="fa fa-arrow-down" v-if="! showNewPayment"></i>
                        <i class="fa fa-times" v-if="showNewPayment"></i>
                        {{ showNewPayment ? 'Cancel Payment' : 'Click Here for Make Payment' }}
                    </base-button>

                    <router-link
                        :to="getTempRegUrl"
                        class="btn btn-lg btn-primary"
                    >
                        <i class="fa fa-history"></i>
                        Renew License Now
                    </router-link>
                </div>

                <!-- <div v-if="! hasPaidRegistration && ! isLoadingBlade">
                    <hr>
                    <h3 class="text-center text-danger">
                        Note you can skip if you make payment and added it online already by clicking on 
                        <em class="text-success">Dashboard Now</em> button below
                    </h3>
                </div> -->  


                <!-- Import Registration payment blade here -->
                <RegistrationPayment
                    :application="application.application"
                    :user="application.user"
                    :serviceType="application.service_type"
                    :hasPreviousApplication="hasPreviousApplication"
                    :previousApplications="application.previous_applications"
                    v-on:addPayment="addPayment"
                    :loadApplication="loadApplication"
                    :displayNewPayment="displayNewPayment"
                    :changeAppId="changeAppId"
                    :applicationType="applicationType"
                    :totalAmount="totalAmount"
                    :getTempRegUrl="getTempRegUrl"
                    v-if="((application && application.previous_applications &&
                        application.previous_applications.length == 0) ||
                        showNewPayment) && ! showAddPayment && ! hasPaidRegistration && ! isLoadingBlade"
                />

                <AddPayment
                    :orderNumber="orderNumber"
                    :application="getApplication"
                    :loadApplication="loadApplication"
                    :app_id="applicationId"
                    :serviceType="serviceType"
                    :totalAmount="totalAmount"
                    :removeAddPayment="removeAddPayment"
                    v-if="! hasPaidRegistration && showAddPayment && ! isLoadingBlade"
                />

                <div class="col-sm-12 text-center">
                    <base-alert :type="errorType" v-if='showErrorStatus'>
                        <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                        <span class="alert-inner--text">
                            {{ errorMsg }}
                        </span>
                    </base-alert>
                </div>

                <div class="col-lg-12" v-if="hasPaidRegistration && ! isLoadingBlade">
                    <div class="d-flex justify-content-center">
                        <img :src="completeImageUrl" />

                    </div>
                    <br>
                    <h2 class="text-uppercase text-center">
                        You have completed the registration process
                    </h2>
                    <h3 class="text-center text-danger">
                        Kindly hit the submit button to submit your application for approval.
                    </h3>
                </div>
            </div>

            <div class="col-lg-12 text-center" v-if="isTempRenwal && ! isLoadingBlade">
                <hr>
                <div class="d-flex justify-content-center">
                    <img :src="completeImageUrl" />

                </div>
                <br>
                <router-link
                    :to="getTempRegUrl"
                    class="btn btn-lg btn-primary"
                >
                    <i class="fa fa-history"></i>
                    Renew License Now
                </router-link>
            </div>

            <hr class="my-4" v-if="! isLoadingBlade"/>

            <div class="pl-lg-4" v-if="! isLoadingBlade">
                <base-button
                    type="danger"
                    v-if="! hasPaidRegistration"
                    @click="showPreviousPage()"
                >
                    <i class="fa fa-arrow-left"></i>
                    Previous
                </base-button>
                <div class="right" v-if="hasPaidRegistration">
                    <base-button
                        type="success"
                        @click="submitNow(true)"
                    >
                        <i class="fa fa-save"></i>
                        Submit Now
                    </base-button>
                </div>

                <!-- <div class="right" v-if="! hasPaidRegistration">
                    <base-button
                        type="success"
                        @click="submitNow()"
                    >
                        <i class="fa fa-save"></i>
                        Dashboard Now
                    </base-button>
                </div> -->
            </div>

        </card>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'; //AddQualPayment
    import RegistrationPayment from './Registration/RegistrationPayment.vue';
    import RegistrationPaymentData from '../../NewAccount/RegistrationPaymentData.vue';
    import AddPayment from './Registration/AddPayment.vue';
    import swal from 'sweetalert2';// import sweet alert
    import moment from 'moment';

    export default {
        components: {
            RegistrationPayment,
            RegistrationPaymentData,
            AddPayment,
        },
        name: 'Registration',
        props: ['displayVerifyCountry'],
        data() {
            return {
                id: this.$route.params.id,
                application: null,
                applicationType: null,
                isLoadingBlade: true,
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isloading: false,
                showNewPayment: false,
                showAddPayment: false,
                orderNumber: null,
                selectedAppId: null,
                serviceType: null
            }
        },
        filters: {
            formatPrice(value) {
                if(value) {
                    let val = (value/1).toFixed(2).replace(',', '.');
                    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                } else {
                    return 'N/A';
                }
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl', 'completeImageUrl', 'user']),
            hasPaidRegistration() {
                return this.application ? this.application.has_paid : false;
            },
            hasPreviousApplication() {
                return this.application && this.application.previous_applications &&
                    this.application.previous_applications.length > 0
            },
            applicationId() {// one previous application id
                if(this.selectedAppId) {// get exact application type
                    return this.selectedAppId;
                } else {
                    return this.application && this.application.previous_applications && this.application.previous_applications.length > 0 ?
                        this.application.previous_applications[0].id : null
                }
            },
            getApplication() {
                return this.application.application;
            },
            getPostageFee() {
                let fees = this.getApplication.temp_postage_fees;
                if(fees) {
                    let amount = (fees.medical_school ? parseInt(fees.medical_school.postage_fee) : 0);
                    amount += (fees.aq_country ? parseInt(fees.aq_country.postage_fee) : 0);
                    return amount;
                }
                return 0;
            },
            totalAmount() {// get total amount
                let amount = parseInt(this.getApplication.has_payment.amount);
                return this.getPostageFee ? (parseInt(this.getPostageFee) + amount) : amount;
            },
            getTempRegUrl() {// get exact temp renewal url
                let code = this.applicationType ? this.applicationType.code : false;
                return code ? '/renew-temp/'+ code + '-renewal' : '/';
            },
            isTempRenwal() {// is temp reg renewal
                let profile = this.user.basic_profile;
                return (profile.is_temp_renewal == 'yes');
            }
        },
        methods: {
            ...mapActions(['getUserRegistrationDetails', 'saveApplicationTransaction',
                'getRemitaOrderPaymentStatus', 'submitApplication']),
            changeAppId(id, type, serviceCode) {// change application id
                this.selectedAppId = id;
                this.applicationType = type;
                this.serviceType = serviceCode;
            },
            displayNewPayment(value) {// show new payment blade
                return this.showNewPayment = value;
            },
            removeAddPayment() {// remove add payment
                return this.showAddPayment = false;
            },
            showPreviousPage() {// go to next page
                return this.displayVerifyCountry(true, true);
            },
            showNewPaymentBlade() {// show new payemnt template
                this.showNewPayment = ! this.showNewPayment;
            },
            submitNow(status = false) {// submit application
                this.preloader();// show loading
                this.submitApplication().then((res) => {
                    if(res.status) {
                        let msg = status ? 'Application has been submitted for approval' : 'Profile has been updated successfully';
                        swal.fire('Account Updated', msg, 'success');
                        return window.location.href='/';
                    } else {
                        let msg = 'An error occured while submitting application, try again';
                        swal.fire('Error Occurred!', msg, 'warning');
                        this.loadApplication();// load application
                    }
                }).
                catch(() => {
                    let msg = 'An error occured while submitting application, try again';
                    swal.fire('Error Occurred!', msg, 'warning');
                    this.loadApplication();// load application
                });
            },
            loadApplication() {// get application
                this.getUserRegistrationDetails().then((res) => {
                    this.application = res;
                    this.applicationType = res.application;
                    this.serviceType = res.service_type;
                    this.isLoadingBlade = false;
                }).
                catch(() => {
                    this.isLoadingBlade = false;
                    this.showErrorStatus = true;
                    this.errorMsg = "Network Failure!, kindly reload the page...";
                    this.errorType = 'danger';
                });
            },
            reQueryTransaction(number) {
                this.preloader();// show loading
                this.getRemitaOrderPaymentStatus(number).then((res) => {// save transaction
                    if(res.status) {
                        swal.fire('Payment Successful', res.msg, 'success');
                    } else {
                        swal.fire('Payment Failed', res.msg, 'warning');
                    }
                    this.loadApplication();// load application
                }).
                catch(() => {
                    let message = 'Network Failure, kindly reload page and try again';
                    swal.fire('Error ocurred', message, 'warning');
                    this.loadApplication();// load application
                });
            },
            addPayment(number) {// add RRR
                this.orderNumber = number;
                this.showAddPayment = true;
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        created() {
            this.loadApplication();// load application
        },
    }
</script>

<style scoped>
.right {
    float: right;
}
</style>