<template>
    <div>
        <div class="row">
            <div class="col-lg-12">
                <h3 class="mb-0 text-uppercase">Personal Details</h3>
                <hr class="my-4" />
            </div>

            <div class="col-lg-12 text-right">
                <img :src="photo" class="photo-class"/>
                <br><br>
            </div>

            <div class="table-responsive">

                <table class="table table-bordered">
                    <tbody>
                        <td>
                            <h4>RRR</h4>
                        </td>
                        <td colspan="5">
                            <h4 class="text-capitalize font-weight-light">
                                {{ rrr ? rrr : 'N/A' }}
                            </h4>
                        </td>
                    </tbody>
                    <tbody>
                        <td>
                            <h4>Surname</h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ personal.surname ? personal.surname : 'N/A' }}
                            </h4>
                        </td>
                        <td>
                            <h4>First Name</h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ personal.first_name ? personal.first_name : 'N/A' }}
                            </h4>
                        </td>
                        <td>
                            <h4>Other Name</h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ personal.other_name ? personal.other_name : 'N/A' }}
                            </h4>
                        </td>
                    </tbody>
                    <tbody>
                        <td>
                            <h4>Previous Name</h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ personal.previous_name ? personal.previous_name : 'N/A' }}
                            </h4>
                        </td>
                        <td>
                            <h4>Gender</h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ personal.gender ? personal.gender : 'N/A' }}
                            </h4>
                        </td>
                        <td>
                            <h4>Marital Status</h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ personal.marital_status ? personal.marital_status : 'N/A' }}
                            </h4>
                        </td>
                    </tbody>
                    <tbody>
                        <td>
                            <h4>Date of Birth</h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ personal.date_of_birth | getDateFormat }}
                            </h4>
                        </td>
                        <td>
                            <h4>Place of Birth</h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ personal.place_of_birth ? personal.place_of_birth : 'N/A' }}
                            </h4>
                        </td>
                        <td>
                            <h4>Nationality</h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ personal.has_country ? personal.has_country.name : 'N/A' }}
                            </h4>
                        </td>
                    </tbody>
                    <tbody>
                        <td v-if="personal.nationality == 164">
                            <h4>State of Origin</h4>
                        </td>
                        <td v-if="personal.nationality == 164">
                            <h4 class="text-capitalize font-weight-light">
                                {{ personal.has_state ? personal.has_state.name : 'N/A' }}
                            </h4>
                        </td>
                        <td v-if="personal.nationality == 164">
                            <h4>LGA of Origin</h4>
                        </td>
                        <td v-if="personal.nationality == 164">
                            <h4 class="text-capitalize font-weight-light">
                                {{ personal.has_lga ? personal.has_lga.name : 'N/A' }}
                            </h4>
                        </td>
                        <td v-if="personal.nationality != 164">
                            <h4>State / Province</h4>
                        </td>
                        <td v-if="personal.nationality != 164">
                            <h4 class="text-capitalize font-weight-light">
                                {{ personal.foreign_state ? personal.foreign_state : 'N/A' }}
                            </h4>
                        </td>
                        <td>
                            <h4>Home Town</h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ personal.home_town ? personal.home_town : 'N/A' }}
                            </h4>
                        </td>
                    </tbody>
                    <tbody>
                        <td>
                            <h4>Permanent Home Address</h4>
                        </td>
                        <td colspan="5">
                            <h4 class="text-capitalize font-weight-light">
                                {{ personal.permanent_address ? personal.permanent_address : 'N/A' }}
                            </h4>
                        </td>
                    </tbody>
                    <tbody>
                        <td>
                            <h4>Residential Address in Nigeria</h4>
                        </td>
                        <td colspan="5">
                            <h4 class="text-capitalize font-weight-light">
                                {{ personal.residential_address ? personal.residential_address : 'N/A' }}
                            </h4>
                        </td>
                    </tbody>
                    <tbody v-if="personal.business_address">
                        <td>
                            <h4>Full Business Address</h4>
                        </td>
                        <td colspan="5">
                            <h4 class="text-capitalize font-weight-light">
                                {{ personal.business_address ? personal.business_address : 'N/A' }}
                            </h4>
                        </td>
                    </tbody>
                    <tbody>
                        <td>
                            <h4>Contact Address</h4>
                        </td>
                        <td colspan="5">
                            <h4 class="text-capitalize font-weight-light">
                                {{ personal.contact_address ? personal.contact_address : 'N/A' }}
                            </h4>
                        </td>
                    </tbody>
                    <tbody>
                        <td v-if="personal.nationality == 164">
                            <h4>Date of Return to Nigeria</h4>
                        </td>
                        <td v-if="personal.nationality == 164">
                            <h4 class="text-capitalize font-weight-light">
                                {{ personal.return_date | getDateFormat }}
                            </h4>
                        </td>
                        <td v-if="personal.nationality != 164">
                            <h4>Date of Entry into Nigeria</h4>
                        </td>
                        <td v-if="personal.nationality != 164">
                            <h4 class="text-capitalize font-weight-light">
                                {{ personal.entry_date | getDateFormat }}
                            </h4>
                        </td>
                        <td>
                            <h4>Phone Number</h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ personal.phone ? personal.phone : 'N/A' }}
                            </h4>
                        </td>
                        <td>
                            <h4>Email Address</h4>
                        </td>
                        <td>
                            <h4 class="font-weight-light">
                                {{ personal.email ? personal.email : 'N/A' }}
                            </h4>
                        </td>
                    </tbody>
                </table>
            </div>
            <div class="col-lg-12">
                <hr class="my-4" />
            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';

    export default {
        name: 'PersonalDetails',
        props: [ 'personal', 'rrr', 'photo' ],
        filters: {
            getDateFormat(value) {
                return value ? moment(value).format('Do MMM, YYYY') : 'N/A';
            }
        }
    }
</script>

<style scoped>
    .no-padding-bottom {
        padding-bottom: 0rem !important
    }
    .right {
        float: right !important;
    }
    .photo-class {
        width: 200px;
        height: 200px;
    }
</style>

<style>
    .form-control-label {
        width: 100%;
    }
    .height-50 {
        height: 50px;
    }
    .borderLine {
        border: 1px solid #ededed;
    }
</style>