<template>
    <div>
        <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center no-padding-bottom">
            <span class="mask bg-gradient-danger opacity-8"></span>
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col-xl-12 order-xl-1">
                    <br><br><br>
                    <card shadow type="secondary">
                        <div slot="header" class="bg-white border-0">
                            <div class="row align-items-center">

                                <div class="col-10">
                                    <h3 class="mb-0 text-uppercase">{{ this.$route.name }}</h3>
                                    <br>
                                    <span class="text-danger">
                                        Kindly fill the form below to create a new account, after successful submission
                                        you will wait for approval by the MDCN before proceeding to complete registration...
                                    </span>
                                </div>
                                <div class="col-2 text-right">
                                    <router-link
                                        to="/register"
                                        class="btn btn-sm btn-danger"
                                    >
                                        <i class="fa fa-arrow-left"></i>
                                        Go Back
                                    </router-link>
                                </div>
                            </div>
                        </div>

                        <div class="row" v-if="isLoadingBlade && ! showErrorStatus">
                            <div class="col-lg-12">
                                <div class="d-flex justify-content-center">
                                    <img :src="loadingBladeUrl" />
                                </div>
                            </div>
                        </div>

                        <div class="row" v-if="! isLoadingBlade">

                            <div class="col-lg-3">
                                <base-input alternative=""
                                    label="Verification RRR Number"
                                    input-classes="form-control-alternative"
                                    v-model="record.rrr.RRR"
                                    readonly
                                />
                            </div>

                            <div class="col-lg-3">
                                <base-input alternative=""
                                    label="First Name"
                                    placeholder="Enter First Name"
                                    input-classes="form-control-alternative"
                                    v-model="record.first_name"
                                    required
                                />
                            </div>

                            <div class="col-lg-3">
                                <base-input alternative=""
                                    label="Last Name (Surname)"
                                    placeholder="Enter Last Name (Surname)"
                                    input-classes="form-control-alternative"
                                    v-model="record.last_name"
                                    required
                                />
                            </div>

                            <div class="col-lg-3">
                                <base-input alternative=""
                                    label="Other Names"
                                    placeholder="Enter Other Names"
                                    input-classes="form-control-alternative"
                                    v-model="record.other_name"
                                />
                            </div>

                            <div class="col-lg-3">
                                <base-input alternative=""
                                    label="Email Address"
                                    placeholder="Enter  Valid Email Address"
                                    input-classes="form-control-alternative"
                                    v-model="record.email"
                                    required
                                />
                            </div>

                            <div class="col-lg-3">
                                <base-input alternative=""
                                    label="Phone Number"
                                    placeholder="Phone Number"
                                    input-classes="form-control-alternative"
                                >
                                <!-- v-model="profile.phone"  -->
                                    <VuePhoneNumberInput
                                        v-model="tel"
                                        @update="updatePhoneNumber"
                                        default-country-code="NG"
                                        :no-example="true"
                                        :clearable="true"
                                    />
                                </base-input>
                            </div>

                            <div class="col-lg-3">
                                <base-input alternative=""
                                    label="Practice Type"
                                    required
                                >
                                    <select
                                        class="form-control form-control-alternative"
                                        v-model="record.doctor_type_id"
                                    >
                                        <option :value="null" disabled selected>--- Select One ---</option>
                                        <option value="1">Medical Registration</option>
                                        <option value="2">Dental Registration</option>
                                        <!-- <option value="3">Alternative Registration</option> -->
                                    </select>
                                </base-input>
                            </div>

                            <div class="col-lg-3">
                                <base-input alternative=""
                                    label="Registration Duration Type"
                                    required
                                >
                                    <select
                                        class="form-control form-control-alternative"
                                        v-model="record.sub_registration_type_id"
                                    >
                                        <option :value="null" disabled selected>--- Select Registration Duration Type ---</option>
                                        <option value="7">Temporary Registration (Short Stay - less than 3 months)</option>
                                <option value="8">Temporary Registration (Long Stay - above 3 months to 2 years)</option>
                                    </select>
                                </base-input>
                            </div>

                            <div class="col-lg-12">
                                <hr>
                                <h4 class="heading text-muted mb-4">
                                    <!-- Upload Files &nbsp;&nbsp;&nbsp;&nbsp; -->
                                    <span class="text-danger">
                                        <i class="fa fa-info-circle"></i> Only PDF Files (Not More than 300KB) are supported for each file
                                    </span>            
                                </h4>
                            </div>

                            <div
                                class="col-lg-6 text-center borderLine"
                                v-for="(document, index) in documents"
                                :key="index"
                            >
                                <br>
                                <input
                                    type="file"
                                    id="file" style="display: none"
                                    @change="onFileSelected"
                                >
                                <h4 class="height-50">
                                    {{ document.name }}
                                </h4><br>
                                <base-button
                                    size="sm" type="primary"
                                    v-if="(document.filename =='basic_medical_dental_certificate.pdf')"
                                    @click="selectFile(document.filename)"
                                >
                                    <i class="fa fa-hand-pointer"></i>
                                    {{ record.files.certificate_file.status ? 'Change File' : 'Select File' }}
                                </base-button>

                                <base-button
                                    size="sm" type="primary"
                                    v-if="(document.filename =='additional_specialist_certificate.pdf')"
                                    @click="selectFile(document.filename)"
                                >
                                    <i class="fa fa-hand-pointer"></i>
                                    {{ record.files.registration_file.status ? 'Change File' : 'Select File' }}
                                </base-button>

                                <base-button
                                    size="sm" type="danger"
                                    v-if="(record.files.registration_file.name == document.filename 
                                        || record.files.certificate_file.name == document.filename)
                                        && (record.files.registration_file.name 
                                            || record.files.certificate_file.name)"
                                    @click="removeFile(document.filename)"
                                >
                                    <i class="fa fa-times"></i> Remove File
                                </base-button>
                                <br><br>

                            </div>

                            <div class="col-lg-12 text-center" v-if='showErrorStatus'>
                                <base-alert :type="errorType">
                                    <!-- <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span> -->
                                    <span class="alert-inner--text">
                                        <h2 class="text-white">{{errorMsg}}</h2>
                                    </span>
                                </base-alert>
                            </div>

                            <div class="col-lg-12 text-center" v-if="! isNotRobot">
                                <vue-recaptcha
                                    :sitekey="recaptchaKey"
                                    :loadRecaptchaScript="true"
                                    @verify="proceedSubmit"
                                ></vue-recaptcha>
                            </div>

                        </div>

                        <div class="col-lg-12" v-if="! isLoadingBlade">
                            <hr>
                            <router-link
                                to="/temp-doctor-register"
                                class="btn btn-danger"
                            >
                                <i class="fa fa-arrow-left"></i>
                                Go Back
                            </router-link>

                            <base-button
                                type="success"
                                class="right"
                                @click="createAccount()"
                                :disabled="! isNotRobot"
                            >
                                {{ isloading ? 'Please Wait...' : 'Proceed Now'}}
                                <i class="fa fa-arrow-right"></i>
                            </base-button>
                        </div>
                        <br><br><br>
                    </card>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import VueRecaptcha from 'vue-recaptcha';
    import VuePhoneNumberInput from 'vue-phone-number-input';
    import 'vue-phone-number-input/dist/vue-phone-number-input.css';
    import swal from 'sweetalert2';// import sweet alert

    export default {
        name: 'TempDoctorSignup',
        components: {
            VueRecaptcha,
            VuePhoneNumberInput
        },
        data() {
            return {
                isLoadingBlade: true,
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                rrr: this.$route.query.rrr,
                record: {
                    first_name: null,
                    last_name: null,
                    other_name: null,
                    email: null,
                    rrr: {},
                    doctor_type_id: null,
                    sub_registration_type_id: null,
                    phone: null,
                    phone_code: null,
                    files: {
                        certificate_file: {
                            name: null,
                            file: null,
                            status: false
                        },
                        registration_file: {
                            name: null,
                            file: null,
                            status: false
                        },
                    }
                },
                selectedFileName: null,
                tel: null,
                isNotRobot: true,
                isloading: false,
                selectedCert: false,
                selectedRegCert: false,
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl', 'recaptchaKey']),
            documents() {
                return [
                    {
                        'name' : 'Basic Medical / Dental Certificate',
                        'filename' : 'basic_medical_dental_certificate.pdf'
                    },
                    {
                        'name' : 'Additional Specialist Certificate',
                        'filename' : 'additional_specialist_certificate.pdf'
                    }
                ];
            },
        },
        methods: {
            ...mapActions(['getRemitaPaymentStatus', 'createTempDocSignup']),
            updatePhoneNumber(event) {
                if(event && event.isValid && event.e164) {
                    this.record.phone = event.formattedNumber;
                    this.record.phone_code = event.countryCode+'-'+event.phoneNumber;
                }
            },
            onFileSelected(event) {
                let status =  this.validateFile(event.target.files[0]);
                var fileReader = new FileReader();
                fileReader.readAsDataURL(event.target.files[0]);
                fileReader.onload = (event) => {// convert file to binary
                   (this.selectedFileName == 'basic_medical_dental_certificate.pdf') ?
                   this.record.files.certificate_file.file = event.target.result :
                    this.record.files.registration_file.file = event.target.result;
                }
                return status;
            },
            validateFile(file) {// check for valid file 
                if(!file) {// update seleted file name
                    (this.selectedFileName == 'basic_medical_dental_certificate.pdf') ?
                    this.record.files.certificate_file.name = null 
                    : this.record.files.registration_file.name = null;
                }
                var fileExt = file.type.split('/').pop();
                let fileSize = file.size;
                if(fileExt == 'pdf' && fileSize <= 300000) {// for valid file and size
                    if(this.selectedFileName == 'basic_medical_dental_certificate.pdf') {
                            this.selectedCert = true
                    } else {
                        this.selectedRegCert = true
                    }
                    return true;
                } else if(fileExt != 'pdf') {// invalid file
                    if(this.selectedFileName == 'basic_medical_dental_certificate.pdf') {
                        this.record.files.certificate_file.name = null;
                        this.selectedCert = false
                    } else {
                        this.record.files.registration_file.name = null;
                        this.selectedRegCert = false
                    }
                    swal.fire("Invalid File!", "Only PDF files are accepted", "warning");
                    return false;
                } else if(fileSize > 300000) {// big file
                    if(this.selectedFileName == 'basic_medical_dental_certificate.pdf') {
                        this.record.files.certificate_file.name = null;
                        this.selectedCert = false
                    } else {
                        this.record.files.registration_file.name = null;
                        this.selectedRegCert = false
                    }
                    swal.fire("File is too large!", "File should be less than 300KB", "warning");
                    return false;
                }
                    
            },
            selectFile(name) {// eslint-disable-next-line
                $('#file').val('');
                this.selectedFileName = null;// eslint-disable-next-line
                $('#file').click();
                if(name == 'basic_medical_dental_certificate.pdf') {
                    this.record.files.certificate_file.name = name;
                    this.record.files.certificate_file.status = true;
                } else {
                    this.record.files.registration_file.name = name;
                    this.record.files.registration_file.status = true;
                }
                this.selectedFileName = name;
            },
            removeFile(name) {// eslint-disable-next-line
                $('#file').val('');
                if(name == 'basic_medical_dental_certificate.pdf') {
                    this.record.files.certificate_file = {
                        name: null,
                        file: null,
                        status: false
                    };
                    this.selectedCert = false;
                } else {
                    this.record.files.registration_file = {
                        name: null,
                        file: null,
                        status: false
                    };
                    this.selectedRegCert = false;
                }
            },
            loadForm() {// load Form
                if(this.rrr) {
                    this.getRemitaPaymentStatus({'rrr': this.rrr, code : 'temp-verify-reg'}).then((res) => {
                        this.isLoadingBlade = false;
                        if(res.status) {
                            this.record.rrr = res.details;
                            this.record.rrr.RRR = (res.details.RRR ? res.details.RRR : res.details.rrr);
                        } else {
                            swal.fire("Error Ocurred!", res.msg, "warning");
                            setTimeout(function() { window.location.href = '/temp-doctor-register'; }, 1000);
                        }
                    }).
                    catch(() => {
                        let msg = "Network Failure!, kindly reload the page...";
                        swal.fire("Error Ocurred!", msg, "warning");
                        setTimeout(function() { window.location.href = '/temp-doctor-register'; }, 1000);
                    });
                } else {
                    let msg = "Invalid RRR number was provided, try again...";
                    swal.fire("Invalid RRR!", msg, "warning");
                    setTimeout(function() { window.location.href = '/temp-doctor-register'; }, 1000);
                }
            },
            createAccount() {
                let status = this.validatedInputs();
                if(status && this.isNotRobot) {
                    this.preloader(); // show loader
                    this.isLoading = true;
                    this.record.is_temp_renewal = 'no';
                    this.createTempDocSignup(this.record).then((res) => {
                        this.isLoadingBlade = false;
                        if(res.status) {// store token and login user
                            swal.fire("Successful!", res.msg, "success");
                            setTimeout(function() { window.location.href = '/doctor-login'; }, 5000);
                            // return window.location.href = '/doctor-login';
                        } else {//show error
                            swal.fire("Error Occurred!", res.msg, "warning");
                        }
                    }).
                    catch(() => {
                        let msg = "Network Failure!, kindly reload the page...";
                        swal.fire("Error Ocurred!", msg, "warning");
                    });
                }
            },
            validatedInputs() {
                let data = this.record;
                let record = { first_name: null, last_name: null, email: null, doctor_type_id: null,
                    sub_registration_type_id: null };
                let testEmail = false;
                let certFile = false;
                let regFile = false;
                regFile = (this.record.files.registration_file.file == null) ?
                    this.showInuptError('Additional Specialist Certificate') : true;
                certFile = (this.record.files.certificate_file.file == null) ?
                    this.showInuptError('Basic Medical / Dental Certificate') : true;                
                record.sub_registration_type_id  = data.sub_registration_type_id == null ?
                    this.showInuptError('Registration Duration Type') : data.sub_registration_type_id;
                record.doctor_type_id  = data.doctor_type_id == null ?
                    this.showInuptError('Practice Type') : data.doctor_type_id;
                testEmail = this.validEmail(data.email) ? true : this.showInuptError('Email Address');
                record.email  = data.email == null ?
                    this.showInuptError('Email Address') : data.email;
                record.last_name  = data.last_name == null ?
                    this.showInuptError('Last Name') : data.last_name;
                record.first_name  = data.first_name == null ?
                    this.showInuptError('First Name') : data.first_name;
                return this.isEmpty(record) && testEmail && certFile && regFile ? true : false;
            },
            isEmpty(obj) {// check for empty field
                return ! Object.keys(obj).some(k => !obj[k]);
            },
            validEmail(email) {// eslint-disable-next-line
                var regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return regEmail.test(email);
            },
            showInuptError(name) {
                this.isloading = false;
                this.showErrorStatus = true
                this.errorType = 'warning'
                this.errorMsg = 'Invalid ' + name + ' has been entered';
                var self = this;
                setTimeout(function() { self.showErrorStatus = false; }, 5000);
            },
            proceedSubmit(res) {
                if(res) {
                    return setTimeout(() => {
                        this.isNotRobot = ! this.isNotRobot;
                    }, 1000);
                } else {
                    swal.fire("Error Occurred", "An error occurred while verifying, kindly try again", "warning");
                }
            },
            preloader() {
                return  swal.fire({
                    title: 'Creating account...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        created() {
            this.loadForm();
        }
    }
</script>

<style scoped>
    .no-padding-bottom {
        padding-bottom: 0rem !important
    }
    .right {
        float: right;
    }
</style>

<style>
    .form-control-label {
        width: 100%;
    }
    .height-50 {
        height: 50px;
    }
    .borderLine {
        border: 1px solid #ededed;
    }
</style>