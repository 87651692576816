<template>
    <div class="row" v-if="! showAddRecord && appRecords && appRecords.length > 0">
        <div class="col-lg-12">
            <div class="card shadow">
                <div class="table-responsive">
                    <base-table
                        class="table align-items-center table-flush"
                        thead-classes="thead-light"
                        tbody-classes="list"
                        :data="appRecords"
                    >
                        <template slot="columns">
                            <th width="2%">S/N</th>
                            <th width="10%">{{ assessment ? 'Employer\'s' : 'Hospital' }} Name</th>
                            <th width="20%">{{ assessment ? 'Employer\'s' : 'Hospital' }} Address</th>
                            <th width="10%">Start Date</th>
                            <th width="10%">End Date</th>
                            <th width="5%">&nbsp;</th>
                        </template>

                        <template
                            slot-scope="{row, index}"
                        >
                        <td class="budget">
                            {{ pagination.from+index+1}}
                        </td>
                        <td class="text-capitalize">
                            {{ assessment ? row.employer_name :  row.hospital_name}}
                            <br>
                            <badge
                                type="success"
                                v-if="row.current_status == 'yes'"
                            >
                                Current Appointment
                            </badge>
                            <badge
                                type="danger"
                                v-if="row.current_status == 'no'"
                            >
                                Past Appointment
                            </badge>
                        </td>
                        <td>
                            {{ assessment ? row.employer_address : row.hospital_address }}
                        </td>
                        <td>
                            {{row.start_date | getDateFormat}}
                        </td>
                        <td
                            v-if="row.current_status == 'no'"
                        >
                            {{row.end_date | getDateFormat}}
                        </td>
                        <td
                            v-if="row.current_status == 'yes'"
                        >
                            Till Date
                        </td>
                        <td>
                            <router-link
                                :to="'/appointment-posting/'+row.id"
                                class="btn btn-sm btn-success"
                                v-if="! newAccount"
                            >
                                <i class="fa fa-eye"></i> View Posting
                            </router-link>
                            <base-button
                                type="danger" size="sm"
                                :disabled="type ? false : lock"
                                @click="$emit('deleteAppRecord', row.id)"
                            >
                                <i class="fa fa-trash"></i> Remove
                            </base-button>
                        </td>

                        </template>

                    </base-table>
                </div>

                <div
                    class="card-footer d-flex justify-content-end bg-transparent"
                    v-if="hasPagination"
                     @click="paginate"
                >
                    <base-pagination
                        :total="pagination.total"
                        align="center"
                        v-model="pagination.number"
                        :perPage="pagination.perPage"

                    >
                    </base-pagination>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import moment from 'moment';

    export default {
        name: 'AppointmentData',
        props: ["appRecords", "showAddRecord", "pagination", "hasPagination", "paginate", 'assessment',
            "newAccount", "type"],
        filters: {
            getDateFormat(value) {
                return moment(value).format('MMM, YYYY');
            }
        },
        computed: {
            ...mapGetters(['lock'])
        }
    }
</script>