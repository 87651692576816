<template>
    <div>
        <div v-if="! showPaymentPage && ! showAddRrrPage">
            <div class="row" v-if='! isLoadingBlade'>

                <div class="col-lg-12 text-center text-uppercase">
                    <span class="text-danger">
                        Please note that you have an additional payment of 
                        <em class="text-primary">{{ payment.amount | formatPrice }}</em>. 
                        Kindly click on <em class="text-success">pay now</em> button, or if you have made the additional payment already and you have the valid RRR, 
                        click on <em class="text-primary">I have valid RRR</em> button
                    </span>
                    <hr>
                </div>

                <div class="col-lg-6">
                    <base-input alternative=""
                        label="Payee's Full Name"
                        placeholder="Payee's Full Name"
                        input-classes="form-control-alternative"
                        v-model="record.name"
                        required
                    />
                </div>

                <div class="col-lg-6">
                    <base-input alternative=""
                        label="Payee's Email Address"
                        placeholder="Payee's Email Address"
                        input-classes="form-control-alternative"
                        v-model="record.email"
                        required
                    />
                </div>

                <div class="col-lg-6">
                    <base-input alternative=""
                        label="Payee's Phone Number"
                        placeholder="Payee's Phone Number"
                        input-classes="form-control-alternative"
                    >
                    <!-- v-model="profile.phone"  -->
                        <VuePhoneNumberInput
                            v-model="tel"
                            @update="updatePhoneNumber"
                            default-country-code="NG"
                            :no-example="true"
                            :clearable="true"
                        />
                    </base-input>
                </div>

            </div>

            <div class="col-lg-12" v-if="isLoadingBlade">
                <div class="d-flex justify-content-center">
                    <img :src="loadingBladeUrl" />
                </div>
            </div>

            <div class="col-lg-12 text-center" v-if='showErrorStatus && ! isLoadingBlade'>
                <base-alert :type="errorType">
                    <!-- <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span> -->
                    <span class="alert-inner--text">
                        <h2 class="text-white">{{errorMsg}}</h2>
                    </span>
                </base-alert>
            </div>
        </div>

        <IncompletePaymentData
            :type="type"
            :record="record"
            :serviceType="payment.service_type"
            :totalAmount="payment.amount"
            :loadAssessmentBatch="loadAssessmentBatch"
            v-if="showPaymentPage && ! showAddRrrPage"
        />

        <div v-if="! showErrorStatus && ! showPaymentPage && ! showAddRrrPage">
            <hr>
            <base-button
                type="primary"
                @click="displayAddRrrPage()"
            >
                <i class="fa fa-save"></i>
                I Have Valid RRR
            </base-button>


            <base-button
                type="success"
                class="right"
                :disabled="isLoadingBlade"
                @click="getPayments()"
            >
                {{ isLoadingBlade ? 'Please Wait...' : 'Pay Now'}}
                <i class="fa fa-arrow-right"></i>
            </base-button>
            <br><br><br><br>
        </div>

        <AddIncompletePaymentRrr
            :loadAssessmentBatch="loadAssessmentBatch"
            v-on:displayAddRrrPage="displayAddRrrPage"
            v-if="! showPaymentPage && showAddRrrPage"
        />

    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import IncompletePaymentData from './IncompletePaymentData';
    import AddIncompletePaymentRrr from './AddIncompletePaymentRrr.vue';
    import VuePhoneNumberInput from 'vue-phone-number-input';
    import 'vue-phone-number-input/dist/vue-phone-number-input.css';
    import swal from 'sweetalert2';// import sweet alert

    export default {
        name: 'IncompletePayment',
        props: ['payment', 'batch', 'category', 'loadAssessmentBatch'],
        components: {
            IncompletePaymentData,
            AddIncompletePaymentRrr,
            VuePhoneNumberInput
        },
        filters: {
            formatPrice(value) {
                if(value) {
                    let val = (value/1).toFixed(2).replace(',', '.');
                    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' NGN';
                } else {
                    return 'N/A';
                }
            }
        },
        data() {
            return {
                isLoadingBlade: true,
                record: {
                    name: null,
                    email: null,
                    phone: null
                },
                tel: null,
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                showPaymentPage: false,
                showAddRrrPage: false
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl'])
        },
        methods: {
            ...mapActions(['getAssessmentTypes', 'getAssessmentBatch', 'getAssessmentPayment']),
            displayAddRrrPage() {
                this.showAddRrrPage = ! this.showAddRrrPage;
            },
            updatePhoneNumber(event) {
                if(event && event.isValid && event.e164) {
                    this.record.phone = event.formattedNumber;
                }
            },
            getPayments() {
                this.isloading = true;
                this.preloader();// show loading
                let status = this.validatedData();// valid input data
                if(status && this.batch) {// update
                    let data = { id : this.batch.id, typeId : this.category };
                    this.getAssessmentPayment(data).then((res) => {
                        this.isloading = false;
                        if(res.status) {
                            this.type = res.type;
                            this.showPaymentPage = true;
                            swal.close()
                        } else {
                            let msg = "Payment details could not be found, reload page";
                            swal.fire("Error Ocurred!", msg, "warning");
                        }
                    }).
                    catch(() => {
                        this.isloading = false;
                        let msg = "Network Failure!, kindly reload the page...";
                        swal.fire("Error Ocurred!", msg, "warning");
                    });
                }   else {
                    this.isloading = false;
                    swal.close();
                }
            },
            emptyFields() {
                this.record = {
                    name: null,
                    email: null,
                    phone: null
                };
                this.isLoadingBlade = false;
            },
            validatedData() {
                let data = this.record;
                data.phone != null ? data.phone : this.showInuptError('Payees\'s Phone Number');
                data.email != null ? data.email : this.showInuptError('Payees\'s Email Address');
                data.name != null ? data.name : this.showInuptError('Payees\'s Full Name');
                return data.name && data.email && data.phone ? true : false;
            },
            showInuptError(name) {
                this.isloading = false;
                this.showErrorStatus = true
                this.errorType = 'warning'
                this.errorMsg = 'Invalid ' + name + ' has been entered';
                var self = this;
                setTimeout(function() { self.showErrorStatus = false; }, 5000);
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        created() {
            this.emptyFields();
        }
    }
</script>

<style scoped>
    .no-padding-bottom {
        padding-bottom: 0rem !important
    }
    .right {
        float: right;
    }
</style>

<style>
    .form-control-label {
        width: 100%;
    }
    .height-50 {
        height: 50px;
    }
    .borderLine {
        border: 1px solid #ededed;
    }
</style>