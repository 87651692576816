<template>
    <div class="pl-lg-4">

        <div class="col-lg-12" v-if="isLoadingBlade">
            <div class="d-flex justify-content-center">
                <img :src="loadingBladeUrl" />
            </div>
        </div>

        <div v-if="! isLoadingBlade">
            <h6 class="heading-small text-muted mb-4">
                Below are your educational information:
                <base-button
                    class="float-right"
                    :type="showAddRecord ? 'danger' : 'success'"
                    size="sm"
                    :disabled="lock"
                    @click="displayNewRecord()"
                >
                    <i class="fa fa-plus-circle" v-if="! showAddRecord"></i>
                    <i class="fa fa-times" v-if="showAddRecord"></i>
                    {{showAddRecord ? 'Cancel' : 'Add New Record' }}
                </base-button>
            </h6>

            <hr class="my-4" v-if="showAddRecord"/>

            <div class="row align-items-center" v-if="showAddRecord">
                <div class="col-lg-12">
                    <base-alert :type="errorType" dismissible v-if='showInputErrorStatus'>
                        <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                        <span class="alert-inner--text">
                            {{errorMsg}}
                        </span>
                        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </base-alert>
                </div>

                <div class="col-lg-4">
                    <base-input alternative=""
                        label="School Type"
                        required
                    >
                        <select
                            class="form-control form-control-alternative"
                            v-model="records.school_type"
                        >
                            <option
                                :value="null"
                                disabled
                            >
                                --- Select One ---
                            </option>
                            <option value="primary">Primary</option>
                            <option value="secondary">Secondary</option>
                            <option value="tertiary">Tertiary</option>
                            <option value="medical-school">Medical School</option>
                            <option value="pre-clinical-period">Pre-Clinical Period</option>
                            <option value="clinical-period">Clinical Period</option>
                        </select>
                    </base-input>
                </div>
                <div class="col-lg-4">
                    <base-input alternative=""
                        label="Country"
                        required
                    >
                        <autocomplete
                            v-if="locations && locations.countries && locations.countries.length > 0"
                            input-class="form-control form-control-alternative"
                            placeholder="Type Country"
                            showNoResults
                            :source="locations.countries"
                            v-model="records.country_id"
                            @selected="changeCountry"
                        >
                        </autocomplete>
                    </base-input>
                </div>

                <div class="col-lg-4" v-if="! isOtherSchool">
                    <base-input alternative=""
                        label="Name of Institution"
                        required
                    >
                        <autocomplete
                            input-class="form-control form-control-alternative"
                            placeholder="Type to Search Institution"
                            showNoResults
                            :disableInput="institutions && institutions.length == 0"
                            :source="institutions"
                            resultsValue="name"
                            @selected="changeInstitution"
                        >
                        </autocomplete>
                    </base-input>
                </div>

                <div class="col-lg-4" v-if="showOtherInstitution || isOtherSchool">
                    <base-input alternative=""
                        :label="(isOtherSchool ? 'Institution Name' : 'Other Institution Name')"
                        placeholder="Enter Institution Name"
                        input-classes="form-control-alternative"
                        v-model="records.other_school_name"
                        required
                    />
                </div>
                <div class="col-lg-4">
                    <base-input alternative=""
                        label="Qualifications Obtained"
                        required
                    >
                        <select
                            class="form-control form-control-alternative"
                            v-model="records.qualifications_obtained"
                            @change="changeOtherQualifications()"
                        >
                            <option
                                :value="null"
                                disabled
                            >
                                --- Select One ---
                            </option>
                            <option value="mbbs">MBBS</option>
                            <option value="bds">BDS</option>
                            <option value="others">Others (Please, specify)</option>
                        </select>
                    </base-input>
                </div>
                <div class="col-lg-4" v-if="showOtherQualifications">
                    <base-input alternative=""
                        label="Other Qualifications"
                        placeholder="Enter Other Qualification Name"
                        input-classes="form-control-alternative"
                        v-model="records.other_qualifications_obtained"
                        required
                    />
                </div>
                <div class="col-lg-4">
                    <base-input
                        label="Start Date"
                        addon-left-icon="ni ni-calendar-grid-58"
                        required

                    >
                        <flat-picker
                            slot-scope="{focus, blur}"
                            @on-open="focus"
                            @on-close="blur"
                            :config="config"
                            class="form-control datepicker"
                            v-model="records.start_date"
                        >
                        </flat-picker>
                    </base-input>
                </div>
                <div class="col-lg-4">
                    <base-input
                        label="End Date"
                        addon-left-icon="ni ni-calendar-grid-58"
                        required

                    >
                        <flat-picker
                            slot-scope="{focus, blur}"
                            @on-open="focus"
                            @on-close="blur"
                            :config="config"
                            class="form-control datepicker"
                            v-model="records.end_date"
                        >
                        </flat-picker>
                    </base-input>
                </div>
                <div class="col-lg-12"></div>
                <div class="pl-lg-4">
                    <base-button
                        type="success"
                        @click="saveEduRecords()"
                    >
                        <i class="fa fa-save"></i>
                        {{isloading ? 'Please Wait...' : 'Add Record'}}
                    </base-button>
                </div>
            </div>

            <!-- import edu records details template here -->
            <EduRecordsData
                v-bind:eduRecords="eduRecords"
                v-bind:showAddRecord="showAddRecord"
                v-on:deleteEduRecord="deleteEduRecord"
                v-bind:pagination="pagination"
                v-bind:hasPagination="hasPagination"
                v-bind:paginate="paginate"
            />

            <div class="col-sm-12 text-center">
                <base-alert :type="errorType" v-if='showErrorStatus'>
                    <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                    <span class="alert-inner--text">
                        {{errorMsg}}
                    </span>
                </base-alert>
            </div>
        </div>

    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import flatPicker from "vue-flatpickr-component";
    import "flatpickr/dist/flatpickr.css";
    import EduRecordsData from './EduRecordsData.vue';
    import swal from 'sweetalert2';// import sweet alert
    import Autocomplete from 'vuejs-auto-complete';
    // import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect/index';

    export default {
        components: {
            flatPicker,
            EduRecordsData,
            Autocomplete
        },
        name: 'EducationalDetails',
        data() {
            return {
                eduRecords: [],
                paginatedItems: [],
                records: {
                    school_type: null,
                    school_name: null,
                    school_id: null,
                    other_school_name: null,
                    qualifications_obtained: null,
                    country_id: null,
                    start_date: null,
                    end_date: null,
                    other_qualifications_obtained: null
                },
                pagination: {
                    total: null,
                    number: 1,
                    from: null,
                    perPage: this.$store.state.user.perPage // get per page count
                },
                institutions: [],
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isloading: false,
                showAddRecord: false,
                showOtherQualifications: false,
                showInputErrorStatus: false,
                showOtherInstitution: false,
                isLoadingBlade: true,
                config: {
                    maxDate: new Date().fp_incr(1),
                    altInput: true,// eslint-disable-next-line
                    plugins: [new monthSelectPlugin({shorthand: true, dateFormat: "Y-m-d", altFormat: "M Y"})]
                }
            };
        },
        computed: {
            ...mapGetters(['lock', 'locations', 'loadingBladeUrl']),
            hasPagination() {
                return this.paginatedItems && (this.paginatedItems.length > this.pagination.perPage)
                    ? true : false;
            },
            showOtherSchoolName() {
                return this.records.country && (this.records.country == 164) && this.records.school_type
                        && (this.records.school_type == 'medical-school') ? false : true;
            },
            isOtherSchool() {
                let type = this.records.school_type;
                return type && (type != 'medical-school') && (type != 'pre-clinical-period') &&
                            (type != 'clinical-period') && (type != 'tertiary');
            }
        },
        methods: {
            ...mapActions(['getUserEducationalRecords', 'getBasicProfile', 'saveUserEduRecords',
                    'deleteUserEduRecord', 'getLocations', 'getAssessmentInstitutions']),
            emptyEduRecordsMsg() {
                return this.eduRecords && this.eduRecords.length == 0 && (!this.showAddRecord)
                    ? this.showEmptyMsg() : false;
            },
            changeCountry(e) {// get exact institutions
                this.records.school_name = null,
                this.records.other_school_name = null,
                this.records.school_id = null;
                this.institutions = [];
                if(! this.isOtherSchool) {
                    this.preloader();
                    this.getAssessmentInstitutions({ id : e.value }).then((res) => {
                        swal.close();
                        this.institutions = res.schools
                        let other = { id : 0, name: 'Others (Specify)'};
                        this.institutions.push(other);
                    });
                }
            },
            changeInstitution(e) {
                let data = e.selectedObject;
                this.records.other_school_name = null;
                this.showOtherInstitution = false;
                this.records.school_id = data.id;
                if(data.id == 0) {
                    this.showOtherInstitution = true;
                } else {
                    this.records.school_name = data.name;
                }
            },
            showEmptyMsg() {// show empty msg
                this.showErrorStatus = true;
                this.errorType = 'warning';
                this.errorMsg = 'No Educational Records Yet!, kindly add one';
            },
            loadEducationalRecords() {
                this.getBasicProfile().then((res) => {// get user Id
                    const userId = res.id;
                    this.getUserEducationalRecords({'id' : userId}).then((res) => {// get edu records
                        this.setPages(res);
                        this.isLoadingBlade = false;
                        this.emptyEduRecordsMsg();//show empty msg
                    }).
                    catch(() => {
                        this.showErrorStatus = true;
                        this.isLoadingBlade = false;
                        this.errorMsg = "Network Failure!", "Kindly reload the page...";
                        this.errorType = 'danger';
                    });
                }).
                catch(() => {
                    this.showErrorStatus = true;
                    this.isLoadingBlade = false;
                    this.errorMsg = "Network Failure!", "Kindly reload the page...";
                    this.errorType = 'danger';
                });
                this.getLocations();// load locations
            },
            setPages(res) {// set paginated function
                this.eduRecords = [];
                this.paginatedItems = res;
                this.pagination.total = this.paginatedItems.length; // get items total
                let perPage = this.pagination.perPage; // get per page count
                if(this.paginatedItems.length > perPage) {// paginate
                    let numberOfPages = Math.ceil(this.paginatedItems.length / perPage);
                    for (let index = 1; index <= numberOfPages; index++) {
                        this.eduRecords.push(this.paginatedItems[index]);
                    }
                } else {
                    this.eduRecords = this.paginatedItems;
                }
            },
            paginate() {// paginate function
                this.eduRecords = [];
                this.pagination.from = null;
                let page = this.pagination.number;
                let perPage = this.pagination.perPage;
                let from = (page * perPage) - perPage;
                let to = (page * perPage);
                this.pagination.from = from;//get first item number
                return this.eduRecords = this.paginatedItems.slice(from, to);
            },
            emptyEduRecords() {
                this.records = {
                    school_type: null,
                    school_name: null,
                    school_id: null,
                    other_school_name: null,
                    qualifications_obtained: null,
                    country_id: null,
                    start_date: null,
                    end_date: null,
                    other_qualifications_obtained: null
                };
            },
            displayNewRecord() {// show new record
                this.emptyEduRecords();// empty edu records
                if(this.eduRecords && this.eduRecords.length == 0) {// hide empty msg
                    this.showEmptyMsg();
                    this.showErrorStatus = this.showAddRecord;
                }
                return this.showAddRecord = ! this.showAddRecord;
            },
            changeOtherQualifications() {// change other qualifications
                let value = this.records.qualifications_obtained;
                return this.showOtherQualifications = (value == 'others') ? true : false;
            },
            changeOtherInstitution() {// change other Institution
                let value = this.records.school_id;
                if(value) {// add school name
                    this.records.other_school_name = null;
                    for(let institution of this.institutions) {
                        if(institution.id == value) {
                            this.records.school_name = institution.school_name;
                            break;
                        }
                    }
                } else {
                    this.showOtherInstitution = true;
                    this.records.school_name = null;
                }
            },
            saveEduRecords() {// save new edu records
                this.isloading = true;
                let data = this.validatedInputs();// validate inputs
                if(data) {// save edu records
                    this.preloader();
                    data.user_data_id = this.$store.state.user.basicProfile.id;// get user id
                    data.school_id = this.records.school_id;
                    this.saveUserEduRecords(data).then((res) => {
                        swal.close();
                        this.isloading = false;
                        this.loadEducationalRecords();
                        if(res.status) {
                            this.showAddRecord = false;
                            this.showErrorStatus = false;
                        } else {
                            this.showErrorMsg();
                            this.emptyEduRecords();
                        }
                    }).
                    catch(() => {
                        swal.close();
                        this.isloading = false;
                        this.loadEducationalRecords();
                        this.emptyEduRecords();
                        this.showErrorMsg();
                    });
                } else {
                    swal.close();
                    this.isloading = false;
                    this.loadEducationalRecords();
                    this.emptyEduRecords();
                    this.showErrorMsg();
                }
            },
            showErrorMsg() {
                this.showInputErrorStatus = true
                this.errorType = 'warning'
                this.errorMsg = 'An error occurred, kindly reload page and try again';
                var self = this;
                setTimeout(function() { self.showInputErrorStatus = false; }, 5000);
            },
            validatedInputs() {
                let data = this.records;
                let eduRecord = { school_type: null, start_date: null, country_id: null,
                    end_date: null, school_name: null, qualifications_obtained: null };
                eduRecord.end_date  = data.end_date == null ? this.showInuptError('School\'s End Date') : data.end_date;
                eduRecord.start_date  = data.start_date == null ? this.showInuptError('School\'s Start Date') : data.start_date;
                // validate qualification obtained
                if((data.qualifications_obtained == null || data.qualifications_obtained == 'others')
                    && data.other_qualifications_obtained != null) {
                    eduRecord.qualifications_obtained = data.other_qualifications_obtained;
                } else if(data.qualifications_obtained != null && data.qualifications_obtained != 'others') {
                    eduRecord.qualifications_obtained = data.qualifications_obtained;
                } else if((data.qualifications_obtained == null || data.qualifications_obtained == 'others')
                    && data.other_qualifications_obtained == null) {
                    this.showInuptError('Qualification Obtained');
                }
                // validate institution obtained
                if((data.school_name == null || data.school_name == 'others')
                    && data.other_school_name != null) {
                    eduRecord.school_name = data.other_school_name;
                } else if(data.school_name != null && data.school_name != 'others') {
                    eduRecord.school_name = data.school_name;
                } else if((data.school_name == null || data.school_name == 'others')
                    && data.other_school_name == null) {
                    this.showInuptError('School\'s Name');
                }
                eduRecord.country_id  = data.country_id == null ? this.showInuptError('Country') : data.country_id;
                eduRecord.school_type = data.school_type == null ? this.showInuptError('School\'s Type') : data.school_type;
                return this.isEmpty(eduRecord) ? eduRecord : false;
            },
            isEmpty(obj) {// check for empty field
                return ! Object.keys(obj).some(k => !obj[k]);
            },
            showInuptError(name) {
                this.isloading = false;
                this.showInputErrorStatus = true
                this.errorType = 'warning'
                this.errorMsg = 'Invalid ' + name + ' has been entered';
                var self = this;
                setTimeout(function() { self.showInputErrorStatus = false; }, 5000);
            },
            deleteEduRecord(id) {// delete User EduRecord function
                this.preloader();// show loading
                this.deleteUserEduRecord({'id' : id}).then((res) => {
                    if(res.status) {
                        swal.close();
                        this.loadEducationalRecords(); // load EduRecords
                    } else {
                        swal.fire("Error Occurred!", "An error occurred while deleting, try again", "warning");
                        this.loadEducationalRecords(); // load EduRecords
                    }
                }).
                catch(() => {
                    swal.fire("Network Failure", "Reload page and try again...", "warning");
                    this.loadEducationalRecords(); // load EduRecords
                });
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b> ...',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        created() {
            this.loadEducationalRecords();// load educational records
        },
    }
</script>

<style scoped>
    .float-right {
        float: right;
    }
</style>

<style>
    .form-control-label {
        width: 100%;
    }
</style>