<template>
    <div>
        <hr class="my-4">

        <div class="col-lg-12" v-if="isLoadingBlade">
            <div class="d-flex justify-content-center">
                <img :src="loadingBladeUrl" />
            </div>
        </div>

        <div class="text-center" v-if="! showAddPayment">
            <h3 class="text-danger">
                Note: Old Doctors who already have Full Registration Certifcate
                can click on the Green button.
            </h3><br>
            <base-button
                type="primary"
                v-if="! isAlternativeReg"
                @click="loadExactApplication('full-reg')"
            >
                <i class="fa fa-credit-card"></i>
                Apply For Full Registration
            </base-button>

            <base-button
                type="primary"
                v-if="isAlternativeReg"
                @click="loadExactApplication('alt-registration')"
            >
                <i class="fa fa-credit-card"></i>
                Apply For Full Registration
            </base-button>

            <base-button
                type="success"
                v-if="! isAlternativeReg"
                @click="submitNow()"
            >
            <!-- @click="loadExactApplication('license')" -->
                <i class="ni ni-tv-2"></i>
                Go To Dashboard
            </base-button>

            <base-button
                type="success"
                v-if="isAlternativeReg"
                @click="submitNow()"
            >
            <!-- @click="loadExactApplication('alt-annual-license')" -->
                <i class="ni ni-tv-2"></i>
                Go To Dashboard
            </base-button>
        </div>

        <!-- Import Registration payment blade here -->
        <RegPaymentDetails
            v-bind:application="application.application"
            v-bind:user="application.user"
            v-bind:serviceType="application.service_type"
            v-bind:hasPreviousApplication="hasPreviousApplication"
            v-bind:previousApplications="application.previous_applications"
            v-bind:applicationType="applicationType"
            v-bind:displayNewPayment="displayNewPayment"
            v-bind:loadApplication="loadApplication"
            v-bind:changeAppId="changeAppId"
            v-on:addPayment="addPayment"
            v-if="displayPayment && application && ! showAddPayment"
        />

        <div class="col-sm-12 text-center">
            <base-alert :type="errorType" v-if='showErrorStatus'>
                <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                <span class="alert-inner--text">
                    {{ errorMsg }}
                </span>
            </base-alert>
        </div>

    </div>



</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import RegPaymentDetails from './RegPaymentDetails.vue';

    export default {
        components: {
            RegPaymentDetails
        },
        name: 'FullRegPayment',
        props: [ 'user', 'serviceType', 'hasPreviousApplication', 'previousApplications', 'displayNewPayment',
            'addPayment', 'showAddPayment', 'isAlternativeReg', 'loadApplication', 'changeAppId', 'submitNow'],
        data() {
            return {
                displayPayment: false,
                application: null,
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isLoadingBlade: false,
                applicationType: null
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl'])
        },
        methods: {
            ...mapActions(['getUserRegistrationDetails']),
            loadExactApplication(type) {// load exact application
                this.isLoadingBlade = true;
                this.application = null;
                this.applicationType = type;
                this.getUserRegistrationDetails(type).then((res) => {
                    this.application = res;
                    this.displayPayment = true;
                    this.isLoadingBlade = false;
                }).
                catch(() => {
                    this.isLoadingBlade = false;
                    this.showErrorStatus = true;
                    this.errorMsg = "Network Failure!, kindly reload the page...";
                    this.errorType = 'danger';
                });
            }
        },
    }
</script>

<style scoped>
.right {
    float: right;
}
</style>