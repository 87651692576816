<template>
    <div>
        <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center no-padding-bottom">
            <span class="mask bg-gradient-danger opacity-8"></span>
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col-xl-12 order-xl-1">
                    <br><br><br>
                    <card shadow type="secondary">
                        <div slot="header" class="bg-white border-0">
                            <div class="row align-items-center">

                                <div class="col-8">
                                    <h3 class="mb-0 text-uppercase">{{ this.$route.name }}</h3>
                                    <br>
                                    <span class="text-danger" v-if="policy && policy.title && showPolicy">
                                        {{ policy.title }}
                                    </span>
                                </div>
                                <div class="col-4 text-right">
                                    <a
                                        :href="websiteUrl"
                                        class="btn btn-lg btn-danger"
                                    >
                                        <i class="fa fa-home"></i>
                                        Home
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div class="row" v-if="! isLoadingBlade && showPolicy">
                            <div class="col-lg-12"
                                v-html="policy.content"
                                v-if="policy"
                            ></div>

                            <div class="col-lg-12">
                                <hr>
                                <a
                                    :href="websiteUrl"
                                    class="btn btn-danger text-white"
                                    :disabled="isLoadingBlade"
                                >
                                    <i class="fa fa-times"></i>
                                    I Disagree
                                </a>

                                <base-button
                                    type="success"
                                    class="right"
                                    :disabled="isLoadingBlade"
                                    @click="hidePolicyPage(false)"
                                >
                                    <i class="fa fa-check"></i>
                                    I Agree
                                </base-button>
                            </div>
                        </div>

                        <div class="row" v-if="isLoadingBlade">
                            <div class="col-lg-12">
                                <div class="d-flex justify-content-center">
                                    <img :src="loadingBladeUrl" />
                                </div>
                            </div>
                        </div>

                        <div v-if="! showPolicy && ! showDocPayPage">
                            <div class="row" v-if='! showErrorStatus && ! isLoadingBlade'>
                                <div class="col-sm-6 text-center borderLine">
                                    <br>
                                    <h4 class="text-uppercase height-50">
                                       I have not paid the verification fee and want to pay now
                                    </h4>
                                    <br>
                                    <base-button
                                        type="primary"
                                        class="btn btn-sm"
                                        :disabled="isLoadingBlade"
                                        @click="showTempPage(true, 'pay')"
                                    >
                                        Click &amp; Continue
                                        <i class="fa fa-arrow-right"></i>
                                    </base-button>
                                    <br><br><br>
                                </div>

                                <div class="col-sm-6 text-center borderLine">
                                    <br>
                                    <h4 class="text-uppercase height-50">
                                        I have paid the verification fee and want to continue with verification
                                    </h4>
                                    <br>

                                    <router-link
                                        to="/get-doctor-status"
                                        class="btn btn-sm btn-success"
                                        :disabled="isLoadingBlade"
                                    >
                                        Click &amp; Continue
                                        <i class="fa fa-arrow-right"></i>
                                    </router-link>
                                    <br><br><br>
                                </div>
                                <br><br><br><br><br><br><br><br><br>
                            </div>
                        </div>

                        <!-- The doc verification payment page included here -->
                        <DoctorVerifyPayPage
                            :hidePolicyPage="hidePolicyPage"
                            v-if="showDocPayPage"
                        />

                    </card>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import DoctorVerifyPayPage from './DoctorVerifyPayPage';

    export default {
        name: 'DoctorStatus',
        components: {
            DoctorVerifyPayPage
        },
        data() {
            return {
                isLoadingBlade: true,
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                showPolicy: true,
                policy: null,
                showDocPayPage: false
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl', 'websiteUrl']),
        },
        methods: {
            ...mapActions(['getSiteParameter']),
            getParameter() {// get Parameter
                let name = 'confirm-doctor-status';
                this.getSiteParameter({'name': name}).then((res) => {
                    this.isLoadingBlade = false;
                    if(res) {
                        this.policy = res;
                    } else {
                        this.showErrorStatus = true;
                        this.isLoadingBlade = false;
                        this.errorMsg = "Network Failure!, kindly reload the page...";
                        this.errorType = 'danger';
                    }
                }).
                catch(() => {
                    this.showErrorStatus = true;
                    this.isLoadingBlade = false;
                    this.errorMsg = "Network Failure!, kindly reload the page...";
                    this.errorType = 'danger';
                });
            },
            hidePolicyPage(value) {
                this.showDocPayPage = false;
                return this.showPolicy = value;
            },
            showTempPage(value, type) {
                if(type == 'pay') {
                    this.showDocPayPage = value;
                }
            }
        },
        created() {
            this.getParameter();
        }
    }
</script>

<style scoped>
    .no-padding-bottom {
        padding-bottom: 0rem !important
    }
    .right {
        float: right;
    }
</style>

<style>
    .form-control-label {
        width: 100%;
    }
    .height-50 {
        height: 50px;
    }
    .borderLine {
        border: 1px solid #ededed;
    }
</style>