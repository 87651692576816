<template>
    <div>
        <div slot="header" class="border-0">
            <div class="row align-items-center">

                <div class="col-8">
                    <h3 class="mb-0 text-uppercase" v-html="title"></h3>
                    <br>
                    <span class="text-danger" v-html="description"></span>
                </div>
                <div class="col-4 text-right">
                    <router-link
                        to="/remediation/programme"
                        class="btn btn-danger"
                    >
                        <i class="fa fa-reply"></i>
                        Back
                    </router-link>
                </div>

                <hr>
            </div>
        </div>

        <div v-if="! submitted || editApplication">
            <ProfileData
                :id="application.id"
                :data="application"
                :transaction="application.transaction"
                :loadApplicantInfo="loadApplicantInfo"
                :displayPhoto="displayPhoto"
                v-if="showProfileData && application && ! isLoadingBlade"
            />

            <!-- Import Profile Photo template here -->
            <StudentPhoto
                :id="application.id"
                :application="application"
                :loadApplicantInfo="loadApplicantInfo"
                :displayProfileData="displayProfileData"
                :displayDocuments="displayDocuments"
                v-if="showPhoto && application && ! isLoadingBlade"
            />

            <!-- this is cpd's documents -->
            <Documents
                :application="application"
                :documents="application.documents"
                :loadApplicantInfo="loadApplicantInfo"
                :displayPhoto="displayPhoto"
                :displaySummary="displaySummary"
                v-if="showDocuments && application && application.documents && ! isLoadingBlade"
            />

            <!-- this is application summary template -->
            <RemediationSummary
                :application="application"
                :displayDocuments="displayDocuments"
                :loadApplicantInfo="loadApplicantInfo"
                :editRemediationApp="editRemediationApp"
                v-if="showSummary && application && ! isLoadingBlade"
            />

            <div class="col-lg-12" v-if="isLoadingBlade">
                <div class="d-flex justify-content-center">
                    <img :src="loadingBladeUrl" />
                </div>
            </div>

            <div class="col-lg-12 text-center" v-if='showErrorStatus && ! isLoadingBlade'>
                <base-alert :type="errorType">
                    <!-- <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span> -->
                    <span class="alert-inner--text">
                        <h2 class="text-white">{{errorMsg}}</h2>
                    </span>
                </base-alert>
            </div>
        </div>

        <RemediationStatusBoard
            :applicant="application"
            :changeTitle="changeTitle"
            :editRemediationApp="editRemediationApp"
            v-if="submitted && ! editApplication"
        />

    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    // import Insurance from './Insurance/Insurance.vue';
    import ProfileData from './ProfileData';
    import StudentPhoto from './StudentPhoto.vue';
    import Documents from './Documents.vue';
    import RemediationSummary from './RemediationSummary/RemediationSummary';
    import RemediationStatusBoard from './RemediationStatusBoard.vue'
    import swal from 'sweetalert2';// import sweet alert

    export default {
        name: 'RemediationForm',
        components: {
            // Insurance,
            ProfileData,
            StudentPhoto,
            Documents,
            RemediationSummary,
            RemediationStatusBoard
        },
        data() {
            return {
                application: null,
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isLoadingBlade: true,
                showProfileData: true,
                showSummary: false,
                showPhoto: false,
                showDocuments: false,
                code: this.$route.name,
                title: null,
                description: null,
                editApplication: false,
                id: localStorage.getItem('applicantId')
            }
        },
        computed: {
            ...mapGetters(['websiteUrl', 'loadingBladeUrl']),
            submitted() {// check if application is stil pending
                return this.application && (this.application.status != 'pending');
            }
        },
        methods: {
            ...mapActions(['getPendingRemediationApplication', 'getLocations']),
            editRemediationApp(status = true) {
                if(status) {
                    let title = 'Update Remediation Programme Form';
                    let des = 'Kindly proceed to correct your application form below...';
                    this.changeTitle(title, des, true);
                } else {
                    this.changeTitle('Remediation Programme Application Form', null, true);
                }                
                return this.editApplication = status;
            },
            loadTitle() {// load title
                return this.title = this.replaceString(this.code);
            },
            replaceString(value) {
                return value ? value.replace('-', ' ') : 'Application Form';
            },
            changeTitle(value, des, status) {// change title
                let description = des ? des : 'Kindly fill the form below with correct information...';
                if(status) {
                    this.title = value;
                    this.description = description;
                } else {
                    this.loadTitle();
                }
            },
            loadApplicantInfo() {
                this.isLoadingBlade = true;
                this.application = null;
                this.getPendingRemediationApplication({ id: this.id }).then((res) => {
                    this.isLoadingBlade = false;
                    if(res.status) {
                        this.changeTitle('Remediation Programme Application Form', null, true);
                        this.application = res.application;
                        if(! res.application) { return window.location.href = '/remediation/programme'; }// return to dashboard
                    } else {
                        let msg = 'Payment has not been made, proceed to make payment'
                        swal.fire("Payment Failed!", msg, "warning");   
                        setTimeout(function() { window.location.href = '/remediation/generate'; }, 3000);
                    }
                }).
                catch(() => {
                    this.showErrorStatus = true;
                    this.isLoadingBlade = false;
                    this.errorMsg = "Server Failure!, kindly reload the page...";
                    this.errorType = 'danger';
                });
            },
            displayProfileData(value) {
                this.showProfileData = value;
                this.showSummary = ! value;
                this.showPhoto = ! value;
                this.showDocuments = ! value;
            },
            displayPhoto(value) {
                this.showProfileData = ! value;
                this.showSummary = ! value;
                this.showPhoto = value;
                this.showDocuments = ! value;
            },
            displayDocuments(value) {
                this.showProfileData = ! value;
                this.showSummary = ! value;
                this.showPhoto = ! value;
                this.showDocuments = value;
            },
            displaySummary(value) {
                this.showProfileData = ! value;
                this.showSummary = value;
                this.showPhoto = ! value;
                this.showDocuments = ! value;
            }
        },
        created() {
            this.loadApplicantInfo();
            this.getLocations();
        }
    }
</script>

<style scoped>
    .no-padding-bottom {
        padding-bottom: 0rem !important
    }
    .right {
        float: right;
    }
</style>

<style>
    .form-control-label {
        width: 100%;
    }
    .height-50 {
        height: 50px;
    }
    .borderLine {
        border: 1px solid #ededed;
    }
</style>