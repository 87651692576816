<template>
    <form>
        <h4 class="heading text-muted mb-4" v-if="! isLoadingBlade">
            <!-- Upload Files &nbsp;&nbsp;&nbsp;&nbsp; -->
            <span class="text-danger">
                <i class="fa fa-info-circle"></i> Only PDF Files (Not More than 800KB) are supported for each file
            </span>            
        </h4>

        <hr class="my-4" v-if="! isLoadingBlade"/>

        <h4 class="heading mb-4 text-center" v-if="! isLoadingBlade">
            Download Embassy Stamp Page Document &nbsp;&nbsp; 
            <span class="text-danger">
                <i class="fa fa-arrow-right"></i>
            </span> 
            &nbsp;&nbsp;&nbsp;&nbsp;
            <base-button 
                tag="a" type="primary" 
                size="lg" 
                :href="embassyDocUrl" 
                class="active" role="button" 
                aria-pressed="true"
                target="_blank"
            >
                <i class="fa fa-download"></i> Download Now
            </base-button>          
        </h4>

        <hr class="my-4" v-if="! isLoadingBlade"/>

        <div class="col-lg-12" v-if="isLoadingBlade">
            <div class="d-flex justify-content-center">
                <img :src="loadingBladeUrl" />
            </div>
        </div>

        <div class="container ct-example-row" v-if="! isLoadingBlade">
            <!-- import User Document File template here -->
            <DocumentFile 
                v-bind:items="items"
                v-bind:showDocuments="showDocuments"
                v-bind:showErrorStatus="showErrorStatus"
                v-bind:errorMsg="errorMsg"
                v-bind:loadUserDocuments="loadUserDocuments"
            />
        </div>

        <hr class="my-4" v-if="! isLoadingBlade"/>

        <div class="pl-lg-4" v-if="! isLoadingBlade">
            <base-button 
                type="danger" 
                @click="showPreviousPage()"
            >
                <i class="fa fa-arrow-left"></i> 
                Previous
            </base-button>
            <div class="right" v-if="hasCompleteDocuments">
                <base-button 
                    type="success" 
                    @click="showNextPage()"
                >                    
                    Proceed 
                    <i class="fa fa-arrow-right"></i> 
                </base-button>
            </div>            
        </div>
        
    </form>
    
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import DocumentFile from '../../NewAccount/DocumentFile';

    export default {
        components: {
            DocumentFile
        },
        name: 'Documents',
        props: ['displayPhoto', 'displayInsurance'],
        data() {
            return {
                items: {},
                showDocuments: true,
                showErrorStatus: false,
                isLoadingBlade: true,
                errorMsg: null
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl', 'embassyDocUrl']),
            hasCompleteDocuments() {// check if all documents has been uploaded
                let status = [];
                for(let document of this.items.documents) {
                    status.push(document.hasFile);
                }
                let checker = arr => arr.every(v => v === true);
                return checker(status);
            }
        },
        methods: {
            ...mapActions(['getUserDocuments']),
            showPreviousPage() {// go to next page
                return this.displayPhoto(true);
            },
            showNextPage() {// go to next page
                return this.displayInsurance(true);
            },
            loadUserDocuments() {// update documents
                this.getUserDocuments().then((res) => {
                    if(res.status) {
                        this.items = res;
                        this.showDocuments = true;
                        this.showErrorStatus = false;
                        this.isLoadingBlade = false;
                    } else {
                        this.showDocuments = false;
                        this.showErrorStatus = true;
                        this.errorMsg = 'Error occurred! Documents wasn\'t fetched, reload page';
                    }                    
                });
            }
        },
        created() {
            this.loadUserDocuments();// load documents
        }
    }
</script>

<style>

</style>
