<template>
    <div>
        <div class="row" v-if='! showErrorStatus && ! isLoadingBlade'>
            <div class="col-lg-12">
                <hr>
            </div>
            <div class="col-lg-8">
                <h1 class="text-uppercase">
                    <em class="text-danger">{{ personal.full_name }}</em>
                    'S REMEDIATION PROGRAMME DASHBOARD
                </h1>
                <span class="text-uppercase">
                    <strong>RRR:</strong> {{ rrr }}
                </span><br>
                <span class="text-uppercase">
                    <strong>Application Status: </strong>
                    <badge
                        :type="(applicant.status == 'approved') ? 'success' : 'warning'"
                        v-if="applicant"
                    >
                        {{ applicant.status }}
                    </badge>
                    <br>
                </span>
                <span class="text-uppercase">
                    <strong>Application Date:</strong> {{ applicant.application_date | getDateFormat }}
                </span><br>
                <span class="text-uppercase" v-if="(applicant.status == 'rejected' || applicant.status == 'approved')">
                    <strong>{{ applicant.status }} Date:</strong> {{ applicant.approved_date | getDateFormat }}
                    <br>
                </span>
                <span v-if="(applicant.status == 'rejected')">
                    <strong>Reject Reason:</strong> {{ applicant.reject_reason }}
                    <br>
                </span>


                <span class="text-uppercase" v-if="applicant && applicant.batch">
                    <strong>REMEDIATION Batch:</strong> {{ applicant.batch.name + ' (' + applicant.batch.year + ')' }}
                </span>
                <br><br>
            </div>

            <div class="col-lg-4 text-right">
                <img :src="photo" class="photo-class"/>
                <br><br>
            </div>

            <div class="table-responsive">
                <table class="table table-bordered">
                    <tbody>
                        <td>
                            <h4>Last Name</h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ personal.last_name ? personal.last_name : 'N/A' }}
                            </h4>
                        </td>
                        <td>
                            <h4>First Name</h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ personal.first_name ? personal.first_name : 'N/A' }}
                            </h4>
                        </td>
                        <td>
                            <h4>Date of Birth</h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ personal.date_of_birth | getDateFormat }}
                            </h4>
                        </td>
                    </tbody>
                    <tbody>
                        <td>
                            <h4>Gender</h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ personal.gender ? personal.gender : 'N/A' }}
                            </h4>
                        </td>
                        <td>
                            <h4>Marital Status</h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ personal.marital_status ? personal.marital_status : 'N/A' }}
                            </h4>
                        </td>
                        <td>
                            <h4>Nationality</h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ personal.country ? personal.country.name : 'N/A' }}
                            </h4>
                        </td>
                    </tbody>
                </table>
            </div>
            <div class="col-lg-12">
                <hr class="my-4" />
            </div>

        </div>

        <div class="col-lg-12" v-if="isLoadingBlade">
            <div class="d-flex justify-content-center">
                <img :src="loadingBladeUrl" />
            </div>
        </div>

        <div class="col-lg-12 text-center" v-if='showErrorStatus && ! isLoadingBlade'>
            <base-alert :type="errorType">
                <!-- <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span> -->
                <span class="alert-inner--text">
                    <h2 class="text-white">{{errorMsg}}</h2>
                </span>
            </base-alert>
        </div>

        <div class="col-lg-12">
            <router-link
                to="/remediation/programme"
                class="btn btn-danger"
            >
                <i class="fa fa-arrow-left"></i>
                Back
            </router-link>

            <base-button
                type="info"
                class="right"
                @click="editRemediationApp"
                v-if="(applicant.status == 'rejected')"
            >
                <i class="fa fa-pen"></i>
                Edit Application
            </base-button>
        </div>
    </div>
</template>


<script>
    import { mapActions, mapGetters } from 'vuex';
    import moment from 'moment';

    export default {
        name: 'RemediationStatusBoard',
        props: ['applicant', 'changeTitle', 'editRemediationApp'],
        filters: {
            getDateFormat(value) {
                return moment(value).format('Do MMM, YYYY');
            }
        },
        data() {
            return {
                isLoadingBlade: false,
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                id: localStorage.getItem('applicantId'),
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl', 'websiteUrl']),
            personal() {
                return this.applicant.personal_data;
            },
            rrr() {
                return this.applicant.ref;
            },
            photo() {
                return this.personal.photo_url;
            }
        },
        methods: {
            ...mapActions(['getRemediationApplicantInfo']),
            setTitle() {
                let title = 'Remediation Programme Application Dashboard';
                let des = 'Stay up-to-date with your application status here...';
                this.changeTitle(title, des, true);
            }
        },
        created() {
            this.setTitle();
        }
    }
</script>

<style scoped>
    .no-padding-bottom {
        padding-bottom: 0rem !important
    }
    .photo-class {
        width: 200px;
        height: 200px;
    }
</style>

<style>
    .form-control-label {
        width: 100%;
    }
    .height-50 {
        height: 50px;
    }
    .borderLine {
        border: 1px solid #ededed;
    }
</style>