<template>
    <div>
        <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center no-padding-bottom">
            <span class="mask bg-gradient-danger opacity-8"></span>
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col-xl-12 order-xl-1">
                    <br><br><br>
                    <card shadow type="secondary">
                        <div slot="header" class="bg-white border-0">
                            <div class="row align-items-center">

                                <div class="col-8">
                                    <h3 class="mb-0 text-uppercase">{{ this.$route.name }}</h3>
                                    <br>
                                    <!-- <span class="text-danger">
                                        Kindly register for Assessment Exam below by clicking appropriate button...
                                    </span> -->
                                </div>
                                <div class="col-4 text-right">
                                    <a :href="websiteUrl" class="btn btn-sm btn-danger">
                                       <i class="fa fa-reply"></i> Home
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div class="row" v-if="! isLoadingBlade && showPolicy">
                            <div class="col-lg-12"
                                v-html="policy.content"
                                v-if="policy"
                            ></div>

                            <div class="col-lg-12">
                                <hr>
                                <a
                                    :href="websiteUrl"
                                    class="btn btn-danger"
                                    :disabled="isLoadingBlade"
                                >
                                    <i class="fa fa-times"></i>
                                    I Disagree
                                </a>

                                <base-button
                                    type="success"
                                    class="right"
                                    :disabled="isLoadingBlade"
                                    @click="loadAssessmentBatch()"
                                >
                                    <i class="fa fa-check"></i>
                                    I Agree
                                </base-button>
                            </div>
                        </div>

                        <div class="row" v-if="isLoadingBlade">
                            <div class="col-lg-12">
                                <div class="d-flex justify-content-center">
                                    <img :src="loadingBladeUrl" />
                                </div>
                            </div>
                        </div>

                        <div v-if="! showPolicy">
                            <div class="row" v-if='! showErrorStatus && ! isLoadingBlade'>

                                <!-- <div class="col-sm-12 text-center text-danger text-uppercase">
                                    <strong>Caveat Emptor</strong>: 
                                    Certificates issued from Ukrainian Medical schools in 2022 shall 
                                    not be accepted. This may lead to forfeiture of registration fees as these 
                                    applications will be rejected.
                                    <br><br>
                                </div> -->
                                <div class="col-sm-6 text-center borderLine">
                                    <br>
                                    <h4 class="text-uppercase height-90">
                                        I do not have RRR and I have not made payment
                                    </h4>
                                    <router-link
                                        to="/assessment-generate"
                                        class="btn btn-sm btn-primary"
                                    >
                                        Click &amp; Continue
                                        <i class="fa fa-arrow-right"></i>
                                    </router-link>
                                    <br><br><br>
                                </div>

                                <div class="col-sm-6 text-center borderLine">
                                    <br>
                                    <h4 class="text-uppercase height-90">
                                        I have RRR and have made payment
                                    </h4>
                                    <router-link
                                        to="/assessment/check-payment"
                                        class="btn btn-sm btn-success"
                                    >
                                        Click &amp; Continue
                                        <i class="fa fa-arrow-right"></i>
                                    </router-link>
                                    <br><br><br>
                                </div>

                                <div class="col-sm-6 text-center borderLine">
                                    <br>
                                    <h4 class="text-uppercase height-90">
                                        I Want to continue my Application / Check my Application status / <br>
                                        <span class="text-danger"> Update my Rejected / Incomplete Application</span>
                                    </h4>
                                    <router-link
                                        to="/assessment/continue-application"
                                        class="btn btn-sm btn-dark"
                                    >
                                        Click &amp; Continue
                                        <i class="fa fa-arrow-right"></i>
                                    </router-link>
                                    <br><br><br>
                                </div>

                                <div class="col-sm-6 text-center borderLine">
                                    <br>
                                    <h4 class="text-uppercase height-90">
                                        I made payment for previous exam batch but did not sit for the exam
                                    </h4>
                                    <router-link
                                        to="/assessment/previous-batch"
                                        class="btn btn-sm btn-info"
                                    >
                                        Click &amp; Continue
                                        <i class="fa fa-arrow-right"></i>
                                    </router-link>
                                    <br><br><br>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-12 text-center" v-if='showErrorStatus && ! isLoadingBlade'>
                                    <base-alert :type="errorType">
                                        <!-- <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span> -->
                                        <span class="alert-inner--text">
                                            <h2 class="text-white text-uppercase">{{errorMsg}}</h2>
                                        </span>
                                    </base-alert>
                                </div>
                                <div class="col-lg-12" v-if='showErrorStatus && ! isLoadingBlade'>
                                    <br><br><br>
                                    <div class="row" v-if="! batchIsSuspended">
                                        <div class="col-lg-4 text-center borderLine">
                                            <br>
                                            <h4 class="text-uppercase height-90">
                                                I want to check status / continue my application for this current batch
                                            </h4>
                                            <router-link
                                                to="/assessment/continue-application"
                                                class="btn btn-sm btn-success"
                                            >
                                                Click &amp; Continue
                                                <i class="fa fa-arrow-right"></i>
                                            </router-link>
                                            <br><br><br>
                                        </div>

                                        <div class="col-lg-4 text-center borderLine">
                                            <br>
                                            <h4 class="text-uppercase height-90">
                                                I paid for this current batch before the registration closing date,
                                                And I want to continue with my application
                                            </h4>
                                            <router-link
                                                to="/assessment/registration-application"
                                                class="btn btn-sm btn-primary"
                                            >
                                                Click &amp; Continue
                                                <i class="fa fa-arrow-right"></i>
                                            </router-link>
                                            <br><br><br>
                                        </div>

                                        <div class="col-lg-4 text-center borderLine">
                                            <br>
                                            <h4 class="text-uppercase height-90">
                                                I want to check check status / continue my application for previous batch
                                                sitting for this current batch
                                            </h4>
                                            <router-link                                                 
                                                to="/assessment/previous-batch"
                                                class="btn btn-sm btn-dark"
                                            >
                                            <!-- to="/assessment/registration-status" -->
                                                Click &amp; Continue
                                                <i class="fa fa-arrow-right"></i>
                                            </router-link>
                                            <br><br><br>
                                        </div>
                                    </div>

                                    <div class="row" v-if="batchIsSuspended">
                                        <div class="col-lg-4"></div>
                                        <div class="col-lg-4 text-center borderLine">
                                            <br>
                                            <h4 class="text-uppercase height-90">
                                                I want to check my application status / print my exam card for this current batch
                                            </h4>
                                            <router-link
                                                to="/assessment/check/application/status"
                                                class="btn btn-sm btn-success"
                                            >
                                                Click &amp; Continue
                                                <i class="fa fa-arrow-right"></i>
                                            </router-link>
                                            <br><br><br>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </card>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';

    export default {
        name: 'AssessmentExam',
        data() {
            return {
                isLoadingBlade: true,
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                showPolicy: true,
                policy: null,
                batch: null
            }
        },
        computed: {
            ...mapGetters(['websiteUrl', 'loadingBladeUrl']),
            batchIsSuspended() {// check if batch is suspended
                return this.batch && (this.batch.status == 'suspended');
            }
        },
        methods: {
            ...mapActions(['getAssessmentBatch', 'getSiteParameter']),
            getParameter() {// get Parameter
                let name = 'assessment-exam-policy';
                this.getSiteParameter({'name': name}).then((res) => {
                    this.isLoadingBlade = false;
                    if(res) {
                        this.policy = res;
                    } else {
                        this.showErrorStatus = true;
                        this.isLoadingBlade = false;
                        this.errorMsg = "Network Failure!, kindly reload the page...";
                        this.errorType = 'danger';
                    }
                }).
                catch(() => {
                    this.showErrorStatus = true;
                    this.isLoadingBlade = false;
                    this.errorMsg = "Network Failure!, kindly reload the page...";
                    this.errorType = 'danger';
                });
            },
            loadAssessmentBatch() {
                this.isLoadingBlade = true;
                this.getAssessmentBatch().then((res) => {
                    this.isLoadingBlade = false;
                    this.showPolicy = false;
                    this.batch = res.batch;
                    if(res.status) {                        
                        this.showErrorStatus = false;
                    } else {
                        this.showErrorStatus = true;
                        this.errorMsg = res.msg;
                        this.errorType = 'danger';
                    }
                }).
                catch(() => {
                    this.showErrorStatus = true;
                    this.isLoadingBlade = false;
                    this.errorMsg = "Network Failure!, kindly reload the page...";
                    this.errorType = 'danger';
                });
            },
        },
        created() {
            this.getParameter();
        }
    }
</script>

<style scoped>
    .no-padding-bottom {
        padding-bottom: 0rem !important
    }
    .right {
        float: right;
    }
</style>

<style>
    .form-control-label {
        width: 100%;
    }
    .height-90 {
        height: 90px;
    }
    .borderLine {
        border: 1px solid #ededed;
    }
</style>