<template>
    <div>
        <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center no-padding-bottom">
            <span class="mask bg-gradient-danger opacity-8"></span>
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col-xl-12 order-xl-1">
                    <br><br><br>
                    <card shadow type="secondary">
                        <div slot="header" class="bg-white border-0">
                            <div class="row align-items-center">

                                <div class="col-8">
                                    <h3 class="mb-0 text-uppercase">{{ this.$route.name }}</h3>
                                    <br>
                                    <span class="text-danger">
                                        Kindly enter a valid RRR (must have been paid for) from the previous Assessment
                                        Exam Batch and proceed to pay the extra service charge...
                                    </span>
                                </div>
                                <div class="col-4 text-right">
                                    <a :href="websiteUrl" class="btn btn-sm btn-danger text-white">
                                       <i class="fa fa-reply"></i> Home
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div v-if="! showPaymentPage">
                            <div class="row" v-if='! showErrorStatus && ! isLoadingBlade'>
                                <div class="col-lg-6">
                                    <base-input alternative=""
                                        label="Assessment Category"
                                        required
                                    >
                                        <select
                                            class="form-control form-control-alternative"
                                            v-model="category"
                                            @change="emptyField"
                                        >
                                            <option :value="null" disabled selected>--- Select One ---</option>
                                            <option
                                                v-for="cat in categories"
                                                :key="cat.id"
                                                :value="cat"
                                            >
                                                {{ cat.title }}
                                                <em v-if="cat.description">({{ cat.description }})</em>
                                            </option>
                                        </select>
                                    </base-input>
                                </div>

                                <div class="col-lg-6">
                                    <base-input alternative=""
                                        label="RRR"
                                        placeholder="Enter Valid RRR"
                                        input-classes="form-control-alternative"
                                        v-model="rrr"
                                        type="number"
                                        required
                                    />
                                </div>
                            </div>

                            <div class="col-lg-12" v-if="isLoadingBlade">
                                <div class="d-flex justify-content-center">
                                    <img :src="loadingBladeUrl" />
                                </div>
                            </div>

                            <div class="col-lg-12 text-center" v-if='showErrorStatus && ! isLoadingBlade'>
                                <base-alert :type="errorType">
                                    <!-- <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span> -->
                                    <span class="alert-inner--text">
                                        <h2 class="text-white">{{errorMsg}}</h2>
                                    </span>
                                </base-alert>
                            </div>

                            <div v-if='! showErrorStatus && ! isLoadingBlade' class="text-center">
                                <base-button
                                    type="success"
                                    :disabled="! rrr"
                                    @click="getRRRStatus()"
                                >
                                    {{ isloading ? 'Please Wait...' : 'Proceed Now'}}
                                    <i class="fa fa-arrow-right"></i>
                                </base-button>
                                <br><br><br><br>
                            </div>
                        </div>

                        <div class="col-sm-12 text-center borderLine" v-if="! showPaymentPage && ! isLoadingBlade">
                            <br>
                            <h3 class="text-uppercase height-50">
                                I have Paid the Extra Service Fee and Add it to the Portal. <br> 
                                I want to continue my Application / 
                                <br> Check my Application status for Previous Batch
                            </h3>
                            <br>
                            <em class="text-danger">
                                Note You are to use the RRR for the previous batch, not the RRR for Extra Service Fee,<br>
                                kindly click on this link to continue with your application
                            </em>
                            <br><br><br>
                            <router-link
                                to="/assessment/continue-application"
                                class="btn btn-sm btn-dark"
                            >
                                Click &amp; Continue
                                <i class="fa fa-arrow-right"></i>
                            </router-link>
                            <br><br><br><br><br><br>
                        </div>

                        <div v-if="! showPaymentPage && ! isLoadingBlade">
                            <router-link
                                to="/assessment-exam"
                                class="btn btn-danger"
                            >
                                <i class="fa fa-arrow-left"></i>
                                Go Back
                            </router-link>
                        </div>

                        <AssessmentReRegisterPayment
                            :record="record"
                            :type="category"
                            :country="country"
                            :serviceType="serviceType"
                            :batchStatus="batchStatus"
                            v-if="showPaymentPage"
                        />

                    </card>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import AssessmentReRegisterPayment from './AssessmentReRegistration/AssessmentReRegisterPayment';
    import swal from 'sweetalert2';// import sweet alert

    export default {
        name: 'AssessmentReRegistration',
        components: {
            AssessmentReRegisterPayment
        },
        data() {
            return {
                category: null,
                country: null,
                rrr: null,
                isloading: false,
                categories: [],
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isLoadingBlade: true,
                showPaymentPage: false,
                record: null,
                serviceType: null,
                batchStatus: true
            }
        },
        computed: {
            ...mapGetters(['websiteUrl', 'loadingBladeUrl', 'locations']),
            showCountry() {// for dental only
                return this.category && ((this.category.id == 2) || (this.category.id == 4));
            }
        },
        methods: {
            ...mapActions(['getAssessmentRrrStatus', 'getAssessmentTypes', 'getLocations']),
            clearStorage() {
                localStorage.removeItem('applicantId');
            },
            emptyField() {
                this.country = null;
            },
            loadAssessmentTypes() {
                this.isLoadingBlade = true;
                this.categories = [];
                let data = { 'type' : true };
                this.getAssessmentTypes(data).then((res) => {
                    this.getLocations();
                    this.categories = res.types;
                    this.serviceType = res.service_type;
                    this.showErrorStatus = false;
                    this.isLoadingBlade = false;
                    this.clearStorage();
                }).
                catch(() => {
                    this.showErrorStatus = true;
                    this.isLoadingBlade = false;
                    this.errorMsg = "Network Failure!, kindly reload the page...";
                    this.errorType = 'danger';
                });
            },
            getRRRStatus() {
                if(this.rrr && this.category) {
                    this.isloading = true;
                    this.record = null;
                    this.preloader();
                    let data = { rrr : this.rrr, type : 'register', assessment_type_id : this.category.id };
                    this.getAssessmentRrrStatus(data).then((res) => {
                        this.isloading = false;
                        if(res.status) {
                            this.batchStatus = res.batch_status;// curent batch status
                            if(res.type) {// get payment page
                                this.record = res.record;
                                this.showPaymentPage = true;
                                swal.fire("Verification Failed!", res.msg, "success");
                            } else {// get form for applicant
                                swal.close();
                                localStorage.setItem('applicantId', res.record.id);
                                return window.location.href = '/assessment/application-form';
                            }
                        } else {
                            this.isloading = false;
                            swal.fire("Verification Failed!", res.msg, "warning");
                        }
                    }).
                    catch(() => {
                        this.isloading = false;
                        let msg = "Network Failure!, kindly reload the page...";
                        swal.fire("Error Ocurred!", msg, "warning");
                    });
                } else {
                    let msg = 'Kindly fill the necessary field(s) and try again...';
                    swal.fire("Empty field(s)!", msg, "warning");
                }
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        created() {
            this.loadAssessmentTypes();
        }
    }
</script>

<style scoped>
    .no-padding-bottom {
        padding-bottom: 0rem !important
    }
    .right {
        float: right;
    }
</style>

<style>
    .form-control-label {
        width: 100%;
    }
    .height-50 {
        height: 50px;
    }
    .borderLine {
        border: 1px solid #ededed;
    }
</style>