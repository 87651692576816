<template>
    <div class="pl-lg-4">

        <div class="col-lg-12" v-if="isLoadingBlade">
            <div class="d-flex justify-content-center">
                <img :src="loadingBladeUrl" />
            </div>
        </div>

        <div v-if="! isLoadingBlade">
            <h3 class="text-uppercase">Previous Examination Information</h3>

            <em class="mb-4 text-danger">
                Kindly fill details of previous examination taken below...
            </em>

            <hr class="my-4"/>

            <!-- import examination details template here -->
            <AssessmentExamData
                v-bind:examRecords="examRecords"
                v-bind:showAddRecord="showAddRecord"
                v-on:deleteExamRecord="deleteExamRecord"
                v-bind:pagination="pagination"
                v-bind:hasPagination="hasPagination"
                v-bind:paginate="paginate"
            />

            <hr class="my-4" v-if="hasRecords && ! showAddRecord"/>

            <div class="pl-lg-4" v-if="hasRecords && ! showAddRecord">
                <base-button
                    type="primary"
                    @click="displayAddPage()"
                >
                    <i class="fa fa-plus"></i>
                    Add New Record
                </base-button>
            </div>


            <div class="row align-items-center" v-if="! hasRecords || showAddRecord">

                <div class="col-lg-6">
                    <base-input alternative=""
                        label="Teaching Hospital"
                        required
                    >
                        <autocomplete
                            input-class="form-control form-control-alternative"
                            placeholder="Type to search Teaching Hospital, else type 'Others' if no results"
                            showNoResults
                            :source="formatedHospitals"
                            resultsValue="name"
                            @selected="updateExamVenue"
                        >
                        </autocomplete>
                    </base-input>
                </div>

                <div class="col-lg-6" v-if="showOtherVenue">
                    <base-input alternative=""
                        label="Examination Venue"
                        placeholder="Enter Examination Venue"
                        input-classes="form-control-alternative"
                        v-model="record.venue"
                        required
                    />
                </div>
                <div class="col-lg-6">
                    <base-input
                        label="Examination Date"
                        addon-left-icon="ni ni-calendar-grid-58"
                        required

                    >
                        <flat-picker
                            slot-scope="{focus, blur}"
                            @on-open="focus"
                            @on-close="blur"
                            :config="config"
                            class="form-control datepicker"
                            v-model="record.date"
                        >
                        </flat-picker>
                    </base-input>
                </div>
                <div class="col-lg-12">
                    <base-alert :type="errorType" dismissible v-if='showInputErrorStatus'>
                        <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                        <span class="alert-inner--text">
                            {{errorMsg}}
                        </span>
                        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </base-alert>
                </div>
                <div class="col-lg-12"></div>

                <div class="col-lg-12">
                    <base-button
                        type="success"
                        @click="saveExamRecords()"
                    >
                        <i class="fa fa-save"></i>
                        {{isloading ? 'Please Wait...' : 'Add Record'}}
                    </base-button>

                    <div class="right" v-if="hasRecords">
                        <base-button
                            type="danger"
                            @click="displayAddPage()"
                        >
                            <i class="fa fa-times"></i>
                            Cancel
                        </base-button>
                    </div>
                </div>

            </div>

            <hr class="my-4"/>

            <div class="pl-lg-4">
                <base-button
                    type="danger"
                    @click="showPreviousPage()"
                >
                    <i class="fa fa-arrow-left"></i>
                    Previous
                </base-button>
                <div class="right">
                    <base-button
                        type="primary"
                        @click="continueLater()"
                    >
                        <i class="fa fa-save"></i>
                        Save &amp; Continue Later
                    </base-button>

                    <base-button
                        type="success"
                        @click="showNextPage()"
                        :disabled="! hasRecords"
                    >
                        Continue
                        <i class="fa fa-arrow-right"></i>
                    </base-button>
                </div>
            </div>


        </div>

    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import AssessmentExamData from './AssessmentExamData';
    import flatPicker from "vue-flatpickr-component";
    import "flatpickr/dist/flatpickr.css";
    import swal from 'sweetalert2';// import sweet alert
    import Autocomplete from 'vuejs-auto-complete'

    export default {
        components: {
            flatPicker,
            AssessmentExamData,
            Autocomplete
        },
        name: 'AssessmentExamInfo',
        props: ['displayEmployData', 'loadApplicantInfo', 'applicant', 'hospitals', 'displayInstitution'],
        data() {
            return {
                examRecords: [],
                paginatedItems: [],
                record: {
                    teaching_hospital_id: null,
                    venue: null,
                    date: null
                },
                pagination: {
                    total: null,
                    number: 1,
                    from: null,
                    perPage: this.$store.state.user.perPage // get per page count
                },
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isloading: false,
                showInputErrorStatus: false,
                isLoadingBlade: true,
                showAddRecord: false,
                newAccount: true,
                id: localStorage.getItem('applicantId'),
                config: {
                    allowInput: true,
                    altInput: true,// eslint-disable-next-line
                    plugins: [new monthSelectPlugin({shorthand: true, dateFormat: "Y-m-d", altFormat: "M Y"})]
                }
            };
        },
        computed: {
            ...mapGetters(['loadingBladeUrl']),
            hasPagination() {
                return this.paginatedItems && (this.paginatedItems.length > this.pagination.perPage)
                    ? true : false;
            },
            hasRecords() {
                return this.examRecords && this.examRecords.length > 0;
            },
            showOtherVenue() {
                return this.record.teaching_hospital_id == 0;
            },
            formatedHospitals() {
                let item = { id: 0, name: 'Others (Specify)'};
                let items = this.hospitals;
                items.push(item)
                return items;
            }
        },
        methods: {
            ...mapActions(['saveAssessmentApplicantData', 'deleteAssessmentExamination']),
            displayAddPage() {
                return this.showAddRecord = ! this.showAddRecord;
            },
            continueLater() {
                this.preloader();
                setTimeout(function() {
                    let msg = 'Application was updated successfully, you can continue later';
                    swal.fire("Application Updated!", msg, "success");
                    window.location.href = '/assessment-exam';
                }, 1000);
            },
            updateExamVenue(e) {
                let data = e.selectedObject;
                this.record.teaching_hospital_id = data.id;
                if(data.id > 0) { this.record.venue = data.name; }
            },
            loadExaminations() {
                this.isLoadingBlade = false;
                if(this.id) {
                    this.setData(this.applicant);
                } else {
                    swal.fire("Session Expired!", "Session timeout, try again...", "warning");
                    window.location.href = '/assessment-exam';
                }
            },
            setData(applicant) {// set paginated function
                if(applicant && applicant.examination_data) {
                    let res = applicant.examination_data;
                    this.examRecords = [];
                    this.paginatedItems = res;
                    this.pagination.total = this.paginatedItems.length; // get items total
                    let perPage = this.pagination.perPage; // get per page count
                    if(this.paginatedItems.length > perPage) {// paginate
                        let numberOfPages = Math.ceil(this.paginatedItems.length / perPage);
                        for (let index = 1; index <= numberOfPages; index++) {
                            this.examRecords.push(this.paginatedItems[index]);
                        }
                    } else {
                        this.examRecords = this.paginatedItems;
                    }
                }
            },
            paginate() {// paginate function
                this.examRecords = [];
                this.pagination.from = null;
                let page = this.pagination.number;
                let perPage = this.pagination.perPage;
                let from = (page * perPage) - perPage;
                let to = (page * perPage);
                this.pagination.from = from;//get first item number
                return this.examRecords = this.paginatedItems.slice(from, to);
            },
            showPreviousPage() {// go to next page
                return this.displayEmployData(true, true);
            },
            showNextPage() {// go to next page
                return this.displayInstitution(true);
            },
            saveExamRecords() {
                this.isloading = true;
                let data = this.validatedInputs();// validate inputs
                if(data) {// save
                    data.teaching_hospital_id  = this.record.teaching_hospital_id;
                    this.preloader();// show loading
                    let record = { 'examination_data' : data , 'id' : this.applicant.id };
                    this.saveAssessmentApplicantData(record).then((res) => {
                        this.loadApplicantInfo();
                        this.isloading = false;
                        if(res.status) {
                            this.showErrorStatus = false;
                            this.showAddRecord = false;
                            swal.fire("Record Added!", res.msg , "success");
                            this.loadExaminations();// load appointments
                            this.emptyRecords();
                        } else {
                            swal.fire("Error Occurred!", "An error occurred, kindly reload page and try again", "warning");
                        }
                    }).
                    catch(() => {
                        this.isloading = false;
                        this.emptyRecords();
                        swal.fire("Error Occurred!", "An error occurred, kindly reload page and try again", "warning");
                    });
                } else {
                    this.isloading = false;
                }
            },
            showErrorMsg() {
                this.showInputErrorStatus = true
                this.errorType = 'warning'
                this.errorMsg = 'An error occurred, kindly reload page and try again';
                var self = this;
                setTimeout(function() { self.showInputErrorStatus = false; }, 5000);
            },
            validatedInputs() {
                let data = this.record;
                let appRecord = { venue: null, date: null };
                appRecord.date = data.date == null ? this.showInuptError('Examination Date') : data.date;
                appRecord.venue  = data.venue == null ? this.showInuptError('Examination Venue') : data.venue;
                if(this.record.teaching_hospital_id > 0 && this.record.teaching_hospital_id < 0) {
                    this.showInuptError('Teaching Hospital')
                }
                return this.isEmpty(appRecord) ? appRecord : false;
            },
            isEmpty(obj) {// check for empty field
                return ! Object.keys(obj).some(k => !obj[k]);
            },
            showInuptError(name) {
                this.isloading = false;
                this.showInputErrorStatus = true
                this.errorType = 'warning'
                this.errorMsg = 'Invalid ' + name + ' has been entered';
                var self = this;
                setTimeout(function() { self.showInputErrorStatus = false; }, 5000);
            },
            emptyRecords() {
                return this.record = {
                    teaching_hospital_id: null,
                    venue: null,
                    date: null
                };
            },
            deleteExamRecord(id) {// delete examination
                this.preloader();// show loading
                this.deleteAssessmentExamination(id).then((res) => {
                    this.loadApplicantInfo();
                    if(res.status) {
                        swal.fire("Record Deleted!", res.msg , "success");
                        this.loadExaminations(); // load appointments
                    } else {
                        swal.fire("Error Occurred!", "An error occurred while deleting, try again", "warning");
                        this.loadExaminations(); // load appointments
                    }
                }).
                catch(() => {
                    swal.fire("Network Failure", "Reload page and try again...", "warning");
                    this.loadExaminations(); // load appointments
                });
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        created() {
            this.loadExaminations();// load appointments
        },
    }
</script>

<style>
.right {
    float: right;
}
</style>

<style>
    .form-control-label {
        width: 100%;
    }
</style>