<template>
    <div class="row" v-if="referees && referees.length > 0">
        <div class="col-lg-12">
            <div class="card shadow">
                <div class="table-responsive">
                    <base-table
                        class="table align-items-center table-flush"
                        thead-classes="thead-light"
                        tbody-classes="list"
                        :data="referees"
                    >
                        <template slot="columns">
                            <th width="2%">S/N</th>
                            <th>Name</th>
                            <th>Address</th>
                            <th>Phone No</th>
                            <th>Occupation</th>
                            <th width="5%">&nbsp;</th>
                        </template>

                        <template
                            slot-scope="{row, index}"
                        >
                        <td class="budget">
                            {{index+1}}
                        </td>
                        <td>
                            {{row.name}}
                        </td>
                        <td>
                            {{row.address}}
                        </td>
                        <td>
                            {{row.phone}}
                        </td>
                        <td>
                            {{row.occupation}}
                        </td>
                        <td>
                            <base-button
                                type="danger" size="sm"
                                :disabled="lock"
                                @click="$emit('deleteReferee', row.id)"
                            >
                                <i class="fa fa-trash"></i> Remove
                            </base-button>
                        </td>

                        </template>

                    </base-table>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';

    export default {
        name: 'Referees',
        props: ["referees"],
        computed: {
            ...mapGetters(['lock'])
        }
    }
</script>

<style>

</style>
