<template>
    <div>
        <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center no-padding-bottom">
            <span class="mask bg-gradient-danger opacity-8"></span>
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col-xl-12 order-xl-1">
                    <br><br><br>
                    <card shadow type="secondary">
                        <div slot="header" class="bg-white border-0">
                            <div class="row align-items-center">

                                <div class="col-8">
                                    <h3 class="mb-0 text-uppercase" v-html="title"></h3>
                                    <br>
                                    <span class="text-danger" v-html="description"></span>
                                </div>
                                <div class="col-4 text-right">
                                    <a :href="websiteUrl" class="btn btn-sm btn-danger text-white">
                                       <i class="fa fa-reply"></i> Home
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div v-if="! submitted || editApplication">
                            <!-- this is applicant's personal data template -->
                            <AssessmentPersonalData
                                :locations="locations"
                                :applicant="applicant"
                                :loadApplicantInfo="loadApplicantInfo"
                                :displayProfilePhoto="displayProfilePhoto"
                                v-if="showPersonalData && ! isLoadingBlade"
                            />

                            <!-- this is applicant's passport template -->
                            <AssessmentProfilePhoto
                                :applicant="applicant"
                                :displayProfilePhoto="displayProfilePhoto"
                                :displayEduData="displayEduData"
                                :loadApplicantInfo="loadApplicantInfo"
                                v-if="showProfilePhoto && ! isLoadingBlade"
                            />

                            <!-- this is applicant's edu data template -->
                            <AssessmentEduData
                                :applicant="applicant"
                                :displayProfilePhoto="displayProfilePhoto"
                                :displayQualData="displayQualData"
                                :loadApplicantInfo="loadApplicantInfo"
                                v-if="showEduData && ! isLoadingBlade"
                            />

                            <!-- this is applicant's prof. qualification data template -->
                            <AssessmentProfQualData
                                :applicant="applicant"
                                :displayEduData="displayEduData"
                                :displayAddiData="displayAddiData"
                                :loadApplicantInfo="loadApplicantInfo"
                                v-if="showQualData && ! isLoadingBlade"
                            />

                            <!-- this is applicant's additional data template -->
                            <AssessmentAdditionalData
                                :applicant="applicant"
                                :displayQualData="displayQualData"
                                :displayEmployData="displayEmployData"
                                :locations="locations"
                                :loadApplicantInfo="loadApplicantInfo"
                                v-if="showAddiData && ! isLoadingBlade"
                            />

                            <!-- this is applicant's employment data template -->
                            <AssessmentEmployData
                                :applicant="applicant"
                                :displayAddiData="displayAddiData"
                                :displayExamData="displayExamData"
                                :loadApplicantInfo="loadApplicantInfo"
                                v-if="showEmployData && ! isLoadingBlade"
                            />

                            <!-- this is applicant's exam data template -->
                            <AssessmentExamInfo
                                :hospitals="hospitals"
                                :applicant="applicant"
                                :displayEmployData="displayEmployData"
                                :displayInstitution="displayInstitution"
                                :locations="locations"
                                :loadApplicantInfo="loadApplicantInfo"
                                v-if="showExamData && ! isLoadingBlade"
                            />

                            <!-- this is applicant's Institution data template -->
                            <AssessmentInstitution
                                :hospitals="hospitals"
                                :applicant="applicant"
                                :displayExamData="displayExamData"
                                :displayReferee="displayReferee"
                                :locations="locations"
                                :loadApplicantInfo="loadApplicantInfo"
                                v-if="showInstitution && ! isLoadingBlade"
                            />

                            <!-- this is applicant's referee data template -->
                            <AssessmentReferee
                                :applicant="applicant"
                                :displayExamData="displayExamData"
                                :displayDocument="displayDocument"
                                :locations="locations"
                                :loadApplicantInfo="loadApplicantInfo"
                                v-if="showReferee && ! isLoadingBlade"
                            />

                            <!-- this is applicant's documents  template -->
                            <AssessmentDocuments
                                :applicant="applicant"
                                :displayReferee="displayReferee"
                                :displayPreview="displayPreview"
                                :loadApplicantInfo="loadApplicantInfo"
                                v-if="showDocument && ! isLoadingBlade"
                            />

                            <!-- this is applicant's summary template -->
                            <AssessmentPreview
                                :applicant="applicant"
                                :displayDocument="displayDocument"
                                :displayPreview="displayPreview"
                                :locations="locations"
                                :loadApplicantInfo="loadApplicantInfo"
                                :showDashbaord="showDashbaord"
                                v-if="showPreview && ! isLoadingBlade"
                            />

                            <div class="col-lg-12" v-if="isLoadingBlade">
                                <div class="d-flex justify-content-center">
                                    <img :src="loadingBladeUrl" />
                                </div>
                            </div>

                            <div class="col-lg-12 text-center" v-if='showErrorStatus && ! isLoadingBlade'>
                                <base-alert :type="errorType">
                                    <!-- <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span> -->
                                    <span class="alert-inner--text">
                                        <h2 class="text-white">{{errorMsg}}</h2>
                                    </span>
                                </base-alert>
                            </div>
                        </div>

                        <!-- This is submitted assessment template here -->
                        <SubmittedAssessment
                            :applicant="applicant"
                            :changeTitle="changeTitle"
                            :editAssessmentApp="editAssessmentApp"
                            :loadAssessmentBatch="loadAssessmentBatch"
                            :batchIsSuspended="batchIsSuspended"
                            v-if="submitted && ! editApplication"
                        />

                    </card>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import AssessmentPersonalData from './AssessmentPersonalData';
    import AssessmentProfilePhoto from './AssessmentProfilePhoto';
    import AssessmentEduData from './AssessmentEduData';
    import AssessmentProfQualData from './AssessmentProfQualData';
    import AssessmentEmployData from './AssessmentEmployData';
    import AssessmentAdditionalData from './AssessmentAdditionalData';
    import AssessmentExamInfo from './AssessmentExamInfo';
    import AssessmentInstitution from './AssessmentInstitution';
    import AssessmentPreview from './AssessmentPreview';
    import AssessmentReferee from './AssessmentReferee';
    import AssessmentDocuments from './AssessmentDocuments';
    import SubmittedAssessment from './SubmittedAssessment';
    import swal from 'sweetalert2';// import sweet alert

    export default {
        name: 'AssessmentForm',
        components: {
            AssessmentPersonalData,
            AssessmentProfilePhoto,
            AssessmentEduData,
            AssessmentProfQualData,
            AssessmentEmployData,
            AssessmentAdditionalData,
            AssessmentExamInfo,
            AssessmentInstitution,
            AssessmentPreview,
            AssessmentReferee,
            AssessmentDocuments,
            SubmittedAssessment
        },
        data() {
            return {
                applicant: null,
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isLoadingBlade: true,
                id: localStorage.getItem('applicantId'),
                showPersonalData: true,
                showProfilePhoto: false,
                showEduData: false,
                showQualData: false,
                showEmployData: false,
                showAddiData: false,
                showExamData: false,
                showInstitution: false,
                showPreview: false,
                showReferee: false,
                showDocument: false,
                hospitals: [],
                code: this.$route.name,
                title: null,
                description: null,
                editApplication: false
            }
        },
        computed: {
            ...mapGetters(['websiteUrl', 'locations', 'loadingBladeUrl']),
            submitted() {
                return this.applicant && (this.applicant.status == 'submitted' || this.applicant.status == 'approved'
                    || this.applicant.status == 'rejected');
            },
            batchIsSuspended() {// check if batch is suspended
                return this.applicant && this.applicant.batch && (this.applicant.batch.status == 'suspended');
            }
        },
        methods: {
            ...mapActions(['getLocations', 'getApplicantInfo', 'getAssessmentBatch']),
            showDashbaord() {
                return this.editApplication = false;
            },
            editAssessmentApp() {
                this.editApplication = true;
                let title = 'Update Assessment Exam Form';
                let des = 'Kindly proceed to correct your application form below...';
                this.changeTitle(title, des, true);
            },
            checkSessionTime() {
                let timeout = localStorage.getItem('timeout')
                if(timeout) {// check if expire
                    if (timeout < new Date().getTime()) {//session time out
                        localStorage.clear();
                        window.location.reload();
                    }
                } else {// create one
                    let hours = 3;
                    return localStorage.setItem('timeout', new Date().getTime() + (hours * 60 * 60 * 1000));// set lock time
                }
            },
            loadTitle() {// load title
                return this.title = this.replaceString(this.code);
            },
            replaceString(value) {
                return value ? value.replace('-', ' ') : 'Assessment';
            },
            changeTitle(value, des, status) {// change title
                let description = des ? des : 'Kindly fill the form below with correct information...';
                if(status) {
                    this.title = value;
                    this.description = description;
                } else {
                    this.loadTitle();
                }
            },
            loadAssessmentBatch() {
                this.checkSessionTime();
                this.editApplication = false;
                if(this.id) {
                    this.isLoadingBlade = true;
                    this.getAssessmentBatch({ type : 'form' }).then((res) => {
                        this.isLoadingBlade = false;
                        if(res.status) {
                            this.loadApplicantInfo();
                            this.loadTitle();
                            this.showErrorStatus = false;
                        } else {
                            window.location.href = '/assessment-exam';
                        }
                    }).
                    catch(() => {
                        this.showErrorStatus = true;
                        this.isLoadingBlade = false;
                        this.errorMsg = "Network Failure!, kindly reload the page...";
                        this.errorType = 'danger';
                    });
                } else {
                    swal.fire("Session Expired!", "Session timeout, try again...", "warning");
                    window.location.href = '/assessment-exam';
                }

            },
            loadApplicantInfo() {
                this.isLoadingBlade = true;
                this.applicant = null;
                this.getApplicantInfo(this.id).then((res) => {
                    this.isLoadingBlade = false;
                    if(res.status) {
                        this.showErrorStatus = false;
                        this.applicant = res.applicant;
                        this.hospitals = res.hospitals;
                        this.getLocations();
                    } else {
                        this.showErrorStatus = true;
                        this.errorMsg = res.msg;
                        this.errorType = 'danger';
                    }
                }).
                catch(() => {
                    this.showErrorStatus = true;
                    this.isLoadingBlade = false;
                    this.errorMsg = "Network Failure!, kindly reload the page...";
                    this.errorType = 'danger';
                });
            },
            displayProfilePhoto(value) {
                this.showPersonalData = ! value;
                this.showQualData = false;
                this.showEmployData = false;
                this.showAddiData = false;
                this.showEduData = false;
                this.showExamData = false;
                this.showInstitution = false;
                this.showPreview = false;
                this.showReferee = false;
                this.showDocument = false;
                this.showProfilePhoto = value;
            },
            displayEduData(value) {
                this.showPersonalData = ! value;
                this.showProfilePhoto = ! value;
                this.showQualData = ! value;
                this.showEmployData = ! value;
                this.showAddiData = ! value;
                this.showExamData = ! value;
                this.showInstitution = ! value;
                this.showPreview = ! value;
                this.showReferee = ! value;
                this.showDocument = ! value;
                this.showEduData = value;
            },
            displayQualData(value) {
                this.showPersonalData = ! value;
                this.showProfilePhoto = ! value;
                this.showEduData = ! value
                this.showEmployData = ! value;
                this.showAddiData = ! value;
                this.showExamData = ! value;
                this.showInstitution = ! value;
                this.showPreview = ! value;
                this.showReferee = ! value;
                this.showDocument = ! value;
                this.showQualData = value;
            },
            displayAddiData(value) {
                this.showPersonalData = ! value;
                this.showProfilePhoto = ! value;
                this.showEduData = ! value
                this.showQualData = ! value;
                this.showEmployData = ! value;
                this.showExamData = ! value;
                this.showInstitution = ! value;
                this.showPreview = ! value;
                this.showReferee = ! value;
                this.showDocument = ! value;
                this.showAddiData = value;
            },
            displayEmployData(value, status = false) {
                if(status) {
                    if(this.applicant && this.applicant.additional_data && this.applicant.additional_data.employ_status
                        && this.applicant.additional_data.employ_status == 'yes') {
                        this.loadApplicantInfo();
                        this.showPersonalData = ! value;
                        this.showProfilePhoto = ! value;
                        this.showEduData = ! value
                        this.showQualData = ! value;
                        this.showAddiData = ! value;
                        this.showExamData = ! value;
                        this.showInstitution = ! value;
                        this.showPreview = ! value;
                        this.showReferee = ! value;
                        this.showDocument = ! value;
                        this.showEmployData = value;
                    } else {
                        this.displayAddiData(true);
                    }
                } else {
                    if(this.applicant && this.applicant.additional_data && this.applicant.additional_data.employ_status
                        && this.applicant.additional_data.employ_status == 'yes') {
                        this.loadApplicantInfo();
                        this.showPersonalData = ! value;
                        this.showProfilePhoto = ! value;
                        this.showEduData = ! value
                        this.showQualData = ! value;
                        this.showAddiData = ! value;
                        this.showExamData = ! value;
                        this.showInstitution = ! value;
                        this.showPreview = ! value;
                        this.showReferee = ! value;
                        this.showDocument = ! value;
                        this.showEmployData = value;
                    } else {
                        this.displayExamData(true);
                    }
                }
            },
            displayExamData(value, status = false) {
                if(status) {
                    if(this.applicant && this.applicant.additional_data && this.applicant.additional_data.exam_taken
                        && this.applicant.additional_data.exam_taken == 'yes') {
                        this.loadApplicantInfo();
                        this.showPersonalData = ! value;
                        this.showProfilePhoto = ! value;
                        this.showEduData = ! value
                        this.showQualData = ! value;
                        this.showEmployData = ! value;
                        this.showAddiData = ! value;
                        this.showInstitution = ! value;
                        this.showPreview = ! value;
                        this.showReferee = ! value;
                        this.showDocument = ! value;
                        this.showExamData = value;
                    } else {
                        this.displayEmployData(true, true);
                    }
                } else {
                    if(this.applicant && this.applicant.additional_data && this.applicant.additional_data.exam_taken
                        && this.applicant.additional_data.exam_taken == 'yes') {
                        this.loadApplicantInfo();
                        this.showPersonalData = ! value;
                        this.showProfilePhoto = ! value;
                        this.showEduData = ! value
                        this.showQualData = ! value;
                        this.showEmployData = ! value;
                        this.showAddiData = ! value;
                        this.showInstitution = ! value;
                        this.showPreview = ! value;
                        this.showReferee = ! value;
                        this.showDocument = ! value;
                        this.showExamData = value;
                    } else {
                        this.displayInstitution(true);
                    }
                }
            },
            displayInstitution(value, status = false) {
                if(status) {
                    if(this.applicant && this.applicant.additional_data && this.applicant.additional_data.remedial_course
                        && this.applicant.additional_data.remedial_course == 'yes') {
                        this.loadApplicantInfo();
                        this.showPersonalData = ! value;
                        this.showProfilePhoto = ! value;
                        this.showEduData = ! value
                        this.showQualData = ! value;
                        this.showEmployData = ! value;
                        this.showAddiData = ! value;
                        this.showExamData = ! value;
                        this.showPreview = ! value;
                        this.showReferee = ! value;
                        this.showDocument = ! value;
                        this.showInstitution = value;
                    } else {
                        this.displayExamData(true , true);
                    }
                } else {
                    if(this.applicant && this.applicant.additional_data && this.applicant.additional_data.remedial_course
                        && this.applicant.additional_data.remedial_course == 'yes') {
                        this.loadApplicantInfo();
                        this.showPersonalData = ! value;
                        this.showProfilePhoto = ! value;
                        this.showEduData = ! value
                        this.showQualData = ! value;
                        this.showEmployData = ! value;
                        this.showAddiData = ! value;
                        this.showExamData = ! value;
                        this.showPreview = ! value;
                        this.showReferee = ! value;
                        this.showDocument = ! value;
                        this.showInstitution = value;
                    } else {
                        this.displayReferee(true);
                    }
                }
            },
            displayReferee(value) {
                this.showPersonalData = ! value;
                this.showProfilePhoto = ! value;
                this.showEduData = ! value
                this.showQualData = ! value;
                this.showEmployData = ! value;
                this.showExamData = ! value;
                this.showInstitution = ! value;
                this.showAddiData = ! value;
                this.showPreview = ! value;
                this.showDocument = ! value;
                this.showReferee = value;
            },
            displayDocument(value) {
                this.showPersonalData = ! value;
                this.showProfilePhoto = ! value;
                this.showEduData = ! value
                this.showQualData = ! value;
                this.showEmployData = ! value;
                this.showExamData = ! value;
                this.showInstitution = ! value;
                this.showAddiData = ! value;
                this.showPreview = ! value;
                this.showReferee = ! value;
                this.showDocument = value;
            },
            displayPreview(value) {
                this.loadApplicantInfo();
                this.showPersonalData = ! value;
                this.showProfilePhoto = ! value;
                this.showEduData = ! value
                this.showQualData = ! value;
                this.showEmployData = ! value;
                this.showExamData = ! value;
                this.showInstitution = ! value;
                this.showAddiData = ! value;
                this.showReferee = ! value;
                this.showDocument = ! value;
                this.showPreview = value;
            }
        },
        created() {
            this.loadAssessmentBatch();
        }
    }
</script>

<style scoped>
    .no-padding-bottom {
        padding-bottom: 0rem !important
    }
    .right {
        float: right;
    }
</style>

<style>
    .form-control-label {
        width: 100%;
    }
    .height-50 {
        height: 50px;
    }
    .borderLine {
        border: 1px solid #ededed;
    }
</style>