<template>
    <div>
        <div class="row" v-if='! showErrorStatus && ! isLoadingBlade'>
            <div class="col-lg-12">
                <h3 class="text-uppercase">Temporary Registration Verification Payment Page</h3>
                <span class="text-danger">
                    Kindly enter a valid RRR number (must have been paid) and proceed to create a signup account on our
                    portal and wait for approval by MDCN before you can proceed to complete registration.
                    Keep your RRR number safe...
                </span>
                <hr>
            </div>

            <div class="col-lg-4"></div>
            <div class="col-lg-4 text-center">
                <base-input alternative=""
                    label="RRR"
                    placeholder="Enter Valid RRR"
                    input-classes="form-control-alternative"
                    v-model="rrr"
                    required
                />
            </div>
        </div>

        <div class="col-lg-12" v-if="isLoadingBlade">
            <div class="d-flex justify-content-center">
                <img :src="loadingBladeUrl" />
            </div>
        </div>

        <div class="col-lg-12 text-center" v-if='showErrorStatus && ! isLoadingBlade'>
            <base-alert :type="errorType">
                <!-- <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span> -->
                <span class="alert-inner--text">
                    <h2 class="text-white">{{errorMsg}}</h2>
                </span>
            </base-alert>
        </div>

        <div v-if='! showErrorStatus && ! isLoadingBlade'>
            <hr>
            <base-button
                type="danger"
                @click="hidePolicyPage(false)"
            >
                <i class="fa fa-arrow-left"></i>
                Go Back
            </base-button>

            <base-button
                type="success"
                class="right"
                :disabled="! rrr"
                @click="getRRRStatus()"
            >
                {{ isloading ? 'Please Wait...' : 'Proceed Now'}}
                <i class="fa fa-arrow-right"></i>
            </base-button>
            <br><br><br><br>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import swal from 'sweetalert2';// import sweet alert

    export default {
        name: 'TempRegistrationPage',
        props: ['hidePolicyPage'],
        data() {
            return {
                rrr: null,
                isloading: false,
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isLoadingBlade: false
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl'])
        },
        methods: {
            ...mapActions(['getRemitaPaymentStatus']), 
            getRRRStatus() {
                if(this.rrr) {
                    this.isloading = true;
                    this.preloader();
                    this.getRemitaPaymentStatus({'rrr': this.rrr, code : 'temp-verify-reg'}).then((res) => {
                        this.isLoadingBlade = false;
                        this.isloading = false;
                        if(res.status) {
                            swal.close();
                            const rrr = res.details && res.details.RRR ? res.details.RRR : res.details.rrr; // get exact rrr varaible 
                            return window.location.href = '/temp-doctor-signup?rrr='+ rrr;
                        } else {
                            this.isLoadingBlade = false;
                            swal.fire("Payment Failed!", res.msg, "warning");
                            setTimeout(function() { window.location.href = '/temp-doctor-register'; }, 1000);
                        }
                    }).
                    catch(() => {
                        let msg = "Network Failure!, kindly reload the page...";
                        swal.fire("Error Ocurred!", msg, "warning");
                        setTimeout(function() { window.location.href = '/temp-doctor-register'; }, 1000);
                    });
                } else {
                    let msg = "Invalid RRR number was provided, try again...";
                    swal.fire("Invalid RRR!", msg, "warning");
                    setTimeout(function() { window.location.href = '/temp-doctor-register'; }, 1000);
                }
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        }
    }
</script>

<style scoped>
    .no-padding-bottom {
        padding-bottom: 0rem !important
    }
    .right {
        float: right;
    }
</style>

<style>
    .form-control-label {
        width: 100%;
    }
    .height-50 {
        height: 50px;
    }
    .borderLine {
        border: 1px solid #ededed;
    }
</style>