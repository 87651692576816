<template>
    <div class="row">

        <div class="col-lg-12" v-if="isLoadingBlade">
            <div class="d-flex justify-content-center">
                <img :src="loadingBladeUrl" />
            </div>
        </div>

        <div class="container" v-if='! showErrorStatus && ! isLoadingBlade'>
            <div class="row">
                <div :class="'col-lg-'+(showOtherInstitution ? 4 : 6)">
                    <base-input alternative=""
                        label="Country"
                        required
                    >
                        <autocomplete
                            v-if="locations && locations.countries"
                            input-class="form-control form-control-alternative"
                            placeholder="Type Country"
                            showNoResults
                            :source="locations.countries"
                            v-model="record.country_id"
                            :initialDisplay="(hasCountry ? hasCountry.name : '')"
                            @selected="changeCountry"
                        >
                        </autocomplete>
                    </base-input>
                </div>

                <div :class="'col-lg-'+(showOtherInstitution ? 4 : 6)">
                    <base-input alternative=""
                        label="School Name / Institution (Post-Graduate College)"
                        required
                    >
                        <autocomplete
                            ref="schoolAutocomplete"
                            input-class="form-control form-control-alternative"
                            placeholder="Type to search Institution"
                            showNoResults
                            :disableInput="schools && schools.length == 0"
                            :source="schools"
                            :initialDisplay="(record.school ? record.school : '')"
                            resultsValue="name"
                            @selected="changeInstitution"
                        >
                        </autocomplete>
                    </base-input>
                </div>

                <div class="col-lg-4" v-if="showOtherInstitution">
                    <base-input alternative=""
                        label="Other School/Institution Name"
                        placeholder="Enter Other School/Institution Name"
                        input-classes="form-control-alternative"
                        v-model="record.other_school_name"
                        required
                        :readonly="showReSendButton"
                    />
                </div>

                <div class="col-lg-12 text-capitalize" v-if="qualifications && qualifications.length > 0">
                    <base-input alternative=""
                        label="Qualification"
                        required
                        >

                        <autocomplete
                            input-class="form-control form-control-alternative text-capitalize"
                            placeholder="Type to Search for Qualification"
                            showNoResults
                            :disableInput="qualifications && qualifications.length == 0"
                            :source="qualifications"
                            :initialDisplay="(hasQual ? hasQual : '')"
                            resultsValue="name"
                            :results-display="formattedDisplay"
                            @selected="changeQualification"
                        >
                        </autocomplete>
                    </base-input>
                </div>


                <div class="col-lg-12" v-if="degrees && degrees.length > 0">
                    <base-input alternative=""
                        v-if="degrees[0] != ''"
                        label="Degree"
                        required
                        >
                        <div class="container">
                            <div class="row">
                                <base-radio
                                    v-for="(degree, index) in degrees"
                                    :key="index"
                                    :name="degree.trim()"
                                    class="mb-3"
                                    v-model="record.degree"
                                    :disabled="showReSendButton"
                                >
                                    {{ degree.trim() }} &nbsp;&nbsp;&nbsp;
                                </base-radio>
                            </div>
                        </div>

                    </base-input>
                </div>

                <div class="col-lg-6">
                    <base-input
                        label="The Additional Qualification Degree Date"
                        addon-left-icon="ni ni-calendar-grid-58"
                        required
                    >
                        <flat-picker
                            slot-scope="{focus, blur}"
                            @on-open="focus"
                            @on-close="blur"
                            :config="config"
                            class="form-control datepicker"
                            v-model="record.qualify_date"
                            :disabled="showReSendButton"
                        >
                        </flat-picker>
                    </base-input>
                </div>

                <div class="col-lg-6">
                    <base-input alternative=""
                        label="Issuing Institution"
                        placeholder="Enter Issuing Institution"
                        input-classes="form-control-alternative text-capitalize"
                        v-model="record.licencing_body"
                        :readonly="showReSendButton"
                    />
                </div>

                <div class="col-lg-6">
                    <base-input alternative=""
                        label="Duration of training (In weeks)"
                        placeholder="Enter Duration of training"
                        input-classes="form-control-alternative text-capitalize"
                        v-model="record.duration"
                        type="number"
                        :readonly="showReSendButton"
                    />
                </div>

                <div class="col-lg-6">
                    <base-input
                        label="Notification Date"
                        addon-left-icon="ni ni-calendar-grid-58"
                        required
                    >
                        <flat-picker
                            slot-scope="{focus, blur}"
                            @on-open="focus"
                            @on-close="blur"
                            :config="config"
                            class="form-control datepicker"
                            v-model="record.notification_date"
                            :disabled="showReSendButton"
                        >
                        </flat-picker>
                    </base-input>
                </div>

                <div class="col-lg-12">
                    <hr>
                    <h4 class="heading text-muted mb-4">
                        <!-- Upload Files &nbsp;&nbsp;&nbsp;&nbsp; -->
                        <span class="text-danger">
                            <i class="fa fa-info-circle"></i> Only PDF Files (Not More than 300KB) are supported for each file
                        </span>            
                    </h4>
                </div>

                <div
                    class="col-lg-4 text-center borderLine"
                    v-for="(document, index) in documents"
                    :key="index"
                >
                    <br>
                    <input
                        type="file"
                        id="file" style="display: none"
                        @change="onFileSelected"
                    >
                    <h4 class="text-capitalize height-50" v-html="document.name"></h4><br>
                    <base-button
                        size="sm" type="primary"
                        v-if="! selectedCert && (document.filename =='additional_certificate.pdf')"
                        @click="selectFile(document.filename)"
                        :disabled="showReSendButton"
                    >
                        <i class="fa fa-hand-pointer"></i>
                        {{ aqFiles.certificate_file ? 'Change File' : 'Select File' }}
                    </base-button>

                    <base-button
                        tag="a" size="sm"
                        type="success"
                        :href="documentUrl+aqId+'_'+document.filename"
                        target="_blank"
                        role="button"
                        aria-pressed="true"
                        v-if="aqFiles.certificate_file && ! selectedCert &&
                        (document.filename =='additional_certificate.pdf')"
                    >
                        <i class="fa fa-eye"></i> View File
                    </base-button>

                    <base-button
                        size="sm" type="primary"
                        v-if="! selectedRegCert && (document.filename =='registration_certificate.pdf')"
                        @click="selectFile(document.filename)"
                        :disabled="showReSendButton"
                    >
                        <i class="fa fa-hand-pointer"></i>
                        {{ aqFiles.registration_file ? 'Change File' : 'Select File' }}
                    </base-button>

                    <base-button
                        tag="a" size="sm"
                        type="success"
                        :href="documentUrl+aqId+'_'+document.filename"
                        target="_blank"
                        role="button"
                        aria-pressed="true"
                        v-if="aqFiles.registration_file && ! selectedRegCert &&
                        (document.filename =='registration_certificate.pdf')"
                    >
                        <i class="fa fa-eye"></i> View File
                    </base-button>

                    <base-button
                        size="sm" type="primary"
                        v-if="! selectedNotifyCert && (document.filename =='notification_certificate.pdf')"
                        @click="selectFile(document.filename)"
                        :disabled="showReSendButton"
                    >
                        <i class="fa fa-hand-pointer"></i>
                        {{ aqFiles.notification_file ? 'Change File' : 'Select File' }}
                    </base-button>

                    <base-button
                        tag="a" size="sm"
                        type="success"
                        :href="documentUrl+aqId+'_'+document.filename"
                        target="_blank"
                        role="button"
                        aria-pressed="true"
                        v-if="aqFiles.notification_file && ! selectedNotifyCert &&
                        (document.filename =='notification_certificate.pdf')"
                    >
                        <i class="fa fa-eye"></i> View File
                    </base-button>

                    <base-button
                        size="sm" type="danger"
                        v-if="(registrationFile.name == document.filename || certificateFile.name == document.filename
                                || notificationFile.name == document.filename)
                            && (registrationFile.name || certificateFile.name || notificationFile.name)"
                        @click="removeFile(document.filename)"
                    >
                        <i class="fa fa-times"></i> Remove File
                    </base-button>
                    <br><br>

                </div>
            </div>
        </div>

        <div class="col-sm-12 text-center">
            <base-alert :type="errorType" v-if='showInputErrorStatus'>
                <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                <span class="alert-inner--text">
                    {{errorMsg}}
                </span>
            </base-alert>
        </div>

        <div class="col-lg-12" v-if='! showErrorStatus && ! isLoadingBlade'>
            <hr>
            <base-button
                type="success"
                @click="saveAdditionalQualifcation()"
                v-if="! showReSendButton"
            >
                <i class="fa fa-save"></i>
                {{isloading ? 'Please Wait...' : 'Save & Continue'}}
            </base-button>

            <base-button
                type="success"
                @click="reSendApplication(applicationId)"
                v-if="showReSendButton"
            >
                <i class="fa fa-upload"></i>
                Re-send Application
            </base-button>

            <div class="right">
                <base-button
                    type="danger"
                    @click="editApplication()"
                    v-if="showReSendButton"
                >
                    <i class="fa fa-pen"></i>
                    Edit Application
                </base-button>
            </div>
        </div>

        <div class="col-sm-12 text-center">
            <base-alert :type="errorType" v-if='showErrorStatus'>
                <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                <span class="alert-inner--text">
                    {{errorMsg}}
                </span>
            </base-alert>
        </div>

    </div>

</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import flatPicker from "vue-flatpickr-component";
    import "flatpickr/dist/flatpickr.css";
    import swal from 'sweetalert2';// import sweet alert
    import Autocomplete from 'vuejs-auto-complete';

    export default {
        name: 'ApplyAddQualification',
        components: {
            flatPicker,
            Autocomplete
        },
        data() {
            return {
                qualifications: [],
                degrees: [],
                schools: [],
                record: {
                    school: null,
                    country_id: null,
                    category: null,
                    qualification: null,
                    degree: null,
                    qualify_date: null,
                    licencing_body: null,
                    other_school_name: null,
                    duration: null,
                    notification_date: null
                },
                certificateFile: {
                    'name' : null,
                    'file' : null
                },
                registrationFile: {
                    'name' : null,
                    'file' : null
                },
                notificationFile: {
                    'name' : null,
                    'file' : null
                },
                aqFiles: {
                    certificate_file: false,
                    registration_file: false,
                    notification_file: false,
                },
                selectedFileName: null,
                showInputErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isloading: false,
                selectedCert: false,
                selectedRegCert: false,
                selectedNotifyCert: false,
                showOtherInstitution: false,
                showErrorStatus: false,
                applicationId: this.$route.params.id,
                aqId: null,
                isRejected: false,
                showReSendButton: false,
                isLoadingBlade: true,
                config: {
                    maxDate: new Date().fp_incr(1),
                    altInput: true,// eslint-disable-next-line
                    plugins: [new monthSelectPlugin({shorthand: true, dateFormat: "Y-m-d", altFormat: "M Y"})]
                }
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl', 'getRootUrl', 'locations']),
            documents() {
                return [
                    {
                        'name' : 'AQ Notification Certificate <br> (required)',
                        'filename' : 'notification_certificate.pdf'
                    },
                    {
                        'name' : 'Full Registration Certificate <br> (required)',
                        'filename' : 'registration_certificate.pdf'
                    },
                    {
                        'name' : 'Postgraduate Certificate being registered obtained (Optional)',
                        'filename' : 'additional_certificate.pdf'
                    }
                ];
            },
            documentUrl() {// get AQ document url
                let userFolder = '/user'+this.$store.state.user.userId;
                return this.getRootUrl+'/storage'+userFolder+'/documents/additional_qualification/';
            },
            hasCountry() {
                let country = this.locations && this.locations.countries ?
                            this.locations.countries.filter(item => item.id == this.record.country_id) : null;
                return country ? country[0] : {};
            },
            hasQual() {
                let qual = this.qualifications ?
                            this.qualifications.filter(item => item.id == this.record.qualification) : null;
                return qual ? this.formattedDisplay(qual[0]) : {};
            }
        },
        methods: {
            ...mapActions(['getAdditionalQulifications', 'getLocations', 'getUserPendingAq', 'getAssessmentInstitutions',
                'addNewAdditionalQualification', 'getApplication', 'reSubmitApplication']),
            changeQualification(e) {
                let data = e.selectedObject;
                this.record.qualification = null;
                this.record.qualification = data.id;
                this.getDegrees();
            },
            formattedDisplay (result) {
                return result && result.course_title ? result.course_title.toLowerCase() + ' [' + 
                                  (result.category ? result.category.name : '') + ']' : '';
            }, 
            changeCountry(e, country = false) {// get exact institutions
                this.showOtherInstitution = false;
                this.schools = []; let id;
                if(country && (country >= 0)) {
                    id = country;
                } else {
                    if(e && e.selectedObject) {
                        id = e.selectedObject.id;
                    }
                    this.record.school = null;
                    this.record.other_school_name = null;
                    this.$refs.schoolAutocomplete.clear();
                }
                this.preloader();
                this.getAssessmentInstitutions({ id : id }).then((res) => {
                    swal.close();
                    this.schools = res.schools ? res.schools : [];
                    let other = { id : 0, name: 'Others (Specify)'};
                    this.schools.push(other);
                });
            },
            changeInstitution(e) {
                let data = e.selectedObject;
                this.record.other_school_name = null;
                this.showOtherInstitution = false;
                if(data.id == 0) {
                    this.showOtherInstitution = true;
                } else {
                    this.record.school = data.name;
                }
            },
            loadAddQualifications() {// load details
                this.getLocations();
                if(this.applicationId) {// edit pending AQ application
                    this.getApplication({'id' : this.applicationId}).then((res) => {
                        this.isRejected = res.status == 'rejected' ? true : false;
                        this.updateAQRecord(res);// update AQ record for editing
                        this.isLoadingBlade = false;
                    }).
                    catch(() => {
                        this.isLoadingBlade = false;
                        this.showErrorStatus = true;
                        this.errorMsg = "Network Failure!, kindly reload the page...";
                        this.errorType = 'danger';
                    });
                } else {// new AQ application
                    let id = this.$store.state.user.userId;// get user id
                    this.getUserPendingAq({'id' : id}).then((res) => {// get pending Aq
                        this.qualifications = res.qualifications;
                        if(res.app_status) {// redirect to edit UI
                            return window.location.href ='/pending-application/'+res.application.id;
                        }
                    }).
                    catch(() => {
                        this.showErrorStatus = true;
                        this.errorMsg = "Network Failure!, kindly reload the page...";
                        this.errorType = 'danger';
                    });
                    this.isLoadingBlade = false;
                }
            },
            updateAQRecord(res) {// update AQ Record for editing
                let data = res.has_additional_qualification;
                if(data) {
                    this.record.school = data.school_name;
                    this.record.country_id = data.country_id;
                    this.record.qualification = data.additional_qualification_config_id;
                    this.record.degree = data.degree ? data.degree : null;
                    this.record.qualify_date = data.qualify_date;
                    this.record.licencing_body = data.licencing_body;
                    this.record.duration = data.duration;
                    this.record.notification_date = data.notification_date;
                    if(data.documents && data.documents.length > 0) {// update document status
                        this.aqFiles.certificate_file = data.documents[0].hasFile;
                        this.aqFiles.registration_file = data.documents[1].hasFile;
                        this.aqFiles.notification_file = data.documents[2].hasFile;
                    } else {
                        this.aqFiles.certificate_file = false;
                        this.aqFiles.registration_file = false;
                        this.aqFiles.notification_file = false;
                    }
                    this.aqId = data.id;
                    data.country_id ? this.changeCountry(null, data.country_id) : false;// get institutions
                }
                this.qualifications = res.qualifications;
                this.getDegreesForEditing();// load degrees
            },
            checkForPendingAq() {
                let id = this.$store.state.user.userId;// get user id
                this.getUserPendingAq({'id' : id}).then((res) => {// get add qual records
                    this.pendingAq = res;
                });
            },
            getDegreesForEditing() {// get Qualification's degrees
                this.degrees = [];
                for(let qualification of this.qualifications) {
                    if(qualification.id == this.record.qualification) {
                        let qual = qualification.qualification ? qualification.qualification : false;
                        return this.degrees = qual ? qual.split(',') : [];
                    }
                }
            },
            getDegrees() {// get Qualification's degrees
                this.degrees = [];
                this.record.degree = null;
                for(let qualification of this.qualifications) {
                    if(qualification.id == this.record.qualification) {
                        return this.degrees = qualification.qualification.split(',');
                    }
                }
            },
            selectFile(name) {// eslint-disable-next-line
                $('#file').val('');
                this.selectedFileName = null;// eslint-disable-next-line
                $('#file').click();
                this.selectedFileName = name;
                if(name == 'additional_certificate.pdf') {
                    this.certificateFile.name = name;
                } else if(name == 'registration_certificate.pdf') {
                    this.registrationFile.name = name;
                } else {
                    this.notificationFile.name = name;
                }
            },
            onFileSelected(event) {
                let status =  this.validateFile(event.target.files[0]);
                if(status) {
                    var fileReader = new FileReader();
                    fileReader.readAsDataURL(event.target.files[0]);
                    fileReader.onload = (event) => {// convert file to binary
                        var file = event.target.result;
                        if(this.selectedFileName == 'additional_certificate.pdf') {
                            this.certificateFile.file = file;
                            this.selectedFileName = null;
                        } else if(this.selectedFileName == 'registration_certificate.pdf') {
                            this.registrationFile.file = file;
                            this.selectedFileName = null;
                        } else if(this.selectedFileName == 'notification_certificate.pdf'){
                            this.notificationFile.file = file;
                            this.selectedFileName = null;
                        }

                    }
                }                
                return status;
            },
            validateFile(file) {// check for valid file
                if(!file) {// update seleted file name
                    if(this.selectedFileName == 'additional_certificate.pdf') {
                        this.certificateFile.name = null
                    } else if(this.selectedFileName == 'registration_certificate.pdf') {
                        this.registrationFile.name = null;
                    } else {
                        this.notificationFile.name = null;
                    }
                }
                var fileExt = file.type.split('/').pop();
                let fileSize = file.size;
                if(fileExt == 'pdf' && fileSize <= 300000) {// for valid file and size
                    if(this.selectedFileName == 'additional_certificate.pdf') {
                        this.selectedCert = true
                    } else if(this.selectedFileName == 'registration_certificate.pdf') {
                        this.selectedRegCert = true;
                    } else {
                        this.selectedNotifyCert = true;
                    }
                    return true;
                } else if(fileExt != 'pdf') {// invalid file
                    this.removeAllFiles();
                    swal.fire("Invalid File!", "Only PDF files are accepted", "warning");
                    return false;
                } else if(fileSize > 300000) {// big file
                    this.removeAllFiles();
                    swal.fire("File is too large!", "File should not be more than 300KB", "warning");
                    return false;
                }
            },
            removeAllFiles() {// eslint-disable-next-line
                $('#file').val('');
                this.certificateFile.name = null;
                this.certificateFile.savedEvent = null;
                this.certificateFile.file = null;
                this.selectedCert = false;
                this.registrationFile.name = null;
                this.registrationFile.savedEvent = null;
                this.registrationFile.file = null;
                this.selectedRegCert = false;
                this.notificationFile.name = null;
                this.notificationFile.savedEvent = null;
                this.notificationFile.file = null;
                this.selectedNotifyCert = false;
            },
            removeFile(name) {// eslint-disable-next-line
                $('#file').val('');
                if(name == 'additional_certificate.pdf') {
                    this.certificateFile.name = null;
                    this.certificateFile.savedEvent = null;
                    this.certificateFile.file = null;
                    this.selectedCert = false;
                } else if(name == 'registration_certificate.pdf') {
                    this.registrationFile.name = null;
                    this.registrationFile.savedEvent = null;
                    this.registrationFile.file = null;
                    this.selectedRegCert = false;
                } else {
                    this.notificationFile.name = null;
                    this.notificationFile.savedEvent = null;
                    this.notificationFile.file = null;
                    this.selectedNotifyCert = false;
                }
            },
            saveAdditionalQualifcation() {// save add qual
                let data = this.validatedInputs();// validate inputs
                if(data) {// update files
                    this.isloading = true;
                    data.certificate_file = this.certificateFile;
                    data.registration_file = this.registrationFile;
                    data.notification_file = this.notificationFile;
                    data.degree = (this.record.degree ? this.record.degree : null);// get degree
                    data.user_data_id = this.$store.state.user.basicProfile.id;// get user id
                    data.user_id = this.$store.state.user.userId;// get user id
                    data.code = this.$route.params.code;
                    data.id = this.applicationId;// get id
                    if(this.applicationId) {
                        data.option = 'update';
                    } else {
                        data.option = 'save';
                        data.trans_no = this.genRandomString();// generate trans no
                    }
                    this.addNewAdditionalQualification(data).then((res) => {
                        this.isloading = false;
                        if(res.status && this.isRejected) {
                            let msg = "Application was updated successfully";
                            swal.fire("Application Updated!", msg, "success");
                            return this.showReSendButton = true;
                        } else if(res.status && ! this.isRejected) {
                            return window.location.href='/new-application-payment/'+res.application.application_type_id;
                        } else {
                            swal.fire("Error Occurred!", "An error occurred while saving, try again", "warning");
                        }
                    }).
                    catch(err => {
                        this.isloading = false;
                        swal.fire(err.message+"!", "An error occurred while saving, try again", "warning");
                    });
                }
            },
            genRandomString() {
                var length = 12;
                var text = '';
                var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
                for (var i=0; i < length; i++) {
                    text += possible.charAt(Math.floor(Math.random() * possible.length));
                }
                return text;
            },
            validatedInputs() {
                let data = this.record;
                let certFile = null;
                let regFile = null;
                let addRecord = { qualification: null, qualify_date: null, licencing_body: null, duration: null, notification_date: null };
                regFile = (this.registrationFile.name == null && ! this.aqFiles.registration_file) ?
                    this.showInuptError('Full Registration Certificate File') : true;
                certFile = (this.notificationFile.name == null && ! this.aqFiles.notification_file) ?
                    this.showInuptError('Notification Certificate File') : true;
                addRecord.notification_date  = data.notification_date == null ?
                    this.showInuptError('Notification Date') : data.notification_date;
                addRecord.duration  = data.duration == null ?
                    this.showInuptError('Duration of Training') : data.duration;
                addRecord.licencing_body  = data.licencing_body == null ?
                    this.showInuptError('Issuing Institution') : data.licencing_body;
                addRecord.qualify_date  = data.qualify_date == null ?
                    this.showInuptError('Qualification Date') : data.qualify_date;
                addRecord.qualification  = data.qualification == null ?
                    this.showInuptError('Qualification') : data.qualification;
                // validate institution
                if(data.school != null) {
                    addRecord.school_name = data.school;
                } else if(data.other_school_name != null) {
                    addRecord.school_name = data.other_school_name;
                } else {
                    this.showInuptError('School\'s Name');
                }
                addRecord.country_id  = (data.country_id <= 0) ? this.showInuptError('Country') : data.country_id;
                return this.isEmpty(addRecord) && certFile && regFile ? addRecord : false;
            },
            isEmpty(obj) {// check for empty field
                return ! Object.keys(obj).some(k => !obj[k]);
            },
            showInuptError(name) {
                this.isloading = false;
                this.showInputErrorStatus = true
                this.errorType = 'warning'
                this.errorMsg = 'Invalid ' + name + ' has been entered';
                var self = this;
                setTimeout(function() { self.showInputErrorStatus = false; }, 5000);
            },
            changeOtherInstitution() {// change other Institution
                let value = this.record.school;
                return this.showOtherInstitution = (value == 'others') ? true : false;
            },
            editApplication() {
                return this.showReSendButton = false;
            },
            reSendApplication(id) {// re-send rejected application
                swal.fire({
                    title: 'Are you sure?',
                    text: "You must have corrected the issue before proceeding to re-submit application!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, I have corrected it!'
                }).then((result) => {
                    if (result.value) {
                        this.makeReSubmitApiCall(id);// make api call
                    }
                });
            },
            makeReSubmitApiCall(id) {
                this.preloader(); // show loading
                this.reSubmitApplication(id).then((res) => {// get application record
                    if(res.status) {
                        swal.fire("Application Submitted!", res.msg, "success");
                        return window.location.href='/applications';
                    } else {
                        swal.fire("Error Occurred!", res.msg, "warning");
                    }
                }).
                catch(() => {
                    let msg = "Network Failure! , kindly reload the page...";
                    swal.fire("Network Failure!", msg, "warning");
                });
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b> ...',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        mounted() {
            this.loadAddQualifications();// load add qual records
        },
    }
</script>

<style scoped>
    .right {
        float: right;
    }
</style>

<style>
    .form-control-label {
        width: 100%;
    }
</style>