<template>
    <div class="row">
        <div
            class="col-sm-4 text-center borderLine"
            v-show="showDocuments"
            v-for="(document, index) in items.documents"
            :key="index"
        >
            <br>
            <input
                type="file"
                id="file" style="display: none"
                @change="onFileSelected"
            >
            <h4 class="text-capitalize height-50">
                {{ document.description }}
            </h4><br>
            <base-progress
                v-if="loadPercent.show && (selectedFileName == document.filename)"
                :type="loadPercent.type"
                :height="10"
                :value="loadPercent.value"
                :striped="true"
                :animated="loadPercent.animated"
            >
            </base-progress>
            <base-button
                size="sm" type="primary"
                v-if="! document.hasFile && ! showUploadButton &&
                        ((selectedFileName != document.filename))"
                @click="selectFile(document.filename)"
            >
                <i class="fa fa-hand-pointer"></i> Select File
            </base-button>
            <base-button
                tag="a" type="primary"
                size="sm"
                v-if="document.hasFile && ! showUploadButton ||
                    ((selectedFileName != document.filename) && document.hasFile)"
                :href="document.url"
                class="active" role="button"
                aria-pressed="true"
                target="_blank"
            >
                <i class="fa fa-eye"></i> View File
            </base-button>
            <base-button
                size="sm" type="warning"
                v-if="document.hasFile && ! showUploadButton ||
                    ((selectedFileName != document.filename) && document.hasFile)"
                @click="selectFile(document.filename)"
                :disabled="lock"
            >
                <i class="fa fa-pen"></i> Change File
            </base-button>
            <base-button
                size="sm" type="success"
                v-if="showUploadButton && (selectedFileName == document.filename) && ! loadPercent.show"
                @click="onUpload()"
                :disabled="lock"
            >
                <i class="fa fa-upload"></i> Upload File
            </base-button>
            <base-button
                size="sm" type="danger"
                v-if="showUploadButton && (selectedFileName == document.filename) && ! loadPercent.show"
                @click="removeFile()"
            >
                <i class="fa fa-times"></i> Cancel
            </base-button>
            <br><br>
        </div>

        <div class="col-sm-12 text-center">
            <base-alert type="danger" dismissible v-if='showErrorStatus'>
                <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                <span class="alert-inner--text">
                    {{errorMsg}}
                </span>
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </base-alert>
        </div>
    </div>

</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import swal from 'sweetalert';// import sweet alert

    export default {
        name: 'DocumentFile',
        data() {
            return {
                items: {},
                showDocuments: true,
                isloading: false,
                showErrorStatus: false,
                errorMsg: null,
                showUploadButton: false,
                savedEvent: null,
                uploadedFile: {
                    'name' : null,
                    'file' : null,
                    'id': null
                },
                selectedFileName: null,
                loadPercent: {
                    type: 'warning',
                    value: 10,
                    animated: true,
                    show: false
                }
            }
        },
        computed: {
            ...mapGetters(['lock']),
        },
        methods: {
            ...mapActions(['getUserDocuments', 'uploadUserDocuments']),
            loadUserDocuments() {// update documents
                this.getUserDocuments().then((res) => {
                    this.uploadedFile.name = null;
                    this.uploadedFile.id = null;
                    this.uploadedFile.file = null;// eslint-disable-next-line
                    $('#file').val('');
                    if(res.status) {
                        this.items = res;
                        this.showDocuments = true;
                        this.showErrorStatus = false;
                    } else {
                        this.showDocuments = false;
                        this.showErrorStatus = true;
                        this.errorMsg = 'Error occurred! Documents wasn\'t fetched, reload page';
                    }
                });
            },
            selectFile(name) {// eslint-disable-next-line
                $('#file').val('');
                this.selectedFileName = null;
                this.showUploadButton = false;// eslint-disable-next-line
                $('#file').click();
                this.uploadedFile.name = name+'.pdf';// add file new name
                this.selectedFileName = name;
            },
            removeFile() {// eslint-disable-next-line
                $('#file').val('');
                this.selectedFileName = null;
                this.uploadedFile.name = null;
                this.uploadedFile.id = null;
                this.uploadedFile.file = null;
                this.showUploadButton = false;
                this.savedEvent = null;
            },
            onFileSelected(event) {
                this.savedEvent = event;
                let status =  this.validateFile(event.target.files[0]);
                status ? this.showUploadButton = true : false;
                return status;
            },
            validateFile(file) {// check for valid file
                if(!file) {// update seleted file name
                    this.uploadedFile.name = null;
                    this.selectedFileName = null;
                }
                var fileExt = file.type.split('/').pop();
                let fileSize = file.size;
                if(fileExt == 'pdf' && fileSize <= 800000) {// for valid file and size
                    return true;
                } else if(fileExt != 'pdf') {// invalid file
                    this.uploadedFile.name = null;
                    this.selectedFileName = null;
                    swal("Invalid File!", "Only PDF files are accepted", "warning");
                    return false;
                } else if(fileSize > 800000) {// big file
                    this.uploadedFile.name = null;
                    this.selectedFileName = null;
                    swal("File is too large!", "File should not be more than 800KB", "warning");
                    return false;
                }
            },
            onUpload() {// upload document
                this.uploadedFile.id = this.items.id;// add user id
                this.loadPercent.show = true;
                let event = this.savedEvent;// get store file event
                var fileReader = new FileReader();
                fileReader.readAsDataURL(event.target.files[0]);
                fileReader.onload = (event) => {// convert file to binary
                    this.uploadedFile.file = event.target.result;
                    this.uploadUserDocuments(this.uploadedFile).then((res) => {
                        // console.log(res)
                        this.uploadPercent(this.$store.state.user.uploadProgress);// show load progress
                        var self = this;
                        if(res.status) {
                            setTimeout(function() {
                                self.loadPercent.show = false;
                                self.showUploadButton = false;
                                self.resetLoadPercent();
                            }, 3000);
                        } else {
                            swal("Error Occurred!", "An error occurred while uploading, try again", "warning");
                            setTimeout(function() {
                                self.loadPercent.show = false;
                                self.showUploadButton = false;
                                self.resetLoadPercent();
                            }, 3000);
                        }
                    }).
                    catch(err => {
                        swal(err.message+"!", "Reduce file size and try again...", "warning");
                        this.loadPercent.show = false;
                        this.showUploadButton = false;
                    });
                    this.loadUserDocuments();// refresh page
                }
            },
            uploadPercent(percent) {// update upload progress percent
                this.loadPercent.value = percent;
                this.loadPercent.type = percent > 60 ? 'success' : 'warning';
            },
            resetLoadPercent() {// reset load percent
                return this.loadPercent = {
                            type: 'warning',
                            value: 10,
                            animated: true,
                            show: false
                        };
            }
        },
        created() {
            this.loadUserDocuments();// load documents
        },
    }
</script>

<style scoped>
    .height-50 {
        height: 50px;
    }
    .borderLine {
        border: 1px solid #ededed;
    }
</style>
