<template>
    <form>
        <h6 class="heading-small text-muted mb-4">
            Upload Files &nbsp;&nbsp;&nbsp;&nbsp;
            <span class="text-danger">
                <i class="fa fa-info-circle"></i> Only PDF Files (Not More than 800KB) are supported for each file
            </span>            
        </h6>

        <div class="container ct-example-row">
            <!-- import User Document File template here -->
            <DocumentFile />
        </div>
    </form>
</template>

<script>
    import DocumentFile from './DocumentFile.vue';

    export default {
        components: {
            DocumentFile
        },
        name: 'Document',
    }
</script>

<style>

</style>
