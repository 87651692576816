<template>
    <div>
        <div class="col-lg-12">
            <!-- Address -->
            <h6 class="heading-small text-muted mb-4">
                Give the names of medical councils or jurisdiction under which you have previously worked:
            </h6>

            <base-alert :type="errorType" dismissible v-if='showInputStatus'>
                <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                <span class="alert-inner--text">
                    {{errorMsg}}
                </span>
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </base-alert>

            <div class="row align-items-center" v-if="! disableInput">
                <div class="col-lg-3">
                    <base-input alternative=""
                        label="Jurisdiction's Name"
                        input-classes="form-control-alternative"
                        required
                        v-model="jurisdiction.name"
                        :readonly="disableInput"
                    />
                </div>
                <div class="col-lg-3">
                    <base-input alternative=""
                        label="Jurisdiction's Address"
                        input-classes="form-control-alternative"
                        required
                        v-model="jurisdiction.address"
                        :readonly="disableInput"
                    />
                </div>
                <div class="col-lg-2">
                    <base-input alternative=""
                        label="Period"
                        input-classes="form-control-alternative"
                        required
                        v-model="jurisdiction.period"
                        :readonly="disableInput"
                    />
                </div>
                <div class="col-lg-2">
                    <base-input alternative=""
                        label="Reg Number"
                        input-classes="form-control-alternative"
                        required
                        v-model="jurisdiction.reg_no"
                        :readonly="disableInput"
                    />
                </div>
                <div class="col-lg-2">
                    <base-button
                        type="success" size="sm"
                        @click="saveJurisdiction()"
                        :disabled="disableInput"
                    >
                        <i class="fa fa-plus"></i>
                        {{isloadingJuris ? 'Saving...' : 'Add Now' }}
                    </base-button>
                </div>
            </div>

            <!-- Import Jurisdictions blade here -->
            <Jurisdictions
                v-bind:jurisdictions="records.jurisdictions"
                v-on:deleteJurisdiction="deleteJurisdiction"
            />
            <br>
        </div>

    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import Jurisdictions from '../../UserProfile/AdditionalRecords/Jurisdictions';
    import swal from 'sweetalert';// import sweet alert

    export default {
        components: {
            Jurisdictions
        },
        name: 'AddJurisdictions',
        props: ['records', 'loadAdditionalRecords', 'profileLock'],
        data() {
            return {
                jurisdiction: {
                    name: null,
                    address: null,
                    period: null,
                    reg_no: null,
                    user_data_id: null
                },
                showInputStatus: false,
                errorType: null,
                errorMsg: null,
                isloadingJuris: false
            };
        },
        computed: {
            ...mapGetters(['loadingBladeUrl', 'lock']),
            disableInput() {// check for profile lock
                return ! this.profileLock ? this.profileLock : this.lock;
            }
        },
        methods: {
            ...mapActions(['saveJurisdictions', 'removeJurisdiction']),
            isEmpty(obj) {// check for empty field
                return ! Object.keys(obj).some(k => !obj[k]);
            },
            emptyJurisdiction() {
                this.jurisdiction.name = null;
                this.jurisdiction.address = null;
                this.jurisdiction.period = null;
                this.jurisdiction.reg_no = null;
                this.jurisdiction.user_data_id = null;
            },
            saveJurisdiction() {//save jurisdiction
                this.isloadingJuris = true;
                let status = this.validatedJurisdictionInput(this.jurisdiction);// validate inputs
                if(status) {// save Jurisdiction
                    let data = this.jurisdiction;
                    data.user_data_id = this.$store.state.user.basicProfile.id;// get user id
                    this.saveJurisdictions(data).then((res) => {
                        this.isloadingJuris = false;

                        if(res.status) {
                            this.loadAdditionalRecords();
                            this.emptyJurisdiction();
                        } else {
                            this.showErrorMsg();
                            this.loadAdditionalRecords();
                        }
                    }).
                    catch(() => {
                        this.isloadingJuris = false;
                        this.loadAdditionalRecords();
                        this.emptyReferee();
                        this.showErrorMsg();
                    });
                }
                else {
                    this.isloadingJuris = false;
                }
            },
            validatedJurisdictionInput(data) {
                let record = { name: null, address: null, period: null, reg_no: null };
                record.reg_no  = data.reg_no == null ? this.showInuptError('Jurisdiction\'s Reg Number') : data.reg_no;
                record.period  = data.period == null ? this.showInuptError('Period with Jurisdiction') : data.period;
                record.address  = data.address == null ? this.showInuptError('Jurisdiction\'s Address') : data.address;
                record.name = data.name == null ? this.showInuptError('Jurisdiction\'s Name') : data.name;
                return this.isEmpty(record) ? record : false;
            },
            showErrorMsg() {
                let msg = 'An error occurred, kindly reload page and try again';
                swal("Error occurred!", msg, "warning");
            },
            showInuptError(name) {
                this.isloadingJuris = false;
                this.showInputStatus = true
                this.errorType = 'warning'
                this.errorMsg = 'Invalid ' + name + ' has been entered';
                var self = this;
                setTimeout(function() { self.showInputStatus = false; }, 5000);
            },
            deleteJurisdiction(id) {// delete Jurisdiction function
                this.removeJurisdiction({'id' : id}).then((res) => {
                    if(res.status) {
                        this.loadAdditionalRecords(); // load Jurisdictions
                    } else {
                        swal("Error Occurred!", "An error occurred while deleting, try again", "warning");
                        this.loadAdditionalRecords(); // load Jurisdictions
                    }
                }).
                catch(() => {
                    swal("Network Failure", "Reload page and try again...", "warning");
                    this.loadAdditionalRecords(); // load Jurisdictions
                });
            }
        }
    }
</script>

<style scoped>
    .marginLeft10 {
        margin-left: 10px;
    }
</style>