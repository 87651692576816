<template>
    <div>
        <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center">
            <span class="mask bg-gradient-danger opacity-8"></span>
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col-xl-12 order-xl-1">
                    <card shadow type="secondary"> 
                        <div slot="header" class="bg-white border-0">
                            <div class="row align-items-center">
                                <div class="col-12">
                                    <h3 class="mb-0 text-uppercase">{{ title }}</h3>
                                    <br>
                                    <span class="text-danger">
                                        Note that you have to provide correct information, so that your account can be updated.
                                        Also check the spelling of your names and make sure they are correct.
                                    </span>
                                </div>
                            </div>
                        </div>



                        <!-- Import Bio Data template here -->
                        <BioData
                            v-if="showBioData"
                            v-bind:displayEduRecords="displayEduRecords"
                        />

                        <!-- Import Edu Records template here -->
                        <EduRecords
                            v-if="! showBioData && showEduRecords"
                            v-bind:displayBioData="displayBioData"
                            v-bind:displaySpecData="displaySpecData"
                        />

                        <!-- Import Specialization template here -->
                        <Specialization
                            v-if="! showBioData && ! showEduRecords && showSpecData"
                            v-bind:displayEduRecords="displayEduRecords"
                            v-bind:displayAppointments="displayAppointments"
                        />

                        <!-- Import Appointments template here -->
                        <Appointments
                            v-if="! showBioData && ! showEduRecords && ! showSpecData
                                && showAppointments"
                            v-bind:displaySpecData="displaySpecData"
                            v-bind:displayPhoto="displayPhoto"
                        />

                        <!-- Import Profile Photo template here -->
                        <ProfilePhoto
                            v-if="! showBioData && ! showEduRecords && ! showSpecData
                                && ! showAppointments && showPhoto"
                            v-bind:displayAppointments="displayAppointments"
                            v-bind:displayDocuments="displayDocuments"
                        />

                        <!-- Import Documents template here -->
                        <Documents
                            v-if="! showBioData && ! showEduRecords && ! showSpecData
                                && ! showAppointments && ! showPhoto && showDocuments"
                            v-bind:displayPhoto="displayPhoto"
                            v-bind:displayInternship="displayInternship"
                        />

                        <Internship
                            v-if="! showBioData && ! showEduRecords && ! showSpecData
                                && ! showAppointments && ! showPhoto && ! showDocuments
                                && showInternship"
                            v-bind:displayDocuments="displayDocuments"
                            v-bind:displayInsurance="displayInsurance"
                        />

                        <!-- Import Insurance template here -->
                        <Insurance
                            v-if="! showBioData && ! showEduRecords && ! showSpecData
                                && ! showAppointments && ! showPhoto && ! showDocuments
                                && ! showInternship && showInsurance"
                            v-bind:displayInternship="displayInternship"
                            v-bind:displayAddQual="displayAddQual"
                        />

                        <!-- Import Insurance template here -->
                        <AddQualification
                            :locations="locations"
                            v-if="! showBioData && ! showEduRecords && ! showSpecData
                                && ! showAppointments && ! showPhoto && ! showDocuments
                                && ! showInternship && ! showInsurance && showAddQual"
                            v-bind:displayInsurance="displayInsurance"
                            v-bind:displayNMALevy="displayReg"
                        />

                        <!-- Import NMA Levy template here -->
                        <NMALevy
                            v-if="! showBioData && ! showEduRecords && ! showSpecData
                                && ! showAppointments && ! showPhoto && ! showDocuments
                                && ! showInternship && ! showInsurance && ! showAddQual
                                && ! showAddRecords && showNMALevy"
                            v-bind:displayAddQual="displayAddQual"
                            v-bind:displayReg="displayReg"
                        />

                        <!-- Import Registration template here -->
                        <Registration
                            v-if="! showBioData && ! showEduRecords && ! showSpecData
                                && ! showAppointments && ! showPhoto && ! showDocuments
                                && ! showInsurance && ! showInternship && ! showAddQual
                                && ! showAddRecords && ! showNMALevy && showReg"
                            v-bind:displayNMALevy="displayAddQual"
                        />

                    </card>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import BioData from './BioData.vue';
    import EduRecords from './EduRecords.vue';
    import Specialization from './Specialization.vue';
    import Appointments from './Appointments.vue';
    import ProfilePhoto from './ProfilePhoto.vue';
    import Documents from './Documents.vue';
    import Insurance from './Insurance.vue';
    import Registration from './Registration.vue';
    import Internship from './Internship.vue';
    import AddQualification from './AddQualification.vue';
    import NMALevy from './NMALevy';
    import swal from 'sweetalert2';// import sweet alert
    import { mapActions, mapGetters } from 'vuex';

    export default {
        components: {
            BioData,
            EduRecords,
            Specialization,
            Appointments,
            ProfilePhoto,
            Documents,
            Insurance,
            Registration,
            Internship,
            AddQualification,
            NMALevy
        },
        name: 'NewAccount',
        data() {
            return {
                showBioData: true,
                showEduRecords: false,
                showSpecData: false,
                showAppointments: false,
                showPhoto: false,
                showDocuments: false,
                showInsurance: false,
                showReg: false,
                showInternship: false,
                showAddQual: false,
                showAddRecords: false,
                showNMALevy: false,
                title: 'Update Bio information'
            }
        },
        computed: {
            ...mapGetters(['user', 'locations']),
        },
        methods: {
            ...mapActions(['getLocations', 'getHospitals', 'fetchUserProfile']),
            displayBioData(value) {
                this.title = 'Update Bio information';
                this.showBioData = value;
            },
            displayEduRecords(value) {
                this.title = 'Update Educational Records';
                this.showBioData = ! value;
                this.showEduRecords = value;
            },
            displaySpecData(value) {
                this.title = 'Update Specialization Information';
                this.showBioData = ! value;
                this.showEduRecords = ! value;
                this.showSpecData = value;
            },
            displayAppointments(value) {
                this.title = 'Update Appointment Records';
                this.showBioData = ! value;
                this.showEduRecords = ! value;
                this.showSpecData = ! value;
                this.showAppointments = value;
            },
            displayPhoto(value) {
                this.title = 'Upload Profile Photo';
                this.showBioData = ! value;
                this.showEduRecords = ! value;
                this.showSpecData = ! value;
                this.showAppointments = ! value;
                this.showPhoto = value;
            },
            displayDocuments(value) {
                this.title = 'Upload Documents';
                this.showBioData = ! value;
                this.showEduRecords = ! value;
                this.showSpecData = ! value;
                this.showAppointments = ! value;
                this.showPhoto = ! value;
                this.showDocuments = value;
            },
            displayInternship(value) {
                this.title = 'Update Internship Performance Report';
                this.showBioData = ! value;
                this.showEduRecords = ! value;
                this.showSpecData = ! value;
                this.showAppointments = ! value;
                this.showPhoto = ! value;
                this.showDocuments = ! value;
                this.showInternship = value;
            },
            displayInsurance(value) {
                this.title = 'Purchase insurance indemnity';
                this.showBioData = ! value;
                this.showEduRecords = ! value;
                this.showSpecData = ! value;
                this.showAppointments = ! value;
                this.showPhoto = ! value;
                this.showDocuments = ! value;
                this.showInternship = ! value;
                this.showInsurance = value;
            },
            displayAddQual(value) {
                this.title = 'Add Additional Qualification';
                this.showBioData = ! value;
                this.showEduRecords = ! value;
                this.showSpecData = ! value;
                this.showAppointments = ! value;
                this.showPhoto = ! value;
                this.showDocuments = ! value;
                this.showInternship = ! value;
                this.showInsurance = ! value;
                this.showAddQual = value;
            },
            displayAddRecords(value) {
                this.title = 'Additional Records';
                this.showBioData = ! value;
                this.showEduRecords = ! value;
                this.showSpecData = ! value;
                this.showAppointments = ! value;
                this.showPhoto = ! value;
                this.showDocuments = ! value;
                this.showInternship = ! value;
                this.showInsurance = ! value;
                this.showAddQual = ! value;
                this.showAddRecords = value;
            },
            displayNMALevy(value) {
                this.title = 'NMA Levy Payment';
                this.showBioData = ! value;
                this.showEduRecords = ! value;
                this.showSpecData = ! value;
                this.showAppointments = ! value;
                this.showPhoto = ! value;
                this.showDocuments = ! value;
                this.showInternship = ! value;
                this.showInsurance = ! value;
                this.showAddQual = ! value;
                this.showAddRecords = ! value;
                this.showNMALevy = value;
            },
            displayReg(value) {
                this.title = 'Registration Payment';
                this.showBioData = ! value;
                this.showEduRecords = ! value;
                this.showSpecData = ! value;
                this.showAppointments = ! value;
                this.showPhoto = ! value;
                this.showDocuments = ! value;
                this.showInsurance = ! value;
                this.showInternship = ! value;
                this.showAddQual = ! value;
                this.showAddRecords = ! value;
                this.showNMALevy = ! value;
                this.showReg = value;
            },
            showSessionMsg() {
                if(this.$route.query.status && this.$route.query.status == 1) {
                    swal.fire("Insurance Purchased!", "Insurance Indemnity was purchased successfully", "success");
                } else if(this.$route.query.status && this.$route.query.status == 0) {
                    swal.fire("Insurance Failed!", "Insurance Indemnity purchased failed, try again", "warning");
                }
                // if(this.$route.path != '/new-account') {
                //     return window.location.href = "/new-account";
                // }
            }
        },
        created() {
            this.showSessionMsg();// show session msg
            this.getLocations();
            this.getHospitals();// get hospitals
            this.fetchUserProfile();
        },
    }
</script>