<template>
    <div>
        <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center">
            <span class="mask bg-gradient-danger opacity-8"></span>
        </base-header>



        <div class="container-fluid mt--7">
            <div class="row">

                <div class="col-lg-12" v-if="isLoadingBlade">
                    <div class="d-flex justify-content-center">
                        <img :src="loadingBladeUrl" />
                    </div>
                </div>

                <div class="col-xl-12 order-xl-1" v-if="! isLoadingBlade">
                    <card shadow type="secondary">

                        <div class="pl-lg-4">
                            <h6 class="heading-small text-muted mb-4">
                                Below is your transaction details:
                                <router-link
                                    to="/insurance-indemnity"
                                    class="btn btn-sm btn-danger right"
                                >
                                    <i class="fa fa-arrow-left"></i>
                                    Go back
                                </router-link>
                            </h6>
                            <span class="text-danger">
                                Note that this purchase to be made is for
                                <em class="text-primary">{{ (record.provisional == 'no') ? 'Non-' : '' }}Provisional
                                Registration Applicants</em>.
                                If Otherwise, kindly go back to start a fresh purchase
                            </span>
                             <br><br>
                            <span class="text-danger text-uppercase">
                                To ensure that your transaction is updated after making payment on the Cornerstone page, please click on the 
                                <em class="text-primary">Return to MDCN Portal</em> button after successfully making payment on the Cornerstone page.
                            </span>

                            <div class="container ct-example-row">
                                <div class="row">
                                    <div class="col">
                                        <ul
                                            class="list-unstyled"
                                            v-if="record && record.user_data"
                                        >
                                            <li>
                                                <hr class="my-4">
                                                <h3>
                                                    Payer Name: &nbsp;
                                                    <small class="text-capitalize">
                                                        {{ getFullName(record)  }}
                                                    </small>
                                                </h3>
                                                <hr class="my-4">
                                                <h3>
                                                    Payer Email Address: &nbsp;
                                                    <small>{{ record.user_data.user ? record.user_data.user.email : '' }}</small>
                                                </h3>
                                                <hr class="my-4">
                                                <h3>
                                                    Payer Phone Number: &nbsp;
                                                    <small>{{ record.user_data.phone  }}</small>
                                                </h3>
                                                <hr class="my-4">
                                                <h3>
                                                    Transaction ID: &nbsp;
                                                    <small>{{ record.trans_no  }}</small>
                                                </h3>
                                                <hr class="my-4">
                                                <h3>
                                                    Beneficiary's Name: &nbsp;
                                                    <small>{{ record.beneficiary_name  }}</small>
                                                </h3>
                                                <hr class="my-4">
                                                <hr class="my-4">
                                                <h3>
                                                    Beneficiary's Phone Number: &nbsp;
                                                    <small>{{ record.beneficiary_phone  }}</small>
                                                </h3>
                                                <hr class="my-4">
                                                <hr class="my-4">
                                                <h3>
                                                    Beneficiary's Relationship: &nbsp;
                                                    <small>{{ record.beneficiary_relationship  }}</small>
                                                </h3>
                                                <hr class="my-4">

                                                <form :action="getInsuranceUrl" method="POST">

                                                    <input
                                                        type="hidden"
                                                        v-for="(item, index) in paymentRecords"
                                                        :key="index"
                                                        :name="item.title"
                                                        :value="item.value"
                                                    />


                                                    <base-button
                                                        type="success"
                                                        nativeType="submit"
                                                    >
                                                        <i class="fa fa-credit-card"></i>
                                                        {{ isloading ? 'Please Wait...' : 'Click to Update Your Profile'}}
                                                    </base-button>
                                                </form>

                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-12 text-center">
                                <base-alert :type="errorType" v-if='showErrorStatus'>
                                    <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                                    <span class="alert-inner--text">
                                        {{ errorMsg }}
                                    </span>
                                </base-alert>
                            </div>

                        </div>

                    </card>
                </div>
            </div>
        </div>

    </div>


</template>

<script>
    import { mapGetters, mapActions } from 'vuex';

    export default {
        name: 'PurchaseInsurance',
        data() {
            return {
                record: {},
                errorType: null,
                showErrorStatus: false,
                errorMsg: null,
                isloading: false,
                isLoadingBlade: true
            }
        },
        computed: {
            ...mapGetters(['getInsuranceUrl', 'getCallbackUrl', 'loadingBladeUrl']),
            paymentRecords() {
                let data = this.record;
                let userData = this.record.user_data;
                let user = this.record.user_data.user;
                return [
                    {'title' : 'title', 'value' : 'Dr'},
                    {'title' : 'firstname', 'value' : userData.first_name},
                    {'title' : 'lastname', 'value' : userData.last_name},
                    {'title' : 'mobile', 'value' : userData.phone},
                    {'title' : 'dob', 'value' : this.formatDate(userData.date_of_birth)},
                    {'title' : 'email', 'value' : user.email},
                    {'title' : 'address', 'value' : userData.permanent_address},
                    {'title' : 'profession', 'value' : 'Medical Doctor'},
                    {'title' : 'sub_profession', 'value' : userData.has_specialization && userData.has_specialization.specialization ?
                                        userData.has_specialization.specialization.name : 'N/A'},
                    {'title' : 'callbackurl', 'value' : this.getCallbackUrl+'?code='+data.trans_no},
                    {'title' : 'unique_ref', 'value' : data.trans_no},
                    {'title' : 'clinic_name', 'value' : data.appointment ? data.appointment.hospital_name : 'N/A'},
                    {'title' : 'gender', 'value' : userData.gender},
                    {'title' : 'professional_body', 'value' : 'MDCN'},
                    {'title' : 'date_inc_clinic', 'value' : data.appointment ? this.formatDate(data.appointment.start_date) : 'N/A'},
                    {'title' : 'clinic_addresss', 'value' : data.appointment ? data.appointment.hospital_address : 'N/A'},
                    {'title' : 'provisional', 'value' : data.provisional},
                    {'title' : 'beneficiary_fullname', 'value' : data.beneficiary_name},
                    {'title' : 'beneficiary_relationship', 'value' : data.beneficiary_relationship},
                    {'title' : 'beneficiary_phone', 'value' : data.beneficiary_phone}
                ]
            }
        },
        methods: {
            ...mapActions(['getAnInsuranceRecord']),
            loadInsurance() {// load insurance details
                const trans_no = this.$route.params.trans_no;
                this.getAnInsuranceRecord({'trans_no' : trans_no}).then((res) => {// get insurance records
                    this.isLoadingBlade = false;
                    this.record = res;
                }).
                catch(() => {
                    this.isLoadingBlade = false;
                    this.showErrorStatus = true;
                    this.errorMsg = "Network Failure!", "Kindly reload the page...";
                    this.errorType = 'danger';
                });
            },
            getFullName(record) {// get user full name
                return record.user_data ? (record.user_data.first_name +' '+
                    record.user_data.last_name) : 'None'
            },
            formatDate(date) {// format date for post request
                let exploded = date.split('-');
                return exploded[2] && exploded[1] && exploded[0] ?
                    exploded[2]+exploded[1]+exploded[0] : 'N/A';
            },
            isProvisional(value) {
                return value && ((value == 1) || (value == 2)) ? 'yes' : 'no';
            }
        },
        created() {
            this.loadInsurance();// load insurance
        },
    }
</script>

<style scoped>
    .right {
        float: right;
    }
</style>