<template>
    <form @submit.prevent>
        <h6 class="heading-small text-muted mb-4">User information</h6>

        <base-alert :type="errorType" dismissible v-if='showErrorStatus'>
            <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
            <span class="alert-inner--text">
                {{errorMsg}}
            </span>
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </base-alert>

        <div class="pl-lg-4">
            <div class="row">
                <div class="col-lg-4">
                    <base-input alternative="" id='first_name'
                        label="First Name"
                        placeholder="First Name"
                        input-classes="form-control-alternative text-capitalize"
                        v-model="profile.first_name"
                        required
                        readonly
                    />
                </div>
                <div class="col-lg-4">
                    <base-input alternative=""
                        label="Last name"
                        placeholder="Last name"
                        input-classes="form-control-alternative text-capitalize"
                        v-model="profile.last_name"
                        required
                        readonly
                    />
                </div>
                <div class="col-lg-4">
                    <base-input alternative=""
                        label="Other Names"
                        placeholder="Other Names"
                        input-classes="form-control-alternative text-capitalize"
                        v-model="profile.other_name"
                        readonly
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4">
                    <base-input alternative=""
                        label="Folio Number"
                        placeholder="Folio Number"
                        input-classes="form-control-alternative text-uppercase"
                        v-model="profile.folio_number"
                        readonly
                    />
                </div>
                <div class="col-md-4">
                    <base-input alternative=""
                        label="Gender"
                        required
                        >
                        <select
                            class="form-control form-control-alternative"
                            v-model="profile.gender"
                            :disabled="lock"
                        >
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                        </select>
                    </base-input>
                </div>
                <div class="col-md-4">
                    <base-input
                        label="Date of Birth"
                        addon-left-icon="ni ni-calendar-grid-58"
                        required
                    >
                        <flat-picker
                            slot-scope="{focus, blur}"
                            @on-open="focus"
                            @on-close="blur"
                            :config="{allowInput: true}"
                            placeholder="Select Date.."
                            class="form-control datepicker"
                            v-model="profile.date_of_birth"
                            disabled
                        >
                        </flat-picker>
                    </base-input>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <base-input alternative=""
                        label="Previous Names"
                        placeholder="Previous Names"
                        input-classes="form-control-alternative text-capitalize"
                        v-model="profile.previous_names"
                        readonly
                    />
                </div>
                <div class="col-md-4">
                    <base-input alternative=""
                        label="Provisional Registration Number"
                        placeholder="Provisional Registration Number"
                        input-classes="form-control-alternative text-capitalize"
                        v-model="profile.provisional_reg_no"
                        type="number"
                        readonly
                    />
                </div>
                <div class="col-md-4">
                    <base-input alternative=""
                        label="Full Registration Number"
                        placeholder="Full Registration Number"
                        input-classes="form-control-alternative text-capitalize"
                        v-model="profile.registration_no"
                        type="number"
                        readonly
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <base-input alternative=""
                        label="Phone Number"
                        placeholder="Phone Number"
                        input-classes="form-control-alternative"
                    >
                    <!-- v-model="profile.phone"  -->
                        <VuePhoneNumberInput
                            v-model="tel.phone"
                            @update="updatePhoneNumber"
                            :default-country-code="tel.code"
                            :no-example="true"
                            :clearable="true"
                        />
                    </base-input>
                </div>
                <div class="col-md-4">
                    <base-input alternative=""
                        label="Place of Birth"
                        placeholder="Place of Birth"
                        input-classes="form-control-alternative text-capitalize"
                        v-model="profile.place_of_birth"
                        :readonly="lock"
                    />
                </div>
                <div class="col-md-4">
                    <base-input alternative=""
                        label="Marital Status"
                        required
                    >
                        <select
                            class="form-control form-control-alternative"
                            v-model="profile.marital_status"
                            :disabled="lock"
                        >
                            <option value="single">Single</option>
                            <option value="married">Married</option>
                            <option value="divorced">Divorced</option>
                            <option value="widow">Widow</option>
                        </select>
                    </base-input>
                </div>
            </div>
        </div>
        <hr class="my-4" />
        <!-- Address -->
        <h6 class="heading-small text-muted mb-4">Nationality information</h6>
        <div class="pl-lg-4">
            <div class="row">
                <div class="col-md-6">
                    <base-input alternative=""
                        label="Nationality"
                        required
                    >
                        <autocomplete
                            v-if="locations && locations.countries && locations.countries.length > 0"
                            input-class="form-control form-control-alternative"
                            placeholder="Type to Search Nationality"
                            showNoResults
                            :source="locations.countries"
                            :disableInput="lock"
                            v-model="profile.nationality"
                            :initialDisplay="(hasCountry ? hasCountry.name : '')"
                            @selected="changeCountry"
                        >
                        </autocomplete>
                    </base-input>
                </div>

                <div class="col-md-6">
                    <base-input alternative=""
                        label="State / Province of Origin"
                        required
                    >
                        <autocomplete
                            v-if="cities && cities.length > 0"
                            ref="autocomplete"
                            input-class="form-control form-control-alternative"
                            placeholder="Type to Search State / Province of Origin"
                            showNoResults
                            :source="cities"
                            :disableInput="lock"
                            :initialDisplay="(hasCity ? hasCity.name : '')"
                            @selected="changeCity"
                        >
                        </autocomplete> 
                    </base-input>
                </div>


                <div class="col-md-6" v-if="displayOtherState">
                    <base-input alternative=""
                        label="Other State/Province"
                        placeholder="Enter Other State/Province"
                        input-classes="form-control-alternative"
                        v-model="profile.foreign_state"
                        :disabled="lock"
                        required
                    />
                </div>

                <div class="col-md-6" v-if="profile.state_origin && ! showForeignState">
                    <base-input alternative=""
                        label="LGA of Origin"
                        required
                    >
                        <autocomplete
                            v-if="lgas && lgas.length > 0"
                            input-class="form-control form-control-alternative"
                            placeholder="Type to Search for LGA of Origin"
                            showNoResults
                            :source="lgas"
                            :disableInput="lock"
                            v-model="profile.lga_origin"
                            :initialDisplay="(profile.has_lga ? profile.has_lga.name : '')"
                        >
                        </autocomplete>
                    </base-input>
                </div>
                <div class="col-md-6">
                    <base-input alternative=""
                        label="HomeTown"
                        placeholder="HomeTown"
                        input-classes="form-control-alternative text-capitalize"
                        v-model="profile.hometown"
                        :readonly="lock"
                    />
                </div>
            </div>
            <div class="row">

            </div>
        </div>
        <hr class="my-4" />
        <!-- Address -->
        <h6 class="heading-small text-muted mb-4">Contact information</h6>
        <div class="pl-lg-4">
            <div class="form-group">
                <base-input alternative=""
                    label="Permanent Address"
                    required
                >
                    <textarea
                        rows="2"
                        class="form-control form-control-alternative"
                        placeholder="Enter Permanent Address"
                        v-model="profile.permanent_address"
                    ></textarea>
                </base-input>
            </div>
            <div
                class="form-group"
                v-if="isForiegner"
            >
                <base-input alternative=""
                    label="Residential Address (In Nigeria)"
                    required
                >
                    <textarea
                        rows="2"
                        class="form-control form-control-alternative"
                        placeholder="Enter Permanent Address"
                        v-model="profile.residential_address"
                    ></textarea>
                </base-input>
            </div>
            <div class="form-group">
                <base-input alternative=""
                    label="Postal Address (If any)">
                    <textarea
                        rows="2"
                        class="form-control form-control-alternative"
                        placeholder="Enter Permanent Address"
                        v-model="profile.postal_address"></textarea>
                </base-input>
            </div>
        </div>

        <base-alert :type="errorType" dismissible v-if='showErrorStatus'>
            <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
            <span class="alert-inner--text">
                {{errorMsg}}
            </span>
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </base-alert>

        <div class="pl-lg-4">
            <base-button type="success" @click="updateProfile()">
                <i class="fa fa-save" @if='! isloading'></i>
                {{isloading ? 'Please Wait' : 'Save Changes'}}
            </base-button>
        </div>
    </form>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import flatPicker from "vue-flatpickr-component";
    import "flatpickr/dist/flatpickr.css";
    import swal from 'sweetalert';// import sweet alert
    import VuePhoneNumberInput from 'vue-phone-number-input';
    import 'vue-phone-number-input/dist/vue-phone-number-input.css';
    import Autocomplete from 'vuejs-auto-complete';
    import states from '../../../states.json';

    export default {
        components: {
            flatPicker,
            VuePhoneNumberInput,
            Autocomplete
        },
        name: 'BasicProfile',
        data() {
            return {
                profile: {},
                lgas: [],
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isloading: false,
                tel: {
                    phone: null,
                    code: 'NG',
                },
                states: states,
                cities: [],
                locations: [],
                displayOtherState: false
            }
        },
        computed: {
            ...mapGetters(['lock']),
            showForeignState() {
                return this.profile.nationality == 164 ? false : true;
            },
            isForiegner() {// check for foriegner
                return this.profile && this.profile.nationality != 164;
            },
            hasCountry() {
                let country = this.locations && this.locations.countries ?
                            this.locations.countries.filter(item => item.id == this.profile.nationality) : null;
                return country && country.length > 0 ? country[0] : {};
            },
            hasCity() {
                let city = this.cities ? this.cities.filter(item => item.id == this.profile.state_origin) : null;
                return city && city.length > 0 ? city[0] : { name: this.profile.foreign_state };
            },
        },
        methods: {
            ...mapActions(['getBasicProfile', 'getLocations', 'UpdateUserProfile']),// make api call for user profile
            changeCity(e) {
                let data = e.selectedObject;
                this.displayOtherState = false;
                this.profile.state_origin = null;
                this.lgas = [];
                if(data.id == 0) {
                    this.profile.foreign_state = null;
                    this.displayOtherState = true;
                } else {
                    if(this.profile.nationality == 164) {
                        this.profile.foreign_state = null;
                        this.profile.state_origin = data.id;
                        this.lgas = this.locations.lgas.filter(item => item.state_id == this.profile.state_origin);
                    } else {
                        this.profile.foreign_state = data.name;
                    }
                }
            },
            changeCountry(e, code) {// get exact institutions
                this.cities = [];
                let id = code ? code : e.selectedObject.id;
                if(id == 164) {
                    this.cities = this.locations.states;
                } else {
                    let data = code ? this.hasCountry :  e.selectedObject;
                    let other = { id : 0, name: 'Others (Specify)'};// get cities
                    this.cities = this.states.filter(item => item.country_code == data.code);
                    this.cities.push(other);
                }
                if(! code) {
                    this.profile.state_origin = null;
                    this.profile.lga_origin = null;
                }
            },
            updatePhoneNumber(event) {
                if(event && event.isValid && event.e164) {
                    this.profile.phone = event.formattedNumber;
                    this.profile.phone_code = event.countryCode+'-'+event.phoneNumber;
                }
            },
            loadPhoneNumber() {// update phone no
                let data = this.profile.phone_code;
                let exploded = data ? data.split('-') : false;
                this.tel.code = exploded && exploded[0] ? exploded[0] : 'NG';
                this.tel.phone = exploded && exploded[1] ? exploded[1] : null;
            },
            loadProfile() {// load user profile function
                this.getBasicProfile().then((res) => {
                    this.profile = res;
                    this.getStateLgas(res.nationality);
                    this.loadPhoneNumber();// update phone no

                });
            },
            getStateLgas(id) {// get state's lgas
                let lgas = [];
                let stateId = this.profile.state_origin;
                this.getLocations().then((res) => {
                    this.locations = res;
                    for(let lga of res.lgas) {
                        if (lga.state_id == stateId) {
                            lgas.push(lga);
                        }
                    }
                    this.lgas = lgas;
                    this.changeCountry(null, id);
                });
            },
            updateProfile() {
                this.isloading = true;
                let status = this.validatedData();// valid input data
                let data = this.profile;
                if(data['id'] && status) {// check for user id & perform update
                    this.UpdateUserProfile(data).then((res) => {
                        this.isloading = false;
                        if(res.status) {// load updated profile
                            this.loadProfile();
                            swal("Profile Updated!", res.msg, "success");
                        } else {
                            swal("Error Ocurred!", res.msg, "warning");
                        }
                    });
                } else {
                    let msg = 'User profile wasn\'t fetched properly, reload page and try again';
                    swal("Error Ocurred!", msg, "warning");
                }
            },
            validatedData() {
                let data = this.profile;
                let status = false;
                if(data.nationality == 164) {
                    let state = data.state_origin ? true : this.showInuptError('State of Origin');
                    let lga = data.lga_origin ? true : this.showInuptError('LGA of Origin');
                    status = state && lga;

                } else {
                    status = data.foreign_state ? true : this.showInuptError('State / Province of Origin');
                }
                return status;

                // let validData = {};
                // validData['first_name'] = data.first_name != '' ? data.first_name : this.showInuptError('First Name');
                // validData['last_name'] = data.last_name ? data.last_name : this.showInuptError('Last Name');
                // validData['other_name'] = data.other_name ? data.other_name : false;
                // validData['gender'] = data.gender ? data.gender : this.showInuptError('Gender');
                // validData['date_of_birth'] = data.date_of_birth ? data.date_of_birth : this.showInuptError('Date of Birth');
                // validData['phone'] = data.phone ? data.phone : this.showInuptError('Phone Number');
                // validData['place_of_birth'] = data.place_of_birth ? data.place_of_birth : this.showInuptError('Place of Birth');
                // validData['marital_status'] = data.marital_status ? data.marital_status : this.showInuptError('Marital Status');
                // validData['nationality'] = data.nationality ? data.nationality : this.showInuptError('Nationality');
                // validData['state_origin'] = data.state_origin ? data.state_origin : this.showInuptError('State of Origin');
                // validData['lga_origin'] = data.lga_origin ? data.lga_origin : this.showInuptError('LGA of Origin');
                // validData['hometown'] = data.hometown ? data.hometown : this.showInuptError('Hometown');
                // validData['permanent_address'] = data.permanent_address ? data.permanent_address : this.showInuptError('Permanent Address');
                // return validData;
            },
            showUpdateMsg(msg, type) {
                this.showErrorStatus = true;
                this.errorType = type;
                this.errorMsg = msg;
                var self = this;
                setTimeout(function() { self.showErrorStatus = false; }, 5000);
            },
            showInuptError(name) {
                this.isloading = false;
                this.showErrorStatus = true
                this.errorType = 'warning'
                this.errorMsg = 'Invalid ' + name + ' has been entered';
                var self = this;
                setTimeout(function() { self.showErrorStatus = false; }, 5000);
            }
        },
        created() {
            this.loadProfile();// load user profile
            this.getLocations();// get locations (states, lgas, and countries)
        },
    }
</script>

<style>
    .form-control-label {
        width: 100%;
    }
</style>