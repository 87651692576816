<template>
    <div class="pl-lg-4">
        <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center">
            <span class="mask bg-gradient-danger opacity-8"></span>
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">

                <div class="col-xl-12 order-xl-1">
                    <card shadow type="secondary">
                        <div slot="header" class="bg-white border-0">
                            <div class="row align-items-center">
                                <div class="col-8">
                                    <h3 class="mb-0 text-uppercase">Update Account's Password</h3>
                                </div>
                                <div class="col-4 text-right">
                                    <router-link to="/" class="btn btn-sm btn-primary">
                                        <i class="fa fa-home"></i>
                                        Dashboard
                                    </router-link>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-lg-12">
                                <base-input alternative=""
                                    label="Current Password"
                                    placeholder="Enter Current Password"
                                    input-classes="form-control-alternative"
                                    v-model="record.current_password"
                                    type="password"
                                />
                            </div>
                            <div class="col-lg-12">
                                <base-input alternative=""
                                    label="New Password"
                                    placeholder="Enter New Password"
                                    input-classes="form-control-alternative"
                                    v-model="record.new_password"
                                    type="password"
                                />
                            </div>
                            <div class="col-lg-12">
                                <base-input alternative=""
                                    label="Confirm Password"
                                    placeholder="Re-enter Password"
                                    input-classes="form-control-alternative"
                                    v-model="record.confirm_password"
                                    type="password"
                                />
                            </div>
                        </div>

                        <base-alert :type="errorType" dismissible v-if='showErrorStatus'>
                            <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                            <span class="alert-inner--text">
                                {{errorMsg}}
                            </span>
                            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </base-alert>

                        <base-button type="success" @click="updatePassword()">
                            <i class="fa fa-save" @if='! isloading'></i>
                            {{isloading ? 'Please Wait...' : 'Update Password'}}
                        </base-button>
                    </card>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import { mapActions } from 'vuex';
    import swal from 'sweetalert';// import sweet alert

    export default {
        name: 'ChangePassword',
        data() {
            return {
                record: {
                    current_password: null,
                    new_password: null,
                    confirm_password: null
                },
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isloading: false
            }
        },
        methods: {
            ...mapActions(['changePassword']),
            updatePassword() {// update password
                this.isloading = true;
                let data = this.validInputs();
                if(data) {// update
                    data.id = this.$store.state.user.userId;// get user id
                    this.changePassword(data).then((res) => {
                        this.isloading = false;
                        this.emptyRecords();
                        if(res.status) {
                            swal("Updated successfully!", res.msg, "success");
                        } else {
                            swal("Error Occurred!", res.msg, "warning");
                        }
                    }).
                    catch(() => {
                        this.isloading = false;
                        swal("Network Failure", "Reload page and try again...", "warning");
                    });
                } else {
                    this.isloading = false;
                }
            },
            emptyRecords() {// empty records
                this.record.current_password = null;
                this.record.new_password = null;
                this.record.confirm_password = null;
            },
            validInputs() {// validate inputs
                let data = this.record;
                let check = (data.new_password == data.confirm_password) ? true :
                    this.showErrorMsg('Password Mis-matched!, kindly check and try again');
                data.confirm_password != null && data.confirm_password.length >= 8 ? true :
                    this.showErrorMsg('Confirm Password should be more than 8 characters');
                data.confirm_password != null ? true : this.showInuptError('Confirm Password');
                data.new_password != null && data.new_password.length >= 8 ? true :
                    this.showErrorMsg('New Password should be more than 8 characters');
                data.new_password != null ? true : this.showInuptError('New Password');
                data.current_password != null ? true : this.showInuptError('Current Password');
                return this.isEmpty(data) && check ? data : false;
            },
            isEmpty(obj) {// check for empty field
                return ! Object.keys(obj).some(k => !obj[k]);
            },
            showErrorMsg(msg) {
                this.isloading = false;
                this.showErrorStatus = true
                this.errorType = 'warning'
                this.errorMsg = msg;
                var self = this;
                setTimeout(function() { self.showErrorStatus = false; }, 5000);
            },
            showInuptError(name) {
                this.isloading = false;
                this.showErrorStatus = true
                this.errorType = 'warning'
                this.errorMsg = 'Invalid ' + name + ' has been entered';
                var self = this;
                setTimeout(function() { self.showErrorStatus = false; }, 5000);
            },
        },
    }
</script>