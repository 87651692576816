<template>
    <div>
        <div class="row" v-if="! isLoadingBlade">

            <div class="col-sm-12 text-center">
                <h3 class="text-uppercase">
                    Select the License Year below
                </h3>
                <br>

                <div class="row">
                    <div class="col-lg-3"></div>
                    <div class="col-lg-6">
                        <base-input alternative=""
                            label="License Year"
                            required
                            >

                            <select
                                class="form-control form-control-alternative"
                                v-model="license_year"
                                @change="loadAnnualLicenseDetails"
                            >
                                <option value="null" disabled>--- Select One ---</option>
                                <option
                                    v-for="year in years"
                                    :value="year"
                                    :key="year"
                                >
                                    {{year}}
                                </option>
                            </select>
                        </base-input>
                    </div>
                </div>
            </div>

            <div class="col-sm-12 text-center" v-if="application && application.application_type">
                <h3 class="text-capitalize">
                    {{ application.application_type.name }}
                </h3>
                <br>
                <h4 class="text-danger"
                    v-if="license_year"
                >
                    Note: If you make payment on or before <em class="text-primary"> 31st Decemeber,
                    {{ license_year - 1 }}</em>,
                    and you have the RRR number, kindly proceed to add the RRR payment. As the surge amount will
                    not be applicable to you.
                </h4>
                <br>
                

                <h3 class="text-capitalize"
                    v-if="license_year"
                >
                    <span>License Year: </span> &nbsp;
                    <em class="font-weight-light">
                        {{ license_year }}
                    </em>
                    <br><br>
                    <span>Amount: </span> &nbsp;
                    <em class="font-weight-light">
                        {{ application.application_type.has_payment.amount | formatPrice }}
                    </em>

                    <span>
                        <br><br>
                        <span class="text-danger">Surcharge: </span> &nbsp;
                        <em class="font-weight-light text-danger">
                            {{ application.application_type.has_payment.surcharge | formatPrice }}
                        </em>
                    </span>

                    <br><br>
                    <span>Total Amount: </span> &nbsp;
                    <em class="font-weight-light">
                        {{ getTotalAmount(application.application_type.has_payment) | formatPrice }}
                    </em>
                </h3>
            </div>

            <div class="col-sm-12 text-center borderLine">
                <hr>
                <base-button
                    v-if="license_year"
                    class="btn btn-lg btn-success"
                    type="success"
                    @click="createApplication()"
                >
                    <i class="fa fa-share-square"></i>
                    {{ isloading ? 'Please Wait...' : 'Proceed Now'}}
                </base-button>
            </div>
        </div>

        <div class="col-lg-12" v-if="isLoadingBlade">
            <div class="d-flex justify-content-center">
                <img :src="loadingBladeUrl" />
            </div>
        </div>

        <div class="col-sm-12 text-center">
            <base-alert :type="errorType" v-if='showErrorStatus'>
                <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                <span class="alert-inner--text">
                    {{errorMsg}}
                </span>
            </base-alert>
        </div>
    </div>
</template>
<script>
    import { mapGetters, mapActions } from 'vuex';
    import moment from 'moment';
    import swal from 'sweetalert2';// import sweet alert

    export default {
        name: 'PreviousLicense',
        filters: {
            formatPrice(value) {
                if(value) {
                    let val = (value/1).toFixed(2).replace(',', '.');
                    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' NGN';
                } else {
                    return 'N/A';
                }
            }
        },
        data() {
            return {
                application: null,
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isLoadingBlade: false,
                license_year: null,
                isloading: false
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl']),
            years() {
                let startYear = 2017;// start from 1900
                // let currentYear = this.application.license ? (moment().year()) : parseInt(moment().format('YYYY'));
                let currentYear = moment().year();
                let years = [];
                for (let i = currentYear; i > startYear; i-- ) {
                    years.push(i);
                }
                return years;
            }
        },
        methods: {
            ...mapActions(['getAnnualLicenseDetails', 'createUserApplication']),
            loadAnnualLicenseDetails(e) {
                let year = e && e.target && e.target.value ? e.target.value : moment().year();
                this.isLoadingBlade = true;
                this.getAnnualLicenseDetails(year).then((res) => {// get records
                    this.application = res;
                    this.isLoadingBlade = false; 
                }).
                catch(() => {
                    this.isLoadingBlade = false;
                    this.showErrorStatus = true;
                    this.errorMsg = "Network Failure!, kindly reload the page...";
                    this.errorType = 'danger';
                });
            },
            getTotalAmount(data) {// get total amount
                return (data && data.surcharge) ? parseInt(data.amount) + parseInt(data.surcharge)
                    : data.amount;
            },
            createApplication() {// create application
                this.preloader();// show loading
                this.isloading = true;
                let data = {};
                data.update = false;
                data.license_year = this.license_year;// add license year
                data.user_data_id = this.$store.state.user.basicProfile.id;// get user data id
                data.application_type_id = this.application.application_type.id;// apllication type id
                data.trans_no = this.genRandomString();// generate trans no
                data.application_date = this.getCurrentDate();// get current date
                this.createUserApplication(data).then((res) => {// create application
                    this.isloading = false;
                    if(res.status) {// update transaction
                        let message = 'Kindly click on Pay Now to make payment online';
                        swal.fire('Application Updated!', message, 'success');
                        // return this.updateTransaction(res, record);
                        return window.location.href='/application-payment/'+res.application.id;
                    } else {
                        let message = 'Network Failure!, kindly reload the page...';
                        swal.fire('Network Failure!', message, 'warning');
                    }
                }).
                catch(() => {
                    this.isloading = false;
                    let message = 'Network Failure!, kindly reload the page...';
                    swal.fire('Network Failure!', message, 'warning');
                    return false;
                });
            },
            getCurrentDate() {// get current date time
                let date = new Date().toJSON().slice(0,10).replace(/-/g,'-');
                return moment(date).format('YYYY-MM-DD');
            },
            genRandomString() {
                var length = 12;
                var text = '';
                var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
                for (var i=0; i < length; i++) {
                    text += possible.charAt(Math.floor(Math.random() * possible.length));
                }
                return text;
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        created() {
            // this.loadAnnualLicenseDetails();// annual license details
        },
    }
</script>