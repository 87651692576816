<template>
    <div>
        <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center no-padding-bottom">
            <span class="mask bg-gradient-danger opacity-8"></span>
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col-xl-12 order-xl-1">
                    <br><br><br>
                    <card shadow type="secondary">
                        <div slot="header" class="bg-white border-0">
                            <div class="row align-items-center">

                                <div class="col-8">
                                    <h3 class="mb-0 text-uppercase">{{ this.$route.name }}</h3>
                                    <br>
                                    <span class="text-danger">
                                        Kindly enter a valid RRR number (must have been paid) and proceed to get doctor status with the council,
                                        keep your RRR number safe...
                                    </span>
                                </div>
                                <div class="col-4 text-right">
                                    <router-link
                                        to="/confirm-doctor-status"
                                        class="btn btn-sm btn-danger"
                                    >
                                        <i class="fa fa-arrow-left"></i>
                                        Go Back
                                    </router-link>
                                </div>
                            </div>
                        </div>

                        <div class="row" v-if='! showErrorStatus && ! isLoadingBlade && ! showRecord'>

                            <div class="col-lg-4">
                                <base-input alternative=""
                                    label="RRR"
                                    placeholder="Enter Valid RRR"
                                    input-classes="form-control-alternative"
                                    v-model="record.rrr"
                                    required
                                    :readonly="rrr"
                                />
                            </div>

                            <div class="col-lg-4">
                                <base-input alternative=""
                                    label="Doctor Practice Type"
                                    required
                                >
                                    <select
                                        class="form-control form-control-alternative"
                                        v-model="record.doctor_type_id"
                                        @change="updateFolioPrefix"
                                        required
                                    >
                                        <option :value="null">--- Select One ---</option>
                                        <option value="1">Medical Registration</option>
                                        <option value="2">Dental Registration</option>
                                        <option value="3">Alternative Registration</option>
                                    </select>
                                </base-input>
                            </div>

                            <div class="col-lg-4">
                                <div class="row">
                                    <div class="col-lg-5">
                                        <base-input
                                            alternative=""
                                            label="Folio Prefix"
                                            input-classes="form-control-alternative"
                                            :placeholder="record.folio_prefix"
                                            readonly
                                        >
                                        </base-input>
                                    </div>

                                    <div class="col-lg-7">
                                        <base-input
                                            label="Doctor Folio Number (Numbers Only)"
                                            input-classes="form-control-alternative"
                                            placeholder="Enter Folio Number"
                                            type="number"
                                            v-model="record.folio"
                                        >
                                        </base-input>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-12" v-if="isLoadingBlade">
                            <div class="d-flex justify-content-center">
                                <img :src="loadingBladeUrl" />
                            </div>
                        </div>

                        <div class="col-lg-12 text-center" v-if='showErrorStatus && ! isLoadingBlade'>
                            <base-alert :type="errorType">
                                <!-- <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span> -->
                                <span class="alert-inner--text">
                                    <h2 class="text-white">{{errorMsg}}</h2>
                                </span>
                            </base-alert>
                        </div>

                        <div class="col-lg-12 text-center" v-if="! showRecord">
                            <hr>
                            <h3 class="text-danger">
                                For more infromation contact MDCN via
                                <em class="text-primary">registration@mdcn.gov.ng</em> to request for
                                verification of the doctor.
                            </h3>
                        </div>

                        <div v-if='! showErrorStatus && ! isLoadingBlade && ! showRecord'>
                            <hr>
                            <router-link
                                to="/confirm-doctor-status"
                                class="btn btn-danger"
                            >
                                <i class="fa fa-arrow-left"></i>
                                Go Back
                            </router-link>

                            <base-button
                                type="success"
                                class="right"
                                :disabled="! enableButton"
                                @click="loadDoctorProfile()"
                            >
                                {{ isloading ? 'Please Wait...' : 'Proceed Now'}}
                                <i class="fa fa-arrow-right"></i>
                            </base-button>
                            <br><br><br><br>
                        </div>

                        <!-- The doc data page included here -->
                        <ShowDoctorData
                            :doctor="doctor"
                            v-if="showRecord"
                        />

                    </card>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import ShowDoctorData from './ShowDoctorData';
    import swal from 'sweetalert2';// import sweet alert

    export default {
        name: 'DoctorVerifyPage',
        components: {
            ShowDoctorData
        },
        data() {
            return {
                rrr: this.$route.query.rrr,
                record: {
                    doctor_type_id: null,
                    folio: null,
                    rrr: null,
                    folio_prefix: null
                },
                doctor: null,
                showRecord: false,
                isloading: false,
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isLoadingBlade: false
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl']),
            enableButton() {
                return this.isEmpty(this.record) ? true : false;
            },
        },
        methods: {
            ...mapActions(['getRemitaPaymentStatus', 'getDoctorStatusProfile']),
            setData() {
                return this.record.rrr = this.rrr ? this.rrr : null;
            },
            updateFolioPrefix(event) {// change prefix
                let type = event.target.value;
                if(type == 1 || type == 2) {
                    this.record.folio_prefix =  'MDCN/R/';
                } else {
                    this.record.folio_prefix =  'MDCN/ALT/R/';
                }
            },
            isEmpty(obj) {// check for empty field
                return ! Object.keys(obj).some(k => !obj[k]);
            },
            loadDoctorProfile() {
                let status = this.isEmpty(this.record);
                if(status) {
                    this.isloading = true;
                    this.preloader();
                    this.getDoctorStatusProfile(this.record).then((res) => {
                        this.isloading = false;
                        if(res.status) {
                            this.doctor = res.doctor;
                            this.showRecord = true;
                            swal.close();
                        } else {
                            swal.fire("Payment Failed!", res.msg, "warning");
                        }
                    }).
                    catch(() => {
                        let msg = "Network Failure!, kindly reload the page...";
                        swal.fire("Error Ocurred!", msg, "warning");
                    });
                } else {
                    let msg = "Kindly fill all necessary field(s), and try again...";
                    swal.fire("Empty Field(s)!", msg, "warning");
                }
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        created() {
            this.setData();
        }
    }
</script>

<style scoped>
    .no-padding-bottom {
        padding-bottom: 0rem !important
    }
    .right {
        float: right;
    }
</style>

<style>
    .form-control-label {
        width: 100%;
    }
    .height-50 {
        height: 50px;
    }
    .borderLine {
        border: 1px solid #ededed;
    }
</style>