<template>
    <div class="row">

        <div class="col-lg-12" v-if="isLoadingBlade">
            <div class="d-flex justify-content-center">
                <img :src="loadingBladeUrl" />
            </div>
        </div>

        <div class="container" v-if='! showErrorStatus && ! isLoadingBlade'>
            <div class="row">

                <div class="col-lg-6">
                    <base-input alternative=""
                        label="Country (Country of verification)"
                        required
                    >
                        <autocomplete
                            v-if="locations && locations.countries"
                            input-class="form-control form-control-alternative"
                            placeholder="Type to Search Country"
                            showNoResults
                            :disableInput="showReSendButton"
                            :source="locations.countries"
                            v-model="record.country_id"
                            :initialDisplay="(hasCountry ? hasCountry.name : '')"
                            @selected="showStates"
                        >
                        </autocomplete>
                    </base-input>
                </div>

                <div class="col-lg-6" v-show="displayStates">
                    <base-input alternative=""
                        label="State of verification (For Nigeria Only)"
                        required
                    >
                        <autocomplete
                            v-if="locations && locations.states"
                            input-class="form-control form-control-alternative"
                            placeholder="Type to Search State"
                            showNoResults
                            :disableInput="showReSendButton"
                            :source="locations.states"
                            v-model="record.state_id"
                            :initialDisplay="(hasState ? hasState.name : '')"
                        >
                        </autocomplete>
                    </base-input>
                </div>

            </div>
            <hr class="my-4"/>
        </div>

        <div class="col-sm-12 text-center">
            <base-alert :type="errorType" v-if='showInputErrorStatus'>
                <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                <span class="alert-inner--text">
                    {{errorMsg}}
                </span>
            </base-alert>
        </div>


        <div class="col-lg-12" v-if='! showErrorStatus && ! isLoadingBlade'>
            <br>
            <base-button
                type="success"
                @click="saveEics()"
                v-if="! showReSendButton"
                :disabled="enableSaveButton"
            >
                <i class="fa fa-save"></i>
                {{isloading ? 'Please Wait...' : 'Save & Continue'}}
            </base-button>

            <base-button
                type="success"
                @click="reSendApplication(applicationId)"
                v-if="showReSendButton"
            >
                <i class="fa fa-upload"></i>
                Re-send Application
            </base-button>

            <div class="right">
                <base-button
                    type="danger"
                    @click="editApplication()"
                    v-if="showReSendButton"
                >
                    <i class="fa fa-pen"></i>
                    Edit Application
                </base-button>
            </div>
        </div>

        <div class="col-sm-12 text-center">
            <base-alert :type="errorType" v-if='showErrorStatus'>
                <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                <span class="alert-inner--text">
                    {{errorMsg}}
                </span>
            </base-alert>
        </div>

    </div>

</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import swal from 'sweetalert2';// import sweet alert
    import Autocomplete from 'vuejs-auto-complete';

    export default {
        name: 'ApplyEics',
        components: {
            Autocomplete
        },
        data() {
            return {
                id: this.$route.params.id,
                record: {
                    id: null,
                    country_id: null,
                    state_id: null,
                    user_data_id: null,
                    update: false
                },
                displayStates: false,
                documents: [],
                showErrorStatus: false,
                showInputErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isloading: false,
                isLoadingBlade: true,
                updateStatus: false,
                isRejected: false,
                showReSendButton: false,
                applicationId: null
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl', 'locations']),
            enableSaveButton() {
                if(this.record.country_id && this.record.country_id == 164 && this.record.state_id)
                    return false;
                else if(this.record.country_id && this.record.country_id != 164)
                    return false;
                else
                    return true;
            },
            hasCountry() {
                let country = this.locations && this.locations.countries ?
                            this.locations.countries.filter(item => item.id == this.record.country_id) : null;
                return country ? country[0] : {};
            },
            hasState() {
                let state = this.locations && this.locations.states ?
                            this.locations.states.filter(item => item.id == this.record.state_id) : null;
                return state ? state[0] : {};
            }
        },
        methods: {
            ...mapActions(['saveUserEics','getEicsApplication', 'getApplication',
                'getLocations', 'reSubmitApplication']),
            loadApplication() {
                this.getLocations();
                if(this.id) {// load exact application
                     this.getExactApplication(this.id);
                } else {// load any pending application
                    return this.loadPendingApplication();
                }
            },
            getExactApplication(id) {// load exact application
                this.getApplication({'id' : id}).then((res) => {
                    this.applicationId = res.id;
                    this.updateRecord(res);// update record for editing
                    this.record.update = res ? true : false;
                    this.isRejected = res.status == 'rejected' ? true : false;
                    this.isLoadingBlade = false;
                }).
                catch(() => {
                    this.isLoadingBlade = false;
                    this.showErrorStatus = true;
                    this.errorMsg = "Network Failure!", "Kindly reload the page...";
                    this.errorType = 'danger';
                });
            },
            loadPendingApplication() {// load details
                this.getEicsApplication().then((res) => {
                    this.record.update = res.update;
                    this.updateRecord(res.application);// update record for editing
                    this.isLoadingBlade = false;
                }).
                catch(() => {
                    this.isLoadingBlade = false;
                    this.showErrorStatus = true;
                    this.errorMsg = "Network Failure!", "Kindly reload the page...";
                    this.errorType = 'danger';
                });
            },
            updateRecord(data) {// update AQ Record for editing
                if(data) {
                    this.record.country_id = data.country_id;
                    this.record.state_id = data.state_id;
                    this.record.id = data.id;
                    if(data.state_id && data.country_id == 164) {
                        this.displayStates = true;
                    }
                }
            },
            showStates(e) {// display state_ids for nigeria option
                this.record.state_id = null;
                let value = e.value;
                return this.displayStates = (value == 164);
            },
            saveEics() {// save change name
                let status = this.validatedInputs();// validate inputs
                if(status) {// update files
                    this.isloading = true;
                    this.record.user_data_id = this.$store.state.user.userDataId;
                    this.saveUserEics(this.record).then((res) => {
                        this.isloading = false;
                        if(res.status && this.isRejected) {
                            let msg = "Application was updated successfully";
                            swal.fire("Application Updated!", msg, "success");
                            return this.showReSendButton = true;
                        } else if(res.status && ! this.isRejected) {
                            return window.location.href='/new-application-payment/'+res.application_type_id;
                        } else {
                            swal.fire("Error Occurred!", "An error occurred while saving, try again", "warning");
                        }
                    }).
                    catch(err => {
                        this.isloading = false;
                        swal.fire(err.message+"!", "An error occurred while saving, try again", "warning");
                    });
                }
            },
            validatedInputs() {
                let data = this.record;
                let addRecord = { country_id: null};
                addRecord.country_id  = data.country_id == null ?
                    this.showInuptError('country_id') : data.country_id;
                return this.isEmpty(addRecord) ? true : false;
            },
            isEmpty(obj) {// check for empty field
                return ! Object.keys(obj).some(k => !obj[k]);
            },
            showInuptError(name) {
                this.isloading = false;
                this.showInputErrorStatus = true
                this.errorType = 'warning'
                this.errorMsg = 'Invalid ' + name + ' has been entered';
                var self = this;
                setTimeout(function() { self.showInputErrorStatus = false; }, 5000);
            },
            editApplication() {
                return this.showReSendButton = false;
            },
            reSendApplication(id) {// re-send rejected application
                swal.fire({
                    title: 'Are you sure?',
                    text: "You must have corrected the issue before proceeding to re-submit application!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, I have corrected it!'
                }).then((result) => {
                    if (result.value) {
                        this.makeReSubmitApiCall(id);// make api call
                    }
                });
            },
            makeReSubmitApiCall(id) {
                this.preloader(); // show loading
                this.reSubmitApplication(id).then((res) => {// get application record
                    if(res.status) {
                        swal.fire("Application Submitted!", res.msg, "success");
                        return window.location.href='/applications';
                    } else {
                        swal.fire("Error Occurred!", res.msg, "warning");
                    }
                }).
                catch(() => {
                    let msg = "Network Failure! , kindly reload the page...";
                    swal.fire("Network Failure!", msg, "warning");
                });
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b> ...',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        created() {
            this.loadApplication();// load records
        },
    }
</script>

<style scoped>
    .right {
        float: right;
    }
</style>