<template>
    <form @submit.prevent>
        <h6 class="heading-small text-muted mb-4">Work information</h6>

        <base-alert :type="errorType" dismissible v-if='showErrorStatus'>
            <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
            <span class="alert-inner--text">
                {{errorMsg}}
            </span>
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </base-alert>

        <div class="pl-lg-4">
            <div class="row">
                <div class="col-lg-4">
                    <base-input alternative=""
                        label="Year of Graduation"
                        required
                    >
                        <select
                            class="form-control form-control-alternative"
                            v-model="profile.year_of_graduation"
                            disabled
                        >
                            <option value="null" disabled>--- Select One ---</option>
                            <option
                                v-for="year in years"
                                :value="year"
                                :key="year"
                            >
                                {{year}}
                            </option>
                        </select>
                    </base-input>
                </div>
                <div :class="showEmploymentSector ? 'col-lg-4' : 'col-lg-8'">
                    <base-input alternative=""
                        label="Employment Status"
                    >
                        <select
                            class="form-control form-control-alternative"
                            v-model="profile.employ_status"
                        >
                            <option value="null" disabled>--- Select One ---</option>
                            <option value="employed">Employed</option>
                            <option value="unemployed">Unemployed</option>
                        </select>
                    </base-input>
                </div>
                <div class="col-lg-4" v-if="showEmploymentSector">
                    <base-input alternative=""
                        label="Employment Sector"
                    >
                        <select
                            class="form-control form-control-alternative"
                            v-model="profile.employ_sector"
                        >
                            <option value="null" disabled>--- Select One ---</option>
                            <option value="private">Private</option>
                            <option value="government">Government</option>
                        </select>
                    </base-input>
                </div>



                <div class="col-lg-4" v-if="showLevelofGovernment">
                    <base-input alternative=""
                        label="Level of Government (Employment)"
                    >
                        <select
                            class="form-control form-control-alternative"
                            v-model="profile.level_of_govt"
                        >
                            <option value="null" disabled>--- Select One ---</option>
                            <option value="federal-government">Federal Government</option>
                            <option value="state-government">State Government</option>
                            <option value="local-government">Local Government</option>
                        </select>
                    </base-input>
                </div>

                <div class="col-lg-4" v-if="showServiceStatus">
                    <base-input alternative=""
                        label="Employment Service Status"
                    >
                        <select
                            class="form-control form-control-alternative"
                            v-model="profile.service_status"
                        >
                            <option value="null" disabled>--- Select One ---</option>
                            <option value="retired">Retired</option>
                            <option value="in-service">In-service</option>
                        </select>
                    </base-input>
                </div>

                <div class="col-lg-4" v-if="showOtherEmploymentDetails">
                    <base-input alternative=""
                        label="Level Of Care (Employment)"
                    >
                        <select
                            class="form-control form-control-alternative"
                            v-model="profile.level_of_care"
                        >
                            <option value="null" disabled>--- Select One ---</option>
                            <option value="tertiary">Tertiary</option>
                            <option value="secondary">Secondary</option>
                            <option value="primary">Primary</option>
                            <option value="not-applicable">Not Applicable</option>
                        </select>
                    </base-input>
                </div>

                <div class="col-lg-4" v-if="showOtherEmploymentDetails">
                    <base-input alternative=""
                        label="Practice Geo-Location (Employment)"
                    >
                        <select
                            class="form-control form-control-alternative"
                            v-model="profile.practice_geolocation"
                        >
                            <option value="null" disabled>--- Select One ---</option>
                            <option value="urban">Urban</option>
                            <option value="rural">Rural</option>
                            <option value="sub-urban">Sub-Urban</option>
                        </select>
                    </base-input>
                </div>

                <div class="col-lg-4" v-if="showOtherEmploymentDetails">
                    <base-input alternative=""
                        label="Category Of Practice (Employment)"
                    >
                        <select
                            class="form-control form-control-alternative"
                            v-model="profile.category_of_practice"
                        >
                            <option value="null" disabled>--- Select One ---</option>
                            <option value="clinical">Clinical</option>
                            <option value="non-clinical">Non-Clinical</option>
                        </select>
                    </base-input>
                </div>

                <div class="col-lg-4" v-if="showClinicalType">
                    <base-input alternative=""
                        label="Clinical Type (Employment)"
                    >
                        <select
                            class="form-control form-control-alternative"
                            v-model="profile.clinical_type"
                        >
                            <option value="null" disabled>--- Select One ---</option>
                            <option value="clinic">Clinic</option>
                            <option value="hospital">Hospital</option>
                        </select>
                    </base-input>
                </div>

                <div class="col-lg-4" v-if="showNonClinicalType">
                    <base-input alternative=""
                        label="Non-Clinical Type (Employment)"
                    >
                        <select
                            class="form-control form-control-alternative"
                            v-model="profile.non_clinical_type"
                        >
                            <option value="null" disabled>--- Select One ---</option>
                            <option value="academia">Academia</option>
                            <option value="ngo">NGO</option>
                            <option value="others">Others</option>
                        </select>
                    </base-input>
                </div>
            </div>            
        </div>

        <h6 class="heading-small text-muted mb-4">Practice Location information</h6>

        <div class="pl-lg-4">
            <div class="row">
                <div class="col-lg-4">
                    <base-input alternative=""
                        label="Country of Practice"
                        required
                    >
                        <autocomplete
                            v-if="locations && locations.countries && locations.countries.length > 0"
                            input-class="form-control form-control-alternative"
                            placeholder="Type to Search Country of Practice"
                            showNoResults
                            :source="locations.countries"
                            v-model="profile.country_of_practice"
                            :initialDisplay="(hasCountry ? hasCountry.name : '')"
                            @selected="changeCountry"
                            :disableInput="lock"
                        >
                        </autocomplete>
                    </base-input>
                </div>
                <div class="col-lg-4">
                    <base-input alternative=""
                        label="State of Practice / Residence"
                    >
                        <autocomplete
                            v-if="cities && cities.length > 0"
                            ref="autocomplete"
                            input-class="form-control form-control-alternative"
                            placeholder="Type to Search State of Practice / Residence"
                            showNoResults
                            :source="cities"
                            :initialDisplay="(hasCity ? hasCity.name : '')"
                            @selected="changeCity"
                            :disableInput="lock"
                        >
                        </autocomplete>
                    </base-input>
                </div>
                <div class="col-lg-4" v-if="showLocalStateOfPractice && profile.state_of_practice">
                    <base-input alternative=""
                        label="LGA of Practice / Residence"
                    >
                        <autocomplete
                            v-if="lgas && lgas.length > 0"
                            input-class="form-control form-control-alternative"
                            placeholder="Type to Search for LGA of Practice / Residence"
                            showNoResults
                            :source="lgas"
                            v-model="profile.lga_of_practice"
                            :initialDisplay="(profile.has_practice_lga ? profile.has_practice_lga.name : '')"
                            :disableInput="lock"
                        >
                        </autocomplete>
                    </base-input>
                </div>
                <div class="col-lg-4" v-if="displayOtherState">
                    <base-input alternative=""
                        label="Other State of Practice / Residence" 
                        placeholder="Other State of Practice / Residence"
                        input-classes="form-control-alternative text-capitalize"
                        v-model="profile.foreign_state_practice"
                        :readonly="lock"
                    />
                </div>

                <div class="col-lg-4"
                    v-if="profile.teaching_hospital_id"
                >
                    <base-input alternative=""
                        label="Hospital of Practice/Residence"
                    >
                        <autocomplete
                            v-if="hospitals && hospitals.length > 0"
                            input-class="form-control form-control-alternative"
                            placeholder="Type to Search for Hospital"
                            showNoResults
                            :source="hospitals"
                            v-model="profile.teaching_hospital_id"
                            :initialDisplay="(profile.has_practice_hospital ? profile.has_practice_hospital.name : profile.hospital_practice)"
                            :disableInput="lock"
                        >
                        </autocomplete>
                    </base-input>
                </div>
            </div>
        </div>


        <h6 class="heading-small text-muted mb-4">Specialization information</h6>

        <div class="pl-lg-4">
            <div class="row">
                <div class="col-lg-6">
                    <base-input alternative=""
                        label="Area of Specialization"
                        required
                    >
                        <select
                            class="form-control form-control-alternative"
                            v-model="doctorSpecialty.specialization"
                            :disabled="lock"
                            @change="changeSubSpecializations()"
                        >
                            <option
                                v-for="specialization in specializations"
                                :value="specialization.id"
                                :key="specialization.id"
                            >
                                {{specialization.name}}
                            </option>
                        </select>
                    </base-input>
                </div>
                <div class="col-lg-6" v-if="subSpecializations && subSpecializations.length > 0">
                    <base-input alternative=""
                        label="Area of Sub Specialization"
                        required
                    >
                        <select
                            class="form-control form-control-alternative"
                            v-if="doctorSpecialty.specialization"
                            v-model="doctorSpecialty.sub_specialization"
                            :disabled="lock"
                        >
                            <option
                                value='0'
                                disabled
                            >
                                ---Select One---
                            </option>
                            <option
                                v-for="sub_specialization in subSpecializations"
                                :value="sub_specialization.id"
                                :key="sub_specialization.id"
                            >
                                {{sub_specialization.name}}
                            </option>
                        </select>
                    </base-input>
                </div>
            </div>
        </div>

        <base-alert :type="errorType" dismissible v-if='showErrorStatus'>
            <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
            <span class="alert-inner--text">
                {{errorMsg}}
            </span>
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </base-alert>

        <div class="pl-lg-4">
            <base-button type="success" @click="updateWorkProfile()">
                <i class="fa fa-save" @if='! isloading'></i>
                {{isloading ? 'Please Wait' : 'Save Changes'}}
            </base-button>
        </div>
    </form>
</template>

<script>
    import moment from 'moment';
    import { mapGetters, mapActions } from 'vuex';
    import swal from 'sweetalert';// import sweet alert
    import Autocomplete from 'vuejs-auto-complete';
    import states from '../../../states.json';

    export default {
        name: 'WorkProfile',
        props: ['loadUserProfile'],
        components: {
            Autocomplete
        },
        data() {
            return {
                profile: {},
                lgas: [],
                specializations: [],
                subSpecializations: [],
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isloading: false,
                doctorSpecialty: {
                    'specialization' : null,
                    'sub_specialization' : null
                },
                locations: [],
                states: states,
                cities: [],
                displayOtherState: false
            }
        },
        computed: {
            ...mapGetters(['lock', 'hospitals']),
            years() {
                let startYear = moment().format('YYYY') - 119;// start from 1900
                let currentYear = parseInt(moment().format('YYYY'));
                let years = [];
                for (let i = currentYear; i > startYear; i-- ) {
                    years.push(i);
                }
                return years;
            },
            showLocalStateOfPractice() {//check if country is Nigeria (id = 164)
                if(this.profile.country_of_practice) {
                    return this.profile.country_of_practice == 164 ? true : false;
                } else {
                    return true;
                }
            },
            showEmploymentSector() {// check if user is employed
                return this.profile.employ_status == "employed" ? true : false;
            },
            showLevelofGovernment() {// display the level of govt
                return this.showEmploymentSector && (this.profile.employ_sector == "government") ? true : false;
            },
            showServiceStatus() {// display employment service status
                return this.showLevelofGovernment && (this.profile.level_of_govt != null) ? true : false;
            },
            showOtherEmploymentDetails() {// display other employment details
                return this.showEmploymentSector && this.profile.employ_sector ? true : false;
            },
            showClinicalType() {// display clinical type
                return this.showEmploymentSector && (this.profile.category_of_practice == 'clinical') ? true : false;
            },
            showNonClinicalType() {// display non-clinical type
                return this.showEmploymentSector && (this.profile.category_of_practice == 'non-clinical') ? true : false;
            },
            hasCountry() {
                let country = this.locations && this.locations.countries ?
                            this.locations.countries.filter(item => item.id == this.profile.country_of_practice) : null;
                return country && country.length > 0 ? country[0] : {};
            },
            hasCity() {
                let city = this.cities ? this.cities.filter(item => item.id == this.profile.state_of_practice) : null;
                return city && city.length > 0 ? city[0] : { name: this.profile.foreign_state_practice };
            }
        },
        methods: {
            ...mapActions(['getBasicProfile', 'getLocations', 'getSpecializations', 'UpdateUserWorkProfile']),// make api call for user profile
            changeCity(e) {
                let data = e.selectedObject;
                this.displayOtherState = false;
                this.profile.state_of_practice = null;
                this.lgas = [];
                if(data.id == 0) {
                    this.profile.foreign_state_practice = null;
                    this.displayOtherState = true;
                } else {
                    if(this.profile.country_of_practice == 164) {
                        this.profile.foreign_state_practice = null;
                        this.profile.state_of_practice = data.id;
                        this.lgas = this.locations.lgas.filter(item => item.state_id == this.profile.state_of_practice);
                    } else {
                        this.profile.foreign_state_practice = data.name;
                    }
                }
            },
            changeCountry(e, code) {// get exact institutions
                this.cities = [];
                let id = code ? code : e.selectedObject.id;
                if(id == 164) {
                    this.cities = this.locations.states;
                } else {
                    let data = code ? this.hasCountry :  e.selectedObject;
                    let other = { id : 0, name: 'Others (Specify)'};// get cities
                    this.cities = this.states.filter(item => item.country_code == data.code);
                    this.cities.push(other);
                }
                if(! code) {
                    this.profile.state_of_practice = null;
                    this.profile.lga_of_practice = null;
                }
            },
            loadProfile() {// load user profile function
                this.getBasicProfile().then((res) => {
                    this.profile = res;
                    this.loadSpecialty(res.has_specialization);// load doctor specialty
                    this.getStateLgas(res.country_of_practice);// load doctor state's lga
                    this.getSpecializationSubs();// load doctor specialty subs only
                    this.getUserTypeSpecializations();// load doctor type specialty only
                });
            },
            loadSpecialty(data) {// update user specialty
                this.doctorSpecialty.specialization = data.specialization_id;
                this.doctorSpecialty.sub_specialization = data.sub_specialization_id;
            },
            getStateLgas(id) {// get state's lgas
                let lgas = [];
                let stateId = this.profile.state_of_practice;
                this.getLocations().then((res) => {
                    this.locations = res;
                    for(let lga of res.lgas) {
                        if (lga.state_id == stateId) {
                            lgas.push(lga);
                        }
                    }
                    this.lgas = lgas;
                    this.changeCountry(null, id);
                });
            },
            getUserTypeSpecializations() {// get doctor type specializations only
                this.getSpecializations().then((res) => {
                    let specializations = [];
                    let doctorTypeId = this.profile.doctor_type_id;// get doctor type id
                    for(let specialty of res.specializations) {
                        if (specialty.doctor_type_id == doctorTypeId) {
                            specializations.push(specialty);
                        }
                    }
                    return this.specializations = specializations;
                });
            },
            getSpecializationSubs() {// get a certain specialization's sub specializations
                let sub_specializations = [];
                let specialtyId = this.doctorSpecialty.specialization;
                this.getSpecializations().then((res) => {
                    for(let specialty of res.sub_specializations) {
                        if (specialty.specialization_id == specialtyId) {
                            sub_specializations.push(specialty);
                        }
                    }
                    return this.subSpecializations = sub_specializations;
                });
            },
            changeSubSpecializations() {// change sub specializations array
                return this.getSpecializationSubs();
            },
            updateWorkProfile() {
                this.isloading = true;
                let data = this.validatedData();// valid input data
                data['id'] = this.profile && this.profile.id ? this.profile.id : false;
                if(data.country_of_practice == 164) {// update practice details
                    data.foreign_state_practice = null;
                } else {
                    data.state_of_practice = null;
                    data.lga_of_practice = null;
                }
                if(data['id']) {// check for user id & perform update
                    this.UpdateUserWorkProfile(data).then((res) => {
                        this.isloading = false;
                        if(res.status) {// load updated profile
                            this.loadProfile();
                            this.loadUserProfile();
                            swal("Work Profile Updated!", res.msg, "success");
                        } else {
                            swal("Error Ocurred!", res.msg, "warning");
                        }
                    });
                } else {
                    let msg = 'User profile wasn\'t fetched properly, reload page and try again';
                    swal("Error Ocurred!", msg, "warning");
                }
            },
            validatedData() {
                let data = this.profile;
                let specialty = this.doctorSpecialty;
                let validData = {};
                validData['year_of_graduation'] = data.year_of_graduation != null ? data.year_of_graduation : this.showInuptError('Year of Graduation');
                validData['country_of_practice'] = data.country_of_practice != null ? data.country_of_practice : this.showInuptError('Country of Practice');
                
                
                validData['state_of_practice'] = data.state_of_practice;
                validData['lga_of_practice'] = data.lga_of_practice;
                validData['foreign_state_practice'] = data.foreign_state_practice;
                specialty && (specialty.specialization != null || specialty.specialization != 0)
                         ? specialty.specialization : this.showInuptError('Area of Specialization');
                validData['specialization'] = {
                    'specialization_id' : specialty.specialization,
                    'sub_specialization_id' : specialty.sub_specialization,
                };
                validData['employ_status'] = data.employ_status;
                if(data.employ_status == 'employed') {// format all employment info
                    validData['employ_sector'] = data.employ_sector;
                    validData['level_of_care'] = data.level_of_care;
                    validData['practice_geolocation'] = data.practice_geolocation;
                    validData['category_of_practice'] = data.category_of_practice;
                    if(data.employ_sector == 'government') {// add government info
                        validData['level_of_govt'] = data.level_of_govt;
                        validData['service_status'] = data.service_status;
                    } 
                    if(data.category_of_practice == 'clinical') {// add exact category of pratice type
                        validData['clinical_type'] = data.clinical_type;
                    } else if(data.category_of_practice == 'non-clinical') {
                        validData['non_clinical_type'] = data.non_clinical_type;
                    } else {
                        this.showInuptError('Clinical Type / Non-Clinical Type')
                    }
                }
                return validData;
            },
            showUpdateMsg(msg, type) {
                this.showErrorStatus = true;
                this.errorType = type;
                this.errorMsg = msg;
                var self = this;
                setTimeout(function() { self.showErrorStatus = false; }, 5000);
            },
            showInuptError(name) {
                this.isloading = false;
                this.showErrorStatus = true
                this.errorType = 'warning'
                this.errorMsg = 'Invalid ' + name + ' has been entered';
                var self = this;
                setTimeout(function() { self.showErrorStatus = false; }, 5000);
            }
        },
        created() {
            this.loadProfile();// load user profile
            this.getLocations();// get locations (states, lgas, and countries)
            this.getSpecializations();// get specializations
        },
    }
</script>

<style>

</style>
