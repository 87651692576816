<template>
    <div class="col-xl-12 order-xl-1">
        <card shadow type="secondary">

            <div class="pl-lg-4">

                <div class="col-lg-12">
                    <div class="d-flex justify-content-center">
                        <img :src="completeImageUrl" />

                    </div>
                    <br>
                    <h2 class="text-uppercase text-center">
                        You have completed the correction process
                    </h2>
                    <h3 class="text-center text-danger">
                        Note before you hit submit button, you can still make correction to your application by
                        clicking on the previous button. After proper check, kindly hit the re-send
                        button to submit your application for approval.
                    </h3>
                </div>

            </div>

            <hr class="my-4"/>

            <div class="pl-lg-4">
                <base-button
                    type="danger"
                    @click="showPreviousPage()"
                >
                    <i class="fa fa-arrow-left"></i>
                    Previous
                </base-button>
                <div class="right">
                    <base-button
                        type="success"
                        @click="reSendApplication()"
                    >
                        <i class="fa fa-history"></i>
                        Re-send Application
                    </base-button>
                </div>
            </div>

        </card>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'; //AddQualPayment
    import swal from 'sweetalert2';// import sweet alert

    export default {
        name: 'ReSubmitApp',
        props: ['displayNMALevy'],
        computed: {
            ...mapGetters(['completeImageUrl']),
        },
        data() {
            return {
                id: this.$route.params.id
            }
        },
        methods: {
            ...mapActions(['reSubmitApplication']),
            showPreviousPage() {// go to next page
                return this.displayNMALevy(true);
            },
            reSendApplication() {// re-send rejected application
                swal.fire({
                    title: 'Are you sure?',
                    text: "You must have corrected the issue before proceeding to re-submit application!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, I have corrected it!'
                }).then((result) => {
                    if (result.value) {
                        this.makeReSubmitApiCall();// make api call
                    }
                });
            },
            makeReSubmitApiCall() {
                this.preloader(); // show loading
                this.reSubmitApplication(this.id).then((res) => {// get application record
                    if(res.status) {
                        swal.fire("Application Submitted!", res.msg, "success");
                        this.$router.push({ path : '/applications' });
                    } else {
                        swal.fire("Error Occurred!", res.msg, "warning");
                    }
                }).
                catch(() => {
                    let msg = "Network Failure! , kindly reload the page...";
                    swal.fire("Network Failure!", msg, "warning");
                });
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        }
    }
</script>

<style scoped>
.right {
    float: right;
}
</style>