<template>
    <div class="row" v-if="! showAddRecord && cpdRecords && cpdRecords.length > 0">
        <div class="col-lg-12">
            <div class="card shadow">
                <div class="table-responsive">
                    <base-table 
                        class="table align-items-center table-flush"
                        thead-classes="thead-light"
                        tbody-classes="list"
                        :data="cpdRecords"
                    >
                        <template slot="columns">
                            <th width="2%">S/N</th>
                            <th width="20%">Description</th>
                            <th width="10%">License Year</th>
                            <th width="10%">Date</th>
                            <th width="10%">Points</th>
                            <th width="5%">&nbsp;</th>
                        </template>

                        <template 
                            slot-scope="{row, index}"
                        >
                        <td class="budget">
                            {{ pagination.from+index+1}}
                        </td>
                        <td class="text-capitalize">
                            {{ row.description }}
                        </td>
                        <td class="text-capitalize">
                            {{ row.license_year }}
                        </td>
                        <td>
                            {{row.date | getDateFormat}}
                        </td>
                        <td class="text-capitalize">
                            {{ row.point ? row.point : 'Not Allocated' }}
                        </td>
                        <td>
                            <base-button tag="a" 
                                type="success" size="sm" 
                                :href="cpdPointUrl+row.file_name" 
                                role="button" target="_blank"
                                aria-pressed="true"
                            >
                               <i class="fa fa-eye"></i> View File                          
                            </base-button>
                            <base-button 
                                type="danger" size="sm"
                                v-if="row.point == null"
                                @click="$emit('deleteCpdRecord', row.id)"
                            >
                                <i class="fa fa-trash"></i> Remove
                            </base-button>
                        </td>

                        </template>

                    </base-table>
                </div>

                <div 
                    class="card-footer d-flex justify-content-end bg-transparent"
                    v-if="hasPagination"
                     @click="paginate"
                >
                    <base-pagination 
                        :total="pagination.total" 
                        align="center"
                        v-model="pagination.number"
                        :perPage="pagination.perPage"
                       
                    >
                    </base-pagination>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import moment from 'moment';

    export default {
        name: 'CpdPointsData',
        props: ["cpdRecords", "showAddRecord", "pagination", "hasPagination", "paginate"],
        filters: {
            getDateFormat(value) {
                return moment(value).format('Do MMM, YYYY');
            }
        },
        computed: {
            ...mapGetters(['lock', 'cpdPointUrl'])
        },
        methods: {
            replaceString(value) {
                return value.replace("-", " ");    
            }
        },
    }
</script>