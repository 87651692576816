<template>
    <div>
        <div class="row">
            <div class="col-lg-12">
                <h3 class="mb-0 text-uppercase">Referees Information</h3>
                <hr class="my-4" />
            </div>

            <div class="table-responsive" v-if="records && records.length > 0">
                <table class="table table-bordered">
                    <thead class="text-uppercase">
                        <td>
                            <h4>S/N</h4>
                        </td>
                        <td>
                            <h4>Name</h4>
                        </td>
                        <td>
                            <h4>Occupation / Position</h4>
                        </td>
                        <td>
                            <h4>address / Business Address</h4>
                        </td>
                        <td>
                            <h4>Tel</h4>
                        </td>
                        <!-- <td>
                            <h4>Folio No</h4>
                        </td> -->
                    </thead>
                    <tbody
                        v-for="(record , index) in records"
                        :key="record.id"
                    >
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ index+1 }}
                            </h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ (record.name) }}
                            </h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ record.occupation }}<br>
                                <badge type="success">
                                    {{ record.position }}
                                </badge>
                            </h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ record.address }}<br>
                                <badge type="success">
                                    {{ record.business_address }}
                                </badge>
                            </h4>
                        </td>
                        <td>
                            <h4 class="font-weight-light">
                                {{ record.phone }}
                            </h4>
                        </td>
                        <!-- <td>
                            <h4 class="font-weight-light">
                                {{ record.folio_number ? record.folio_number : 'N/A' }}
                            </h4>
                        </td> -->
                    </tbody>
                </table>
            </div>

            <div class="col-lg-12 text-center" v-if="records && records.length == 0">
                <base-alert type="danger">
                    <!-- <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span> -->
                    <span class="alert-inner--text">
                        <h2 class="text-white">No Referee Records !!!</h2>
                    </span>
                </base-alert>
            </div>

            <div class="col-lg-12">
                <hr class="my-4" />
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'RefereeDetails',
        props: [ 'records' ]
    }
</script>

<style scoped>
    .no-padding-bottom {
        padding-bottom: 0rem !important
    }
    .right {
        float: right !important;
    }
    .photo-class {
        width: 200px;
        height: 200px;
    }
</style>

<style>
    .form-control-label {
        width: 100%;
    }
    .height-50 {
        height: 50px;
    }
    .borderLine {
        border: 1px solid #ededed;
    }
</style>