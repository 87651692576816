<template>
    <form @submit.prevent>
        <h6 class="heading-small text-muted mb-4">Bio information</h6>

        <div class="col-lg-12" v-if="isLoadingBlade">
            <div class="d-flex justify-content-center">
                <img :src="loadingBladeUrl" />
            </div>
        </div>

        <div class="pl-lg-4" v-if="! isLoadingBlade">
            <div class="row">
                <div class="col-lg-4">
                    <base-input alternative=""
                        label="First Name"
                        placeholder="First Name"
                        input-classes="form-control-alternative text-capitalize"
                        v-model="profile.first_name"
                        readonly
                    />
                </div>
                <div class="col-lg-4">
                    <base-input alternative=""
                        label="Last name"
                        placeholder="Last name"
                        input-classes="form-control-alternative text-capitalize"
                        v-model="profile.last_name"
                        readonly
                    />
                </div>
                <div class="col-lg-4">
                    <base-input alternative=""
                        label="Other Names"
                        placeholder="Other Names"
                        input-classes="form-control-alternative text-capitalize"
                        v-model="profile.other_name"
                        readonly
                    />
                </div>
                <div class="col-md-4"
                    v-if="checkForValue('gender')"
                >
                    <base-input alternative=""
                        label="Gender"
                        required
                        >
                        <select
                            class="form-control form-control-alternative"
                            v-model="profile.gender"
                        >
                            <option value="null" disabled>--- Select One ---</option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                        </select>
                    </base-input>
                </div>
                <div class="col-md-4"
                    v-if="checkForValue('date_of_birth')"
                >
                    <base-input
                        label="Date of Birth (Date you were born/given birth to)"
                        addon-left-icon="ni ni-calendar-grid-58"
                        required

                    >
                        <flat-picker
                            slot-scope="{focus, blur}"
                            @on-open="focus"
                            @on-close="blur"
                            :config="{allowInput: true}"
                            class="form-control datepicker"
                            v-model="profile.date_of_birth"
                        >
                        </flat-picker>
                    </base-input>
                </div>
                <div class="col-md-4"
                    v-if="checkForValue('previous_names')"
                >
                    <base-input alternative=""
                        label="Previous Names"
                        placeholder="Previous Names"
                        input-classes="form-control-alternative text-capitalize"
                        v-model="profile.previous_names"
                    />
                </div>
                <div class="col-md-4"
                    v-if="checkForValue('phone')"
                >
                    <base-input alternative=""
                        label="Phone Number"
                        placeholder="Phone Number"
                        input-classes="form-control-alternative"
                    >
                    <!-- v-model="profile.phone"  -->
                        <VuePhoneNumberInput
                            v-model="tel.phone"
                            @update="updatePhoneNumber"
                            :default-country-code="tel.code"
                            :no-example="false"
                            :clearable="false"
                        />
                    </base-input>
                </div>
                <div class="col-md-4"
                    v-if="checkForValue('place_of_birth')"
                >
                    <base-input alternative=""
                        label="Place of Birth"
                        placeholder="Place of Birth"
                        input-classes="form-control-alternative text-capitalize"
                        v-model="profile.place_of_birth"
                    />
                </div>
                <div class="col-md-4"
                    v-if="checkForValue('marital_status')"
                >
                    <base-input alternative=""
                        label="Marital Status"
                        required
                    >
                        <select
                            class="form-control form-control-alternative"
                            v-model="profile.marital_status"
                        >
                            <option value="null" disabled>--- Select One ---</option>
                            <option value="single">Single</option>
                            <option value="married">Married</option>
                            <option value="divorced">Divorced</option>
                            <option value="widow">Widow</option>
                        </select>
                    </base-input>
                </div>
                <div class="col-md-4"
                    v-if="checkForValue('nationality') || checkForValue('state_origin') 
                        || checkForValue('lga_origin')"
                > 
                    <base-input alternative=""
                        label="Nationality"
                        required
                    >
                    <!-- :initialDisplay="(hasCountry ? hasCountry.name : '')" -->
                        <autocomplete
                            v-if="locations && locations.countries && locations.countries.length > 0"
                            input-class="form-control form-control-alternative"
                            placeholder="Type to Search Country"
                            showNoResults
                            :source="locations.countries"
                            v-model="profile.nationality"
                            @selected="changeCountry"
                        >
                        </autocomplete>
                    </base-input>
                </div>
                <div class="col-md-4"
                    v-if="profile.nationality && (checkForValue('state_origin') 
                                || checkForValue('lga_origin'))"
                >
                    <base-input alternative=""
                        label="State of Origin"
                        required
                    >
                        <autocomplete
                            v-if="cities && cities.length > 0"
                            ref="autocomplete"
                            input-class="form-control form-control-alternative"
                            placeholder="Type to Search State"
                            showNoResults
                            :source="cities"
                            :initialDisplay="(hasCity ? hasCity.name : '')"
                            @selected="changeCity"
                        >
                        </autocomplete>
                    </base-input>
                </div>

                <div class="col-md-4"
                    v-if="showLgaOrigin && profile.state_origin && checkForValue('lga_origin')"
                >
                    <base-input alternative=""
                        label="LGA of Origin"
                        required
                    >
                        <autocomplete
                            v-if="lgas && lgas.length > 0"
                            input-class="form-control form-control-alternative"
                            placeholder="Type to Search for LGA"
                            showNoResults
                            :source="lgas"
                            v-model="profile.lga_origin"
                        >
                        </autocomplete>
                    </base-input>
                </div>


                <div class="col-md-4"
                    v-if="checkForValue('hometown')"
                >
                    <base-input alternative=""
                        label="HomeTown"
                        placeholder="HomeTown"
                        input-classes="form-control-alternative text-capitalize"
                        v-model="profile.hometown"
                    />
                </div>

                <div class="col-lg-4"
                    v-if="checkForValue('year_of_graduation')"
                >
                    <base-input alternative=""
                        label="Year of Graduation"
                        required
                    >
                        <select
                            class="form-control form-control-alternative"
                            v-model="profile.year_of_graduation"
                        >
                            <option value="null" disabled>--- Select One ---</option>
                            <option
                                v-for="year in years"
                                :value="year"
                                :key="year"
                            >
                                {{year}}
                            </option>
                        </select>
                    </base-input>
                </div>

                <div class="col-lg-4"
                    v-if="checkForValue('employ_status')"
                >
                    <base-input alternative=""
                        label="Employment Status"
                    >
                        <select
                            class="form-control form-control-alternative"
                            v-model="profile.employ_status"
                        >
                            <option :value="null" disabled>--- Select One ---</option>
                            <option value="employed">Employed</option>
                            <option value="unemployed">Unemployed</option>
                        </select>
                    </base-input>
                </div>

                <div class="col-lg-4"
                    v-if="showEmploymentSector && checkForValue('employ_sector')">
                    <base-input alternative=""
                        label="Employment Sector"
                    >
                        <select
                            class="form-control form-control-alternative"
                            v-model="profile.employ_sector"
                        >
                            <option :value="null" disabled>--- Select One ---</option>
                            <option value="private">Private</option>
                            <option value="government">Government</option>
                        </select>
                    </base-input>
                </div>

                <div class="col-lg-4"
                    v-if="showLevelofGovernment && checkForValue('level_of_govt')">
                    <base-input alternative=""
                        label="Level of Government (Employment)"
                    >
                        <select
                            class="form-control form-control-alternative"
                            v-model="profile.level_of_govt"
                        >
                            <option :value="null" disabled>--- Select One ---</option>
                            <option value="federal-government">Federal Government</option>
                            <option value="state-government">State Government</option>
                            <option value="local-government">Local Government</option>
                        </select>
                    </base-input>
                </div>

                <div class="col-lg-4"
                    v-if="showServiceStatus && checkForValue('service_status')">
                    <base-input alternative=""
                        label="Employment Service Status"
                    >
                        <select
                            class="form-control form-control-alternative"
                            v-model="profile.service_status"
                        >
                            <option :value="null" disabled>--- Select One ---</option>
                            <option value="retired">Retired</option>
                            <option value="in-service">In-service</option>
                        </select>
                    </base-input>
                </div>

                <div class="col-lg-4"
                    v-if="showOtherEmploymentDetails && checkForValue('level_of_care')">
                    <base-input alternative=""
                        label="Level Of Care (Employment)"
                    >
                        <select
                            class="form-control form-control-alternative"
                            v-model="profile.level_of_care"
                        >
                            <option :value="null" disabled>--- Select One ---</option>
                            <option value="tertiary">Tertiary</option>
                            <option value="secondary">Secondary</option>
                            <option value="primary">Primary</option>
                            <option value="not-applicable">Not Applicable</option>
                        </select>
                    </base-input>
                </div>

                <div class="col-lg-4"
                    v-if="showOtherEmploymentDetails && checkForValue('practice_geolocation')">
                    <base-input alternative=""
                        label="Practice Geo-Location (Employment)"
                    >
                        <select
                            class="form-control form-control-alternative"
                            v-model="profile.practice_geolocation"
                        >
                            <option :value="null" disabled>--- Select One ---</option>
                            <option value="urban">Urban</option>
                            <option value="rural">Rural</option>
                            <option value="sub-urban">Sub-Urban</option>
                        </select>
                    </base-input>
                </div>

                <div class="col-lg-4"
                    v-if="showOtherEmploymentDetails && checkForValue('category_of_practice')">
                    <base-input alternative=""
                        label="Category Of Practice (Employment)"
                    >
                        <select
                            class="form-control form-control-alternative"
                            v-model="profile.category_of_practice"
                        >
                            <option :value="null" disabled>--- Select One ---</option>
                            <option value="clinical">Clinical</option>
                            <option value="non-clinical">Non-Clinical</option>
                        </select>
                    </base-input>
                </div>

                <div class="col-lg-4"
                    v-if="showClinicalType && checkForValue('clinical_type')">
                    <base-input alternative=""
                        label="Clinical Type (Employment)"
                    >
                        <select
                            class="form-control form-control-alternative"
                            v-model="profile.clinical_type"
                        >
                            <option :value="null" disabled>--- Select One ---</option>
                            <option value="clinic">Clinic</option>
                            <option value="hospital">Hospital</option>
                        </select>
                    </base-input>
                </div>

                <div class="col-lg-4"
                    v-if="showNonClinicalType && checkForValue('non_clinical_type')">
                    <base-input alternative=""
                        label="Non-Clinical Type (Employment)"
                    >
                        <select
                            class="form-control form-control-alternative"
                            v-model="profile.non_clinical_type"
                        >
                            <option :value="null" disabled>--- Select One ---</option>
                            <option value="academia">Academia</option>
                            <option value="ngo">NGO</option>
                            <option value="others">Others</option>
                        </select>
                    </base-input>
                </div>
                
                <div class="col-lg-4"
                    v-if="checkForValue('country_of_practice') || checkForValue('state_of_practice') 
                            || checkForValue('lga_of_practice')"
                >
                    <base-input alternative=""
                        label="Country of Practice/Residence"
                        required
                    >
                    <!-- :initialDisplay="(hasCountryPractice ? hasCountryPractice.name : '')" -->
                        <autocomplete
                            v-if="locations && locations.countries && locations.countries.length > 0"
                            input-class="form-control form-control-alternative"
                            placeholder="Type to Search Country"
                            showNoResults
                            :source="locations.countries"
                            v-model="profile.country_of_practice"
                            @selected="changeCountryPractice"
                        >
                        </autocomplete>
                    </base-input>
                </div>
                <div class="col-lg-4"
                    v-if="profile.country_of_practice && (checkForValue('state_of_practice') 
                                || checkForValue('lga_of_practice'))"
                >
                    <base-input alternative=""
                        label="State/Province of Practice/Residence"
                    >
                        <autocomplete
                            v-if="cities && cities.length > 0"
                            ref="autocomplete"
                            input-class="form-control form-control-alternative"
                            placeholder="Type to Search State"
                            showNoResults
                            :source="cities"
                            :initialDisplay="(hasCityPractice ? hasCityPractice.name : '')"
                            @selected="changeCityPractice"
                        >
                        </autocomplete>
                    </base-input>
                </div>
                <div class="col-lg-4"
                    v-if="showPraticeLga && checkForValue('lga_of_practice')"
                >
                    <base-input alternative=""
                        label="LGA of Practice/Residence"
                    >
                        <autocomplete
                            v-if="lgas && lgas.length > 0"
                            input-class="form-control form-control-alternative"
                            placeholder="Type to Search for LGA"
                            showNoResults
                            :source="lgas"
                            v-model="profile.lga_of_practice"
                        >
                        </autocomplete>
                    </base-input>
                </div>

                <div class="col-lg-4"
                    v-if="checkForValue('teaching_hospital_id')"
                >
                    <base-input alternative=""
                        label="Hospital of Practice/Residence (Find your Hospital first, before choosing Others)" 
                    >
                        <autocomplete
                            v-if="hospitals && hospitals.length > 0"
                            input-class="form-control form-control-alternative"
                            placeholder="Type to Search for Hospital"
                            showNoResults
                            :source="hospitals"
                            v-model="profile.teaching_hospital_id"
                        >
                        </autocomplete>
                    </base-input>
                </div>

                <div class="col-md-4"
                    v-if="(profile.teaching_hospital_id == 0) && checkForValue('hospital_practice')"
                >
                    <base-input alternative=""
                        label="Other Hospital of Practice/Residence"
                        placeholder="Enter the Name of the Hospital "
                        input-classes="form-control-alternative text-capitalize"
                        v-model="profile.hospital_practice"
                    />
                </div>
                
                <div class="col-lg-4"
                    v-if="checkForValue('sub_registration_type_id')"
                >
                    <base-input alternative=""
                        label="Registration Type"
                    >
                        <select
                            class="form-control form-control-alternative"
                            @change="getSubRegistrationTypes()"
                            v-model="selectedRegType"
                        >
                            <option value="null" selected disabled>
                                --- Select One ---
                            </option>
                            <option
                                v-for="reg in registrationTypes"
                                @if="registrationTypes && registrationTypes.length > 0"
                                :key="reg.id"
                                :value="reg.id"
                            >
                                {{ reg.name }}
                            </option>
                        </select>
                    </base-input>
                </div>

                <div class="col-lg-4"
                    v-if="checkForValue('sub_registration_type_id') && subRegTypes
                        && subRegTypes.length > 0"
                >
                    <base-input alternative=""
                        label="Sub Registration Type"
                    >
                        <select
                            class="form-control form-control-alternative"
                            v-model="profile.sub_registration_type_id"
                        >
                            <option value="null" selected disabled>
                                --- Select One ---
                            </option>
                            <option
                                v-for="reg in subRegTypes"
                                @if="subRegTypes && subRegTypes.length > 0"
                                :key="reg.id"
                                :value="reg.id"
                            >
                                {{ reg.name }}
                            </option>
                        </select>
                    </base-input>
                </div>

                <div class="col-md-4" v-if="checkForValue('registration_no')">
                    <base-input alternative=""
                        label="Registration Number"
                        placeholder="Enter Registration Number"
                        input-classes="form-control-alternative text-capitalize"
                        v-model="profile.registration_no"
                        type="number"
                    />
                </div>

            </div>

            <div class="pl-lg-4">
                <div class="form-group"
                    v-if="profile.permanent_address"
                >
                    <base-input alternative=""
                        label="Permanent Address"
                        required
                    >
                        <textarea
                            rows="2"
                            class="form-control form-control-alternative"
                            placeholder="Enter Permanent Address"
                            v-model="profile.permanent_address"
                        ></textarea>
                    </base-input>
                </div>
                <div class="form-group"
                    v-if="profile.postal_address"
                >
                    <base-input alternative=""
                        label="Postal Address (If any)">
                        <textarea
                            rows="2"
                            class="form-control form-control-alternative"
                            placeholder="Enter Permanent Address"
                            v-model="profile.postal_address"
                        ></textarea>
                    </base-input>
                </div>
            </div>
        </div>
        <hr class="my-4" v-if="! isLoadingBlade"/>

        <base-alert :type="errorType" dismissible v-if='showErrorStatus'>
            <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
            <span class="alert-inner--text">
                {{errorMsg}}
            </span>
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </base-alert>

        <div class="pl-lg-4" v-if="! isLoadingBlade">
            <base-button type="success" @click="saveBioData()">
                <i class="fa fa-save" @if='! isloading'></i>
                {{isloading ? 'Please Wait' : 'Save & Continue'}}
            </base-button>
        </div>
    </form>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import moment from 'moment';
    import swal from 'sweetalert';// import sweet alert
    import flatPicker from "vue-flatpickr-component";
    import "flatpickr/dist/flatpickr.css";
    import VuePhoneNumberInput from 'vue-phone-number-input';
    import 'vue-phone-number-input/dist/vue-phone-number-input.css';
    import Autocomplete from 'vuejs-auto-complete';
    import states from '../../../../states.json';

    export default {
        components: {
            flatPicker,
            VuePhoneNumberInput,
            Autocomplete
        },
        name: 'UpdateBioData',
        props: ['bioData', 'hasEmptyData'],
        data() {
            return {
                profile: this.bioData,
                lgas: [],
                practiceLgas: [],
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                registrationTypes: [],
                selectedRegType: null,
                subRegTypes: [],
                isloading: false,
                isLoadingBlade: true,
                showLgaOrigin: false,
                showPraticeLga: false,
                tel: {
                    phone: null,
                    code: 'NG',
                },
                showForeignState : false,
                states: states,
                cities: []  
            }
        },
        computed: {
            ...mapGetters(['locations', 'hospitals', 'loadingBladeUrl']),
            // showForeignState() {
            //     return this.profile.nationality == 164 ? false : true;
            // },
            years() {
                let startYear = moment().format('YYYY') - 119;// start from 1900
                let currentYear = parseInt(moment().format('YYYY'));
                let years = [];
                for (let i = currentYear; i > startYear; i-- ) {
                    years.push(i);
                }
                return years;
            },
            showLocalStateOfPractice() {//check if country is Nigeria (id = 164)
                return this.profile.country_of_practice == 164 ? true : false;
            },
            showEmploymentSector() {// check if user is employed
                return this.profile.employ_status == "employed" ? true : false;
            },
            showLevelofGovernment() {// display the level of govt
                return this.showEmploymentSector && (this.profile.employ_sector == "government") ? true : false;
            },
            showServiceStatus() {// display employment service status
                return this.showLevelofGovernment && (this.profile.level_of_govt != null) ? true : false;
            },
            showOtherEmploymentDetails() {// display other employment details
                return this.showEmploymentSector && this.profile.employ_sector ? true : false;
            },
            showClinicalType() {// display clinical type
                return this.showEmploymentSector && (this.profile.category_of_practice == 'clinical') ? true : false;
            },
            showNonClinicalType() {// display non-clinical type
                return this.showEmploymentSector && (this.profile.category_of_practice == 'non-clinical') ? true : false;
            },
            hasCountry() {// get country 
                let country = this.locations && this.locations.countries ?
                            this.locations.countries.filter(item => item.id == this.profile.nationality) : null;
                return country && country.length > 0 ? country[0] : {};
            },
            hasCity() {// get state of origin
                let city = this.cities ? this.cities.filter(item => item.id == this.profile.state_origin) : null;
                return city && city.length > 0 ? city[0] : {};
            },
            hasCountryPractice() {// get country of practice
                let country = this.locations && this.locations.countries ?
                            this.locations.countries.filter(item => item.id == this.profile.country_of_practice) : null;
                return country && country.length > 0 ? country[0] : {};
            },
            hasCityPractice() {// get state of practice
                let city = this.cities ? this.cities.filter(item => item.id == this.profile.state_of_practice) : null;
                return city && city.length > 0 ? city[0] : {};
            }
        },
        methods: {
            ...mapActions(['getLocations', 'getRegistrationTypes', 'updateBioData']),
            updatePhoneNumber(event) {
                if(event && event.isValid && event.e164) {
                    this.profile.phone = event.formattedNumber;
                    this.profile.phone_code = event.countryCode+'-'+event.phoneNumber;
                }
            },
            loadPhoneNumber() {// update phone no
                let data = this.profile.phone_code;
                let exploded = data ? data.split('-') : false;
                this.tel.code = exploded && exploded[0] ? exploded[0] : 'NG';
                this.tel.phone = exploded && exploded[1] ? exploded[1] : null;
                setTimeout(function () { // load cites from country
                    this.changeCountryPractice(null, this.profile.country_of_practice)
                    this.changeCountry(null, this.profile.nationality)
                 }.bind(this), 2000);
            },
            changeCountry(e, code) {// change Country
                this.filterCities(e, code, this.hasCountry);// filter cities
                if(code || e) {
                    this.profile.state_origin = null;
                    this.profile.lga_origin = null;
                    this.profile.foreign_state = null;
                }
            },
            filterCities(e, code, defaultCountry) {// filter cities from country
                this.cities = [];
                let id = code ? code : (e && e.selectedObject ? e.selectedObject.id : 0);
                if(id == 164) {
                    this.cities = this.locations.states;
                } else {
                    let data = code ? defaultCountry :  (e && e.selectedObject ? e.selectedObject : {});
                    let other = { id : 0, name: 'Others (Specify)'};// get cities
                    this.cities = this.states.filter(item => item.country_code == data.code);
                    this.cities.push(other);
                }
            },
            changeCountryPractice(e, code) {// change Country Practice
                this.filterCities(e, code, this.hasCountryPractice);// filter cities
                if(code || e) {
                    this.profile.state_of_practice = null;
                    this.profile.lga_of_practice = null;
                    this.profile.foreign_state_practice = null;
                }
            },
            changeCity(e) {// change state of origin
                let data = e.selectedObject;
                this.profile.state_origin = null;
                this.showLgaOrigin = false;
                this.lgas = [];
                if(data.id == 0) {
                    this.profile.foreign_state = null;
                } else {
                    if(this.profile.nationality == 164) {
                        this.profile.foreign_state = null;
                        this.profile.state_origin = data.id;
                        this.lgas = this.locations.lgas.filter(item => item.state_id == this.profile.state_origin);
                        this.showLgaOrigin = true;
                    } else {
                        this.profile.foreign_state = data.name;
                    }
                }
            },
            changeCityPractice(e) {// change state of pratice
                let data = e.selectedObject;
                this.displayOtherState = false;
                this.profile.state_of_practice = null;
                this.showPraticeLga = false;
                this.lgas = [];
                if(data.id == 0) {
                    this.profile.foreign_state_practice = null;
                    this.displayOtherState = true;
                } else {
                    if(this.profile.country_of_practice == 164) {
                        this.profile.foreign_state_practice = null;
                        this.profile.state_of_practice = data.id;
                        this.lgas = this.locations.lgas.filter(item => item.state_id == this.profile.state_of_practice);
                        this.showPraticeLga = true;
                    } else {
                        this.profile.foreign_state_practice = data.name;
                    }
                }
            },
            checkForValue(value) {// check if empty data is valid
                return this.bioData.hasOwnProperty(value) ? true : false;
            },
            loadRegistrationTypes() {// load reg types
                this.getLocations();// load locations
                this.getRegistrationTypes().then((res) => {
                    this.isLoadingBlade = false;
                    let regTypes = [];
                    let doctorType = this.profile.doctor_type_id;
                    if(doctorType == 3) {
                        for(let reg of res) {// check if doctor is alternative
                            if(doctorType == 3 && reg.id == 2) {
                                regTypes.push(reg);
                            }
                        }
                        return this.registrationTypes = regTypes;
                    } else {
                        return this.registrationTypes = res;
                    }
                });
            },
            getSubRegistrationTypes() {// get reg sub types
                this.subRegTypes = [];
                this.profile.sub_registration_type_id = null;
                for(let reg of this.registrationTypes) {
                    if(reg.id == this.selectedRegType) {
                        let types = [];
                        let doctorType = this.profile.doctor_type_id;
                        if(doctorType == 3) {// check if doctor is alternative
                            for(let subType of reg.has_sub_registrations) {
                                if(doctorType == 3 && subType.id == 9) {
                                    types.push(subType);
                                }
                            }

                        } else {
                            for(let subType of reg.has_sub_registrations) {
                                if(subType.id != 9) {
                                    types.push(subType);
                                }
                            }
                        }
                        return this.subRegTypes = types;
                    }
                }
            },
            saveBioData() {
                this.isloading = true;
                let data = this.profile;
                let check = this.validatedData(data);// valid input data
                if(data && check) {// add user id
                    data.id = this.$store.state.user.userId;
                    this.updateBioData(data).then((res) => {
                        this.isloading = false;
                        if(res.status) {// load updated profile
                            swal("Record Updated!", "Bio Data was updated successfully", "success");
                            this.hasEmptyData();
                        } else {
                            this.showUpdateMsg('An error occurred while updating, try again', 'warning');
                        }
                    });
                } else if(! check) {
                    this.isloading = false;
                    this.showUpdateMsg('Invalid Hospital of Practice, select / enter your Hospital', 'warning');
                } else {
                    this.isloading = false;
                    this.showUpdateMsg('An error occurred while updating, try again', 'warning');
                }
            },
            validatedData(data) {// valid inputs
                return ((! data.hospital_practice) || ((data.teaching_hospital_id != null) && (data.teaching_hospital_id != 0)) || 
                    ((data.teaching_hospital_id == 0) && data.hospital_practice)) ? true : false;
            },
            isEmpty(obj) {// check for empty field
                return ! Object.keys(obj).some(k => !obj[formatDisk]);
            },
            showUpdateMsg(msg, type) {
                this.showErrorStatus = true;
                this.errorType = type;
                this.errorMsg = msg;
                var self = this;
                setTimeout(function() { self.showErrorStatus = false; }, 5000);
            }
        },
        created() {
            this.loadRegistrationTypes();// load reg types
            // this.displayState();
            this.loadPhoneNumber();// update phone no
        },
    }
</script>