<template>
    <div class="pl-lg-4">
        <div class="row">

            <div class="col-lg-12" v-if="isLoadingBlade">
                <div class="d-flex justify-content-center">
                    <img :src="loadingBladeUrl" />
                </div>
            </div>

            <div class="col-lg-12" v-if="! isLoadingBlade">
                <div class="card shadow">
                    <div class="table-responsive">
                        <base-table
                            class="table align-items-center table-flush"
                            thead-classes="thead-light"
                            tbody-classes="list"
                            :data="applications"
                            v-if="applications && applications.length > 0"
                        >
                            <template slot="columns">
                                <th width="2%">S/N</th>
                                <th width="10%">Type</th>
                                <th width="20%">Application<br> Date</th>
                                <th width="10%">Status</th>
                                <th width="10%">Payment<br> Status</th>
                                <th width="10%">Approve/<br>Reject Date</th>
                                <th width="5%">&nbsp;</th>
                            </template>

                            <template
                                slot-scope="{row, index}"
                            >
                            <td class="budget">
                                {{ pagination.from+index}}
                            </td>
                            <td class="text-capitalize">
                                <span v-html="breakword(row.application_type.name)"></span>
                                <br>
                                <badge
                                    type="dark" size="lg"
                                    v-if="row.license_year"
                                >
                                    License Year - {{ row.license_year }}
                                </badge>
                            </td>
                            <td>
                                {{ row.application_date | getDateFormat }}
                            </td>
                            <td>
                                <badge :type="getStatusColor(row.status)">
                                    {{ row.status }}
                                </badge>
                            </td>
                            <td>
                                <badge :type="getPaymentStatusColor(row.payment_status)">
                                    {{ row.payment_status }}
                                </badge>
                            </td>
                            <td>
                                {{ row.approved_date | getDateFormat }}
                            </td>
                            <td>
                                <router-link
                                    v-if="(row.transaction && (row.transaction.status == 'PAID')
                                        || (row.status == 'approved') || (row.status == 'paid'))"
                                    :to="'/application-details/'+row.id"
                                    class="btn btn-sm btn-success"
                                >
                                    <i class="fa fa-eye"></i> Details
                                </router-link>
                                <base-button
                                    type="info"
                                    v-if="row.transaction && (row.transaction.status != 'PAID')"
                                    class="btn btn-sm btn-info"
                                    @click="reQueryTransaction(row.transaction)"
                                >
                                    <i class="fa fa-history"></i> Re-Query
                                </base-button>
                                <router-link
                                    v-if="row.application_type && row.application_type.code && (row.payment_status == 'not-paid') 
                                            && ! isOtherPaymentType(row)"
                                    :to="'/new-application/'+ row.application_type.code"
                                    class="btn btn-sm btn-primary"
                                >
                                    <i class="fa fa-credit-card"></i> Make Payment 
                                </router-link>
                                <router-link
                                    v-if="! row.application_type && ! row.transaction && (row.status != 'approved') && (row.payment_status == 'not-paid') 
                                            && ! isOtherPaymentType(row)"
                                    :to="'/application-payment/'+row.id"
                                    class="btn btn-sm btn-primary"
                                >
                                    <i class="fa fa-credit-card"></i> Make Payment
                                </router-link>

                                <router-link
                                    v-if="row.application_type && row.application_type.code && (row.payment_status == 'not-paid') 
                                            && isOtherPaymentType(row)"
                                    :to="'/application-payment/'+row.id"
                                    class="btn btn-sm btn-primary"
                                >
                                    <i class="fa fa-credit-card"></i> Make Payment
                                </router-link>
                            </td>

                            </template>

                        </base-table>
                    </div>

                    <div
                        class="card-footer d-flex justify-content-end bg-transparent"
                        v-if="hasPagination"
                        @click="paginate"
                    >
                        <base-pagination
                            :total="pagination.total"
                            align="center"
                            v-model="pagination.number"
                            :perPage="pagination.perPage"

                        >
                        </base-pagination>
                    </div>

                </div>
            </div>

            <!-- Other Notice template here -->
            <div class="col-md-4" :show="procedureModal">
                <modal :show="procedureModal"
                        gradient="info"
                        modal-classes="modal-info modal-dialog-centered">
                    <h4
                        slot="header" class="modal-title"
                        id="modal-title-notification"
                    >
                        Importance Notice
                    </h4>

                    <div class="py-3 text-center">
                        <!-- <i class="ni ni-bell-55 ni-3x"></i> -->
                        <h4 class="heading mt-4">Procedure for Rejected Application!</h4>
                        <p>
                            1. Check for Application with status <em>"Rejected"</em><br>
                            2. Click on the <em>Details</em> button on the exact Rejected Application<br>
                            3. At the bottom on the page, You can now Click on the <em>Edit Application</em> button<br>
                            4. After editing Application, You can now Click on <em>Re-send Application</em><br>
                            5. The Application will be submitted for review and approval by MDCN.
                        </p>
                    </div>

                    <template slot="footer">
                        <base-button type="link"
                                    text-color="white"
                                    class="ml-auto"
                                    @click="procedureModal = false">
                            Close
                        </base-button>
                    </template>
                </modal>
            </div>

            <div class="col-sm-12 text-center">
                <base-alert :type="errorType" v-if='showErrorStatus'>
                    <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                    <span class="alert-inner--text">
                        {{errorMsg}}
                    </span>
                </base-alert>
            </div>

        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import moment from 'moment';
    import swal from 'sweetalert2';// import sweet alert

    export default {
        name: 'PreviousApplications',
        data() {
            return {
                applications: [],
                paginatedItems: [],
                pagination: {
                    total: null,
                    prev_page_url: null,
                    next_page_url: null,
                    current_page: null,
                    last_page: null,
                    number: 1,
                    from: 0,
                    to: null,
                    perPage: this.$store.state.user.perPage // get per page count
                },
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isloading: false,
                isLoadingBlade: true,
                procedureModal: true
            }
        },
        filters: {
            getDateFormat(value) {
                if (value) {
                    return moment(value).format('Do MMM, YYYY');
                } else {
                    return 'N/A';
                }
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl']),
            hasPagination() {
                return this.pagination.next_page_url || this.pagination.prev_page_url;
            },
        },
        methods: {
            ...mapActions(['getUserApplications', 'getPaginatedApplicationItems', 'getRemitaPaymentStatus',
                    'updateUserTransaction']),
            isOtherPaymentType(record) {// check if the payment is not in the array of other payment
                let otherPayments = ['inter-verification', 'late-reg-payment', 'annual-license-old', 'annual-license'];
                let code = record && record.application_type ? record.application_type.code : false;
                return (otherPayments.indexOf(code) > -1);
            },
            breakword(text, length = 4) {
                if(text) {
                    let splitted = text.split(' ');
                    let halfPoint = Math.ceil(splitted.length / 2);
                    let word = '';
                    for (let index = 0; index < splitted.length; index++) {
                        if((index == halfPoint) && (index >= length)) {
                            word += '<br> ' + splitted[index];
                        } else {
                            word += ' ' + splitted[index];
                        }
                    }
                    return word;
                } else {
                    return 'N/A';
                }
            },
            truncate: function (text, length, suffix) {
                if (text.length > length) {
                    return text.substring(0, length) + suffix;
                } else {
                    return text;
                }
            },
            loadApplications() {
                const userId = this.$store.state.user.userId;
                let data = {'id' : userId, 'limit' : this.pagination.perPage};
                this.getUserApplications(data).then((res) => {// get edu records
                // console.log(res);
                    this.setPages(res);// set pagination
                    this.isLoadingBlade = false;
                    this.emptyRecordsMsg();//show empty msg
                }).
                catch(() => {
                    this.showErrorStatus = true;
                    this.errorMsg = "Network Failure!", "Kindly reload the page...";
                    this.errorType = 'danger';
                });
            },
            setPages(res) {// set paginated function
                this.applications = [];
                this.paginatedItems = res.data;
                this.applications = this.paginatedItems;
                this.pagination.from = res.from;
                this.pagination.to = res.to;
                this.pagination.total = res.total;
                this.pagination.current_page = res.current_page;
                this.pagination.last_page = res.last_page;
                this.pagination.prev_page_url = res.prev_page_url;
                this.pagination.next_page_url = res.next_page_url;
                this.isLoadingBlade = false;
                this.emptyRecordsMsg();//show empty msg
            },
            paginate() {// paginate function
                this.showErrorStatus = false;
                let url = (this.pagination.number > this.pagination.current_page) ?
                    this.pagination.next_page_url : this.pagination.prev_page_url;
                if(url) {// make api call
                    this.isLoadingBlade = true
                    this.getPaginatedApplicationItems(url).then((res) => {// get users
                        this.pagination.number = res.current_page;
                        this.setPages(res);
                    }).
                    catch(() => {
                        this.users = [];
                        this.showErrorStatus = true;
                        this.isLoadingBlade = false;
                        this.errorMsg = "Network Failure!, Kindly reload the page...";
                        this.errorType = 'danger';
                    });
                } else {
                    this.applications = this.paginatedItems;
                }
            },
            emptyRecordsMsg() {
                return this.applications && this.applications.length == 0
                    ? this.showEmptyMsg() : false;
            },
            showEmptyMsg() {// show empty msg
                this.showErrorStatus = true;
                this.errorType = 'warning';
                this.errorMsg = 'No Applications Yet!, kindly apply for one';
            },
            getStatusColor(status) {
                if(status == 'approved') {
                    return 'success';
                } else if(status == 'pending') {
                    return 'warning';
                } else {
                    return 'danger';
                }
            },
            getPaymentStatusColor(status) {
                if(status == 'paid') {
                    return 'success';
                } else {
                    return 'danger';
                }
            },
            showRouteMessage() {// displaying session message
                let message = this.$route.params;
                if(message && message.msg) {
                    swal.fire(message.msgTitle, message.msg, message.msgType);
                }
            },
            reQueryTransaction(transaction) {// re-query function
                this.preloader();
                let rrr = transaction.payment_ref;
                if(rrr) {// get rrr status
                    this.preloader();// show loading
                    this.getRemitaPaymentStatus({'rrr' : rrr}).then((res) => {
                        if(res.status) {// update transaction
                            this.updateTransaction(transaction, res.details);// update transaction
                            swal.fire("Payment Successfully!",res.msg , "success");
                            this.loadApplications();// load applications
                        } else {
                            swal.fire("Payment Failed!",res.msg , "warning");
                        }
                    }).
                    catch(() => {
                        swal.fire("Network Failure!", "Kindly reload the page...", "warning");
                    });
                } else {
                    swal.fire("Payment Failed!", "Payment was not successful, no valid RRR number", "warning");
                }
            },
            updateTransaction(transaction, res) {// update transaction
                let data = {};
                data.id = transaction.id;
                data.status = 'PAID';
                data.amount = res.amount;
                data.date_generated = this.convertTimeTo24Hours(res.transactiontime);
                data.date_paid = this.convertTimeTo24Hours(res.paymentDate);
                data.response_code = res.status;
                data.response_message = res.message;
                this.updateUserTransaction(data).then((res) => {
                    return res.status;
                });
            },
            convertTimeTo24Hours(date) {// comvert local time to 24 Hours
                let time = date.split(" ");
                let formattedTime = time[1]+' '+time[2];
                let convertedTime = moment(formattedTime, ["h:mm:ss A"]).format("HH:mm:ss");
                return time[0]+' '+convertedTime;
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait!',
                    html: 'Trying to fetch data from <b>server</b> ...',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        created() {
            this.loadApplications();// load applications
            this.showRouteMessage(); // display message
        },
    }
</script>