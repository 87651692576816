<template>
    <div>
        <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center">
            <span class="mask bg-gradient-danger opacity-8"></span>
        </base-header>


        <div class="container-fluid mt--7">
            <div class="row" v-if="! isLoadingBlade">

                <div class="col-xl-12 order-xl-2 mb-5 mb-xl-0">
                    <div class="card card-profile shadow">
                        <div class="row justify-content-center">
                            <div class="col-lg-3 order-lg-2">
                                <div class="card-profile-image">
                                    <a href="javascript:void(0)">
                                        <img
                                            :alt="fullname"
                                            :src="userPhotoUrl && basicProfile.photo ? userPhotoUrl+basicProfile.photo : ''"
                                            class="rounded-circle image-size"
                                            @error="replaceByDefault"
                                        >
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="card-header text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                            <div class="d-flex justify-content-between">
                                <!-- <base-button size="sm" type="info" class="mr-4">Connect</base-button>
                                <base-button size="sm" type="default" class="float-right">Message</base-button> -->
                            </div>
                        </div>
                        <div class="card-body pt-0 pt-md-4">
                            <div class="row">
                                <div class="col">
                                    <div class="card-profile-stats d-flex justify-content-center mt-md-5">

                                    </div>
                                </div>
                            </div>
                            <div class="text-center">
                                <input
                                    type="file"
                                    ref="photo"
                                    style="display: none"
                                    @change="onFileSelected"
                                >
                                <h3>
                                    <base-progress
                                        v-if="loadPercent.show"
                                        :type="loadPercent.type"
                                        :height="10"
                                        :value="loadPercent.value"
                                        :striped="true"
                                        :animated="loadPercent.animated"
                                    >
                                    </base-progress>
                                    <h4 class="text-danger">
                                        Note that you are to upload passport photograph
                                        esle your application will be rejected (Your face
                                        and jaws must be displayed)
                                    </h4>
                                    <base-button
                                        size="sm" type="info"
                                        class="mr-4 marginLeft23"
                                        v-if="! loadPercent.show"
                                        @click="$refs.photo.click()"
                                        :disabled="lock"
                                    >
                                        <i class="fas fa-pen"></i>
                                        Click to change Photo
                                    </base-button>
                                </h3>
                                <h3 class="text-capitalize">
                                    {{fullname}}
                                    <span class="font-weight-light">,
                                        {{getAge(basicProfile.date_of_birth)}}
                                    </span>
                                </h3>
                                <div class="h5 font-weight-300"
                                    v-show="practiceLocally"
                                >
                                    <i class="ni location_pin mr-2"></i>
                                    {{ basicProfile.has_practice_lga && basicProfile.has_practice_lga.name ?
                                        basicProfile.has_practice_lga.name : ''}}
                                    {{ basicProfile.has_practice_state && basicProfile.has_practice_state.name ?
                                        ', '+basicProfile.has_practice_state.name : ''}}
                                </div>
                                <div class="h5 font-weight-300"
                                    v-show="! practiceLocally"
                                >
                                    <i class="ni location_pin mr-2"></i>
                                    {{ basicProfile.foreign_state_practice ? basicProfile.foreign_state_practice : ''}}
                                    {{ basicProfile.has_practice_country && basicProfile.has_practice_country.name ?
                                        ', '+basicProfile.has_practice_country.name : ''}}
                                </div>
                                <div class="h5 mt-4">
                                    <h3>
                                        {{ basicProfile.has_sub_registration_type && basicProfile.has_sub_registration_type.registration_type ?
                                        basicProfile.has_sub_registration_type.registration_type.name : ''}}
                                    </h3>
                                </div>
                                <div class="h5 mt-4">
                                    <h5>
                                        {{ basicProfile.has_sub_registration_type ?
                                        basicProfile.has_sub_registration_type.name : ''}}
                                    </h5>
                                </div>
                                <div class="h5 mt-4">
                                    <badge size="sm"
                                      :type="basicProfile.verify_status &&
                                        basicProfile.verify_status == 'approved' ? 'success' : 'warning'"
                                    >
                                      {{ basicProfile.verify_status }}
                                    </badge>
                                </div>

                                <!-- <hr class="my-4" />
                                <p>Ryan — the name taken by Melbourne-raised, Brooklyn-based Nick Murphy — writes, performs and records all of his own music.</p>
                                <a href="#">Show more</a> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <br><br><br><br><br><br>

        <div class="container-fluid mt--7">
            <div class="row">

                <div class="col-xl-12 order-xl-1">

                    <card shadow type="secondary">
                        <div class="col-lg-12" v-if="isLoadingBlade">
                            <div class="d-flex justify-content-center">
                                <img :src="loadingBladeUrl" />
                            </div>
                        </div>
                    </card>

                    <card shadow type="secondary" v-if="! isLoadingBlade">
                        <div slot="header" class="bg-white border-0">
                            <div class="row align-items-center">
                                <div class="col-8">
                                    <h3 class="mb-0">Update Account Information</h3>
                                </div>
                                <div class="col-4 text-right">
                                    <!-- <a href="#!" class="btn btn-sm btn-primary">Settings</a> -->
                                </div>
                            </div>
                        </div>

                        <template>
                            <tabs fill class="flex-column flex-md-row">
                                <card shadow>
                                    <tab-pane title="basicProfile">
                                        <span slot="title">
                                            <i class="ni ni-circle-08"></i>
                                            Basic Profile
                                        </span>
                                        <!-- import User Basic Profile template here -->
                                        <BasicProfile />
                                    </tab-pane>

                                    <tab-pane title="work">
                                        <span slot="title">
                                            <i class="ni ni-bell-55 mr-2"></i>
                                            Work/Specialization
                                        </span>
                                        <!-- import User work/Specialization Profile template here -->
                                        <WorkProfile
                                            :loadUserProfile="loadUserProfile"
                                        />
                                    </tab-pane>

                                    <tab-pane
                                        title="Additional Records"
                                        v-if="isTemporaryRegistration"
                                    >
                                        <span slot="title">
                                            <i class="ni ni-fat-add"></i>
                                            Additional Records
                                        </span>
                                        <!-- import User Documents Profile template here -->
                                        <AdditionalRecords />
                                    </tab-pane>

                                    <tab-pane title="Documents">
                                        <span slot="title">
                                            <i class="ni ni-archive-2"></i>
                                            Documents
                                        </span>
                                        <!-- import User Documents Profile template here -->
                                        <Document />
                                    </tab-pane>
                                </card>
                            </tabs>

                        </template>
                    </card>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    // get user's details from store
    import { mapGetters, mapActions } from 'vuex';
    import moment from 'moment';
    import swal from 'sweetalert';// import sweet alert
    // get components
    import BasicProfile from './UserProfile/BasicProfile.vue';
    import WorkProfile from './UserProfile/WorkProfile.vue';
    import Document from './UserProfile/Document.vue';
    import AdditionalRecords from './UserProfile/AdditionalRecords/AdditionalRecords.vue';

    export default {
        components: {
            BasicProfile,
            WorkProfile,
            Document,
            AdditionalRecords
        },
        name: 'user-profile',
        data() {
            return {
                basicProfile: {},
                savedEvent: null,
                showUploadButton: false,
                photo: {
                    id: null,
                    ext: null,
                    file: null
                },
                loadPercent: {
                    type: 'warning',
                    value: 10,
                    animated: true,
                    show: false
                },
                isLoadingBlade: true
            }
        },
        computed: {
            ...mapGetters(['userPhotoUrl', 'loadingBladeUrl', 'lock', 'blankImageUrl']),
            isTemporaryRegistration() {
                const profile = this.$store.state.user.basicProfile;// get user basic profile
                const subregTypeCode = this.$store.state.user.basicProfile.
                        has_sub_registration_type;// get user reg type
                return profile && subregTypeCode &&
                        subregTypeCode.registration_type.code == 'temporary-reg' ? true : false;
            },
            practiceLocally() {
                return this.basicProfile && this.basicProfile.country_of_practice == 164;
            },
            fullname() {
                let profile = this.basicProfile;
                let name = profile.first_name + ' ';
                name += profile.last_name;
                return name.toLowerCase();
            }
        },
        methods: {
            ...mapActions(['uploadUserPhoto', 'getBasicProfile', 'updatePhotoName', 'getHospitals']),
            loadUserProfile() {// load profile
                this.getBasicProfile().then((res) => {
                    this.isLoadingBlade = false;
                    this.basicProfile = res;
                });
            },
            getAge(date) {
                var age = date ? date.slice(0,4) : '';
                return moment().diff(age, 'years');
            },
            replaceByDefault(e) {
                return e.target.src = this.blankImageUrl;
            },
            makeUploadFieldsEmpty() {
                this.photo.id = null;
                this.photo.ext = null;
                this.photo.file = null;
            },
            onFileSelected(event) {
                this.savedEvent = null;
                this.makeUploadFieldsEmpty();
                this.savedEvent = event;
                let status =  this.validateFile(event.target.files[0]);
                return status ? this.uploadPhoto() : false;
            },
            uploadPhoto() {
                let event = this.savedEvent;// get store file event
                var fileReader = new FileReader();
                fileReader.readAsDataURL(event.target.files[0]);
                fileReader.onload = (event) => {// convert file to binary
                    this.photo.file = event.target.result;
                    let img = new Image();
                    img.src = window.URL.createObjectURL(this.savedEvent.target.files[0]);
                    img.onload = () => {
                        var height = img.height;
                        var width = img.width;
                        if ((width <= 500) && (height <= 500)) {
                            this.makeUploadApiCall();// upload photo
                        } else {
                            let msg = "Width and Height must not exceed 500 * 500 pixels";
                            swal("Invalid Width/Height!", msg, "warning");
                            return false;
                        }
                    }
                }
            },
            makeUploadApiCall() {
                this.photo.id = this.$store.state.user.userId;// get user id
                this.loadPercent.value = 10;
                this.loadPercent.show = true;
                this.uploadUserPhoto(this.photo).then((res) => {
                    this.uploadPercent(this.$store.state.user.uploadProgress);// show load progress
                    var self = this;
                    self.makeUploadFieldsEmpty();
                    self.savedEvent = null;
                    if(res.status) {
                        self.updatePhotoName(res.file_name);// update file name
                        setTimeout(function() {
                            self.loadPercent.show = false;
                        }, 3000);
                    } else {
                        self.loadPercent.show = false;
                        let msg = res && res.msg ? res.msg : "An error occurred while uploading, try again";
                        swal("Error Occurred!", msg, "warning");
                    }
                }).
                catch(err => {
                    var self = this;
                    self.makeUploadFieldsEmpty();
                    self.savedEvent = null;
                    swal(err.message+"!", "Reduce file size and try again...", "warning");
                    self.loadPercent.show = false;
                });
                this.loadUserProfile();// refresh page
            },
            uploadPercent(percent) {// update upload progress percent
                this.loadPercent.value = percent;
                this.loadPercent.type = percent > 60 ? 'success' : 'warning';
            },
            validateFile(file) {
                if(!file) { this.savedEvent = null; }// update seleted file
                const validFileTypes = ['jpg', 'jpeg', 'JPEG', 'JPG', 'png', 'PNG'];// valid image format array
                var fileExt = file.type.split('/').pop();// get selected file ext
                this.photo.ext = fileExt;
                var validExt = validFileTypes.includes(fileExt);
                let fileSize = file.size;// get selected file size
                if(validExt && fileSize <= 1000000) {// for valid file and size
                    return true;
                } else if(! validExt) {// invalid file
                    this.savedEvent = null;
                    swal("Invalid File!", "Only JPG/PNG files are accepted", "warning");
                    return false;
                } else if(fileSize > 1000000) {// big file
                    this.savedEvent = null;
                    swal("File is too large!", "File should be less than 1MB", "warning");
                    return false;
                }
            }
        },
        created() {
            this.loadUserProfile();
            this.getHospitals();// get hospitals
        }
    };
</script>
<style scoped>
    .width600 {
        width: 600px !important;
    }
    .marginLeft23 {
        margin-left: 23px;
    }
    .image-size {
        height: 180px;
        width: 180px;
    }
</style>
