<template>
    <div class="row" v-if="! showAddRecord && records && records.length > 0">
        <div class="col-lg-12">
            <div class="card shadow">
                <div class="table-responsive">
                    <base-table
                        class="table align-items-center table-flush"
                        thead-classes="thead-light"
                        tbody-classes="list"
                        :data="records"
                    >
                        <template slot="columns">
                            <th width="2%">S/N</th>
                            <th width="30%">Description</th>
                            <th width="10%">Degree</th>
                            <th width="10%">Qualify Date</th>
                            <th width="10%">Licensing <br>Body</th>
                            <th width="5%">&nbsp;</th>
                        </template>

                        <template
                            slot-scope="{row, index}"
                        >
                        <td class="budget">
                            {{ pagination.from+index+1}}
                        </td>
                        <td>
                            {{ row.qualification.course_title }}<br>
                            <b>from</b> {{ row.school_name }}<br>
                            <badge size="sm" type="primary" v-if="row.category">
                                {{ row.category.name }}
                            </badge>
                            <badge size="sm" :type="getColor(row.status)">
                                {{ row.status }}
                            </badge>
                            <badge size="sm" type="primary">
                                {{ row.type }}
                            </badge>
                        </td>
                        <td>
                            {{ row.degree ? row.degree : 'N/A' }}
                        </td>
                        <td>
                            {{ row.qualify_date | getDateFormat }}
                        </td>
                        <td>
                            {{row.licencing_body}}
                        </td>
                        <td>
                            <base-button
                                type="primary" size="sm"
                                v-if="((row.status == 'rejected') || ((row.type == 'old') && (row.status != 'approved')))"
                                @click="$emit('editAqRecord', row)"
                            >
                                <i class="fa fa-pen"></i> Edit
                            </base-button>
                            <base-button
                                type="danger" size="sm"
                                v-if="(((row.status != 'approved') && (row.status != 'submitted')) ||
                                        (row.type == 'old') && (row.status != 'approved'))"
                                @click="$emit('deleteAqRecord', row.id)"
                            >
                                <i class="fa fa-trash"></i> Remove
                            </base-button>
                        </td>

                        </template>

                    </base-table>
                </div>

                <div
                    class="card-footer d-flex justify-content-end bg-transparent"
                    v-if="hasPagination"
                     @click="paginate"
                >
                    <base-pagination
                        :total="pagination.total"
                        align="center"
                        v-model="pagination.number"
                        :perPage="pagination.perPage"

                    >
                    </base-pagination>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import moment from 'moment';

    export default {
        name: 'AddQualificationData',
        props: ["records", "showAddRecord", "pagination", "hasPagination", "paginate"],
        filters: {
            getDateFormat(value) {
                return moment(value).format('MMM, YYYY');
            }
        },
        computed: {
            ...mapGetters(['lock'])
        },
        methods: {
            getStatus(value) {
                return value == 'approved' ? 'approved' : 'pending';
            },
            getColor(value) {
                if(value == 'approved') {
                    return 'success';
                } else if(value == 'submitted') {
                    return 'info';
                } else {
                    return 'danger';
                }
            }
        },
    }
</script>