<template>
    <div class="pl-lg-4">
        <h6 class="heading-small text-muted mb-4">Update Educational Records</h6>

        <em class="mb-4 text-danger">
            Kindly fill only your medical school details below, you can edit/ add more later
        </em>

        <div class="col-lg-12" v-if="isLoadingBlade">
            <div class="d-flex justify-content-center">
                <img :src="loadingBladeUrl" />
            </div>
        </div>

        <hr class="my-4" v-if="! isLoadingBlade"/>

        <div class="row align-items-center" v-if="! isLoadingBlade">

            <div class="col-lg-4">
                <base-input alternative=""
                    label="School Type"
                    required
                >
                    <select
                        class="form-control form-control-alternative"
                        v-model="records.school_type"
                        disabled
                    >
                        <option value="medical-school">Medical School</option>
                    </select>
                </base-input>
            </div>
            <div class="col-lg-4">
                <base-input alternative=""
                    label="Country"
                    required
                >
                    <autocomplete
                        v-if="locations && locations.countries && locations.countries.length > 0"
                        input-class="form-control form-control-alternative"
                        placeholder="Type Country"
                        showNoResults
                        :source="locations.countries"
                        v-model="records.country"
                        @selected="changeCountry"
                    >
                    </autocomplete>
                </base-input>
            </div>

            <div class="col-lg-4" v-if="! isOtherSchool">
                <base-input alternative=""
                    label="Name of Institution"
                    required
                >
                    <autocomplete
                        input-class="form-control form-control-alternative"
                        placeholder="Type to Search Institution"
                        showNoResults
                        :disableInput="institutions && institutions.length == 0"
                        :source="institutions"
                        resultsValue="name"
                        @selected="changeInstitution"
                    >
                    </autocomplete>
                </base-input>
            </div>

            <div class="col-lg-4" v-if="showOtherInstitution">
                <base-input alternative=""
                    label="Other Institution"
                    placeholder="Enter Other Institution Name"
                    input-classes="form-control-alternative"
                    v-model="records.other_school_name"
                    required
                />
            </div>
            <div class="col-lg-4">
                <base-input alternative=""
                    label="Qualifications Obtained"
                    required
                >
                    <select
                        class="form-control form-control-alternative"
                        v-model="records.qualifications_obtained"
                        @change="changeOtherQualifications()"
                    >
                        <option
                            :value="null"
                            disabled
                        >
                            --- Select One ---
                        </option>
                        <option value="mbbs">MBBS</option>
                        <option value="bds">BDS</option>
                        <option value="others">Others (Please, specify)</option>
                    </select>
                </base-input>
            </div>
            <div class="col-lg-4" v-if="showOtherQualifications">
                <base-input alternative=""
                    label="Other Qualifications"
                    placeholder="Enter Other Qualification Name"
                    input-classes="form-control-alternative"
                    v-model="records.other_qualifications_obtained"
                    required
                />
            </div>
            <div class="col-lg-4">
                <base-input
                    label="Start Date"
                    addon-left-icon="ni ni-calendar-grid-58"
                    required

                >
                    <flat-picker
                        slot-scope="{focus, blur}"
                        @on-open="focus"
                        @on-close="blur"
                        :config="config"
                        class="form-control datepicker"
                        v-model="records.start_date"
                    >
                    </flat-picker>
                </base-input>
            </div>
            <div class="col-lg-4">
                <base-input
                    label="End Date"
                    addon-left-icon="ni ni-calendar-grid-58"
                    required

                >
                    <flat-picker
                        slot-scope="{focus, blur}"
                        @on-open="focus"
                        @on-close="blur"
                        :config="config"
                        class="form-control datepicker"
                        v-model="records.end_date"
                    >
                    </flat-picker>
                </base-input>
            </div>
            <div class="col-lg-12">
                <base-alert :type="errorType" dismissible v-if='showInputErrorStatus'>
                    <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                    <span class="alert-inner--text">
                        {{errorMsg}}
                    </span>
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </base-alert>
            </div>
            <div class="col-lg-12"></div>
            <div class="pl-lg-4">
                <base-button
                    type="success"
                    @click="saveEduRecords()"
                >
                    <i class="fa fa-save"></i>
                    {{isloading ? 'Please Wait...' : 'Add Record'}}
                </base-button>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import flatPicker from "vue-flatpickr-component";
    import "flatpickr/dist/flatpickr.css";
    import swal from 'sweetalert2';// import sweet alert
    import Autocomplete from 'vuejs-auto-complete';

    export default {
        name: 'UpdateEduRecords',
        props: ['hasEmptyData'],
        components: {
            Autocomplete,
            flatPicker
        },
        data() {
            return {
                records: {
                    school_type: 'medical-school',
                    school_name: null,
                    school_id: null,
                    other_school_name: null,
                    qualifications_obtained: null,
                    country: null,
                    start_date: null,
                    end_date: null,
                    other_qualifications_obtained: null
                },
                institutions: [],
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isloading: false,
                showOtherQualifications: false,
                showInputErrorStatus: false,
                showOtherInstitution: false,
                isLoadingBlade: false,
                config: {
                    maxDate: new Date().fp_incr(1),
                    altInput: true,// eslint-disable-next-line
                    plugins: [new monthSelectPlugin({shorthand: true, dateFormat: "Y-m-d", altFormat: "M Y"})]
                }
            };
        },
        computed: {
            ...mapGetters(['locations', 'loadingBladeUrl']),
            showOtherSchoolName() {
                return this.records.country && (this.records.country == 164) && this.records.school_type
                        && (this.records.school_type == 'medical-school') ? false : true;
            },
            isOtherSchool() {
                let type = this.records.school_type;
                return type && (type != 'medical-school') && (type != 'pre-clinical-period') &&
                            (type != 'clinical-period') && (type != 'tertiary');
            }
        },
        methods: {
            ...mapActions(['getLocations', 'getInstitutions', 'saveUserEduRecords', 'getAssessmentInstitutions']),
            changeCountry(e) {// get exact institutions
                this.records.school_name = null,
                this.records.other_school_name = null,
                this.records.school_id = null;
                this.institutions = [];
                this.preloader();
                this.getAssessmentInstitutions({ id : e.value }).then((res) => {
                    swal.close();
                    this.institutions = res.schools
                    let other = { id : 0, name: 'Others (Specify)'};
                    this.institutions.push(other);
                });
            },
            changeInstitution(e) {
                let data = e.selectedObject;
                this.records.other_school_name = null;
                this.showOtherInstitution = false;
                this.records.school_id = data.id;
                if(data.id == 0) {
                    this.showOtherInstitution = true;
                } else {
                    this.records.school_name = data.name;
                }
            },
            changeOtherQualifications() {// change other qualifications
                let value = this.records.qualifications_obtained;
                return this.showOtherQualifications = (value == 'others') ? true : false;
            },
            saveEduRecords() {// save new edu records
                this.isloading = true;
                let data = this.validatedInputs();// validate inputs
                if(data) {// save edu records
                    data.user_data_id = this.$store.state.user.basicProfile.id;// get user id
                    data.school_id = this.records.school_id;
                    this.saveUserEduRecords(data).then((res) => {
                        this.isloading = false;
                        if(res.status) {
                            this.showErrorStatus = false;
                            swal.fire("Record Updated!", "Medical School record was added successfully", "success");
                            this.hasEmptyData();// reload page
                        } else {
                            this.showErrorMsg();
                            this.emptyEduRecords();
                        }
                    }).
                    catch(() => {
                        this.isloading = false;
                        this.emptyEduRecords();
                        this.showErrorMsg();
                    });
                } else {
                    this.isloading = false;
                }
            },
            showErrorMsg() {
                this.showInputErrorStatus = true
                this.errorType = 'warning'
                this.errorMsg = 'An error occurred, kindly reload page and try again';
                var self = this;
                setTimeout(function() { self.showInputErrorStatus = false; }, 5000);
            },
            validatedInputs() {
                let data = this.records;
                let eduRecord = { school_type: null, start_date: null, country_id: null,
                    end_date: null, school_name: null, qualifications_obtained: null };
                eduRecord.end_date  = data.end_date == null ? this.showInuptError('School\'s End Date') : data.end_date;
                eduRecord.start_date  = data.start_date == null ? this.showInuptError('School\'s Start Date') : data.start_date;
                // validate qualification obtained
                if((data.qualifications_obtained == null || data.qualifications_obtained == 'others')
                    && data.other_qualifications_obtained != null) {
                    eduRecord.qualifications_obtained = data.other_qualifications_obtained;
                } else if(data.qualifications_obtained != null && data.qualifications_obtained != 'others') {
                    eduRecord.qualifications_obtained = data.qualifications_obtained;
                } else if((data.qualifications_obtained == null || data.qualifications_obtained == 'others')
                    && data.other_qualifications_obtained == null) {
                    this.showInuptError('Qualification Obtained');
                }
                // validate institution obtained
                if(data.school_name != null) {
                    eduRecord.school_name = data.school_name;
                } else if(data.other_school_name != null) {
                    eduRecord.school_name = data.other_school_name;
                } else {
                    this.showInuptError('School\'s Name');
                }
                eduRecord.country_id  = data.country == null ? this.showInuptError('Country') : data.country;
                eduRecord.school_type = data.school_type == null ? this.showInuptError('School\'s Type') : data.school_type;
                return this.isEmpty(eduRecord) ? eduRecord : false;
            },
            isEmpty(obj) {// check for empty field
                return ! Object.keys(obj).some(k => !obj[k]);
            },
            showInuptError(name) {
                this.isloading = false;
                this.showInputErrorStatus = true
                this.errorType = 'warning'
                this.errorMsg = 'Invalid ' + name + ' has been entered';
                var self = this;
                setTimeout(function() { self.showInputErrorStatus = false; }, 5000);
            },
            emptyEduRecords() {
                this.records = {
                    school_type: 'medical-school',
                    school_name: null,
                    school_id: null,
                    other_school_name: null,
                    qualifications_obtained: null,
                    country: null,
                    start_date: null,
                    end_date: null,
                    other_qualifications_obtained: null
                };
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        created() {
            this.getLocations();// get locations
        }
    }
</script>

<style>
    .form-control-label {
        width: 100%;
    }
</style>