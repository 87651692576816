<template>
    <div class="pl-lg-4">

        <div class="col-lg-12" v-if="isLoadingBlade">
            <div class="d-flex justify-content-center">
                <img :src="loadingBladeUrl" />
            </div>
        </div>

        <div v-if="! isLoadingBlade">
            <h3 class="text-uppercase">Referees</h3>

            <em class="mb-4 text-danger">
                Two Referees (Non-relatives) are required,
                <!-- one of your Referees must be a fully registered doctor,
                and have practiced in Nigeria for more than Ten (10) Years.  -->
                kindly fill details below...
            </em>

            <hr class="my-4"/>

            <!-- import referee details template here -->
            <AssessmentRefereeData
                v-bind:refereeRecords="refereeRecords"
                v-bind:showAddRecord="showAddRecord"
                v-on:deleteRefereeRecord="deleteRefereeRecord"
                v-bind:pagination="pagination"
                v-bind:hasPagination="hasPagination"
                v-bind:paginate="paginate"
            />

            <hr class="my-4" v-if="hasRecords && ! showAddRecord"/>

            <div class="pl-lg-4" v-if="hasRecords && ! showAddRecord">
                <base-button
                    type="primary"
                    @click="displayAddPage()"
                    :disabled="enableContinue"
                >
                    <i class="fa fa-plus"></i>
                    Add New Record
                </base-button>
            </div>


            <div class="row align-items-center" v-if="! hasRecords || showAddRecord">

                <div class="col-lg-6">
                    <base-input alternative=""
                        label="Referee's Name"
                        placeholder="Enter Referee's Name"
                        input-classes="form-control-alternative"
                        v-model="record.name"
                        required
                    />
                </div>

                <div class="col-lg-6">
                    <base-input alternative=""
                        label="Phone Number"
                        placeholder="Phone Number"
                        input-classes="form-control-alternative"
                        required
                    >
                    <!-- v-model="profile.phone"  -->
                        <VuePhoneNumberInput
                            v-model="tel.phone"
                            @update="updatePhoneNumber"
                            :default-country-code="tel.code"
                            :no-example="true"
                            :clearable="true"
                        />
                    </base-input>
                </div>

                <div class="col-lg-6">
                    <base-input alternative=""
                        label="Referee's Occupation"
                        placeholder="Enter Referee's Occupation"
                        input-classes="form-control-alternative"
                        v-model="record.occupation"
                        required
                    />
                </div>

                <div class="col-lg-6">
                    <base-input alternative=""
                        label="Referee's Position / Status"
                        placeholder="Enter Referee's position in respective occupation"
                        input-classes="form-control-alternative"
                        v-model="record.position"
                        required
                    />
                </div>

                <!-- <div class="col-lg-4">
                    <base-input alternative=""
                        label="Referee's Folio Number (In Full)"
                        placeholder="Enter Referee's Folio Number"
                        input-classes="form-control-alternative"
                        v-model="record.folio_number"
                        :required="! hasRecords"
                    />
                </div> -->

                <div class="col-lg-12">
                    <base-input alternative=""
                        label="Referee's Address"
                        required
                    >
                        <textarea
                            rows="2"
                            class="form-control form-control-alternative"
                            placeholder="Enter Referee's Address"
                            v-model="record.address"
                        ></textarea>
                    </base-input>
                </div>

                <div class="col-lg-12">
                    <base-input alternative=""
                        label="Referee's Business Address"
                        required
                    >
                        <textarea
                            rows="2"
                            class="form-control form-control-alternative"
                            placeholder="Enter Referee's Address"
                            v-model="record.business_address"
                        ></textarea>
                    </base-input>
                </div>

                <div class="col-lg-12">
                    <base-alert :type="errorType" dismissible v-if='showInputErrorStatus'>
                        <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                        <span class="alert-inner--text">
                            {{errorMsg}}
                        </span>
                        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </base-alert>
                </div>
                <div class="col-lg-12"></div>

                <div class="col-lg-12">
                    <base-button
                        type="success"
                        @click="saveRefereeRecords()"
                    >
                        <i class="fa fa-save"></i>
                        {{isloading ? 'Please Wait...' : 'Add Record'}}
                    </base-button>

                    <div class="right" v-if="hasRecords">
                        <base-button
                            type="danger"
                            @click="displayAddPage()"
                        >
                            <i class="fa fa-times"></i>
                            Cancel
                        </base-button>
                    </div>
                </div>

            </div>

            <hr class="my-4"/>

            <div class="pl-lg-4">
                <base-button
                    type="danger"
                    @click="showPreviousPage()"
                >
                    <i class="fa fa-arrow-left"></i>
                    Previous
                </base-button>
                <div class="right">
                    <base-button
                        type="primary"
                        @click="continueLater()"
                    >
                        <i class="fa fa-save"></i>
                        Save &amp; Continue Later
                    </base-button>

                    <base-button
                        type="success"
                        @click="showNextPage()"
                        :disabled="! enableContinue"
                    >
                        Continue
                        <i class="fa fa-arrow-right"></i>
                    </base-button>
                </div>
            </div>


        </div>

    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import AssessmentRefereeData from './AssessmentRefereeData';
    import VuePhoneNumberInput from 'vue-phone-number-input';
    import 'vue-phone-number-input/dist/vue-phone-number-input.css';
    import swal from 'sweetalert2';// import sweet alert

    export default {
        components: {
            AssessmentRefereeData,
            VuePhoneNumberInput
        },
        name: 'AssessmentReferee',
        props: ['displayExamData', 'loadApplicantInfo', 'applicant', 'displayDocument'],
        data() {
            return {
                refereeRecords: [],
                paginatedItems: [],
                record: {
                    name: null,
                    occupation: null,
                    address: null,
                    position: null,
                    business_address: null,
                    phone: null,
                    phone_code: null,
                    folio_number: null
                },
                tel: {
                    phone: null,
                    code: 'NG',
                },
                pagination: {
                    total: null,
                    number: 1,
                    from: null,
                    perPage: this.$store.state.user.perPage // get per page count
                },
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isloading: false,
                showInputErrorStatus: false,
                isLoadingBlade: true,
                showAddRecord: false,
                newAccount: true,
                id: localStorage.getItem('applicantId')
            };
        },
        computed: {
            ...mapGetters(['loadingBladeUrl']),
            hasPagination() {
                return this.paginatedItems && (this.paginatedItems.length > this.pagination.perPage)
                    ? true : false;
            },
            hasRecords() {
                return this.refereeRecords && this.refereeRecords.length > 0;
            },
            enableContinue() {
                return this.refereeRecords && this.refereeRecords.length >= 2;
            }
        },
        methods: {
            ...mapActions(['saveAssessmentApplicantData', 'deleteAssessmentReferee']),
            loadPhoneNumber() {// update phone no
                let data = this.record.phone_code;
                let exploded = data ? data.split('-') : false;
                this.tel.code = exploded && exploded[0] ? exploded[0] : 'NG';
                this.tel.phone = exploded && exploded[1] ? exploded[1] : null;
            },
            continueLater() {
                this.preloader();
                setTimeout(function() {
                    let msg = 'Application was updated successfully, you can continue later';
                    swal.fire("Application Updated!", msg, "success");
                    window.location.href = '/assessment-exam';
                }, 1000);
            },
            updatePhoneNumber(event) {
                if(event && event.isValid && event.e164) {
                    this.record.phone = event.formattedNumber;
                    this.record.phone_code = event.countryCode+'-'+event.phoneNumber;
                }
            },
            displayAddPage() {
                return this.showAddRecord = ! this.showAddRecord;
            },
            loadReferees() {
                this.isLoadingBlade = false;
                if(this.id) {
                    this.setData(this.applicant);
                } else {
                    swal.fire("Session Expired!", "Session timeout, try again...", "warning");
                    window.location.href = '/assessment-exam';
                }
            },
            setData(applicant) {// set paginated function
                if(applicant && applicant.referee_data) {
                    let res = applicant.referee_data;
                    this.refereeRecords = [];
                    this.paginatedItems = res;
                    this.pagination.total = this.paginatedItems.length; // get items total
                    let perPage = this.pagination.perPage; // get per page count
                    if(this.paginatedItems.length > perPage) {// paginate
                        let numberOfPages = Math.ceil(this.paginatedItems.length / perPage);
                        for (let index = 1; index <= numberOfPages; index++) {
                            this.refereeRecords.push(this.paginatedItems[index]);
                        }
                    } else {
                        this.refereeRecords = this.paginatedItems;
                    }
                }
                this.loadPhoneNumber();
            },
            paginate() {// paginate function
                this.refereeRecords = [];
                this.pagination.from = null;
                let page = this.pagination.number;
                let perPage = this.pagination.perPage;
                let from = (page * perPage) - perPage;
                let to = (page * perPage);
                this.pagination.from = from;//get first item number
                return this.refereeRecords = this.paginatedItems.slice(from, to);
            },
            showPreviousPage() {// go to next page
                return this.displayExamData(true, true);
            },
            showNextPage() {// go to next page
                return this.displayDocument(true);
            },
            saveRefereeRecords() {
                this.isloading = true;
                let data = this.validatedInputs();// validate inputs
                if(data) {// save
                    this.preloader();// show loading
                    data.folio_number = this.record.folio_number;
                    data.phone_code = this.record.phone_code;
                    let record = { 'referee_data' : data , 'id' : this.applicant.id };
                    this.saveAssessmentApplicantData(record).then((res) => {
                        this.loadApplicantInfo();
                        this.isloading = false;
                        if(res.status) {
                            this.showErrorStatus = false;
                            this.showAddRecord = false;
                            swal.fire("Record Added!", res.msg , "success");
                            this.loadReferees();// load appointments
                            this.emptyRecords();
                        } else {
                            swal.fire("Error Occurred!", "An error occurred, kindly reload page and try again", "warning");
                        }
                    }).
                    catch(() => {
                        this.isloading = false;
                        this.emptyRecords();
                        swal.fire("Error Occurred!", "An error occurred, kindly reload page and try again", "warning");
                    });
                } else {
                    this.isloading = false;
                    // this.emptyRecords();
                }
            },
            showErrorMsg() {
                this.showInputErrorStatus = true
                this.errorType = 'warning'
                this.errorMsg = 'An error occurred, kindly reload page and try again';
                var self = this;
                setTimeout(function() { self.showInputErrorStatus = false; }, 5000);
            },
            validatedInputs() {
                // let checkFolio = false;
                let data = this.record;
                let appRecord = { name: null, phone: null, occupation: null, address: null, position: null,
                                business_address: null };
                appRecord.business_address = data.business_address == null
                                        ? this.showInuptError('Referee\'s Business Address') : data.business_address;
                appRecord.address = data.address == null ? this.showInuptError('Referee\'s Address') : data.address;
                // if(this.hasRecords) {// check for folio number
                //     checkFolio = true;
                // } else {
                //     checkFolio = data.folio_number == null ? this.showInuptError('Referee\'s Folio Number') : true;
                // }
                appRecord.position = data.position == null ? this.showInuptError('Referee\'s Position') : data.position;
                appRecord.occupation  = data.occupation == null ? this.showInuptError('Referee\'s Occupation') : data.occupation;
                appRecord.phone  = data.phone == null ? this.showInuptError('Referee\'s Phone Number') : data.phone;
                appRecord.name  = data.name == null ? this.showInuptError('Referee\'s Name') : data.name;
                return this.isEmpty(appRecord) ? appRecord : false;
            },
            isEmpty(obj) {// check for empty field
                return ! Object.keys(obj).some(k => !obj[k]);
            },
            showInuptError(name) {
                this.isloading = false;
                this.showInputErrorStatus = true
                this.errorType = 'warning'
                this.errorMsg = 'Invalid ' + name + ' has been entered';
                var self = this;
                setTimeout(function() { self.showInputErrorStatus = false; }, 5000);
            },
            emptyRecords() {
                return this.record = {
                    name: null,
                    occupation: null,
                    address: null,
                    position: null,
                    business_address: null,
                    phone: null,
                    phone_code: null,
                    folio_number: null
                };
            },
            deleteRefereeRecord(id) {// delete examination
                this.preloader();// show loading
                this.deleteAssessmentReferee(id).then((res) => {
                    this.loadApplicantInfo();
                    if(res.status) {
                        swal.fire("Record Deleted!", res.msg , "success");
                        this.loadReferees(); // load appointments
                    } else {
                        swal.fire("Error Occurred!", "An error occurred while deleting, try again", "warning");
                        this.loadReferees(); // load appointments
                    }
                }).
                catch(() => {
                    swal.fire("Network Failure", "Reload page and try again...", "warning");
                    this.loadReferees(); // load appointments
                });
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        created() {
            this.loadReferees();// load appointments
        },
    }
</script>

<style>
.right {
    float: right;
}
</style>

<style>
    .form-control-label {
        width: 100%;
    }
</style>