<template>
    <div>

        <div class="text-center" v-if="! saveCountry">

            <hr class="my-4">
            <h2
                class="mb-0 text-uppercase text-center"
                v-if="application && application.has_payment"
            >
                Make New Payment for
                <em class="text-danger">
                    {{ application.name }}
                </em>
            </h2>

            <hr class="my-4"/>

            <h3 class="text-danger">
                Note: For Old Doctors who already have Full Registration Certifcate
                can click on the Red button. <br>Kindly select the country of Internship
                (i.e. country of Internship placement) below
            </h3><br>

            <div class="row">
                <div class="col-md-3"></div>

                <div class="col-md-6">
                    <base-input alternative=""
                        label="Country of Internship Placement"
                        required
                    >
                        <select
                            class="form-control form-control-alternative"
                            v-model="country"
                        >
                            <option value="null" disabled>--- Select One ---</option>
                            <option
                                v-for="country in locations.countries"
                                v-show="locations && locations.countries"
                                :value="country.id"
                                :key="country.id"
                            >
                                {{country.name}}
                            </option>
                        </select>
                    </base-input>
                </div>

                <div class="col-md-12">
                    <base-button
                        type="success"
                        @click="createApplication()"
                        :disabled="disableSaveButton"
                    >
                        <i class="fa fa-save"></i>
                        Save Changes
                    </base-button>

                    <base-button
                        type="danger"
                        @click="submitNow()"
                    >
                        <i class="ni ni-tv-2"></i>
                        Go To Dashboard
                    </base-button>
                </div>
            </div>
        </div>

        <!-- Import Registration payment blade here -->
        <RegPaymentDetails
            v-bind:application="application"
            v-bind:user="user"
            v-bind:serviceType="serviceType"
            v-bind:hasPreviousApplication="hasPreviousApplication"
            v-bind:previousApplications="previousApplications"
            v-bind:loadApplication="loadApplication"
            v-bind:displayNewPayment="displayNewPayment"
            v-bind:changeAppId="changeAppId"
            v-on:addPayment="addPayment"
            v-if="saveCountry"
        />
    </div>
</template>

<script>
    import RegPaymentDetails from './RegPaymentDetails.vue';
    import { mapGetters, mapActions } from 'vuex';
    import moment from 'moment';
    import swal from 'sweetalert2';

    export default {
        name: 'FullWithoutInternRegPayment',
        components: {
            RegPaymentDetails
        },
        props: ['application', 'user', 'serviceType', 'hasPreviousApplication', 'previousApplications', 'changeAppId',
            'addPayment', 'loadApplication', 'displayNewPayment', 'submitNow'],
        data() {
            return {
                saveCountry: false,
                country: null,
                id: null
            }
        },
        computed: {
            ...mapGetters(['locations']),
            disableSaveButton() {
                return this.country && (this.country == null || this.country == '');
            }
        },
        methods: {
            ...mapActions(['createNewUserApplication', 'updateInternshipCountry']),
            loadPreviousDetails() {
                let application = this.previousApplications && this.previousApplications.length > 0
                        ? this.previousApplications[0] : null;
                this.orderId = application ? application.trans_no : null;
                this.id = application ? application.id : null;
                this.country = application ? application.country_id : null;
            },
            createApplication() {// create application
                this.preloader();// show loading
                let data = { 'id' : this.id, 'country_id' : this.country };
                // console.log(data)
                if(this.id) {
                    this.updateInternshipCountry(data).then((res) => {//update country
                        this.loadApplication();
                        if(res.status) {
                            this.saveCountry = true;
                            swal.close();
                        } else {
                            let msg = 'An error occurred while proccessing, try again...';
                            swal.fire('Error Occurred!', msg, 'warning');
                        }
                    }).
                    catch(() => {
                        let message = 'Network Failure!, kindly reload the page...';
                        swal.fire('Network Failure!', message, 'warning');
                    });
                } else {
                     return this.createNewApplication(); // create new application
                }
            },
            createNewApplication() {
                let data = {};
                data.type = this.applicationType;// get exact application type
                data.user_data_id = this.$store.state.user.userDataId;// get user data id
                data.application_type_id = this.application.id;// apllication type id
                data.trans_no = this.genRandomString();// generate trans no
                data.application_date = this.getCurrentDate();// get current date
                data.country_id = this.country;// get internship country
                this.createNewUserApplication(data).then((res) => {// create application
                    this.loadApplication();
                    if(res.status) {// update transaction
                        this.saveCountry = true;
                        swal.close();
                    } else {
                        let msg = 'An error occurred while proccessing, try again...';
                        swal.fire('Error Occurred!', msg, 'warning');
                    }
                }).
                catch(() => {
                    let message = 'While creating application, kindly reload and try again';
                    swal.fire('Error ocurred', message, 'warning');
                    return false;
                });
            },
            getCurrentDate() {// get current date time
                let date = new Date().toJSON().slice(0,10).replace(/-/g,'-');
                return moment(date).format('YYYY-MM-DD');
            },
            genRandomString() {
                var length = 12;
                var text = '';
                var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
                for (var i=0; i < length; i++) {
                    text += possible.charAt(Math.floor(Math.random() * possible.length));
                }
                return text;
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        created() {
            this.loadPreviousDetails();// get previous application details
        },
    }
</script>

<style scoped>
.right {
    float: right;
}
</style>