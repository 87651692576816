<template>
    <div>
        <div v-if="! showGenerateRrr && ! showAddRrrPage">
            <div class="col-lg-12 text-center">
                <hr>
                <h3 class="mb-0 text-uppercase">
                    Additional Payment for
                    <em class="text-danger">{{ type.title }}</em>
                    <em class="text-danger" v-if="type.batch_payment">{{ ' ('+type.batch_payment.title +')' }}</em>
                </h3>
                <hr>
            </div>

            <div class="container ct-example-row">
                <div class="row">
                    <div class="col">
                        <ul
                            class="list-unstyled"
                            v-if="type && type.batch_payment"
                        >
                            <li>
                                <h3 class="text-center">
                                    Application Name: &nbsp;
                                    <em class="text-capitalize font-weight-light">
                                        {{ type.title + ' ('+type.batch_payment.title +')' }}
                                    </em>
                                </h3>
                                <br>
                                <h3 class="text-center">
                                    Total Amount: &nbsp;
                                    <em class="text-capitalize font-weight-light">
                                        {{ totalAmount | formatPrice }}
                                        NGN
                                    </em>
                                </h3>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 text-center">
                <hr>
                <form :action="remitaPaymentUrl" method="POST">
                    <input
                        type="hidden"
                        v-for="item in paymentRecords"
                        :key="item.title"
                        :name="item.title"
                        :value="item.value"
                    />
                    <base-button
                        type="success"
                        nativeType="submit"
                    >
                        <i class="fa fa-credit-card"></i>
                        {{ isloading ? 'Please Wait...' : 'Pay Directly Online Now'}}
                    </base-button>

                    <base-button
                        type="dark"
                        @click="generateRRR()"
                    >
                        <i class="fa fa-history"></i>
                        {{ isloading ? 'Please Wait...' : 'Generate RRR &amp; Pay at the Bank'}}
                    </base-button>

                    <base-button
                        type="primary"
                        @click="displayAddRrrPage()"
                    >
                        <i class="fa fa-save"></i>
                        I Have Valid RRR
                    </base-button>
                </form>
                <hr>
            </div>
        </div>


        <AssessmentGenerateRrr
            :record="record"
            :rrr="rrr"
            :type="type"
            :orderId="orderId"
            :amount="totalAmount"
            v-if="showGenerateRrr && ! showAddRrrPage"
        />

        <AddIncompletePaymentRrr
            :loadAssessmentBatch="loadAssessmentBatch"
            v-on:displayAddRrrPage="displayAddRrrPage"
            v-if="! showGenerateRrr && showAddRrrPage"
        />

    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import AssessmentGenerateRrr from '../../AssessmentGenerateRrr.vue';
    import AddIncompletePaymentRrr from './AddIncompletePaymentRrr.vue';
    import swal from 'sweetalert2';// import sweet alert

    export default {
        name: 'IncompletePaymentData',
        props: ['type', 'record', 'serviceType', 'totalAmount', 'loadAssessmentBatch'],
        filters: {
            formatPrice(value) {
                if(value) {
                    let val = (value/1).toFixed(2).replace(',', '.');
                    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                } else {
                    return 'N/A';
                }
            }
        },
        components: {
            AssessmentGenerateRrr,
            AddIncompletePaymentRrr
        },
        data() {
            return {
                isloading: false,
                orderId: null,
                showGenerateRrr: false,
                rrr: null,
                showAddRrrPage: false
            }
        },
        computed: {
            ...mapGetters(['remitaMerchantId', 'remitaAdditionalAssessmentCallBackUrl', 'remitaApiKey', 'remitaPaymentUrl']),
            
            gethash() {
                var sha512 = require('js-sha512');
                let text = this.remitaMerchantId+this.serviceType.remita_code+this.orderId
                    +this.totalAmount+this.remitaAdditionalAssessmentCallBackUrl
                    +this.remitaApiKey;
                return sha512(text);
            },
            paymentRecords() {// get payment records
                return [
                    {'title' : 'merchantId', 'value' : this.remitaMerchantId},
                    {'title' : 'serviceTypeId', 'value' : this.serviceType.remita_code},
                    {'title' : 'amt', 'value' : this.totalAmount},
                    {'title' : 'responseurl', 'value' : this.remitaAdditionalAssessmentCallBackUrl},
                    {'title' : 'hash', 'value' : this.gethash},
                    {'title' : 'payerName', 'value' : this.record.name},
                    {'title' : 'paymenttype', 'value' : ''},
                    {'title' : 'payerEmail', 'value' : this.record.email},
                    {'title' : 'payerPhone', 'value' : this.record.phone},
                    {'title' : 'orderId', 'value' : this.orderId},
                ]
            }
        },
        methods: {
            ...mapActions(['generateAssessmentRrr']),
            displayAddRrrPage() {
                this.showAddRrrPage = ! this.showAddRrrPage;
            },
            setData() {
                this.rrr = null;
                this.orderId = null;
                return this.orderId = this.genRandomString(12);
            },
            genRandomString(length = 12) {
                var text = '';
                var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
                for (var i=0; i < length; i++) {
                    text += possible.charAt(Math.floor(Math.random() * possible.length));
                }
                return text;
            },
            generateRRR() {// generate rrr
                let data = {
                    serviceTypeCode : this.serviceType.remita_code,
                    amount : this.totalAmount,
                    orderId : this.orderId,
                    payerName : this.record.name,
                    payerEmail : this.record.email,
                    payerPhone : this.record.phone
                };
                this.isloading = true;
                this.rrr = null
                this.preloader();
                this.generateAssessmentRrr(data).then((res) => {
                    this.isloading = false;
                    swal.close();
                    if(res.statuscode == "025") {
                        this.rrr = res;
                        this.showGenerateRrr = true;
                    } else {
                        this.setData();
                        let msg = "RRR was not generated, try again...";
                        swal.fire("RRR Failed!", msg, "warning");
                    }
                }).
                catch(() => {
                    this.setData();
                    this.isloading = false;
                    let msg = "Network Failure!, kindly reload the page...";
                    swal.fire("Error Ocurred!", msg, "warning");
                });
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        created() {
            this.setData();
        }
    }
</script>

<style scoped>
    .no-padding-bottom {
        padding-bottom: 0rem !important
    }
    .right {
        float: right;
    }
</style>

<style>
    .form-control-label {
        width: 100%;
    }
    .height-50 {
        height: 50px;
    }
    .borderLine {
        border: 1px solid #ededed;
    }
</style>