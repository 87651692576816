<template>
    <div>
        <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center no-padding-bottom">
            <span class="mask bg-gradient-danger opacity-8"></span>
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col-xl-12 order-xl-1">
                    <br><br><br>
                    <card shadow type="secondary">
                        <div slot="header" class="bg-white border-0">
                            <div class="row align-items-center">

                                <div class="col-8">
                                    <h3 class="mb-0 text-uppercase">{{ this.$route.name }}</h3>
                                    <br>
                                    <span class="text-danger">
                                        Fill the form below....
                                    </span>
                                </div>
                                <div class="col-4 text-right">
                                    <a :href="websiteUrl" class="btn btn-sm btn-danger">
                                       <i class="fa fa-reply"></i> Home
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-lg-4">
                                <base-input alternative=""
                                    label="Reporter's Full Name"
                                    placeholder="Enter Your Full Name"
                                    input-classes="form-control-alternative text-capitalize"
                                    v-model="record.name"
                                    required
                                />
                            </div>

                            <div class="col-lg-4">
                                <base-input alternative=""
                                    label="Reporter's Email Address"
                                    placeholder="Enter Your Email Address"
                                    input-classes="form-control-alternative"
                                    v-model="record.email"
                                    required
                                />
                            </div>

                            <div class="col-lg-4">
                                <base-input alternative=""
                                    label="Reporter's Phone Number (Optional)"
                                    placeholder="Enter Your Phone Number"
                                    input-classes="form-control-alternative"
                                >
                                <!-- v-model="profile.phone"  -->
                                    <VuePhoneNumberInput
                                        v-model="tel"
                                        @update="updatePhoneNumber"
                                        default-country-code="NG"
                                        :no-example="true"
                                        :clearable="true"
                                    />
                                </base-input>
                            </div>

                            <div class="col-lg-12">
                                <base-input alternative=""
                                    label="Complain Details"
                                    required
                                >
                                    <textarea
                                        rows="5"
                                        class="form-control form-control-alternative"
                                        placeholder="Enter Complain Details"
                                        v-model="record.message"
                                    ></textarea>
                                </base-input>
                            </div>

                            <div class="col-lg-4">
                                <base-input alternative=""
                                    label="Suspect's Name"
                                    placeholder="Enter Your Name"
                                    input-classes="form-control-alternative text-capitalize"
                                    v-model="record.suspect_name"
                                    required
                                />
                            </div>

                            <div class="col-lg-4">
                                <base-input alternative=""
                                    label="Suspect's Gender"
                                    >
                                    <select
                                        class="form-control form-control-alternative"
                                        v-model="record.suspect_gender"
                                    >
                                        <option :value="null" disabled>--- Select One ---</option>
                                        <option value="male">Male</option>
                                        <option value="female">Female</option>
                                        <option value="others">School/Hospital/CPD Provider</option>
                                    </select>
                                </base-input>
                            </div>

                            <div class="col-lg-4">
                                <base-input alternative=""
                                    label="Suspect's Category"
                                    required
                                    >
                                    <select
                                        class="form-control form-control-alternative"
                                        v-model="record.suspect_category"
                                    >
                                        <option :value="null" disabled>--- Select One ---</option>
                                        <option value="personnel">Personnel</option>
                                        <option value="school">School</option>
                                        <option value="hospital">Hospital</option>
                                        <option value="cpd-provider">CPD Provider</option>
                                    </select>
                                </base-input>
                            </div>

                            <div class="col-lg-12">
                                <base-input alternative=""
                                    label="Suspect's Address"
                                    required
                                >
                                    <textarea
                                        rows="3"
                                        class="form-control form-control-alternative"
                                        placeholder="Enter Suspect's Address"
                                        v-model="record.suspect_address"
                                    ></textarea>
                                </base-input>
                            </div>

                            <div class="col-lg-4" v-if="locations && locations.states">
                                <base-input alternative=""
                                    label="Suspect's State"
                                >
                                    <select
                                        class="form-control form-control-alternative"
                                        v-model="record.suspect_state"
                                        @change="getStateLgas()"
                                    >
                                        <option :value="null" disabled>--- Select One ---</option>
                                        <option
                                            v-for="state in locations.states"
                                            :value="state.id"
                                            :key="state.id"
                                        >
                                            {{state.name}}
                                        </option>
                                    </select>
                                </base-input>
                            </div>

                            <div class="col-lg-4" v-if="record && record.suspect_state">
                                <base-input alternative=""
                                    label="Suspect's LGA"
                                >
                                    <select
                                        class="form-control form-control-alternative"
                                        v-model="record.suspect_lga"
                                        v-if="lgas"
                                    >
                                        <option :value="null" disabled>--- Select One ---</option>
                                        <option
                                            v-for="lga in lgas"
                                            :value="lga.id"
                                            :key="lga.id"
                                        >
                                            {{lga.name}}
                                        </option>
                                    </select>
                                </base-input>
                            </div>

                            <div class="col-lg-4">
                                <base-input
                                    label="Date of Incident"
                                    addon-left-icon="ni ni-calendar-grid-58"
                                    required
                                >
                                    <flat-picker
                                        slot-scope="{focus, blur}"
                                        @on-open="focus"
                                        @on-close="blur"
                                        :config="{allowInput: true}"
                                        placeholder="Select Date.."
                                        class="form-control datepicker"
                                        v-model="record.date_of_incident"
                                    >
                                    </flat-picker>
                                </base-input>
                            </div>
                        </div>

                        <div class="row">

                            <div
                                class="col-lg-12 text-center"
                                v-if="showErrorStatus"
                            >
                                <base-alert :type="errorType">
                                    <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                                    <span class="alert-inner--text">
                                        {{errorMsg}}
                                    </span>
                                </base-alert>
                                <br>
                            </div>

                            <div class="col-lg-12" v-if="! isNotRobot">
                                <vue-recaptcha
                                    :sitekey="recaptchaKey"
                                    :loadRecaptchaScript="true"
                                    @verify="proceedSubmit"
                                ></vue-recaptcha>
                            </div>

                            <div class="col-lg-12">
                                <br>
                                <base-button
                                    type="success"
                                    @click="sendMessage()"
                                    :disabled="! isNotRobot"
                                >
                                    <i class="fa fa-save"></i>
                                    {{isloading ? 'Please Wait...' : 'Send Report'}}
                                </base-button>
                                <br><br><br>
                            </div>
                        </div>
                    </card>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import VuePhoneNumberInput from 'vue-phone-number-input';
    import 'vue-phone-number-input/dist/vue-phone-number-input.css';
    import VueRecaptcha from 'vue-recaptcha';
    import flatPicker from "vue-flatpickr-component";
    import "flatpickr/dist/flatpickr.css";
    import swal from 'sweetalert2';// import sweet alert

    export default {
        components: {
            VuePhoneNumberInput,
            VueRecaptcha,
            flatPicker
        },
        name: 'AddReportQuack',
        data() {
            return {
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                record: {
                    name: null,
                    email: null,
                    phone: null,
                    message: null,
                    suspect_name: null,
                    suspect_gender: null,
                    suspect_address: null,
                    suspect_category: null,
                    suspect_state: null,
                    suspect_lga: null,
                    date_of_incident: null
                },
                lgas: [],
                tel: null,
                isloading: false,
                isNotRobot: false
            }
        },
        computed: {
            ...mapGetters(['websiteUrl', 'recaptchaKey', 'locations']),
        },
        methods: {
            ...mapActions(['sendQuackReport', 'getLocations', 'verifyRecaptcha']),
            sendMessage() {
                this.isloading = true;
                this.preloader();// show loading
                let status = this.validatedData();// valid input data
                if(status && this.isNotRobot) {// send report
                    this.sendQuackReport(this.record).then((res) => {
                        this.isloading = false;
                        swal.close();
                        if(res.status) {
                            this.emptyFields();
                            swal.fire("Report Sent!", res.msg, "success");
                        } else {
                            swal.fire("Error Ocurred!", res.msg, "warning");
                        }
                    });
                } else if(! this.isNotRobot) {
                    this.isloading = false;
                    swal.fire("Error Ocurred!", "Click on the Google reCAPTCHA above", "warning");
                } else {
                    this.isloading = false;
                    swal.close();
                }
            },
            validatedData() {
                let data = this.record;
                data.date_of_incident != null ? true : this.showInuptError('Date of Incident');
                data.suspect_address != null ? true : this.showInuptError('Suspect\'s Address');
                data.suspect_category != null ? true : this.showInuptError('Suspect\'s Category');
                data.suspect_name != null ? true : this.showInuptError('Suspect\'s Name');
                data.message != null ? true : this.showInuptError('Complain Details');
                data.email != null ? true : this.showInuptError('Reporter\'s Email Address');
                data.name != null ? true : this.showInuptError('Reporter\'s Name');
                let status = data.name && data.email && data.message && data.suspect_name && data.suspect_category
                            && data.suspect_address && data.date_of_incident;
                return status ? true : false;
            },
            showInuptError(name) {
                this.isloading = false;
                this.showErrorStatus = true
                this.errorType = 'warning'
                this.errorMsg = 'Invalid ' + name + ' has been entered';
                var self = this;
                setTimeout(function() { self.showErrorStatus = false; }, 3000);
            },
            getStateLgas() {// get state's lgas
                let lgas = [];
                let stateId = this.record.suspect_state;
                for(let lga of this.locations.lgas) {
                    if (lga.state_id == stateId) {
                        lgas.push(lga);
                    }
                }
                return this.lgas = lgas;
            },
            updatePhoneNumber(event) {
                if(event && event.isValid && event.e164) {
                    this.record.phone = event.formattedNumber;
                }
            },
            emptyFields() {
                this.record = {
                    name: null,
                    email: null,
                    phone: null,
                    message: null,
                    suspect_name: null,
                    suspect_gender: null,
                    suspect_address: null,
                    suspect_category: null,
                    suspect_state: null,
                    suspect_lga: null,
                    date_of_incident: null
                };
            },
            proceedSubmit(response) {
                let data = { response: response };
                this.verifyRecaptcha(data).then((res) => {
                    if(res.success) {
                        return setTimeout(() => {
                            this.isNotRobot = ! this.isNotRobot;
                        }, 1000);
                        
                    } else {
                        swal.fire("Error Occurred", "An error occurred while verifying, kindly try again", "warning");
                    }
                }).catch(() => {//check for failure
                    swal.fire("Error Occurred", "An error occurred while verifying, kindly try again", "warning");
                });
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        created() {
            this.getLocations();
        }
    }
</script>

<style scoped>
    .no-padding-bottom {
        padding-bottom: 0rem !important
    }
</style>

<style>
    .form-control-label {
        width: 100%;
    }
</style>