<template>
    <div>
        <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center">
            <span class="mask bg-gradient-danger opacity-8"></span>
        </base-header>


        <div class="container-fluid mt--7">
            <div class="row">

                <div class="col-xl-12 order-xl-1">
                    <card shadow type="secondary">
                        <div slot="header" class="bg-white border-0">
                            <div class="row align-items-center">
                                <div class="col-8">
                                    <h3 class="mb-0 text-uppercase">Professional Information</h3>
                                </div>
                                <div class="col-4 text-right">
                                    <!-- <a href="#!" class="btn btn-sm btn-primary">Settings</a> -->
                                </div>
                            </div>
                        </div>

                        <template>
                            <tabs fill class="flex-column flex-md-row">
                                <card shadow>
                                    <tab-pane title="Educational Details">
                                        <span slot="title">
                                            <i class="ni ni-books"></i>
                                            Educational Details
                                        </span>
                                        <!-- import User Educational Details template here -->
                                        <EducationalDetails />
                                    </tab-pane>

                                    <tab-pane
                                        title="Appointments"
                                        v-if="! isProvisionalRegistration"
                                    >
                                        <span slot="title">
                                            <i class="ni ni-badge"></i>
                                            Appointments
                                        </span>
                                        <!-- import User Appointments Profile template here -->
                                        <Appointment />
                                    </tab-pane>

                                    <tab-pane
                                        title="Internship Performance"
                                        v-if="isFullRegistration"
                                    >
                                        <span slot="title">
                                            <i class="ni ni-archive-2"></i>
                                            Internship Performance
                                        </span>
                                        <!-- import User Internship Performance template here -->
                                        <Internship />
                                    </tab-pane>

                                    <tab-pane
                                        title="Additional Qualications"
                                        v-if="! isProvisionalRegistration"
                                    >
                                        <span slot="title">
                                            <i class="ni ni-bag-17"></i>
                                            Additional Qualications
                                        </span>
                                        <!-- import User Additional Qualications template here -->
                                        <AddQualification />
                                    </tab-pane>

                                </card>
                            </tabs>

                        </template>
                    </card>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    // get user's details from store
    import { mapActions } from 'vuex';
    // get components
    import EducationalDetails from './ProfessionalData/EducationalRecords/EducationalDetails.vue';
    import Appointment from './ProfessionalData/Appointment/Appointment.vue';
    import Internship from './ProfessionalData/Internship/Internship.vue';
    import AddQualification from './ProfessionalData/AddQualification/AddQualification.vue';

    export default {
        components: {
            EducationalDetails,
            Appointment,
            Internship,
            AddQualification
        },
        name: 'ProfessionalData',
        computed: {
            isFullRegistration() {
                const profile = this.$store.state.user.basicProfile;// get user basic profile
                const subregTypeCode = this.$store.state.user.basicProfile.
                        has_sub_registration_type;// get user reg type
                return profile && subregTypeCode &&
                        (subregTypeCode.registration_type.code == 'full-reg' ||
                        subregTypeCode.registration_type.code == 'full-without-provisional-reg')
                        ? true : false;
            },
            isProvisionalRegistration() {
                const profile = this.$store.state.user.basicProfile;// get user basic profile
                const subregTypeCode = this.$store.state.user.basicProfile.
                        has_sub_registration_type;// get user reg type
                return profile && subregTypeCode &&
                        subregTypeCode.registration_type.code == 'provisional-reg' ? true : false;
            }
        },
        methods: {
            ...mapActions(['fetchUserProfile']),
        },
        created() {
            this.fetchUserProfile();
        }
    };
</script>
<style scoped>
    .width600 {
        width: 600px !important;
    }
    .marginLeft23 {
        margin-left: 23px;
    }
    .image-size {
        height: 180px;
        width: 190px;
    }
</style>
