<template>
    <div>
        <div class="row">
            <div class="col-lg-12">
                <h3 class="mb-0 text-uppercase">Additional Information</h3>
                <hr class="my-4" />
            </div>

            <div class="table-responsive">
                <table class="table table-bordered">
                    <tbody>
                        <td>
                            <h4>Professional Experiences</h4>
                        </td>
                        <td colspan="5">
                            <h4 class="text-capitalize font-weight-light">
                                {{ record.professional_experiences }}
                            </h4>
                        </td>
                    </tbody>
                    <tbody>
                        <td>
                            <h4>Employ Status</h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ record.employ_status ? record.employ_status : 'N/A' }}
                            </h4>
                        </td>
                        <td>
                            <h4>Have you attended any <br> Remedial Course</h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ record.remedial_course ? record.remedial_course : 'N/A' }}
                            </h4>
                        </td>
                        <td>
                            <h4>Epic Verification <br> Ref ID</h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ record.epic_id ? record.epic_id : 'N/A' }}
                            </h4>
                        </td>
                    </tbody>
                    <tbody v-if="(record.employ_status == 'yes')">
                        <td>
                            <h4>Employment Country</h4>
                        </td>
                        <td colspan="2">
                            <h4 class="text-capitalize font-weight-light">
                                {{ record.has_country ? record.has_country.name : 'N/A' }}
                            </h4>
                        </td>
                        <td>
                            <h4>Employment State</h4>
                        </td>
                        <td v-if="(record.country_id == 164)" colspan="2">
                            <h4 class="text-capitalize font-weight-light">
                                {{ record.has_state ? record.has_state.name : 'N/A' }}
                            </h4>
                        </td>
                        <td v-if="(record.country_id != 164)" colspan="2">
                            <h4 class="text-capitalize font-weight-light">
                                {{ record.foreign_state ? record.foreign_state : 'N/A' }}
                            </h4>
                        </td>
                    </tbody>
                    <tbody>
                        <td>
                            <h4>Have you taken this <br> exam before</h4>
                        </td>
                        <td :colspan="(record.exam_taken == 'yes') ? '2' : '5'">
                            <h4 class="text-capitalize font-weight-light">
                                {{ record.exam_taken ? record.exam_taken : 'N/A' }}
                            </h4>
                        </td>
                        <td v-if="(record.exam_taken == 'yes')">
                            <h4>How many times</h4>
                        </td>
                        <td v-if="(record.exam_taken == 'yes')" colspan="2">
                            <h4 class="text-capitalize font-weight-light">
                                {{ record.exam_times ? record.exam_times : 'N/A' }}
                            </h4>
                        </td>
                    </tbody>
                </table>
            </div>
            <div class="col-lg-12">
                <hr class="my-4" />
            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';

    export default {
        name: 'AdditionalDetails',
        props: [ 'record' ],
        filters: {
            getDateFormat(value) {
                return value ? moment(value).format('Do MMM, YYYY') : 'N/A';
            }
        }
    }
</script>

<style scoped>
    .no-padding-bottom {
        padding-bottom: 0rem !important
    }
    .right {
        float: right !important;
    }
    .photo-class {
        width: 200px;
        height: 200px;
    }
</style>

<style>
    .form-control-label {
        width: 100%;
    }
    .height-50 {
        height: 50px;
    }
    .borderLine {
        border: 1px solid #ededed;
    }
</style>