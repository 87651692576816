<template>
    <div>
        <div v-if="! isLoadingBlade && ! showErrorStatus">
            <div class="row">

                <div class="col-lg-12">
                    <hr>
                    <h3 class="mb-0 text-uppercase">Personal Information</h3>
                    <hr>
                </div>

                <div class="col-lg-3">
                    <base-input alternative=""
                        label="RRR"
                        input-classes="form-control-alternative"
                        v-model="rrr"
                        required
                        readonly
                    />
                </div>

                <div class="col-lg-3">
                    <base-input alternative=""
                        label="Last Name"
                        placeholder="Enter Last Name"
                        input-classes="form-control-alternative"
                        v-model="record.last_name"
                        required
                    />
                </div>

                <div class="col-lg-3">
                    <base-input alternative=""
                        label="First Name"
                        placeholder="Enter First Name"
                        input-classes="form-control-alternative"
                        v-model="record.first_name"
                        required
                    />
                </div>

                <div class="col-lg-3">
                    <base-input alternative=""
                        label="Other Names"
                        placeholder="Enter Other Names"
                        input-classes="form-control-alternative"
                        v-model="record.other_names"
                    />
                </div>

                <div class="col-lg-3">
                    <base-input alternative=""
                        label="Previous Name"
                        placeholder="Enter Previous Name"
                        input-classes="form-control-alternative"
                        v-model="record.previous_name"
                    />
                </div>

                <div class="col-lg-3">
                    <base-input alternative=""
                        label="Gender"
                        required
                    >
                        <select
                            class="form-control form-control-alternative"
                            v-model="record.gender"
                        >
                            <option :value="null" disabled selected>--- Select One ---</option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                        </select>
                    </base-input>
                </div>

                <div class="col-lg-3">
                    <base-input alternative=""
                        label="Marital Status"
                        required
                    >
                        <select
                            class="form-control form-control-alternative"
                            v-model="record.marital_status"
                        >
                            <option :value="null" disabled selected>--- Select One ---</option>
                            <option value="single">Single</option>
                            <option value="married">Married</option>
                            <option value="divorced">Divorced</option>
                            <option value="widow">Widow</option>
                        </select>
                    </base-input>
                </div>

                <div class="col-lg-3">
                    <base-input
                        label="Date of Birth"
                        addon-left-icon="ni ni-calendar-grid-58"
                        required
                    >
                        <flat-picker
                            slot-scope="{focus, blur}"
                            @on-open="focus"
                            @on-close="blur"
                            :config="{allowInput: true}"
                            placeholder="Select Date.."
                            class="form-control datepicker"
                            v-model="record.date_of_birth"
                        >
                        </flat-picker>
                    </base-input>
                </div>

                <div class="col-lg-3">
                    <base-input alternative=""
                        label="Place of Birth"
                        placeholder="Enter Place of Birth"
                        input-classes="form-control-alternative"
                        v-model="record.place_of_birth"
                        required
                    />
                </div>

                <div class="col-lg-3">
                    <base-input alternative=""
                        label="Phone Number"
                        placeholder="Phone Number"
                        input-classes="form-control-alternative"
                        required
                    >
                    <!-- v-model="profile.phone"  -->
                        <VuePhoneNumberInput
                            v-model="tel.phone"
                            @update="updatePhoneNumber"
                            :default-country-code="tel.code"
                            :no-example="true"
                            :clearable="true"
                        />
                    </base-input>
                </div>

                <div class="col-lg-3">
                    <base-input alternative=""
                        label="Email Address"
                        placeholder="Enter Email Address"
                        input-classes="form-control-alternative"
                        v-model="record.email"
                        type="email"
                        required
                    />
                </div>

                <div class="col-lg-3">
                    <base-input alternative=""
                        label="Practice Type"
                        required
                    >
                        <select
                            class="form-control form-control-alternative"
                            v-model="record.type"
                        >
                            <option :value="null" disabled selected>--- Select One ---</option>
                            <option value="medical">Medical</option>
                            <option value="dental">Dental</option>
                        </select>
                    </base-input>
                </div>

                <div class="col-lg-12">
                    <hr>
                    <h3 class="mb-0 text-uppercase">Nationality Information</h3>
                    <hr>
                </div>

                <div class="col-lg-4">
                    <base-input alternative=""
                        label="Nationality"
                        required
                    >
                        <autocomplete
                            v-if="locations.countries && locations.countries.length > 0"
                            input-class="form-control form-control-alternative"
                            placeholder="Type to Search for Country"
                            showNoResults
                            :source="locations.countries"
                            v-model="record.nationality" 
                            :initialDisplay="(hasCountry ? hasCountry.name : '')"                           
                            @selected="changeCountry"
                        >
                        </autocomplete>
                    </base-input>
                </div>

                <div class="col-lg-4">
                    <base-input alternative=""
                        label="State/Province"
                        required
                    >
                        <autocomplete
                            ref="autocomplete"
                            :disableInput="cities && cities.length == 0"
                            input-class="form-control form-control-alternative"
                            placeholder="Type to Search State/Province"
                            showNoResults
                            :source="cities"
                            :initialDisplay="record.state_origin"
                            resultsValue="name"
                            @selected="changeCity"
                        >
                        </autocomplete>
                    </base-input>
                </div>

                <div class="col-lg-4">
                    <base-input alternative=""
                        label="Town"
                        placeholder="Enter Town"
                        input-classes="form-control-alternative"
                        v-model="record.home_town"
                        type="text"
                        required
                    />
                </div>


                <div class="col-lg-12">
                    <hr>
                    <h3 class="mb-0 text-uppercase">Contact (Residential) Information</h3>
                    <hr>
                </div>

                <div class="col-lg-12">
                    <base-input alternative=""
                        label="Contact Address"
                        required
                    >
                        <textarea
                            rows="2"
                            class="form-control form-control-alternative"
                            placeholder="Enter Contact Address"
                            v-model="record.contact_address"
                        ></textarea>
                    </base-input>
                </div>

                

                <div class="col-lg-12">
                    <hr>
                    <h3 class="mb-0 text-uppercase">Academic History</h3>
                    <hr>
                </div>
                
                <div class="col-lg-4">
                    <base-input alternative=""
                        label="Select Your University"
                        required
                    >
                        <autocomplete
                            v-if="schools && schools.length > 0"
                            input-class="form-control form-control-alternative"
                            placeholder="Type to Search for University"
                            showNoResults
                            :source="schools"
                            v-model="record.world_school_id"
                            :disableInput="lock"
                            :initialDisplay="studentSchool"
                            :results-display="formattedDisplay"
                        >
                        </autocomplete>
                    </base-input>
                </div>
            </div>
        </div>

        <div class="col-lg-12" v-if="isLoadingBlade">
            <div class="d-flex justify-content-center">
                <img :src="loadingBladeUrl" />
            </div>
        </div>

        <div class="col-lg-12 text-center" v-if='(showErrorStatus || showInputErrorStatus) && ! isLoadingBlade'>
            <base-alert :type="errorType">
                <!-- <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span> -->
                <span class="alert-inner--text">
                    <h2 class="text-white">{{errorMsg}}</h2>
                </span>
            </base-alert>
        </div>

        <div class="col-lg-12" v-if="! isLoadingBlade">
            <hr>
            <base-button
                type="success"
                class="right"
                :disabled="! rrr"
                @click="savePersonalData()"
            >
                {{ isloading ? 'Please Wait...' : 'Save &amp; Continue'}}
                <i class="fa fa-arrow-right"></i>
            </base-button>
        </div>

    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import VuePhoneNumberInput from 'vue-phone-number-input';
    import 'vue-phone-number-input/dist/vue-phone-number-input.css';
    import flatPicker from "vue-flatpickr-component";
    import "flatpickr/dist/flatpickr.css";    
    import swal from 'sweetalert2';// import sweet alert
    import moment from 'moment';
    import Autocomplete from 'vuejs-auto-complete';
    import states from '../../../../states.json';

    export default {
        name: 'ProfileData',
        props: ['displayPhoto', 'displayPhoto', 'data', 'transaction', 'loadApplicantInfo', 'id', 'lock'],
        components: {
            VuePhoneNumberInput,
            Autocomplete,
            flatPicker
        },
        data() {
            return {
                isLoadingBlade: true,
                isloading: false,
                showInputErrorStatus: false,
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                record: {},
                tel: {
                    phone: null,
                    code: 'NG',
                },
                states: states,
                cities: []
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl', 'locations']),
            rrr() {
                return this.transaction  ? this.transaction.payment_ref : null;
            },
            years() {
                let startYear = 1959;// start from 1900
                let currentYear = parseInt(moment().format('YYYY'));
                let years = [];
                for (let i = currentYear; i > startYear; i-- ) {
                    years.push(i);
                }
                return years;
            },
            schools() {// get all nigerian schools
                return this.data && this.data.schools ? this.data.schools : [];
            },
            studentSchool() {// get student's school name
                return this.data && this.data.personal_data && this.data.personal_data.school && this.data.personal_data.school.school_name 
                            ? this.data.personal_data.school.school_name : '';
            },
            hasCountry() {// student country of residence 
                return this.data && this.data.personal_data && this.data.personal_data.country ? this.data.personal_data.country : {};
            }
        },
        methods: {
            ...mapActions(['updateRemediationStudentData', 'getLocations']),
            setProfileData() {// set applicant data
                this.showInputErrorStatus = false;
                this.emptyFields();
                this.isLoadingBlade = false;
                if(this.data) {
                    let application = this.data;  
                    let student = application.personal_data;
                    if(student) {
                        this.record = {
                            last_name:          student.last_name,
                            first_name:         student.first_name,                    
                            other_names:        student.other_names,
                            previous_name:      student.previous_name,
                            gender:             student.gender,
                            marital_status:     student.marital_status,
                            date_of_birth:      student.date_of_birth,
                            place_of_birth:     student.place_of_birth,
                            phone:              student.phone,
                            phone_code:         student.phone_code,
                            email:              student.email,
                            type:               student.type,
                            nationality:        student.nationality,
                            state_origin:       student.state_origin,
                            home_town:          student.home_town,
                            contact_address:    student.contact_address,                            
                            world_school_id:    student.world_school_id
                        };
                        student.country ? this.changeCountry(false, student.country) : false;// update country
                        this.loadPhoneNumber();// update phone number
                    } 
                }
            },
            loadPhoneNumber() {// update phone no
                let data = this.record.phone_code;
                let exploded = data ? data.split('-') : false;
                this.tel.code = exploded && exploded[0] ? exploded[0] : 'NG';
                this.tel.phone = exploded && exploded[1] ? exploded[1] : null;
            },
            changeCountry(e, country = null) {// update cities 
                this.cities = [];
                let data = e ? e.selectedObject : country;
                this.cities = this.states.filter(item => item.country_code == data.code);
            },
            changeCity(e) {// update state
                this.record.state_origin = null;
                let data = e.selectedObject;
                return this.record.state_origin = data.name;
            },
            savePersonalData() {                
                this.showInputErrorStatus = false;                
                let res = this.validatedData();// valid input data
                if(res.status) {// update
                    this.isloading = true;
                    this.preloader();// show loading
                    let data = { profile_data : res.data, id : this.id };
                    this.updateRemediationStudentData(data).then((res) => {
                        this.isloading = false;
                        this.loadApplicantInfo();
                        if(res.status) {
                            this.displayPhoto(true);
                            swal.fire("Profile Updated!", res.msg, "success");
                        } else {
                            let msg = "Error occurred while updating, reload page";
                            swal.fire("Error Ocurred!", msg, "warning");
                        }
                    }).
                    catch(() => {
                        this.isloading = false;
                        let msg = "Server Failure!, kindly reload the page...";
                        swal.fire("Error Ocurred!", msg, "warning");
                    });
                } else {
                    this.isloading = false;
                    swal.close();
                }
            },
            validatedData() {// check if input is a locked one and is valid before passing
                const inputs = this.record; // get all data
                const optionalInputs = ['other_names', 'previous_name' ];// get optional inputs
                const inputsArray = Object.entries(inputs);
                let validItems = {};
                for(let item of inputsArray) {
                    const input = item[0];
                    const value = item[1];
                    const isValid = (value != null && value != '');// check for empty input
                    const isOptionalInput = optionalInputs.includes(input);
                    if(isValid || isOptionalInput) {// push item into valid items
                        validItems[input] = value;
                    } else if(! isValid && ! isOptionalInput) {
                        const errorText = (input == 'world_school_id') ? 'University' : this.replaceAll(input, '_', ' ');
                        this.showInuptError(errorText);
                        validItems = {};
                        break;
                    }                    
                }
                var status = Object.entries(validItems).length > 0;
                return {data : validItems, status : status };
            },
            titleCase(string){
                return string[0].toUpperCase() + string.slice(1).toLowerCase();
            },
            escapeRegExp(string) {
                return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
            },
            replaceAll(str, find, replace) {
                return str.replace(new RegExp(this.escapeRegExp(find), 'g'), replace);
            },
            emptyFields() {
                this.record = {
                    last_name:          null,
                    first_name:         null,                    
                    other_names:        null,
                    previous_name:      null,
                    gender:             null,
                    marital_status:     null,
                    date_of_birth:      null,
                    place_of_birth:     null,
                    phone:              null,
                    phone_code:         null,
                    email:              null,
                    type:               null,
                    nationality:        null,
                    state_origin:       null,
                    home_town:          null,
                    contact_address:    null,
                    world_school_id:    null
                };
            },
            showInuptError(name) {
                this.isloading = false;
                this.showInputErrorStatus = true;
                this.errorType = 'warning'
                this.errorMsg = 'Invalid ' + this.titleCase(name) + ' has been entered';
                var self = this;
                setTimeout(function() { self.showInputErrorStatus = false; }, 5000);
            },
            updatePhoneNumber(event) {
                if(event && event.isValid && event.e164) {
                    this.record.phone = event.formattedNumber;
                    this.record.phone_code = event.countryCode+'-'+event.phoneNumber;
                }
            },
            formattedDisplay (result) {
                return result.school_name + ' [' + result.city + ']';
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        created() {
            this.setProfileData();
            this.getLocations();
        }
    }
</script>

<style scoped>
    .no-padding-bottom {
        padding-bottom: 0rem !important
    }
    .right {
        float: right !important;
    }
</style>

<style>
    .form-control-label {
        width: 100%;
    }
    .height-50 {
        height: 50px;
    }
    .borderLine {
        border: 1px solid #ededed;
    }
</style>