<template>
    <div>
        <div slot="header" class="border-0">
            <div class="row align-items-center" v-if="application">
                <div class="col-8">
                    <h4 class="mb-0 text-uppercase">
                        Make Payment for
                        <em class="text-danger">{{ application.name }}</em>
                    </h4>
                </div>
                <div class="col-4 text-right">
                    <router-link to="/applications" class="btn btn-sm btn-danger">
                        <i class="fa fa-reply"></i> Back
                    </router-link>
                </div>
            </div>
        </div>

        <div class="container ct-example-row" v-if="! showAddPayment">
            <div class="row" v-if="application && application.has_payment">
                <div class="col-lg-12">
                    <ul class="list-unstyled">
                        <li>
                            <hr class="my-4">
                            <h3 class="text-center">
                                Application Name: &nbsp;
                                <em class="text-capitalize font-weight-light">
                                    {{ application.name }}
                                </em>
                            </h3>
                            <br>
                            <h3 class="text-center">
                                Total Amount: &nbsp;
                                <em class="text-capitalize font-weight-light">
                                    {{ totalAmount | formatPrice }} NGN
                                </em>
                            </h3>
                            <hr class="my-4"
                                v-if="application && application.has_payment &&
                                    application.has_payment.has_breakdowns.length > 0"
                            >
                            <h1
                                class="text-center"
                                v-if="application && application.has_payment &&
                                    application.has_payment.has_breakdowns.length > 0"
                            >
                                Payment Details
                            </h1>
                            <hr class="my-4">
                            <h3
                                v-for="breakdown in application.has_payment.has_breakdowns"
                                :key="breakdown.id"
                            >
                                {{ breakdown.name }}: &nbsp;
                                <em class="text-capitalize font-weight-light right">
                                    {{ breakdown.amount | formatPrice }} NGN
                                </em>
                                <br>
                            </h3>

                            <h3 v-if="application.code == 'cert-good-standing'">
                                Administrative Charges
                                <strong>
                                    {{ postageFee && postageFee.country ? ' for ('+postageFee.country.name+')' : '' }}
                                </strong>: &nbsp;
                                <em class="text-capitalize font-weight-light right">
                                    {{ postageFee.fee | formatPrice }} NGN
                                </em>
                                <br>
                            </h3>

                            <hr class="my-4">
                            <h3>
                                Total: &nbsp;
                                <em class="text-capitalize font-weight-light right">
                                    {{  totalAmount | formatPrice }} NGN
                                </em>
                            </h3>
                            <hr class="my-4">

                            <h3 class="text-danger text-center"
                                v-if="showPaymentForm"
                            >
                                Note: If you want to generate RRR for this payment, kindly click on
                                <em class="text-primary">Pay Via RRR</em> button.
                            </h3>
                        </li>
                    </ul>
                </div>
                <div class="col-lg-12">
                    <hr class="my-4" v-if="showPaymentForm">

                    <form :action="remitaPaymentUrl" method="POST"
                        v-if="showPaymentForm"
                    >
                        <input
                            type="hidden"
                            v-for="item in paymentRecords"
                            :name="item.title"
                            :value="item.value"
                            :key="item.title"
                        />


                        <base-button
                            type="success"
                            nativeType="submit"
                        >
                            <i class="fa fa-credit-card"></i>
                            {{ isloading ? 'Please Wait...' : 'Pay Now'}}
                        </base-button>

                        <div class="right">
                            <base-button
                                type="primary"
                                @click="addPayment(orderId)"
                            >
                                <i class="fa fa-plus"></i>
                                Pay Via RRR
                            </base-button>
                        </div>

                    </form>

                    <base-button
                        type="success"
                        @click="createApplication()"
                        v-if="! showPaymentForm"
                    >
                        <i class="fa fa-reply"></i>
                        {{ isloading ? 'Please Wait...' : 'Apply Now'}}
                    </base-button>
                </div>
            </div>
        </div>

        <AddPayment
            v-bind:orderNumber="orderNumber"
            v-bind:app_id="applicationId"
            v-bind:serviceType="serviceType"
            v-bind:applicationName="application.name"
            v-bind:applicationCode="application.code"
            v-bind:applicationAmount="totalAmount"
            v-on:removeAddPayment="removeAddPayment"
            v-bind:removeAddPayment="removeAddPayment"
            v-if="showAddPayment"
        />

    </div>

</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import AddPayment from './AddPayment.vue';
    import swal from 'sweetalert2';// import sweet alert 

    export default {
        components: {
            AddPayment
        },
        name: 'LogsPayment',
        data() {
            return {
                id: this.$route.params.id,
                application: null,
                postageFee: null,
                isLoadingBlade: true,
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isloading: false,
                applicationId: null,
                orderId: null,
                serviceType: null,
                showAddPayment: false,
                user: {},
                orderNumber: null,
                showPaymentForm: false
            }
        },
        filters: {
            formatPrice(value) {
                if(value) {
                    let val = (value/1).toFixed(2).replace(',', '.');
                    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                } else {
                    return 'N/A';
                }
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl', 'remitaPaymentUrl', 'remitaMerchantId',
                'remitaPaymentCallbackUrl', 'remitaApiKey']),
            gethash() {
                var sha512 = require('js-sha512');
                let text = this.remitaMerchantId+this.serviceType.remita_code+this.orderId
                    +this.totalAmount+this.remitaPaymentCallbackUrl
                    +this.remitaApiKey;
                return sha512(text);
            },
            paymentRecords() {// get payment records
                let fullName = this.user.basic_profile.first_name+' '+this.user.basic_profile.last_name;
                return [
                    {'title' : 'merchantId', 'value' : this.remitaMerchantId},
                    {'title' : 'serviceTypeId', 'value' : this.serviceType.remita_code},
                    {'title' : 'amt', 'value' : this.totalAmount},
                    {'title' : 'responseurl', 'value' : this.remitaPaymentCallbackUrl},
                    {'title' : 'hash', 'value' : this.gethash},
                    {'title' : 'payerName', 'value' : fullName},
                    {'title' : 'paymenttype', 'value' : ''},
                    {'title' : 'payerEmail', 'value' : this.user.email},
                    {'title' : 'payerPhone', 'value' : this.user.basic_profile.phone},
                    {'title' : 'orderId', 'value' : this.orderId},
                ]
            },
            totalAmount() {
                return (this.postageFee && this.postageFee.fee ? (parseInt(this.application.has_payment.amount) +
                    parseInt(this.postageFee.fee)) : parseInt(this.application.has_payment.amount))
            }
        },
        methods: {
            ...mapActions(['getApplication', 'getUserPendingLog', 'saveApplicationTransaction',
                'createUserApplication', 'getUserApplicationDetails', 'updateTransNo']),
            loadApplication() {// get application
                let userId = this.$store.state.user.userId;// get user id
                this.getUserPendingLog({'id' : userId}).then((res) => {// get pending Log
                    this.applicationId = res.application.id;
                    // this.orderId = res.application.trans_no;
                    this.getUserApplicationDetails(this.id).then((res) => {// get application
                        this.application = res.application;
                        this.serviceType = res.service_type;
                        this.user = res.user;
                        this.postageFee = res.postage_fee;
                        this.isLoadingBlade = false;
                    }).
                    catch(() => {
                        this.isLoadingBlade = false;
                        this.showErrorStatus = true;
                        this.errorMsg = "Network Failure!", "Kindly reload the page...";
                        this.errorType = 'danger';
                    });
                }).
                catch(() => {
                    this.isLoadingBlade = false;
                    this.showErrorStatus = true;
                    this.errorMsg = "Network Failure!", "Kindly reload the page...";
                    this.errorType = 'danger';
                });
            },
            createApplication() {// get application details
                this.preloader();// show loading
                this.updateTransNo({'id' : this.applicationId}).then((res) => {//update trans no
                    // console.log(res);
                    this.orderId = res.trans_no;
                    this.showPaymentForm = true;
                    let message = 'Kindly click on Pay Now to make payment online';
                    swal.fire('Application Updated!', message, 'success');
                }).
                catch(() => {
                    let message = 'Network Failure!, kindly reload the page...';
                    swal.fire('Network Failure!', message, 'warning');
                });
            },
            addPayment(number) {// add RRR
                this.orderNumber = number;
                this.showAddPayment = true;
            },
            removeAddPayment() {// remove add payment
                this.loadApplication();// load application
                return this.showAddPayment = false;
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        created() {
            this.loadApplication();// load application
        },
    }
</script>

<style scoped>
.right {
    float: right;
}
</style>