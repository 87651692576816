var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('base-header',{staticClass:"header pb-8 pt-5 pt-lg-8 d-flex align-items-center"},[_c('span',{staticClass:"mask bg-gradient-danger opacity-8"})]),_c('div',{staticClass:"container-fluid mt--7"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-12 order-xl-1"},[_c('card',{attrs:{"shadow":"","type":"secondary"}},[_c('div',{staticClass:"bg-white border-0",attrs:{"slot":"header"},slot:"header"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-12"},[_c('h3',{staticClass:"mb-0"},[_vm._v("Update Account Information")]),_c('br'),_c('span',{staticClass:"text-danger"},[_vm._v(" Note that you have to provide correct information, so that your account can be updated. Also check the spelling of your names and make sure they are correct, after this update, you may not be able to correct some of the information except you contact MDCN. Read carefully and provide correct information, you can also do this at your convenience time. ")])])])]),(_vm.isLoadingBlade)?_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"d-flex justify-content-center"},[_c('img',{attrs:{"src":_vm.loadingBladeUrl}})])]):_vm._e(),(_vm.profileInfo && _vm.profileInfo.hasCompleteBioData
                            && ! _vm.profileInfo.hasCompleteBioData.status)?_c('UpdateBioData',{attrs:{"bioData":_vm.profileInfo.hasCompleteBioData.records,"hasEmptyData":_vm.hasEmptyData}}):_vm._e(),(_vm.profileInfo && ! _vm.profileInfo.hasEducationalRecord
                            && _vm.profileInfo.hasCompleteBioData
                            && _vm.profileInfo.hasCompleteBioData.status)?_c('UpdateEduRecords',{attrs:{"hasEmptyData":_vm.hasEmptyData}}):_vm._e(),(_vm.profileInfo && ! _vm.profileInfo.hasSpecialization
                            && _vm.profileInfo.hasEducationalRecord
                            && _vm.profileInfo.hasCompleteBioData
                            && _vm.profileInfo.hasCompleteBioData.status)?_c('UpdateSpecialization',{attrs:{"hasEmptyData":_vm.hasEmptyData}}):_vm._e(),(_vm.profileInfo && ! _vm.profileInfo.hasProfilePhoto
                            && _vm.profileInfo.hasEducationalRecord
                            && _vm.profileInfo.hasCompleteBioData
                            && _vm.profileInfo.hasCompleteBioData.status
                            && _vm.profileInfo.hasSpecialization)?_c('UpdateProfilePhoto',{attrs:{"hasEmptyData":_vm.hasEmptyData}}):_vm._e(),(_vm.profileInfo && _vm.profileInfo.hasCompleteDocuments
                            && ! _vm.profileInfo.hasCompleteDocuments.status
                            && _vm.profileInfo.hasCompleteBioData.status
                            && _vm.profileInfo.hasEducationalRecord
                            && _vm.profileInfo.hasSpecialization
                            && _vm.profileInfo.hasProfilePhoto )?_c('UpdateDocuments',{attrs:{"documents":_vm.profileInfo.hasCompleteDocuments.records,"hasEmptyData":_vm.hasEmptyData}}):_vm._e()],1)],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }