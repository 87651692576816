<template>
    <div>
        <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center">
            <span class="mask bg-gradient-danger opacity-8"></span>
        </base-header>


        <div class="container-fluid mt--7">
            <div class="row">

                <div class="col-xl-12 order-xl-1">
                    <card shadow type="secondary">
                        <div slot="header" class="bg-white border-0">
                            <div class="row align-items-center">
                                <div class="col-8">
                                    <h3 class="mb-0">
                                        Positngs for
                                        <em class="text-capitalize">
                                            {{appointment.hospital_name}}
                                        </em>
                                    </h3>
                                </div>
                                <div class="col-4 text-right">
                                    <router-link
                                        to="/professional-data"
                                        class="btn btn-sm btn-danger"
                                    >
                                        <i class="fa fa-reply"></i> Back
                                    </router-link>
                                </div>
                            </div>
                        </div>

                        <div class="pl-lg-4">
                            <h6 class="heading-small text-muted mb-4">
                                Below are your postings information:
                                <base-button
                                    class="float-right"
                                    :type="showAddRecord ? 'danger' : 'success'"
                                    size="sm"
                                    @click="displayNewRecord()"
                                >
                                    <i class="fa fa-plus-circle" v-if="! showAddRecord"></i>
                                    <i class="fa fa-times" v-if="showAddRecord"></i>
                                    {{showAddRecord ? 'Cancel' : 'Add New Record' }}
                                </base-button>
                            </h6>

                            <hr class="my-4" v-if="showAddRecord"/>

                            <div class="row align-items-center" v-if="showAddRecord">
                                <div class="col-lg-12">
                                    <base-alert :type="errorType" dismissible v-if='showInputErrorStatus'>
                                        <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                                        <span class="alert-inner--text">
                                            {{errorMsg}}
                                        </span>
                                        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </base-alert>
                                </div>

                                <div class="col-lg-6">
                                    <base-input alternative=""
                                        label="Supervising Consultant Name"
                                        placeholder="Enter Supervising Consultant Name"
                                        input-classes="form-control-alternative"
                                        v-model="posting.consultant_name"
                                        required
                                    />
                                </div>
                                <div class="col-lg-6">
                                    <base-input alternative=""
                                        label="Supervising Consultant Qualification"
                                        placeholder="Enter Supervising Consultant Qualification"
                                        input-classes="form-control-alternative"
                                        v-model="posting.consultant_qualification"
                                        required
                                    />
                                </div>
                                <div class="col-lg-4">
                                    <base-input alternative=""
                                        label="Department"
                                        required
                                    >
                                        <select
                                            class="form-control form-control-alternative"
                                            v-model="posting.posting_department_id"
                                        >
                                            <option
                                                :value="null"
                                                disabled
                                            >
                                                --- Select One ---
                                            </option>
                                            <option
                                                v-show="postingDepartments && postingDepartments.length > 0"
                                                v-for="department in postingDepartments"
                                                :key="department.id"
                                                :value="department.id"
                                            >
                                                {{department.name}}
                                            </option>
                                        </select>
                                    </base-input>
                                </div>

                                <div class="col-lg-4">
                                    <base-input
                                        label="Start Date"
                                        addon-left-icon="ni ni-calendar-grid-58"
                                        required

                                    >
                                        <flat-picker
                                            slot-scope="{focus, blur}"
                                            @on-open="focus"
                                            @on-close="blur"
                                            :config="config"
                                            class="form-control datepicker"
                                            v-model="posting.start_date"
                                        >
                                        </flat-picker>
                                    </base-input>
                                </div>
                                <div class="col-lg-4">
                                    <base-input
                                        label="End  Date"
                                        addon-left-icon="ni ni-calendar-grid-58"
                                        required

                                    >
                                        <flat-picker
                                            slot-scope="{focus, blur}"
                                            @on-open="focus"
                                            @on-close="blur"
                                            :config="config"
                                            class="form-control datepicker"
                                            v-model="posting.end_date"
                                        >
                                        </flat-picker>
                                    </base-input>
                                </div>
                                <div class="col-lg-3">
                                    <br>
                                    <h4>
                                        Is this your current Posting?
                                    </h4>
                                </div>
                                <div class="col-lg-2">
                                    <br>
                                    <base-switch
                                        v-if="posting.current_status == 'no'"
                                        :value="false"
                                        @click="changeCurrentStatus()"
                                    ></base-switch>
                                    <base-switch
                                        v-if="posting.current_status == 'yes'"
                                        :value="true"
                                        @click="changeCurrentStatus()"
                                    ></base-switch>
                                </div>
                                <div class="col-lg-12"></div>
                                <div class="pl-lg-4">
                                    <br>
                                    <base-button
                                        type="success"
                                        @click="savePostingRecords()"
                                    >
                                        <i class="fa fa-save"></i>
                                        {{isloading ? 'Please Wait...' : 'Add Record'}}
                                    </base-button>
                                </div>
                            </div>

                            <!-- import posting details template here -->
                            <PositngData
                                v-bind:postings="postings"
                                v-bind:showAddRecord="showAddRecord"
                                v-on:deletePostingRecord="deletePostingRecord"
                            />

                            <div class="col-sm-12 text-center">
                                <base-alert :type="errorType" v-if='showErrorStatus'>
                                    <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                                    <span class="alert-inner--text">
                                        {{errorMsg}}
                                    </span>
                                </base-alert>
                            </div>

                        </div>

                        <!-- import Posting Details template here -->
                        <!-- <PositngData /> -->


                    </card>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    // get user's details from store
    import { mapGetters, mapActions } from 'vuex';
    // get components
    import PositngData from './PostingData.vue';
    import flatPicker from "vue-flatpickr-component";
    import "flatpickr/dist/flatpickr.css";
    import swal from 'sweetalert2';// import sweet alert

    export default {
        components: {
            PositngData,
            flatPicker
        },
        name: 'ProfessionalData',
        data() {
            return {
                postings: [],
                appointment: {},
                posting: {
                    posting_department_id: null,
                    consultant_name: null,
                    consultant_qualification: null,
                    current_status: 'no',
                    start_date: null,
                    end_date: null
                },
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isloading: false,
                showAddRecord: false,
                showInputErrorStatus: false,
                config: {
                    allowInput: true,
                    altInput: true,// eslint-disable-next-line
                    plugins: [new monthSelectPlugin({shorthand: true, dateFormat: "Y-m-d", altFormat: "M Y"})]
                }
            };
        },
        computed: {
            ...mapGetters(['lock', 'locations', 'postingDepartments'])
        },
        methods: {
            ...mapActions(['getAppointmentPostings', 'getPostingDepartments', 'savePosting',
                'deletePosting']),
            loadPostings() {// load posting
                const id = this.$route.params.id;
                this.getAppointmentPostings({'id' : id}).then((res) => {
                    this.appointment = res.appointment;
                    this.postings = res.appointment.has_postings;
                    this.emptyRecordsMsg();//check for empty records
                });
                this.getPostingDepartments();// load departments
            },
            displayNewRecord() {// show new record
                this.emptyRecords();// empty edu records
                if(this.postings && this.postings.length == 0) {// hide empty msg
                    this.showEmptyMsg();
                    this.showErrorStatus = this.showAddRecord;
                }
                return this.showAddRecord = ! this.showAddRecord;
            },
            emptyRecordsMsg() {
                return this.postings && this.postings.length == 0 && (!this.showAddRecord)
                    ? this.showEmptyMsg() : false;
            },
            showEmptyMsg() {// show empty msg
                this.showErrorStatus = true;
                this.errorType = 'warning';
                this.errorMsg = 'No Posting Records Yet!, kindly add one';
            },
            emptyRecords() {
                this.posting.posting_department_id = null;
                this.posting.consultant_name = null;
                this.posting.consultant_qualification = null;
                this.posting.current_status = 'no';
                this.posting.start_date = null;
                this.posting.end_date = null;
            },
            savePostingRecords() {// save new posting records
                this.isloading = true;
                let data = this.validatedInputs();// validate inputs
                // console.log(data)
                if(data) {// save edu records
                    data.user_appointment_id = this.appointment.id;// get appointment id
                    // console.log(data)
                    this.savePosting(data).then((res) => {
                        this.isloading = false;
                        this.loadPostings();
                        if(res.status) {
                            this.displayNewRecord();
                            this.showErrorStatus = false;
                        } else {
                            this.showErrorMsg();
                            this.emptyRecords();
                        }
                    }).
                    catch(() => {
                        this.isloading = false;
                        this.loadPostings();
                        this.emptyRecords();
                        this.showErrorMsg();
                    });
                } else {
                    this.isloading = false;
                    this.loadPostings();
                    // this.emptyRecords();
                    // this.showErrorMsg();
                }
            },
            showErrorMsg() {
                this.showInputErrorStatus = true
                this.errorType = 'warning'
                this.errorMsg = 'An error occurred, kindly reload page and try again';
                var self = this;
                setTimeout(function() { self.showInputErrorStatus = false; }, 5000);
            },
            validatedInputs() {
                let data = this.posting;
                let postingRecord = { posting_department_id: null, consultant_name: null,
                    consultant_qualification: null, current_status: null, start_date: null, end_date: null };
                postingRecord.current_status  = data.current_status == null ?
                    this.showInuptError('Current Status') : data.current_status;
                postingRecord.end_date  = data.end_date == null ?
                    this.showInuptError('Posting\'s End Date') : data.end_date;
                postingRecord.start_date  = data.start_date == null ?
                    this.showInuptError('Posting\'s Start Date') : data.start_date;
                postingRecord.posting_department_id = data.posting_department_id == null ?
                    this.showInuptError('Posting Department') : data.posting_department_id;
                postingRecord.consultant_qualification  = data.consultant_qualification == null ?
                    this.showInuptError('Consultant\'s Qualification') : data.consultant_qualification;
                postingRecord.consultant_name  = data.consultant_name == null ?
                    this.showInuptError('Consultant\'s Name') : data.consultant_name;
                return this.isEmpty(postingRecord) ? postingRecord : false;
            },
            isEmpty(obj) {// check for empty field
                return ! Object.keys(obj).some(k => !obj[k]);
            },
            showInuptError(name) {
                this.isloading = false;
                this.showInputErrorStatus = true
                this.errorType = 'warning'
                this.errorMsg = 'Invalid ' + name + ' has been entered';
                var self = this;
                setTimeout(function() { self.showInputErrorStatus = false; }, 5000);
            },
            changeCurrentStatus() {// change Current Status switch
                const status = this.posting.current_status == "yes" ? "no" : "yes";
                this.posting.current_status = status;
            },
            deletePostingRecord(id) {// delete User EduRecord function
                this.preloader();// show loading
                this.deletePosting({'id' : id}).then((res) => {
                    if(res.status) {
                        swal.close();
                        this.loadPostings(); // load postings
                    } else {
                        swal.fire("Error Occurred!", "An error occurred while deleting, try again", "warning");
                        this.loadPostings(); // load postings
                    }
                }).
                catch(() => {
                    swal.fire("Network Failure", "Reload page and try again...", "warning");
                    this.loadPostings(); // load postings
                });
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b> ...',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        created() {
            // this.loadEducationalRecords();// load educational records
            this.loadPostings();// load postings
        }
    };
</script>
<style scoped>
    .width600 {
        width: 600px !important;
    }
    .marginLeft23 {
        margin-left: 23px;
    }
    .image-size {
        height: 180px;
        width: 190px;
    }
    .float-right {
        float: right;
    }
</style>
<style>
    .form-control-label {
        width: 100% !important;
    }
</style>