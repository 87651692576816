<template>
    <div>
        <div v-if="! isLoadingBlade && ! showErrorStatus">
            <h3 class="text-uppercase">Personal Data</h3>
            <hr>
            <div class="row">
                <div class="col-lg-3">
                    <base-input alternative=""
                        label="RRR"
                        input-classes="form-control-alternative"
                        v-model="rrr"
                        required
                        readonly
                    />
                </div>

                <div class="col-lg-3">
                    <base-input alternative=""
                        label="Surname"
                        placeholder="Enter Surname"
                        input-classes="form-control-alternative"
                        v-model="record.surname"
                        required
                    />
                </div>

                <div class="col-lg-3">
                    <base-input alternative=""
                        label="First Name"
                        placeholder="Enter First Name"
                        input-classes="form-control-alternative"
                        v-model="record.first_name"
                        required
                    />
                </div>

                <div class="col-lg-3">
                    <base-input alternative=""
                        label="Other Name"
                        placeholder="Enter Other Name"
                        input-classes="form-control-alternative"
                        v-model="record.other_name"
                    />
                </div>

                <div class="col-lg-3">
                    <base-input alternative=""
                        label="Previous Name"
                        placeholder="Enter Previous Name"
                        input-classes="form-control-alternative"
                        v-model="record.previous_name"
                    />
                </div>

                <div class="col-lg-3">
                    <base-input alternative=""
                        label="Gender"
                        required
                    >
                        <select
                            class="form-control form-control-alternative"
                            v-model="record.gender"
                        >
                            <option :value="null" disabled selected>--- Select One ---</option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                        </select>
                    </base-input>
                </div>

                <div class="col-lg-3">
                    <base-input alternative=""
                        label="Phone Number"
                        placeholder="Phone Number"
                        input-classes="form-control-alternative"
                        required
                    >
                    <!-- v-model="profile.phone"  -->
                        <VuePhoneNumberInput
                            v-model="tel.phone"
                            @update="updatePhoneNumber"
                            :default-country-code="tel.code"
                            :no-example="true"
                            :clearable="true"
                        />
                    </base-input>
                </div>

                <div class="col-lg-3">
                    <base-input alternative=""
                        label="Email Address"
                        placeholder="Enter Email Address"
                        input-classes="form-control-alternative"
                        v-model="record.email"
                        type="email"
                        required
                    />
                </div>

                <div class="col-lg-4">
                    <base-input alternative=""
                        label="Marital Status"
                        required
                    >
                        <select
                            class="form-control form-control-alternative"
                            v-model="record.marital_status"
                        >
                            <option :value="null" disabled selected>--- Select One ---</option>
                            <option value="single">Single</option>
                            <option value="married">Married</option>
                            <option value="divorced">Divorced</option>
                            <option value="widow">Widow</option>
                        </select>
                    </base-input>
                </div>

                <div class="col-lg-4">
                    <base-input
                        label="Date of Birth"
                        addon-left-icon="ni ni-calendar-grid-58"
                        required
                    >
                        <flat-picker
                            slot-scope="{focus, blur}"
                            @on-open="focus"
                            @on-close="blur"
                            :config="{allowInput: true}"
                            placeholder="Select Date.."
                            class="form-control datepicker"
                            v-model="record.date_of_birth"
                        >
                        </flat-picker>
                    </base-input>
                </div>

                <div class="col-lg-4">
                    <base-input alternative=""
                        label="Place of Birth"
                        placeholder="Enter Place of Birth"
                        input-classes="form-control-alternative"
                        v-model="record.place_of_birth"
                        required
                    />
                </div>

                <div class="col-lg-12">
                    <hr>
                </div>

                <div class="col-lg-3">
                    <base-input alternative=""
                        label="Nationality"
                        required
                    >
                        <autocomplete
                            v-if="locations.countries && locations.countries.length > 0"
                            input-class="form-control form-control-alternative"
                            placeholder="Type to Search for Nationality"
                            showNoResults
                            :source="locations.countries"
                            v-model="record.nationality"
                            :initialDisplay="(record.has_country ? record.has_country.name : '')"
                            @selected="changeCountry"
                        >
                        </autocomplete>
                    </base-input>
                </div>

                <div class="col-lg-3" v-if="record.nationality && showForeignState">

                    <base-input alternative=""
                        label="State/Province of Origin"
                        required
                    >
                        <autocomplete
                            ref="autocomplete"
                            :disableInput="cities && cities.length == 0"
                            input-class="form-control form-control-alternative"
                            placeholder="Type to Search State/Province of Origin"
                            showNoResults
                            :source="cities"
                            :initialValue="record.foreign_state"
                            :initialDisplay="record.foreign_state"
                            resultsValue="name"
                            @selected="changeCity"
                        >
                        </autocomplete>
                    </base-input>
                </div>

                <div class="col-lg-3" v-if="displayOtherState">
                    <base-input alternative=""
                        label="Other State/Province of Origin"
                        placeholder="Enter Other State/Province of Origin"
                        input-classes="form-control-alternative"
                        v-model="other_foreign_state"
                        required
                    />
                </div>

                <div class="col-lg-3" v-if="! showForeignState && locations && locations.states">
                    <base-input alternative=""
                        label="State of Origin"
                        required
                    >
                        <autocomplete
                            v-if="locations.states && locations.states.length > 0"
                            input-class="form-control form-control-alternative"
                            placeholder="Type to Search for State of Origin"
                            showNoResults
                            :source="locations.states"
                            v-model="record.state_origin"
                            :initialDisplay="(record.has_state ? record.has_state.name : '')"
                            @selected="getStateLgas"
                        >
                        </autocomplete>
                    </base-input>
                </div>

                <div class="col-lg-3" v-if="record.state_origin && ! showForeignState">
                    <base-input alternative=""
                        label="LGA of Origin"
                        required
                    >
                        <autocomplete
                            v-if="lgas && lgas.length > 0"
                            input-class="form-control form-control-alternative"
                            placeholder="Type to Search for LGA of Origin"
                            showNoResults
                            :source="lgas"
                            v-model="record.lga_origin"
                            :initialDisplay="(record.has_lga ? record.has_lga.name : '')"
                        >
                        </autocomplete>
                    </base-input>
                </div>

                <div class="col-lg-3" v-if="record.nationality">
                    <base-input alternative=""
                        label="Home Town"
                        placeholder="Enter Home Town"
                        input-classes="form-control-alternative"
                        v-model="record.home_town"
                        required
                    />
                </div>

                <div class="col-lg-12">
                    <hr>
                </div>

                <div class="col-lg-12">
                    <base-input alternative=""
                        label="Permanent Home Address"
                        required
                    >
                        <textarea
                            rows="2"
                            class="form-control form-control-alternative"
                            placeholder="Enter Permanent Home Address"
                            v-model="record.permanent_address"
                        ></textarea>
                    </base-input>
                </div>

                <div class="col-lg-12">
                    <base-input alternative=""
                        label="Residential Address (In Nigeria)"
                        required
                    >
                        <textarea
                            rows="2"
                            class="form-control form-control-alternative"
                            placeholder="Enter Residential Address"
                            v-model="record.residential_address"
                        ></textarea>
                    </base-input>
                </div>

                <div class="col-lg-12">
                    <base-input alternative=""
                        label="Full Business Address"
                    >
                        <textarea
                            rows="2"
                            class="form-control form-control-alternative"
                            placeholder="Enter Full Business Address"
                            v-model="record.business_address"
                        ></textarea>
                    </base-input>
                </div>

                <div class="col-lg-12">
                    <base-input alternative=""
                        label="Contact Address"
                        required
                    >
                        <textarea
                            rows="2"
                            class="form-control form-control-alternative"
                            placeholder="Enter Contact Address"
                            v-model="record.contact_address"
                        ></textarea>
                    </base-input>
                </div>


                <div class="col-lg-12">
                    <hr>
                </div>

                <div class="col-lg-3">
                    <base-input
                        label="Date of Return to Nigeria"
                        addon-left-icon="ni ni-calendar-grid-58"
                        required
                    >
                        <flat-picker
                            :disabled="record.nationality && showForeignState"
                            slot-scope="{focus, blur}"
                            @on-open="focus"
                            @on-close="blur"
                            :config="{allowInput: true}"
                            placeholder="Select Date.."
                            class="form-control datepicker"
                            v-model="record.return_date"
                        >
                        </flat-picker>
                    </base-input>
                </div>

                <div class="col-lg-3">
                    <base-input
                        label="Date of Entry into Nigeria"
                        addon-left-icon="ni ni-calendar-grid-58"
                        required
                    >
                        <flat-picker
                            :disabled="record.nationality && ! showForeignState"
                            slot-scope="{focus, blur}"
                            @on-open="focus"
                            @on-close="blur"
                            :config="{allowInput: true}"
                            placeholder="Select Date.."
                            class="form-control datepicker"
                            v-model="record.entry_date"
                        >
                        </flat-picker>
                    </base-input>
                </div>

                <div class="col-lg-3">
                    <base-input alternative=""
                        label="Assessment Exam Category"
                        required
                    >
                        <select
                            class="form-control form-control-alternative"
                            v-model="record.assessment_type_id"
                        >
                            <option :value="null" disabled selected>--- Select One ---</option>
                            <option 
                                :value="assessmentType.id"
                                v-for="assessmentType in assessmentTypes"
                                :key="assessmentType.id"
                            >
                                {{ assessmentType.title + ' (' + assessmentType.description + ')' }}
                            </option>
                        </select>
                    </base-input>
                </div>

            </div>
        </div>

        <div class="col-lg-12" v-if="isLoadingBlade">
            <div class="d-flex justify-content-center">
                <img :src="loadingBladeUrl" />
            </div>
        </div>

        <div class="col-lg-12 text-center" v-if='(showErrorStatus || showInputErrorStatus) && ! isLoadingBlade'>
            <base-alert :type="errorType">
                <!-- <span class="alert-inner--icon">
                    320541287149
                    <i class="ni ni-like-2"></i></span> -->
                <span class="alert-inner--text">
                    <h2 class="text-white">{{errorMsg}}</h2>
                </span>
            </base-alert>
        </div>

        <div class="col-lg-12" v-if="! isLoadingBlade">
            <base-button
                type="primary"
                :disabled="! rrr"
                @click="savePersonalData(false)"
            >
                <i class="fa fa-save"></i>
                {{ isloading ? 'Please Wait...' : 'Save &amp; Continue Later'}}
            </base-button>
            <base-button
                type="success"
                class="right"
                :disabled="! rrr"
                @click="savePersonalData(true)"
            >
                {{ isloading ? 'Please Wait...' : 'Save &amp; Continue'}}
                <i class="fa fa-arrow-right"></i>
            </base-button>
        </div>

    </div>
</template>

<script>
    import flatPicker from "vue-flatpickr-component";
    import "flatpickr/dist/flatpickr.css";
    import VuePhoneNumberInput from 'vue-phone-number-input';
    import 'vue-phone-number-input/dist/vue-phone-number-input.css';
    import { mapActions, mapGetters } from 'vuex';
    import swal from 'sweetalert2';// import sweet alert
    import Autocomplete from 'vuejs-auto-complete';
    import states from '../../../../states.json';

    export default {
        name: 'AssessmentPersonalData',
        props: ['displayProfilePhoto', 'applicant', 'loadApplicantInfo'],
        components: {
            flatPicker,
            VuePhoneNumberInput,
            Autocomplete
        },
        data() {
            return {
                locations: [],
                isLoadingBlade: true,
                isloading: false,
                showInputErrorStatus: false,
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                rrr: null,
                record: {
                    surname: null,
                    first_name: null,
                    other_name: null,
                    previous_name: null,
                    gender: null,
                    phone: null,
                    marital_status: null,
                    date_of_birth: null,
                    place_of_birth: null,
                    nationality: null,
                    state_origin: null,
                    lga_origin: null,
                    foreign_state: null,
                    home_town: null,
                    permanent_address: null,
                    residential_address: null,
                    business_address: null,
                    contact_address: null,
                    return_date: null,
                    entry_date: null,
                    email: null,
                    phone_code: null,
                    assessment_type_id: null
                },
                other_foreign_state: null,
                displayOtherState: false,
                states: states,
                cities: [],
                lgas: [],
                tel: {
                    phone: null,
                    code: 'NG',
                },
                id: localStorage.getItem('applicantId')
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl']),
            showForeignState() {
                return this.record.nationality == 164 ? false : true;
            },
            assessmentTypes() {// get assessment exam application types
                return this.applicant.assessment_types;
            }
        },
        methods: {
            ...mapActions(['saveAssessmentApplicantData', 'getLocations']),
            changeCity(e) {
                let data = e.selectedObject;
                this.other_foreign_state = null;
                if(data.id == 0) {
                    this.record.foreign_state = null;
                    this.displayOtherState = true;
                } else {
                    this.displayOtherState = false;
                    this.record.foreign_state = data.name;
                }
            },
            changeCountry(e) {
                this.cities = [];
                this.record.nationality = null;
                let data = e.selectedObject;
                this.record.nationality = data.id;
                if(data.id == 164){
                    this.record.entry_date = null
                } else {
                    this.record.return_date = null
                }// get cities
                let other = { id : 0, name: 'Others (Specify)'};
                this.cities = this.states.filter(item => item.country_code == data.code);
                this.cities.push(other);
            },
            setPersonalData() {// set applicant data
                this.displayOtherState = false;
                this.showInputErrorStatus = false;
                this.emptyFields();
                if(this.applicant) {
                    this.rrr = this.applicant.rrr;
                    if(this.applicant.personal_data) {
                        this.record = this.applicant.personal_data
                        this.loadPhoneNumber();// update phone no
                    } else {
                        this.emptyFields();
                    }
                    this.record = this.applicant.personal_data ? this.applicant.personal_data : this.emptyFields();
                    this.getLocations().then((res) => {
                        this.locations = res;
                        this.getStateLgas();
                        this.isLoadingBlade = false;
                    }).
                    catch(() => {
                        this.isLoadingBlade = false;
                        let msg = "Network Failure!, kindly reload the page...";
                        swal.fire("Error Ocurred!", msg, "warning");
                    });
                }
            },
            loadPhoneNumber() {// update phone no
                let data = this.record.phone_code;
                let exploded = data ? data.split('-') : false;
                this.tel.code = exploded && exploded[0] ? exploded[0] : 'NG';
                this.tel.phone = exploded && exploded[1] ? exploded[1] : null;
            },
            savePersonalData(type) {
                this.isloading = true;
                this.showInputErrorStatus = false;
                this.preloader();// show loading
                let status = this.validatedData();// valid input data
                if(status) {// update
                    this.record.foreign_state = this.record.foreign_state ? this.record.foreign_state :
                                                    this.other_foreign_state;// update foreign state
                    let data = { 'personal_data' : this.record , 'id' : this.id };
                    this.saveAssessmentApplicantData(data).then((res) => {
                        this.loadApplicantInfo();
                        this.isloading = false;
                        if(res.status) {// redirect when it is false
                            if(type) {
                                this.displayProfilePhoto(true)
                                swal.fire("Profile Updated!", res.msg, "success");
                            } else {
                                let msg = 'Application was updated successfully, you can continue later';
                                swal.fire("Application Updated!", msg, "success");
                                return window.location.href = '/assessment-exam';
                            }
                        } else {
                            let msg = "Payment details could not be found, reload page";
                            swal.fire("Error Ocurred!", msg, "warning");
                        }
                    }).
                    catch(() => {
                        this.isloading = false;
                        let msg = "Network Failure!, kindly reload the page...";
                        swal.fire("Error Ocurred!", msg, "warning");
                    });
                }   else {
                    this.isloading = false;
                    swal.close();
                }
            },
            updateNationality() {
                if(this.record.nationality == 164) {
                    this.record.foreign_state = null
                    this.record.entry_date = null;
                } else {
                    this.record.state_origin = null;
                    this.record.lga_origin = null;
                    this.record.return_date = null;
                }
            },
            validatedData() {
                this.updateNationality();// update nation
                let entryDate = true;
                let returnDate = true;
                let foreignState = true;
                let stateOrigin = true;
                let lgaOrigin = true;
                let data = this.record;
                data.assessment_type_id != null ? data.assessment_type_id : this.showInuptError('Assessment Exam Category');                
                data.contact_address != null ? data.contact_address : this.showInuptError('Contact Address');
                data.residential_address != null ? data.residential_address : this.showInuptError('Residential Address');
                data.permanent_address != null ? data.permanent_address : this.showInuptError('Permanent Address');
                if(data.nationality && (data.nationality != 164)&& (data.entry_date != '') && data.entry_date) {// entry date
                    entryDate = true;
                } else {
                    if(data.return_date ) {
                        entryDate = true;
                    } else {
                        entryDate = false;
                        this.showInuptError('Date of Entry into Nigeria')
                    }
                }
                if(data.nationality && (data.nationality == 164) && (data.return_date != '') && data.return_date) {// return date
                    returnDate = true;
                } else {
                    if(data.entry_date ) {
                        returnDate = true;
                    } else {
                        returnDate = false;
                        this.showInuptError('Date of Return into Nigeria')
                    }
                }
                data.home_town != null ? data.home_town : this.showInuptError('Hometown');
                if(data.nationality && (data.nationality != 164) && (data.foreign_state || this.other_foreign_state)) {// return date
                    foreignState = true;
                } else if(data.nationality && (data.nationality == 164) && ! data.foreign_state) {
                    foreignState = true;
                } else {
                    foreignState = false;
                    this.showInuptError('State/Province of Origin')
                }
                if(data.nationality && (data.nationality == 164) && data.lga_origin) {// return date
                    lgaOrigin = true;
                } else if(data.nationality && (data.nationality != 164) && ! data.lga_origin) {
                    lgaOrigin = true;
                } else {
                    lgaOrigin = false;
                    this.showInuptError('LGA of Origin')
                }
                if(data.nationality && (data.nationality == 164) && data.state_origin) {// return date
                    stateOrigin = true;
                } else if(data.nationality && (data.nationality != 164) && ! data.state_origin) {
                    stateOrigin = true;
                } else {
                    stateOrigin = false;
                    this.showInuptError('State of Origin')
                }
                data.nationality != null ? data.nationality : this.showInuptError('Nationality');
                data.place_of_birth != null ? data.place_of_birth : this.showInuptError('Place Of Birth');
                data.date_of_birth != null ? data.date_of_birth : this.showInuptError('Date Of Birth');
                data.marital_status != null ? data.marital_status : this.showInuptError('Marital Status');
                data.email != null ? data.email : this.showInuptError('Email Address');
                data.phone != null ? data.phone : this.showInuptError('Phone Number');
                data.gender != null ? data.gender : this.showInuptError('Gender');
                data.first_name != null ? data.first_name : this.showInuptError('First Name');
                data.surname != null ? data.surname : this.showInuptError('Surname');
                let status = data.surname && data.first_name && data.gender && data.phone && data.marital_status && data.date_of_birth 
                    && data.place_of_birth && data.nationality && data.home_town && data.permanent_address && data.residential_address && data.email 
                    && data.contact_address && data.assessment_type_id && entryDate && returnDate && foreignState && stateOrigin && lgaOrigin;
                return status ? true : false;
            },
            showInuptError(name) {
                this.isloading = false;
                this.showInputErrorStatus = true;
                this.errorType = 'warning'
                this.errorMsg = 'Invalid ' + name + ' has been entered';
                var self = this;
                setTimeout(function() { self.showInputErrorStatus = false; }, 5000);
            },
            emptyFields() {
                this.other_foreign_state = null;
                return this.record = {
                    surname: null,
                    first_name: null,
                    other_name: null,
                    previous_name: null,
                    gender: null,
                    phone: null,
                    marital_status: null,
                    date_of_birth: null,
                    place_of_birth: null,
                    nationality: null,
                    state_origin: null,
                    lga_origin: null,
                    foreign_state: null,
                    home_town: null,
                    permanent_address: null,
                    residential_address: null,
                    business_address: null,
                    contact_address: null,
                    return_date: null,
                    entry_date: null,
                    email: null,
                    phone_code: null,
                    assessment_type_id: null
                };
            },
            updatePhoneNumber(event) {
                if(event && event.isValid && event.e164) {
                    this.record.phone = event.formattedNumber;
                    this.record.phone_code = event.countryCode+'-'+event.phoneNumber;
                }
            },
            getStateLgas(e) {// get state's lgas
                let lgas = [];
                let stateId = e ? e.value : this.record.state_origin;
                for(let lga of this.locations.lgas) {
                    if (lga.state_id == stateId) {
                        lgas.push(lga);
                    }
                }
                return this.lgas = lgas;
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        created() {
            this.getLocations();
            this.setPersonalData();
        }
    }
</script>

<style scoped>
    .no-padding-bottom {
        padding-bottom: 0rem !important
    }
    .right {
        float: right !important;
    }
</style>

<style>
    .form-control-label {
        width: 100%;
    }
    .height-50 {
        height: 50px;
    }
    .borderLine {
        border: 1px solid #ededed;
    }
</style>