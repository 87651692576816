<template>
    <div>
        <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center">
            <span class="mask bg-gradient-danger opacity-8"></span>
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col-xl-12 order-xl-1">
                    <card shadow type="secondary">
                        <div slot="header" class="bg-white border-0">
                            <div class="row align-items-center">
                                <div class="col-12">
                                    <h3 class="mb-0">Update Account Information</h3>
                                    <br>
                                    <span class="text-danger">
                                        Note that you have to provide correct information, so that your account can be updated.
                                        Also check the spelling of your names and make sure they are correct, after this update,
                                        you may not be able to correct some of the information except you contact MDCN.
                                        Read carefully and provide correct information, you can also do this at your convenience time.
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-12" v-if="isLoadingBlade">
                            <div class="d-flex justify-content-center">
                                <img :src="loadingBladeUrl" />
                            </div>
                        </div>

                        <!-- Import Bio Data template here -->
                        <UpdateBioData
                            v-if="profileInfo && profileInfo.hasCompleteBioData
                                && ! profileInfo.hasCompleteBioData.status"
                            v-bind:bioData="profileInfo.hasCompleteBioData.records"
                            v-bind:hasEmptyData="hasEmptyData"
                        />

                        <!-- Import Edu Records template here -->
                        <UpdateEduRecords
                            v-if="profileInfo && ! profileInfo.hasEducationalRecord
                                && profileInfo.hasCompleteBioData
                                && profileInfo.hasCompleteBioData.status"
                            v-bind:hasEmptyData="hasEmptyData"
                        />

                        <!-- Import Specialization template here -->
                        <UpdateSpecialization
                            v-if="profileInfo && ! profileInfo.hasSpecialization
                                && profileInfo.hasEducationalRecord
                                && profileInfo.hasCompleteBioData
                                && profileInfo.hasCompleteBioData.status"
                            v-bind:hasEmptyData="hasEmptyData"
                        />

                        <!-- Import Appointments template here -->
                        <!-- <UpdateAppointment
                            v-if="profileInfo && ! profileInfo.hasAppointments
                                && profileInfo.hasEducationalRecord
                                && profileInfo.hasCompleteBioData
                                && profileInfo.hasCompleteBioData.status
                                && profileInfo.hasSpecialization"
                            v-bind:hasEmptyData="hasEmptyData"
                        /> -->

                        <!-- Import Profile Photo template here -->
                        <UpdateProfilePhoto
                            v-if="profileInfo && ! profileInfo.hasProfilePhoto
                                && profileInfo.hasEducationalRecord
                                && profileInfo.hasCompleteBioData
                                && profileInfo.hasCompleteBioData.status
                                && profileInfo.hasSpecialization"
                            v-bind:hasEmptyData="hasEmptyData"
                        />

                        <!-- Import Documents template here -->
                        <UpdateDocuments
                            v-if="profileInfo && profileInfo.hasCompleteDocuments
                                && ! profileInfo.hasCompleteDocuments.status
                                && profileInfo.hasCompleteBioData.status
                                && profileInfo.hasEducationalRecord
                                && profileInfo.hasSpecialization
                                && profileInfo.hasProfilePhoto "
                            v-bind:documents="profileInfo.hasCompleteDocuments.records"
                            v-bind:hasEmptyData="hasEmptyData"
                        />

                    </card>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import UpdateBioData from './UpdateBioData.vue';
    import UpdateDocuments from './UpdateDocuments.vue';
    import UpdateEduRecords from './UpdateEduRecords.vue';
    import UpdateSpecialization from './UpdateSpecialization.vue';
    import UpdateProfilePhoto from './UpdateProfilePhoto.vue';

    export default {
        components: {
            UpdateBioData,
            UpdateDocuments,
            UpdateEduRecords,
            UpdateSpecialization,
            UpdateProfilePhoto
        },
        name: 'UpdateProfile',
        data() {
            return {
                profileInfo: [],
                isLoadingBlade: true
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl'])
        },
        methods: {
            ...mapActions(['getUpdateProfileInfo', 'getHospitals']),
            hasEmptyData() {// get profile incompleteness
                this.getUpdateProfileInfo().then((res) => {
                    this.isLoadingBlade = false;
                    if(res.status) {// redirect to dashboard
                        this.$router.push({ name: 'dashboard' });
                    } else {
                        this.profileInfo = res;
                    }
                });
            },
        },
        created() {
            this.hasEmptyData();// get empty data
            this.getHospitals();// get hospitals
        },
    }
</script>