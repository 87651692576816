
<template>
    <div>
        <div class="col-lg-8" v-if="records && records.length > 0">
            <small class="font-weight-bold">
                Showing {{ pagination.from == 0 ? 1 : pagination.from }} - {{ pagination.to }} of
                {{ pagination.total }} entries
            </small>
        </div>

        <div
            class="card shadow"
            v-if="records && records.length > 0"
        >
            <div class="table-responsive">
                <base-table
                    class="table align-items-center table-flush"
                    thead-classes="thead-light"
                    tbody-classes="list"
                    :data="records"
                >
                    <template slot="columns">
                        <th width="2%">S/N</th>
                        <th width="15%">Doctor's Name</th>
                        <th width="10%">Practice Type <br>/ Gender</th>
                        <th width="10%">State / LGA <br>of Practice</th>
                        <th width="10%">Nature of <br>Sanction</th>
                        <th width="10%">Regulatory <br>Body</th>
                        <th width="10%">Sanctioned <br>Date</th>
                    </template>

                    <template
                        slot-scope="{row, index}"
                    >
                        <td class="budget">
                            {{ pagination.from+index}}
                        </td>
                        <td class="text-capitalize">
                            {{ getFullName(row) }}<br>
                            <badge
                                size="sm" type="primary"
                                class="font-weight-bolder"
                            >
                                {{ row.user_data && row.user_data.folio_number ? row.user_data.folio_number : 'M/A' }}
                            </badge>
                        </td>
                        <td>
                            {{ getPracticeTypeName(row) }}<br>
                            <badge
                                size="sm" type="primary"
                                class="font-weight-bolder"
                            >
                                {{ row.user_data ? row.user_data.gender : 'N/A' }}
                            </badge>
                        </td>
                        <td class="text-capitalize">
                            {{ row.user_data && row.user_data.has_practice_state
                                    ? row.user_data.has_practice_state.name : 'N/A' }}<br>
                            <badge
                                size="sm" type="primary"
                                class="font-weight-bolder"
                            >
                                {{ row.user_data && row.user_data.has_practice_lga
                                    ? row.user_data.has_practice_lga.name : 'N/A' }}
                            </badge>
                        </td>
                        <td class="text-capitalize">
                            {{ row.sanction_nature ? row.sanction_nature : 'N/A' }}
                        </td>
                        <td class="text-capitalize">
                            {{ row.regulatory_body ? row.regulatory_body : 'N/A' }}
                        </td>
                        <td>
                            {{ row.sanctioned_date | formatDate }}
                        </td>
                    </template>
                </base-table>
            </div>

            <div
                class="card-footer d-flex justify-content-end bg-transparent"
                v-if="hasPagination"
                    @click="paginate"
            >
                <base-pagination
                    :total="pagination.total"
                    align="center"
                    v-model="pagination.number"
                    :perPage="pagination.perPage"
                >
                </base-pagination>
            </div>
        </div>
    </div>
</template>
<script>
    import moment from 'moment';

    export default {
        name: 'SanctionsData',
        props: ['records', 'hasPagination', 'pagination', 'paginate'],
        filters: {
            formatDate(value) {
                return value ? moment(value).format('Do MMM, YYYY') : 'N/A';
            },
        },
        methods: {
            getFullName(data) {// get full name
                let user = data && data.user_data ? data.user_data : false;
                if(user) {
                    let name = user.first_name+' ';
                    name += user.other_name ? user.other_name+' ' : '';
                    name += user.last_name+' ';
                    return name.toLowerCase();
                } else {
                    return 'N/A';
                }
            },
            getPracticeTypeName(data) {// get practice type name
                let type = data && data.user_data && data.user_data.get_doctor_type
                            ? data.user_data.get_doctor_type : false;
                return (type && type.name) ? type.name : 'N/A';
            }
        }
    };
</script>
<style>
    .table td {
        font-size: 15px;
    }
</style>
