<template>
    <div>
        <!-- <h3>
            test 250332191070
        </h3> -->

        <div v-if="! showPaymentPage">
            <div class="row" v-if='! showErrorStatus && ! isLoadingBlade && batchStatus'>
                <div class="col-sm-12 text-uppercase text-center">
                    <h2 class="text-danger">
                        Note that you are to pay {{ record.percentage }}% of the current Assessment Exam Fee...
                    </h2>
                    <hr>
                </div>
                <div class="col-sm-6 text-center borderLine">
                    <br>
                    <h4 class="text-uppercase height-50">
                        I Want to pay the extra charge now
                    </h4>
                    <base-button
                        type="info"
                        class="btn btn-sm"
                        @click="displayPayment(true, 'pay')"
                    >
                        Click &amp; Continue
                        <i class="fa fa-arrow-right"></i>
                    </base-button>
                    <br><br><br>
                </div>

                <div class="col-sm-6 text-center borderLine">
                    <br>
                    <h4 class="text-uppercase height-50">
                        I have paid the extra charge and want to continue application
                    </h4>
                    <base-button
                        type="success"
                        class="btn btn-sm"
                        @click="displayPayment(true, 'rrr')"
                    >
                        Click &amp; Continue
                        <i class="fa fa-arrow-right"></i>
                    </base-button>
                    <br><br><br>
                </div>
            </div>

            <div class="row" v-if='! showErrorStatus && ! isLoadingBlade && ! batchStatus'>
                <div class="col-sm-12 text-uppercase text-center">
                    <h2 class="text-danger">
                        Note You are to provide the Extra Charge RRR (It must have been paid), before you can procced...
                    </h2>
                    <hr>
                </div>
                <div class="col-sm-3"></div>
                <div class="col-sm-6 text-center borderLine">
                    <br>
                    <h4 class="text-uppercase height-50">
                        I have paid the extra charge and want to continue application
                    </h4>
                    <base-button
                        type="success"
                        class="btn btn-sm"
                        @click="displayPayment(true, 'rrr')"
                    >
                        Click &amp; Continue
                        <i class="fa fa-arrow-right"></i>
                    </base-button>
                    <br><br><br>
                </div>
            </div>

            <div class="col-lg-12" v-if="isLoadingBlade">
                <div class="d-flex justify-content-center">
                    <img :src="loadingBladeUrl" />
                </div>
            </div>

            <div class="col-lg-12 text-center" v-if='showErrorStatus && ! isLoadingBlade'>
                <base-alert :type="errorType">
                    <!-- <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span> -->
                    <span class="alert-inner--text">
                        <h3 class="text-white">{{errorMsg}}</h3>
                    </span>
                </base-alert>
            </div>

            <div>
                <hr>
                <router-link
                    to="/assessment-exam"
                    class="btn btn-danger"
                >
                    <i class="fa fa-arrow-left"></i>
                    Go Back
                </router-link>
            </div>
        </div>

        <AssessmentExtraPayment
            :type="type"
            :record="record"
            :serviceType="serviceType"
            :country="country"
            v-on:displayPayment="displayPayment"
            v-if="showPaymentPage && (showPaymentType == 'pay') && record.percentage && type.batch_payment"
        />

        <AssessmentExtraRRR
            :record="record"
            :type="type"
            :country="country"
            v-on:displayPayment="displayPayment"
            v-if="showPaymentPage && (showPaymentType == 'rrr')"
        />

    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import AssessmentExtraPayment from './AssessmentExtraPayment';
    import AssessmentExtraRRR from './AssessmentExtraRRR';
    import swal from 'sweetalert2';// import sweet alert

    export default {
        name: 'AssessmentReRegisterPayment',
        props: ['record', 'type', 'serviceType', 'country', 'batchStatus'],
        components: {
            AssessmentExtraPayment,
            AssessmentExtraRRR
        },
        data() {
            return {
                category: null,
                rrr: null,
                isloading: false,
                categories: [],
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isLoadingBlade: false,
                showPaymentPage: false,
                showPaymentType: null
            }
        },
        computed: {
            ...mapGetters(['websiteUrl', 'loadingBladeUrl'])
        },
        methods: {
            ...mapActions(['getAssessmentRrrStatus', 'getAssessmentTypes']),
            clearStorage() {
                localStorage.removeItem('applicantId');
            },
            displayPayment(status, text = null) {
                this.showPaymentPage = false;
                this.showPaymentType = null;
                this.showPaymentPage = status;
                this.showPaymentType = text;
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        created() {
            this.clearStorage();
        }
    }
</script>

<style scoped>
    .no-padding-bottom {
        padding-bottom: 0rem !important
    }
    .right {
        float: right;
    }
</style>

<style>
    .form-control-label {
        width: 100%;
    }
    .height-50 {
        height: 50px;
    }
    .borderLine {
        border: 1px solid #ededed;
    }
</style>