<template>
    <div>
        <div class="col-lg-12" v-if="isLoadingBlade">
            <div class="d-flex justify-content-center">
                <img :src="loadingBladeUrl" />
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';

    export default {
        name: 'AssessmentAdditionalPaymentCallback',
        data() {
            return {
                isLoadingBlade: true
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl'])
        },
        methods: {
            loadCallback() {
                return window.location.href = '/assessment/application-form';
            }
        },
        created() {
            this.loadCallback();
        }
    }
</script>

<style scoped>
.right {
    float: right;
}
</style>