<template>
    <form>
        <h6 class="heading-small text-muted mb-4">
            Below is your internship performance details &nbsp;&nbsp;&nbsp;&nbsp;
            <span class="text-danger">
                <i class="fa fa-info-circle"></i> Only PDF Files (Not More than 800KB) are supported for each file
            </span>            
        </h6>

        <div class="container ct-example-row">
            <!-- import Internship Document File template here -->
            <InternshipDocument 
                v-bind:documents="documents"
                v-bind:id="record.id"
                v-bind:loadInternship="loadInternship"
            />
        </div>

        <br>
        <div class="row align-items-center">
            <div class="col-lg-6">
                <base-input 
                    label="Internship Performance Start Date"
                    addon-left-icon="ni ni-calendar-grid-58"
                    required
                    
                >
                    <flat-picker 
                        slot-scope="{focus, blur}"
                        @on-open="focus"
                        @on-close="blur"
                        :config="{allowInput: true}"
                        class="form-control datepicker"
                        v-model="record.start_date"
                        :disabled="lock"
                    >
                    </flat-picker>
                </base-input>
            </div>
            <div class="col-lg-6">
                <base-input 
                    label="Internship Performance End Date"
                    addon-left-icon="ni ni-calendar-grid-58"
                    required
                    
                >
                    <flat-picker 
                        slot-scope="{focus, blur}"
                        @on-open="focus"
                        @on-close="blur"
                        :config="{allowInput: true}"
                        class="form-control datepicker"
                        v-model="record.end_date"
                        :disabled="lock"
                    >
                    </flat-picker>
                </base-input>
            </div>
        </div>
        
        <div class="col-sm-12 text-center">
            <base-alert :type="errorType" v-if='showErrorStatus'>
                <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                <span class="alert-inner--text">
                    {{errorMsg}}
                </span>
            </base-alert>
        </div>

        <div class="col-lg-12"></div>
        <div class="col-lg-12">
            <base-button 
                type="success" 
                @click="updateRecords()"
                :disabled="lock || isloading"
            >
                <i class="fa fa-save"></i> 
                {{isloading ? 'Please Wait...' : 'Update Record'}}
            </base-button>
        </div>

        

    </form>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import InternshipDocument from './InternshipDocument.vue';
    import flatPicker from "vue-flatpickr-component";
    import "flatpickr/dist/flatpickr.css";

    export default {
        components: {
            InternshipDocument,
            flatPicker
        },
        name: 'Internship',
        data() {
            return {
                record: {
                    start_date: null,
                    end_date: null,
                    id: null
                },
                documents: [],
                isloading: false,
                showErrorStatus: false,
                errorType: null,
                errorMsg: null
            };
        },
        computed: {
            ...mapGetters(['lock'])
        },
        methods: {
            ...mapActions(['getUserInternshipRecords', 'getBasicProfile', 'updateInternship']),
            loadInternship() {
                const id = this.$store.state.user.userDataId;// get user data Id
                this.getUserInternshipRecords({'id' : id}).then((res) => {
                        this.documents = res.documents;
                        this.record.start_date = res.start_date;
                        this.record.end_date = res.end_date;
                        this.record.id = this.$store.state.user.userId;// get user Id
                }).
                catch(() => {
                    this.showErrorStatus = true;
                    this.errorMsg = "Network Failure!, Kindly reload the page...";
                    this.errorType = 'danger';
                });
            },
            updateRecords() {
                this.isloading = true;
                let data = this.validatedInputs();// validate inputs
                if(data) {// save edu records
                    data.id = this.$store.state.user.userDataId;// get user data Id
                    this.updateInternship(data).then((res) => {
                        this.isloading = false;
                        this.loadInternship();
                        if(res.status) {
                            let msg = 'Internship Records updated successfully';
                            this.showMsg(msg, 'success');
                        } else {
                            this.showErrorMsg();
                        }
                    }).
                    catch(() => {
                        this.isloading = false;
                        this.loadInternship();
                        this.showErrorMsg();
                    });
                } else {
                    this.isloading = false;
                    this.loadInternship();
                    this.showErrorMsg();
                }
            },
            validatedInputs() {
                let data = this.record;  
                let internRecord = { start_date: null, end_date: null };
                internRecord.start_date = data.start_date == null ? 
                    this.showInuptError('Start Date') : data.start_date;
                internRecord.end_date = data.end_date == null ? 
                    this.showInuptError('End Date') : data.end_date;
                return this.isEmpty(internRecord) ? internRecord : false;
            },
            isEmpty(obj) {// check for empty field
                return ! Object.keys(obj).some(k => !obj[k]);
            },
            showInuptError(name) {
                this.isloading = false;
                this.showInputErrorStatus = true
                this.errorType = 'warning'
                this.errorMsg = 'Invalid ' + name + ' has been entered';
                var self = this;
                setTimeout(function() { self.showInputErrorStatus = false; }, 5000);
            },
            showErrorMsg() {
                this.showErrorStatus = true
                this.errorType = 'warning'
                this.errorMsg = 'An error occurred, kindly reload page and try again';
                var self = this;
                setTimeout(function() { self.showErrorStatus = false; }, 5000);
            },
            showMsg(msg, type) {
                this.showErrorStatus = true;
                this.errorType = type;
                this.errorMsg = msg;
                var self = this;
                setTimeout(function() { self.showErrorStatus = false; }, 5000);
            },
        },
        created() {
            this.loadInternship();// load Internship
        },
    }
</script>

<style>
    .form-control-label {
        width: 100%;
    }
</style>
