<template>
    <div class="row">

        <div class="col-lg-12" v-if="isLoadingBlade">
            <div class="d-flex justify-content-center">
                <img :src="loadingBladeUrl" />
            </div>
        </div>

        <div class="container" v-if='! showErrorStatus && ! isLoadingBlade'>
            <div class="row">

                <div class="col-lg-12">
                    <h6 class="heading-small text-muted mb-4">
                        Upload Passport Photograph &nbsp;&nbsp;&nbsp;&nbsp;
                        <span class="text-danger">
                            <i class="fa fa-info-circle"></i> Only Image Files (Less than 1MB) are supported
                        </span>
                    </h6>
                    <br><br>
                </div>

                <div class="col-lg-12"></div>


                <div class="col-lg-12">
                    <div class="card card-profile shadow">
                        <div class="row justify-content-center">
                            <div class="col-lg-3 order-lg-2">
                                <div class="card-profile-image">
                                    <a href="javascript:void(0)">
                                        <img
                                            v-if="file.has_file"
                                            :src="file.url"
                                            class="rounded-circle image-size"
                                        >
                                        <img
                                            v-if="! file.has_file"
                                            :src="blankImageUrl"
                                            class="rounded-circle image-size"
                                        >
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="card-header text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                            <div class="d-flex justify-content-between">
                            </div>
                        </div>
                        <div class="card-body pt-0 pt-md-4">
                            <div class="row">
                                <div class="col">
                                    <div class="card-profile-stats d-flex justify-content-center mt-md-5">

                                    </div>
                                </div>
                            </div>
                            <div class="text-center">
                                <input
                                    type="file"
                                    ref="photo"
                                    style="display: none"
                                    id="life_photo"
                                    @change="onFileSelected"
                                >
                                <h3>
                                    <base-progress
                                        v-if="loadPercent.show"
                                        :type="loadPercent.type"
                                        :height="10"
                                        :value="loadPercent.value"
                                        :striped="true"
                                        :animated="loadPercent.animated"
                                    >
                                    </base-progress>
                                    <h4 class="text-danger">
                                        Please Upload Recent Passport <br>
                                        Note that you are to upload passport photograph
                                        else your application will be rejected (Your face
                                        and jaws must be displayed)
                                    </h4>
                                    <base-button
                                        size="sm" type="info"
                                        class="mr-4 marginLeft30"
                                        v-if="! loadPercent.show"
                                        @click="$refs.photo.click()"
                                        :disabled="showReSendButton"
                                    >
                                        <i class="fas fa-upload"></i>
                                        {{ file.has_file ? 'Change File' : 'Select File' }}
                                    </base-button>
                                </h3>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <hr class="my-4"/>
        </div>

        <div class="col-sm-12 text-center" v-if='showErrorStatus'>
            <base-alert :type="errorType">
                <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                <span class="alert-inner--text">
                    {{errorMsg}}
                </span>
            </base-alert>
        </div>


        <div class="col-lg-12" v-if='! showErrorStatus && ! isLoadingBlade'>
            <br>
            <base-button
                type="success"
                @click="makePayment()"
                v-if="! showReSendButton && updateStatus"
                :disabled="! file.has_file"
            >
                <i class="fa fa-save"></i>
                Save &amp; Continue
            </base-button>

            <base-button
                type="success"
                @click="reSendApplication(applicationId)"
                v-if="showReSendButton"
            >
                <i class="fa fa-upload"></i>
                Re-send Application
            </base-button>

            <div class="right">
                <base-button
                    type="danger"
                    @click="editApplication()"
                    v-if="showReSendButton"
                >
                    <i class="fa fa-pen"></i>
                    Edit Application
                </base-button>
            </div>
        </div>

    </div>

</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import moment from 'moment';
    import swal from 'sweetalert2';// import sweet alert

    export default {
        name: 'ApplyLifeLicense',
        props: ['applicationType'],
        data() {
            return {
                id: this.$route.params.id,
                savedEvent: null,
                showUploadButton: false,
                photo: {
                    user_id: null,
                    ext: null,
                    file: null,
                    user_data_id: null,
                    application_type_id: null,
                    trans_no: null,
                    application_date: null,
                    application_id: null
                },
                loadPercent: {
                    type: 'warning',
                    value: 10,
                    animated: true,
                    show: false
                },
                file: {
                    has_file: false,
                    url: null
                },
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isLoadingBlade: true,
                updateStatus: false,
                isRejected: false,
                showReSendButton: false,
                applicationId: null
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl', 'blankImageUrl']),
        },
        methods: {
            ...mapActions(['updateLifeLicense','getLifeLicenseApplication', 'getApplication',
                'reSubmitApplication']),
            loadApplication() {
                if(this.id) {// load exact application
                     this.getExactApplication(this.id);
                } else {// load any pending application
                    return this.loadPendingApplication();
                }
            },
            getExactApplication(id) {// load exact application
                this.getApplication({'id' : id}).then((res) => {
                    this.applicationId = res.id;
                    this.updateRecord(res);// update record for editing
                    this.isRejected = res.status == 'rejected' ? true : false;
                    this.isLoadingBlade = false;
                }).
                catch(() => {
                    this.isLoadingBlade = false;
                    this.showErrorStatus = true;
                    this.errorMsg = "Network Failure!, kindly reload the page...";
                    this.errorType = 'danger';
                });
            },
            loadPendingApplication() {// load details
                this.getLifeLicenseApplication().then((res) => {
                    this.updateRecord(res);// update record for editing
                    this.isLoadingBlade = false;
                }).
                catch(() => {
                    this.isLoadingBlade = false;
                    this.showErrorStatus = true;
                    this.errorMsg = "Network Failure!, kindly reload the page...";
                    this.errorType = 'danger';
                });
            },
            updateRecord(data) {// update AQ Record for editing
                if(data) {
                    this.photo.user_data_id = data.user_data_id;
                    this.photo.trans_no = data.trans_no;
                    this.photo.application_date = data.application_date;
                    this.photo.application_id = data.id;
                    if(data.life_license_file) {
                        this.file.has_file = data.life_license_file.has_file;
                        this.file.url = data.life_license_file.url;
                    }
                    this.updateStatus = data ? true : false;
                }
            },
            makeUploadFieldsEmpty() {
                this.photo.ext = null;
                this.photo.file = null;
            },
            onFileSelected(event) {
                this.savedEvent = null;
                this.makeUploadFieldsEmpty();
                this.savedEvent = event;
                let status =  this.validateFile(event.target.files[0]);
                return status ? this.uploadPhoto() : false;
            },
            uploadPhoto() {
                this.photo.user_id = this.$store.state.user.userId;// get user id
                let event = this.savedEvent;// get store file event
                var fileReader = new FileReader();
                fileReader.readAsDataURL(event.target.files[0]);
                fileReader.onload = (event) => {// convert file to binary
                    this.photo.file = event.target.result;
                    let img = new Image();
                    img.src = window.URL.createObjectURL(this.savedEvent.target.files[0]);
                    img.onload = () => {
                        var height = img.height;
                        var width = img.width;
                        if ((width <= 500) && (height <= 500)) {
                            this.loadPercent.show = true;
                            this.makeUploadApiCall();// upload photo
                        } else {
                            let msg = "Width and Height must not exceed 500 * 500 pixels";
                            swal.fire("Invalid Width/Height!", msg, "warning");
                            return false;
                        }
                    }
                }
            },
            makeUploadApiCall() {
                this.photo.user_data_id = this.$store.state.user.basicProfile.id;
                this.photo.application_type_id = this.applicationType.id;
                this.photo.trans_no = this.genRandomString();// generate trans number
                this.photo.application_date = this.photo.application_date ? this.photo.application_date
                                                : this.getCurrentDate();
                this.updateLifeLicense(this.photo).then((res) => {
                    this.uploadPercent(this.$store.state.user.uploadProgress);// show load progress
                    this.makeUploadFieldsEmpty();
                    this.savedEvent = null;
                    this.loadApplication();// load photo
                    if(res.status) {
                        this.loadApplication();// load photo
                        var self = this;
                        setTimeout(function() {
                            self.loadPercent.show = false;
                            self.loadPercent.value = 10;
                            self.loadPercent.type = 'warning';
                            swal.fire("Passport Uploaded!", "Passport was uploaded successfully", "success");
                        }, 2000);
                    } else {// eslint-disable-next-line
                        var self = this;
                        setTimeout(function() {
                            self.loadPercent.show = false;
                            self.loadPercent.value = 10;
                            self.loadPercent.type = 'warning';
                            swal.fire("Error Occurred!", "An error occurred while uploading, try again", "warning");
                        }, 2000);
                    }// eslint-disable-next-line
                    $('#life_photo').val('');
                }).
                catch(err => {
                    var self = this;
                    self.savedEvent = null;
                    swal.fire(err.message+"!", "Reduce file size and try again...", "warning");
                    self.loadPercent.show = false;
                    this.loadApplication();// load photo
                    // eslint-disable-next-line
                    $('#life_photo').val('');
                });
            },
            uploadPercent(percent) {// update upload progress percent
                this.loadPercent.value = 10;
                this.loadPercent.value = percent;
                this.loadPercent.type = percent > 60 ? 'success' : 'warning';
            },
            validateFile(file) {
                if(!file) { this.savedEvent = null; }// update seleted file
                const validFileTypes = ['jpg', 'jpeg', 'JPEG', 'JPG', 'png', 'PNG'];// valid image format array
                var fileExt = file.type.split('/').pop();// get selected file ext
                this.photo.ext = fileExt;
                var validExt = validFileTypes.includes(fileExt);
                let fileSize = file.size;// get selected file size
                if(validExt && fileSize <= 1000000) {// for valid file and size
                    return true;
                } else if(! validExt) {// invalid file
                    this.savedEvent = null;
                    swal.fire("Invalid File!", "Only JPG/PNG files are accepted", "warning");
                    return false;
                } else if(fileSize > 1000000) {// big file
                    this.savedEvent = null;
                    swal.fire("File is too large!", "File should be less than 1MB", "warning");
                    return false;
                }
            },
            makePayment() {
                if(this.isRejected) {
                    return this.showReSendButton = true;
                } else {
                    return window.location.href = "/application-payment/"+this.photo.application_id;
                }
            },
            genRandomString() {
                var length = 12;
                var text = '';
                var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
                for (var i=0; i < length; i++) {
                    text += possible.charAt(Math.floor(Math.random() * possible.length));
                }
                return text;
            },
            getCurrentDate() {// get current date time
                let date = new Date().toJSON().slice(0,10).replace(/-/g,'-');
                return moment(date).format('YYYY-MM-DD');
            },
            editApplication() {
                return this.showReSendButton = false;
            },
            reSendApplication(id) {// re-send rejected application
                swal.fire({
                    title: 'Are you sure?',
                    text: "You must have corrected the issue before proceeding to re-submit application!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, I have corrected it!'
                }).then((result) => {
                    if (result.value) {
                        this.makeReSubmitApiCall(id);// make api call
                    }
                });
            },
            makeReSubmitApiCall(id) {
                this.preloader(); // show loading
                this.reSubmitApplication(id).then((res) => {// get application record
                    if(res.status) {
                        swal.fire("Application Submitted!", res.msg, "success");
                        return window.location.href='/applications';
                    } else {
                        swal.fire("Error Occurred!", res.msg, "warning");
                    }
                }).
                catch(() => {
                    let msg = "Network Failure! , kindly reload the page...";
                    swal.fire("Network Failure!", msg, "warning");
                });
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b> ...',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        created() {
            this.loadApplication();// load records
        },
    }
</script>

<style scoped>
    .right {
        float: right;
    }
    .image-size {
        height: 180px;
        width: 180px;
    }
</style>