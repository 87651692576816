<template>
    <div class="container">
        <div class="row">
            <div class="col-lg-12" v-if="isLoadingBlade">
                <div class="d-flex justify-content-center">
                    <img :src="loadingBladeUrl" />
                </div>
            </div>

            <div class="row"  v-if='! showErrorStatus && ! isLoadingBlade'>
                <div class="col-lg-6">
                    <base-input alternative=""
                        label="Country"
                        required
                    >
                        <autocomplete
                            v-if="locations && locations.countries"
                            input-class="form-control form-control-alternative"
                            placeholder="Type Country"
                            showNoResults
                            :source="locations.countries"
                            v-model="record.country_id"
                            :initialDisplay="hasCountry(record.country_id)"
                            @selected="changeCountry"
                        >
                        </autocomplete>
                    </base-input>
                </div>

                <div class="col-lg-6">
                    <base-input alternative=""
                        label="School Name/Institution (Postgraduate College)"
                        required
                    >
                        <autocomplete
                            ref="schoolAutocomplete"
                            input-class="form-control form-control-alternative"
                            placeholder="Type to search Institution"
                            showNoResults
                            :disableInput="(schools && schools.length == 0) || showReSendButton"
                            :source="schools"
                            :initialDisplay="(record.school ? record.school : '')"
                            resultsValue="name"
                            @selected="changeInstitution"
                        >
                        </autocomplete>
                    </base-input>
                </div>

                <div class="col-lg-12" v-if="showOtherInstitution">
                    <base-input alternative=""
                        label="Other School/Institution Name"
                        placeholder="Enter Other School/Institution Name"
                        input-classes="form-control-alternative"
                        v-model="record.other_school_name"
                        required
                        :readonly="showReSendButton"
                    />
                </div>

                <div class="col-lg-12" v-if="qualifications && qualifications.length > 0">
                    <base-input alternative=""
                        label="Qualification"
                        required
                        >

                        <select
                            class="form-control form-control-alternative"
                            v-model="record.qualification"
                            @change="getDegrees()"
                            :disabled="showReSendButton"
                        >
                            <option value="null" disabled>--- Select One ---</option>
                            <option
                                v-for="qualification in qualifications"
                                :key="qualification.id"
                                :value="qualification.id"

                            >
                                {{ qualification.course_title }}
                            </option>
                        </select>
                    </base-input>
                </div>


                <div class="col-lg-12" v-if="degrees && degrees.length > 0">
                    <base-input alternative=""
                        v-if="degrees[0] != ''"
                        label="Degree"
                        required
                        >
                        <div class="container">
                            <div class="row">
                                <base-radio
                                    v-for="(degree, index) in degrees"
                                    :key="index"
                                    :name="degree.trim()"
                                    class="mb-3"
                                    v-model="record.degree"
                                    :disabled="showReSendButton"
                                >
                                    {{ degree.trim() }} &nbsp;&nbsp;&nbsp;
                                </base-radio>
                            </div>
                        </div>

                    </base-input>
                </div>

                <div class="col-lg-6">
                    <base-input
                        label="The Additional Qualification Degree Date"
                        addon-left-icon="ni ni-calendar-grid-58"
                        required
                    >
                        <flat-picker
                            slot-scope="{focus, blur}"
                            @on-open="focus"
                            @on-close="blur"
                            :config="config"
                            class="form-control datepicker"
                            v-model="record.qualify_date"
                            :disabled="showReSendButton"
                        >
                        </flat-picker>
                    </base-input>
                </div>

                <div class="col-lg-6">
                    <base-input alternative=""
                        label="Issuing Institution"
                        placeholder="Enter Issuing Institution"
                        input-classes="form-control-alternative text-capitalize"
                        v-model="record.licencing_body"
                        :readonly="showReSendButton"
                    />
                </div>

                <div class="col-lg-6">
                    <base-input alternative=""
                        label="Duration of training (In weeks)"
                        placeholder="Enter Duration of training"
                        input-classes="form-control-alternative text-capitalize"
                        v-model="record.duration"
                        :readonly="showReSendButton"
                        type="number"
                    />
                </div>

                <div class="col-lg-6">
                    <base-input
                        label="Notification Date"
                        addon-left-icon="ni ni-calendar-grid-58"
                        required
                    >
                        <flat-picker
                            slot-scope="{focus, blur}"
                            @on-open="focus"
                            @on-close="blur"
                            :config="config"
                            class="form-control datepicker"
                            v-model="record.notification_date"
                            :disabled="showReSendButton"
                        >
                        </flat-picker>
                    </base-input>
                </div>

                <div class="col-lg-6">
                    <base-input alternative=""
                        label="Country of Verfication"
                        required
                    >
                        <autocomplete
                            v-if="locations && locations.countries"
                            input-class="form-control form-control-alternative"
                            placeholder="Type to Search Country"
                            showNoResults
                            :source="locations.countries"
                            :disableInput="showReSendButton"
                            v-model="country.country_id"
                            :initialDisplay="hasCountry(country.country_id)"
                        >
                        </autocomplete>

                        <!-- <select
                            class="form-control form-control-alternative"
                            v-model="country.country_id"
                            :disabled="showReSendButton"
                        >
                            <option value="null" disabled>--- Select One ---</option>
                            <option
                                v-for="country in locations.countries"
                                :key="country.id"
                                :value="country.id"
                            >
                                {{ country.name }}
                            </option>
                        </select> -->
                    </base-input>
                </div>

                <div class="col-lg-12"><hr></div>

                <div
                    class="col-lg-4 text-center borderLine"
                    v-for="(document, index) in documents"
                    :key="index"
                >
                    <br>
                    <input
                        type="file"
                        id="file" style="display: none"
                        @change="onFileSelected"
                    >
                    <h4 class="text-capitalize height-50" v-html="document.name"></h4><br>
                    <base-button
                        size="sm" type="primary"
                        v-if="! selectedCert && (document.filename =='additional_certificate.pdf')"
                        @click="selectFile(document.filename)"
                        :disabled="showReSendButton"
                    >
                        <i class="fa fa-hand-pointer"></i>
                        {{ aqFiles.certificate_file ? 'Change File' : 'Select File' }}
                    </base-button>

                    <base-button
                        tag="a" size="sm"
                        type="success"
                        :href="documentUrl+aqId+'_'+document.filename"
                        target="_blank"
                        role="button"
                        aria-pressed="true"
                        v-if="aqFiles.certificate_file && ! selectedCert &&
                        (document.filename =='additional_certificate.pdf')"
                    >
                        <i class="fa fa-eye"></i> View File
                    </base-button>

                    <base-button
                        size="sm" type="primary"
                        v-if="! selectedRegCert && (document.filename =='registration_certificate.pdf')"
                        @click="selectFile(document.filename)"
                        :disabled="showReSendButton"
                    >
                        <i class="fa fa-hand-pointer"></i>
                        {{ aqFiles.registration_file ? 'Change File' : 'Select File' }}
                    </base-button>

                    <base-button
                        tag="a" size="sm"
                        type="success"
                        :href="documentUrl+aqId+'_'+document.filename"
                        target="_blank"
                        role="button"
                        aria-pressed="true"
                        v-if="aqFiles.registration_file && ! selectedRegCert &&
                        (document.filename =='registration_certificate.pdf')"
                    >
                        <i class="fa fa-eye"></i> View File
                    </base-button>

                    <base-button
                        size="sm" type="primary"
                        v-if="! selectedNotifyCert && (document.filename =='notification_certificate.pdf')"
                        @click="selectFile(document.filename)"
                        :disabled="showReSendButton"
                    >
                        <i class="fa fa-hand-pointer"></i>
                        {{ aqFiles.notification_file ? 'Change File' : 'Select File' }}
                    </base-button>

                    <base-button
                        tag="a" size="sm"
                        type="success"
                        :href="documentUrl+aqId+'_'+document.filename"
                        target="_blank"
                        role="button"
                        aria-pressed="true"
                        v-if="aqFiles.notification_file && ! selectedNotifyCert &&
                        (document.filename =='notification_certificate.pdf')"
                    >
                        <i class="fa fa-eye"></i> View File
                    </base-button>

                    <base-button
                        size="sm" type="danger"
                        v-if="(registrationFile.name == document.filename || certificateFile.name == document.filename
                                || notificationFile.name == document.filename)
                            && (registrationFile.name || certificateFile.name || notificationFile.name)"
                        @click="removeFile(document.filename)"
                    >
                        <i class="fa fa-times"></i> Remove File
                    </base-button>
                    <br><br>
                </div>
            </div>
        </div>

        <div class="col-sm-12 text-center">
            <base-alert :type="errorType" v-if='showInputErrorStatus'>
                <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                <span class="alert-inner--text">
                    {{errorMsg}}
                </span>
            </base-alert>
        </div>

        <div class="col-lg-12" v-if='! showErrorStatus && ! isLoadingBlade && ! showReSendButton'>
            <hr>
            <base-button
                type="danger"
                @click="$emit('displayAqPage', false)"
            >
                <i class="fa fa-arrow-left"></i>
                Previous
            </base-button>

            <base-button
                type="success"
                class="right"
                @click="saveAdditionalQualifcation()"
            >
                {{isloading ? 'Please Wait...' : 'Save & Continue'}}
                <i class="fa fa-arrow-right"></i>
            </base-button>
        </div>

        <div class="col-sm-12 text-center">
            <base-alert :type="errorType" v-if='showErrorStatus'>
                <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                <span class="alert-inner--text">
                    {{errorMsg}}
                </span>
            </base-alert>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import flatPicker from "vue-flatpickr-component";
    // import "flatpickr/dist/flatpickr.css";
    import swal from 'sweetalert2';// import sweet alert
    import Autocomplete from 'vuejs-auto-complete';

    export default {
        name: 'AqFormFullWithoutAq',
        props: ['createApplication', 'country', 'locations', 'showReSendButton'],
        components: {
            flatPicker,
            Autocomplete
        },
        data() {
            return {
                id: this.$route.params.id,
                qualifications: [],
                degrees: [],
                schools: [],
                record: {
                    country_id: null,
                    school: null,
                    category: null,
                    qualification: null,
                    degree: null,
                    qualify_date: null,
                    licencing_body: null,
                    other_school_name: null,
                    duration: null,
                    notification_date: null
                },
                certificateFile: {
                    'name' : null,
                    'file' : null
                },
                registrationFile: {
                    'name' : null,
                    'file' : null
                },
                notificationFile: {
                    'name' : null,
                    'file' : null
                },
                aqFiles: {
                    certificate_file: false,
                    registration_file: false,
                    notification_file: false,
                },
                selectedFileName: null,
                showInputErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isloading: false,
                selectedCert: false,
                selectedRegCert: false,
                selectedNotifyCert: false,
                showOtherInstitution: false,
                showErrorStatus: false,
                aqId: null,
                isRejected: false,
                isLoadingBlade: true,
                config: {
                    allowInput: true,
                    altInput: true,// eslint-disable-next-line
                    plugins: [new monthSelectPlugin({shorthand: true, dateFormat: "Y-m-d", altFormat: "M Y"})]
                }
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl']),
            documents() {
                return [
                    {
                        'name' : 'AQ Notification Certificate <br> (required)',
                        'filename' : 'notification_certificate.pdf'
                    },
                    {
                        'name' : 'Full Registration Certificate <br> (required)',
                        'filename' : 'registration_certificate.pdf'
                    },
                    {
                        'name' : 'Postgraduate Certificate being registered obtained (Optional)',
                        'filename' : 'additional_certificate.pdf'
                    }
                ];
            },
            documentUrl() {// get AQ document url
                let rootUrl = this.$store.state.url.rootUrl;
                let userFolder = '/user'+this.$store.state.user.userId;
                return rootUrl+'/storage'+userFolder+'/documents/additional_qualification/';
            }
        },
        methods: {
            ...mapActions(['getAdditionalQulifications', 'getAssessmentInstitutions', 'getUserPendingAq', 'saveNewAQData']),
            hasCountry(id) {// get exact country
                if(id) {
                    let country = this.locations && this.locations.countries ?
                            this.locations.countries.filter(item => item.id == id) : null;
                    return country ? country[0].name : '';
                } else {
                    return '';
                }
            },
            changeCountry(e, country = false) {// get exact institutions
                this.schools = []; let id;
                if(country) {
                    id = country;
                } else {
                    id =  e.selectedObject.id;
                    this.record.school = null;
                    this.record.other_school_name = null;
                    this.$refs.schoolAutocomplete.clear();
                }
                this.preloader();
                this.getAssessmentInstitutions({ id : id }).then((res) => {
                    swal.close();
                    this.schools = res.schools;
                    let other = { id : 0, name: 'Others (Specify)'};
                    this.schools.push(other);
                });
            },
            changeInstitution(e) {
                let data = e.selectedObject;
                this.record.other_school_name = null;
                this.showOtherInstitution = false;
                if(data.id == 0) {
                    this.showOtherInstitution = true;
                } else {
                    this.record.school = data.name;
                }
            },
            loadAddQualifications() {// load details
                let data;
                if(this.id) {
                    data = { 'id' : this.id, type : 'full-aq' };
                } else {
                    data = { 'id' : this.$store.state.user.userId };
                }

                this.getUserPendingAq(data).then((res) => {// get pending Aq

                    this.qualifications = res.qualifications;
                    this.isLoadingBlade = false;
                    if(res.app_status) {
                        this.updateAQRecord(res);
                    }
                }).
                catch(() => {
                    this.isLoadingBlade = false;
                    this.showErrorStatus = true;
                    this.errorMsg = "Network Failure!, kindly reload the page...";
                    this.errorType = 'danger';
                });
            },
            loadAqAndInstitions() {// load Aq And Institions
                this.getAdditionalQulifications().then((res) => {// get add qual records
                    for(let category of res) {
                        if(category.has_qualifications && category.has_qualifications.length > 0) {
                            for(let qual of category.has_qualifications) {
                                this.qualifications.push(qual);
                            }
                        }
                    }
                });
            },
            updateAQRecord(data) {// update AQ Record for editing
                this.record.school = data.school_name;
                this.record.qualification = data.additional_qualification_config_id;
                this.record.country_id = data.country_id;
                this.record.degree = data.degree;
                this.record.qualify_date = data.qualify_date;
                this.record.licencing_body = data.licencing_body;
                this.record.duration = data.duration;
                this.record.notification_date = data.notification_date;
                if(data.files) {// update document status
                    this.aqFiles.certificate_file = data.files.certificate_file;
                    this.aqFiles.registration_file = data.files.registration_file;
                    this.aqFiles.notification_file = data.files.notification_file;
                } else {
                    this.aqFiles.certificate_file = false;
                    this.aqFiles.registration_file = false;
                    this.aqFiles.notification_file = false;
                }
                this.aqId = data.id;
                this.getDegreesForEditing();// load degrees
                if(data.country_id > 0) {// get institutions
                    this.changeCountry(null, data.country_id);
                }
            },
            getDegreesForEditing() {// get Qualification's degrees
                this.degrees = [];
                for(let qualification of this.qualifications) {
                    if(qualification.id == this.record.qualification) {
                        return this.degrees = qualification.qualification.split(',');
                    }
                }
            },
            getDegrees() {// get Qualification's degrees
                this.degrees = [];
                this.record.degree = null;
                for(let qualification of this.qualifications) {
                    if(qualification.id == this.record.qualification) {
                        return this.degrees = qualification.qualification.split(',');
                    }
                }
            },
            selectFile(name) {// eslint-disable-next-line
                $('#file').val('');
                this.selectedFileName = null;// eslint-disable-next-line
                $('#file').click();
                this.selectedFileName = name;
                if(name == 'additional_certificate.pdf') {
                    this.certificateFile.name = name;
                } else if(name == 'registration_certificate.pdf') {
                    this.registrationFile.name = name;
                } else {
                    this.notificationFile.name = name;
                }
            },
            onFileSelected(event) {
                let status =  this.validateFile(event.target.files[0]);
                var fileReader = new FileReader();
                fileReader.readAsDataURL(event.target.files[0]);
                fileReader.onload = (event) => {// convert file to binary
                    var file = event.target.result;
                    if(this.selectedFileName == 'additional_certificate.pdf') {
                        this.certificateFile.file = file;
                        this.selectedFileName = null;
                    } else if(this.selectedFileName == 'registration_certificate.pdf') {
                        this.registrationFile.file = file;
                        this.selectedFileName = null;
                    } else if(this.selectedFileName == 'notification_certificate.pdf'){
                        this.notificationFile.file = file;
                        this.selectedFileName = null;
                    }

                }
                return status;
            },
            validateFile(file) {// check for valid file
                if(!file) {// update seleted file name
                    if(this.selectedFileName == 'additional_certificate.pdf') {
                        this.certificateFile.name = null
                    } else if(this.selectedFileName == 'registration_certificate.pdf') {
                        this.registrationFile.name = null;
                    } else {
                        this.notificationFile.name = null;
                    }
                }
                var fileExt = file.type.split('/').pop();
                let fileSize = file.size;
                if(fileExt == 'pdf' && fileSize <= 500000) {// for valid file and size
                    if(this.selectedFileName == 'additional_certificate.pdf') {
                        this.selectedCert = true
                    } else if(this.selectedFileName == 'registration_certificate.pdf') {
                        this.selectedRegCert = true;
                    } else {
                        this.selectedNotifyCert = true;
                    }
                    return true;
                } else if(fileExt != 'pdf') {// invalid file
                    this.removeAllFiles();
                    swal.fire("Invalid File!", "Only PDF files are accepted", "warning");
                    return false;
                } else if(fileSize > 500000) {// big file
                    this.removeAllFiles();
                    swal.fire("File is too large!", "File should be less than 500KB", "warning");
                    return false;
                }
            },
            removeAllFiles() {// eslint-disable-next-line
                $('#file').val('');
                this.certificateFile.name = null;
                this.certificateFile.savedEvent = null;
                this.certificateFile.file = null;
                this.selectedCert = false;
                this.registrationFile.name = null;
                this.registrationFile.savedEvent = null;
                this.registrationFile.file = null;
                this.selectedRegCert = false;
                this.notificationFile.name = null;
                this.notificationFile.savedEvent = null;
                this.notificationFile.file = null;
                this.selectedNotifyCert = false;
            },
            removeFile(name) {// eslint-disable-next-line
                $('#file').val('');
                if(name == 'additional_certificate.pdf') {
                    this.certificateFile.name = null;
                    this.certificateFile.savedEvent = null;
                    this.certificateFile.file = null;
                    this.selectedCert = false;
                } else if(name == 'registration_certificate.pdf') {
                    this.registrationFile.name = null;
                    this.registrationFile.savedEvent = null;
                    this.registrationFile.file = null;
                    this.selectedRegCert = false;
                } else {
                    this.notificationFile.name = null;
                    this.notificationFile.savedEvent = null;
                    this.notificationFile.file = null;
                    this.selectedNotifyCert = false;
                }
            },
            saveAdditionalQualifcation() {// save add qual
                let data = this.validatedInputs();// validate inputs
                if(data) {// update files
                    this.isloading = true;
                    data.certificate_file = this.certificateFile;
                    data.registration_file = this.registrationFile;
                    data.notification_file = this.notificationFile;
                    data.degree = this.record.degree;// get degree
                    data.user_data_id = this.$store.state.user.basicProfile.id;// get user id
                    data.user_id = this.$store.state.user.userId;// get user id
                    data.id = this.aqId ;// get id
                    this.saveNewAQData(data).then((res) => {
                        this.isloading = false;
                        if(res.status) {
                            this.createApplication(res.addQual.id);
                        } else {
                            swal.fire("Error Occurred!", "An error occurred while saving, try again", "warning");
                        }
                    }).
                    catch(err => {
                        this.isloading = false;
                        swal.fire(err.message+"!", "An error occurred while saving, try again", "warning");
                    });
                }
            },
            validatedInputs() {
                let data = this.record;
                let certFile = null;
                let regFile = null;
                let addRecord = { school_name: null, country_id: null, qualification: null,
                        qualify_date: null, licencing_body: null, duration: null, notification_date: null };
                let country = (this.country.country_id == null) ? this.showInuptError('Country of Verfication') : true;
                regFile = (this.registrationFile.name == null && ! this.aqFiles.registration_file) ?
                    this.showInuptError('Full Registration Certificate File') : true;
                certFile = (this.notificationFile.name == null && ! this.aqFiles.notification_file) ?
                    this.showInuptError('Notification Certificate File') : true;
                addRecord.notification_date  = data.notification_date == null ?
                    this.showInuptError('Notification Date') : data.notification_date;
                addRecord.duration  = data.duration == null ?
                    this.showInuptError('Duration of Training') : data.duration;
                addRecord.licencing_body  = data.licencing_body == null ?
                    this.showInuptError('Issuing Institution') : data.licencing_body;
                addRecord.qualify_date  = data.qualify_date == null ?
                    this.showInuptError('Qualification Date') : data.qualify_date;
                addRecord.qualification  = data.qualification == null ?
                    this.showInuptError('Qualification') : data.qualification;
                // validate institution
                if(data.school == null && data.other_school_name != null) {
                    addRecord.school_name = data.other_school_name;
                } else if(data.school != null) {
                    addRecord.school_name = data.school;
                } else if(data.school == null && data.other_school_name == null) {
                    this.showInuptError('School\'s Name');
                }
                addRecord.country_id = data.country_id == null ? this.showInuptError('Country') : data.country_id;
                return this.isEmpty(addRecord) && certFile && regFile && country ? addRecord : false;
            },
            isEmpty(obj) {// check for empty field
                return ! Object.keys(obj).some(k => !obj[k]);
            },
            showInuptError(name) {
                this.isloading = false;
                this.showInputErrorStatus = true
                this.errorType = 'warning'
                this.errorMsg = 'Invalid ' + name + ' has been entered';
                var self = this;
                setTimeout(function() { self.showInputErrorStatus = false; }, 5000);
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b> ...',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        mounted() {
            // this.loadAqAndInstitions();// load Aq And Institions
            this.loadAddQualifications();// load add qual records
        },
    }
</script>

<style scoped>
    .right {
        float: right;
    }
</style>

<style>
    .form-control-label {
        width: 100%;
    }
</style>