<template>
    <div>

        <div class="row">

            <div class="col-lg-12">
                <h3 class="text-uppercase">Input valid RRR (payment of the extra service charge), and proceed
                    to apply...
                </h3>
                <hr>
            </div>

            <div class="col-lg-4"></div>

            <div class="col-lg-4 text-center">
                <base-input alternative=""
                    label="New RRR"
                    placeholder="Enter valid RRR for extra service charge"
                    input-classes="form-control-alternative"
                    v-model="rrr"
                    required
                />
            </div>


            <div class="col-lg-12 text-center" v-if='showErrorStatus'>
                <base-alert :type="errorType">
                    <!-- <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span> -->
                    <span class="alert-inner--text">
                        <h2 class="text-white">{{errorMsg}}</h2>
                    </span>
                </base-alert>
            </div>

            <div class="col-lg-12">
                <base-button
                    type="danger"
                    @click="$emit('displayPayment', false)"
                >
                    <i class="fa fa-arrow-left"></i>
                    Go Back
                </base-button>

                <base-button
                    type="success"
                    class="right"
                    @click="validateRRR()"
                >
                    {{ isloading ? 'Please Wait...' : 'Proceed Now'}}
                    <i class="fa fa-arrow-right"></i>
                </base-button>
            </div>
        </div>

        <br><br><br>
    </div>
</template>

<script>
    import { mapActions } from 'vuex';
    import swal from 'sweetalert2';// import sweet alert

    export default {
        name: 'AssessmentExtraRRR',
        props: ['record', 'type', 'country'],
        data() {
            return {
                isloading: false,
                rrr: null,
                showErrorStatus: false,
                errorType: null,
                errorMsg: null
            }
        },
        computed: {
            totalAmount() {
                let amount = parseInt(this.type.batch_payment.amount);
                amount -= (this.record && this.record.amount) ? parseInt(this.record.amount) : 0;// minus from total
                return (this.record.percentage / 100) * amount;
            }
        },
        methods: {
            ...mapActions(['addExtraAssessmentCharge']),
            validateRRR() {// validated rrr and save transaction
                if(this.rrr && (this.rrr != this.record.rrr)) {
                    this.isloading = true;
                    this.preloader();
                    let data = { rrr : this.rrr, previous_rrr : this.record.rrr, amt: this.totalAmount };
                    this.addExtraAssessmentCharge(data).then((res) => {
                        this.isloading = false;
                        swal.close();
                        if(res.status && res.applicant) {
                            localStorage.setItem('applicantId', res.applicant.id);
                            return window.location.href = '/assessment/application-form';
                        } else {
                            swal.fire("Verification Failed!", res.msg, "warning");
                        }
                    }).
                    catch(() => {
                        this.isloading = false;
                        let msg = "Network Failure!, kindly reload the page...";
                        swal.fire("Error Ocurred!", msg, "warning");
                    });
                } else if(this.rrr == this.record.rrr) {
                    let msg = "You entered RRR meant for the previous batch, check and try again";
                    swal.fire("Invalid RRR!", msg, "warning");
                } else {
                    let msg = "Invalid RRR entered, check and try again";
                    swal.fire("Invalid RRR!", msg, "warning");
                }
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        }
    }
</script>

<style scoped>
    .no-padding-bottom {
        padding-bottom: 0rem !important
    }
    .right {
        float: right;
    }
</style>

<style>
    .form-control-label {
        width: 100%;
    }
    .height-50 {
        height: 50px;
    }
    .borderLine {
        border: 1px solid #ededed;
    }
</style>