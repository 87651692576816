<template>
    <div>
        <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center">
            <span class="mask bg-gradient-danger opacity-8"></span>
        </base-header>


        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col-xl-12 order-xl-1">
                    <card shadow type="secondary">
                        <div slot="header" class="bg-white border-0">

                            <div class="row align-items-center" v-if="! isLoadingBlade">
                                <div class="col-8">
                                    <h4 class="mb-0 text-uppercase" v-if="! showErrorStatus">
                                        Application for
                                        <em class="text-danger" v-if="applicationType">
                                            {{ applicationType.name }}
                                        </em>
                                    </h4>
                                </div>
                                <div class="col-4 text-right">
                                    <router-link to="/applications" class="btn btn-sm btn-danger">
                                        <i class="fa fa-reply"></i> Back
                                    </router-link>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-12" v-if="isLoadingBlade">
                            <div class="d-flex justify-content-center">
                                <img :src="loadingBladeUrl" />
                            </div>
                        </div>

                        <div v-if="! isLoadingBlade && ! showErrorStatus">
                            <!-- import apply for Add Qual template here -->
                            <ApplyAddQualification
                                v-if="code == 'additional-pgq'"
                            />

                            <!-- import Apply for change of name template here -->
                            <ApplyChangeName
                                v-if="code == 'change-of-name'"
                            />

                            <!-- import Apply for Revalidation template here -->
                            <ApplyRevalidation
                                v-if="code == 'reval-reg'"
                            />

                            <!-- import Apply for LOGs template here -->
                            <Logs
                                v-if="code == 'cert-good-standing'"
                            />

                            <!-- import Apply for Full Reg Upgrade template here -->
                            <ApplyFullUpgrade
                                v-if="code == 'full-reg-upgrade'"
                            />

                            <!-- import Apply for life license template here -->
                            <ApplyLifeLicense
                                v-if="code == 'life-license'"
                                v-bind:applicationType="applicationType"
                            />

                            <!-- import Apply EICS template here -->
                            <ApplyEics
                                v-if="code == 'eics-verification'"
                            />

                            <!-- import Apply for CTC Full Reg template here -->
                            <ApplyCtcFullReg
                                v-if="code == 'ctc-full-reg'"
                            />

                            <!-- import Apply Full Without Provisional Registration (Internship) template here -->
                            <ApplyFullWithoutIntern
                                v-if="code == 'full-intern-reg'"
                                v-bind:applicationType="applicationType"
                            />

                            <!-- import Apply Full Without Provisional Registration (Internship) template here -->
                            <ApplyFullWithoutAq
                                v-if="code == 'full-aq-reg'"
                                v-bind:applicationType="applicationType"
                            />
                        </div>


                        <div class="col-sm-12 text-center" v-if='showErrorStatus'>
                            <base-alert :type="errorType">
                                <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                                <span class="alert-inner--text">
                                    {{errorMsg}}
                                </span>
                            </base-alert>
                        </div>
                    </card>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import ApplyAddQualification from './ApplyAddQualification.vue';
    import ApplyChangeName from './ApplyChangeName.vue';
    import Logs from './Logs.vue';
    import ApplyFullUpgrade from './ApplyFullUpgrade';
    import ApplyLifeLicense from './ApplyLifeLicense';
    import ApplyRevalidation from './ApplyRevalidation';
    import ApplyEics from './ApplyEics';
    import ApplyCtcFullReg from './ApplyCtcFullReg';
    import ApplyFullWithoutIntern from './ApplyFullWithoutIntern';
    import ApplyFullWithoutAq from './ApplyFullWithoutAq';

    export default {
        components: {
            ApplyAddQualification,
            ApplyChangeName,
            Logs,
            ApplyFullUpgrade,
            ApplyLifeLicense,
            ApplyRevalidation,
            ApplyEics,
            ApplyCtcFullReg,
            ApplyFullWithoutIntern,
            ApplyFullWithoutAq
        },
        name: 'Apply',
        data() {
            return {
                code: this.$route.params.code,
                applicationType: null,
                isLoadingBlade: true,
                showErrorStatus: false,
                errorType: null,
                errorMsg: null
            }
        },
        watch: {

        },
        computed: {
            ...mapGetters(['loadingBladeUrl'])
        },
        methods: {
            ...mapActions(['getApplicationType', 'getOtherPayment', 'internshipStatus']),
            loadApplicationType() {// get application type
                this.getOtherPayment().then((res) => {// check if user can pass to make payment 
                    this.showErrorStatus = false;
                    if(res.status) {// redirect payment
                        this.isLoadingBlade = true;
                        return window.location.href='/new-application-payment/'+res.application.id;
                    } else {
                        this.getPaymentDetails();// get payment details
                    }
                }).
                catch(() => {
                    this.isLoadingBlade = false;
                    this.showErrorStatus = true;
                    this.errorMsg = "Network Failure!", "Kindly reload the page...";
                    this.errorType = 'danger';
                });

            },
            getPaymentDetails() {// get payment details
                if((this.code == 'full-reg-upgrade') || (this.code == 'full-reg')) {// check if provisional doctor is upgrading to full, has completed internship
                    this.internshipStatus().then((res) => {// check internship status
                        this.showErrorStatus = false;
                        if(res.status) {// proceed to get paymentdetails
                            return this.getExactPayment();
                        } else {// show the error message
                            this.isLoadingBlade = false;
                            this.showErrorStatus = true;
                            this.errorMsg = res.msg;
                            this.errorType = 'danger';
                        }
                    }).
                    catch(() => {
                        this.isLoadingBlade = false;
                        this.showErrorStatus = true;
                        this.errorMsg = "Network Failure!, kindly reload the page...";
                        this.errorType = 'danger';
                    });
                } else {// if not full reg upgrade, then proceed to get payment details
                    return this.getExactPayment();
                }
            },

            getExactPayment() {// get exact payment item details
                let validRoutes = ['additional-pgq', 'annual-license', 'change-of-name', 'eics-verification',
                    'cert-good-standing', 'full-reg-upgrade', 'life-license', 'reval-reg', 'ctc-full-reg',
                    'full-intern-reg', 'full-aq-reg'];
                this.getApplicationType({'code' : this.code}).then((res) => {// get edu records
                    this.applicationType = res;
                    this.isLoadingBlade = false;// eslint-disable-next-line
                    if(($.inArray(this.code, validRoutes) < 0) ) {
                        this.isLoadingBlade = true;
                        return window.location.href="/new-application-payment/"+res.id;
                    }
                    this.redirectToPayment();// check for payment only
                }).
                catch(() => {
                    this.isLoadingBlade = false;
                    this.showErrorStatus = true;
                    this.errorMsg = "Network Failure!", "Kindly reload the page...";
                    this.errorType = 'danger';
                });
            },
            redirectToPayment() {//
                let codes = ['annual-license'];// accepted code array
                // eslint-disable-next-line
                if($.inArray(this.code, codes) > -1 ) {
                    this.isLoadingBlade = true;
                    this.getApplicationType({'code' : this.code}).then((res) => {// get app type
                        // this.isLoadingBlade = false;
                        return window.location.href = '/application-payment-details/'+res.code;
                    });
                }
            }
        },
        created() {
            this.loadApplicationType(); // load application type
        },
    }
</script>