<template>
    <div class="row">

        <div class="col-lg-12 text-center">
            <hr>
            <h3 class="mb-0 text-uppercase text-danger">
                If you have made the additional payment, kindly add the valid RRR below...
            </h3>
            <hr>
        </div>

        <div class="col-lg-4"></div>
        <div class="col-lg-4 text-center">
            <base-input alternative=""
                label="RRR"
                placeholder="Enter Valid RRR"
                input-classes="form-control-alternative"
                v-model="rrr"
                type="number"
                required
            />
        </div>

        <div class="col-lg-12" v-if="isLoadingBlade">
            <div class="d-flex justify-content-center">
                <img :src="loadingBladeUrl" />
            </div>
        </div>

        <div class="col-lg-12 text-center" v-if='showErrorStatus && ! isLoadingBlade'>
            <base-alert :type="errorType">
                <!-- <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span> -->
                <span class="alert-inner--text">
                    <h2 class="text-white">{{errorMsg}}</h2>
                </span>
            </base-alert>
        </div>

        <div class="col-lg-12" v-if='! showErrorStatus && ! isLoadingBlade'>
            <hr>
            <base-button
                type="danger"
                @click="$emit('displayAddRrrPage')"
            >
                <i class="fa fa-arrow-left"></i>
                Go Back
            </base-button>

            <base-button
                type="success"
                class="right"
                :disabled="! rrr"
                @click="updateAdditionalRrr()"
            >
                {{ isloading ? 'Please Wait...' : 'Add Now'}}
                <i class="fa fa-arrow-right"></i>
            </base-button>
            <br><br><br><br>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import swal from 'sweetalert2';// import sweet alert

    export default {
        name: 'AddIncompletePaymentRrr',
        props: ['loadAssessmentBatch'],
        data() {
            return {
                rrr: null,
                isloading: false,
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isLoadingBlade: false,
                id: localStorage.getItem('applicantId'),
            }
        },
        computed: {
            ...mapGetters(['websiteUrl', 'loadingBladeUrl'])
        },
        methods: {
            ...mapActions(['addAdditionalAssessmentRrr']),
            updateAdditionalRrr() {
                if(this.rrr) {
                    this.isloading = true;
                    this.preloader();
                    let data = { rrr : this.rrr, id : this.id, type: 'rrr' };
                    this.addAdditionalAssessmentRrr(data).then((res) => {
                        this.isloading = false;
                        if(res.status) {
                            swal.fire("Payment Added!", res.msg, "success");
                            this.loadAssessmentBatch();
                        } else {
                            this.isloading = false;
                            swal.fire("Verification Failed!", res.msg, "warning");
                        }
                    }).
                    catch(() => {
                        this.isloading = false;
                        swal.fire("Error Ocurred!", "Network Failure!, kindly reload the page...", "warning");
                    });
                } else {
                    swal.fire("Empty field(s)!", 'Invalid RRR entered and try again...', "warning");
                }
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        }
    }
</script>

<style scoped>
    .no-padding-bottom {
        padding-bottom: 0rem !important
    }
    .right {
        float: right;
    }
</style>

<style>
    .form-control-label {
        width: 100%;
    }
    .height-50 {
        height: 50px;
    }
    .borderLine {
        border: 1px solid #ededed;
    }
</style>