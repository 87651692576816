<template>
    <div class="row" v-if="records && records.length > 0">

        <div class="col-lg-12">

            <h2 class="mb-0 text-center">
                PREVIOUS PAYMENTS 
                <hr class="my-4"/>
                <h4 class="text-danger text-center">
                    Note that you can re-query each of the transactions below, before making 
                    another new payment attempt below. Remita might delay in updating their server
                    kindly wait for 24 hours, and try again...
                </h4>
            </h2>
            
            <hr class="my-4">

            <div class="card shadow">
                <div class="table-responsive">
                    <base-table 
                        class="table align-items-center table-flush"
                        thead-classes="thead-light"
                        tbody-classes="list"
                        :data="records"
                    >
                        <template slot="columns">
                            <th width="2%">S/N</th>
                            <th width="10%">Transaction Number</th>
                            <th width="20%">Application Date</th>
                            <th width="10%">Payment Status</th>
                            <th width="5%">&nbsp;</th>
                        </template>

                        <template 
                            slot-scope="{row, index}"
                        >
                        <td class="budget">
                            {{ index+1}}
                        </td>
                        <td>
                            {{row.trans_no}}
                        </td>
                        <td>
                            {{ row.application_date|getDateFormat }}
                        </td>
                        <td class="text-uppercase">
                            {{  replaceString(row.payment_status) }}
                        </td>
                        <td>
                            <base-button 
                                type="danger" size="sm"
                                @click="$emit('reQueryTransaction', row.trans_no)"
                            >
                                <i class="fa fa-history"></i> Re-Query
                            </base-button>
                        </td>

                        </template>

                    </base-table>
                </div>
            

            </div>
            <hr class="my-4">
        </div>
    </div>
</template>

<script>
    import moment from 'moment';

    export default {
        name: 'NMALevyData',
        props: ["records"],
        filters: {
            getDateFormat(value) {
                return moment(value).format('Do MMM, YYYY');
            }
        },
        methods: {
            replaceString(value) {
                return value.replace("-", " ");    
            }
        },
    }
</script>