<template>
    <div>
        <!-- <hr class="my-4">  -->
        <h2 class="mb-0 text-uppercase text-center">
            RRR Number Generated Details
        </h2>

        <hr class="my-4">

        <h4 class="mb-0 text-danger">
            Note: Write down the RRR number below and keep it safe, proceed to designated bank to
            make payment. Kindly keep the RRR number safe untill you add the payment to the portal.
        </h4>

        <hr class="my-4">

        <div class="table-responsive">
            <table class="table table-bordered">
                <tbody>
                    <td>
                        <h4>RRR Number</h4>
                    </td>
                    <td>
                        <h4 class="text-capitalize font-weight-light">
                            {{ record.payment_ref }}
                        </h4>
                    </td>
                    <td>
                        <h4>Payee's Name</h4>
                    </td>
                    <td>
                        <h4 class="text-capitalize font-weight-light">
                            {{ getFullName(record.user_data) }}
                        </h4>
                    </td>
                </tbody>
                <tbody>
                    <td>
                        <h4>Application Type</h4>
                    </td>
                    <td>
                        <h4 class="text-capitalize font-weight-light">
                            {{ record.application_type ? record.application_type.name : 'N/A' }}
                        </h4>
                    </td>
                    <td>
                        <h4>Amount</h4>
                    </td>
                    <td>
                        <h4 class="text-capitalize font-weight-light">
                            {{ record.amount | formatPrice }}
                        </h4>
                    </td>
                </tbody>
                <tbody>
                    <td>
                        <h4>Trans Number</h4>
                    </td>
                    <td>
                        <h4 class="text-capitalize font-weight-light">
                            {{ record.order_id }}
                        </h4>
                    </td>
                    <td>
                        <h4>Date Generated</h4>
                    </td>
                    <td>
                        <h4 class="font-weight-light">
                            {{ record.created_at | formatDate }}
                        </h4>
                    </td>
                </tbody>
            </table>
        </div>

        <hr class="my-4">

        <div class="text-center">
            <form :action="getRootUrl+'/print-rrr-invoice'" method="POST" target="_blank">
                <input type="hidden" name="id" :value="record.id" />
                <input type="hidden" name="user_data_id" :value="user.basic_profile.id" />

                <base-button
                    type="primary" size="lg"
                    nativeType="submit"
                >
                    <i class="fa fa-print"></i>
                    Print Invoice
                </base-button>

                <base-button
                    type="danger" size="lg"
                    @click="$emit('removeAddPayment', true)"
                >
                    <i class="fa fa-reply"></i>
                    Go Back
                </base-button>
            </form>
        </div>

    </div>

</template>

<script>
    import moment from 'moment';
import { mapGetters } from 'vuex';

    export default {
        name: 'GeneratedRrr',
        props: ["record"],
        filters: {
            formatDate(value) {
                return value ? moment(value).format('Do MMM, YYYY [at] h:mm:ss a') : 'N/A';
            },
            formatPrice(value) {
                if(value) {
                    let val = (value/1).toFixed(2).replace(',', '.');
                    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")+ ' NGN';
                } else {
                    return 'N/A';
                }
            }
        },
        computed: {
            ...mapGetters(['getRootUrl', 'user'])
        },
        methods: {
            getFullName(user) {
                let name = user.first_name ? user.first_name+' ' : '';
                name += user.other_name ? user.other_name+' ' : '';
                name += user.last_name ? user.last_name+' ' : '';
                return name.replace(',', '');
            }
        }
    }
</script>

<style scoped>
.right {
    float: right;
}
</style>