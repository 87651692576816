<template>
    <div>
        <div slot="header" class="border-0">
            <div class="row align-items-center">

                <div class="col-8">
                    <h3 class="mb-0 text-uppercase">{{ this.$route.name }}</h3>
                    <br>
                    <span class="text-danger">
                        Kindly select appropriate Practice Type and proceed to either generate RRR for payment
                        through designated banks or pay online now
                    </span>
                </div>
                <div class="col-4 text-right">
                    <a :href="websiteUrl" class="btn btn-sm btn-danger">
                        <i class="fa fa-reply"></i> Home
                    </a>
                </div>
            </div>
        </div>

        <div v-if="! showPaymentPage">
            <div class="row" v-if='! isLoadingBlade && ! backError'>
                <div class="col-lg-12">
                    <hr>
                </div>
                <div class="col-lg-6">
                    <base-input alternative=""
                        label="Practice Type"
                        required
                    >
                        <select
                            class="form-control form-control-alternative"
                            v-model="record.type"
                        >
                            <option :value="null" disabled selected>--- Select One ---</option>
                            <option value="medical">Medical Practice</option>
                            <option value="dental">Dental Practice</option>
                        </select>
                    </base-input>
                </div>

                <div class="col-lg-6">
                    <base-input alternative=""
                        label="Payee's Full Name"
                        placeholder="Payee's Full Name"
                        input-classes="form-control-alternative"
                        v-model="record.name"
                        required
                    />
                </div>

                <div class="col-lg-6">
                    <base-input alternative=""
                        label="Payee's Email Address"
                        placeholder="Payee's Email Address"
                        input-classes="form-control-alternative"
                        v-model="record.email"
                        required
                    />
                </div>

                <div class="col-lg-6">
                    <base-input alternative=""
                        label="Payee's Phone Number"
                        placeholder="Payee's Phone Number"
                        input-classes="form-control-alternative"
                    >
                    <!-- v-model="profile.phone"  -->
                        <VuePhoneNumberInput
                            v-model="tel"
                            @update="updatePhoneNumber"
                            default-country-code="NG"
                            :no-example="true"
                            :clearable="true"
                        />
                    </base-input>
                </div>

            </div>

            <div class="col-lg-12" v-if="isLoadingBlade">
                <div class="d-flex justify-content-center">
                    <img :src="loadingBladeUrl" />
                </div>
            </div>

            <div class="col-lg-12 text-center" v-if='showErrorStatus && ! isLoadingBlade'>
                <base-alert :type="errorType">
                    <!-- <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span> -->
                    <span class="alert-inner--text">
                        <h2 class="text-white">{{errorMsg}}</h2>
                    </span>
                </base-alert>
            </div>
        </div>

        <RemediationPaymentData
            :type="type"
            :record="record"
            :serviceType="serviceType"
            :country="country"
            v-on:goBack="goBack"
            v-if="showPaymentPage"
        />

        <div v-if="! showErrorStatus && ! showPaymentPage">
            <hr>
            <router-link
                to="/remediation/programme"
                class="btn btn-danger"
                :disabled="isLoadingBlade"
            >
                <i class="fa fa-arrow-left"></i>
                Go Back
            </router-link>

            <base-button
                type="success"
                class="right"
                :disabled="isLoadingBlade"
                @click="getPayments()"
            >
                {{ isLoadingBlade ? 'Please Wait...' : 'Proceed Now'}}
                <i class="fa fa-arrow-right"></i>
            </base-button>
            <br><br><br><br>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import RemediationPaymentData from './RemediationPaymentData';
    import VuePhoneNumberInput from 'vue-phone-number-input';
    import 'vue-phone-number-input/dist/vue-phone-number-input.css';
    import swal from 'sweetalert2';// import sweet alert

    export default {
        name: 'RemediationGenerate',
        components: {
            RemediationPaymentData,
            VuePhoneNumberInput
        },
        data() {
            return {
                isLoadingBlade: true,
                record: {
                    name: null,
                    email: null,
                    phone: null,
                    type: null
                },
                tel: null,
                country: null,
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                type: null,
                serviceType: null,
                showPaymentPage: false,
                backError: false
            }
        },
        computed: {
            ...mapGetters(['websiteUrl', 'loadingBladeUrl']),
        },
        methods: {
            ...mapActions(['getAssessmentTypes', 'getRemediationBatch', 'getRemediationPayment', 'getLocations']),
            updatePhoneNumber(event) {
                if(event && event.isValid && event.e164) {
                    this.record.phone = event.formattedNumber;
                }
            },
            goBack() {
                this.emptyFields();
                this.tel = null;
                this.category = null;
                this.showPaymentPage = false;
                this.loadRemediationBatch();
            },
            loadRemediationBatch() {
                localStorage.removeItem('applicantId');
                this.emptyFields();
                this.backError = false;
                this.showPaymentPage = false;
                this.getRemediationBatch().then((res) => {
                    this.getLocations();// get locations
                    this.isLoadingBlade = false;
                    if(res.status) {
                        this.batch = res.batch;
                        this.showErrorStatus = false;
                    } else {
                        this.backError = true;
                        this.showErrorStatus = true;
                        this.errorMsg = res.msg;
                        this.errorType = 'danger';
                    }
                }).
                catch(() => {
                    this.backError = true;
                    this.showErrorStatus = true;
                    this.isLoadingBlade = false;
                    this.errorMsg = "Network Failure!, kindly reload the page...";
                    this.errorType = 'danger';
                });
            },
            getPayments() {
                this.isloading = true;
                this.preloader();// show loading
                let status = this.validatedData();// valid input data
                if(status) {// update
                    this.getRemediationPayment({type : this.record.type}).then((res) => {
                        this.isloading = false;
                        if(res.status) {
                            this.type = res.type;
                            this.serviceType = res.service_type;
                            this.showPaymentPage = true;
                            swal.close()
                        } else {
                            let msg = "Payment details could not be found, reload page";
                            swal.fire("Error Ocurred!", msg, "warning");
                        }
                    }).
                    catch(() => {
                        this.isloading = false;
                        let msg = "Network Failure!, kindly reload the page...";
                        swal.fire("Error Ocurred!", msg, "warning");
                    });
                }   else {
                    this.isloading = false;
                    swal.close();
                }
            },
            emptyFields() {
                this.record = {
                    name: null,
                    email: null,
                    phone: null,
                    type: null
                };
            },
            validatedData() {
                let data = this.record;
                data.phone != null ? data.phone : this.showInuptError('Payees\'s Phone Number');
                data.email != null ? data.email : this.showInuptError('Payees\'s Email Address');
                data.name != null ? data.name : this.showInuptError('Payees\'s Full Name');
                data.type != null ? data.type : this.showInuptError('Practice Type');
                return data.name && data.email && data.phone && data.type;
            },
            showInuptError(name) {
                this.isloading = false;
                this.showErrorStatus = true
                this.errorType = 'warning'
                this.errorMsg = 'Invalid ' + name + ' has been entered';
                var self = this;
                setTimeout(function() { self.showErrorStatus = false; }, 5000);
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        created() {
            this.loadRemediationBatch();
        }
    }
</script>

<style scoped>
    .no-padding-bottom {
        padding-bottom: 0rem !important
    }
    .right {
        float: right;
    }
</style>

<style>
    .form-control-label {
        width: 100%;
    }
    .height-50 {
        height: 50px;
    }
    .borderLine {
        border: 1px solid #ededed;
    }
</style>