<template>
    <div>
        <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center">
            <span class="mask bg-gradient-danger opacity-8"></span>
        </base-header>
        
        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col-xl-12 order-xl-1">
                    <card shadow type="secondary">

                        <div class="pl-lg-4">
                            <!-- Import Annual License Payment blade here -->
                            <AnnualLicensePayment
                                v-if="(code == 'annual-license') && ! hasNotPaidMwanLevy"
                            />

                            <MwanPayment
                                :loadApplication="loadApplication"
                                v-if="hasNotPaidMwanLevy"
                            />

                        </div>

                    </card>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import AnnualLicensePayment from './AnnualLicensePayment.vue';
    import MwanPayment from '../../../NewAccount/MwanPayment.vue';

    export default {
        components: {
            AnnualLicensePayment,
            MwanPayment
        },
        name: 'Payment',
        data() {
            return {
                code: this.$route.params.code
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl', 'user']),
            hasNotPaidMwanLevy() {// if doctor is medical women and has not paid levy
                const mwan = this.user && this.user.basic_profile && this.user.basic_profile.mwan_levy ? this.user.basic_profile.mwan_levy : false;
                return mwan && mwan.status && ! mwan.pay_status;
            }
        },
        methods: {
            ...mapActions(['fetchUserProfile']),
            loadApplication() {
                return false;
            }
        },
        created() {
            this.fetchUserProfile();// load user profile
        },
    }
</script>

<style scoped>
.right {
    float: right;
}
</style>