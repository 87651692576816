<template>
    <div class="pl-lg-4">

        <div class="col-lg-12" v-if="isLoadingBlade">
            <div class="d-flex justify-content-center">
                <img :src="loadingBladeUrl" />
            </div>
        </div>


        <div class="col-lg-12" v-if="! isLoadingBlade">

            <base-alert :type="errorType" dismissible v-if='showMsgStatus'>
                <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                <span class="alert-inner--text">
                    {{errorMsg}}
                </span>
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </base-alert>

            <div class="row">
                <div class="col-lg-3">
                    <base-input
                        label="First Date of entry into Nigeria "
                        placeholder="First Entry Day"
                        addon-left-icon="ni ni-calendar-grid-58"
                        required

                    >
                        <flat-picker
                            slot-scope="{focus, blur}"
                            @on-open="focus"
                            @on-close="blur"
                            :config="{allowInput: true}"
                            class="form-control datepicker"
                            v-model="records.first_entry_day"
                            :disabled="lock"
                        >
                        </flat-picker>
                    </base-input>
                </div>
                <div class="col-lg-4">
                    <base-input
                        label="Intended date of departure (out of Nigeria)"
                        addon-left-icon="ni ni-calendar-grid-58"
                        required

                    >
                        <flat-picker
                            slot-scope="{focus, blur}"
                            @on-open="focus"
                            @on-close="blur"
                            :config="{allowInput: true}"
                            class="form-control datepicker"
                            v-model="records.departure_day"
                            :disabled="lock"
                        >
                        </flat-picker>
                    </base-input>
                </div>
                <div class="col-lg-5">
                    <base-input alternative=""
                        label="Possible duration of stay in Nigeria (Months)"
                        placeholder="Duration of stay"
                        input-classes="form-control-alternative text-capitalize"
                        v-model="records.duration_of_stay"
                        type="number"
                        required
                        :readonly="lock"
                    />
                </div>
            </div>

            <div class="row">
                <div class="col-lg-12">
                    <base-input alternative=""
                        label="Purpose of entry into Nigeria"
                        required
                    >
                        <textarea
                            rows="2"
                            class="form-control form-control-alternative"
                            placeholder="Purpose of entry into Nigeria"
                            v-model="records.purpose_of_entry"
                            :readonly="lock"
                        ></textarea>
                    </base-input>
                </div>
            </div>

            <!-- import Add Jurisdictions template here -->
            <AddJurisdictions
                v-bind:records="records"
                v-bind:loadAdditionalRecords="loadAdditionalRecords"
            />

            <hr class="my-4" />
            <div class="row">
                <div class="col-lg-10">
                    <h4>
                        Have you ever been the subject of an investigation or disciplinary action
                        for professional misconduct in Nigeria or Jurisdiction you have worked?
                    </h4>
                </div>
                <div class="col-lg-2">
                    <base-switch
                        v-if="records.disciplinary == 'no'"
                        :value="false"
                        @click="changeDisciplinary()"
                        :disabled="lock"
                    ></base-switch>
                    <base-switch
                        v-if="records.disciplinary == 'yes'"
                        :value="true"
                        @click="changeDisciplinary()"
                        :disabled="lock"
                    ></base-switch>
                </div>
            </div>

            <br>
            <div class="row">
                <div class="col-lg-10">
                    <h4>
                        Have you been previously registered in Nigeria?
                    </h4>
                </div>
                <div class="col-lg-2">
                    <base-switch
                        v-if="records.previousely_registered == 'no'"
                        :value="false"
                        @click="changePreviouselyRegistered()"
                        :disabled="lock"
                    ></base-switch>
                    <base-switch
                        v-if="records.previousely_registered == 'yes'"
                        :value="true"
                        @click="changePreviouselyRegistered()"
                        :disabled="lock"
                    ></base-switch>
                </div>
            </div>

            <br>
            <div class="row">
                <div class="col-lg-10">
                    <h4>
                        Have you left Nigeria since previous Registraton?
                    </h4>
                </div>
                <div class="col-lg-2">
                    <base-switch
                        v-if="records.left_nigeria == 'no'"
                        :value="false"
                        @click="changeLeftNigeria()"
                        :disabled="lock"
                    ></base-switch>
                    <base-switch
                        v-if="records.left_nigeria == 'yes'"
                        :value="true"
                        @click="changeLeftNigeria()"
                        :disabled="lock"
                    ></base-switch>
                </div>
            </div>

            <!-- import Add Jurisdictions template here -->
            <AddReferees
                v-bind:records="records"
                v-bind:loadAdditionalRecords="loadAdditionalRecords"
            />

            <base-alert :type="errorType" dismissible v-if='showMsgStatus'>
                <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                <span class="alert-inner--text">
                    {{errorMsg}}
                </span>
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </base-alert>

            <div class="pl-lg-4">
                <base-button
                    type="success"
                    @click="updateAdditionalRecords()"
                    :disabled="lock"
                >
                    <i class="fa fa-save" @if='! isloading'></i>
                    {{isloading ? 'Please Wait' : 'Save Changes'}}
                </base-button>
            </div>

        </div>

    </div>


</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import flatPicker from "vue-flatpickr-component";
    import "flatpickr/dist/flatpickr.css";
    import AddJurisdictions from '../../NewAccount/AdditionalRecords/AddJurisdictions';
    import AddReferees from '../../NewAccount/AdditionalRecords/AddReferees';
    import swal from 'sweetalert';// import sweet alert

    export default {
        components: {
            flatPicker,
            AddJurisdictions,
            AddReferees
        },
        name: 'AdditionalRecords',
        data() {
            return {
                records: {
                    id: null,
                    first_entry_day: null,
                    duration_of_stay: null,
                    departure_day: null,
                    purpose_of_entry: null,
                    disciplinary: 'no',
                    previousely_registered: 'no',
                    left_nigeria: 'no',
                    jurisdictions: [],
                    referees: [],
                    user_data_id: null
                },
                addRecords: [],
                showMsgStatus: false,
                errorType: null,
                errorMsg: null,
                isloading: false,
                displayPageError: false,
                isLoadingBlade: true
            };
        },
        computed: {
            ...mapGetters(['lock', 'loadingBladeUrl', 'user']),
            hasJursAndReferees() {
                let record = this.addRecords;
                let jurisdictions = record.jurisdictions ? record.jurisdictions.length > 0 : false;
                let referees = record.referees ? record.referees.length > 0 : false;
                return jurisdictions && referees;
            },
            hasAdditionalRecords() {
                let record = this.addRecords;
                let data = {
                    first_entry_day: record.first_entry_day,
                    duration_of_stay: record.duration_of_stay,
                    departure_day: record.duration_of_stay,
                    purpose_of_entry: record.duration_of_stay
                };
                let jurisdictions = record.jurisdictions ? record.jurisdictions.length > 0 : false;
                let referees = record.referees ? record.referees.length > 0 : false;
                return this.isEmpty(data) && jurisdictions && referees;
            },
            isTempDoctor() {
                let regType = this.user && this.user.basic_profile && this.user.basic_profile.has_sub_registration_type
                    && this.user.basic_profile.has_sub_registration_type.registration_type ?
                    this.user.basic_profile.has_sub_registration_type.registration_type : false;
                return regType ? regType.code == 'temporary-reg' : false;
            }
        },
        methods: {
            ...mapActions(['saveJurisdictions', 'removeJurisdiction', 'saveReferees',
                'removeReferee', 'updateUserAdditionalRecords', 'getUserAdditionalRecords']),
            loadAdditionalRecords() {// load additional records
                const userId = this.$store.state.user.basicProfile.id;
                this.getUserAdditionalRecords({'id' : userId}).then((res) => {
                    // console.log(res);
                    this.isLoadingBlade = false;
                    // update additional records details
                    this.addRecords = res;
                    if(res.id) { this.records.id = res.id;  }
                    if(res.first_entry_day) { this.records.first_entry_day = res.first_entry_day;  }
                    if(res.duration_of_stay) { this.records.duration_of_stay = res.duration_of_stay;  }
                    if(res.departure_day) { this.records.departure_day = res.departure_day;  }
                    if(res.purpose_of_entry) { this.records.purpose_of_entry = res.purpose_of_entry;  }
                    if(res.disciplinary) { this.records.disciplinary = res.disciplinary;  }
                    if(res.previousely_registered) { this.records.previousely_registered = res.previousely_registered;  }
                    if(res.left_nigeria) { this.records.left_nigeria = res.left_nigeria;  }
                    this.records.jurisdictions = res.jurisdictions;
                    this.records.referees = res.referees;
                }).
                catch(() => {
                    this.isLoadingBlade = false;
                    this.displayPageError = true;
                    this.errorMsg = 'Error occurred while loading page, kindly reload page...'
                });
            },
            updateAdditionalRecords() {
                this.isloading = true;
                let data = this.records;
                let status = this.validatedData(data);// valid input data
                if(status) {// check for user id & perform update
                    data.user_data_id = this.$store.state.user.basicProfile.id;
                    this.updateUserAdditionalRecords(data).then((res) => {
                        this.isloading = false;
                        if(res.status) {// load updated profile
                            let msg = 'Additional Records was updated successfully';
                            swal('Record Updated', msg, 'success');
                            this.loadAdditionalRecords();
                        } else {
                            swal('Error Occurred', res.msg, 'warning');
                        }
                    }).
                    catch(() => {
                        this.isloading = false;
                        let msg = 'Network Failure! reload page and try again';
                        swal('Error Occurred', msg, 'warning');
                    });
                }
            },
            validatedData(data) {
                let validData = { first_entry_day: null, duration_of_stay: null, departure_day: null, purpose_of_entry: null };
                validData.first_entry_day = data.first_entry_day != null ? data.first_entry_day : this.showInuptError('First day of entry');
                validData.duration_of_stay = data.duration_of_stay != null ? data.duration_of_stay : this.showInuptError('Duration of stay');
                validData.departure_day = data.departure_day != null ? data.departure_day : this.showInuptError('Departure day');
                validData.purpose_of_entry = data.purpose_of_entry != null ? data.purpose_of_entry : this.showInuptError('Purpose of entry');
                return this.isEmpty(validData) ? true : false;
            },
            isEmpty(obj) {// check for empty field
                return ! Object.keys(obj).some(k => !obj[k]);
            },
            showErrorMsg() {
                let msg = 'An error occurred, kindly reload page and try again';
                swal("Error occurred!", msg, "warning");
            },
            showInuptError(name) {
                this.isloading = false;
                this.showMsgStatus = true
                this.errorType = 'warning'
                this.errorMsg = 'Invalid ' + name + ' has been entered';
                var self = this;
                setTimeout(function() { self.showMsgStatus = false; }, 5000);
            },
            changeDisciplinary() {// change disciplinary switch
                const status = this.records.disciplinary == "yes" ? "no" : "yes";
                this.records.disciplinary = status;
            },
            changePreviouselyRegistered() {// change Registered switch
                const status = this.records.previousely_registered == "yes" ? "no" : "yes";
                this.records.previousely_registered = status;
            },
            changeLeftNigeria() {// change Left Nigeria switch
                const status = this.records.left_nigeria == "yes" ? "no" : "yes";
                this.records.left_nigeria = status;
            }
        },
        created() {
            this.loadAdditionalRecords();//load necessary additional records
        },
    }
</script>

<style scoped>
    .marginLeft10 {
        margin-left: 10px;
    }
</style>