<template>
    <div>
        <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center">
            <span class="mask bg-gradient-danger opacity-8"></span>
        </base-header>


        <div class="container-fluid mt--7">
            <div class="row">

                <div class="col-xl-12 order-xl-1">
                    <card shadow type="secondary">
                        <div slot="header" class="bg-white border-0">
                            <div class="row align-items-center">
                                <div class="col-8">
                                    <h3 class="mb-0 text-uppercase">My License</h3>
                                </div>
                                <div class="col-4 text-right">
                                    <router-link to="/" class="btn btn-sm btn-primary">Dashboard</router-link>
                                </div>
                            </div>
                        </div>

                        <template>
                            <tabs fill class="flex-column flex-md-row">
                                <card shadow>
                                    <tab-pane title="Purchase License">
                                        <span slot="title">
                                            <i class="ni ni-spaceship"></i>
                                            Purchase License
                                        </span>
                                        <!-- import User Purchase License template here -->
                                        <PurchaseLicense
                                            :isTempReg="isTempReg"
                                            :getTempRegUrl="getTempRegUrl"
                                        />
                                    </tab-pane>

                                    <tab-pane
                                        title="CPD Points"
                                        v-if="! hideCpdPoint"
                                    >
                                        <span slot="title">
                                            <i class="ni ni-books"></i>
                                            CPD Points
                                        </span>
                                        <!-- import CPD Points template here -->
                                        <CpdPoints />
                                    </tab-pane>

                                    <tab-pane
                                        title="Previous Licenses Purchase"
                                        v-if="! isTempReg"
                                    >
                                        <span slot="title">
                                            <i class="ni ni-spaceship"></i>
                                            Previous Licenses Purchase
                                        </span>
                                        <!-- import User Previous License template here -->
                                        <PreviousLicense />
                                    </tab-pane>

                                </card>
                            </tabs>

                        </template>
                    </card>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import CpdPoints from './CpdPoints.vue';
    import PurchaseLicense from './PurchaseLicense.vue';
    import PreviousLicense from './PreviousLicense';

    export default {
        components: {
            CpdPoints,
            PurchaseLicense,
            PreviousLicense
        },
        name: 'License',
        computed: {
            isProvisionalRegistration() {
                const profile = this.$store.state.user.basicProfile;// get user basic profile
                const subregTypeCode = this.$store.state.user.basicProfile.
                        has_sub_registration_type;// get user reg type
                return profile && subregTypeCode &&
                        subregTypeCode.registration_type.code == 'provisional-reg' ? true : false;
            },
            isTempReg() {
                const profile = this.$store.state.user.basicProfile;// get user basic profile
                const subregTypeCode = this.$store.state.user.basicProfile.has_sub_registration_type;// get user reg type
                return profile && subregTypeCode &&
                        subregTypeCode.registration_type.code == 'temporary-reg' ? true : false;
            },
            hideCpdPoint() {
                return this.isProvisionalRegistration || this.isTempReg;
            },
            getTempRegUrl() {// get exact temp renewal url
                const subRegType = this.$store.state.user.basicProfile.has_sub_registration_type;
                let code = subRegType ? subRegType.code : false;
                return this.isTempReg && code ? '/renew-temp/'+ code + '-renewal' : '/';
            }
        },
    }
</script>