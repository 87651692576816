<template>
    <div class="row">

        <div class="col-lg-12" v-if="isLoadingBlade">
            <div class="d-flex justify-content-center">
                <img :src="loadingBladeUrl" />
            </div>
        </div>

        <div class="container" v-if='! showErrorStatus && ! isLoadingBlade'>
            <div class="row">
                <div class="col-lg-6">
                    <base-input alternative=""
                        label="Country of Destination"
                        required
                    >
                        <autocomplete
                            v-if="locations && locations.countries"
                            input-class="form-control form-control-alternative"
                            placeholder="Type to Search Country"
                            showNoResults
                            :disableInput="showReSendButton"
                            :source="locations.countries"
                            :initialDisplay="record.country"
                            @selected="changeOtherCountry"
                        >
                        </autocomplete>
                    </base-input>
                </div>

                <div class="col-lg-6" v-if="showStates">
                    <base-input alternative=""
                        label="State of Destination"
                        required
                    >
                        <autocomplete
                            v-if="locations && locations.states"
                            input-class="form-control form-control-alternative"
                            placeholder="Type to Search State"
                            showNoResults
                            :disableInput="showReSendButton"
                            :source="locations.states"
                            v-model="record.state_id"
                            :initialDisplay="(hasState ? hasState.name : '')"
                        >
                        </autocomplete>
                    </base-input>
                </div>

                <!-- <div class="col-lg-6" v-if="showOtherCountry">
                    <base-input alternative=""
                        label="Other Country of Destination"
                        placeholder="Enter Country of Destination"
                        input-classes="form-control-alternative"
                        v-model="record.other_country"
                        required
                        :readonly="showReSendButton"
                    />
                </div> -->

                <div :class="'col-lg-'+(showStates ? 12 : 6)">
                    <base-input alternative=""
                        label="Purpose of Travel"
                        required
                        >
                        <select
                            class="form-control form-control-alternative"
                            v-model="record.purpose_of_travel"
                            @change="changeOtherPurpose()"
                            :disabled="showReSendButton"
                        >
                            <option value="null" disabled>--- Select One ---</option>
                            <option value="For further education">
                                For further education
                            </option>
                            <option value="Professional practice">
                                Professional practice
                            </option>
                            <option value="To join spouse">
                                To join spouse
                            </option>
                            <option value="Dissatisfaction with facilities for medical/dental practice in Nigeria">
                                Dissatisfaction with facilities for medical/dental practice in Nigeria
                            </option>
                            <option value="Dissatisfaction with income from medical/dental practice in Nigeria">
                                Dissatisfaction with income from medical/dental practice in Nigeria
                            </option>
                            <option value="Dissatisfaction with the regulation of medical/dental practice in Nigeria">
                                Dissatisfaction with the regulation of medical/dental practice in Nigeria
                            </option>
                            <option value="others">Others (Please, specify)</option>
                        </select>
                    </base-input>
                </div>

                <div class="col-lg-6" v-if="showOtherPurpose">
                    <base-input alternative=""
                        label="Other Purpose of Travel"
                        placeholder="Enter Other Purpose of Travel"
                        input-classes="form-control-alternative"
                        v-model="record.other_purpose_of_travel"
                        required
                        :readonly="showReSendButton"
                    />
                </div>

                <div class="col-lg-12">
                    <base-input alternative=""
                        label="Full Destination Address"
                        required
                    >
                        <textarea
                            rows="2"
                            class="form-control form-control-alternative"
                            placeholder="Enter Full Destination Address"
                            v-model="record.address"
                            :readonly="showReSendButton"
                        ></textarea>
                    </base-input>
                </div>

                <div class="col-lg-6">
                    <base-input alternative=""
                        label="Duration of Stay"
                        placeholder="Example 2 weeks"
                        input-classes="form-control-alternative"
                        v-model="record.duration_of_stay"
                        required
                        :readonly="showReSendButton"
                    />
                </div>

                <div class="col-lg-6" v-if="! isProvisional && ! isFullWithout">
                    <base-input alternative=""
                        label="Provisional Registration Number (Numbers Only)"
                        placeholder="Enter Provisional Registration Number"
                        input-classes="form-control-alternative"
                        v-model="record.prov_reg_number"
                        type="number"
                        required
                        :readonly="showReSendButton"
                    />
                </div>

                <div class="col-lg-6" v-if="! isFullWithout">
                    <base-input
                        label="Date of Provisional Registration Certificate Date"
                        addon-left-icon="ni ni-calendar-grid-58"
                        required

                    >
                        <flat-picker
                            slot-scope="{focus, blur}"
                            @on-open="focus"
                            @on-close="blur"
                            :config="{allowInput: true}"
                            class="form-control datepicker"
                            v-model="record.prov_reg_date"
                            :disabled="showReSendButton"
                        >
                        </flat-picker>
                    </base-input>
                </div>

                <div class="col-lg-6" v-if="! isProvisional">
                    <base-input
                        label="Date of Full Registration Certificate Date"
                        addon-left-icon="ni ni-calendar-grid-58"
                        required

                    >
                        <flat-picker
                            slot-scope="{focus, blur}"
                            @on-open="focus"
                            @on-close="blur"
                            :config="{allowInput: true}"
                            class="form-control datepicker"
                            v-model="record.full_reg_date"
                            :disabled="showReSendButton"
                        >
                        </flat-picker>
                    </base-input>
                </div>
            </div>
        </div>

        <div class="col-sm-12 text-center">
            <base-alert :type="errorType" v-if='showInputErrorStatus'>
                <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                <span class="alert-inner--text">
                    {{errorMsg}}
                </span>
            </base-alert>
        </div>

        <div class="col-lg-12" v-if='! showErrorStatus && ! isLoadingBlade'>
            <br>
            <base-button
                type="success"
                @click="saveLogs()"
                v-if="! showReSendButton"
            >
                <i class="fa fa-save"></i>
                {{isloading ? 'Please Wait...' : 'Save & Continue'}}
            </base-button>

            <base-button
                type="success"
                @click="reSendApplication(applicationId)"
                v-if="showReSendButton"
            >
                <i class="fa fa-upload"></i>
                Re-send Application
            </base-button>

            <div class="right">
                <base-button
                    type="danger"
                    @click="editApplication()"
                    v-if="showReSendButton"
                >
                    <i class="fa fa-pen"></i>
                    Edit Application
                </base-button>
            </div>
        </div>

        <div class="col-sm-12 text-center">
            <base-alert :type="errorType" v-if='showErrorStatus'>
                <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                <span class="alert-inner--text">
                    {{errorMsg}}
                </span>
            </base-alert>
        </div>

    </div>

</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import flatPicker from "vue-flatpickr-component";
    import "flatpickr/dist/flatpickr.css";
    import swal from 'sweetalert2';// import sweet alert
    import Autocomplete from 'vuejs-auto-complete';

    export default {
        name: 'LOGS',
        components: {
            flatPicker,
            Autocomplete
        },
        data() {
            return {
                record: {
                    country: null,
                    state_id: null,
                    address: null,
                    purpose_of_travel: null,
                    duration_of_stay: null,
                    prov_reg_number: null,
                    prov_reg_date: null,
                    full_reg_date: null,
                    other_country: null,
                    other_purpose_of_travel: null
                },
                showOtherPurpose: false,
                showStates: false,
                // showOtherCountry: false,
                showInputErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isloading: false,
                showErrorStatus: false,
                applicationId: this.$route.params.id,
                logId: null,
                isRejected: false,
                showReSendButton: false,
                isLoadingBlade: true
            }
        },
        computed: {
            ...mapGetters(['locations', 'loadingBladeUrl', 'user']),
            isProvisional() {// check if user is provisional
                let user = this.user;
                return user && user.basic_profile && user.basic_profile.has_sub_registration_type
                    && user.basic_profile.has_sub_registration_type.registration_type &&
                    user.basic_profile.has_sub_registration_type.registration_type &&
                    user.basic_profile.has_sub_registration_type.registration_type.code == 'provisional-reg';
            },
            isFullWithout() {// check if user is full without provisional
                let user = this.user;
                return user && user.basic_profile && user.basic_profile.has_sub_registration_type
                    && user.basic_profile.has_sub_registration_type.registration_type &&
                    user.basic_profile.has_sub_registration_type.registration_type &&
                    user.basic_profile.has_sub_registration_type.registration_type.code
                        == 'full-without-provisional-reg';
            },
            hasState() {
                let state = this.locations && this.locations.states ?
                            this.locations.states.filter(item => item.id == this.record.state_id) : null;
                return state ? state[0] : {};
            }
        },
        methods: {
            ...mapActions(['getUserPendingLog', 'addNewLogs', 'getLocations', 'getApplication',
                'reSubmitApplication']),
            changeOtherCountry(e) {// change other Institution
                let data = e.selectedObject;
                this.showStates = false;
                this.record.country = null;
                this.record.state_id = null;
                if(data.id == 164) {// check for nigeria
                    this.showStates = true;
                }
                this.record.country = data.name;
            },
            changeOtherPurpose() {// change other purpose
                let value = this.record.purpose_of_travel;
                return this.showOtherPurpose = (value == 'others') ? true : false;
            },
            loadPendingLogs() {// load details
                if(this.applicationId) {// edit pending AQ application
                    this.getApplication({'id' : this.applicationId}).then((res) => {
                        this.isRejected = res.status == 'rejected' ? true : false;
                        this.updateLogRecord(res);// update Log record for editing
                        this.isLoadingBlade = false;
                    }).
                    catch(() => {
                        this.isLoadingBlade = false;
                        this.showErrorStatus = true;
                        this.errorMsg = "Network Failure!", "Kindly reload the page...";
                        this.errorType = 'danger';
                    });
                } else {
                    let id = this.$store.state.user.userId;// get user id
                    this.getUserPendingLog({'id' : id}).then((res) => {// get pending Aq
                        if(res.app_status) {// redirect to edit UI
                            return window.location.href ='/pending-application/'+res.application.id;
                        }
                    }).
                    catch(() => {
                        this.showErrorStatus = true;
                        this.errorMsg = "Network Failure!, kindly reload the page...";
                        this.errorType = 'danger';
                    });
                    this.isLoadingBlade = false;
                }
            },
            updateLogRecord(res) {// update Log Record for editing
                let data = res.has_good_standing;
                let user = res.user_data;
                this.showStates = false;
                this.record.country = data.country;
                this.record.state_id = data.state_id;
                this.record.address = data.address;
                this.record.purpose_of_travel = data.purpose_of_travel;
                this.record.duration_of_stay = data.duration_of_stay;
                this.record.prov_reg_number = data.prov_reg_number ? data.prov_reg_number : user.provisional_reg_no;
                this.record.prov_reg_date = data.prov_reg_date ? data.prov_reg_date : user.provisional_reg_date;
                this.record.full_reg_date = data.full_reg_date ? data.full_reg_date : user.full_reg_date;
                this.logId = data.id;
                if(data.country && data.country.toLowerCase() == 'nigeria' && data.state_id) {
                    this.showStates = true;
                }
            },
            saveLogs() {// save LOGs
                let data = this.validatedInputs();// validate inputs
                if(data) {// update files
                    data.state_id = this.record.state_id;
                    this.isloading = true;
                    data.user_data_id = this.$store.state.user.userDataId;// get user id
                    data.code = this.$route.params.code;
                    data.trans_no = this.genRandomString();// generate trans no
                    data.option = data.code ? 'save' : 'update';// check save/update option
                    data.id = this.logId;// get id
                    this.addNewLogs(data).then((res) => {
                        this.isloading = false;
                        if(res.status && this.isRejected) {
                            let msg = "Application was updated successfully";
                            swal.fire("Application Updated!", msg, "success");
                            return this.showReSendButton = true;
                        } else if(res.status && ! this.isRejected) {
                            return window.location.href='/application-payment/'+res.application_id;
                        } else {
                            swal.fire("Error Occurred!", "An error occurred while saving, try again", "warning");
                        }
                    }).
                    catch(err => {
                        this.isloading = false;
                        swal.fire(err.message+"!", "An error occurred while saving, try again", "warning");
                    });
                }
            },
            genRandomString() {
                var length = 12;
                var text = '';
                var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
                for (var i=0; i < length; i++) {
                    text += possible.charAt(Math.floor(Math.random() * possible.length));
                }
                return text;
            },
            validatedInputs() {
                let data = this.record;
                let addRecord = { country: null, address: null, purpose_of_travel: null,
                    duration_of_stay: null, prov_reg_number: null, prov_reg_date: null,
                    full_reg_date: null };
                addRecord.full_reg_date  = data.full_reg_date == null && ! this.isProvisional ?
                    this.showInuptError('Full Registration Date') : data.full_reg_date;
                addRecord.prov_reg_date  = data.prov_reg_date == null && ! this.isFullWithout ?
                    this.showInuptError('Provisional Registration Date') : data.prov_reg_date;
                addRecord.prov_reg_number  = data.prov_reg_number == null && ! this.isProvisional
                    && ! this.isFullWithout ? this.showInuptError('Provisional Registration Number')
                    : data.prov_reg_number;
                addRecord.duration_of_stay  = data.duration_of_stay == null ?
                    this.showInuptError('Duration of Stay') : data.duration_of_stay;
                addRecord.address  = data.address == null ?
                    this.showInuptError('Full Destination Address') : data.address;
                // validate Pourpose of Travel
                if((data.purpose_of_travel == null || data.purpose_of_travel == 'others')
                    && data.other_purpose_of_travel != null) {
                    addRecord.purpose_of_travel = data.other_purpose_of_travel;
                } else if(data.purpose_of_travel != null && data.purpose_of_travel != 'others') {
                    addRecord.purpose_of_travel = data.purpose_of_travel;
                } else if((data.purpose_of_travel == null || data.purpose_of_travel == 'others')
                    && data.other_purpose_of_travel == null) {
                    this.showInuptError('Purpose of Travel');
                }
                if(data.country && data.country.toLowerCase() == 'nigeria'
                        && data.state_id == null) {// show state error
                    this.showInuptError('State of Destination');
                } else if(data.country && data.country.toLowerCase() == 'nigeria' && data.state_id) {
                    addRecord.state_id = data.state_id;
                }
                // validate Country of Destination
                if((data.country == null || data.country == 'others')
                    && data.other_country != null) {
                    addRecord.country = data.other_country;
                } else if(data.country != null && data.country != 'others') {
                    addRecord.country = data.country;
                } else if((data.country == null || data.country == 'others')
                    && data.other_country == null) {
                    this.showInuptError('Country of Destination');
                }
                if(this.isProvisional) {// unset some unnecessary field for provisional
                    delete addRecord.prov_reg_number;
                    delete addRecord.full_reg_date;
                } else if(this.isFullWithout) {// unset some unnecessary field for Full Without
                    delete addRecord.prov_reg_number;
                    delete addRecord.prov_reg_date;
                }
                return this.isEmpty(addRecord) ? addRecord : false;
            },
            isEmpty(obj) {// check for empty field
                return ! Object.keys(obj).some(k => !obj[k]);
            },
            showInuptError(name) {
                this.isloading = false;
                this.showInputErrorStatus = true
                this.errorType = 'warning'
                this.errorMsg = 'Invalid ' + name + ' has been entered';
                var self = this;
                setTimeout(function() { self.showInputErrorStatus = false; }, 5000);
            },
            editApplication() {
                return this.showReSendButton = false;
            },
            reSendApplication(id) {// re-send rejected application
                swal.fire({
                    title: 'Are you sure?',
                    text: "You must have corrected the issue before proceeding to re-submit application!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, I have corrected it!'
                }).then((result) => {
                    if (result.value) {
                        this.makeReSubmitApiCall(id);// make api call
                    }
                });
            },
            makeReSubmitApiCall(id) {
                this.preloader(); // show loading
                this.reSubmitApplication(id).then((res) => {// get application record
                    if(res.status) {
                        swal.fire("Application Submitted!", res.msg, "success");
                        return window.location.href='/applications';
                    } else {
                        swal.fire("Error Occurred!", res.msg, "warning");
                    }
                }).
                catch(() => {
                    let msg = "Network Failure! , kindly reload the page...";
                    swal.fire("Network Failure!", msg, "warning");
                });
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b> ...',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        created() {
            this.getLocations(); // get locations
            this.loadPendingLogs();// load add qual records
        },
    }
</script>
<style scoped>
    .right {
        float: right;
    }
</style>

<style>
    .form-control-label {
        width: 100%;
    }
</style>