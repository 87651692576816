<template>
    <div>
        <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center">
            <span class="mask bg-gradient-danger opacity-8"></span>
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col-xl-12 order-xl-1">
                    <card shadow type="secondary">
                        <div class="pl-lg-4">
                            <!-- Import Temp payment blade here -->
                            <TempRenewalPayment 
                                :application="application"
                                v-if="application && application.application"
                            />

                            <div class="col-lg-12" v-if="isLoadingBlade">
                                <div class="d-flex justify-content-center">
                                    <img :src="loadingBladeUrl" />
                                </div>
                            </div>

                            <div class="col-sm-12 text-center">
                                <base-alert :type="errorType" v-if='showErrorStatus'>
                                    <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                                    <span class="alert-inner--text">
                                        {{ errorMsg }}
                                    </span>
                                </base-alert>
                            </div>
                        </div>
                    </card>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'; //AddQualPayment
    import TempRenewalPayment from './TempRenewalPayment.vue';

    export default {
        components: {
            TempRenewalPayment
        },
        name: 'TempRenewal',
        data() {
            return {
                code: this.$route.params.code,
                application: {},
                isLoadingBlade: true,
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isloading: false
            }
        },
        filters: {
            formatPrice(value) {
                if(value) {
                    let val = (value/1).toFixed(2).replace(',', '.');
                    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                } else {
                    return 'N/A';
                }
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl'])
        },
        methods: {
            ...mapActions(['getTemporaryRegDetails']),
            loadApplication() {// get application
                this.getTemporaryRegDetails(this.code).then((res) => {
                    this.application = res;
                    this.isLoadingBlade = false;
                }).
                catch(() => {
                    this.isLoadingBlade = false;
                    this.showErrorStatus = true;
                    this.errorMsg = "Network Failure!", "Kindly reload the page...";
                    this.errorType = 'danger';
                });
            }
        },
        created() {
            this.loadApplication();// load application
        },
    }
</script>

<style scoped>
.right {
    float: right;
}
</style>