<template>
    <div>
        <hr class="my-4"> 
        <h2 class="mb-0 text-uppercase text-center">
            RRR Number Generated Details
        </h2>

        <hr class="my-4">

        <h4 class="mb-0 text-danger text-center">
            Note: Write down the RRR number below and keep it safe, proceed to designated bank to
            make payment. Kindly keep the RRR number safe untill you add the payment to the portal.
        </h4>

        <hr class="my-4">

        <div class="table-responsive">
            <table class="table table-bordered">
                <tbody>
                    <td>
                        <h4>RRR Number</h4>
                    </td>
                    <td>
                        <h4 class="text-capitalize font-weight-light">
                            {{ rrr.RRR }}
                        </h4>
                    </td>
                    <td>
                        <h4>Payee's Name</h4>
                    </td>
                    <td>
                        <h4 class="text-capitalize font-weight-light">
                            {{ record.name }}
                        </h4>
                    </td>
                </tbody>
                <tbody>
                    <td>
                        <h4>Application Type</h4>
                    </td>
                    <td>
                        <h4 class="text-capitalize font-weight-light">
                            {{ type.name }}
                        </h4>
                    </td>
                    <td>
                        <h4>Amount</h4>
                    </td>
                    <td>
                        <h4 class="text-capitalize font-weight-light">
                            {{ (amount ? amount : type.has_payment.amount) | formatPrice }}
                        </h4>
                    </td>
                </tbody>
                <tbody>
                    <td>
                        <h4>Trans Number</h4>
                    </td>
                    <td>
                        <h4 class="text-capitalize font-weight-light">
                            {{ orderId }}
                        </h4>
                    </td>
                    <td>
                        <h4>Date Generated</h4>
                    </td>
                    <td>
                        <h4 class="font-weight-light">
                            {{ formatDateNow }}
                        </h4>
                    </td>
                </tbody>
            </table>
        </div>

        <hr class="my-4">

        <div class="text-center">
            <router-link
                to="/remediation/programme"
                class="btn btn-danger"
            >
                <i class="fa fa-reply"></i>
                Go Back
            </router-link>
        </div>
        <hr class="my-4">

    </div>

</template>

<script>
    import moment from 'moment';

    export default {
        name: 'RemediationGenerateRrr',
        props: ["record", "type", "rrr", "orderId", "amount"],
        filters: {
            formatPrice(value) {
                if(value) {
                    let val = (value/1).toFixed(2).replace(',', '.');
                    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")+ ' NGN';
                } else {
                    return 'N/A';
                }
            }
        },
        computed: {
            formatDateNow() {
                return moment().format('Do MMM, YYYY [at] h:mm:ss a');
            }
        }
    }
</script>

<style scoped>
.right {
    float: right;
}
</style>