<template>
    <div class="row">

        <div class="col-lg-12" v-if="isLoadingBlade">
            <div class="d-flex justify-content-center">
                <img :src="loadingBladeUrl" />
            </div>
        </div>

        <div class="container" v-if='! showErrorStatus && ! isLoadingBlade'>
            <div class="row">
                <div class="col-lg-12" v-if="hasDocuments">
                    <h6 class="heading-small text-muted mb-4">
                        Upload Files &nbsp;&nbsp;&nbsp;&nbsp;
                        <span class="text-danger">
                            <i class="fa fa-info-circle"></i> Only PDF Files (Not More than 800KB) are supported for each file
                        </span>
                    </h6>

                    <div class="container ct-example-row">
                        <!-- import User Document File template here -->
                        <DocumentFile
                            :documents="documents"
                            :itemsId="items.id"
                            :loadUserDocuments="loadUserDocuments"
                        />
                    </div>
                    <br>
                </div>


                <div class="col-lg-6">
                    <base-input alternative=""
                        label="Country of Internship Placement"
                        required
                    >
                        <autocomplete
                            v-if="locations && locations.countries"
                            input-class="form-control form-control-alternative"
                            placeholder="Type to Search Country"
                            showNoResults
                            :disableInput="showReSendButton"
                            :source="locations.countries"
                            v-model="record.country_id"
                            :initialDisplay="(hasCountry ? hasCountry.name : '')"
                        >
                        </autocomplete>
                    </base-input>
                </div>

            </div>
            <hr class="my-4"/>
        </div>

        <div class="col-sm-12 text-center">
            <base-alert :type="errorType" v-if='showInputErrorStatus'>
                <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                <span class="alert-inner--text">
                    {{errorMsg}}
                </span>
            </base-alert>
        </div>


        <div class="col-lg-12" v-if='! showErrorStatus && ! isLoadingBlade'>
            <br>
            <base-button
                type="success"
                @click="createApplication()"
                v-if="! showReSendButton && ! hasDocuments"
                :disabled="enableSaveButton"
            >
                <i class="fa fa-save"></i>
                {{isloading ? 'Please Wait...' : 'Save & Continue'}}
            </base-button>

            <base-button
                type="success"
                @click="reSendApplication(applicationId)"
                v-if="showReSendButton"
            >
                <i class="fa fa-upload"></i>
                Re-send Application
            </base-button>

            <div class="right">
                <base-button
                    type="danger"
                    @click="editApplication()"
                    v-if="showReSendButton"
                >
                    <i class="fa fa-pen"></i>
                    Edit Application
                </base-button>
            </div>
        </div>

        <div class="col-sm-12 text-center">
            <base-alert :type="errorType" v-if='showErrorStatus'>
                <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                <span class="alert-inner--text">
                    {{errorMsg}}
                </span>
            </base-alert>
        </div>

    </div>

</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import DocumentFile from './ApplyFullWithoutIntern/DocumentFile';
    import moment from 'moment';
    import swal from 'sweetalert2';// import sweet alert
    import Autocomplete from 'vuejs-auto-complete';

    export default {
        name: 'ApplyFullWithoutIntern',
        props: ['applicationType'],
        components: {
            DocumentFile,
            Autocomplete
        },
        data() {
            return {
                id: this.$route.params.id,
                code: this.$route.params.code,
                subRegId: 6,
                record: {
                    id: null,
                    country_id: null,
                    user_data_id: null,
                    update: false
                },
                showErrorStatus: false,
                showInputErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isloading: false,
                isLoadingBlade: true,
                updateStatus: false,
                isRejected: false,
                showReSendButton: false,
                applicationId: null,
                // hasDocuments: true,
                showDocuments: false,
                items: {},
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl', 'locations']),
            enableSaveButton() {
                return this.record && this.record.country_id == null;
            },
            documents() {
                if(this.items.documents && this.items.documents.length > 0) {
                    let documents = []
                    for(let document of this.items.documents) {
                        if(! document.hasFile) {
                            documents.push(document);
                        }
                    }
                    return documents;
                } else {
                    return [];
                }
            },
            hasDocuments() {
                return this.documents && this.documents.length > 0;
            },
            hasCountry() {
                let country = this.locations && this.locations.countries ?
                            this.locations.countries.filter(item => item.id == this.record.country_id) : null;
                return country ? country[0] : {};
            }
        },
        methods: {
            ...mapActions(['saveUserEics','getUserRegistrationDetails', 'getApplication', 'updateInternshipCountry',
                'createNewUserApplication', 'getLocations', 'reSubmitApplication', 'getUserDocuments']),
            loadApplication() {
                this.getLocations();
                if(this.id) {// load exact application
                     this.getExactApplication(this.id);
                } else {// load any pending application
                    return this.loadPendingApplication();
                }
            },
            loadUserDocuments() {// update documents
                this.getUserDocuments(this.subRegId).then((res) => {// eslint-disable-next-line
                    $('#file').val('');
                    if(res.status) {
                        this.items = res;
                        this.showDocuments = true;
                        this.showErrorStatus = false;
                    } else {
                        this.showDocuments = false;
                        this.showErrorStatus = true;
                        this.errorMsg = 'Error occurred! Documents wasn\'t fetched, reload page';
                    }
                });
            },
            getExactApplication(id) {// load exact application
                this.getApplication({'id' : id}).then((res) => {
                    this.applicationId = res.id;
                    this.updateRecord(res);// update record for editing
                    this.record.update = res ? true : false;
                    this.isRejected = res.status == 'rejected' ? true : false;
                    this.isLoadingBlade = false;
                }).
                catch(() => {
                    this.isLoadingBlade = false;
                    this.showErrorStatus = true;
                    this.errorMsg = "Network Failure!", "Kindly reload the page...";
                    this.errorType = 'danger';
                });
            },
            loadPendingApplication() {// load details
                this.getUserRegistrationDetails(this.code).then((res) => {
                    let previousApps = res.previous_applications;
                    if(previousApps && previousApps.length > 0) {
                        let app = previousApps[0];
                        this.record.update = app;
                        this.updateRecord(app);// update record for editing
                    }
                    this.isLoadingBlade = false;
                }).
                catch(() => {
                    this.isLoadingBlade = false;
                    this.showErrorStatus = true;
                    this.errorMsg = "Network Failure!", "Kindly reload the page...";
                    this.errorType = 'danger';
                });
            },
            updateRecord(data) {// update AQ Record for editing
                if(data) {
                    this.record.country_id = data.country_id;
                    this.record.id = data.id;
                }
            },
            createApplication() {// create application
                this.preloader();// show loading
                if(this.record.id) {
                    let data = { 'id' : this.record.id, 'country_id' : this.record.country_id };
                    this.updateInternshipCountry(data).then((res) => {//update country
                        if(res.status && this.isRejected) {
                            let msg = "Application was updated successfully";
                            swal.fire("Application Updated!", msg, "success");
                            return this.showReSendButton = true;
                        } else if(res.status && ! this.isRejected) {
                            swal.close();
                            return window.location.href='/application-payment/'+this.record.id;
                        } else {
                            swal.fire("Error Occurred!", "An error occurred while saving, try again", "warning");
                        }
                    }).
                    catch(() => {
                        let message = 'Network Failure!, kindly reload the page...';
                        swal.fire('Network Failure!', message, 'warning');
                    });
                } else {
                     return this.createNewApplication(); // create new application
                }
            },
            createNewApplication() {// create application
                let data = {};
                data.type = this.applicationType;// get exact application type
                data.user_data_id = this.$store.state.user.userDataId;// get user data id
                data.application_type_id = this.applicationType.id;// apllication type id
                data.trans_no = this.genRandomString();// generate trans no
                data.application_date = this.getCurrentDate();// get current date
                data.country_id = this.record.country_id;// get internship country
                this.createNewUserApplication(data).then((res) => {// create application
                    if(res.status) {// update transaction
                        swal.close();
                        return window.location.href='/application-payment/'+res.application.id;
                    } else {
                        let msg = 'An error occurred while proccessing, try again...';
                        swal.fire('Error Occurred!', msg, 'warning');
                    }
                }).
                catch(() => {
                    let message = 'While creating application, kindly reload and try again';
                    swal.fire('Error ocurred', message, 'warning');
                    return false;
                });
            },
            getCurrentDate() {// get current date time
                let date = new Date().toJSON().slice(0,10).replace(/-/g,'-');
                return moment(date).format('YYYY-MM-DD');
            },
            genRandomString() {
                var length = 12;
                var text = '';
                var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
                for (var i=0; i < length; i++) {
                    text += possible.charAt(Math.floor(Math.random() * possible.length));
                }
                return text;
            },
            editApplication() {
                return this.showReSendButton = false;
            },
            reSendApplication(id) {// re-send rejected application
                swal.fire({
                    title: 'Are you sure?',
                    text: "You must have corrected the issue before proceeding to re-submit application!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, I have corrected it!'
                }).then((result) => {
                    if (result.value) {
                        this.makeReSubmitApiCall(id);// make api call
                    }
                });
            },
            makeReSubmitApiCall(id) {
                this.preloader(); // show loading
                this.reSubmitApplication(id).then((res) => {// get application record
                    if(res.status) {
                        swal.fire("Application Submitted!", res.msg, "success");
                        return window.location.href='/applications';
                    } else {
                        swal.fire("Error Occurred!", res.msg, "warning");
                    }
                }).
                catch(() => {
                    let msg = "Network Failure! , kindly reload the page...";
                    swal.fire("Network Failure!", msg, "warning");
                });
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b> ...',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        created() {
            this.loadApplication();// load records
            this.loadUserDocuments();
        },
    }
</script>

<style scoped>
    .right {
        float: right;
    }
</style>