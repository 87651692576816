<template>
    <div>
        <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center no-padding-bottom">
            <span class="mask bg-gradient-danger opacity-8"></span>
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col-xl-12 order-xl-1">
                    <br><br><br>
                    <card shadow type="secondary">
                        <div slot="header" class="bg-white border-0">
                            <div class="row align-items-center">

                                <div class="col-8">
                                    <h3 class="mb-0 text-uppercase">{{ this.$route.name }}</h3>
                                    <br>
                                    <span class="text-danger">
                                        Fill the form below....
                                    </span>
                                </div>
                                <div class="col-4 text-right">
                                    <a :href="websiteUrl" class="btn btn-sm btn-danger">
                                       <i class="fa fa-reply"></i> Home
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-lg-6">
                                <base-input alternative=""
                                    label="Complainant's Full Name"
                                    placeholder="Enter Your Full Name"
                                    input-classes="form-control-alternative text-capitalize"
                                    v-model="record.name"
                                    required
                                />
                            </div>

                            <div class="col-lg-6">
                                <base-input alternative=""
                                    label="Complainant's Email Address"
                                    placeholder="Enter Your Email Address"
                                    input-classes="form-control-alternative"
                                    v-model="record.email"
                                    required
                                />
                            </div>

                            <div class="col-lg-6">
                                <base-input alternative=""
                                    label="Complainant's Phone Number (Optional)"
                                    placeholder="Enter Your Phone Number"
                                    input-classes="form-control-alternative"
                                >
                                <!-- v-model="profile.phone"  -->
                                    <VuePhoneNumberInput
                                        v-model="tel"
                                        @update="updatePhoneNumber"
                                        default-country-code="NG"
                                        :no-example="true"
                                        :clearable="true"
                                    />
                                </base-input>
                            </div>

                            <div class="col-lg-6">
                                <base-input alternative=""
                                    label="State Location"
                                    required
                                >
                                    <autocomplete
                                        v-if="locations && locations.states && locations.states.length > 0"
                                        input-class="form-control form-control-alternative"
                                        placeholder="Type and search for State"
                                        showNoResults
                                        :source="locations.states"
                                        v-model="record.state_id"
                                    >
                                    </autocomplete>
                                </base-input>
                            </div>

                            <div class="col-lg-12">
                                <base-input alternative=""
                                    label="Complain Details"
                                    required
                                >
                                    <textarea
                                        rows="5"
                                        class="form-control form-control-alternative"
                                        placeholder="Enter Complain Details"
                                        v-model="record.message"
                                    ></textarea>
                                </base-input>
                            </div>

                            <div class="col-lg-12 borderLine">
                                <br>
                                <input
                                    type="file"
                                    id="file" style="display: none"
                                    required
                                    @change="onFileSelected"
                                >
                                <h4 class="height-50">
                                    Affidavit *
                                    <em class="text-danger">
                                        (PDF file not more than 500KB)
                                    </em>
                                </h4>
                                <a
                                    href="javascript:void()"
                                    class="text-danger font-weight-bold"
                                    @click="emptyImageDetails()"
                                    v-if="record.image.file"
                                >
                                    File Selected&nbsp;&nbsp;
                                    <i class="fa fa-times"></i>
                                </a>
                                <br><br v-if="record.image.file">
                                <base-button
                                    size="sm" type="danger"
                                    @click="selectFile()"
                                    v-if="record.image.file"
                                >
                                    <i class="fa fa-hand-pointer"></i>
                                    Change File
                                </base-button>
                                <base-button
                                    size="sm" type="primary"
                                    @click="selectFile()"
                                    v-if="! record.image.file"
                                >
                                    <i class="fa fa-hand-pointer"></i>
                                    Select File
                                </base-button>
                                <br><br>
                            </div>
                        </div>

                        <div class="row">

                            <div
                                class="col-lg-12 text-center"
                                v-if="showErrorStatus"
                            >
                                <base-alert :type="errorType">
                                    <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                                    <span class="alert-inner--text">
                                        {{errorMsg}}
                                    </span>
                                </base-alert>
                                <br>
                            </div>

                            <div class="col-lg-12" v-if="! isNotRobot">
                                <vue-recaptcha
                                    :sitekey="recaptchaKey"
                                    :loadRecaptchaScript="true"
                                    @verify="proceedSubmit"
                                ></vue-recaptcha>
                            </div>

                            <div class="col-lg-12">
                                <br>
                                <base-button
                                    type="success"
                                    @click="sendMessage()"
                                    :disabled="! isNotRobot"
                                >
                                    <i class="fa fa-save"></i>
                                    {{isloading ? 'Please Wait...' : 'Send Complain'}}
                                </base-button>
                                <br><br><br>
                            </div>
                        </div>
                    </card>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import VuePhoneNumberInput from 'vue-phone-number-input';
    import 'vue-phone-number-input/dist/vue-phone-number-input.css';
    import VueRecaptcha from 'vue-recaptcha';
    import swal from 'sweetalert2';// import sweet alert
    import Autocomplete from 'vuejs-auto-complete'

    export default {
        components: {
            VuePhoneNumberInput,
            VueRecaptcha,
            Autocomplete
        },
        name: 'AddComplaints',
        data() {
            return {
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                record: {
                    name: null,
                    email: null,
                    phone: null,
                    message: null,
                    state_id: null,
                    image: {
                        ext: null,
                        file: null
                    },
                },
                tel: null,
                isloading: false,
                isNotRobot: false
            }
        },
        computed: {
            ...mapGetters(['websiteUrl', 'recaptchaKey', 'locations']),
        },
        methods: {
            ...mapActions(['sendComplaint', 'verifyRecaptcha', 'getLocations']),
            sendMessage() {
                this.isloading = true;
                this.preloader();// show loading
                let status = this.validatedData();// valid input data
                if(status && this.isNotRobot) {// send complain
                    this.sendComplaint(this.record).then((res) => {
                        this.isloading = false;
                        swal.close();
                        if(res.status) {
                            this.emptyFields();
                            swal.fire("Complaint Sent!", res.msg, "success");
                        } else {
                            swal.fire("Error Ocurred!", res.msg, "warning");
                        }
                    });
                } else if(! this.isNotRobot) {
                    this.isloading = false;
                    swal.fire("Error Ocurred!", "Click on the Google reCAPTCHA above", "warning");
                } else {
                    this.isloading = false;
                    swal.close();
                }
            },
            validatedData() {
                let data = this.record;
                data.image.file != null ? true : this.showInuptError('File');
                data.message != null ? true : this.showInuptError('Complain Details');
                data.state_id != null ? true : this.showInuptError('State Location');
                data.email != null ? true : this.showInuptError('Email Address');
                data.name != null ? true : this.showInuptError('Full Name');
                let status = data.name && data.email && data.message && data.image.file;
                return status ? true : false;
            },
            showInuptError(name) {
                this.isloading = false;
                this.showErrorStatus = true
                this.errorType = 'warning'
                this.errorMsg = 'Invalid ' + name + ' has been entered';
                var self = this;
                setTimeout(function() { self.showErrorStatus = false; }, 3000);
            },
            updatePhoneNumber(event) {
                if(event && event.isValid && event.e164) {
                    this.record.phone = event.formattedNumber;
                }
            },
            emptyFields() {
                this.record = {
                    name: null,
                    email: null,
                    phone: null,
                    message: null,
                    state_id: null,
                    image: {
                        ext: null,
                        file: null
                    },
                }
                this.tel = null;
            },
            emptyImageDetails() {
                this.record.image = {
                                        ext: null,
                                        file: null
                                    };
            },
            selectFile() {// eslint-disable-next-line
                $('#file').val('');// eslint-disable-next-line
                $('#file').click();
            },
            onFileSelected(event) {
                this.emptyImageDetails();
                let status =  this.validateFile(event.target.files[0]);
                if(status) {
                    var fileReader = new FileReader();
                    fileReader.readAsDataURL(event.target.files[0]);
                    fileReader.onload = (event) => {// convert file to binary
                    this.record.image.file = event.target.result;
                    }
                }
            },
            validateFile(file) {// check for valid file
                var fileExt = file.type.split('/').pop();
                let fileSize = file.size;
                if(fileExt == 'pdf' && fileSize <= 500000) {// for valid file and size
                    this.record.image.ext = fileExt;
                    return true;
                } else if(fileExt != 'pdf') {// invalid file
                    swal.fire("Invalid File!", "Only PDF files are accepted", "warning");
                    return false;
                } else if(fileSize > 500000) {// big file
                    swal.fire("File is too large!", "File should be less than 500KB", "warning");
                    return false;
                }
            },
            proceedSubmit(response) {
                let data = { response: response };
                this.verifyRecaptcha(data).then((res) => {
                    if(res.success) {
                        return setTimeout(() => {
                            this.isNotRobot = ! this.isNotRobot;
                        }, 1000);
                        
                    } else {
                        swal.fire("Error Occurred", "An error occurred while verifying, kindly try again", "warning");
                    }
                }).catch(() => {//check for failure
                    swal.fire("Error Occurred", "An error occurred while verifying, kindly try again", "warning");
                });
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        created() {
            this.getLocations();
        }
    }
</script>

<style scoped>
    .no-padding-bottom {
        padding-bottom: 0rem !important
    }
</style>