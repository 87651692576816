<template>
    
    <!-- Import Registration payment blade here -->
    <RegPaymentDetails 
        v-bind:application="application"
        v-bind:user="user"
        v-bind:serviceType="serviceType"
        v-bind:hasPreviousApplication="hasPreviousApplication"
        v-bind:previousApplications="previousApplications"
        v-bind:loadApplication="loadApplication"
        v-bind:displayNewPayment="displayNewPayment"
        v-bind:changeAppId="changeAppId"
        :isTempReg="isTempReg"
        :subTemReg="subTemReg"
        v-on:addPayment="addPayment"
    />

</template>

<script>
    import RegPaymentDetails from './RegPaymentDetails.vue';

    export default {
        components: {
            RegPaymentDetails
        },
        name: 'RegistrationPayment',
        props: ['application', 'user', 'serviceType', 'hasPreviousApplication', 'previousApplications', 'changeAppId',
            'addPayment', 'loadApplication', 'displayNewPayment'],
        computed: {
            subTemReg() {
                let profile = this.user && this.user.basic_profile ? this.user.basic_profile : false;
                return profile && profile.has_sub_registration_type ? profile.has_sub_registration_type : false;
            },
            isTempReg() {// is temp reg
                let type = this.subTemReg;
                if(type) {
                    let regType = type && type.registration_type ? type.registration_type : false;
                    return regType && (regType.code == 'temporary-reg');
                } 
                return false;              
            }
        }
    }
</script>

<style scoped>
.right {
    float: right;
}
</style>