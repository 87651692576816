<template>
    <div>
        <div class="col-lg-12" v-if="isLoadingBlade">
            <div class="d-flex justify-content-center">
                <img :src="loadingBladeUrl" />
            </div>
        </div>

        <hr>

        <div class="row text-center" v-if="! isLoadingBlade">
            <div class="col-lg-10">
                <h2 class="text-danger">
                    Did you seat for Assessment Exam before applying for Temporary Registration?
                </h2>
            </div>
            <div class="col-lg-2">
                <base-switch
                    v-if="! skipAq"
                    :value="false"
                    @click="checkToSkipAq(true)"
                ></base-switch>
                <base-switch
                    v-if="skipAq"
                    :value="true"
                    @click="checkToSkipAq(false)"
                ></base-switch>
            </div>
        </div>

        <div class="row text-center" v-if="skipAq && ! isLoadingBlade">
            <div class="col-lg-12">
                <hr>
                <h2 class="text-primary">
                    You can proceed since You seat for the Assessment Exam, if otherwise kindly hit on the 
                    button above to enable fill your Additional Qualification.
                </h2>
            </div>
        </div>

        <hr v-if="! skipAq && ! isLoadingBlade">

        <div class="row" v-if='! isLoadingBlade && ! showErrorStatus && ! skipAq'>
            <div class="col-lg-12 text-center">                
                <h2 class="text-primary">
                    Since You didn't seat for the Assessment Exam, kindly fill your Additional Qualification below.
                </h2>
                <hr>
            </div>

            <div class="col-lg-6">
                <base-input alternative=""
                    label="Country"
                    required
                >
                    <autocomplete
                        v-if="loadedTemplate"
                        input-class="form-control form-control-alternative"
                        placeholder="Type Country"
                        showNoResults
                        :source="locations.countries"
                        :initialDisplay="country"
                        @selected="changeCountry"
                    >
                    </autocomplete>
                </base-input>
            </div>

            <div class="col-lg-6">
                <base-input alternative=""
                    label="School Name / Institution (Post-Graduate College)"
                    required
                    >
                    <autocomplete
                        ref="schoolAutocomplete"
                        input-class="form-control form-control-alternative"
                        placeholder="Type to search Institution's Name"
                        showNoResults
                        :disableInput="schools && schools.length == 0"
                        :source="schools"
                        :initialDisplay="record.school_name"
                        resultsValue="name"
                        v-model="record.school_name"
                    >
                    </autocomplete>
                </base-input>
            </div>

            <div class="col-lg-6" v-if="showOtherInstitution">
                <base-input alternative=""
                    label="Other School/Institution Name"
                    placeholder="Enter Other School/Institution Name"
                    input-classes="form-control-alternative"
                    v-model="record.other_school_name"
                    required
                />
            </div>

            <div class="col-lg-12 text-capitalize" v-if="qualifications && qualifications.length > 0">
                <base-input alternative=""
                    label="Qualification"
                    required
                    >
                    <autocomplete
                        input-class="form-control form-control-alternative text-capitalize"
                        placeholder="Type to Search for Qualification"
                        showNoResults
                        :disableInput="qualifications && qualifications.length == 0"
                        :source="qualifications"
                        :initialDisplay="(hasQual ? hasQual : '')"
                        resultsValue="name"
                        :results-display="formattedDisplay"
                        @selected="changeQualification"
                    >
                    </autocomplete>

                    <!-- <select
                        class="form-control form-control-alternative"
                        v-model="record.qualification"
                        @change="getDegrees()"
                    >
                        <option value="null" disabled>--- Select One ---</option>
                        <option
                            v-for="qualification in qualifications"
                            :key="qualification.id+10000"
                            :value="qualification.id"

                        >
                            {{ qualification.course_title }}
                        </option>
                    </select> -->
                </base-input>
            </div>


            <div
                class="col-lg-12"
                v-if="(degrees && degrees.length > 0) || record.degree"
            >
                <base-input alternative=""
                    v-if="degrees[0] != ''"
                    label="Degree"
                    required
                >
                    <div class="container">
                        <div class="row">
                            <base-radio
                                v-for="degree in degrees"
                                :key="degree"
                                v-show="degree != ''"
                                :name="degree.trim()"
                                class="mb-3"
                                v-model="record.degree"
                                style="margin-right: 20px;padding-left: 50px;"
                            >
                                {{ degree.trim() }}
                            </base-radio>
                        </div>
                    </div>
                </base-input>
            </div>

            <div class="col-lg-6">
                <base-input
                    label="The Additional Qualification Degree Date"
                    addon-left-icon="ni ni-calendar-grid-58"
                    required

                >
                    <flat-picker
                        slot-scope="{focus, blur}"
                        @on-open="focus"
                        @on-close="blur"
                        :config="config"
                        class="form-control datepicker"
                        v-model="record.qualify_date"
                    >
                    </flat-picker>
                </base-input>
            </div>

            <div class="col-lg-6">
                <base-input
                    label="Additional Qualification Notification Date"
                    addon-left-icon="ni ni-calendar-grid-58"
                    required

                >
                    <flat-picker
                        slot-scope="{focus, blur}"
                        @on-open="focus"
                        @on-close="blur"
                        :config="config"
                        class="form-control datepicker"
                        v-model="record.notification_date"
                    >
                    </flat-picker>
                </base-input>
            </div>

            <div class="col-lg-6">
                <base-input alternative=""
                    label="Issuing Institution"
                    placeholder="Enter Issuing Institution"
                    input-classes="form-control-alternative text-capitalize"
                    v-model="record.licencing_body"
                />
            </div>

            <div class="col-lg-6">
                <base-input alternative=""
                    label="Duration of training (In weeks)"
                    placeholder="Enter Duration of training"
                    input-classes="form-control-alternative text-capitalize"
                    v-model="record.duration"
                />
            </div>
            

            <div class="col-lg-12"></div> 

            <div
                class="col-lg-6 text-center borderLine"
                v-for="(document, index) in documents"
                :key="index+20000"
            >
                <br>
                <input
                    type="file"
                    id="file" style="display: none"
                    @change="onFileSelected"
                >
                <h4 class="height-50">
                    {{ document.name }}
                </h4><br>
                <base-button
                    size="sm" type="primary"
                    v-if="! selectedCert && (document.filename =='additional_certificate.pdf')"
                    @click="selectFile(document.filename)"
                >
                    <i class="fa fa-hand-pointer"></i>
                    {{ aqFiles.certificate_file ? 'Change File' : 'Select File' }}
                </base-button>

                <base-button
                    tag="a" size="sm"
                    type="success"
                    :href="documentUrl+qualificationId+'_'+document.filename"
                    target="_blank"
                    role="button"
                    aria-pressed="true"
                    v-if="aqFiles.certificate_file && ! selectedCert &&
                    (document.filename =='additional_certificate.pdf')"
                >
                    <i class="fa fa-eye"></i> View File
                </base-button>

                <base-button
                    size="sm" type="primary"
                    v-if="! selectedRegCert && (document.filename =='registration_certificate.pdf')"
                    @click="selectFile(document.filename)"
                >
                    <i class="fa fa-hand-pointer"></i>
                    {{ aqFiles.registration_file ? 'Change File' : 'Select File' }}
                </base-button>

                <base-button
                    tag="a" size="sm"
                    type="success"
                    :href="documentUrl+qualificationId+'_'+document.filename"
                    target="_blank"
                    role="button"
                    aria-pressed="true"
                    v-if="aqFiles.registration_file && ! selectedRegCert &&
                    (document.filename =='registration_certificate.pdf')"
                >
                    <i class="fa fa-eye"></i> View File
                </base-button>

                <base-button
                    size="sm" type="danger"
                    v-if="(registrationFile.name == document.filename || certificateFile.name == document.filename)
                        && (registrationFile.name || certificateFile.name)"
                    @click="removeFile(document.filename)"
                >
                    <i class="fa fa-times"></i> Remove File
                </base-button>
                <br><br>

            </div>
        </div>

        <div class="col-lg-12 text-center" v-if='! isLoadingBlade && showInputErrorStatus && ! skipAq'>
            <base-alert :type="errorType">
                <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                <span class="alert-inner--text">
                    {{errorMsg}}
                </span>
            </base-alert>
        </div>

        <div class="col-lg-12" v-if='! isLoadingBlade && ! showErrorStatus && ! skipAq'>
            <br>
            <base-button
                type="success"
                @click="saveAdditionalQualifcation()"
            >
                <i class="fa fa-save"></i>
                {{isloading ? 'Please Wait...' : saveButton }}
            </base-button>
        </div>

        <div class="col-lg-12 text-center" v-if='! isLoadingBlade && showErrorStatus'>
            <base-alert :type="errorType">
                <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                <span class="alert-inner--text">
                    {{errorMsg}}
                </span>
            </base-alert>
        </div>

        <hr class="my-4"/>

        <div class="col-lg-12">
            <base-button
                type="danger"
                @click="showPreviousPage()"
            >
                <i class="fa fa-arrow-left"></i>
                Previous
            </base-button>
            <div class="right">
                <base-button
                    type="success"
                    @click="showNextPage()"
                    v-if="(applicationId || qualificationId) && ! skipAq"
                >
                    Proceed
                    <i class="fa fa-arrow-right"></i>
                </base-button>

                <base-button
                    type="success"
                    @click="showNextPage()"
                    v-if="skipAq"
                >
                    Skip &amp; Proceed
                    <i class="fa fa-arrow-right"></i>
                </base-button>
            </div>
        </div>
    </div>


</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import flatPicker from "vue-flatpickr-component";
    import "flatpickr/dist/flatpickr.css";
    import swal from 'sweetalert2';// import sweet alert
    import Autocomplete from 'vuejs-auto-complete';

    export default {
        name: 'AddQualification',
        props: ['displayInsurance', 'displayAddRecords', 'type', 'locations'],
        components: {
            flatPicker,
            Autocomplete
        },
        data() {
            return {
                pendingId : this.$route.params.id,
                qualifications: [],
                degrees: [],
                schools: [],
                saveButton: 'Add Now',
                record: {
                    school_name: null,
                    category: null,
                    qualification: null,
                    degree: null,
                    qualify_date: null,
                    licencing_body: null,
                    duration: null,
                    other_school_name: null,
                    notification_date: null,
                    country_id: null
                },
                loadedTemplate: false,
                country: null,
                certificateFile: {
                    'name' : null,
                    'file' : null
                },
                registrationFile: {
                    'name' : null,
                    'file' : null
                },
                notificationFile: {
                    'name' : null,
                    'file' : null
                },
                aqFiles: {
                    certificate_file: false,
                    registration_file: false
                },
                application: null,
                selectedFileName: null,
                showInputErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isloading: false,
                selectedCert: false,
                selectedRegCert: false,
                showOtherInstitution: false,
                showErrorStatus: false,
                applicationId: null,
                qualificationId: null,
                hasPendingAQ: false,
                isLoadingBlade: true,
                config: {
                    allowInput: false,
                    altInput: true,// eslint-disable-next-line
                    plugins: [new monthSelectPlugin({shorthand: true, dateFormat: "Y-m-d", altFormat: "M Y"})]
                },
                skipAq: false
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl', 'getRootUrl', 'user']),
            documents() {
                return [
                    {
                        'name' : 'Postgraduate Certificate being Registered',
                        'filename' : 'additional_certificate.pdf'
                    },
                    {
                        'name' : 'Supporting Documents (optional)',
                        'filename' : 'registration_certificate.pdf'
                    }
                ];
            },
            documentUrl() {// get AQ document url
                let userFolder = '/user'+this.$store.state.user.userId;
                return this.getRootUrl+'/storage'+userFolder+'/documents/additional_qualification/';
            },
            isShortStay() {
                let profile = this.user.basic_profile;
                let code = profile && profile.has_sub_registration_type ? profile.has_sub_registration_type.code : false;
                return code && (code == 'limited-reg');
            },
            hasQual() {
                let qual = this.qualifications ?
                            this.qualifications.filter(item => item.id == this.record.qualification) : null;
                return qual ? this.formattedDisplay(qual[0]) : {};
            }
        },
        methods: {
            ...mapActions(['getAdditionalQulifications', 'getAssessmentInstitutions', 'getUserPendingAq',
                'addNewAdditionalQualification', 'getApplication', 'getLocations', 'updateTempAssessment',
                'fetchUserProfile']),
            changeQualification(e) {
                let data = e.selectedObject;
                this.record.qualification = null;
                this.record.qualification = data.id;
                this.getDegrees();
            },
            formattedDisplay (result) {
                return result && result.course_title ? result.course_title.toLowerCase() + ' [' + 
                                  (result.category ? result.category.name : '') + ']' : '';
            }, 
            setTempAssessment() {// set temporary assessment exam ui
                let value = this.user.basic_profile.is_temp_assessment;
                return this.skipAq = (value == 'yes') ? true : false;
            },
            checkToSkipAq(value) {// check to skip indemnity for license applicants only
                this.preloader();// show loading
                let status = this.skipAq ? 'no' : 'yes';
                let data = { is_temp_assessment : status, med_school_country: null, aq_country: null };
                this.updateTempAssessment(data).then((res) => {// get pending Aq
                    this.fetchUserProfile(); // refresh profile
                    this.loadAddQualifications();// load add qual records
                    swal.close();
                    if(res.status) {// update
                        this.skipAq = value;
                    } else {
                        this.skipAq = false;
                    }
                }).
                catch(() => {
                    swal.close();
                    this.skipAq = false;
                });
            },
            changeCountry(e) {// get exact institutions
                this.country = null;
                this.record.school_name = null;
                this.record.other_school_name = null;
                this.schools = [];
                this.preloader();
                this.country = e.selectedObject.name;
                this.record.country_id = e.selectedObject.id;
                this.getAssessmentInstitutions({ id : e.selectedObject.id }).then((res) => {
                    swal.close();
                    this.$refs.schoolAutocomplete.clear();
                    return this.schools = res.schools;
                });
            },
            showPreviousPage() {// go to next page
                return this.displayInsurance(true);
            },
            showNextPage() {// go to next page
                return this.displayAddRecords(true);
            },
            loadAddQualifications() {// load details
                this.loadAqAndInstitions();// load Aq And Institions
                let data = {
                    id : (this.pendingId ? this.pendingId : this.$store.state.user.userId),// get user id
                    type : (this.type ? this.type : null)
                }
                this.getUserPendingAq(data).then((res) => {// get pending Aq
                    this.isLoadingBlade = false;
                    this.hasPendingAQ = res.app_status;
                    this.application = res.application;// set application
                    if(res.app_status) {// update
                        this.updateAQRecord(res);// update AQ record
                        this.saveButton = 'Update Now';
                    } else {
                        this.loadedTemplate = true;
                    }
                }).
                catch(() => {
                    this.loadedTemplate = true;
                    this.isLoadingBlade = false;
                });
            },
            loadAqAndInstitions() {// load Aq And Institions
                this.getAdditionalQulifications().then((res) => {// get add qual records
                    for(let category of res) {
                        if(category.has_qualifications && category.has_qualifications.length > 0) {
                            for(let qual of category.has_qualifications) {
                                let record = qual;
                                record.category = category;
                                this.qualifications.push(record);
                            }
                        }
                    }
                });
                this.getLocations(); // get locations
            },
            updateAQRecord(data) {// update AQ Record for editing
                this.record.school_name = data.school_name;
                this.record.category = data.additional_qualification_category_id;
                this.record.qualification = data.additional_qualification_config_id;
                this.record.degree = data.degree;
                this.record.qualify_date = data.qualify_date;
                this.record.notification_date = data.notification_date;
                this.record.licencing_body = data.licencing_body;
                this.record.duration = data.duration;
                this.record.country_id = data.country_id;
                this.aqFiles.certificate_file = data.files.certificate_file;
                this.aqFiles.registration_file = data.files.registration_file;
                this.applicationId = (data.application ? data.application.id : null);
                this.qualificationId = data.id;
                this.getSchoolsForEditing();// load schools
                this.getDegreesForEditing();// load degrees                
            },
            getSchoolsForEditing() {// get schools for editing
                let name = this.record.school_name;
                this.getAssessmentInstitutions({ name : name }).then((res) => {
                    this.isLoadingBlade = false;
                    this.country = res.country.name;
                    this.schools = res.schools;
                    return this.loadedTemplate = true;
                });
            },
            getDegreesForEditing() {// get Qualification's degrees
                this.degrees = [];
                for(let qualification of this.qualifications) {
                    if(qualification.id == this.record.qualification) {
                        this.degrees = qualification.qualification.split(',');
                    }
                }
            },
            getDegrees() {// get Qualification's degrees
                this.degrees = [];
                this.record.degree = null;
                for(let qualification of this.qualifications) {
                    if(qualification.id == this.record.qualification) {
                        this.degrees = qualification.qualification.split(',');
                    }
                }
            },
            selectFile(name) {// eslint-disable-next-line
                $('#file').val('');
                this.selectedFileName = null;// eslint-disable-next-line
                $('#file').click();
                (name == 'additional_certificate.pdf') ? this.certificateFile.name = name :
                    this.registrationFile.name = name;
                this.selectedFileName = name;
            },
            onFileSelected(event) {
                let status =  this.validateFile(event.target.files[0]);
                // certificate file
                var fileReader = new FileReader();
                fileReader.readAsDataURL(event.target.files[0]);
                fileReader.onload = (event) => {// convert file to binary
                   (this.selectedFileName == 'additional_certificate.pdf') ?
                   this.certificateFile.file = event.target.result :
                    this.registrationFile.file = event.target.result;
                }
                return status;
            },
            validateFile(file) {// check for valid file
                if(!file) {// update seleted file name
                    (this.selectedFileName == 'additional_certificate.pdf') ?
                    this.certificateFile.name = null : this.registrationFile.name = null;
                }
                var fileExt = file.type.split('/').pop();
                let fileSize = file.size;
                if(fileExt == 'pdf' && fileSize <= 500000) {// for valid file and size
                if(this.selectedFileName == 'additional_certificate.pdf') {
                        this.selectedCert = true
                    } else {
                        this.selectedRegCert = true
                    }
                    return true;
                } else if(fileExt != 'pdf') {// invalid file
                    if(this.selectedFileName == 'additional_certificate.pdf') {
                        this.certificateFile.name = null;
                        this.selectedCert = false
                    } else {
                        this.registrationFile.name = null;
                        this.selectedRegCert = false
                    }
                    swal.fire("Invalid File!", "Only PDF files are accepted", "warning");
                    return false;
                } else if(fileSize > 500000) {// big file
                    if(this.selectedFileName == 'additional_certificate.pdf') {
                        this.certificateFile.name = null;
                        this.selectedCert = false
                    } else {
                        this.registrationFile.name = null;
                        this.selectedRegCert = false
                    }
                    swal.fire("File is too large!", "File should be less than 500KB", "warning");
                    return false;
                }
            },
            removeFile(name) {// eslint-disable-next-line
                $('#file').val('');
                if(name == 'additional_certificate.pdf') {
                    this.certificateFile.name = null;
                    this.certificateFile.savedEvent = null;
                    this.certificateFile.file = null;
                    this.selectedCert = false;
                } else {
                    this.registrationFile.name = null;
                    this.registrationFile.savedEvent = null;
                    this.registrationFile.file = null;
                    this.selectedRegCert = false;
                }
            },
            saveAdditionalQualifcation() {// save add qual 
                let data = this.validatedInputs();// validate inputs
                if(data) {// update files
                    this.isloading = true;
                    data.certificate_file = this.certificateFile;
                    data.registration_file = this.registrationFile;
                    data.notification_file = this.notificationFile;
                    data.degree = this.record.degree;// get degree
                    data.user_data_id = this.$store.state.user.userDataId;// get user id
                    data.user_id = this.$store.state.user.userId;// get user id
                    data.code = 'additional-pgq';
                    if(this.application && this.application.trans_no) {// generate trans no
                        data.trans_no = this.application.trans_no;
                    } else {
                        data.trans_no = this.genRandomString();
                    }
                    // if(! this.pendingId) { data.trans_no = this.genRandomString(); }// generate trans no
                    data.id = this.applicationId;// get id
                    data.option = this.applicationId ? 'update' : 'save';// check save/update option
                    data.create_app = (this.isShortStay || (this.type == 'rejected-temp')); // only create application for long stay
                    data.aq_id = this.qualificationId;
                    data.country_id = this.record.country_id;
                    this.addNewAdditionalQualification(data).then((res) => {// add AQ
                        this.fetchUserProfile(); // refresh profile
                        this.loadAddQualifications();// load add qual records
                        this.isloading = false;
                        this.emptyFileName();
                        if(res.status) {                            
                            swal.fire("Record Updated!", "Record was updated successfully", "success");
                        } else {
                            swal.fire("Error Occurred!", "An error occurred while saving, try again", "warning");
                        }
                    }).
                    catch(err => {
                        this.isloading = false;
                        this.emptyFileName();
                        this.loadAddQualifications();// load add qual records
                        swal.fire(err.message+"!", "An error occurred while saving, try again", "warning");
                    });
                }
            },
            emptyFileName() {
                this.certificateFile.name = null;
                this.selectedFileName = null;
                this.registrationFile.name = null;
                this.selectedCert = false;
                this.selectedRegCert = false;
            },
            genRandomString() {
                var length = 12;
                var text = '';
                var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
                for (var i=0; i < length; i++) {
                    text += possible.charAt(Math.floor(Math.random() * possible.length));
                }
                return text;
            },
            validatedInputs() {
                let data = this.record;
                let certFile = null;
                let addRecord = { school_name: null, qualification: null, qualify_date: null,
                                licencing_body: null, duration: null, notification_date: null };
                certFile = (this.certificateFile.name == null && ! this.aqFiles.certificate_file) ?
                    this.showInuptError('Postgraduate Certificate File') : true;
                addRecord.duration  = data.duration == null ?
                    this.showInuptError('Duration of Training') : data.duration;
                addRecord.licencing_body  = data.licencing_body == null ?
                    this.showInuptError('Issuing Institution') : data.licencing_body;
                addRecord.notification_date  = data.notification_date == null ?
                    this.showInuptError('Notification Date') : data.notification_date;
                addRecord.qualify_date  = data.qualify_date == null ?
                    this.showInuptError('Qualification Date') : data.qualify_date;
                addRecord.qualification  = data.qualification == null ?
                    this.showInuptError('Qualification') : data.qualification;
                // addRecord.category = data.category == null ?
                //     this.showInuptError('Category') : data.category;
                // validate institution
                if((data.school_name == null || data.school_name == 'others')
                    && data.other_school_name != null) {
                    addRecord.school_name = data.other_school_name;
                } else if(data.school_name != null && data.school_name != 'others') {
                    addRecord.school_name = data.school_name;
                } else if((data.school_name == null || data.school_name == 'others')
                    && data.other_school_name == null) {
                    this.showInuptError('School\'s Name');
                }
                return this.isEmpty(addRecord) && certFile ? addRecord : false;
            },
            isEmpty(obj) {// check for empty field
                return ! Object.keys(obj).some(k => !obj[k]);
            },
            showInuptError(name) {
                this.isloading = false;
                this.showInputErrorStatus = true
                this.errorType = 'warning'
                this.errorMsg = 'Invalid ' + name + ' has been entered';
                var self = this;
                setTimeout(function() { self.showInputErrorStatus = false; }, 5000);
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b> ...',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        created() {
            this.loadAddQualifications();// load add qual records
            this.setTempAssessment();
        },
    }
</script>

<style>
    .form-control-label {
        width: 100%;
    }
</style>