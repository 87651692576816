<template>
    <div class="pl-lg-4">
        <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center">
            <span class="mask bg-gradient-danger opacity-8"></span>
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">

                <div class="col-xl-12 order-xl-1">
                    <card shadow type="secondary">
                        <div slot="header" class="bg-white border-0">
                            <div class="row align-items-center">
                                <div class="col-8">
                                    <h3 class="mb-0 text-uppercase">
                                        {{ this.$route.name }}
                                    </h3>
                                </div>
                                <div class="col-4 text-right">
                                    <router-link to="/" class="btn btn-sm btn-primary">Dashboard</router-link>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-12" v-if="isLoadingBlade">
                            <div class="d-flex justify-content-center">
                                <img :src="loadingBladeUrl" />
                            </div>
                        </div>

                        <div class="row" v-if="ticketing && ticketing.status && ticketing.batch">
                            <div class="col-sm-3"></div>
                            <div
                                class="col-sm-6 text-center borderLine margin20"
                            >
                                <br>
                                <h1 class="text-uppercase height-50">
                                    Doctor Ticket
                                </h1><br>

                                <form :action="printTicketUrl" method="POST" target="_blank">
                                
                                    <input type="hidden" name="option" value="doctor" />
                                    <input type="hidden" name="authorization_token" :value="getAuthorizationToken" />
                                    <input type="hidden" name="id" :value="basicProfile.id" />

                                    <base-button
                                        size="lg"
                                        type="primary"
                                        nativeType="submit"
                                    >
                                        <i class="fa fa-print"></i>
                                        Print Now
                                    </base-button>
                                </form>
                                <br><br>
                            </div>

                            <div
                                class="col-sm-6 text-center borderLine"
                                v-for="number in inviteeQuota"
                                :key="number"
                            >
                                <br>
                                <h1 class="text-uppercase height-50">
                                    Invitee Ticket ({{ number }})
                                </h1><br>

                                <form :action="printTicketUrl" method="POST" target="_blank">
                                
                                    <input type="hidden" name="option" value="invitee" />
                                    <input type="hidden" name="authorization_token" :value="getAuthorizationToken" />
                                    <input type="hidden" name="id" :value="basicProfile.id" />
                                    <input type="hidden" name="invitee_no" :value="number" />

                                    <base-button
                                        size="lg"
                                        type="success"
                                        nativeType="submit"
                                    >
                                        <i class="fa fa-print"></i>
                                        Print Now
                                    </base-button>
                                </form>
                                <br>
                            </div>
                        </div>

                        <div class="row" v-if="ticketing && ! ticketing.status">
                            <div class="col-sm-12 text-center">
                                <base-alert type="danger">
                                    <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                                    <span class="alert-inner--text text-uppercase">
                                        No Induction Ticket for you yet!!!
                                    </span>
                                </base-alert>
                            </div>
                        </div>

                        <div class="col-sm-12 text-center">
                            <base-alert :type="errorType" v-if='showErrorStatus && ! isLoadingBlade'>
                                <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                                <span class="alert-inner--text">
                                    {{errorMsg}}
                                </span>
                            </base-alert>
                        </div>

                    </card>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';

    export default {
        name: 'InductionTickets',
        data() {
            return {
                record: {},
                isLoadingBlade: false,
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl', 'basicProfile', 'printTicketUrl', 'getAuthorizationToken']),
            ticketing() {// induction ticketing
                return this.basicProfile ? this.basicProfile.ticketing : {};
            },
            inviteeQuota() {// get invitee quota
                return this.ticketing && this.ticketing.batch && this.ticketing.batch.invitee_quota ? parseInt(this.ticketing.batch.invitee_quota) : 1;
            }
        },
        methods: {
            ...mapActions(['fetchUserProfile'])
        },
        created() {
            this.fetchUserProfile();// get Parameter
        },
    }
</script>
<style scoped>
    .height-50 {
        height: 50px;
    }
    .borderLine {
        border: 2px solid #ededed;
        /* background-color: #f7fafc; */
    }
    .margin20 {
        margin-bottom: 20px;
    }
</style>