<template>
    <div class="pl-lg-4">

        <div class="col-lg-12" v-if="isLoadingBlade">
            <div class="d-flex justify-content-center">
                <img :src="loadingBladeUrl" />
            </div>
        </div>

        <div v-if="! isLoadingBlade">
            <!-- <h6 class="heading-small text-muted mb-4">Update Appointment Records</h6> -->

            <em class="mb-4 text-danger">
                Kindly fill your appointments below...
            </em>

            <hr class="my-4"/>

            <!-- import appointment records details template here -->
            <AppointmentData
                v-bind:appRecords="appRecords"
                v-bind:showAddRecord="showAddRecord"
                v-on:deleteAppRecord="deleteAppRecord"
                v-bind:pagination="pagination"
                v-bind:hasPagination="hasPagination"
                v-bind:paginate="paginate"
                v-bind:newAccount="newAccount"
                :type="true"
            />

            <hr class="my-4" v-if="hasRecords && ! showAddRecord"/>

            <div class="pl-lg-4" v-if="hasRecords && ! showAddRecord">
                <base-button
                    type="primary"
                    @click="displayAddPage()"
                >
                    <i class="fa fa-plus"></i>
                    Add New Record
                </base-button>
            </div>


            <div class="row align-items-center" v-if="! hasRecords || showAddRecord">

                <div class="col-lg-6">
                    <base-input alternative=""
                        label="Full Hospital Name"
                        placeholder="Enter Full Hospital Name"
                        input-classes="form-control-alternative"
                        v-model="record.hospital_name"
                        required
                    />
                </div>
                <div class="col-lg-6">
                    <base-input alternative=""
                        label="Full Hospital Address"
                        required
                    >
                        <textarea
                            rows="2"
                            class="form-control form-control-alternative"
                            placeholder="Enter Full Hospital Address"
                            v-model="record.hospital_address"></textarea>
                    </base-input>
                </div>

                <div class="col-lg-5">
                    <br>
                    <h4>
                        Is this your current Appointment?
                    </h4>
                </div>
                <div class="col-lg-2">
                    <br>
                    <base-switch
                        v-if="record.current_status == 'no'"
                        :value="false"
                        @click="changeCurrentStatus()"
                    ></base-switch>
                    <base-switch
                        v-if="record.current_status == 'yes'"
                        :value="true"
                        @click="changeCurrentStatus()"
                    ></base-switch>
                </div>
                <div class="col-lg-12"></div>
                <div class="col-lg-6">
                    <br>
                    <base-input
                        label="Employment Start Date"
                        addon-left-icon="ni ni-calendar-grid-58"
                        required

                    >
                        <flat-picker
                            slot-scope="{focus, blur}"
                            @on-open="focus"
                            @on-close="blur"
                            :config="config"
                            class="form-control datepicker"
                            v-model="record.start_date"
                        >
                        </flat-picker>
                    </base-input>
                </div>
                <div class="col-lg-6" v-if="showEndDate">
                    <br>
                    <base-input
                        label="Employment End Date"
                        addon-left-icon="ni ni-calendar-grid-58"
                        required

                    >
                        <flat-picker
                            slot-scope="{focus, blur}"
                            @on-open="focus"
                            @on-close="blur"
                            :config="config"
                            class="form-control datepicker"
                            v-model="record.end_date"
                        >
                        </flat-picker>
                    </base-input>
                </div>
                <div class="col-lg-12">
                    <base-alert :type="errorType" dismissible v-if='showInputErrorStatus'>
                        <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                        <span class="alert-inner--text">
                            {{errorMsg}}
                        </span>
                        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </base-alert>
                </div>
                <div class="col-lg-12"></div>

                <div class="col-lg-12">
                    <base-button
                        type="success"
                        @click="saveAppRecords()"
                    >
                        <i class="fa fa-save"></i>
                        {{isloading ? 'Please Wait...' : 'Add Record'}}
                    </base-button>

                    <div class="right" v-if="hasRecords">
                        <base-button
                            type="danger"
                            @click="displayAddPage()"
                        >
                            <i class="fa fa-times"></i>
                            Cancel
                        </base-button>
                    </div>
                </div>

            </div>

            <hr class="my-4"/>

            <div class="pl-lg-4">
                <base-button
                    type="danger"
                    @click="showPreviousPage()"
                >
                    <i class="fa fa-arrow-left"></i>
                    Previous
                </base-button>
                <div class="right">
                    <base-button
                        type="success"
                        @click="showNextPage()"
                    >
                        Proceed
                        <i class="fa fa-arrow-right"></i>
                    </base-button>
                </div>
            </div>


        </div>

    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import AppointmentData from '../ProfessionalData/Appointment/AppointmentData.vue';
    import flatPicker from "vue-flatpickr-component";
    import "flatpickr/dist/flatpickr.css";
    import swal from 'sweetalert2';// import sweet alert

    export default {
        components: {
            flatPicker,
            AppointmentData
        },
        name: 'Appointments',
        props: ['displaySpecData', 'displayPhoto'],
        data() {
            return {
                appRecords: [],
                paginatedItems: [],
                record: {
                    hospital_name: null,
                    hospital_address: null,
                    current_status: 'no',
                    start_date: null,
                    end_date: null
                },
                pagination: {
                    total: null,
                    number: 1,
                    from: null,
                    perPage: this.$store.state.user.perPage // get per page count
                },
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isloading: false,
                showInputErrorStatus: false,
                isLoadingBlade: true,
                showAddRecord: false,
                newAccount: true,
                config: {
                    maxDate: new Date().fp_incr(1),
                    altInput: true,// eslint-disable-next-line
                    plugins: [new monthSelectPlugin({shorthand: true, dateFormat: "Y-m-d", altFormat: "M Y"})]
                }
            };
        },
        computed: {
            ...mapGetters(['loadingBladeUrl']),
            showEndDate() {
                return this.record.current_status == 'no' ? true : false;
            },
            hasPagination() {
                return this.paginatedItems && (this.paginatedItems.length > this.pagination.perPage)
                    ? true : false;
            },
            hasRecords() {
                return this.appRecords && this.appRecords.length > 0;
            }
        },
        methods: {
            ...mapActions(['saveUserAppointment', 'getUserAppointments', 'deleteUserAppointment']),
            displayAddPage() {
                return this.showAddRecord = ! this.showAddRecord;
            },
            loadAppiontments() {
                const id = this.$store.state.user.userDataId;
                if(id) {
                    this.getUserAppointments({'id' : id}).then((res) => {
                        this.setPages(res);
                        this.isLoadingBlade = false;
                    });
                }
            },
            setPages(res) {// set paginated function
                this.appRecords = [];
                this.paginatedItems = res;
                this.pagination.total = this.paginatedItems.length; // get items total
                let perPage = this.pagination.perPage; // get per page count
                if(this.paginatedItems.length > perPage) {// paginate
                    let numberOfPages = Math.ceil(this.paginatedItems.length / perPage);
                    for (let index = 1; index <= numberOfPages; index++) {
                        this.appRecords.push(this.paginatedItems[index]);
                    }
                } else {
                    this.appRecords = this.paginatedItems;
                }
            },
            paginate() {// paginate function
                this.appRecords = [];
                this.pagination.from = null;
                let page = this.pagination.number;
                let perPage = this.pagination.perPage;
                let from = (page * perPage) - perPage;
                let to = (page * perPage);
                this.pagination.from = from;//get first item number
                return this.appRecords = this.paginatedItems.slice(from, to);
            },
            showPreviousPage() {// go to next page
                return this.displaySpecData(true);
            },
            showNextPage() {// go to next page
                return this.displayPhoto(true);
            },
            changeCurrentStatus() {// change Current Status switch
                const status = this.record.current_status == "yes" ? "no" : "yes";
                this.record.current_status = status;
            },
            saveAppRecords() {
                this.isloading = true;
                let data = this.validatedInputs();// validate inputs
                if(data) {// save appointment
                    this.preloader();// show loading
                    data.user_data_id = this.$store.state.user.basicProfile.id;// get user id
                    this.saveUserAppointment(data).then((res) => {
                        this.isloading = false;
                        if(res.status) {
                            this.showErrorStatus = false;
                            this.showAddRecord = false;
                            swal.fire("Record Added!", "Appointment record was added successfully", "success");
                            this.loadAppiontments();// load appointments
                            this.emptyRecords();
                        } else {
                            swal.fire("Error Occurred!", "An error occurred, kindly reload page and try again", "warning");
                        }
                    }).
                    catch(() => {
                        this.isloading = false;
                        this.emptyRecords();
                        swal.fire("Error Occurred!", "An error occurred, kindly reload page and try again", "warning");
                    });
                } else {
                    this.isloading = false;
                    this.emptyRecords();
                }
            },
            showErrorMsg() {
                this.showInputErrorStatus = true
                this.errorType = 'warning'
                this.errorMsg = 'An error occurred, kindly reload page and try again';
                var self = this;
                setTimeout(function() { self.showInputErrorStatus = false; }, 5000);
            },
            validatedInputs() {
                let data = this.record;
                let appRecord = { hospital_name: null, hospital_address: null, start_date: null };
                appRecord.hospital_name = data.hospital_name == null ?
                    this.showInuptError('Hospital\'s Name') : data.hospital_name;
                appRecord.hospital_address  = data.hospital_address == null ?
                    this.showInuptError('Hospital\'s Address') : data.hospital_address;
                appRecord.start_date  = data.start_date == null ?
                    this.showInuptError('Hospital\'s Start Date') : data.start_date;
                var status = this.isEmpty(appRecord);
                appRecord.current_status = data.current_status;
                appRecord.end_date  = data.end_date == null && data.current_status == 'no' ?
                    this.showInuptError('Hospital\'s End Date') : data.end_date;
                return (status && data.current_status == 'yes') || (status && data.current_status == 'no'
                    && data.end_date != null) ? appRecord : false;
            },
            isEmpty(obj) {// check for empty field
                return ! Object.keys(obj).some(k => !obj[k]);
            },
            showInuptError(name) {
                this.isloading = false;
                this.showInputErrorStatus = true
                this.errorType = 'warning'
                this.errorMsg = 'Invalid ' + name + ' has been entered';
                var self = this;
                setTimeout(function() { self.showInputErrorStatus = false; }, 5000);
            },
            emptyRecords() {
                this.record.hospital_name = null;
                this.record.hospital_address = null;
                this.record.current_status = 'no';
                this.record.start_date = null;
                this.record.end_date = null;
            },
            deleteAppRecord(id) {// delete appointment
                this.preloader();// show loading
                this.deleteUserAppointment({'id' : id}).then((res) => {
                    if(res.status) {
                        swal.fire("Record Deleted!", "Appointment record was removed successfully", "success");
                        this.loadAppiontments(); // load appointments
                    } else {
                        swal.fire("Error Occurred!", "An error occurred while deleting, try again", "warning");
                        this.loadAppiontments(); // load appointments
                    }
                }).
                catch(() => {
                    swal.fire("Network Failure", "Reload page and try again...", "warning");
                    this.loadAppiontments(); // load appointments
                });
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        created() {
            this.loadAppiontments();// load appointments
        },
    }
</script>

<style>
.right {
    float: right;
}
</style>

<style>
    .form-control-label {
        width: 100%;
    }
</style>