<template>
    <div>
        <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center no-padding-bottom">
            <span class="mask bg-gradient-danger opacity-8"></span>
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col-xl-12 order-xl-1">
                    <br><br><br>
                    <card shadow type="secondary">
                        <div slot="header" class="bg-white border-0">
                            <div class="row align-items-center">

                                <div class="col-8">
                                    <h3 class="mb-0 text-uppercase">{{ this.$route.name }}</h3>
                                    <br>
                                    <span class="text-danger">
                                        Fill the form below for deceased doctors....
                                    </span>
                                </div>
                                <div class="col-4 text-right">
                                    <a :href="websiteUrl" class="btn btn-sm btn-danger">
                                       <i class="fa fa-reply"></i> Home
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-lg-4">
                                <base-input alternative=""
                                    label="Doctor's Full Name"
                                    placeholder="Enter Doctor's Full Name"
                                    input-classes="form-control-alternative text-capitalize"
                                    v-model="record.name"
                                    required
                                />
                            </div>

                            <div class="col-lg-4">
                                <base-input alternative=""
                                    label="Location / Institution of Practice"
                                    placeholder="Enter Doctor's Location/Institution of Practice"
                                    input-classes="form-control-alternative"
                                    v-model="record.location"
                                    required
                                />
                            </div>

                            <div class="col-lg-4">
                                <base-input
                                    label="Date of Death"
                                    addon-left-icon="ni ni-calendar-grid-58"
                                    required
                                >
                                    <flat-picker
                                        slot-scope="{focus, blur}"
                                        @on-open="focus"
                                        @on-close="blur"
                                        :config="{allowInput: true}"
                                        placeholder="Select Date.."
                                        class="form-control datepicker"
                                        v-model="record.date_of_death"
                                    >
                                    </flat-picker>
                                </base-input>
                            </div>

                            <div class="col-lg-4">
                                <base-input alternative=""
                                    label="Doctor's Gender"
                                    >
                                    <select
                                        class="form-control form-control-alternative"
                                        v-model="record.gender"
                                    >
                                        <option :value="null" disabled>--- Select One ---</option>
                                        <option value="male">Male</option>
                                        <option value="female">Female</option>
                                    </select>
                                </base-input>
                            </div>

                            <div class="col-lg-4" v-if="locations && locations.states">
                                <base-input alternative=""
                                    label="Doctor's State of Practice"
                                    required
                                >
                                    <select
                                        class="form-control form-control-alternative"
                                        v-model="record.state_practice"
                                    >
                                        <option :value="null" disabled>--- Select One ---</option>
                                        <option
                                            v-for="state in locations.states"
                                            :value="state.id"
                                            :key="state.id"
                                        >
                                            {{state.name}}
                                        </option>
                                    </select>
                                </base-input>
                            </div>

                            <div class="col-lg-4">
                                <base-input alternative=""
                                    label="Reporter's Name (Optional)"
                                    placeholder="Enter Your Name"
                                    input-classes="form-control-alternative text-capitalize"
                                    v-model="record.reporter_name"
                                />
                            </div>

                            <div class="col-lg-4">
                                <base-input alternative=""
                                    label="Reporter's Email Address (Optional)"
                                    placeholder="Enter Your Email Address"
                                    input-classes="form-control-alternative"
                                    v-model="record.reporter_email"
                                />
                            </div>

                            <div class="col-lg-4">
                                <base-input alternative=""
                                    label="Reporter's Phone Number (Optional)"
                                    placeholder="Enter Your Phone Number"
                                    input-classes="form-control-alternative"
                                >
                                <!-- v-model="profile.phone"  -->
                                    <VuePhoneNumberInput
                                        v-model="tel"
                                        @update="updatePhoneNumber"
                                        default-country-code="NG"
                                        :no-example="true"
                                        :clearable="true"
                                    />
                                </base-input>
                            </div>

                            <div class="col-lg-4">
                                <base-input alternative=""
                                    label="Relation's Name (Optional)"
                                    placeholder="Enter Relation's Name"
                                    input-classes="form-control-alternative text-capitalize"
                                    v-model="record.relation_name"
                                />
                            </div>

                            <div class="col-lg-4">
                                <base-input alternative=""
                                    label="Relation's Phone Number (Optional)"
                                    placeholder="Enter Relation's Phone Number"
                                    input-classes="form-control-alternative"
                                >
                                <!-- v-model="profile.phone"  -->
                                    <VuePhoneNumberInput
                                        v-model="relation_tel"
                                        @update="updateRelationPhoneNumber"
                                        default-country-code="NG"
                                        :no-example="true"
                                        :clearable="true"
                                    />
                                </base-input>
                            </div>

                        </div>

                        <div class="row">

                            <div
                                class="col-lg-12 text-center"
                                v-if="showErrorStatus"
                            >
                                <base-alert :type="errorType">
                                    <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                                    <span class="alert-inner--text">
                                        {{errorMsg}}
                                    </span>
                                </base-alert>
                                <br>
                            </div>

                            <div class="col-lg-12" v-if="! isNotRobot">
                                <vue-recaptcha
                                    :sitekey="recaptchaKey"
                                    :loadRecaptchaScript="true"
                                    @verify="proceedSubmit"
                                ></vue-recaptcha>
                            </div>

                            <div class="col-lg-12" v-if='! showErrorStatus'>
                                <br>
                                <base-button
                                    type="success"
                                    @click="sendMessage()"
                                    :disabled="! isNotRobot"
                                >
                                    <i class="fa fa-save"></i>
                                    {{isloading ? 'Please Wait...' : 'Send Report'}}
                                </base-button>
                                <br><br><br>
                            </div>
                        </div>
                    </card>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import VuePhoneNumberInput from 'vue-phone-number-input';
    import 'vue-phone-number-input/dist/vue-phone-number-input.css';
    import VueRecaptcha from 'vue-recaptcha';
    import flatPicker from "vue-flatpickr-component";
    import "flatpickr/dist/flatpickr.css";
    import swal from 'sweetalert2';// import sweet alert

    export default {
        components: {
            VuePhoneNumberInput,
            VueRecaptcha,
            flatPicker
        },
        name: 'AddExitRegister',
        data() {
            return {
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                record: {
                    name: null,
                    location: null,
                    date_of_death: null,
                    gender: null,
                    state_practice: null,
                    reporter_name: null,
                    reporter_phone: null,
                    reporter_email: null,
                    relation_name: null,
                    relation_phone: null
                },
                tel: null,
                relation_tel: null,
                isloading: false,
                isNotRobot: false
            }
        },
        computed: {
            ...mapGetters(['websiteUrl', 'recaptchaKey', 'locations']),
        },
        methods: {
            ...mapActions(['sendExitRegisterForm', 'getLocations']),
            sendMessage() {
                this.isloading = true;
                this.preloader();// show loading
                let status = this.validatedData();// valid input data
                if(status && this.isNotRobot) {// send report
                    this.sendExitRegisterForm(this.record).then((res) => {
                        this.isloading = false;
                        swal.close();
                        if(res.status) {
                            this.emptyFields();
                            swal.fire("Report Sent!", res.msg, "success");
                        } else {
                            swal.fire("Error Ocurred!", res.msg, "warning");
                        }
                    });
                } else if(! this.isNotRobot) {
                    this.isloading = false;
                    swal.fire("Error Ocurred!", "Click on the Google reCAPTCHA above", "warning");
                } else {
                    this.isloading = false;
                    swal.close();
                }
            },
            validatedData() {
                let data = this.record;
                data.state_practice != null ? true : this.showInuptError('Doctor\'s State of Practice');
                data.gender != null ? true : this.showInuptError('Doctor\'s Gender');
                data.date_of_death != null ? true : this.showInuptError('Date of Death');
                data.location != null ? true : this.showInuptError('Location / Institution of Practice');
                data.name != null ? true : this.showInuptError('Doctor\'s Name');
                let status = data.name && data.location && data.date_of_death && data.gender && data.state_practice;
                return status ? true : false;
            },
            showInuptError(name) {
                this.isloading = false;
                this.showErrorStatus = true
                this.errorType = 'warning'
                this.errorMsg = 'Invalid ' + name + ' has been entered';
                var self = this;
                setTimeout(function() { self.showErrorStatus = false; }, 3000);
            },
            updatePhoneNumber(event) {
                if(event && event.isValid && event.e164) {
                    this.record.reporter_phone = event.formattedNumber;
                }
            },
            updateRelationPhoneNumber(event) {
                if(event && event.isValid && event.e164) {
                    this.record.relation_phone = event.formattedNumber;
                }
            },
            emptyFields() {
                this.record = {
                    name: null,
                    location: null,
                    date_of_death: null,
                    gender: null,
                    state_practice: null,
                    reporter_name: null,
                    reporter_phone: null,
                    reporter_email: null,
                    relation_name: null,
                    relation_phone: null
                };
                this.tel = null;
            },
            proceedSubmit(res) {
                if(res) {
                    return setTimeout(() => {
                        this.isNotRobot = ! this.isNotRobot;
                    }, 1000);
                } else {
                    swal.fire("Error Occurred", "An error occurred while verifying, kindly try again", "warning");
                }
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        created() {
            this.getLocations();
        }
    }
</script>

<style scoped>
    .no-padding-bottom {
        padding-bottom: 0rem !important
    }
</style>

<style>
    .form-control-label {
        width: 100%;
    }
</style>