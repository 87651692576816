<template>
    <div class="pl-lg-4">

        <div class="col-lg-12" v-if="isLoadingBlade">
            <div class="d-flex justify-content-center">
                <img :src="loadingBladeUrl" />
            </div>
        </div>

        <div class="container ct-example-row">
            <hr class="my-4">        
            <div class="row" v-if="! isLoadingBlade">
                <div class="col-lg-6">
                    <base-input alternative=""
                        label="Medical School Country"
                        required
                    >
                        <autocomplete
                            v-if="locations.countries && locations.countries.length > 0"
                            input-class="form-control form-control-alternative"
                            placeholder="Type to Search for Country"
                            showNoResults
                            :source="locations.countries"
                            v-model="record.med_school_country"
                            :initialDisplay="(hasCountry(record.med_school_country) 
                                    ? hasCountry(record.med_school_country).name : '')"
                        >
                        </autocomplete>
                    </base-input>
                </div>

                <div class="col-lg-6">
                    <base-input alternative=""
                        label="Additional Qualification Country"
                        required
                    >
                        <autocomplete
                            v-if="locations.countries && locations.countries.length > 0"
                            input-class="form-control form-control-alternative"
                            placeholder="Type to Search for Country"
                            showNoResults
                            :source="locations.countries"
                            v-model="record.aq_country"
                            :initialDisplay="(hasCountry(record.aq_country) ? hasCountry(record.aq_country).name : '')"
                        >
                        </autocomplete>
                    </base-input>
                </div>
            </div>
        </div>

        <base-alert :type="errorType" dismissible v-if='showErrorStatus'>
            <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
            <span class="alert-inner--text">
                {{errorMsg}}
            </span>
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </base-alert>

        <div v-if="! isLoadingBlade">
            <base-button type="success" @click="updateVerifyCountries()">
                <i class="fa fa-save" @if='! isloading'></i> 
                {{isloading ? 'Please Wait' : 'Save Changes'}}
            </base-button>
        </div>       

    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import Autocomplete from 'vuejs-auto-complete';
    import swal from 'sweetalert';// import sweet alert

    export default {
        name: 'VerifiyCountries',
        props: ['loadApplication'],
        components: {
            Autocomplete
        },
        data() {
            return {
                record: {
                    med_school_country: null,
                    aq_country: null
                },
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isloading: false,
                isLoadingBlade: true
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl', 'locations', 'user']),
            userProfile() {
                return this.user && this.user.basic_profile ? this.user.basic_profile : false;
            },
            hasRecord() {
                let profile = this.userProfile;
                return profile && profile.med_school_country && profile.aq_country;
            }
        },
        methods: {
            ...mapActions(['getLocations', 'fetchUserProfile', 'updateTempAssessment']),
            hasCountry(id) {
                if(this.locations && this.locations.countries.length > 0)
                return this.locations.countries.filter(item => item.id == id)[0];
            },
            loadVerifyCountries() {
                this.fetchUserProfile().then((res) => {
                    this.isLoadingBlade = false;
                    if(res.basic_profile) {// update
                        let profile = res.basic_profile;
                        this.record = {
                            med_school_country: profile.med_school_country,
                            aq_country: profile.aq_country
                        };
                    } else {
                        this.showErrorStatus = true;                        
                        this.errorMsg = "Network Failure!", "Kindly reload the page...";
                        this.errorType = 'danger'; 
                    }
                }).
                catch(() => {
                    this.showErrorStatus = true;
                    this.isLoadingBlade = false;
                    this.errorMsg = "Network Failure!", "Kindly reload the page...";
                    this.errorType = 'danger';
                });                
            },
            updateVerifyCountries() {
                this.isloading = true;
                let data = this.validatedData();// valid input data
                if(data) {// check for user id & perform update
                    this.updateTempAssessment(this.record).then((res) => {
                        this.loadApplication(); // refresh profile
                        this.isloading = false;
                        if(res.status) {// load updated profile 
                            swal("Record Updated!", "Record was added successfully", "success");
                        } else {
                            swal("Error Occurred!", "An error occurred, kindly reload page and try again", "warning");
                        }
                    });
                } else {
                    this.isloading = false;
                }
            },
            validatedData() {
                let data = this.record;
                let res = { med_school_country: null, aq_country: null};  
                res.aq_country = data.aq_country != null ? data.aq_country 
                                            : this.showInuptError('Additional Qualification Country');           
                res.med_school_country = data.med_school_country != null ? data.med_school_country 
                                            : this.showInuptError('Medical School Country');
                return res.med_school_country && res.aq_country;
            },
            showUpdateMsg(msg, type) {
                this.showErrorStatus = true;
                this.errorType = type; 
                this.errorMsg = msg;
                var self = this;
                setTimeout(function() { self.showErrorStatus = false; }, 5000);
            },
            showInuptError(name) {
                this.isloading = false;
                this.showErrorStatus = true
                this.errorType = 'warning'
                this.errorMsg = 'Invalid ' + name + ' has been entered';
                var self = this;
                setTimeout(function() { self.showErrorStatus = false; }, 5000);
            }
        },
        created() {            
            this.getLocations();// load doctor type specialty only 
            this.loadVerifyCountries();// load user specialty
        },
    }
</script>

<style scoped>
.right {
    float: right;
}
</style>