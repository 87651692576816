<template>
    <div>
        <div class="col-lg-12">
            <hr class="my-4" />
            <!-- Address -->
            <h6 class="heading-small text-muted mb-4">
                Provide Your Referee information Below:
            </h6>

            <base-alert :type="errorType" dismissible v-if='showInputStatus'>
                <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                <span class="alert-inner--text">
                    {{errorMsg}}
                </span>
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </base-alert>

            <div class="row align-items-center" v-if="! disableInput">
                <div class="col-lg-2">
                    <base-input alternative=""
                        label="Referee's Name"
                        input-classes="form-control-alternative"
                        required
                        v-model="referee.name"
                        :readonly="disableInput"
                    />
                </div>
                <div class="col-lg-3">
                    <base-input alternative=""
                        label="Referee's Address"
                        input-classes="form-control-alternative"
                        required
                        v-model="referee.address"
                        :readonly="disableInput"
                    />
                </div>
                <div class="col-lg-3">
                    <!-- <base-input alternative=""
                        label="Phone"
                        input-classes="form-control-alternative"
                        required
                        v-model="referee.phone"
                        :readonly="lock"
                    /> -->
                    <base-input alternative=""
                        label="Phone Number"
                        input-classes="form-control-alternative"
                    >
                    <!-- v-model="profile.phone"  -->
                        <VuePhoneNumberInput
                            v-model="tel"
                            @update="updatePhoneNumber"
                            default-country-code="NG"
                            :no-example="false"
                            :clearable="true"
                            :disabled="disableInput"
                        />
                    </base-input>
                </div>
                <div class="col-lg-2">
                    <base-input alternative=""
                        label="Occupation"
                        input-classes="form-control-alternative"
                        required
                        v-model="referee.occupation"
                        :readonly="disableInput"
                    />
                </div>
                <div class="col-lg-2">
                    <base-button
                        type="success" size="sm"
                        @click="saveReferee()"
                        :disabled="disableInput"
                    >
                        <i class="fa fa-plus"></i>
                        {{isloadingRef ? 'Saving...' : 'Add Now' }}
                    </base-button>
                </div>
            </div>

            <!-- Import Referees blade here -->
            <Referees
                v-bind:referees="records.referees"
                v-on:deleteReferee="deleteReferee"
            />
            <br>
        </div>

    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import Referees from '../../UserProfile/AdditionalRecords/Referees';
    import VuePhoneNumberInput from 'vue-phone-number-input';
    import 'vue-phone-number-input/dist/vue-phone-number-input.css';
    import swal from 'sweetalert';// import sweet alert

    export default {
        components: {
            Referees,
            VuePhoneNumberInput
        },
        name: 'AddReferees',
        props: ['records', 'loadAdditionalRecords', 'profileLock'],
        data() {
            return {
                referee: {
                    name: null,
                    address: null,
                    phone: null,
                    occupation: null,
                    user_data_id: null
                },
                tel: null,
                showInputStatus: false,
                errorType: null,
                errorMsg: null,
                isloadingRef: false
            };
        },
        computed: {
            ...mapGetters(['loadingBladeUrl', 'lock']),
            disableInput() {// check for profile lock
                return ! this.profileLock ? this.profileLock : this.lock;
            }
        },
        methods: {
            ...mapActions(['saveReferees', 'removeReferee']),
            updatePhoneNumber(event) {
                if(event && event.isValid && event.e164) {
                    this.referee.phone = event.formattedNumber;
                }
            },
            isEmpty(obj) {// check for empty field
                return ! Object.keys(obj).some(k => !obj[k]);
            },
            emptyReferee() {
                this.referee.name = null;
                this.referee.address = null;
                this.referee.phone = null;
                this.referee.occupation = null;
                this.referee.user_data_id = null;
            },
            saveReferee() {//save Referee
                this.isloadingRef = true;
                let status = this.validatedRefereeInput(this.referee);// validate inputs
                if(status) {// save Referee
                    let data = this.referee;
                    data.user_data_id = this.$store.state.user.basicProfile.id;// get user id
                    this.saveReferees(data).then((res) => {
                        this.tel = null;
                        this.isloadingRef = false;
                        this.loadAdditionalRecords();
                        if(res.status) {
                            this.emptyReferee();
                        } else {
                            this.showErrorMsg();
                            this.emptyReferee();
                        }
                    }).
                    catch(() => {
                        this.isloadingRef = false;
                        this.loadAdditionalRecords();
                        this.emptyReferee();
                        this.showErrorMsg();
                    });
                } else {
                    this.isloadingRef = false;
                }
            },
            validatedRefereeInput(data) {
                let record = { name: null, address: null, phone: null, occupation: null };
                record.occupation  = data.occupation == null ? this.showInuptError('Referee\'s Occupation') : data.occupation;
                record.phone  = data.phone == null ? this.showInuptError('Referee\'s Phone Number') : data.phone;
                record.address  = data.address == null ? this.showInuptError('Referee\'s Address') : data.address;
                record.name = data.name == null ? this.showInuptError('Referee\'s Name') : data.name;
                return this.isEmpty(record) ? record : false;
            },
            showErrorMsg() {
                let msg = 'An error occurred, kindly reload page and try again';
                swal("Error occurred!", msg, "warning");
            },
            showInuptError(name) {
                this.isloadingRef = false;
                this.showInputStatus = true
                this.errorType = 'warning'
                this.errorMsg = 'Invalid ' + name + ' has been entered';
                var self = this;
                setTimeout(function() { self.showInputStatus = false; }, 5000);
            },
            deleteReferee(id) {// delete Jurisdiction function
                this.removeReferee({'id' : id}).then((res) => {
                    if(res.status) {
                        this.loadAdditionalRecords(); // load Referees
                    } else {
                        swal("Error Occurred!", "An error occurred while deleting, try again", "warning");
                        this.loadAdditionalRecords(); // load Referees
                    }
                }).
                catch(() => {
                    swal("Network Failure", "Reload page and try again...", "warning");
                    this.loadAdditionalRecords(); // load Referees
                });
            },
        }
    }
</script>

<style scoped>
    .marginLeft10 {
        margin-left: 10px;
    }
</style>