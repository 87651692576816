<template>
    <div class="row">
        <div class="col-lg-12" v-if="isLoadingBlade">
            <div class="d-flex justify-content-center">
                <img :src="loadingBladeUrl" />
            </div>
        </div>

        <div
            class="col-lg-12"
            v-if="! showAddRecord && insuranceRecords && insuranceRecords.length > 0 && ! isLoadingBlade"
        >
            <div class="card shadow">
                <div class="table-responsive">
                    <base-table
                        class="table align-items-center table-flush"
                        thead-classes="thead-light"
                        tbody-classes="list"
                        :data="insuranceRecords"
                    >
                        <template slot="columns">
                            <th width="2%">S/N</th>
                            <th width="10%">Transaction Date</th>
                            <th width="20%">Transaction No</th>
                            <th width="10%">Status</th>
                            <th width="10%">Policy No</th>
                            <th width="10%">Expiry Date</th>
                            <th width="5%">&nbsp;</th>
                        </template>

                        <template
                            slot-scope="{row, index}"
                        >
                        <td class="budget">
                            {{ pagination.from+index+1}}
                        </td>
                        <td>
                            {{row.trans_date | getDateTimeFormat}}
                        </td>
                        <td>
                            {{(row.trans_no)}}
                        </td>
                        <td>
                            <badge :type="showStatus(row.trans_status)">
                                {{ row.trans_status }}
                            </badge>
                        </td>
                        <td>
                            {{row.policy_no ? row.policy_no : 'N/A'}}
                        </td>
                        <td>
                            {{ row.expiry_date | getDateFormat }}
                        </td>
                        <td>
                            <base-button
                                type="primary" size="sm"
                                v-if="row.trans_status == 'PENDING' && (! isloading || (selectedRowNo != row.trans_no))"
                                @click="$emit('reQueryTransaction', row.trans_no)"
                            >
                                <i class="fa fa-history"></i> Re-Query 
                            </base-button>

                            <base-button
                                type="primary" size="sm"
                                v-if="((row.trans_status == 'FAILED') || (row.trans_status == 'PENDING'))
                                        && (! isloading || (selectedRowNo != row.trans_no))"
                                @click="$emit('makeRepayment', row.trans_no)"
                            >
                                <i class="fa fa-credit-card"></i> Make Payment
                            </base-button>

                            <base-button
                                type="primary" size="sm"
                                v-if="isloading && (selectedRowNo == row.trans_no)"
                            >
                                <span class="spinner-border spinner-border-sm"
                                    role="status" aria-hidden="true"></span>
                                <span class="sr-only">Loading...</span>
                                Please Wait...
                            </base-button>
                        </td>

                        </template>

                    </base-table>
                </div>

                <div
                    class="card-footer d-flex justify-content-end bg-transparent"
                    v-if="hasPagination"
                     @click="paginate"
                >
                    <base-pagination
                        :total="pagination.total"
                        align="center"
                        v-model="pagination.number"
                        :perPage="pagination.perPage"

                    >
                    </base-pagination>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import moment from 'moment';

    export default {
        name: 'IndemnityData',
        props: ["insuranceRecords", "showAddRecord", "isloading", "selectedRowNo",
            "isLoadingBlade", "pagination", "hasPagination", "paginate"],
        filters: {
            getDateFormat(value) {
                if(value) {
                    return moment(value).format('Do MMM, YYYY');
                } else {
                    return 'N/A';
                }
            },
            getDateTimeFormat(value) {
                if(value) {
                    return moment(value).format('Do MMM, YYYY [at] h:mm:ss a');
                } else {
                    return 'N/A';
                }
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl'])
        },
        methods: {
            showStatus(value) {
                if(value == 'PAID') {
                    return 'success';
                } else if(value == 'PENDING') {
                    return 'warning';
                } else {
                    return 'danger';
                }
            }
        },
    }
</script>