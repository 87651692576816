<template>
    <div>
        <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center">
            <span class="mask bg-gradient-danger opacity-8"></span>
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col-xl-12 order-xl-1">
                    <card shadow type="secondary">
                        <div slot="header" class="bg-white border-0">
                            <div class="row align-items-center">
                                <div class="col-12">
                                    <h3 class="mb-0 text-uppercase">{{ title }}</h3>
                                    <br>
                                    <span class="text-danger">
                                        Note that you have to provide correct information below.
                                    </span>
                                </div>
                            </div>
                        </div>

                        <!-- Import Bio Data template here -->
                        <BioData
                            v-if="showBioData"
                            v-bind:displayEduRecords="displayEduRecords"
                            :locations="locations"
                        />

                        <!-- Import Edu Records template here -->
                        <EduRecords
                            v-if="! showBioData && showEduRecords"
                            :displayBioData="displayBioData"
                            :displaySpecData="displaySpecData"
                        />

                        <!-- Import Specialization template here -->
                        <Specialization
                            v-if="! showBioData && ! showEduRecords && showSpecData"
                            v-bind:displayEduRecords="displayEduRecords"
                            v-bind:displayAppointments="displayAppointments"
                        />

                        <!-- Import Appointments template here -->
                        <Appointments
                            v-if="! showBioData && ! showEduRecords && ! showSpecData
                                && showAppointments"
                            v-bind:displaySpecData="displaySpecData"
                            v-bind:displayPhoto="displayPhoto"
                        />

                        <!-- Import Profile Photo template here -->
                        <ProfilePhoto
                            v-if="! showBioData && ! showEduRecords && ! showSpecData
                                && ! showAppointments && showPhoto"
                            v-bind:displayAppointments="displayAppointments"
                            v-bind:displayDocuments="displayDocuments"
                        />

                        <!-- Import Documents template here -->
                        <Documents
                            v-if="! showBioData && ! showEduRecords && ! showSpecData
                                && ! showAppointments && ! showPhoto && showDocuments"
                            v-bind:displayPhoto="displayPhoto"
                            v-bind:displayInsurance="displayInsurance"
                        />

                        <!-- Import Insurance template here -->
                        <Insurance
                            v-if="! showBioData && ! showEduRecords && ! showSpecData
                                && ! showAppointments && ! showPhoto && ! showDocuments
                                && showInsurance"
                            v-bind:displayDocuments="displayDocuments"
                            v-bind:displayAddQual="displayAddQual"
                        />

                        <!-- Import Add Qualification template here -->
                        <AddQualification
                            v-if="! showBioData && ! showEduRecords && ! showSpecData
                                && ! showAppointments && ! showPhoto && ! showDocuments
                                && ! showInsurance && showAddQual"
                            :displayInsurance="displayInsurance"
                            :displayAddRecords="displayAddRecords"
                            :locations="locations"
                        />

                        <!-- Import Additional Records template here -->
                        <AdditionalRecords
                            v-if="! showBioData && ! showEduRecords && ! showSpecData
                                && ! showAppointments && ! showPhoto && ! showDocuments
                                && ! showInsurance && ! showAddQual && showAddRecords"
                            :displayAddQual="displayAddQual"
                            :displayNMALevy="displayVerifyCountry"
                        />

                        <!-- Import Verification Country template here -->
                        <VerificationCountry
                            v-if="! showBioData && ! showEduRecords && ! showSpecData
                                && ! showAppointments && ! showPhoto && ! showDocuments
                                && ! showInsurance && ! showAddQual && ! showAddRecords 
                                && showVerifyCountry"
                            :displayAddRecords="displayAddRecords"
                            :displayReg="displayReg"
                        />

                        <!-- Import Registration template here -->
                        <Registration
                            v-if="! showBioData && ! showEduRecords && ! showSpecData
                                && ! showAppointments && ! showPhoto && ! showDocuments
                                && ! showInsurance && ! showAddQual && ! showAddRecords
                                && showReg"
                            :displayVerifyCountry="displayVerifyCountry"
                        />

                    </card>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import BioData from './Others/BioData';
    import EduRecords from './Others/EduRecords';
    import Specialization from './Others/Specialization';
    import Appointments from './Others/Appointments';
    import ProfilePhoto from '../NewAccount/ProfilePhoto';
    import Documents from './Others/Documents';
    import Insurance from './Others/Insurance';
    import Registration from './Others/Registration.vue';
    // import Internship from './Internship.vue';
    import AddQualification from './Others/AddQualification';
    import VerificationCountry from './Others/VerificationCountry.vue';
    import AdditionalRecords from '../NewAccount/AdditionalRecords/AdditionalRecords';
    // import NMALevy from './NMALevy';
    import swal from 'sweetalert2';// import sweet alert
    import { mapActions, mapGetters } from 'vuex';

    export default {
        components: {
            BioData,
            EduRecords,
            Specialization,
            Appointments,
            ProfilePhoto,
            Documents,
            Insurance,
            Registration,
            // Internship,
            AddQualification,
            VerificationCountry,
            AdditionalRecords,
            // NMALevy
        },
        name: 'NewTempAccount',
        data() {
            return {
                showBioData: true,
                showEduRecords: false,
                showSpecData: false,
                showAppointments: false,
                showPhoto: false,
                showDocuments: false,
                showInsurance: false,
                showReg: false,
                showAddQual: false,
                showAddRecords: false,
                showVerifyCountry: false,
                title: 'Update Bio information'
            }
        },
        computed: {
            ...mapGetters(['user', 'locations']),
            userProfile() {
                return this.user && this.user.basic_profile ? this.user.basic_profile : false;
            },
            isTempAssessment() {
                return this.userProfile && (this.userProfile.is_temp_assessment == 'yes');
            }
        },
        methods: {
            ...mapActions(['getLocations']),
            displayBioData(value) {
                this.title = 'Qpdate Bio information';
                this.showBioData = value;
            },
            displayEduRecords(value) {
                this.title = 'Update Educational Records';
                this.showBioData = ! value;
                this.showEduRecords = value;
            },
            displaySpecData(value) {
                this.title = 'Update Specialization Information';
                this.showBioData = ! value;
                this.showEduRecords = ! value;
                this.showSpecData = value;
            },
            displayAppointments(value) {
                this.title = 'Update Appointment Records';
                this.showBioData = ! value;
                this.showEduRecords = ! value;
                this.showSpecData = ! value;
                this.showAppointments = value;
            },
            displayPhoto(value) {
                this.title = 'Upload Profile Photo';
                this.showBioData = ! value;
                this.showEduRecords = ! value;
                this.showSpecData = ! value;
                this.showAppointments = ! value;
                this.showPhoto = value;
            },
            displayDocuments(value) {
                this.title = 'Upload Documents';
                this.showBioData = ! value;
                this.showEduRecords = ! value;
                this.showSpecData = ! value;
                this.showAppointments = ! value;
                this.showPhoto = ! value;
                this.showDocuments = value;
            },
            displayInsurance(value) {
                this.title = 'Purchase insurance indemnity';
                this.showBioData = ! value;
                this.showEduRecords = ! value;
                this.showSpecData = ! value;
                this.showAppointments = ! value;
                this.showPhoto = ! value;
                this.showDocuments = ! value;
                this.showInsurance = value;
            },
            displayAddQual(value) {
                this.title = 'Update Additional Qualification';
                this.showBioData = ! value;
                this.showEduRecords = ! value;
                this.showSpecData = ! value;
                this.showAppointments = ! value;
                this.showPhoto = ! value;
                this.showDocuments = ! value;
                this.showInsurance = ! value;
                this.showVerifyCountry = ! value;
                this.showAddQual = value;
            },
            displayAddRecords(value) {
                this.title = 'Additional Records';
                this.showBioData = ! value;
                this.showEduRecords = ! value;
                this.showSpecData = ! value;
                this.showAppointments = ! value;
                this.showPhoto = ! value;
                this.showDocuments = ! value;
                this.showInsurance = ! value;
                this.showAddQual = ! value;
                this.showVerifyCountry = ! value;
                this.showAddRecords = value;
            },
            displayVerifyCountry(value, status = false) {
                
                if(status) {
                    if(this.isTempAssessment) {
                        this.displayAddRecords(true);
                    } else {
                        this.showOnlyVerifyCountries(value);
                    }  
                } else {
                    if(this.isTempAssessment) {
                        this.displayReg(true);
                    } else {
                        this.showOnlyVerifyCountries(value);
                    }  
                }            
            },
            showOnlyVerifyCountries(value) {
                this.title = 'Update Verification Countries';
                this.showBioData = ! value;
                this.showEduRecords = ! value;
                this.showSpecData = ! value;
                this.showAppointments = ! value;
                this.showPhoto = ! value;
                this.showDocuments = ! value;
                this.showInsurance = ! value;
                this.showAddQual = ! value;
                this.showAddRecords = ! value;
                this.showReg = ! value;
                this.showVerifyCountry = value;
            },
            displayReg(value) {
                this.title = 'Registration Payment';
                this.showBioData = ! value;
                this.showEduRecords = ! value;
                this.showSpecData = ! value;
                this.showAppointments = ! value;
                this.showPhoto = ! value;
                this.showDocuments = ! value;
                this.showInsurance = ! value;
                this.showInternship = ! value;
                this.showAddQual = ! value;
                this.showAddRecords = ! value;
                this.showVerifyCountry = ! value;
                this.showReg = value;
            },
            showSessionMsg() {
                if(this.$route.query.status && this.$route.query.status == 1) {
                    swal.fire("Insurance Purchased!", "Insurance Indemnity was purchased successfully", "success");
                } else if(this.$route.query.status && this.$route.query.status == 0) {
                    swal.fire("Insurance Failed!", "Insurance Indemnity purchased failed, try again", "warning");
                }
                // if(this.$route.path != '/new-temp-account') {
                //     return this.$router.push({ path: '/new-temp-account' });
                // }
            }
        },
        created() {
            this.getLocations();
            this.showSessionMsg();// show session msg
        },
    }
</script>