<template>
    <div>
        <div v-if="! isLoadingBlade && ! showErrorStatus">
            <h3 class="text-uppercase">Application Review</h3>
            <em class="mb-4 text-danger">
                Kindly check all information supplied, after submission changes can't be made...
            </em>
            <hr>

            <!-- This is personal details template here -->
            <PersonalDetails
                :personal="applicant.personal_data"
                :rrr="applicant.rrr"
                :photo="applicant.photo"
                v-if="applicant && applicant.personal_data"
            />

            <!-- This is educational details template here -->
            <EduDetails
                :records="applicant.edu_data"
                v-if="applicant && applicant.edu_data"
            />

            <!-- This is professional qualification details template here -->
            <ProfQualDetails
                :records="applicant.qualification_data"
                v-if="applicant && applicant.qualification_data"
            />

            <!-- This is additional details template here -->
            <AdditionalDetails
                :record="applicant.additional_data"
                v-if="applicant && applicant.additional_data"
            />

            <!-- This is Employment details template here -->
            <EmploymentDetails
                :records="applicant.employment_data"
                v-if="applicant && applicant.additional_data && applicant.additional_data.employ_status == 'yes'
                        && applicant.employment_data"
            />

            <!-- This is previous examination details template here -->
            <ExaminationDetails
                :records="applicant.examination_data"
                v-if="applicant && applicant.additional_data && applicant.additional_data.exam_taken == 'yes'
                        && applicant.examination_data"
            />

            <!-- This is Institution (Remedial course) details template here -->
            <InstitutionDetails
                :records="applicant.institution_data"
                v-if="applicant && applicant.additional_data && applicant.additional_data.remedial_course == 'yes'
                        && applicant.institution_data"
            />

            <!-- This is Referee details template here -->
            <RefereeDetails
                :records="applicant.referee_data"
                v-if="applicant && applicant.referee_data"
            />

            <!-- This is documents details template here -->
            <Documents
                :records="documents"
                v-if="applicant && documents"
            />

        </div>

        <div class="col-lg-12">
            <h3 class="mb-4 text-danger">
                Kindly check all information before hitting the submit button, changes can't be made after submission.
            </h3>
            <hr>
        </div>


        <div class="col-lg-12" v-if="isLoadingBlade">
            <div class="d-flex justify-content-center">
                <img :src="loadingBladeUrl" />
            </div>
        </div>

        <div class="col-lg-12 text-center" v-if='showErrorStatus && ! isLoadingBlade'>
            <base-alert :type="errorType">
                <!-- <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span> -->
                <span class="alert-inner--text">
                    <h2 class="text-white">{{errorMsg}}</h2>
                </span>
            </base-alert>
        </div>

        <div class="col-lg-12" v-if="! isLoadingBlade">
            <base-button
                type="danger"
                @click="showPreviousPage()"
            >
                <i class="fa fa-arrow-left"></i>
                Previous
            </base-button>

            <div class="right">
                <base-button
                    type="primary"
                    @click="continueLater()"
                >
                    <i class="fa fa-save"></i>
                    Save &amp; Continue Later
                </base-button>

                <base-button
                    type="success"
                    @click="submitNow()"
                >
                    <i class="fa fa-save"></i>
                    {{ isloading ? 'Please Wait...' : 'Submit Now'}}
                </base-button>
            </div>
        </div>

    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import PersonalDetails from './AssessmentSummary/PersonalDetails';
    import EduDetails from './AssessmentSummary/EduDetails';
    import ProfQualDetails from './AssessmentSummary/ProfQualDetails';
    import EmploymentDetails from './AssessmentSummary/EmploymentDetails';
    import AdditionalDetails from './AssessmentSummary/AdditionalDetails';
    import ExaminationDetails from './AssessmentSummary/ExaminationDetails';
    import InstitutionDetails from './AssessmentSummary/InstitutionDetails';
    import RefereeDetails from './AssessmentSummary/RefereeDetails';
    import Documents from './AssessmentSummary/Documents.vue';
    import swal from 'sweetalert2';// import sweet alert

    export default {
        name: 'AssessmentPreview',
        props: ['displayDocument', 'applicant', 'loadApplicantInfo', 'showDashbaord'],
        components: {
            PersonalDetails,
            EduDetails,
            ProfQualDetails,
            EmploymentDetails,
            AdditionalDetails,
            ExaminationDetails,
            InstitutionDetails,
            RefereeDetails,
            Documents
        },
        data() {
            return {
                isLoadingBlade: false,
                isloading: false,
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                id: localStorage.getItem('applicantId')
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl']),
            isDental() {// is a dental applicant
                let data = this.applicant && this.applicant.additional_data ? this.applicant.additional_data : false;
                return data && data.practice_type && (data.practice_type == 'd');
            },
            documents() {// check for dental applicants files
                let files = this.applicant.files;
                return this.isDental ? files.filter( item  => item.filename != "epic_evidence.pdf" ) : files;
            }
        },
        methods: {
            ...mapActions(['submitAssessmentForm']),
            showPreviousPage() {// go to next page
                return this.displayDocument(true);
            },
            continueLater() {
                this.preloader();
                setTimeout(function() {
                    let msg = 'Application was updated successfully, you can continue later';
                    swal.fire("Application Updated!", msg, "success");
                    window.location.href = '/assessment-exam';
                }, 1000);
            },
            submitNow() {// submit application
                swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, Proceed!'
                }).then((result) => {
                    if (result.value) {
                        this.makeSubmitApiCall();// make api call
                    }
                });
            },
            makeSubmitApiCall() {
                this.isloading = true;
                this.showInputErrorStatus = false;
                this.preloader();// show loading
                if(this.applicant.id) {// submit
                    this.submitAssessmentForm(this.applicant.id).then((res) => {
                        this.loadApplicantInfo();
                        this.isloading = false;
                        if(res.status) {
                            this.showDashbaord();
                            swal.fire("Application Submitted!", res.msg, "success");                        
                        } else {
                            let msg = "An error occurred while submitting form, reload page";
                            swal.fire("Error Ocurred!", msg, "warning");
                        }
                    }).
                    catch(() => {
                        this.isloading = false;
                        let msg = "Network Failure!, kindly reload the page...";
                        swal.fire("Error Ocurred!", msg, "warning");
                    });
                }   else {
                    this.isloading = false;
                    let msg = "Network Failure!, kindly reload the page...";
                    swal.fire("Error Ocurred!", msg, "warning");
                }
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        }
    }
</script>

<style scoped>
    .no-padding-bottom {
        padding-bottom: 0rem !important
    }
    .right {
        float: right !important;
    }
</style>

<style>
    .form-control-label {
        width: 100%;
    }
    .height-50 {
        height: 50px;
    }
    .borderLine {
        border: 1px solid #ededed;
    }
</style>