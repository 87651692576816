<template>
    <div>
        <div v-if="! isLoadingBlade && ! showErrorStatus">
            <h3 class="text-uppercase">Additional Information</h3>
            <hr>
            <div class="row">
                <div class="col-lg-12">
                    <base-input alternative=""
                        label="Professional Experiences"
                        required
                    >
                        <textarea
                            rows="2"
                            class="form-control form-control-alternative"
                            placeholder="if no experience, enter 'None'"
                            v-model="record.professional_experiences"
                        ></textarea>
                    </base-input>
                </div>

                <div class="col-lg-3">
                    <base-input alternative=""
                        label="Practice Type"
                        required
                    >
                        <select
                            class="form-control form-control-alternative"
                            v-model="record.practice_type"
                        >
                            <option :value="null" disabled selected>--- Select One ---</option>
                            <option value="m">Medical Practitioner</option>
                            <option value="d">Dental Practitioner</option>
                        </select>
                    </base-input>
                </div>

                <div class="col-lg-3">
                    <base-input alternative=""
                        label="Employment Status"
                        required
                    >
                        <select
                            class="form-control form-control-alternative"
                            v-model="record.employ_status"
                            @change="updateEmployment()"
                        >
                            <option :value="null" disabled selected>--- Select One ---</option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                        </select>
                    </base-input>
                </div>

                <div class="col-lg-3" v-if="showEmploymentSector">
                    <base-input alternative=""
                        label="Employment Sector"
                        required
                    >
                        <select
                            class="form-control form-control-alternative"
                            v-model="record.employ_sector"
                        >
                            <option :value="null" disabled selected>--- Select One ---</option>
                            <option value="government">Government</option>
                            <option value="private">Private</option>
                        </select>
                    </base-input>
                </div>

                <div class="col-lg-3" v-if="showEmploymentSector">
                    <base-input alternative=""
                        label="Employment Country"
                        required
                    >
                        <autocomplete
                            v-if="locations.countries && locations.countries.length > 0"
                            input-class="form-control form-control-alternative"
                            placeholder="Type to Search for Employment Country"
                            showNoResults
                            :source="locations.countries"
                            v-model="record.country_id"
                            :initialDisplay="(hasCountry ? hasCountry.name : '')"
                            @selected="changeCountry"
                        >
                        </autocomplete>
                    </base-input>
                </div>

                <div class="col-lg-3" v-if="record.country_id && showForeignState">
                    <base-input alternative=""
                        label="Employment State"
                        required
                    >
                        <autocomplete
                            ref="autocomplete"
                            :disableInput="cities && cities.length == 0"
                            input-class="form-control form-control-alternative"
                            placeholder="Type to Search Employment State"
                            showNoResults
                            :source="cities"
                            :initialValue="record.foreign_state"
                            :initialDisplay="record.foreign_state"
                            resultsValue="name"
                            @selected="changeCity"
                        >
                        </autocomplete>
                    </base-input>
                </div>

                <div class="col-lg-3" v-if="record.country_id && showForeignState && displayOtherState">
                    <base-input alternative=""
                        label="Other Employment State"
                        placeholder="Enter Other Employment State"
                        input-classes="form-control-alternative"
                        v-model="other_foreign_state"
                        required
                    />
                </div>

                <div class="col-lg-3" v-if="! showForeignState && locations && locations.states">
                    <base-input alternative=""
                        label="Employment State"
                        required
                    >
                        <autocomplete
                            v-if="locations.states && locations.states.length > 0"
                            input-class="form-control form-control-alternative"
                            placeholder="Type to Search for State of Origin"
                            showNoResults
                            :source="locations.states"
                            v-model="record.state_id"
                            :initialDisplay="(hasState ? hasState.name : '')"
                        >
                        </autocomplete>
                    </base-input>
                </div>

                <div class="col-lg-3">
                    <base-input alternative=""
                        label="Have you taken this exam before"
                        required
                    >
                        <select
                            class="form-control form-control-alternative"
                            v-model="record.exam_taken"
                            @change="updateExamTimes()"
                        >
                            <option :value="null" disabled selected>--- Select One ---</option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                        </select>
                    </base-input>
                </div>

                <div class="col-lg-3" v-if="showExamTimes">
                    <base-input alternative=""
                        label="How many times"
                        required
                    >
                        <select
                            class="form-control form-control-alternative"
                            v-model="record.exam_times"
                        >
                            <option :value="null" disabled selected>--- Select One ---</option>
                            <option
                                v-for="number in numbers"
                                :value="number"
                                :key="number"
                            >
                                {{number}}
                            </option>
                        </select>
                    </base-input>
                </div>

                <div class="col-lg-3">
                    <base-input alternative=""
                        label="Have you attended any remedial course"
                        required
                    >
                        <select
                            class="form-control form-control-alternative"
                            v-model="record.remedial_course"
                        >
                            <option :value="null" disabled selected>--- Select One ---</option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                        </select>
                    </base-input>
                </div>

                <div class="col-lg-3">
                    <base-input alternative=""
                        label="Epic Verification Reference ID"
                        placeholder="Enter Epic Verification Reference ID"
                        input-classes="form-control-alternative"
                        v-model="record.epic_id"
                    />
                </div>
            </div>
        </div>

        <div class="col-lg-12" v-if="isLoadingBlade">
            <div class="d-flex justify-content-center">
                <img :src="loadingBladeUrl" />
            </div>
        </div>

        <div class="col-lg-12 text-center" v-if='(showErrorStatus || showInputErrorStatus) && ! isLoadingBlade'>
            <base-alert :type="errorType">
                <!-- <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span> -->
                <span class="alert-inner--text">
                    <h2 class="text-white">{{errorMsg}}</h2>
                </span>
            </base-alert>
        </div>

        <!-- <div class="col-lg-12" v-if="! isLoadingBlade">
            <base-button
                type="success"
                @click="saveAdditionalData()"
            >
                <i class="fa fa-save"></i>
                {{ isloading ? 'Please Wait...' : 'Save Changes'}}
            </base-button>
        </div> -->

        <hr>

        <div class="col-lg-12" v-if="! isLoadingBlade">
            <base-button
                type="danger"
                @click="showPreviousPage()"
            >
                <i class="fa fa-arrow-left"></i>
                Previous
            </base-button>

            <div class="right" v-if="! showNextButton">
                <base-button
                    type="success"
                    @click="saveAdditionalData()"
                >
                    <i class="fa fa-save"></i>
                    {{ isloading ? 'Please Wait...' : 'Save Changes'}}
                </base-button>          
            </div>

            <div class="right" v-if="showNextButton">
                <base-button
                    type="primary"
                    @click="continueLater()"
                >
                    <i class="fa fa-save"></i>
                    Save &amp; Continue Later
                </base-button>

                <base-button
                    type="success"
                    @click="showNextPage()"
                    :disabled="! disableContinue"
                >
                    {{ isloading ? 'Please Wait...' : 'Continue'}}
                    <i class="fa fa-arrow-right"></i>
                </base-button>
            </div> 

        </div>

    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import swal from 'sweetalert2';// import sweet alert
    import Autocomplete from 'vuejs-auto-complete';
    import states from '../../../../states.json';

    export default {
        name: 'AssessmentAdditionalData',
        props: ['locations', 'displayQualData', 'applicant', 'loadApplicantInfo', 'displayEmployData'],
        components: {
            Autocomplete
        },
        data() {
            return {
                isLoadingBlade: true,
                isloading: false,
                showInputErrorStatus: false,
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                record: {
                    professional_experiences: null,
                    employ_status: null,
                    employ_sector: null,
                    country_id: null,
                    foreign_state: null,
                    state_id: null,
                    exam_taken: null,
                    exam_times: null,
                    remedial_course: null,
                    epic_id: null,
                    practice_type: null
                },
                other_foreign_state: null,
                displayOtherState: false,
                states: states,
                cities: [],
                showNextButton: false,
                id: localStorage.getItem('applicantId')
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl']),
            hasCountry() {
                return this.locations.countries.filter(item => item.id == this.record.country_id)[0];
            },
            hasState() {
                return this.locations.states.filter(item => item.id == this.record.state_id)[0];
            },
            showForeignState() {
                return this.record.country_id == 164 ? false : true;
            },
            showEmploymentSector() {
                return this.record.employ_status && this.record.employ_status == 'yes' ? true : false;
            },
            showExamTimes() {
                return this.record.exam_taken && this.record.exam_taken == 'yes' ? true : false;
            },
            numbers() {
                let startNum = 1;// start from 1
                let endNum = 50
                let numbers = [];
                for (let i = startNum; i <= endNum; i++ ) {
                    numbers.push(i);
                }
                return numbers;
            },
            disableContinue() {
                return this.record.professional_experiences && this.record.employ_status
                    && this.record.exam_taken && this.record.remedial_course && this.record.practice_type
            }
        },
        methods: {
            ...mapActions(['saveAssessmentApplicantData']),
            changeCity(e) {
                let data = e.selectedObject;
                this.other_foreign_state = null;
                if(data.id == 0) {
                    this.record.foreign_state = null;
                    this.displayOtherState = true;
                } else {
                    this.displayOtherState = false;
                    this.record.foreign_state = data.name;
                }
            },
            changeCountry(e) {
                this.cities = [];
                let data = e.selectedObject;
                let other = { id : 0, name: 'Others (Specify)'};
                this.cities = this.states.filter(item => item.country_code == data.code);
                this.cities.push(other);
            },
            updateEmployment() {
                if(this.record.employ_status && this.record.employ_status == 'no') {
                    this.record.employ_sector = null;
                    this.record.country_id = null;
                    this.record.foreign_state = null;
                    this.record.state_id = null;
                }
            },
            continueLater() {
                this.preloader();
                setTimeout(function() {
                    let msg = 'Application was updated successfully, you can continue later';
                    swal.fire("Application Updated!", msg, "success");
                    window.location.href = '/assessment-exam';
                }, 1000);
            },
            updateExamTimes() {
                if(this.record.exam_taken && this.record.exam_taken == 'no') {
                    this.record.exam_times = null;
                }
            },
            showPreviousPage() {// go to next page
                return this.displayQualData(true);
            },
            setAdditionalData() {// set applicant data
                this.showInputErrorStatus = false;
                this.emptyFields();
                this.isLoadingBlade = false;
                if(this.applicant) {
                    let data = this.applicant.additional_data;
                    if(data) {
                        this.record = {
                            professional_experiences: data.professional_experiences,
                            employ_status: data.employ_status,
                            employ_sector: data.employ_sector,
                            country_id: data.country_id,
                            foreign_state: data.foreign_state,
                            state_id: data.state_id,
                            exam_taken: data.exam_taken,
                            exam_times: data.exam_times,
                            remedial_course: data.remedial_course,
                            epic_id: data.epic_id,
                            practice_type: data.practice_type,
                            id: data.id
                        };
                    } else {
                        this.emptyFields();
                    }
                }
            },
            showNextPage() {// go to next page
                return this.displayEmployData(true);
            },
            saveAdditionalData() {
                this.isloading = true;
                this.showInputErrorStatus = false;
                this.preloader();// show loading
                let status = this.validatedData();// valid input data
                if(status) {// update
                    this.record.foreign_state = this.record.foreign_state ? this.record.foreign_state :
                                                    this.other_foreign_state;// update foreign state
                    let data = { 'additional_data' : this.record , 'id' : this.id };
                    this.saveAssessmentApplicantData(data).then((res) => {
                        // this.loadApplicantInfo();
                        this.isloading = false;
                        if(res.status) {
                            this.showNextButton = true;
                            swal.fire("Information Updated!", res.msg, "success");
                        } else {
                            let msg = "Payment details could not be found, reload page";
                            swal.fire("Error Ocurred!", msg, "warning");
                        }
                    }).
                    catch(() => {
                        this.isloading = false;
                        let msg = "Network Failure!, kindly reload the page...";
                        swal.fire("Error Ocurred!", msg, "warning");
                    });
                }   else {
                    this.isloading = false;
                    swal.close();
                }
            },
            emptyFields() {
                this.displayOtherState = false;
                this.other_foreign_state = null;
                return this.record = {
                    professional_experiences: null,
                    employ_status: null,
                    employ_sector: null,
                    country_id: null,
                    foreign_state: null,
                    state_id: null,
                    exam_taken: null,
                    exam_times: null,
                    remedial_course: null,
                    epic_id: null,
                    practice_type: null
                };
            },
            updateEmploymentData() {
                if(this.record.country_id == 164) {
                    this.record.foreign_state = null;
                } else {
                    this.record.state_id = null;
                }
            },
            validatedData() {
                this.updateEmploymentData();// update employment
                let employSector = true;
                let state = true;
                let foreignState = true;
                let country = true;
                let examTaken = true;
                let data = this.record;
                data.remedial_course != null ? data.remedial_course : this.showInuptError('Remedial Course');
                if(data.exam_taken && data.exam_taken == 'yes' && data.exam_times) {
                    examTaken = true;
                } else if(data.exam_taken && data.exam_taken == 'no') {
                    examTaken = true;
                } else {
                    examTaken = false;
                    this.showInuptError('Examination Times');
                }
                data.exam_taken != null ? data.exam_taken : this.showInuptError('Examination Taken');
                if(data.employ_status && data.employ_status == 'yes') {
                    employSector = data.employ_sector ? true : false;
                    country = data.country_id ? true : false;
                    if((data.country_id && data.country_id == 164) && data.state_id) {
                        state = true;
                    } else if((data.country_id && data.country_id == 164) && ! data.state_id) {
                        state = false;
                    }
                    if((data.country_id && data.country_id != 164) && (data.foreign_state || this.other_foreign_state)) {
                        foreignState = true;
                    } else if((data.country_id && data.country_id != 164) && ! data.foreign_state) {
                        foreignState = false;
                    }
                } else if(data.employ_status && data.employ_status == 'no') {
                    employSector = true;
                    country = true;
                    state = true;
                    foreignState = true;
                }
                ! foreignState && (data.country_id != 164) ? this.showInuptError('Employment Foreign State') : false;
                ! state && (data.country_id == 164) ? this.showInuptError('Employment State') : false;
                ! country ? this.showInuptError('Employment Country') : false;
                ! employSector ? this.showInuptError('Employment Sector') : false;
                data.employ_status != null ? data.employ_status : this.showInuptError('Employment Status');
                data.practice_type != null ? data.practice_type : this.showInuptError('Practice Type');
                data.professional_experiences != null ? data.professional_experiences : this.showInuptError('Professional Experiences');
                let status = data.professional_experiences && data.employ_status && employSector && state && foreignState
                                && data.exam_taken && data.remedial_course && country && examTaken && data.practice_type;
                return status ? true : false;
            },
            showInuptError(name) {
                this.isloading = false;
                this.showInputErrorStatus = true;
                this.errorType = 'warning'
                this.errorMsg = 'Invalid ' + name + ' has been entered';
                var self = this;
                setTimeout(function() { self.showInputErrorStatus = false; }, 5000);
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        created() {
            this.setAdditionalData();
        }
    }
</script>

<style scoped>
    .no-padding-bottom {
        padding-bottom: 0rem !important
    }
    .right {
        float: right !important;
    }
</style>

<style>
    .form-control-label {
        width: 100%;
    }
    .height-50 {
        height: 50px;
    }
    .borderLine {
        border: 1px solid #ededed;
    }
</style>