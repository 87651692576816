<template>
    <form>
        <div class="col-lg-12" v-if="isLoadingBlade">
            <div class="d-flex justify-content-center">
                <img :src="loadingBladeUrl" />
            </div>
        </div>

        <div v-if="! isLoadingBlade">
            <h3 class="heading-small text-danger mb-4">
                Note: Upload your internship files below, before you can proceed to make payment
                for Full Registration
                <br><br>
                <span class="text-danger">
                    <i class="fa fa-info-circle"></i> Only PDF Files (Not More than 800KB) are supported for each file
                </span>            
            </h3>

            <div class="container ct-example-row">
                <!-- import Internship Document File template here -->
                <InternshipDocument 
                    v-bind:documents="documents"
                    v-bind:id="record.id"
                    v-bind:type="type"
                    v-bind:loadInternship="loadInternship"
                />
            </div>

            <br>
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <base-input 
                        label="Internship Performance Start Date"
                        addon-left-icon="ni ni-calendar-grid-58"
                        required
                        
                    >
                        <flat-picker 
                            slot-scope="{focus, blur}"
                            @on-open="focus"
                            @on-close="blur"
                            :config="{allowInput: true}"
                            class="form-control datepicker"
                            v-model="record.start_date"
                        >
                        </flat-picker>
                    </base-input>
                </div>
                <div class="col-lg-6">
                    <base-input 
                        label="Internship Performance End Date"
                        addon-left-icon="ni ni-calendar-grid-58"
                        required
                        
                    >
                        <flat-picker 
                            slot-scope="{focus, blur}"
                            @on-open="focus"
                            @on-close="blur"
                            :config="{allowInput: true}"
                            class="form-control datepicker"
                            v-model="record.end_date"
                        >
                        </flat-picker>
                    </base-input>
                </div>
            </div>
            
            <div class="col-sm-12 text-center">
                <base-alert :type="errorType" v-if='showErrorStatus'>
                    <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                    <span class="alert-inner--text">
                        {{errorMsg}}
                    </span>
                </base-alert>
            </div>

            <div class="col-lg-12"></div>
            <div class="col-lg-12" v-if="! showProceedButton">
                <base-button 
                    type="success" 
                    @click="updateRecords()"
                    :disabled="isloading"
                >
                    <i class="fa fa-save"></i> 
                    {{isloading ? 'Please Wait...' : 'Update Record'}}
                </base-button>
            </div>
        </div>

        <div class="pl-lg-4">
            <!-- redirect to full registration payment page below -->
            <router-link 
                to="/new-application-payment/2" 
                class="btn btn-lg btn-success right"
                v-if="showProceedButton && hasInternReport"
            >
                Proceed 
                <i class="fa fa-arrow-right"></i> 
            </router-link>         
        </div>

    </form>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import InternshipDocument from '../../ProfessionalData/Internship/InternshipDocument.vue';
    import flatPicker from "vue-flatpickr-component";
    import "flatpickr/dist/flatpickr.css";

    export default {
        components: {
            InternshipDocument,
            flatPicker
        },
        name: 'ApplyFullUpgrade',
        data() {
            return {
                record: {
                    start_date: null,
                    end_date: null,
                    id: null
                },
                documents: [],
                isloading: false,
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isLoadingBlade: true,
                showProceedButton: false,
                reload: true,
                type: 'upgrade'
            };
        },
        computed: {
            ...mapGetters(['loadingBladeUrl']),
            hasInternReport() {
                let status = [];
                let checker = arr => arr.every(v => v === true);
                for(let doc of this.documents) {
                    status.push(doc.hasFile);
                }
                return checker(status) && this.record.start_date != null 
                    && this.record.end_date != null;
            }
        },
        methods: {
            ...mapActions(['getUserInternshipRecords', 'updateInternship']),
            showNextPage() {// go to next page
                // return this.displayInsurance(true);
            },
            loadInternship() {
                const id = this.$store.state.user.userDataId;// get user data Id
                this.getUserInternshipRecords({'id' : id}).then((res) => {
                    this.isLoadingBlade = false;
                    this.documents = res.documents;
                    this.record.start_date = res.start_date;
                    this.record.end_date = res.end_date;
                    this.record.id = this.$store.state.user.userId;// get user Id
                }).
                catch(() => {
                    this.isLoadingBlade = false;
                    this.showErrorStatus = true;
                    this.errorMsg = "Network Failure!, Kindly reload the page...";
                    this.errorType = 'danger';
                });
            },
            updateRecords() {
                this.isloading = true;
                let data = this.validatedInputs();// validate inputs
                if(data) {// save edu records
                    data.id = this.$store.state.user.userDataId;// get user data Id
                    this.updateInternship(data).then((res) => {
                        this.isloading = false;
                        this.loadInternship();
                        if(res.status) {
                            let msg = 'Internship Records updated successfully';
                            this.showMsg(msg, 'success');
                            this.showProceedButton = true;
                        } else {
                            this.showErrorMsg();
                        }
                    }).
                    catch(() => {
                        this.isloading = false;
                        this.loadInternship();
                        this.showErrorMsg();
                    });
                } else {
                    this.isloading = false;
                    this.loadInternship();
                }
            },
            validatedInputs() {
                let data = this.record;  
                let internRecord = { start_date: null, end_date: null };
                internRecord.end_date = data.end_date == null ? 
                    this.showInuptError('End Date') : data.end_date;
                internRecord.start_date = data.start_date == null ? 
                    this.showInuptError('Start Date') : data.start_date; 
                // check documents
                let status = [];
                let docStatus = false;
                let checker = arr => arr.every(v => v === true);
                for(let doc of this.documents) {
                    status.push(doc.hasFile);
                }
                let msg = 'Some documents has not been uploaded yet, kindly upload all documents';
                docStatus = checker(status) ? true : this.showMsg(msg, 'warning');
                return this.isEmpty(internRecord) && docStatus? internRecord : false;
            },
            isEmpty(obj) {// check for empty field
                return ! Object.keys(obj).some(k => !obj[k]);
            },
            showErrorMsg() {
                this.showErrorStatus = true
                this.errorType = 'warning'
                this.errorMsg = 'An error occurred, kindly reload page and try again';
                var self = this;
                setTimeout(function() { self.showErrorStatus = false; }, 5000);
            },
            showInuptError(name) {
                this.isloading = false;
                this.showErrorStatus = true
                this.errorType = 'warning'
                this.errorMsg = 'Invalid ' + name + ' has been entered';
                var self = this;
                setTimeout(function() { self.showErrorStatus = false; }, 5000);
            },
            showMsg(msg, type) {
                this.showErrorStatus = true;
                this.errorType = type;
                this.errorMsg = msg;
                var self = this;
                setTimeout(function() { self.showErrorStatus = false; }, 5000);
            }
        },
        created() {
            this.loadInternship();// load Internship
        },
    }
</script>

<style scoped>
    .right {
        float: right;
    }
</style>

<style>
    .form-control-label {
        width: 100%;
    }
</style>
