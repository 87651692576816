<template>
    <div>
        <div class="row" v-if="showPayeeForm && ! isLoadingBlade">
            <div class="col-lg-12">
                <h3 class="text-uppercase">Fill the Payee form below and proceed to pay for verification Fee...</h3>
                <span class="text-danger">
                    Note that after successful payment, the RRR number (must have been paid) will be used to create a
                    signup account on our portal and wait for approval by MDCN before you can proceed to complete registration.
                    Keep your RRR number safe...
                </span>
                <hr>
            </div>

            <div class="col-lg-12">
                <base-input alternative=""
                    label="Payee's Full Name"
                    placeholder="Payee's Full Name"
                    input-classes="form-control-alternative"
                    v-model="payee.name"
                    required
                />
            </div>

            <div class="col-lg-6">
                <base-input alternative=""
                    label="Payee's Email Address"
                    placeholder="Payee's Email Address"
                    input-classes="form-control-alternative"
                    v-model="payee.email"
                    required
                />
            </div>

            <div class="col-lg-6">
                <base-input alternative=""
                    label="Payee's Phone Number"
                    placeholder="Payee's Phone Number"
                    input-classes="form-control-alternative"
                >
                <!-- v-model="profile.phone"  -->
                    <VuePhoneNumberInput
                        v-model="tel"
                        @update="updatePhoneNumber"
                        default-country-code="NG"
                        :no-example="true"
                        :clearable="true"
                    />
                </base-input>
            </div>

            <div class="col-lg-12">
                <base-button
                    type="danger"
                    @click="hidePolicyPage(false)"
                >
                    <i class="fa fa-arrow-left"></i>
                    Go Back
                </base-button>

                <base-button
                    type="success"
                    class="right"
                    @click="generatePayeeInfo()"
                >
                    {{ isloading ? 'Please Wait...' : 'Proceed Now'}}
                    <i class="fa fa-arrow-right"></i>
                </base-button>
            </div>
        </div>

        <div class="col-lg-12 text-center" v-if='showErrorStatus'>
            <base-alert :type="errorType">
                <!-- <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span> -->
                <span class="alert-inner--text">
                    <h2 class="text-white">{{errorMsg}}</h2>
                </span>
            </base-alert>
        </div>

        <div v-if="! showGenerateRrr && ! showPayeeForm && ! isLoadingBlade">
            <div class="col-lg-12 text-center">
                <hr>
                <h3 class="mb-0 text-uppercase">
                    Payment Details for
                    <em class="text-danger">{{ type.name }}</em>
                </h3>
                <hr>
            </div>

            <div class="container ct-example-row">
                <div class="row">
                    <div class="col">
                        <ul
                            class="list-unstyled"
                            v-if="type && type.has_payment"
                        >
                            <li>
                                <h3 class="text-center">
                                    Application Name: &nbsp;
                                    <em class="text-capitalize font-weight-light">
                                        {{ type.name }}
                                    </em>
                                </h3>
                                <br>
                                <h3 class="text-center">
                                    Application Amount: &nbsp;
                                    <em class="text-capitalize font-weight-light">
                                        {{ type.has_payment.amount | formatPrice }}
                                        NGN
                                    </em>
                                </h3>
                                <div>
                                    <hr class="my-4">
                                    <h3 class="text-center text-uppercase">
                                        Payment Details
                                    </h3>
                                    <hr class="my-4">
                                    <h3
                                        class="text-center"
                                        v-show="type.has_payment && type.has_payment.has_breakdowns.length > 0"
                                        v-for="breakdown in type.has_payment.has_breakdowns"
                                        :key="breakdown.id"
                                    >
                                        {{ breakdown.name }} : &nbsp;
                                        <em class="text-capitalize font-weight-light">
                                            {{ breakdown.amount | formatPrice }} NGN
                                        </em>
                                        <br>
                                    </h3>
                                </div>
                                <hr class="my-4">
                                <h3 class="text-center">
                                    Total Amount: &nbsp;
                                    <em class="text-capitalize font-weight-light">
                                        {{ totalAmount | formatPrice }}
                                        NGN
                                    </em>
                                </h3>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-12">
                <hr>
                <form :action="remitaPaymentUrl" method="POST">
                    <input
                        type="hidden"
                        v-for="item in paymentRecords"
                        :key="item.title"
                        :name="item.title"
                        :value="item.value"
                    />

                    <div class="right">
                        <base-button
                            type="dark"
                            @click="generateRRR()"
                        >
                            <i class="fa fa-history"></i>
                            {{ isloading ? 'Please Wait...' : 'Generate RRR &amp; Pay at the Bank'}}
                        </base-button>
                    </div>

                    <base-button
                        type="success"
                        class="right"
                        nativeType="submit"
                    >
                        <i class="fa fa-credit-card"></i>
                        {{ isloading ? 'Please Wait...' : 'Pay Directly Online Now'}}
                    </base-button>


                    <base-button
                        type="danger"
                        @click="hidePolicyPage(false)"
                    >
                        <i class="fa fa-arrow-left"></i>
                        Go Back
                    </base-button>
                </form>
                <hr>
            </div>
        </div>

        <div class="row" v-if="isLoadingBlade">
            <div class="col-lg-12">
                <div class="d-flex justify-content-center">
                    <img :src="loadingBladeUrl" />
                </div>
            </div>
        </div>

        <TempVerifyGenerateRrr
            :payee="payee"
            :rrr="rrr"
            :type="type"
            :orderId="orderId"
            :amount="totalAmount"
            v-on:hidePolicyPage="hidePolicyPage"
            v-if="showGenerateRrr && ! showPayeeForm"
        />

        <br><br><br>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import VuePhoneNumberInput from 'vue-phone-number-input';
    import 'vue-phone-number-input/dist/vue-phone-number-input.css';
    import TempVerifyGenerateRrr from './TempVerifyGenerateRrr';
    import swal from 'sweetalert2';// import sweet alert

    export default {
        name: 'TempVerifyPayPage',
        props: ['hidePolicyPage'],
        filters: {
            formatPrice(value) {
                if(value) {
                    let val = (value/1).toFixed(2).replace(',', '.');
                    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                } else {
                    return 'N/A';
                }
            }
        },
        components: {
            VuePhoneNumberInput,
            TempVerifyGenerateRrr
        },
        data() {
            return {
                isLoadingBlade: true,
                isloading: false,
                orderId: null,
                showGenerateRrr: false,
                payee: {
                    name: null,
                    email: null,
                    phone: null
                },
                tel: null,
                showPayeeForm: true,
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                serviceType: null,
                type: null
            }
        },
        computed: {
            ...mapGetters(['remitaMerchantId', 'remitaTempVerifyCallUrl', 'remitaApiKey', 'remitaPaymentUrl',
                'loadingBladeUrl']),
            totalAmount() {
                return parseInt(this.type.has_payment.amount);
            },
            gethash() {
                var sha512 = require('js-sha512');
                let text = this.remitaMerchantId+this.serviceType.remita_code+this.orderId
                    +this.totalAmount+this.remitaTempVerifyCallUrl+this.remitaApiKey;
                return sha512(text);
            },
            paymentRecords() {// get payment records
                return [
                    {'title' : 'merchantId', 'value' : this.remitaMerchantId},
                    {'title' : 'serviceTypeId', 'value' : this.serviceType.remita_code},
                    {'title' : 'amt', 'value' : this.totalAmount},
                    {'title' : 'responseurl', 'value' : this.remitaTempVerifyCallUrl},
                    {'title' : 'hash', 'value' : this.gethash},
                    {'title' : 'payerName', 'value' : this.payee.name},
                    {'title' : 'paymenttype', 'value' : ''},
                    {'title' : 'payerEmail', 'value' : this.payee.email},
                    {'title' : 'payerPhone', 'value' : this.payee.phone},
                    {'title' : 'orderId', 'value' : this.orderId},
                ]
            }
        },
        methods: {
            ...mapActions(['generateAssessmentRrr', 'getTempVerifyDetails']),
            loadPaymentDetails() {
                this.isLoadingBlade = true;
                this.isloading = true;
                this.emptyFields();
                this.getTempVerifyDetails().then((res) => {
                    this.isloading = false;
                    this.isLoadingBlade = false;
                    if(res.status) {
                        this.type = res.application_type;
                        this.serviceType = res.service_type;
                    } else {
                        this.showErrorStatus = true;
                        this.isLoadingBlade = false;
                        this.errorMsg = "Network Failure!, kindly reload the page...";
                        this.errorType = 'danger';
                    }
                }).
                catch(() => {
                    this.showErrorStatus = true;
                    this.isLoadingBlade = false;
                    this.errorMsg = "Network Failure!, kindly reload the page...";
                    this.errorType = 'danger';
                });
            },
            generatePayeeInfo() {
                let status = this.validatedData();// valid input data
                return status ? this.showPayeeForm = false : false;
            },
            emptyFields() {
                this.payee = {
                    name: null,
                    email: null,
                    phone: null
                };
                this.orderId = null;
                return this.orderId = this.genRandomString(12);
            },
            validatedData() {
                let data = this.payee;
                data.phone != null ? data.phone : this.showInuptError('Payees\'s Phone Number');
                data.email != null ? data.email : this.showInuptError('Payees\'s Email Address');
                data.name != null ? data.name : this.showInuptError('Payees\'s Full Name');
                let status = data.name && data.email && data.phone;
                return status ? true : false;
            },
            showInuptError(name) {
                this.isloading = false;
                this.showErrorStatus = true
                this.errorType = 'warning'
                this.errorMsg = 'Invalid ' + name + ' has been entered';
                var self = this;
                setTimeout(function() { self.showErrorStatus = false; }, 5000);
            },
            updatePhoneNumber(event) {
                if(event && event.isValid && event.e164) {
                    this.payee.phone = event.formattedNumber;
                }
            },
            genRandomString(length = 12) {
                var text = '';
                var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
                for (var i=0; i < length; i++) {
                    text += possible.charAt(Math.floor(Math.random() * possible.length));
                }
                return text;
            },
            generateRRR() {// generate rrr
                let data = {
                    serviceTypeCode : this.serviceType.remita_code,
                    amount : this.totalAmount,
                    orderId : this.orderId,
                    payerName : this.payee.name,
                    payerEmail : this.payee.email,
                    payerPhone : this.payee.phone
                };
                this.isloading = true;
                this.rrr = null
                this.preloader();
                this.generateAssessmentRrr(data).then((res) => {
                    this.isloading = false;
                    swal.close();
                    if(res.statuscode == "025") {
                        this.rrr = res;
                        this.showGenerateRrr = true;
                    } else {
                        this.setData();
                        let msg = "RRR was not generated, try again...";
                        swal.fire("RRR Failed!", msg, "warning");
                    }
                }).
                catch(() => {
                    this.emptyFields();
                    this.isloading = false;
                    let msg = "Network Failure!, kindly reload the page...";
                    swal.fire("Error Ocurred!", msg, "warning");
                });
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        created() {
            this.loadPaymentDetails();
        }
    }
</script>

<style scoped>
    .no-padding-bottom {
        padding-bottom: 0rem !important
    }
    .right {
        float: right;
    }
</style>

<style>
    .form-control-label {
        width: 100%;
    }
    .height-50 {
        height: 50px;
    }
    .borderLine {
        border: 1px solid #ededed;
    }
</style>