<template>
    <div>
        <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center">
            <span class="mask bg-gradient-danger opacity-8"></span>
        </base-header>
        
        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col-xl-12 order-xl-1">
                    <card shadow type="secondary">

                        <div class="pl-lg-4">

                            <div v-if="! hasNotPaidMwanLevy">
                                <!-- Import Application Payment Details blade here -->
                                <ApplicationPaymentDetails
                                    v-bind:application="application"
                                    v-bind:loadApplication="loadApplication"
                                    v-if="! isLoadingBlade"
                                />

                                <div class="col-lg-12" v-if="isLoadingBlade">
                                    <div class="d-flex justify-content-center">
                                        <img :src="loadingBladeUrl" />
                                    </div>
                                </div>

                                <div class="col-sm-12 text-center">
                                    <base-alert :type="errorType" v-if='showErrorStatus'>
                                        <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                                        <span class="alert-inner--text">
                                            {{ errorMsg }}
                                        </span>
                                    </base-alert>
                                </div>
                            </div>


                            <MwanPayment
                                :loadApplication="loadApplication"
                                v-if="hasNotPaidMwanLevy && ! isLoadingBlade"
                            />
                        </div>

                    </card>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'; //AddQualPayment
    import ApplicationPaymentDetails from './ApplicationPaymentDetails';
    import MwanPayment from '../../NewAccount/MwanPayment.vue';

    export default {
        components: {
            ApplicationPaymentDetails,
            MwanPayment
        },
        name: 'ApplicationPayment',
        data() {
            return {
                id: this.$route.params.id,
                application: {},
                isLoadingBlade: true,
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isloading: false
            }
        },
        filters: {
            formatPrice(value) {
                if(value) {
                    let val = (value/1).toFixed(2).replace(',', '.');
                    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                } else {
                    return 'N/A';
                }
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl', 'user']),
            hasNotPaidMwanLevy() {// if doctor is medical women and has not paid levy
                const mwan = this.user && this.user.basic_profile && this.user.basic_profile.mwan_levy ? this.user.basic_profile.mwan_levy : false;
                return mwan && mwan.status && ! mwan.pay_status;
            }
        },
        methods: {
            ...mapActions(['getApplication', 'getOtherPayment']),
            loadApplication() {// get application
                let status = this.checkForOtherPayment()
                if(! status) {// process payment
                    this.getApplication({'id' : this.id}).then((res) => {
                        this.application = res;
                        this.isLoadingBlade = false;
                    }).
                    catch(() => {
                        this.isLoadingBlade = false;
                        this.showErrorStatus = true;
                        this.errorMsg = "Network Failure!", "Kindly reload the page...";
                        this.errorType = 'danger';
                    });
                }
            },
            checkForOtherPayment() {// check if user can pass to make payment
                this.getOtherPayment().then((res) => {
                    if(res.status) {// redirect payment
                        return window.location.href='/new-application-payment/'+res.application.id;
                    }
                }).
                catch(() => {
                    this.isLoadingBlade = false;
                    return false;
                });
            }
        },
        created() {
            this.loadApplication();// load application
        },
    }
</script>

<style scoped>
.right {
    float: right;
}
</style>