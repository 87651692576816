<template>
    <div>
        <div class="row" v-if='! showErrorStatus && ! isLoadingBlade && hasValidAssessmentType && (! hasIncompletePayment || batchIsSuspended)'>
            <div class="col-lg-8">
                <h1 class="text-uppercase">
                    <em class="text-danger">{{ personal.fullname }}</em>
                    'S ASSESSMENT EXAM DASHBOARD
                </h1>
                <span class="text-uppercase">
                    <strong>RRR:</strong> {{ rrr }}
                </span><br>
                <span class="text-uppercase">
                    <strong>Application Status: </strong>
                    <badge
                        :type="(applicant.status == 'approved') ? 'success' : 'warning'"
                        v-if="applicant"
                    >
                        {{ (applicant.status == 'rejected') ? 'INCOMPLETE APPLICATION' : applicant.status }}
                    </badge>
                    <br>
                </span>
                <span class="text-uppercase">
                    <strong>Application Date:</strong> {{ applicant.reg_date | getDateFormat }}
                </span><br>
                <span class="text-uppercase" v-if="(applicant.status == 'rejected' || applicant.status == 'approved')">
                    <strong>{{ applicant.status }} Date:</strong> {{ applicant.approved_date | getDateFormat }}
                    <br>
                </span>
                <span class="text-uppercase" v-if="applicant && applicant.batch">
                    <strong>Assessment Batch:</strong> {{ applicant.batch.title + ' (' + applicant.batch.year + ')' }}
                </span>
                <br><br>

                <span v-if="(applicant.status == 'submitted')" class="text-danger">
                    <em>
                        Note: Your Application is currently reviewed by MDCN, Kindly check back later...
                    </em><br><br>
                </span>

                <span v-if="(applicant.status == 'rejected')" class="text-danger">
                    <em>
                        Note: Your Application was rejected, due to the reason stated below, carefully go through
                        the reason and update accordingly. Kindly click on the 
                        <em class="text-info">Edit Application</em> button at the 
                        bottom-right corner to update your application form before application closing date...
                    </em><br><br>
                    <strong>Reason:</strong> {{ applicant.reject_reason }}
                    <br><br>
                </span>
            </div>

            <div class="col-lg-4 text-right">
                <img :src="photo" class="photo-class"/>
                <br><br>
            </div>

            <div class="table-responsive">
                <table class="table table-bordered">
                    <tbody>
                        <td>
                            <h4>Surname</h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ personal.surname ? personal.surname : 'N/A' }}
                            </h4>
                        </td>
                        <td>
                            <h4>First Name</h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ personal.first_name ? personal.first_name : 'N/A' }}
                            </h4>
                        </td>
                        <td>
                            <h4>Date of Birth</h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ personal.date_of_birth | getDateFormat }}
                            </h4>
                        </td>
                    </tbody>
                    <tbody>
                        <td>
                            <h4>Gender</h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ personal.gender ? personal.gender : 'N/A' }}
                            </h4>
                        </td>
                        <td>
                            <h4>Marital Status</h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ personal.marital_status ? personal.marital_status : 'N/A' }}
                            </h4>
                        </td>
                        <td>
                            <h4>Nationality</h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ personal.has_country ? personal.has_country.name : 'N/A' }}
                            </h4>
                        </td>
                    </tbody>
                </table>
            </div>
            <div class="col-lg-12">
                <hr class="my-4" />
            </div>

            <div class="col-lg-12">
                <form
                    :action="getRootUrl+'/print-assessment-card'"
                    method="POST"
                    target="_blank"
                >
                    <input type="hidden" name="id" :value="applicant.id" />
                    <input type="hidden" name="authorization_token" :value="getAuthorizationToken" />

                    <router-link
                        to="/assessment-exam"
                        class="btn btn-danger"
                    >
                        <i class="fa fa-arrow-left"></i>
                        Back
                    </router-link>

                    <base-button
                        type="info"
                        class="right"
                        @click="editAssessmentApp"
                        v-if="(applicant.status == 'rejected') && ! batchIsSuspended"
                    >
                        <i class="fa fa-pen"></i>
                        Edit Application
                    </base-button>

                    <base-button
                        type="success"
                        class="right"
                        nativeType="submit"
                        v-if="applicant.status == 'approved'"
                    >
                        <i class="fa fa-print"></i>
                        Print Exam Card
                    </base-button>
                </form>
            </div>
        </div>

        <div class="row" v-if='! showErrorStatus && ! isLoadingBlade && ! hasValidAssessmentType'>
            <div class="col-lg-12 text-center">
                <span class="text-danger text-uppercase"> Kindly update your Assessment Exam Category to see your Dashboard</span>
                <hr>
            </div>
            <div class="col-lg-4"></div>
            <div class="col-lg-4 text-center">
                <base-input alternative=""
                    label="Assessment Exam Category"
                    required
                >
                    <select
                        class="form-control form-control-alternative"
                        v-model="record.assessment_type_id"
                    >
                        <option :value="null" disabled selected>--- Select One ---</option>
                        <option 
                            :value="assessmentType.id"
                            v-for="assessmentType in assessmentTypes"
                            :key="assessmentType.id"
                        >
                            {{ assessmentType.title + ' (' + assessmentType.description + ')' }}
                        </option>
                    </select>
                </base-input>
            </div>
            <div class="col-lg-12 text-center">
                <base-button
                    type="success"
                    @click="savePersonalData()"
                >
                    <i class="fa fa-save"></i>
                    {{ isloading ? 'Please Wait...' : 'Update Now'}}                    
                </base-button>
            </div>            
        </div>

        <IncompletePayment
            :payment="applicant.payment"
            :batch="applicant.batch"
            :category="applicant.personal_data.assessment_type_id"
            :loadAssessmentBatch="loadAssessmentBatch"
            v-if="! showErrorStatus && ! isLoadingBlade && hasIncompletePayment && applicant && applicant.personal_data && ! batchIsSuspended"
        />

        <div class="col-lg-12" v-if="isLoadingBlade">
            <div class="d-flex justify-content-center">
                <img :src="loadingBladeUrl" />
            </div>
        </div>

        <div class="col-lg-12 text-center" v-if='showErrorStatus && ! isLoadingBlade'>
            <base-alert :type="errorType">
                <!-- <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span> -->
                <span class="alert-inner--text">
                    <h2 class="text-white">{{errorMsg}}</h2>
                </span>
            </base-alert>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex'; 
    import moment from 'moment';
    import swal from 'sweetalert2';// import sweet alert
    import IncompletePayment from './IncompletePayment/IncompletePayment.vue'

    export default {
        name: 'SubmittedAssessment',
        props: ['changeTitle', 'applicant', 'editAssessmentApp', 'loadAssessmentBatch', 'batchIsSuspended'],
        components: {
            IncompletePayment
        },
        filters: {
            getDateFormat(value) {
                return moment(value).format('Do MMM, YYYY');
            }
        },
        data() {
            return {
                isLoadingBlade: false,
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                record: {
                    assessment_type_id: null
                },
                id: localStorage.getItem('applicantId'),
                isloading: false
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl', 'getRootUrl', 'getAuthorizationToken']),
            personal() {
                return this.applicant.personal_data;
            },
            rrr() {
                return this.applicant.rrr;
            },
            photo() {
                return this.applicant.photo;
            },
            hasValidAssessmentType() {// check if applicant has filled assessment type field
                return this.applicant && this.applicant.personal_data && this.applicant.personal_data.assessment_type_id ? true : false;
            },
            assessmentTypes() {// get assessment exam application types
                return this.applicant.assessment_types;
            },
            hasIncompletePayment() {// check if applicant has incomplete payment 
                let status = this.applicant && this.applicant.payment ? this.applicant.payment.status : false;
                return this.hasValidAssessmentType && status;
            }
        },
        methods: {
            ...mapActions(['saveAssessmentApplicantData']),
            loadTitle() {
                let title = 'Assessment Exam Registration Dashboard' ;
                let description = 'Stay up-to-date with your application status here...';
                return this.changeTitle(title, description, true);
            },
            savePersonalData() {
                this.isloading = true;
                this.showErrorStatus = false;
                this.preloader();// show loading
                if(this.record.assessment_type_id) {// update
                    let data = { personal_data : this.record , id : this.id };
                    this.saveAssessmentApplicantData(data).then((res) => {
                        this.loadAssessmentBatch();
                        this.isloading = false;
                        if(res.status) {// redirect when it is false
                            swal.fire("Profile Updated!", res.msg, "success");
                        } else {
                            swal.fire("Error Ocurred!", "Error occurred while updating, try again...", "warning");
                        }
                    }).
                    catch(() => {
                        this.isloading = false;
                        swal.fire("Error Ocurred!", "Network Failure!, kindly reload the page...", "warning");
                    });
                }   else {
                    this.isloading = false;
                    swal.fire("Invalid Entry!", "Kindly select your Assessment Exam Category", "warning");
                }
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        created() {
            this.loadTitle();
        }
    }
</script>

<style scoped>
    .no-padding-bottom {
        padding-bottom: 0rem !important
    }
    .photo-class {
        width: 200px;
        height: 200px;
    }
</style>

<style>
    .form-control-label {
        width: 100%;
    }
    .height-50 {
        height: 50px;
    }
    .borderLine {
        border: 1px solid #ededed;
    }
</style>