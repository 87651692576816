<template>
    <div class="pl-lg-4">
        <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center">
            <span class="mask bg-gradient-danger opacity-8"></span>
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">

                <div class="col-xl-12 order-xl-1">
                    <card shadow type="secondary">
                        <div slot="header" class="bg-white border-0">
                            <div class="row align-items-center">
                                <div class="col-8">
                                    <h4 class="mb-0">
                                        {{ record.title }}
                                    </h4>
                                </div>
                                <div class="col-4 text-right">
                                    <a href="/user-guide.pdf" target="_blank" class="btn btn-sm btn-primary">
                                        <i class="ni ni-books"></i> User Guide
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-12" v-if="isLoadingBlade">
                            <div class="d-flex justify-content-center">
                                <img :src="loadingBladeUrl" />
                            </div>
                        </div>

                        <div class="col-sm-12" v-if="! isLoadingBlade">
                            <span v-html="record.content"></span>
                        </div>

                        <div class="col-sm-12 text-center">
                            <base-alert :type="errorType" v-if='showErrorStatus && ! isLoadingBlade'>
                                <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                                <span class="alert-inner--text">
                                    {{errorMsg}}
                                </span>
                            </base-alert>
                        </div>

                    </card>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';

    export default {
        name: 'PortalGuidelines',
        data() {
            return {
                record: {},
                isLoadingBlade: true,
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl'])
        },
        methods: {
            ...mapActions(['getSiteParameter']),
            getParameter() {// get Parameter
                let name = 'portal-guidelines';
                this.getSiteParameter({'name': name}).then((res) => {
                    this.isLoadingBlade = false;
                    if(res) {
                        this.record = res;
                    } else {
                        this.showErrorMsg('No Portal Guidelines Yet!');
                    }
                }).
                catch(() => {
                    this.isLoadingBlade = false;
                    this.showErrorMsg('Network Failure!, reload page and try again...');
                });
            },
            showErrorMsg(msg) {// show empty msg
                this.showErrorStatus = true;
                this.errorType = 'warning';
                this.errorMsg = msg;
            },
        },
        created() {
            this.getParameter();// get Parameter
        },
    }
</script>