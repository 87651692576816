<template>
    <div class="pl-lg-4">

        <div class="col-lg-12" v-if="isLoadingBlade">
            <div class="d-flex justify-content-center">
                <img :src="loadingBladeUrl" />
            </div>
        </div> 

        <div v-if="! isLoadingBlade">
            <h6 class="heading-small text-muted mb-4">
                Below are your CPD Points:
                <base-button 
                    class="float-right"
                    :type="showAddRecord ? 'danger' : 'success'" 
                    size="sm"
                    @click="displayNewRecord()"
                >
                    <i class="fa fa-plus-circle" v-if="! showAddRecord"></i> 
                    <i class="fa fa-times" v-if="showAddRecord"></i>
                    {{showAddRecord ? 'Cancel' : 'Add New Record' }}
                </base-button>
            </h6>

            <hr class="my-4" v-if="showAddRecord"/>

            <div class="row align-items-center" v-if="showAddRecord">
                <div class="col-lg-12">
                    <h4 class="text-uppercase">Upload CPD Points</h4>
                    <hr class="my-4" />
                </div>
                
                <div class="col-lg-12">
                    <base-input alternative=""
                        label="File Description (Event/Programme/Workshop Description)"
                        required
                    >
                        <textarea 
                            rows="2" 
                            class="form-control form-control-alternative" 
                            placeholder="Enter File Description"
                            v-model="record.description"
                        ></textarea>
                    </base-input>
                </div>                

                <div class="col-lg-6">
                    <base-input 
                        label="The Event Date (Date of the Event/Programme/Workshop)"
                        addon-left-icon="ni ni-calendar-grid-58"
                        required
                        
                    >
                        <flat-picker 
                            slot-scope="{focus, blur}"
                            @on-open="focus"
                            @on-close="blur"
                            :config="{allowInput: true}"
                            class="form-control datepicker"
                            v-model="record.date"
                        >
                        </flat-picker>
                    </base-input>
                </div>

                <div class="col-lg-6">
                    <base-input alternative=""
                        label="License Year"
                        required
                    >
                        <select 
                            class="form-control form-control-alternative"
                            v-model="record.license_year"
                        >
                            <option value="null" disabled>--- Select One ---</option>
                            <option 
                                v-for="year in years"
                                :value="year" 
                                :key="year"
                            >
                                {{year}}
                            </option>
                        </select>
                    </base-input>
                </div>


                <div class="col-lg-12"></div>

                <div 
                    class="col-sm-6 text-center borderLine" 
                >
                    <br>
                    <input 
                        type="file" 
                        id="file" style="display: none"
                        @change="onFileSelected"
                    >
                    <h4 class="text-capitalize height-50">
                       Upload Document Obtained<br>
                       <small class="text-danger">
                           PDF Files Only (Not more than 2MB)
                        </small>
                    </h4>
                    <base-button 
                        size="sm" type="primary" 
                        v-if="! fileSelected"
                        @click="selectFile()"
                    >
                        <i class="fa fa-hand-pointer"></i> Select File
                    </base-button>

                    <base-button 
                        size="sm" type="danger" 
                        v-if="fileSelected"
                        @click="removeFile()"
                    >
                        <i class="fa fa-times"></i> Remove File
                    </base-button>            
                    <br><br>                   
                </div>

                <div class="col-lg-12">
                    <base-alert :type="errorType" dismissible v-if='showInputErrorStatus'>
                        <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                        <span class="alert-inner--text">
                            {{errorMsg}}
                        </span>
                        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </base-alert>
                </div>
                <div class="col-lg-12"></div>                
                <div class="pl-lg-4">
                    <base-button 
                        type="success" 
                        @click="saveCpdPoints()"
                    >
                        <i class="fa fa-save"></i> 
                        {{isloading ? 'Please Wait...' : 'Add Record'}}
                    </base-button>
                </div>
            </div>

            <!-- import Cpd records template here -->
            <CpdPointsData 
                v-bind:cpdRecords="cpdRecords"
                v-bind:showAddRecord="showAddRecord"
                v-bind:pagination="pagination"
                v-bind:hasPagination="hasPagination"
                v-bind:paginate="paginate"
                v-on:deleteCpdRecord="deleteCpdRecord"
            /> 

            <div class="col-sm-12 text-center">
                <base-alert :type="errorType" v-if='showErrorStatus'>
                    <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                    <span class="alert-inner--text">
                        {{errorMsg}}
                    </span>
                </base-alert>
            </div>
        </div>
        
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import flatPicker from "vue-flatpickr-component";
    import "flatpickr/dist/flatpickr.css";
    import CpdPointsData from './CpdPointsData.vue';
    import moment from 'moment';
    import swal from 'sweetalert';// import sweet alert

    export default {
        components: {
            flatPicker,
            CpdPointsData
        },
        name: 'CpdPoints',
        data() {
            return {
                cpdRecords: [],
                paginatedItems: [],
                record: {
                    description: null,
                    date: null,
                    license_year: null,
                    file: null
                },
                pagination: {
                    total: null,
                    number: 1,
                    from: null,
                    perPage: this.$store.state.user.perPage // get per page count
                },
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isloading: false,
                showAddRecord: false,
                showInputErrorStatus: false,
                isLoadingBlade: true,
                fileSelected: false
            };
        },
        computed: {
            ...mapGetters(['loadingBladeUrl']),
            hasPagination() {
                return this.paginatedItems && (this.paginatedItems.length > this.pagination.perPage)
                    ? true : false;
            },
            years() {
                let endYear = moment().format('YYYY') - 119;// start from 1900
                let startYear = parseInt(moment().format('YYYY'));// plus 50 to current year
                let years = [];
                for (let i = startYear; i > endYear; i-- ) {
                    years.push(i);
                }
                return years;
            }
        },
        methods: {
            ...mapActions(['saveUserCpdPoints', 'getUserCpdPoints', 'deleteUserCpdRecord']),
            loadCpdPoints() {
                this.getUserCpdPoints().then((res) => {// get edu records
                    this.setPages(res);
                    this.isLoadingBlade = false;
                    this.emptyCpdRecordsMsg();//show empty msg
                }).
                catch(() => {
                    this.showErrorStatus = true;
                    this.isLoadingBlade = false;
                    this.errorMsg = "Network Failure!", "Kindly reload the page...";
                    this.errorType = 'danger';
                });
            },
            setPages(res) {// set paginated function
                this.cpdRecords = [];
                this.paginatedItems = res;               
                this.pagination.total = this.paginatedItems.length; // get items total
                let perPage = this.pagination.perPage; // get per page count                
                if(this.paginatedItems.length > perPage) {// paginate
                    let numberOfPages = Math.ceil(this.paginatedItems.length / perPage);
                    for (let index = 1; index <= numberOfPages; index++) {
                        this.cpdRecords.push(this.paginatedItems[index]);
                    }
                } else {
                    this.cpdRecords = this.paginatedItems;
                }
            },
            paginate() {// paginate function
                this.eduRecords = [];
                this.pagination.from = null;
                let page = this.pagination.number;
                let perPage = this.pagination.perPage;
                let from = (page * perPage) - perPage;
                let to = (page * perPage);
                this.pagination.from = from;//get first item number
                return this.eduRecords = this.paginatedItems.slice(from, to);
            },
            emptyCpdRecordsMsg() {
                return this.cpdRecords && this.cpdRecords.length == 0 && (!this.showAddRecord)
                    ? this.showEmptyMsg() : false;
            },
            displayNewRecord() {// show new record
                this.emptyRecords();// empty edu records
                if(this.cpdRecords && this.cpdRecords.length == 0) {// hide empty msg
                    this.showEmptyMsg();
                    this.showErrorStatus = this.showAddRecord;
                } 
                return this.showAddRecord = ! this.showAddRecord;
            },
            emptyRecords() {
                this.record.description = null;
                this.record.date = null;
                this.record.file = null;
                this.record.license_year = null;
                this.fileSelected = false;
            },
            showEmptyMsg() {// show empty msg
                this.showErrorStatus = true; 
                this.errorType = 'warning';
                this.errorMsg = 'No CPD Points Yet!, kindly add one';
            },
            saveCpdPoints() {// save new edu records
                this.isloading = true;
                let data = this.validatedInputs();// validate inputs         
                if(data) {// save edu records
                    data.id = this.$store.state.user.userId;// get user id
                    this.saveUserCpdPoints(data).then((res) => {
                        this.isloading = false;
                        if(res.status) {
                            this.loadCpdPoints();// load CPD points
                            this.showAddRecord = false;
                            this.showErrorStatus = false;
                            swal("CPD Point Added!", "CPD Point has been added successfully", "success");
                        } else {
                            this.showErrorMsg('An error occured while saving, try again');
                            this.emptyRecords();
                        }
                    }).
                    catch(() => {
                        this.isloading = false;
                        this.emptyRecords();
                        this.showErrorMsg('An error occured while saving, try again');
                    });
                } else {
                    this.isloading = false;
                }
            },
            showErrorMsg(msg) {
                this.showInputErrorStatus = true
                this.errorType = 'warning'
                this.errorMsg = msg;
                var self = this;
                setTimeout(function() { self.showInputErrorStatus = false; }, 5000);
            },
            validatedInputs() {
                let data = this.record;                
                data.file == null ? this.showErrorMsg('File was not seletected') : true;
                data.license_year == null ? this.showInuptError('License Year') : true;
                data.date == null ? this.showInuptError('Date') : true;
                data.description == null ? this.showInuptError('File Description') : true;                
                return this.isEmpty(data) ? data : false;
            },
            isEmpty(obj) {// check for empty field
                return ! Object.keys(obj).some(k => !obj[k]);
            },
            showInuptError(name) {
                this.isloading = false;
                this.showInputErrorStatus = true
                this.errorType = 'warning'
                this.errorMsg = 'Invalid ' + name + ' has been entered';
                var self = this;
                setTimeout(function() { self.showInputErrorStatus = false; }, 5000);
            },
            selectFile() {// eslint-disable-next-line
                $('#file').val('');
                this.fileSelected = false;// eslint-disable-next-line
                $('#file').click();
            },
            removeFile() {// eslint-disable-next-line
                $('#file').val('');
                this.fileSelected = false;
            },
            onFileSelected(event) {
                let status =  this.validateFile(event.target.files[0]);
                if(status) {// convert file to binary
                    var fileReader = new FileReader(); 
                    fileReader.readAsDataURL(event.target.files[0]);
                    fileReader.onload = (event) => {// convert file to binary
                        this.record.file = event.target.result;
                    }
                    this.fileSelected = true;
                }
                return status;
            },
            validateFile(file) {// check for valid file
                if(!file) {// update seleted file name
                    this.uploadedFile.name = null;
                    this.selectedFileName = null;
                }
                var fileExt = file.type.split('/').pop();
                let fileSize = file.size;
                if(fileExt == 'pdf' && fileSize <= 2000000) {// for valid file and size
                    return true;
                } else if(fileExt != 'pdf') {// invalid file
                    swal("Invalid File!", "Only PDF files are accepted", "warning");
                    return false;
                } else if(fileSize > 2000000) {// big file
                    swal("File is too large!", "File should be less than 2MB", "warning");
                    return false;
                }
            },
            deleteCpdRecord(id) {// delete cpd points 
                this.deleteUserCpdRecord({'id' : id}).then((res) => {
                    if(res.status) {
                        this.loadCpdPoints(); // load Cpd Points
                    } else {
                        swal("Error Occurred!", "An error occurred while deleting, try again", "warning");
                        this.loadCpdPoints(); // load Cpd Points
                    }
                }).
                catch(() => {
                    swal("Network Failure", "Reload page and try again...", "warning");
                    this.loadCpdPoints(); // load Cpd Points
                });
            },
        },
        created() {
            this.loadCpdPoints();// load Cpd Points
        },
    }
</script>

<style>
    .form-control-label {
        width: 100%;
    }
</style>