<template>
    <div>
        <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center">
            <span class="mask bg-gradient-danger opacity-8"></span>
        </base-header>


        <div class="container-fluid mt--7">
            <div class="row">

                <div class="col-xl-12 order-xl-1">
                    <card shadow type="secondary">

                        <div class="col-lg-12">
                            <div class="row">
                                <div class="col-lg-9">
                                    <h4 class="heading-small text-muted mb-4">
                                        Below are your insurance indemnity records:
                                        <br>
                                        <span class="text-danger">
                                            Confirm if your transaction actually went through before attempting to make another payment, 
                                            you can click on <em class="text-primary">Re-Query button</em> below
                                        </span>
                                    </h4>
                                </div>
                                <div class="col-lg-3">
                                    <base-button
                                        class="float-right"
                                        :type="showAddRecord ? 'danger' : 'success'"
                                        size="lg"
                                        @click="displayNewRecord()"
                                    >
                                        <i class="fa fa-plus-circle" v-if="! showAddRecord"></i>
                                        <i class="fa fa-times" v-if="showAddRecord"></i>
                                        {{showAddRecord ? 'Cancel Purchase' : 'Purchase Insurance' }}
                                    </base-button>
                                </div>
                            </div>

                            <hr class="my-4" v-if="showAddRecord"/>

                            <div class="row align-items-center" v-if="showAddRecord">
                                <br>
                                <div class="col-lg-12 text-danger">
                                    Note if you are purchasing this indemnity for provisional registration, please select
                                    YES, while others should select NO. Note select the right option so that the right amount
                                    to be paid will be available for you.
                                    <hr>
                                </div>

                                <div class="col-lg-6">
                                    <base-input alternative=""
                                        label="Are you purchasing this insurance
                                                indemnity for provisional registration?"
                                        required
                                    >
                                        <select
                                            class="form-control form-control-alternative"
                                            v-model="records.provisional"
                                        >
                                            <option
                                                value="null"
                                                disabled
                                            >
                                                --- Select One ---
                                            </option>
                                            <option value="yes">Yes</option>
                                            <option value="no">No</option>
                                        </select>
                                    </base-input>
                                </div>

                                <div class="col-lg-6">
                                    <base-input alternative=""
                                        label="Beneficiary of the Life Insurance Benefit (Next of Kin)"
                                        placeholder="Enter Beneficiary of the Life Insurance Benefit (Next of Kin)"
                                        input-classes="form-control-alternative"
                                        v-model="records.beneficiary_name"
                                        required
                                    />
                                </div>
                                <div class="col-lg-6">
                                    <base-input alternative=""
                                        label="Contact Telephone of Beneficiary (Next of Kin)"
                                        input-classes="form-control-alternative"
                                        required
                                    >
                                    <!-- v-model="profile.phone"  -->
                                        <VuePhoneNumberInput
                                            v-model="tel"
                                            @update="updatePhoneNumber"
                                            default-country-code="NG"
                                            :no-example="false"
                                            :clearable="true"
                                        />
                                    </base-input>
                                </div>
                                <div class="col-lg-6">
                                    <base-input alternative=""
                                        label="Relationship (Next of Kin)"
                                        placeholder="Enter Relationship (Next of Kin)"
                                        input-classes="form-control-alternative"
                                        v-model="records.beneficiary_relationship"
                                        required
                                    />
                                </div>

                                <div class="col-lg-12">
                                    <base-alert :type="errorType" dismissible v-if='showInputErrorStatus'>
                                        <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                                        <span class="alert-inner--text">
                                            {{errorMsg}}
                                        </span>
                                        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </base-alert>
                                </div>

                                <div class="col-lg-12"></div>
                                <div class="pl-lg-4">
                                    <base-button
                                        type="success"
                                        @click="purchaseInsurance()"
                                    >
                                        <i class="fa fa-save"></i>
                                        {{isloading ? 'Please Wait...' : 'Purchase Now'}}
                                    </base-button>
                                </div>
                            </div>

                            <!-- import edu records details template here -->
                            <IndemnityData
                                v-bind:insuranceRecords="insuranceRecords"
                                v-bind:showAddRecord="showAddRecord"
                                v-bind:isLoadingBlade="isLoadingBlade"
                                v-bind:pagination="pagination"
                                v-bind:hasPagination="hasPagination"
                                v-bind:isloading="isloading"
                                v-bind:selectedRowNo="selectedRowNo"
                                v-bind:paginate="paginate"
                                v-on:reQueryTransaction="reQueryTransaction"
                                v-on:makeRepayment="makeRepayment"
                            />
                        </div>
                        <div class="col-lg-12 text-center" v-if='showErrorStatus'>
                            <br>
                            <base-alert :type="errorType">
                                <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                                <span class="alert-inner--text">
                                    {{errorMsg}}
                                </span>
                            </base-alert>
                        </div>
                    </card>                    
                </div>
            </div>
        </div>
    </div>


</template>

<script>
    import { mapActions } from 'vuex';
    import IndemnityData from './IndemnityData.vue';
    import VuePhoneNumberInput from 'vue-phone-number-input';
    import 'vue-phone-number-input/dist/vue-phone-number-input.css';
    import swal from 'sweetalert';// import sweet alert

    export default {
        components: {
            IndemnityData,
            VuePhoneNumberInput
        },
        name: 'InsuranceIndemnity',
        data() {
            return {
                insuranceRecords: [],
                paginatedItems: [],
                records: {
                    beneficiary_name: null,
                    beneficiary_phone: null,
                    beneficiary_relationship: null,
                    provisional: null
                },
                tel: null,
                pagination: {
                    total: null,
                    from: null,
                    number: 1,
                    perPage: this.$store.state.user.perPage // get per page count
                },
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isloading: false,
                selectedRowNo: null,
                showAddRecord: false,
                showInputErrorStatus: false,
                isLoadingBlade: true
            };
        },
        computed: {
            hasPagination() {
                return this.paginatedItems && (this.paginatedItems.length > this.pagination.perPage)
                    ? true : false;
            }
        },
        methods: {
            ...mapActions(['getUserInsuranceRecords', 'getBasicProfile', 'reQueryInsurance',
                'addUserInsurance', 'deleteUserEduRecord']),
            updatePhoneNumber(event) {
                if(event && event.isValid && event.e164) {
                    this.records.beneficiary_phone = event.formattedNumber;
                }
            },
            emptyInsuranceRecordsMsg() {
                return this.insuranceRecords && this.insuranceRecords.length == 0 && (!this.showAddRecord)
                    ? this.showEmptyMsg() : false;
            },
            changeCurrentStatus(value) {// change Current Status switch
                this.records.provisional = value;
            },
            showEmptyMsg() {// show empty msg
                this.showErrorStatus = true;
                this.errorType = 'warning';
                this.errorMsg = 'No Insurance Records Yet!, kindly purchase one';
            },
            loadInsuranceRecords() {
                this.getBasicProfile().then((res) => {// get user Id
                    const userId = res.id;
                    this.getUserInsuranceRecords({'id' : userId}).then((res) => {// get insurance records
                        this.setPages(res);
                        this.isLoadingBlade = false;
                        this.emptyInsuranceRecordsMsg();//show empty msg
                    }).
                    catch(() => {
                        this.showErrorStatus = true;
                        this.isLoadingBlade = false;
                        this.errorMsg = "Network Failure!", "Kindly reload the page...";
                        this.errorType = 'danger';
                    });
                }).
                catch(() => {
                    this.showErrorStatus = true;
                    this.isLoadingBlade = false;
                    this.errorMsg = "Network Failure!", "Kindly reload the page...";
                    this.errorType = 'danger';
                });
            },
            setPages(res) {// set paginated function
                this.insuranceRecords = [];
                this.paginatedItems = res;
                this.pagination.total = this.paginatedItems.length; // get items total
                let perPage = this.pagination.perPage; // get per page count
                if(this.paginatedItems.length > perPage) {// paginate
                    let numberOfPages = Math.ceil(this.paginatedItems.length / perPage);
                    for (let index = 1; index <= numberOfPages; index++) {
                        this.insuranceRecords.push(this.paginatedItems[index]);
                    }
                } else {
                    this.insuranceRecords = this.paginatedItems;
                }
            },
            paginate() {// paginate function
                this.insuranceRecords = [];
                this.pagination.from = null;
                let page = this.pagination.number;
                let perPage = this.pagination.perPage;
                let from = (page * perPage) - perPage;
                let to = (page * perPage);
                this.pagination.from = from;//get first item number
                return this.insuranceRecords = this.paginatedItems.slice(from, to);
            },
            emptyInsuranceRecords() {
                this.records.beneficiary_name = null;
                this.records.beneficiary_phone = null;
                this.records.beneficiary_relationship = null;
            },
            displayNewRecord() {// show new record
                this.emptyInsuranceRecords();// empty edu records
                if(this.insuranceRecords && this.insuranceRecords.length == 0) {// hide empty msg
                    this.showEmptyMsg();
                    this.showErrorStatus = this.showAddRecord;
                }
                return this.showAddRecord = ! this.showAddRecord;
            },
            purchaseInsurance() {// save new edu records
                this.isloading = true;
                let data = this.validatedInputs();// validate inputs
                if(data) {// save edu records
                    data.user_data_id = this.$store.state.user.basicProfile.id;// get user id
                    data.trans_no = this.genRandomString();// generate trans no
                    this.addUserInsurance(data).then((res) => {// add insurance
                        if(res.status) {// redirect url
                            return window.location.href='/purchase-insurance/'+res.record.trans_no;
                        } else {
                            this.isloading = false;
                            this.loadInsuranceRecords();
                            this.showErrorMsg();
                            this.emptyInsuranceRecords();
                        }
                    }).
                    catch(() => {
                        this.isloading = false;
                        this.loadInsuranceRecords();
                        this.emptyInsuranceRecords();
                        this.showErrorMsg();
                    });
                } else {
                    this.isloading = false;
                    this.loadInsuranceRecords();
                }
            },
            genRandomString() {
                var length = 12;
                var text = '';
                var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
                for (var i=0; i < length; i++) {
                    text += possible.charAt(Math.floor(Math.random() * possible.length));
                }
                return text;
            },
            showErrorMsg() {
                this.showInputErrorStatus = true
                this.errorType = 'warning'
                this.errorMsg = 'An error occurred, kindly reload page and try again';
                var self = this;
                setTimeout(function() { self.showInputErrorStatus = false; }, 5000);
            },
            validatedInputs() {
                let data = this.records;
                let insuranceRecord = { beneficiary_name: null, beneficiary_phone: null,
                    beneficiary_relationship: null, provisional: null };
                insuranceRecord.beneficiary_relationship  = data.beneficiary_relationship == null ?
                    this.showInuptError('Beneficiary Relationship') : data.beneficiary_relationship;
                insuranceRecord.beneficiary_phone  = data.beneficiary_phone == null ?
                    this.showInuptError('Beneficiary Phone Number') : data.beneficiary_phone;
                insuranceRecord.beneficiary_name = data.beneficiary_name == null ?
                    this.showInuptError('Beneficiary Name') : data.beneficiary_name;
                insuranceRecord.provisional = data.provisional == null ?
                    this.showInuptError('Provisional Reg Type') : data.provisional;
                return this.isEmpty(insuranceRecord) ? insuranceRecord : false;
            },
            isEmpty(obj) {// check for empty field
                return ! Object.keys(obj).some(k => !obj[k]);
            },
            showInuptError(name) {
                this.isloading = false;
                this.showInputErrorStatus = true
                this.errorType = 'warning'
                this.errorMsg = 'Invalid ' + name + ' has been entered';
                var self = this;
                setTimeout(function() { self.showInputErrorStatus = false; }, 5000);
            },
            reQueryTransaction(number) {// re-query payment function
                this.isloading = true;
                this.selectedRowNo = number;
                this.reQueryInsurance(number).then((res) => {// add insurance
                    let msgTitle = res.status ? 'Payment Successful!' : 'Payment Failed!';
                    let type = res.status ? 'success' : 'warning';
                    swal(msgTitle, res.msg , type);
                    this.selectedRowNo = null;
                    this.loadInsuranceRecords();
                }).
                catch(() => {
                    this.isloading = false;
                    let msgBody = 'An error occurred while verifying, try again';
                    let msgTitle = 'Error Occurred!';
                    let type = 'warning';
                    swal(msgTitle, msgBody, type);
                    this.selectedRowNo = null;
                    this.loadInsuranceRecords();
                });
            },
            makeRepayment(number) {// make re-payment for fail transaction
                return window.location.href='/purchase-insurance/'+number;
            }
        },
        created() {
            this.loadInsuranceRecords();// load educational records
        },
    }
</script>

<style scoped>
    .float-right {
        float: right;
    }
</style>