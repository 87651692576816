<template>
    <div>
        <div v-if="isTempReg">
            <hr class="my-4">
            <h2 class="text-danger text-center">
                Note: If you want to renew your Temporary Practicing license, click on 
                <span class="text-primary">Renew License</span> button,
                else for New Temporary Registration kindly click on the 
                <span class="text-success">Apply Now</span> button below
            </h2>
        </div>

        <div v-if="! hideApplyButton">
            <hr class="my-4">
            <h2
                class="mb-0 text-uppercase text-center"
                v-if="application && application.has_payment"
            >
                Make New Payment for
                <em class="text-danger">
                    {{ application.name }}
                </em>
            </h2>
        </div>

        <div class="container ct-example-row" v-if="application && application.has_payment && ! hideApplyButton">
            <div class="row">
                <div class="col-lg-12">
                    <ul class="list-unstyled">
                        <li>
                            <hr class="my-4">
                            <h3 class="text-center">
                                Application Name: &nbsp;
                                <em class="text-capitalize font-weight-light">
                                    {{ application.name }}
                                </em>
                            </h3>
                            <br>
                            <h3 class="text-center">
                                Total Amount: &nbsp;
                                <em class="text-capitalize font-weight-light">
                                    {{ totalAmount | formatPrice }}
                                </em>
                            </h3>

                            <div v-if="application && application.has_payment && application.has_payment.has_breakdowns
                                        && application.has_payment.has_breakdowns.length > 0"
                            >
                                <hr class="my-4">
                                <h1 class="text-center">
                                    Payment Breakdowns
                                </h1>
                                <hr class="my-4">
                                <h3
                                    v-for="breakdown in application.has_payment.has_breakdowns"
                                    :key="breakdown.id"
                                >
                                    {{ breakdown.name }}: &nbsp;
                                    <em class="text-capitalize font-weight-light right">
                                        {{ breakdown.amount | formatPrice }}
                                        </em>
                                    <br>
                                </h3>

                                <h3
                                    v-if="getCountryPostageFee"
                                >
                                    Postage Fee for
                                    <em class="text-primary">{{ getCountryPostageFee.name }}</em>: &nbsp;
                                    <em class="text-capitalize font-weight-light right">
                                        {{ getCountryPostageFee.postage_fee | formatPrice }}
                                        </em>
                                    <br>
                                </h3>

                                <hr class="my-4">
                                <h3>
                                    Total: &nbsp;
                                    <em class="text-capitalize font-weight-light right">
                                        {{ totalAmount | formatPrice }}
                                    </em>
                                </h3>
                            </div>
                        </li>
                    </ul>
                </div>

                <div class="col-lg-12 text-center" v-if='showErrorStatus'>
                    <base-alert :type="errorType">
                        <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                        <span class="alert-inner--text">
                            {{ errorMsg }}
                        </span>
                    </base-alert>
                </div>

                <div class="col-lg-12" v-if="! hideApplyButton">
                    <hr class="my-4">

                    <form
                        :action="remitaPaymentUrl" method="POST"
                        v-if="showPaymentForm"
                    >
                        <input
                            type="hidden"
                            v-for="(item, index) in paymentRecords"
                            :key="index"
                            :name="item.title"
                            :value="item.value"
                        />


                        <base-button
                            type="success"
                            nativeType="submit"
                        >
                            <i class="fa fa-credit-card"></i>
                            {{ isloading ? 'Please Wait...' : 'Pay Now'}}
                        </base-button>

                        <div class="right">
                            <base-button
                                type="primary"
                                @click="$emit('addPayment', orderId)"
                            >
                                <i class="fa fa-plus"></i>
                                Pay Via RRR
                            </base-button>
                        </div>

                    </form>
                    <base-button
                        type="success"
                        @click="createApplication()"
                        v-if="! showPaymentForm"
                    >
                        <i class="fa fa-reply"></i>
                        {{ isloading ? 'Please Wait...' : 'Apply Now'}}
                    </base-button>

                    <router-link
                        :to="getTempRegUrl"
                        class="btn btn-lg btn-primary right"
                    >
                        <i class="fa fa-history"></i>
                        Renew License
                    </router-link>
                </div>
            </div>
        </div>

    </div>



</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import moment from 'moment';
    import swal from 'sweetalert2';// import sweet alert

    export default {
        name: 'RegPaymentDetails',
        props: ['application', 'user', 'serviceType', 'hasPreviousApplication', 'previousApplications',
            'applicationType', 'loadApplication', 'displayNewPayment', 'changeAppId', 'isTempReg', 'subTemReg'],
        data() {
            return {
                isLoadingBlade: true,
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isloading: false,
                showPaymentForm: false,
                orderId: null,
                id: null,
                hideApplyButton: false
            }
        },
        filters: {
            formatPrice(value) {
                if(value) {
                    let val = (value/1).toFixed(2).replace(',', '.');
                    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")+ ' NGN';
                } else {
                    return 'N/A';
                }
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl', 'remitaPaymentUrl', 'remitaMerchantId', 'locations',
                'remitaPaymentCallbackUrl', 'remitaApiKey']),
            gethash() {
                var sha512 = require('js-sha512');
                let text = this.remitaMerchantId+this.serviceType.remita_code+this.orderId
                    +this.totalAmount+this.remitaPaymentCallbackUrl+this.remitaApiKey;
                return sha512(text);
            },
            totalAmount() {// get total amount
                let amount = parseInt(this.application.has_payment.amount);
                return this.getPostageFee() ? (parseInt(this.getPostageFee().postage_fee) + amount) : amount;
            },
            paymentRecords() {// get payment records
                let fullName = this.user.basic_profile.first_name+' '+this.user.basic_profile.last_name;
                return [
                    {'title' : 'merchantId', 'value' : this.remitaMerchantId},
                    {'title' : 'serviceTypeId', 'value' : this.serviceType.remita_code},
                    {'title' : 'amt', 'value' : this.totalAmount},
                    {'title' : 'responseurl', 'value' : this.remitaPaymentCallbackUrl},
                    {'title' : 'hash', 'value' : this.gethash},
                    {'title' : 'payerName', 'value' : fullName},
                    {'title' : 'paymenttype', 'value' : ''},
                    {'title' : 'payerEmail', 'value' : this.user.email},
                    {'title' : 'payerPhone', 'value' : this.user.basic_profile.phone},
                    {'title' : 'orderId', 'value' : this.orderId},
                ]
            },
            getCountryPostageFee() {// get postage fee for full without provisional internship
                if(this.previousApplications && this.previousApplications.length > 0) {
                    let app = this.previousApplications[0];
                    if(app.postage_fee) {
                        return app.postage_fee;
                    } else {
                        return false;
                    }
                } else {
                    return false;
                }
            },
            getTempRegUrl() {// get exact temp renewal url
                let code = this.subTemReg ? this.subTemReg.code : false;
                return code ? '/renew-temp/'+ code + '-renewal' : '/';
            }
        },
        methods: {
            ...mapActions(['createNewUserApplication', 'saveNewApplicationTransaction', 'updateTransNo', 'internshipStatus']),
            getPostageFee() {// get postage fee for full without provisional internship
                if(this.previousApplications && this.previousApplications.length > 0) {
                    let app = this.previousApplications[0];
                    if(app.postage_fee) {
                        return app.postage_fee;
                    } else {
                        return false;
                    }
                } else {
                    return false;
                }
            },
            loadAppDetails() {
                if(this.application && this.application.code && ((this.application.code == 'full-reg-upgrade') 
                        || (this.application.code == 'full-reg'))) {// check if doctor is upgrading to full, has completed internship
                    this.preloader();// show loading
                    this.hideApplyButton = true;
                    this.internshipStatus().then((res) => {// check internship status
                        if(res.status) {// proceed to get paymentdetails
                            swal.close();
                            this.hideApplyButton = false;
                            return this.loadPreviousDetails();// proceed
                        } else {// show the error message
                            this.hideApplyButton = true;
                            swal.fire('Network Failure!', res.msg, 'warning');
                        }
                    }).
                    catch(() => {
                        this.isLoadingBlade = false;
                        this.hideApplyButton = false;
                        swal.fire('Network Failure!', "Network Failure!, kindly reload the page...", 'error');
                    });
                } else {
                    return this.loadPreviousDetails();
                }
            },
            loadPreviousDetails() {
                let application = this.previousApplications && this.previousApplications.length > 0
                        ? this.previousApplications[0] : null;
                this.orderId = application ? application.trans_no : null;
                this.id = application ? application.id : null;
            },
            showMsg(type, msg) {
                this.showErrorStatus = true;
                this.errorType = type;
                this.errorMsg = msg;
                var self = this;
                setTimeout(function() { self.showErrorStatus = false; }, 5000);
            },
            createApplication() {// create application
                this.preloader();// show loading
                if(this.orderId) {
                    this.updateTransNo({'id' : this.id}).then((res) => {//update trans no
                        this.changeAppId(res.application.id, res.application_type, res.service_type);// apply changes to application type
                        this.displayNewPayment(true);// show new payment blade
                        this.orderId = res.trans_no;
                        this.showPaymentForm = true;
                        let message = 'Kindly click on Pay Now to make payment online';
                        swal.fire('Application Updated!', message, 'success');
                    }).
                    catch(() => {
                        let message = 'Network Failure!, kindly reload the page...';
                        swal.fire('Network Failure!', message, 'warning');
                    });
                } else {
                     return this.createNewApplication(); // create new application
                }
            },
            createNewApplication() {
                let data = {};
                data.type = this.applicationType;// get exact application type
                data.user_data_id = this.$store.state.user.userDataId;// get user data id
                data.application_type_id = this.application.id;// apllication type id
                data.trans_no = this.genRandomString();// generate trans no
                data.application_date = this.getCurrentDate();// get current date
                this.createNewUserApplication(data).then((res) => {// create application
                    let app = res.application ? res.application : res.license;// get valid application
                    this.changeAppId(app.id, res.type, res.service_type);// apply changes to application type
                    if(res.status) {// update transaction
                        this.showPaymentForm = true;
                        this.orderId = data.trans_no;
                        let message = 'Kindly click on Pay Now to make payment online';
                        swal.fire('Application Updated!', message, 'success');
                    }
                }).
                catch(() => {
                    let message = 'While creating application, kindly reload and try again';
                    swal.fire('Error ocurred', message, 'warning');
                    return false;
                });
            },
            getCurrentDate() {// get current date time
                let date = new Date().toJSON().slice(0,10).replace(/-/g,'-');
                return moment(date).format('YYYY-MM-DD');
            },
            genRandomString() {
                var length = 12;
                var text = '';
                var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
                for (var i=0; i < length; i++) {
                    text += possible.charAt(Math.floor(Math.random() * possible.length));
                }
                return text;
            },
            updateTransaction(res, record) {// update transaction
                let data = {};
                data.application = record.application;
                data.license = record.license;
                data.amount = this.application.has_payment.amount;
                data.status = 'PAID';
                data.date_generated = this.getCurrentDateTime();
                data.date_paid = this.getCurrentDateTime();
                data.payment_ref = res.paymentReference;
                data.response_message = 'Successful';
                data.transaction_id = res.transactionId;
                this.saveNewApplicationTransaction(data).then((res) => {// save transaction
                    if(res.status) {
                        let msg = 'Payment was successfully, Kindly wait for approval by MDCN';
                        swal.fire('Payment successfully', msg, 'success');
                    } else {
                        let msg = 'While updating transacton, kindly click on the Re-query button';
                        swal.fire('Error ocurred', msg, 'warning');
                    }
                }).
                catch(() => {
                    let message = 'While updating transacton, kindly click on the Re-query button';
                    swal.fire('Error ocurred', message, 'warning');
                });
            },
            getCurrentDateTime() {// get current date time
                let date = new Date().toJSON().slice(0,10).replace(/-/g,'-');
                return moment(date).format('YYYY-M-D h:mm:ss');
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        created() {
            this.loadAppDetails();// get previous application details
        },
    }
</script>

<style scoped>
.right {
    float: right;
}
</style>