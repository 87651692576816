<template>
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <h3 class="mb-0 text-uppercase">{{ title }}</h3>
                <br>
                <span class="text-danger">
                    Note that you have to provide correct information below.
                </span>
                <hr>
            </div>

            <div class="col-lg-12">
                <!-- Import Bio Data template here -->
                <BioData
                    v-if="showBioData"
                    v-bind:displayEduRecords="displayEduRecords"
                    :locations="locations"
                />

                <!-- Import Edu Records template here -->
                <EduRecords
                    v-if="! showBioData && showEduRecords"
                    :displayBioData="displayBioData"
                    :displaySpecData="displaySpecData"
                    :resend="resend"
                />

                <!-- Import Specialization template here -->
                <Specialization
                    v-if="! showBioData && ! showEduRecords && showSpecData"
                    v-bind:displayEduRecords="displayEduRecords"
                    v-bind:displayAppointments="displayAppointments"
                />

                <!-- Import Appointments template here -->
                <Appointments
                    v-if="! showBioData && ! showEduRecords && ! showSpecData
                        && showAppointments"
                    :displaySpecData="displaySpecData"
                    :displayPhoto="displayPhoto"
                    :resend="resend"
                />

                <!-- Import Profile Photo template here -->
                <ProfilePhoto
                    v-if="! showBioData && ! showEduRecords && ! showSpecData
                        && ! showAppointments && showPhoto"
                    v-bind:displayAppointments="displayAppointments"
                    v-bind:displayDocuments="displayDocuments"
                />

                <!-- Import Documents template here -->
                <Documents
                    v-if="! showBioData && ! showEduRecords && ! showSpecData
                        && ! showAppointments && ! showPhoto && showDocuments"
                    v-bind:displayPhoto="displayPhoto"
                    v-bind:displayInsurance="displayInsurance"
                />

                <!-- Import Insurance template here -->
                <Insurance
                    v-if="! showBioData && ! showEduRecords && ! showSpecData
                        && ! showAppointments && ! showPhoto && ! showDocuments
                        && showInsurance"
                    v-bind:displayDocuments="displayDocuments"
                    v-bind:displayAddQual="displayAddQual"
                />

                <!-- Import Add Qualification template here -->
                <AddQualification
                    v-if="! showBioData && ! showEduRecords && ! showSpecData
                        && ! showAppointments && ! showPhoto && ! showDocuments
                        && ! showInsurance && showAddQual"
                    v-bind:displayInsurance="displayInsurance"
                    v-bind:displayAddRecords="displayAddRecords"
                    :type="'rejected-temp'"
                    :locations="locations" 
                />

                <!-- Import Additional Records template here -->
                <AdditionalRecords
                    v-if="! showBioData && ! showEduRecords && ! showSpecData
                        && ! showAppointments && ! showPhoto && ! showDocuments
                        && ! showInsurance && ! showAddQual && showAddRecords"
                    :displayAddQual="displayAddQual"
                    :displayNMALevy="displayReg"
                    :profileLock="false"
                />

                <!-- Import Registration template here -->
                <ReSubmitApp
                    v-if="! showBioData && ! showEduRecords && ! showSpecData
                        && ! showAppointments && ! showPhoto && ! showDocuments
                        && ! showInsurance && ! showAddQual && ! showAddRecords
                        && showReg"
                    v-bind:displayNMALevy="displayAddRecords"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import BioData from '../../NewTempAccount/Others/BioData.vue';
    import EduRecords from '../../NewTempAccount/Others/EduRecords';
    import Specialization from '../../NewTempAccount/Others/Specialization';
    import Appointments from '../../NewTempAccount/Others/Appointments';
    import ProfilePhoto from '../../NewAccount/ProfilePhoto.vue';
    import Documents from '../../NewTempAccount/Others/Documents';
    import Insurance from '../../NewTempAccount/Others/Insurance';
    import ReSubmitApp from './ReSubmitApp.vue';
    import AddQualification from '../../NewTempAccount/Others/AddQualification';
    import AdditionalRecords from '../../NewAccount/AdditionalRecords/AdditionalRecords';
    import { mapActions, mapGetters } from 'vuex';

    export default {
        components: {
            BioData,
            EduRecords,
            Specialization,
            Appointments,
            ProfilePhoto,
            Documents,
            Insurance,
            ReSubmitApp,
            AddQualification,
            AdditionalRecords,
        },
        name: 'PendingTempReg',
        data() {
            return {
                resend: true,
                showBioData: true,
                showEduRecords: false,
                showSpecData: false,
                showAppointments: false,
                showPhoto: false,
                showDocuments: false,
                showInsurance: false,
                showReg: false,
                showAddQual: false,
                showAddRecords: false,
                title: 'Update Bio information'
            }
        },
        computed: {
            ...mapGetters(['user', 'locations'])
        },
        methods: {
            ...mapActions(['getLocations']),
            displayBioData(value) {
                this.title = 'Qpdate Bio information';
                this.showBioData = value;
            },
            displayEduRecords(value) {
                this.title = 'Update Educational Records';
                this.showBioData = ! value;
                this.showEduRecords = value;
            },
            displaySpecData(value) {
                this.title = 'Update Specialization Information';
                this.showBioData = ! value;
                this.showEduRecords = ! value;
                this.showSpecData = value;
            },
            displayAppointments(value) {
                this.title = 'Update Appointment Records';
                this.showBioData = ! value;
                this.showEduRecords = ! value;
                this.showSpecData = ! value;
                this.showAppointments = value;
            },
            displayPhoto(value) {
                this.title = 'Upload Profile Photo';
                this.showBioData = ! value;
                this.showEduRecords = ! value;
                this.showSpecData = ! value;
                this.showAppointments = ! value;
                this.showPhoto = value;
            },
            displayDocuments(value) {
                this.title = 'Upload Documents';
                this.showBioData = ! value;
                this.showEduRecords = ! value;
                this.showSpecData = ! value;
                this.showAppointments = ! value;
                this.showPhoto = ! value;
                this.showDocuments = value;
            },
            displayInsurance(value) {
                this.title = 'Purchase insurance indemnity';
                this.showBioData = ! value;
                this.showEduRecords = ! value;
                this.showSpecData = ! value;
                this.showAppointments = ! value;
                this.showPhoto = ! value;
                this.showDocuments = ! value;
                this.showInsurance = value;
            },
            displayAddQual(value) {
                this.title = 'Update Additional Qualification';
                this.showBioData = ! value;
                this.showEduRecords = ! value;
                this.showSpecData = ! value;
                this.showAppointments = ! value;
                this.showPhoto = ! value;
                this.showDocuments = ! value;
                this.showInsurance = ! value;
                this.showAddQual = value;
            },
            displayAddRecords(value) {
                this.title = 'Additional Records';
                this.showBioData = ! value;
                this.showEduRecords = ! value;
                this.showSpecData = ! value;
                this.showAppointments = ! value;
                this.showPhoto = ! value;
                this.showDocuments = ! value;
                this.showInsurance = ! value;
                this.showAddQual = ! value;
                this.showAddRecords = value;
            },
            displayReg(value) {
                this.title = 'Re-send Application';
                this.showBioData = ! value;
                this.showEduRecords = ! value;
                this.showSpecData = ! value;
                this.showAppointments = ! value;
                this.showPhoto = ! value;
                this.showDocuments = ! value;
                this.showInsurance = ! value; 
                this.showInternship = ! value;
                this.showAddQual = ! value;
                this.showAddRecords = ! value;
                this.showReg = value;
            },
        },
        created() {
            this.getLocations();
        }
    }
</script>