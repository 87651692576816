<template>
    <div>
        <div class="col-lg-12" v-if="isLoadingBlade">
            <div class="d-flex justify-content-center">
                <img :src="loadingBladeUrl" />
            </div>
        </div>

        <div v-if="! isLoadingBlade">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6">
                        <h6 class="heading-small text-muted mb-4">
                            Below is your Additional Qualifications

                        </h6>
                    </div>

                    <div class="col-lg-6">
                        <div class="col" v-if="! showAddRecord">
                            <router-link
                                class="btn btn-lg btn-primary float-right"
                                type="primary"
                                to="/new-application/additional-pgq"
                            >
                                <i class="fa fa-credit-card"></i>
                                Add New AQ
                            </router-link>
                        </div>

                        <div class="col">
                            <base-button
                                class="float-right margin-right10"
                                :type="showAddRecord ? 'danger' : 'success'"
                                size="lg"
                                @click="displayNewRecord()"
                            >
                                <i class="fa fa-plus-circle" v-if="! showAddRecord"></i>
                                <i class="fa fa-times" v-if="showAddRecord"></i>
                                {{showAddRecord ? 'Cancel' : 'Add Registered AQ' }}
                            </base-button>
                        </div>

                    </div>
                </div>
            </div>


            <hr class="my-4">

            <div class="row" v-if="showAddRecord">
                <div class="container" v-if='! showErrorStatus'>
                    <div class="row">
                        <div class="col-lg-12 text-center">
                            <h3 class="text-danger">
                                Note: This form is only for registered Additional Qualification (AQ) that haven't been 
                                added to the portal. 
                                <br> For those registering AQ for the first time, kindly 
                                <router-link
                                    to="/new-application/additional-pgq"
                                    class="text-primary"
                                >
                                    Click Here
                                </router-link> 
                            </h3>
                            <hr>
                        </div>

                        <div class="col-lg-6">
                            <base-input alternative=""
                                label="Country"
                                required
                            >
                                <autocomplete
                                    input-class="form-control form-control-alternative"
                                    placeholder="Type Country"
                                    showNoResults
                                    :source="locations.countries"
                                    v-model="record.country_id"
                                    :initialDisplay="(hasCountry ? hasCountry.name : '')"
                                    @selected="changeCountry"
                                >
                                </autocomplete>
                            </base-input>
                        </div>

                        <div class="col-lg-6">
                            <base-input alternative=""
                                label="School Name / Institution (Post-Graduate College)"
                                required
                                >
                                <autocomplete
                                    ref="schoolAutocomplete"
                                    input-class="form-control form-control-alternative"
                                    placeholder="Type to search Institution"
                                    showNoResults
                                    :disableInput="schools && schools.length == 0"
                                    :source="schools"
                                    :initialDisplay="(record.school ? record.school : '')"
                                    resultsValue="name"
                                    v-model="record.school"
                                    @selected="changeInstitution"
                                >
                                </autocomplete>
                            </base-input>
                        </div>

                        <div class="col-lg-6" v-if="showOtherInstitution">
                            <base-input alternative=""
                                label="Other School/Institution Name"
                                placeholder="Enter Other School/Institution Name"
                                input-classes="form-control-alternative"
                                v-model="record.other_school_name"
                                required
                            />
                        </div>

                        <div class="col-lg-12" v-if="qualifications && qualifications.length > 0">
                            <base-input alternative=""
                                label="Qualification"
                                required
                            >
                                <select
                                    class="form-control form-control-alternative"
                                    v-model="record.qualification"
                                    @change="getDegrees()"
                                >
                                    <option value="null" disabled>--- Select One ---</option>
                                    <option
                                        v-for="qualification in qualifications"
                                        :key="qualification.id"
                                        :value="qualification.id"

                                    >
                                        {{ qualification.course_title }}
                                    </option>
                                </select>
                            </base-input>
                        </div>


                        <div
                            class="col-lg-12"
                            v-if="(degrees && degrees.length > 0) || record.degree"
                        >
                            <base-input alternative=""
                                v-if="degrees[0] != ''"
                                label="Degree"
                                required
                                >
                                <div class="container">
                                    <div class="row">
                                        <base-radio
                                            v-for="(degree, index) in degrees"
                                            :key="index"
                                            v-show="degree != ''"
                                            :name="degree.trim()"
                                            class="mb-3"
                                            v-model="record.degree"
                                        >
                                            {{ degree.trim() }} &nbsp;&nbsp;&nbsp;
                                        </base-radio>
                                    </div>
                                </div>

                            </base-input>
                        </div>

                        <div class="col-lg-6">
                            <base-input
                                label="Date of Qualification Obtained"
                                addon-left-icon="ni ni-calendar-grid-58"
                                required

                            >
                                <flat-picker
                                    slot-scope="{focus, blur}"
                                    @on-open="focus"
                                    @on-close="blur"
                                    :config="config"
                                    class="form-control datepicker"
                                    v-model="record.qualify_date"
                                >
                                </flat-picker>
                            </base-input>
                        </div>

                        <div class="col-lg-6">
                            <base-input alternative=""
                                label="Issuing Institution"
                                placeholder="Enter Issuing Institution"
                                input-classes="form-control-alternative text-capitalize"
                                v-model="record.licencing_body"
                            />
                        </div>

                        <div class="col-lg-6">
                            <base-input alternative=""
                                label="Duration of training (In weeks)"
                                placeholder="Enter Duration of training"
                                input-classes="form-control-alternative text-capitalize"
                                v-model="record.duration"
                                type="number"
                            />
                        </div>


                        <div class="col-lg-6">
                            <base-input alternative=""
                                label="AQ Number"
                                placeholder="Enter AQ Number"
                                input-classes="form-control-alternative text-capitalize"
                                v-model="record.aq_number"
                            />
                        </div>

                        <div class="col-lg-12">
                            <hr>
                            <h4 class="heading text-muted mb-4">
                                <!-- Upload Files &nbsp;&nbsp;&nbsp;&nbsp; -->
                                <span class="text-danger">
                                    <i class="fa fa-info-circle"></i> Only PDF Files (Not More than 400KB) are supported for each file
                                </span>
                            </h4>
                        </div>

                        <div
                            class="col-lg-6 text-center borderLine"
                            v-for="(document, index) in documents"
                            :key="index"
                        >
                            <br>
                            <input
                                type="file"
                                id="aq_file" style="display: none"
                                @change="onFileSelected"
                            >
                            <h4 class="text-capitalize height-50">
                                {{ document.name }}
                            </h4><br>
                            <base-button
                                size="sm" type="primary"
                                v-if="! selectedCert && (document.filename =='additional_certificate.pdf')"
                                @click="selectFile(document.filename)"
                            >
                                <i class="fa fa-hand-pointer"></i>
                                {{ aqFiles.certificate_file ? 'Change File' : 'Select File' }}
                            </base-button>

                            <base-button
                                size="sm" type="primary"
                                v-if="! selectedRegCert && (document.filename =='registration_certificate.pdf')"
                                @click="selectFile(document.filename)"
                            >
                                <i class="fa fa-hand-pointer"></i>
                                {{ aqFiles.registration_file ? 'Change File' : 'Select File' }}
                            </base-button>

                            <base-button
                                size="sm" type="danger"
                                v-if="(registrationFile.name == document.filename || certificateFile.name == document.filename)
                                    && (registrationFile.name || certificateFile.name)"
                                @click="removeFile(document.filename)"
                            >
                                <i class="fa fa-times"></i> Remove File
                            </base-button>
                            <br><br>
                        </div>
                        <div class="col-lg-12">
                            <hr>
                        </div>
                    </div>
                </div>

                <div class="col-sm-12 text-center">
                    <base-alert :type="errorType" v-if='showInputErrorStatus'>
                        <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                        <span class="alert-inner--text">
                            {{errorMsg}}
                        </span>
                    </base-alert>
                </div>

                <div class="pl-lg-4" v-if='! showErrorStatus'>
                    <br>
                    <base-button
                        type="success"
                        @click="saveAdditionalQualifcation()"
                    >
                        <i class="fa fa-save"></i>
                        {{isloading ? 'Please Wait...' : saveButton }}
                    </base-button>
                </div>


            </div>
        </div>

        <!-- import edu records details template here -->
        <AddQualificationData
            v-bind:records="records"
            v-bind:showAddRecord="showAddRecord"
            v-on:deleteAqRecord="deleteAqRecord"
            v-on:editAqRecord="editAqRecord"
            v-bind:pagination="pagination"
            v-bind:hasPagination="hasPagination"
            v-bind:paginate="paginate"
        />

        <div class="col-sm-12 text-center">
            <base-alert :type="errorType" v-if='showErrorStatus'>
                <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                <span class="alert-inner--text">
                    {{errorMsg}}
                </span>
            </base-alert>
        </div>



    </div>


</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import flatPicker from "vue-flatpickr-component";
    import "flatpickr/dist/flatpickr.css";
    import AddQualificationData from './AddQualificationData.vue';
    import swal from 'sweetalert2';// import sweet alert
    import Autocomplete from 'vuejs-auto-complete';

    export default {
        name: 'AddQualification',
        components: {
            flatPicker,
            AddQualificationData,
            Autocomplete
        },
        data() {
            return {
                records: [],
                paginatedItems: [],
                showAddRecord: false,
                categories: [],
                qualifications: [],
                degrees: [],
                schools: [],
                saveButton: 'Add Now',
                record: {
                    country_id: null,
                    school: null,
                    category: null,
                    qualification: null,
                    degree: null,
                    qualify_date: null,
                    licencing_body: null,
                    other_school_name: null,
                    aq_number: null,
                    duration: null,
                    id: null
                },
                certificateFile: {
                    'name' : null,
                    'file' : null
                },
                registrationFile: {
                    'name' : null,
                    'file' : null
                },
                notificationFile: {
                    'name' : null,
                    'file' : null
                },
                aqFiles: {
                    certificate_file: false,
                    registration_file: false
                },
                pagination: {
                    total: null,
                    number: 1,
                    from: null,
                    perPage: this.$store.state.user.perPage // get per page count
                },
                selectedFileName: null,
                showInputErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isloading: false,
                selectedCert: false,
                selectedRegCert: false,
                showOtherInstitution: false,
                showErrorStatus: false,
                applicationId: null,
                qualificationId: null,
                isLoadingBlade: true,
                config: {
                    maxDate: new Date().fp_incr(1),
                    altInput: true,// eslint-disable-next-line
                    plugins: [new monthSelectPlugin({shorthand: true, dateFormat: "Y-m-d", altFormat: "M Y"})]
                }
            }
        },
        computed: {
            ...mapGetters(['lock','loadingBladeUrl', 'locations']),
            documents() {
                return [
                    {'name' : 'Certificate Obtained', 'filename' : 'additional_certificate.pdf'},
                    {'name' : 'Registration Certificate', 'filename' : 'registration_certificate.pdf'}
                ];
            },
            hasPagination() {
                return this.paginatedItems && (this.paginatedItems.length > this.pagination.perPage)
                    ? true : false;
            },
            hasCountry() {
                return this.locations.countries.filter(item => item.id == this.record.country_id)[0];
            },
        },
        methods: {
            ...mapActions(['getAdditionalQulifications', 'getLocations', 'getUserAddQual', 'getAssessmentInstitutions',
                'addOldAdditionalQualification', 'deleteUserAq']),
            displayNewRecord() {// show new record
                this.emptyAqRecords();// empty edu records
                if(this.records && this.records.length == 0) {// hide empty msg
                    this.showEmptyMsg();
                    this.showErrorStatus = this.showAddRecord;
                }
                return this.showAddRecord = ! this.showAddRecord;
            },
            changeInstitution(e) {
                let data = e.selectedObject;
                this.record.other_school_name = null;
                this.showOtherInstitution = false;
                if(data.id == 0) {
                    this.showOtherInstitution = true;
                } else {
                    this.record.school = data.name;
                }
            },
            changeCountry(e, country = false) {// get exact institutions
                this.schools = []; let id;
                if(country) {
                    id = country;
                } else {
                    id =  e.selectedObject.id;
                    this.record.school = null;
                    this.record.other_school_name = null;
                    this.$refs.schoolAutocomplete.clear();
                }
                this.preloader();
                this.getAssessmentInstitutions({ id : id }).then((res) => {
                    swal.close();
                    this.schools = res.schools;
                    let other = { id : 0, name: 'Others (Specify)'};
                    this.schools.push(other);
                });
            },
            showEmptyMsg() {// show empty msg
                this.showErrorStatus = true;
                this.errorType = 'warning';
                this.errorMsg = 'No Additional Qualifications Yet!, kindly apply one';
            },
            editAqRecord(aqRecord) {// edit AQ record
                this.record.id = aqRecord.id;
                this.record.country_id = aqRecord.country_id;
                this.record.duration = aqRecord.duration;
                this.record.school = aqRecord.school_name;
                this.record.category = aqRecord.additional_qualification_category_id;
                this.record.qualification = aqRecord.additional_qualification_config_id;
                this.record.degree = (aqRecord.degree ? aqRecord.degree : null);
                this.record.qualify_date = aqRecord.qualify_date;
                this.record.licencing_body = aqRecord.licencing_body;
                this.record.aq_number = aqRecord.aq_number;
                if(aqRecord.documents && aqRecord.documents.length > 0) {// update documents status
                    this.aqFiles.certificate_file = aqRecord.documents[0].hasFile ?
                                aqRecord.documents[0].hasFile : false;
                    this.aqFiles.registration_file = aqRecord.documents[1].hasFile ?
                                aqRecord.documents[1].hasFile : false;
                }
                this.degrees = [];
                this.degrees = aqRecord.qualification.qualification.split(',');// update degrees
                this.showAddRecord = true;
                this.saveButton = 'Re-send Now';
                this.changeCountry(null, aqRecord.country_id);// get institutions
            },
            deleteAqRecord(id) {
                this.preloader();// show loading
                this.deleteUserAq({'id' : id}).then((res) => {
                    if(res.status) {
                        this.loadAddQualifications(); // load Aq
                        swal.fire("Record Deleted!", "Record was deleted successfully", "success");
                    } else {
                        swal.fire("Error Occurred!", "An error occurred while deleting, try again", "warning");
                        this.loadAddQualifications(); // load Aq
                    }
                }).
                catch(() => {
                    swal.fire("Network Failure", "Reload page and try again...", "warning");
                    this.loadAddQualifications(); // load EduRecords
                });
            },
            emptyAqRecords() {
                this.saveButton = 'Add Now';
                this.record.id = null;
                this.record.school = null;
                this.record.country_id = null;
                this.record.duration = null;
                this.record.category = null;
                this.record.qualification = null;
                this.record.degree = null;
                this.record.qualify_date = null;
                this.record.licencing_body = null;
                this.record.other_school_name = null;
                this.record.aq_number = null;
                this.aqFiles.certificate_file = false;
                this.aqFiles.registration_file = false;
                this.country = null;
            },
            emptyRecordsMsg() {
                return this.records && this.records.length == 0 && (!this.showAddRecord)
                    ? this.showEmptyMsg() : false;
            },
            loadAddQualifications() {// load details
                this.loadAqAndInstitions();// load Aq And Institions
                this.getLocations();
                this.getUserAddQual().then((res) => {// get Aq records
                    this.isLoadingBlade = false;
                    this.qualifications = res.qualifications;
                    this.setPages(res.aqs);
                    this.emptyRecordsMsg();//show empty msg
                }).
                catch(() => {
                    this.isLoadingBlade = false;
                });
            },
            setPages(res) {// set paginated function
                this.records = [];
                this.paginatedItems = res;
                this.pagination.total = this.paginatedItems.length; // get items total
                let perPage = this.pagination.perPage; // get per page count
                if(this.paginatedItems.length > perPage) {// paginate
                    let numberOfPages = Math.ceil(this.paginatedItems.length / perPage);
                    for (let index = 1; index <= numberOfPages; index++) {
                        this.records.push(this.paginatedItems[index]);
                    }
                } else {
                    this.records = this.paginatedItems;
                }
            },
            paginate() {// paginate function
                this.eduRecords = [];
                this.pagination.from = null;
                let page = this.pagination.number;
                let perPage = this.pagination.perPage;
                let from = (page * perPage) - perPage;
                let to = (page * perPage);
                this.pagination.from = from;//get first item number
                return this.eduRecords = this.paginatedItems.slice(from, to);
            },
            loadAqAndInstitions() {// load Aq And Institions
                this.getAdditionalQulifications().then((res) => {// get add qual records
                    this.categories = res;
                });
            },
            getDegrees() {// get Qualification's degrees
                this.degrees = [];
                this.record.degree = null;
                for(let qualification of this.qualifications) {
                    if(qualification.id == this.record.qualification) {
                        this.degrees = qualification.qualification.split(',');
                    }
                }
            },
            selectFile(name) {// eslint-disable-next-line
                $('#aq_file').val('');
                this.selectedFileName = null;// eslint-disable-next-line
                $('#aq_file').click();
                (name == 'additional_certificate.pdf') ? this.certificateFile.name = name :
                    this.registrationFile.name = name;
                this.selectedFileName = name;
            },
            onFileSelected(event) {
                let status =  this.validateFile(event.target.files[0]);
                // certificate file
                var fileReader = new FileReader();
                fileReader.readAsDataURL(event.target.files[0]);
                fileReader.onload = (event) => {// convert file to binary
                   (this.selectedFileName == 'additional_certificate.pdf') ?
                   this.certificateFile.file = event.target.result :
                    this.registrationFile.file = event.target.result;
                }
                return status;
            },
            validateFile(file) {// check for valid file
                if(!file) {// update seleted file name
                    (this.selectedFileName == 'additional_certificate.pdf') ?
                    this.certificateFile.name = null : this.registrationFile.name = null;
                }
                var fileExt = file.type.split('/').pop();
                let fileSize = file.size;
                if(fileExt == 'pdf' && fileSize <= 400000) {// for valid file and size
                if(this.selectedFileName == 'additional_certificate.pdf') {
                        this.selectedCert = true
                    } else {
                        this.selectedRegCert = true
                    }
                    return true;
                } else if(fileExt != 'pdf') {// invalid file
                    if(this.selectedFileName == 'additional_certificate.pdf') {
                        this.certificateFile.name = null;
                        this.selectedCert = false
                    } else {
                        this.registrationFile.name = null;
                        this.selectedRegCert = false
                    }
                    swal.fire("Invalid File!", "Only PDF files are accepted", "warning");
                    return false;
                } else if(fileSize > 400000) {// big file
                    if(this.selectedFileName == 'additional_certificate.pdf') {
                        this.certificateFile.name = null;
                        this.selectedCert = false
                    } else {
                        this.registrationFile.name = null;
                        this.selectedRegCert = false
                    }
                    swal.fire("File is too large!", "File should be less than 400KB", "warning");
                    return false;
                }
            },
            removeFile(name) {// eslint-disable-next-line
                $('#aq_file').val('');
                if(name == 'additional_certificate.pdf') {
                    this.certificateFile.name = null;
                    this.certificateFile.savedEvent = null;
                    this.certificateFile.file = null;
                    this.selectedCert = false;
                } else {
                    this.registrationFile.name = null;
                    this.registrationFile.savedEvent = null;
                    this.registrationFile.file = null;
                    this.selectedRegCert = false;
                }
            },
            saveAdditionalQualifcation() {// save add qual
                let data = this.validatedInputs();// validate inputs
                if(data) {// update files
                    this.isloading = true;
                    data.certificate_file = this.certificateFile;
                    data.registration_file = this.registrationFile;
                    data.notification_file = this.notificationFile;
                    data.degree = (this.record.degree ? this.record.degree : null);// get degree
                    data.user_data_id = this.$store.state.user.userDataId;// get user id
                    data.user_id = this.$store.state.user.userId;// get user id
                    data.id = this.record.id;// get Aq id
                    this.addOldAdditionalQualification(data).then((res) => {// add AQ
                        this.isloading = false;
                        this.emptyFileName();
                        if(res.status) {
                            this.showAddRecord = false;
                            this.loadAddQualifications();// load add qual records
                            swal.fire("Record Updated!", "Record was updated successfully", "success");
                        } else {
                            this.loadAddQualifications();// load add qual records
                            swal.fire("Error Occurred!", "An error occurred while saving, try again", "warning");
                        }
                    }).
                    catch(err => {
                        this.isloading = false;
                        this.emptyFileName();
                        this.loadAddQualifications();// load add qual records
                        swal.fire(err.message+"!", "An error occurred while saving, try again", "warning");
                    });
                }
            },
            emptyFileName() {
                this.certificateFile.name = null;
                this.selectedFileName = null;
                this.registrationFile.name = null;
                this.selectedCert = false;
                this.selectedRegCert = false;
            },
            validatedInputs() {
                let data = this.record;
                let certFile = null;
                let regFile = null;
                let addRecord = { country_id: null, qualification: null, duration: null, qualify_date: null,
                    licencing_body: null };
                regFile = (this.registrationFile.name == null && ! this.aqFiles.registration_file) ?
                    this.showInuptError('Registration Certificate File') : true;
                certFile = (this.certificateFile.name == null && ! this.aqFiles.certificate_file) ?
                    this.showInuptError('Certificate File') : true;
                addRecord.aq_number  = data.aq_number == null ?
                    this.showInuptError('AQ Number') : data.aq_number;
                addRecord.duration  = data.duration == null ?
                    this.showInuptError('Duration of Training') : data.duration;
                addRecord.licencing_body  = data.licencing_body == null ?
                    this.showInuptError('Issuing Institution') : data.licencing_body;
                addRecord.qualify_date  = data.qualify_date == null ?
                    this.showInuptError('Qualification Date') : data.qualify_date;
                addRecord.qualification  = data.qualification == null ?
                    this.showInuptError('Qualification') : data.qualification;

                // validate institution
                if((data.school == null || data.school == 'others')
                    && data.other_school_name != null) {
                    addRecord.school_name = data.other_school_name;
                } else if(data.school != null && data.school != 'others') {
                    addRecord.school_name = data.school;
                } else if((data.school == null || data.school == 'others')
                    && data.other_school_name == null) {
                    this.showInuptError('School\'s Name');
                }
                addRecord.country_id  = data.country_id == null ? this.showInuptError('Country') : data.country_id;
                return this.isEmpty(addRecord) && certFile && regFile ? addRecord : false;
            },
            isEmpty(obj) {// check for empty field
                return ! Object.keys(obj).some(k => !obj[k]);
            },
            showInuptError(name) {
                this.isloading = false;
                this.showInputErrorStatus = true
                this.errorType = 'warning'
                this.errorMsg = 'Invalid ' + name + ' has been entered';
                var self = this;
                setTimeout(function() { self.showInputErrorStatus = false; }, 5000);
            },
            changeOtherInstitution() {// change other Institution
                let value = this.record.school;
                return this.showOtherInstitution = (value == 'others') ? true : false;
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        created() {
            this.loadAddQualifications();// load add qual records
        },
    }
</script>

<style>
    .margin-right10 {
        margin-right: 10px;
    }
</style>

<style>
    .form-control-label {
        width: 100%;
    }
</style>