<template>
    <div class="row" v-if="! showAddRecord && postings && postings.length > 0">
        <div class="col-lg-12">
            <div class="card shadow">
                <div class="table-responsive">
                    <base-table
                        class="table align-items-center table-flush"
                        thead-classes="thead-light"
                        tbody-classes="list"
                        :data="postings"
                    >
                        <template slot="columns">
                            <th width="2%">S/N</th>
                            <th width="10%">Consultant Name</th>
                            <th width="20%">Consultant Qual</th>
                            <th width="10%">Department</th>
                            <th width="10%">Start Date</th>
                            <th width="10%">End Date</th>
                            <th width="5%">&nbsp;</th>
                        </template>

                        <template
                            slot-scope="{row, index}"
                        >
                        <td class="budget">
                            {{index+1}}
                        </td>
                        <td class="text-capitalize">
                            {{row.consultant_name}}
                            <br>
                            <badge
                                type="success"
                                v-if="row.current_status == 'yes'"
                            >
                                Current Posting
                            </badge>
                            <badge
                                type="danger"
                                v-if="row.current_status == 'no'"
                            >
                                Past Posting
                            </badge>
                        </td>
                        <td>
                            {{row.consultant_qualification}}
                        </td>
                        <td>
                            {{row.has_department ? row.has_department.name : ''}}
                        </td>
                        <td>
                            {{row.start_date | getDateFormat}}
                        </td>
                        <td>
                            {{row.end_date | getDateFormat}}
                        </td>
                        <td>
                            <base-button
                                type="danger" size="sm"
                                @click="$emit('deletePostingRecord', row.id)"
                            >
                                <i class="fa fa-trash"></i> Remove
                            </base-button>
                        </td>

                        </template>

                    </base-table>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import moment from 'moment';

    export default {
        name: 'PostingData',
        props: ["postings", "showAddRecord"],
        filters: {
            getDateFormat(value) {
                return moment(value).format('Do MMM, YYYY');
            }
        },
        computed: {
            ...mapGetters(['lock'])
        }
    }
</script>