<template>
    <div>
        <div class="col-lg-12" v-if="isLoadingBlade">
            <div class="d-flex justify-content-center">
                <img :src="loadingBladeUrl" />
            </div>
        </div>

        <div v-if="! isLoadingBlade">
            <h4 class="mb-0 text-uppercase" v-if="application && application.application_type">
                Make Payment for
                <em class="text-danger">
                    {{ application.application_type.name }}
                </em>
            </h4>

            <div class="container ct-example-row" v-if="! showAddPayment">
                <div class="row">
                    <div class="col-lg-12">
                        <ul
                            class="list-unstyled"
                            v-if="application && application.application_type &&
                                application.application_type.has_payment"
                        >
                            <li>
                                <hr class="my-4" v-if="application.license && application.license.surcharge">



                                <h3 class="height-50 text-danger text-center"
                                    v-if="application.license && application.license.surcharge"
                                >
                                    Note: If you make payment on or before <em class="text-primary"> 31st Decemeber,
                                    {{ application.license.license_year - 1 }}</em>,
                                    and you have the RRR number, kindly proceed to add the RRR payment. As the surge amount will
                                    not be applicable to you.
                                </h3>

                                <hr class="my-4">

                                <h3 class="text-center">
                                    Application Name: &nbsp;
                                    <em class="text-capitalize font-weight-light">
                                        {{ application.application_type.name }}
                                    </em>
                                </h3>
                                <br>
                                <h3 class="text-center">
                                    Total Amount: &nbsp;
                                    <em class="text-capitalize font-weight-light">
                                        {{ application.license.amount | formatPrice }}
                                    </em>
                                </h3>
                                <hr class="my-4">
                                <h1 class="text-center">
                                    Payment Details
                                </h1>
                                <hr class="my-4">
                                    <h3
                                        v-for="breakdown in application.application_type.has_payment.has_breakdowns"
                                        :key="breakdown.id"
                                    >
                                        {{ breakdown.name }}: &nbsp;
                                        <em class="text-capitalize font-weight-light right">
                                            {{ breakdown.amount | formatPrice }}
                                        </em>
                                        <br>
                                    </h3>

                                    <h3
                                        class="text-danger"
                                        v-if="application.license.surcharge"
                                    >
                                        Surcharge (applicable to late payment only): &nbsp;
                                        <em class="text-capitalize font-weight-light right">
                                            {{ application.license.surcharge_amount | formatPrice }}
                                        </em>
                                    </h3>

                                    <hr class="my-4">
                                    <h3>
                                        Total: &nbsp;
                                        <em class="text-capitalize font-weight-light right">
                                            {{ application.license.amount | formatPrice }}
                                        </em>
                                    </h3>
                                <hr class="my-4">

                                <div class="col-sm-12 text-center">
                                    <base-alert :type="errorType" v-if='showErrorStatus'>
                                        <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                                        <span class="alert-inner--text">
                                            {{ errorMsg }}
                                        </span>
                                    </base-alert>
                                </div>

                                <h3 class="text-danger text-center"
                                    v-if="showPaymentForm"
                                >
                                    Note: If you want to generate RRR for this payment and pay at designated banks, kindly click on
                                    <em class="text-dark">Black</em> button. However, if you have made payment already, you can add your
                                    payment to the application by clicking on the <em class="text-primary">Purple</em> button.
                                    <hr>
                                    Note: When you Generate RRR, you are to proceed to the designated banks to make payment. After
                                    succesful payment, click on the <em class="text-primary">I have Paid, Add RRR Now</em> button
                                    to add your payment.
                                </h3>
                            </li>
                        </ul>
                    </div>
                    <div class="col-lg-12">
                        <hr class="my-4" v-if="showPaymentForm">

                        <form
                            :action="remitaPaymentUrl" method="POST"
                            v-if="showPaymentForm"
                        >
                            <input
                                type="hidden"
                                v-for="(item, index) in paymentRecords"
                                :key="index"
                                :name="item.title"
                                :value="item.value"
                            />


                            <base-button
                                type="success"
                                nativeType="submit"
                            >
                                <i class="fa fa-credit-card"></i>
                                {{ isloading ? 'Please Wait...' : 'Pay Directly Online Now'}}
                            </base-button>

                            <div class="right">
                                <base-button
                                    type="dark"
                                    @click="addPayment(orderId, true)"
                                >
                                    <i class="fa fa-history"></i>
                                    Generate RRR &amp; Pay at the Bank
                                </base-button>

                                <base-button
                                    type="primary"
                                    @click="addPayment(orderId, false)"
                                >
                                    <i class="fa fa-plus"></i>
                                    I have Paid, Add RRR Now
                                </base-button>
                            </div>

                        </form>

                        <base-button
                            type="success"
                            @click="createApplication()"
                            v-if="! showPaymentForm"
                        >
                            <i class="fa fa-reply"></i>
                            {{ isloading ? 'Please Wait...' : 'Apply Now'}}
                        </base-button>
                    </div>
                </div>
            </div>

            <AddPayment
                :orderNumber="orderNumber"
                :app_id="applicationId"
                :serviceType="serviceType"
                :applicationName="application.application_type.name"
                :applicationCode="application.application_type.code"
                :applicationAmount="application.application_type.has_payment.amount"
                v-on:removeAddPayment="removeAddPayment"
                :removeAddPayment="removeAddPayment"
                :licenseYear="application.license.license_year"
                :showGeneratePage="showGeneratePage"
                :isDirectPaymentPage="isLicensePayment"
                :applicationSurcharge="surcharge"
                v-if="showAddPayment"
            />
        </div>

    </div>



</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import moment from 'moment';
    import AddPayment from './AddPayment.vue';
    import swal from 'sweetalert2';// import sweet alert

    export default {
        components: {
            AddPayment
        },
        name: 'AnnualLicensePayment',
        data() {
            return {
                code: this.$route.params.code,
                application: null,
                user: {},
                isLoadingBlade: true,
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isloading: false,
                orderId: null,
                serviceType: null,
                showPaymentForm: false,
                showAddPayment: false,
                orderNumber: null,
                applicationId: null,
                showGeneratePage: null,
                isLicensePayment: false
            }
        },
        filters: {
            formatPrice(value) {
                if(value) {
                    let val = (value/1).toFixed(2).replace(',', '.');
                    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")+ ' NGN';
                } else {
                    return 'N/A';
                }
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl', 'remitaPaymentUrl', 'remitaMerchantId',
                'remitaPaymentCallbackUrl', 'remitaApiKey']),
            gethash() {
                var sha512 = require('js-sha512');
                let text = this.remitaMerchantId+this.serviceType.remita_code+this.orderId
                    +this.application.license.amount+this.remitaPaymentCallbackUrl
                    +this.remitaApiKey;
                return sha512(text);
            },
            paymentRecords() {// get payment records
                let fullName = this.user.first_name+' '+this.user.last_name;
                return [
                    {'title' : 'merchantId', 'value' : this.remitaMerchantId},
                    {'title' : 'serviceTypeId', 'value' : this.serviceType.remita_code},
                    {'title' : 'amt', 'value' : this.application.license.amount},
                    {'title' : 'responseurl', 'value' : this.remitaPaymentCallbackUrl},
                    {'title' : 'hash', 'value' : this.gethash},
                    {'title' : 'payerName', 'value' : fullName},
                    {'title' : 'paymenttype', 'value' : ''},
                    {'title' : 'payerEmail', 'value' : this.user.email},
                    {'title' : 'payerPhone', 'value' : this.user.phone},
                    {'title' : 'orderId', 'value' : this.orderId},
                ]
            },
            surcharge() {// surcharge details
                let status = this.application.license.surcharge;
                return {
                    status: status,
                    amount: this.application.license.amount
                }
            }
        },
        methods: {
            ...mapActions(['getAnnualLicenseDetails', 'createUserApplication', 'fetchUserProfile', 'updateTransNo',
            'saveApplicationTransaction']),
            isValidAnnualLicense(app) {// check if license can be purchased
                let appType = app && app.application_type ? app.application_type : false;
                let code = appType && appType.code ? appType.code : false;
                let validCode = ((code == 'annual-license-old') || (code == 'annual-license'));
                let licenseYear = app && app.license && app.license.license_year ? app.license.license_year : false;
                let maximunYear = moment().year() + 1;
                return validCode && licenseYear && (licenseYear > maximunYear);
            },
            loadApplication(status = false) {// get application
                this.showAddPayment = false;
                this.getAnnualLicenseDetails().then((res) => {// get edu records
                    this.applicationId = res.application ? res.application.id : null;
                    this.application = res;
                    this.serviceType = res.service_type;
                    if(this.isValidAnnualLicense(res)) {// redirect to my license
                        return window.location.href = '/my-license';
                    } else { 
                        ! status ? this.createApplication() : this.isLoadingBlade = false;// create application
                    }
                }).
                catch(() => {
                    this.isLoadingBlade = false;
                    this.showMsg('danger', "Network Failure!, kindly reload the page...");
                });
                this.fetchUserProfile().then((res) => {// set user details
                    this.user.first_name = res.basic_profile.first_name;
                    this.user.last_name = res.basic_profile.last_name;
                    this.user.phone = res.basic_profile.phone;
                    this.user.email = res.email;
                });
            },
            showMsg(type, msg) {
                this.showErrorStatus = true;
                this.errorType = type;
                this.errorMsg = msg;
                var self = this;
                setTimeout(function() { self.showErrorStatus = false; }, 5000);
            },
            createApplication() {// create application
                this.isloading = true;
                this.isLoadingBlade = true;
                if(this.applicationId) {
                    let licenseYear = this.application && this.application.license && this.application.license.license_year ?
                                        this.application.license.license_year : null;// get license year
                    this.updateTransNo({'id' : this.applicationId, 'license_year' : licenseYear})
                        .then((res) => {//update trans no
                            this.isloading = false;
                            this.showPaymentForm = true;
                            this.orderId = res.trans_no;
                            this.applicationId = res.application.id;
                            this.isLoadingBlade = false;
                            // let message = 'Kindly click on Pay Now to make payment online';
                            // swal.fire('Application Updated!', message, 'success');
                        }).
                    catch(() => {
                        this.isloading = false;
                        let message = 'Network Failure!, kindly reload the page...';
                        swal.fire('Network Failure!', message, 'warning');
                    });
                } else {
                    this.createNewApplication();// create new application
                }
            },
            createNewApplication() {
                let data = {};
                data.user_data_id = this.$store.state.user.basicProfile.id;// get user data id
                data.application_type_id = this.application.application_type.id;// apllication type id
                data.trans_no = this.genRandomString();// generate trans no
                data.application_date = this.getCurrentDate();// get current date
                data.license_year = this.application.license.license_year;// get license year
                this.createUserApplication(data).then((res) => {// create application
                    this.isloading = false;
                    if(res.status) {// update transaction
                        this.showPaymentForm = true;
                        this.orderId = data.trans_no;
                        this.applicationId = res.application.id;
                        this.isLoadingBlade = false;
                        // let message = 'Kindly click on Pay Now to make payment online';
                        // swal.fire('Application Updated!', message, 'success');
                        // return this.updateTransaction(res, record);
                    }
                }).
                catch(() => {
                    this.isloading = false;
                    let message = 'Network Failure!, kindly reload the page...';
                    swal.fire('Network Failure!', message, 'warning');
                    return false;
                });
            },
            getCurrentDate() {// get current date time
                let date = new Date().toJSON().slice(0,10).replace(/-/g,'-');
                return moment(date).format('YYYY-MM-DD');
            },
            removeAddPayment(status = false) {// remove add payment
                this.loadApplication(status);// load application
                return this.showAddPayment = false;
            },
            genRandomString() {
                var length = 12;
                var text = '';
                var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
                for (var i=0; i < length; i++) {
                    text += possible.charAt(Math.floor(Math.random() * possible.length));
                }
                return text;
            },
            getCurrentDateTime() {// get current date time
                let date = new Date().toJSON().slice(0,10).replace(/-/g,'-');
                return moment(date).format('YYYY-M-D h:mm:ss');
            },
            addPayment(number, status) {// add RRR
                this.orderNumber = number;
                this.showAddPayment = true;
                this.isLicensePayment = true;
                this.showGeneratePage = status;
            },
        },
        created() {
            this.loadApplication();// load application
        },
    }
</script>

<style scoped>
.right {
    float: right;
}
</style>