<template>
    <div class="pl-lg-4">
        <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center">
            <span class="mask bg-gradient-danger opacity-8"></span>
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">

                <div class="col-xl-12 order-xl-1">
                    <card shadow type="secondary">
                        <div slot="header" class="bg-white border-0">
                            <div class="row align-items-center">
                                <div class="col-8">
                                    <h3 class="mb-0 text-uppercase">{{ this.$route.name }}</h3>
                                </div>
                                <div class="col-4 text-right">
                                    <router-link to="/" class="btn btn-sm btn-primary">
                                        <i class="fa fa-home"></i>
                                        Dashboard
                                    </router-link>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-lg-6">
                                <base-input alternative=""
                                label="Payment Type"
                                required
                                >
                                <select
                                    class="form-control form-control-alternative"
                                    v-model="record.code"
                                >
                                    <option value="null" selected disabled>--- Select One ---</option>
                                    <option 
                                        v-for="(item, index) in paymentItems"
                                        :key="index"
                                        :value="item.value"
                                    >
                                        {{ item.name }}
                                    </option>
                                </select>
                            </base-input>
                            </div>
                            <div class="col-lg-12"></div>
                            <div class="col-lg-6">
                                <base-input alternative=""
                                    label="Payment Description"
                                    placeholder="Enter Payment Description"
                                    input-classes="form-control-alternative"
                                    v-model="record.description"
                                    required
                                />
                            </div>
                            <div class="col-lg-12"></div>
                            <div class="col-lg-6">
                                <base-input alternative=""
                                    label="Payment Amount (NGN)"
                                    placeholder="Enter Amount"
                                    input-classes="form-control-alternative"
                                    v-model="record.amount"
                                    type="number"
                                    required
                                />
                            </div>
                        </div>

                        <base-alert :type="errorType" dismissible v-if='showErrorStatus'>
                            <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                            <span class="alert-inner--text">
                                {{errorMsg}}
                            </span>
                            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </base-alert>

                        <base-button type="success" @click="createApplication()">
                            <i class="fa fa-credit-card"></i>
                            {{isloading ? 'Please Wait...' : 'Pay Now'}}
                        </base-button>
                    </card>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import { mapActions } from 'vuex';
    import swal from 'sweetalert';// import sweet alert

    export default {
        name: 'OtherPayments',
        data() {
            return {
                record: {
                    code: null,
                    description: null,
                    amount: null
                },
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isloading: false
            }
        },
        computed: {
            paymentItems() {// payment item types
                return [
                    { name : 'Late Registration Payment/Additional Payment', value : 'late-reg-payment' },
                    { name : 'International Verification', value : 'inter-verification' }
                ]
            }
        },
        methods: {
            ...mapActions(['saveOtherPayments']),
            createApplication() {// update password
                this.isloading = true;
                let status = this.validInputs();
                if(status) {// update
                    let data = this.record;
                    data.user_data_id = this.$store.state.user.basicProfile.id;// get user id
                    this.saveOtherPayments(data).then((res) => {
                        // console.log(res);
                        this.isloading = false;
                        this.emptyRecords();
                        if(res.status) {
                            return window.location.href = '/application-payment/'+res.application.id;
                        } else {
                            swal("Error Occurred!", res.msg, "warning");
                        }
                    }).
                    catch(() => {
                        this.isloading = false;
                        swal("Network Failure", "Reload page and try again...", "warning");
                    });
                } else {
                    this.isloading = false;
                }
            },
            emptyRecords() {// empty records
                this.record.code = null;
                this.record.description = null;
                this.record.amount = null;
            },
            validInputs() {// validate inputs
                let data = this.record;
                data.amount != null && data.amount != '' ? true : this.showInuptError('Payment Amount');
                data.description != null && data.description != '' ? true : this.showInuptError('Payment Description');
                data.code != null && data.code != '' ? true :
                    this.showInuptError('Payment Type');
                return this.isEmpty(data) ? true : false;
            },
            isEmpty(obj) {// check for empty field
                return ! Object.keys(obj).some(k => !obj[k]);
            },
            showErrorMsg(msg) {
                this.isloading = false;
                this.showErrorStatus = true
                this.errorType = 'warning'
                this.errorMsg = msg;
                var self = this;
                setTimeout(function() { self.showErrorStatus = false; }, 5000);
            },
            showInuptError(name) {
                this.isloading = false;
                this.showErrorStatus = true
                this.errorType = 'warning'
                this.errorMsg = 'Invalid ' + name + ' has been entered';
                var self = this;
                setTimeout(function() { self.showErrorStatus = false; }, 5000);
            },
        },
    }
</script>