<template>
    <div class="pl-lg-4">
        <h6 class="heading-small text-muted mb-4">
            Update Specialization Information
        </h6>

        <div class="col-lg-12" v-if="isLoadingBlade">
            <div class="d-flex justify-content-center">
                <img :src="loadingBladeUrl" /> 
            </div>
        </div>

        <div class="row" v-if="! isLoadingBlade">
            <div class="col-lg-6">
                <base-input alternative=""
                    label="Area of Specialization"
                    required
                >
                    <select 
                        class="form-control form-control-alternative" 
                        v-model="doctorSpecialty.specialization_id"
                        @change="changeSubSpecializations()"
                    >
                        <option 
                            value='0'
                            disabled
                        >
                            ---Select One---
                        </option>
                        <option 
                            v-for="specialization in specializations"
                            :value="specialization.id" 
                            :key="specialization.id"
                        >
                            {{specialization.name}}
                        </option>
                    </select>
                </base-input>
            </div>
            <div class="col-lg-6" v-if="subSpecializations && subSpecializations.length > 0">
                <base-input alternative=""
                    label="Area of Sub Specialization"
                    v-if="doctorSpecialty.specialization_id"
                    required
                >
                    <select 
                        class="form-control form-control-alternative"                         
                        v-model="doctorSpecialty.sub_specialization_id"
                    >
                        <option 
                            value='0'
                            disabled
                        >
                            ---Select One---
                        </option>
                        <option 
                            v-for="sub_specialization in subSpecializations"
                            :value="sub_specialization.id" 
                            :key="sub_specialization.id"
                        >
                            {{sub_specialization.name}}
                        </option>
                    </select>
                </base-input>
            </div>
        </div>

        <base-alert :type="errorType" dismissible v-if='showErrorStatus'>
            <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
            <span class="alert-inner--text">
                {{errorMsg}}
            </span>
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </base-alert>

        <base-button 
            type="success"
            v-if="! isLoadingBlade" 
            @click="updateUserSpecialization()"
        >
            <i class="fa fa-save" @if='! isloading'></i> 
            {{isloading ? 'Please Wait' : 'Save Changes'}}
        </base-button>

    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import swal from 'sweetalert';// import sweet alert

    export default {
        name: 'UpdateSpecialization',
        props: ['hasEmptyData'],
        data() {
            return {
                specializations: [],
                subSpecializations: [],
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isloading: false,
                doctorSpecialty: {
                    'specialization_id' : 0,
                    'sub_specialization_id' : 0
                },
                isLoadingBlade: true
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl'])
        },
        methods: {
            ...mapActions(['getSpecializations', 'updateSpecialization']),
            getUserTypeSpecializations() {// get doctor type specializations only
                this.getSpecializations().then((res) => {
                    this.isLoadingBlade = false;
                    let specializations = [];
                    let doctorTypeId = this.$store.state.user.basicProfile.doctor_type_id;// get doctor type id
                    for(let specialty of res.specializations) {                        
                        if (specialty.doctor_type_id == doctorTypeId) {
                            specializations.push(specialty);
                        }
                    }                    
                    return this.specializations = specializations;                    
                });
            },
            getSpecializationSubs() {// get a certain specialization's sub specializations
                this.isLoadingBlade = true;
                let sub_specializations = [];
                this.subSpecializations = [];
                let specialtyId = this.doctorSpecialty.specialization_id;
                this.getSpecializations().then((res) => {                   
                    for(let specialty of res.sub_specializations) {                        
                        if (specialty.specialization_id == specialtyId) {
                            sub_specializations.push(specialty);
                        }
                    }   
                    this.isLoadingBlade = false;                 
                    return this.subSpecializations = sub_specializations;                    
                });
            },
            changeSubSpecializations() {// change sub specializations array
                return this.getSpecializationSubs();
            },
            updateUserSpecialization() {
                this.isloading = true;
                let data = this.validatedData();// valid input data
                if(data) {// check for user id & perform update
                    data['id'] = this.$store.state.user.userId;
                    this.updateSpecialization(data).then((res) => {
                        this.isloading = false;
                        if(res.status) {// load updated profile
                            this.hasEmptyData();
                            swal("Record Updated!", "Specialization record was added successfully", "success");
                        } else {
                            swal("Error Occurred!", "An error occurred, kindly reload page and try again", "warning");
                        }
                    });
                } else {
                    this.isloading = false;
                }
            },
            validatedData() {
                let specialty = this.doctorSpecialty;
                if(specialty && (specialty.specialization_id > 0)) {
                    return  {
                        'specialization_id' : specialty.specialization_id,
                        'sub_specialization_id' : specialty.sub_specialization_id,
                    };
                } else {
                    this.showInuptError('Area of Specialization');
                    return false;
                }
            },
            showUpdateMsg(msg, type) {
                this.showErrorStatus = true;
                this.errorType = type;
                this.errorMsg = msg;
                var self = this;
                setTimeout(function() { self.showErrorStatus = false; }, 5000);
            },
            showInuptError(name) {
                this.isloading = false;
                this.showErrorStatus = true
                this.errorType = 'warning'
                this.errorMsg = 'Invalid ' + name + ' has been entered';
                var self = this;
                setTimeout(function() { self.showErrorStatus = false; }, 5000);
            }
        },
        created() {
            this.getSpecializationSubs();// load doctor specialty subs only
            this.getUserTypeSpecializations();// load doctor type specialty only 
        },
    }
</script>