<template>
    <form>
        <h4 class="heading text-muted mb-4">
            <!-- Upload Files &nbsp;&nbsp;&nbsp;&nbsp; -->
            <span class="text-danger">
                <i class="fa fa-info-circle"></i> Only PDF Files (Less than 800KB) are supported
            </span>
        </h4>

        <hr class="my-4" v-if="! isLoadingBlade"/>

        <div class="col-lg-12" v-if="isLoadingBlade">
            <div class="d-flex justify-content-center">
                <img :src="loadingBladeUrl" />
            </div>
        </div>

        <div class="container ct-example-row" v-if="! isLoadingBlade">
            <!-- import User Document File template here -->
            <DocumentFile
                :applicant="applicant"
                :documents="documents"
                :showDocuments="showDocuments"
                :showErrorStatus="showErrorStatus"
                :errorMsg="errorMsg"
                :loadApplicantInfo="loadApplicantInfo"
                v-bind:loadUserDocuments="loadUserDocuments"
            />
        </div>

        <hr class="my-4" v-if="! isLoadingBlade"/>

        <div class="pl-lg-4" v-if="! isLoadingBlade">
            <base-button
                type="danger"
                @click="showPreviousPage()"
            >
                <i class="fa fa-arrow-left"></i>
                Previous
            </base-button>
            <div class="right" v-if="hasCompleteDocuments">
                <base-button
                    type="primary"
                    @click="continueLater()"
                >
                    <i class="fa fa-save"></i>
                    Save &amp; Continue Later
                </base-button>

                <base-button
                    type="success"
                    @click="showNextPage()"
                >
                    Continue
                    <i class="fa fa-arrow-right"></i>
                </base-button>
            </div>
        </div>

    </form>

</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import DocumentFile from './DocumentFile';
    import swal from 'sweetalert2';// import sweet alert

    export default {
        components: {
            DocumentFile
        },
        name: 'AssessmentDocuments',
        props: ['displayReferee', 'displayPreview', 'applicant', 'loadApplicantInfo'],
        data() {
            return {
                showDocuments: true,
                showErrorStatus: false,
                isLoadingBlade: true,
                errorMsg: null
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl']),
            hasCompleteDocuments() {// check if all documents has been uploaded
                let status = [];
                for(let document of this.documents) {
                    status.push(document.hasFile);
                }
                let checker = arr => arr.every(v => v === true);
                return checker(status);
            },
            isDental() {// is a dental applicant
                let data = this.applicant && this.applicant.additional_data ? this.applicant.additional_data : false;
                return data && data.practice_type && (data.practice_type == 'd');
            },
            documents() {// check for dental applicants files
                let files = this.applicant.files;
                return this.isDental ? files.filter( item  => item.filename != "epic_evidence.pdf" ) : files;
            }
        },
        methods: {
            ...mapActions(['getUserDocuments']),
            continueLater() {
                this.preloader();
                setTimeout(function() {
                    let msg = 'Application was updated successfully, you can continue later';
                    swal.fire("Application Updated!", msg, "success");
                    window.location.href = '/assessment-exam';
                }, 1000);
            },
            showPreviousPage() {// go to next page
                return this.displayReferee(true);
            },
            showNextPage() {// go to next page
                return this.displayPreview(true);
            },
            loadUserDocuments() {// update documents
                // this.loadApplicantInfo();
                this.showDocuments = true;
                this.showErrorStatus = false;
                this.isLoadingBlade = false;
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        created() {
            this.loadUserDocuments();// load documents
        }
    }
</script>

<style>

</style>
