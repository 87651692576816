<template>
    <div>
        <div slot="header" class="border-0">
            <div class="row align-items-center" v-if="application">
                <div class="col-8">
                    <h4 class="mb-0 text-uppercase">
                        Make Payment for
                        <em class="text-danger">{{ application.name }}</em>
                    </h4>
                </div>
                <div class="col-4 text-right">
                    <router-link to="/applications" class="btn btn-sm btn-danger">
                        <i class="fa fa-reply"></i> Back
                    </router-link>
                </div>
            </div>
        </div>

        <div class="container ct-example-row" v-if="! showAddPayment">
            <div class="row" v-if="application && application.has_payment">
                <div class="col-lg-12">
                    <ul class="list-unstyled">
                        <li>
                            <hr class="my-4">
                            <h3 class="text-center">
                                Application Name: &nbsp;
                                <em class="text-capitalize font-weight-light">
                                    {{ application.name }}
                                </em>
                            </h3>
                            <br>
                            <h3 class="text-center">
                                Total Amount: &nbsp;
                                <em class="text-capitalize font-weight-light">
                                    {{ application.has_payment.amount | formatPrice }}
                                    NGN
                                </em>
                            </h3>

                            <div v-if="application.has_payment.has_breakdowns.length > 0">
                                <hr class="my-4">
                                <h1 class="text-center">
                                    Payment Details
                                </h1>
                                <hr class="my-4">
                                <h3
                                    v-for="breakdown in application.has_payment.has_breakdowns"
                                    :key="breakdown.id"
                                >
                                    {{ breakdown.name }}: &nbsp;
                                    <em class="text-capitalize font-weight-light right">
                                        {{ breakdown.amount | formatPrice }}
                                        NGN
                                    </em>
                                    <br>
                                </h3>
                            </div>
                            <hr class="my-4">
                            <h3>
                                Total: &nbsp;
                                <em class="text-capitalize font-weight-light right">
                                    {{ application.has_payment.amount | formatPrice }}
                                    NGN
                                </em>
                            </h3>

                            <hr class="my-4">

                            <h3 class="text-danger text-center"
                                v-if="showPaymentForm"
                            >
                                Note: If you want to generate RRR for this payment and pay at designated banks, kindly click on
                                <em class="text-dark">Black</em> button. However, if you have made payment already, you can add your
                                payment to the application by clicking on the <em class="text-primary">Purple</em> button.
                                <hr>
                                Note: When you Generate RRR, you are to proceed to the designated banks to make payment. After
                                succesful payment, click on the <em class="text-primary">I have Paid, Add RRR Now</em> button
                                to add your payment.
                            </h3>
                        </li>
                    </ul>
                </div>
                <div class="col-lg-12">
                    <hr class="my-4" v-if="showPaymentForm">

                    <form :action="remitaPaymentUrl" method="POST"
                        v-if="showPaymentForm"
                    >
                        <input
                            type="hidden"
                            v-for="item in paymentRecords"
                            :key="item.title"
                            :name="item.title"
                            :value="item.value"
                        />


                        <base-button
                            type="success"
                            nativeType="submit"
                        >
                            <i class="fa fa-credit-card"></i>
                            {{ isloading ? 'Please Wait...' : 'Pay Directly Online Now'}}
                        </base-button>

                        <div class="right">
                            <base-button
                                type="dark"
                                @click="addPayment(orderId, true)"
                            >
                                <i class="fa fa-history"></i>
                                Generate RRR &amp; Pay at the Bank
                            </base-button>

                            <base-button
                                type="primary"
                                @click="addPayment(orderId, false)"
                            >
                                <i class="fa fa-plus"></i>
                                I have Paid, Add RRR Now
                            </base-button>
                        </div>

                    </form>

                    <base-button
                        type="success"
                        @click="createApplication()"
                        v-if="! showPaymentForm"
                    >
                        <i class="fa fa-reply"></i>
                        {{ isloading ? 'Please Wait...' : 'Apply Now'}}
                    </base-button>
                </div>
            </div>
        </div>

        <AddPayment
            :orderNumber="orderNumber"
            :app_id="applicationId"
            :serviceType="serviceType"
            :applicationName="application.name"
            :applicationCode="application.code"
            :applicationAmount="application.has_payment.amount"
            v-on:removeAddPayment="removeAddPayment"
            :removeAddPayment="removeAddPayment"
            :isDirectPaymentPage="isDirectPaymentPage"
            :showGeneratePage="showGeneratePage"
            v-if="showAddPayment"
        />

    </div>

</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import AddPayment from './AddPayment.vue';
    import swal from 'sweetalert2';// import sweet alert

    export default {
        components: {
            AddPayment
        },
        name: 'ChangeNamePayment',
        data() {
            return {
                id: this.$route.params.id,
                application: null,
                isLoadingBlade: true,
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isloading: false,
                applicationId: null,
                orderId: null,
                serviceType: null,
                showAddPayment: false,
                user: {},
                orderNumber: null,
                showPaymentForm: false,
                isDirectPaymentPage: false,
                showGeneratePage: false
            }
        },
        filters: {
            formatPrice(value) {
                if(value) {
                    let val = (value/1).toFixed(2).replace(',', '.');
                    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                } else {
                    return 'N/A';
                }
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl', 'remitaPaymentUrl', 'remitaMerchantId',
                'remitaPaymentCallbackUrl', 'remitaApiKey']),
            gethash() {
                var sha512 = require('js-sha512');
                let text = this.remitaMerchantId+this.serviceType.remita_code+this.orderId
                    +this.application.has_payment.amount+this.remitaPaymentCallbackUrl
                    +this.remitaApiKey;
                return sha512(text);
            },
            paymentRecords() {// get payment records
                let fullName = this.user.basic_profile.first_name+' '+this.user.basic_profile.last_name;
                return [
                    {'title' : 'merchantId', 'value' : this.remitaMerchantId},
                    {'title' : 'serviceTypeId', 'value' : this.serviceType.remita_code},
                    {'title' : 'amt', 'value' : this.application.has_payment.amount},
                    {'title' : 'responseurl', 'value' : this.remitaPaymentCallbackUrl},
                    {'title' : 'hash', 'value' : this.gethash},
                    {'title' : 'payerName', 'value' : fullName},
                    {'title' : 'paymenttype', 'value' : ''},
                    {'title' : 'payerEmail', 'value' : this.user.email},
                    {'title' : 'payerPhone', 'value' : this.user.basic_profile.phone},
                    {'title' : 'orderId', 'value' : this.orderId},
                ]
            }
        },
        methods: {
            ...mapActions(['getChangeNameApplication', 'getUserApplicationDetails', 'updateTransNo']),
            loadApplication(status = false) {// get application
                // let userId = this.$store.state.user.userId;// get user id
                this.getChangeNameApplication().then((res) => {// get pending Change of Name
                    if(res.update) {
                        this.applicationId = res.record.application.id;
                        this.orderId = res.record.application.trans_no;
                        this.loadUserAppDetails();
                        ! status ? this.createApplication(this.applicationId) : this.isLoadingBlade = false;
                    } else {
                        return this.$router.push({ 'path' : '/applications'});
                    }                    
                }).
                catch(() => {
                    this.isLoadingBlade = false;
                    this.showErrorStatus = true;
                    this.errorMsg = "Network Failure!", "Kindly reload the page...";
                    this.errorType = 'danger';
                });
            },
            loadUserAppDetails() {
                this.getUserApplicationDetails(this.id).then((res) => {// get application
                    this.application = res.application;
                    this.serviceType = res.service_type;
                    this.user = res.user;
                    this.isLoadingBlade = false;
                }).
                catch(() => {
                    this.isLoadingBlade = false;
                    this.showErrorStatus = true;
                    this.errorMsg = "Network Failure!", "Kindly reload the page...";
                    this.errorType = 'danger';
                });
            },
            createApplication(id) {// get application details
                this.preloader();// show loading
                this.updateTransNo({'id' : id}).then((res) => {//update trans no
                    this.orderId = res.trans_no;
                    this.showPaymentForm = true;
                    swal.close();
                    // let message = 'Kindly click on Pay Now to make payment online';
                    // swal.fire('Application Updated!', message, 'success');
                }).
                catch(() => {
                    let message = 'Network Failure!, kindly reload the page...';
                    swal.fire('Network Failure!', message, 'warning');
                });
            },
            addPayment(number, status) {// add RRR
                this.orderNumber = number;
                this.showAddPayment = true;
                this.isDirectPaymentPage = true;
                this.showGeneratePage = status;
            },
            removeAddPayment(status = false) {// remove add payment
                this.loadApplication(status);// load application
                return this.showAddPayment = false;
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        created() {
            this.loadApplication();// load application
        },
    }
</script>

<style scoped>
.right {
    float: right;
}
</style>