<template>
    <div>
        <div v-if="! showRecieptPage">
            <!-- <hr class="my-4">  -->
            <h2
                class="mb-0 text-uppercase text-center"
            >
                Add Payment to Transaction with number <br>
                <small class="text-danger">
                    {{ orderNumber }}
                </small>
            </h2>
            <hr class="my-4">

            <h3
                class="mb-0 text-uppercase text-center"
            >
                Required Amount :
                <b class="text-danger">
                    {{ totalAmount | formatPrice }}
                </b>
            </h3>

            <hr class="my-4">

            <div v-if="! hideVerifyRRR">
                <h4 class="mb-0 text-danger">
                    Note: Read Carefully<br>
                    <ul>
                        <li>
                            <b class="text-primary">Generating RRR</b> - You can now generate RRR (Remita Retrieval
                            Reference) number and move to make payment offline through the designated banks. After
                            successful payment at the bank, you are advised to wait for 24 hours. Afterwards, you can now
                            proceed to add your payment to the portal. If you want to generate RRR for payment through designated
                            banks, kindly click on <strong class="text-primary">Generate RRR</strong> button.
                        </li><br>
                        <li>
                            <b class="text-primary">Adding Payment via RRR</b> - If you want to add payment to your
                            application via RRR, the RRR number must be a valid one (i.e. it must have to be paid and
                            not used before on the portal). Kindly click on
                            <strong class="text-primary">Add RRR</strong> button.
                        </li>
                    </ul>
                </h4>

                <hr class="my-4">
            </div>

            <div class="text-center" v-if="! hideVerifyRRR">
                <base-button
                    type="primary" size="lg"
                    @click="generatePayment()"
                >
                    <i class="fa fa-cogs"></i>
                    {{ isloading ? 'Please Wait' : 'Generate RRR' }}
                </base-button>

                <base-button
                    type="success" size="lg"
                    @click="showVerifyPage()"
                    :disabled="isloading"
                >
                    <i class="fa fa-credit-card"></i>
                    Add RRR
                </base-button>

                <base-button
                    type="danger" size="lg"
                    @click="removeAddPayment"
                >
                    <i class="fa fa-times"></i>
                    Cancel
                </base-button>
            </div>

            <div v-if="hideVerifyRRR">
                <h4 class="mb-0 text-danger">
                    Note: Kindly add all RRR payments that weren't generated from MDCN portal (i.e. RRR generated
                    from Remita Platform) on or before <em class="text-primary">31st August, 2020</em>. However,
                    RRR can now be generated from the portal and it is now only the valid means to generate payment
                    for MDCN portal, else any other means will not be accepted and will be forfeited
                </h4>

                <hr class="my-4">

                <div class="row">
                    <div class="col-md-3 text-center"></div>
                    <div class="col-md-6 text-center" v-if="! showPaymentBlade">
                        <base-input alternative=""
                            label="Valid RRR Number"
                            placeholder="Enter Valid RRR Number"
                            input-classes="form-control-alternative"
                            type="number"
                            v-model="rrr"
                        />
                    </div>
                    <div class="col-md-3 text-center"></div>
                </div>


                <div class="text-center" v-if="! showPaymentBlade">

                    <base-button
                        type="success" size="lg"
                        @click="verifyPayment()"
                    >
                        <i class="fa fa-history"></i>
                        {{ isloading ? 'Please Wait' : 'Verify RRR' }}
                    </base-button>

                    <base-button
                        type="danger" size="lg"
                        @click="removeAddPayment"
                    >
                        <i class="fa fa-times"></i>
                        Cancel
                    </base-button>
                </div>

                <div v-if="showPaymentBlade">
                    <h2 class="text-center">
                        Payment Details
                    </h2>

                    <hr class="my-4">

                    <h3 class="text-center">
                        Application Type: &nbsp;
                        <em class="text-capitalize font-weight-light">
                            {{ this.application.name }}
                        </em>
                    </h3>
                    <h3 class="text-center">
                        Amount: &nbsp;
                        <em class="text-capitalize font-weight-light">
                            {{ paymentDetails.amount | formatPrice }}
                        </em>
                    </h3>
                    <h3 class="text-center">
                        Transaction Number: &nbsp;
                        <em class="text-capitalize font-weight-light">
                            {{ orderNumber }}
                        </em>
                    </h3>
                    <h3 class="text-center">
                        RRR Number: &nbsp;
                        <em class="text-capitalize font-weight-light">
                            {{ paymentDetails.RRR }}
                        </em>
                    </h3>
                    <h3 class="text-center">
                        Payment Date: &nbsp;
                        <em class="font-weight-light">
                            {{ paymentDetails.paymentDate | getDateTimeFormat }}
                        </em>
                    </h3>

                    <hr class="my-4">

                    <div class="text-center">
                        <base-button
                            type="success" size="lg"
                            @click="updatePayment()"
                        >
                            <i class="fa fa-credit-card"></i>
                            {{ isloading ? 'Please Wait' : 'Add Payment' }}
                        </base-button>
                        <base-button
                            type="danger" size="lg"
                            @click="removeAddPayment"
                        >
                            <i class="fa fa-times"></i>
                            Cancel
                        </base-button>
                    </div>
                </div>
            </div>
        </div>

        <!-- generate RRR details template here -->
        <GeneratedRrr
            v-bind:record="generatedRrr"
            v-on:removeAddPayment="removeGenratePayment"
            v-if="showRecieptPage && generatedRrr"
        />
    </div>

</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import moment from 'moment';
    import GeneratedRrr from '../../../Application/Payments/PaymentDetails/GeneratedRrr.vue';
    import swal from 'sweetalert2';// import sweet alert
    import axios from 'axios';

    export default {
        name: 'AddPayment',
        props: ["orderNumber", "application", "loadApplication", "serviceType", "app_id", "removeAddPayment", 
                "totalAmount"],
        components: {
            GeneratedRrr
        },
        data() {
            return {
                rrr: null,
                isloading: false,
                showPaymentBlade: false,
                paymentDetails: {},
                generate: {
                    service_type_id: null,
                    app_id: null,
                    amount: null,
                    user_data_id: null,
                    description: null,
                },
                hideVerifyRRR: false,
                showRecieptPage: false,
                generatedRrr: null
            }
        },
        filters: {
            getDateTimeFormat(value) {
                if(value) {
                    return moment(value).format('Do MMM, YYYY [at] h:mm:ss a');
                } else {
                    return 'N/A';
                }
            },
            formatPrice(value) {
                if(value) {
                    let val = (value/1).toFixed(2).replace(',', '.');
                    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")+ ' NGN';
                } else {
                    return 'N/A';
                }
            }
        },
        computed: {
            ...mapGetters(['user'])
        },
        methods: {
            ...mapActions(['getRemitaPaymentStatus', 'updateApplicationTransaction', 'generatePaymentRRR']),
            removeGenratePayment() {
                this.showRecieptPage = false;
            },
            showVerifyPage() {
                return this.hideVerifyRRR = ! this.hideVerifyRRR;
            },
            verifyPayment() {
                this.preloader();// show loading
                this.isloading = true;
                let status = this.verifyRrrNumber();
                // console.log(status); 160352882748    250332191070  330204213948
                if(status) {
                    let data = { 
                        rrr : this.rrr, code : this.application.code, 
                        user_data_id : this.user.basic_profile.id,
                        is_url : true
                    };
                    this.getRemitaPaymentStatus(data).then((res) => {// create
                        if(res.status) {// update transaction
                            this.verifyThroughRemita(res.url);
                        } else {
                            swal.fire('Payment Failed!', res.msg, 'warning');
                        }
                    }).
                    catch(() => {
                        this.isloading = false;
                        let message = 'An error while verying payment, kindly reload and try again';
                        swal.fire('Network Failure!', message, 'warning');
                    });
                }
            },
            verifyThroughRemita(url) {
                axios.get(url).then((res) => {
                    this.isloading = false;
                    if(res.status == 200 && res.data) {
                        let check = res.data;
                        if((check.status == '00') || (check.status == '01')) {
                            this.paymentDetails = res.data;
                            this.showPaymentBlade = true;
                            swal.close();
                        } else {
                            let msg = 'Payment was not successful, kindly enter a valid RRR';
                            swal.fire('Payment Failed!', msg, 'warning');
                        }                        
                    }
                })
                .catch(() => {//check for failure
                    this.isloading = false;
                    let msg = 'Payment was not verified, kindly try again';
                    swal.fire('Payment Verfication Failed!', msg, 'warning');
                });
            },
            verifyRrrNumber() {
                if(this.rrr && this.rrr.length > 10) {
                    return true;
                } else if(this.rrr && this.rrr.length < 10) {
                    swal.fire("Incomplete RRR!", "Kindly check and enter a valid RRR", "warning");
                    return false;
                } else {
                    swal.fire("No RRR!", "Kindly enter a valid RRR, and try again", "warning");
                    return false;
                }
            },
            updatePayment() {// update transaction
                this.isloading = true;
                let data = this.paymentDetails;
                data.orderId = this.orderNumber;// add trans no
                // console.log(data);
                if(this.totalAmount <= data.amount) {// add payment
                    this.preloader();// show loading
                    this.updateApplicationTransaction(data).then((res) => {// create
                    // console.log(res);
                        this.isloading = false;
                        if(res.status) {// update transaction
                            swal.fire('Payment Added!', res.msg, 'success');
                            this.loadApplication();
                        } else {
                            swal.fire('Payment Failed!', res.msg, 'warning');
                        }
                    }).
                    catch(() => {
                        this.isloading = false;
                        let message = 'An error while adding payment, kindly reload and try again';
                        swal.fire('Network Failure!', message, 'warning');
                    });
                } else {
                    this.isloading = false;
                    let message = 'Amount on the RRR is less than the required amount';
                    swal.fire('Invalid Amount!', message, 'warning');
                }
            },
            generatePayment() {// generate RRR payment
                this.showRecieptPage = false;
                this.generatedRrr = null;
                this.emptyGenerateRRR();
                let data = this.generate;
                this.isloading = true;
                data.service_type_id = this.serviceType.id;
                data.app_id = this.app_id;
                data.amount = this.totalAmount;
                data.user_data_id = this.user && this.user.basic_profile ? this.user.basic_profile.id : null;
                data.description = this.application.name ? 'Payment for '+ this.application.name : null;
                // console.log(data);
                if(this.isNotEmpty(data)) {
                    this.preloader();
                        this.generatePaymentRRR(data).then((res) => {// generate RRR
                        // console.log(res);
                        swal.close();// dismiss loading
                        this.isloading = false;
                        if(res.status) {
                            this.generatedRrr = res.rrr;
                            this.showRecieptPage = true;
                            this.showAlert(res.msg, 'success');
                        } else {
                            this.showAlert(res.msg, 'warning');
                        }
                    }).
                    catch(() => {
                        swal.close();// dismiss loading
                        this.isloading = false;
                        let type = 'warning';
                        let msg = 'An error occurred while generating RRR, kindly refresh page';
                        this.showAlert(msg, type);
                    });
                } else {
                    this.isloading = false;
                    let type = 'warning';
                    let msg = 'An error occurred while generating RRR, kindly refresh page';
                    this.showAlert(msg, type);
                }
            },
            isNotEmpty(obj) {// check for empty field
                return ! Object.keys(obj).some(k => !obj[k]);
            },
            emptyGenerateRRR() {// emtpy generate rrr
                this.generate.service_type_id = null;
                this.generate.app_id = null;
                this.generate.amount = null;
                this.generate.user_data_id = null;
                this.generate.description = null;
            },
            showAlert(msg, type) {
                this.$notify({
                    type: type,
                    title: msg
                })
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
    }
</script>

<style scoped>
.right {
    float: right;
}
</style>