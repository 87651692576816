<template>
    <div>
        <div class="row" v-if="doctor">

            <div class="col-lg-12 text-center">
                <h2 class="mb-0 text-uppercase">Docotr's Information</h2>
                <hr class="my-4" />
            </div>

            <div class="col-lg-12">
                <h3 class="mb-0 text-uppercase">Personal Details</h3>
                <hr class="my-4" />
            </div>

            <div class="col-lg-12 text-right">
                <img :src="doctor.passport" class="photo-class"/>
                <br><br>
            </div>

            <div class="table-responsive">

                <table class="table table-bordered">
                    <tbody>
                        <td>
                            <h4>Full Name</h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ doctor.full_name ? doctor.full_name : 'N/A' }}
                            </h4>
                        </td>
                        <td>
                            <h4>Folio Number</h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ doctor.folio_number ? doctor.folio_number : 'N/A' }}
                            </h4>
                        </td>
                        <td>
                            <h4>Specialty</h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ doctor.specialty ? doctor.specialty.name : 'N/A' }}
                            </h4>
                        </td>
                    </tbody>
                    <tbody>
                        <td>
                            <h4>Sub Specialty</h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ doctor.sub_specialty ? doctor.sub_specialty.name : 'N/A' }}
                            </h4>
                        </td>
                        <td>
                            <h4>Practice Type</h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ doctor.practice_type ? doctor.practice_type.name : 'N/A' }}
                            </h4>
                        </td>
                        <td>
                            <h4>Registration Type</h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ doctor.registration_type ? doctor.registration_type.name : 'N/A' }}
                            </h4>
                        </td>
                    </tbody>
                    <tbody>
                        <td>
                            <h4>Registration Status</h4>
                        </td>
                        <td>
                            <badge :type="(doctor.is_sanctioned ? 'danger' : 'success')">
                                {{ doctor.is_sanctioned ? 'Sanctioned' : 'Not Sanctioned' }}
                            </badge>
                        </td>
                    </tbody>
                </table>
            </div>
            <div class="col-lg-12">
                <hr class="my-4" />
            </div>
            <div class="col-lg-12">
                <h3 class="mb-0 text-uppercase">Schools Attended</h3>
                <hr class="my-4" />
            </div>

            <div class="table-responsive" v-if="doctor && doctor.schools.length > 0">
                <table class="table table-bordered">
                    <thead class="text-uppercase">
                        <td>
                            <h4>S/N</h4>
                        </td>
                        <td>
                            <h4>School Type</h4>
                        </td>
                        <td>
                            <h4>School Name</h4>
                        </td>
                        <td>
                            <h4>Qualification</h4>
                        </td>
                        <td>
                            <h4>Start Date</h4>
                        </td>
                        <td>
                            <h4>End Date</h4>
                        </td>
                    </thead>
                    <tbody
                        v-for="(record , index) in doctor.schools"
                        :key="record.id"
                    >
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ index+1 }}
                            </h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ replaceString(record.school_type) }}
                            </h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ record.school_name }}
                            </h4>
                        </td>
                        <td>
                            <h4 class="font-weight-light">
                                {{ record.qualifications_obtained ? record.qualifications_obtained : 'N/A' }}
                            </h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ record.start_date | getDateFormat }}
                            </h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ record.end_date | getDateFormat }}
                            </h4>
                        </td>
                    </tbody>
                </table>
            </div>

            <div class="col-lg-12 text-center">
                <hr>
                <h3 class="text-danger">
                    For more infromation contact MDCN via
                    <em class="text-primary">registration@mdcn.gov.ng</em> to request for
                    verification of the doctor.
                </h3>
            </div>

            <div class="col-lg-12 text-center">
                <hr>
                <router-link
                    to="/confirm-doctor-status"
                    class="btn btn-danger"
                >
                    <i class="fa fa-reply"></i>
                    Go Back
                </router-link>
                <!-- <base-button
                    type="danger"
                >
                    <i class="fa fa-reply"></i>
                    Go Back
                </base-button> -->
            </div>

        </div>
    </div>
</template>

<script>
    import moment from 'moment';
import Badge from '../../components/Badge.vue';

    export default {
  components: { Badge },
        name: 'ShowDoctorData',
        props: [ 'doctor' ],
        filters: {
            getDateFormat(value) {
                return value ? moment(value).format('Do MMM, YYYY') : 'N/A';
            }
        },
        methods: {
            replaceString(value) {
                return value.replace("-", " ");
            }
        }
    }
</script>

<style scoped>
    .no-padding-bottom {
        padding-bottom: 0rem !important
    }
    .right {
        float: right !important;
    }
    .photo-class {
        width: 200px;
        height: 200px;
    }
</style>

<style>
    .form-control-label {
        width: 100%;
    }
    .height-50 {
        height: 50px;
    }
    .borderLine {
        border: 1px solid #ededed;
    }
</style>