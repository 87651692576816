<template>
    <div>
        <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center no-padding-bottom">
            <span class="mask bg-gradient-danger opacity-8"></span>
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col-xl-12 order-xl-1">
                    <card shadow type="secondary">
                        <div slot="header" class="bg-white border-0">
                            <div class="row align-items-center">
                                <div class="col-8">
                                    <h3 class="mb-0 text-uppercase">{{ this.$route.name }}</h3>
                                    <br>
                                    <span class="text-danger">
                                        Note that these Doctors below are under sanction.
                                    </span>
                                </div>
                                <div class="col-4 text-right">
                                    <a :href="websiteUrl" class="btn btn-sm btn-danger">
                                       <i class="fa fa-reply"></i> Home
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-12" v-if="isLoadingBlade">
                            <div class="d-flex justify-content-center">
                                <img :src="loadingBladeUrl" />
                            </div>
                        </div>

                        <div class="row" v-if="! isLoadingBlade">
                            <div class="col-lg-2">
                                <base-input alternative="">
                                    <select
                                        class="form-control form-control-alternative"
                                        v-model="page"
                                        @change="getPageItems()"
                                    >
                                        <option
                                            v-for="number in pageNumbers"
                                            :key="number"
                                            :value="number"
                                        >
                                            {{ number }}
                                        </option>
                                    </select>
                                </base-input>
                            </div>
                            <div class="col-lg-4"></div>
                            <div class="col-lg-6 text-right">
                                <base-input
                                    placeholder="Search by Name/Folio Number and hit enter"
                                    class="input-group-alternative"
                                    alternative=""
                                    addon-right-icon="fas fa-search"
                                    @change="searchRecords"
                                    v-model="searchKey"
                                    >
                                </base-input>
                            </div>
                        </div>

                        <!-- import Sanctions Data template here -->
                        <SanctionsData
                            v-bind:records="records"
                            v-bind:hasPagination="hasPagination"
                            v-bind:pagination="pagination"
                            v-bind:paginate="paginate"
                        />

                        <div
                            class="col-sm-12 text-center"
                            v-if="showErrorStatus"
                        >
                            <base-alert :type="errorType">
                                <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                                <span class="alert-inner--text">
                                    {{errorMsg}}
                                </span>
                            </base-alert>
                        </div>

                    </card>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import SanctionsData from './SanctionsData.vue';

    export default {
        components: {
            SanctionsData
        },
        name: 'Sanctions',
        data() {
            return {
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isLoadingBlade: true,
                records: [],
                paginatedItems: [],
                pagination: {
                    total: null,
                    prev_page_url: null,
                    next_page_url: null,
                    current_page: null,
                    last_page: null,
                    number: 1,
                    from: 0,
                    to: null,
                    // perPage: 1  // get per page count
                    perPage: this.$store.state.url.perPage // get per page count
                },
                page: 1,
                searchKey: ''
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl', 'websiteUrl']),
            hasPagination() {
                return this.pagination.next_page_url || this.pagination.prev_page_url;
            },
            pageNumbers() {
                let start = 1;
                let end = this.pagination.last_page;
                let pages = [];
                for (let i = start; i <= end; i++ ) {
                    pages.push(i);
                }
                return pages;
            }
        },
        methods: {
            ...mapActions(['getSanctions', 'getPaginatedSanctions', 'getSearchedSanctions']),
            loadSanctions() {// load details
                let data = { 'limit' : this.pagination.perPage, type: 'public' };
                this.isLoadingBlade = true;
                this.showErrorStatus = false;
                this.getSanctions(data).then((res) => {// get users
                    this.isLoadingBlade = false;
                    this.setPages(res.sanctions);//set data
                }).
                catch(() => {
                    this.records = [];
                    this.showErrorStatus = true;
                    this.isLoadingBlade = false;
                    this.errorMsg = "Network Failure!, Kindly reload the page...";
                    this.errorType = 'danger';
                });
            },
            setPages(res) {// set paginated function
                this.records = [];
                this.paginatedItems = res.data;
                this.records = this.paginatedItems;
                this.pagination.from = res.from;
                this.pagination.to = res.to;
                this.pagination.total = res.total;
                this.pagination.current_page = res.current_page;
                this.pagination.last_page = res.last_page;
                this.pagination.prev_page_url = res.prev_page_url;
                this.pagination.next_page_url = res.next_page_url;
                this.isLoadingBlade = false;
                this.emptyRecordsMsg();//show empty msg
            },
            paginate() {// paginate function
                this.showErrorStatus = false;
                let url = (this.pagination.number > this.pagination.current_page) ?
                    this.pagination.next_page_url : this.pagination.prev_page_url;
                if(url) {// make api call
                    this.isLoadingBlade = true
                    this.getPaginatedSanctions(url).then((res) => {// get users
                        this.pagination.number = res.sanctions.current_page;
                        this.setPages(res.sanctions);
                    }).
                    catch(() => {
                        this.users = [];
                        this.showErrorStatus = true;
                        this.isLoadingBlade = false;
                        this.errorMsg = "Network Failure!, Kindly reload the page...";
                        this.errorType = 'danger';
                    });
                } else {
                    this.records = this.paginatedItems;
                }
            },
            emptyRecordsMsg() {
                return this.records && this.records.length == 0 ? this.showEmptyMsg() : false;
            },
            showEmptyMsg() {// show empty msg
                this.showErrorStatus = true;
                this.errorType = 'warning';
                this.errorMsg = 'No Sanctions recorded yet!!!';
            },
            getPageItems() {
                let url = this.pagination.next_page_url ?
                            this.pagination.next_page_url : this.pagination.prev_page_url;
                let exploded = url.split('&');
                let newUrl = exploded[0]+'&'+exploded[1]+'&'+exploded[2]+'&page='+this.page;
                this.isLoadingBlade = true
                this.getPaginatedSanctions(newUrl).then((res) => {// get records
                    this.pagination.number = res.sanctions.current_page;
                    this.setPages(res.sanctions);
                }).
                catch(() => {
                    this.records = [];
                    this.showErrorStatus = true;
                    this.isLoadingBlade = false;
                    this.errorMsg = "Network Failure!, Kindly reload the page...";
                    this.errorType = 'danger';
                });
            },
            searchRecords() {
                this.showErrorStatus = false;
                let key = this.searchKey.toLowerCase();
                if(key != '' ) {
                    this.isLoadingBlade = true;
                    let data = { 'key' : key };
                    this.records = [];
                    this.getSearchedSanctions(data).then((res) => {// get records
                        this.isLoadingBlade = false;
                        this.records = res;
                        if(this.records.length == 0) {
                            this.showErrorStatus = true;
                            this.errorType = 'warning';
                            this.errorMsg = 'No Matched Record!!!';
                        }
                    }).
                    catch(() => {
                        this.showErrorStatus = true;
                        this.isLoadingBlade = false;
                        this.errorMsg = "Network Failure!, Kindly reload the page...";
                        this.errorType = 'danger';
                    });
                } else {
                    this.records = this.paginatedItems;
                    this.emptyRecordsMsg();
                }
            }
        },
        mounted() {
            this.loadSanctions();
        }
    }
</script>

<style scoped>
    .no-padding-bottom {
        padding-bottom: 0rem !important
    }
</style>