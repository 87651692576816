<template>
    <div>
        <div class="col-lg-12" v-if="isLoadingBlade">
            <div class="d-flex justify-content-center">
                <img :src="loadingBladeUrl" />
            </div>
        </div>

        <div v-if="! isLoadingBlade">

            <hr class="my-4" v-if="! showAddPayment">

            <h2
                class="mb-0 text-uppercase text-center"
                v-if="! showAddPayment"
            >
                Make Payment for
                <em class="text-danger">
                    NMA Building Levy
                </em>
            </h2>



            <hr class="my-4">

            <!-- Import Registration payment data blade here -->
            <NMALevyData
                v-bind:records="records"
                v-on:reQueryTransaction="reQueryTransaction"
                v-if="! isLoadingBlade && showPaidBlade && ! showNewPayment"
            />

            <div class="text-center"
                v-if="! isLoadingBlade && showPaidBlade && ! showAddPayment"
            >
                <base-button
                    :type="showNewPayment ? 'danger' : 'primary'"
                    @click="showNewPaymentBlade()"
                >
                    <i class="fa fa-arrow-down" v-if="! showNewPayment"></i>
                    <i class="fa fa-times" v-if="showNewPayment"></i>
                    {{ showNewPayment ? 'Close New Payment' : 'Click Here for New Payment' }}
                </base-button>
            </div>

            <div class="container ct-example-row" v-if="! isLoadingBlade && ! showAddPayment">
                <div class="row">

                    <div class="col">
                        <ul
                            class="list-unstyled"
                            v-if="application && application.has_payment && showNewPayment"
                        >
                            <li>
                                <hr class="my-4">
                                <h3 class="text-center">
                                    Application Name: &nbsp;
                                    <em class="text-capitalize font-weight-light">
                                        {{ application.name }}
                                    </em>
                                </h3>
                                <br>
                                <h3 class="text-center">
                                    Total Amount: &nbsp;
                                    <em class="text-capitalize font-weight-light">
                                        {{ application.has_payment.amount | formatPrice }}
                                    </em>
                                </h3>

                                <div v-if="application && application.has_payment && application.has_payment.has_breakdowns
                                            && application.has_payment.has_breakdowns.length > 0"
                                >
                                    <hr class="my-4">
                                    <h1 class="text-center">
                                        Payment Breakdowns
                                    </h1>
                                    <hr class="my-4">
                                    <h3
                                        v-for="breakdown in application.has_payment.has_breakdowns"
                                        :key="breakdown.id"
                                    >
                                        {{ breakdown.name }}: &nbsp;
                                        <em class="text-capitalize font-weight-light right">
                                            {{ breakdown.amount | formatPrice }}
                                            </em>
                                        <br>
                                    </h3>

                                    <hr class="my-4">
                                    <h3>
                                        Total: &nbsp;
                                        <em class="text-capitalize font-weight-light right">
                                            {{ application.has_payment.amount | formatPrice }}
                                        </em>
                                    </h3>
                                </div>
                                <hr class="my-4">


                                <div class="col-sm-12 text-center">
                                    <base-alert :type="errorType" v-if='showErrorStatus'>
                                        <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                                        <span class="alert-inner--text">
                                            {{ errorMsg }}
                                        </span>
                                    </base-alert>
                                </div>

                                <form
                                    :action="remitaPaymentUrl" method="POST"
                                    v-if="showPaymentForm"
                                >
                                    <input
                                        type="hidden"
                                        v-for="(item, index) in paymentRecords"
                                        :key="index"
                                        :name="item.title"
                                        :value="item.value"
                                    />


                                    <base-button
                                        type="success"
                                        nativeType="submit"
                                    >
                                        <i class="fa fa-credit-card"></i>
                                        {{ isloading ? 'Please Wait...' : 'Pay Now'}}
                                    </base-button>

                                    <div class="right">
                                        <base-button
                                            type="primary"
                                            @click="addPayment()"
                                        >
                                            <i class="fa fa-plus"></i>
                                            Pay Via RRR
                                        </base-button>
                                    </div>

                                </form>
                                <base-button
                                    type="success"
                                    @click="createApplication()"
                                    v-if="! showPaymentForm"
                                >
                                    <i class="fa fa-reply"></i>
                                    {{ isloading ? 'Please Wait...' : 'Apply Now'}}
                                </base-button>
                            </li>
                        </ul>
                    </div>

                    <div class="col-lg-12" v-if="! showPaidBlade">
                        <div class="d-flex justify-content-center">
                            <img :src="completeImageUrl" />

                        </div>
                        <br>
                        <h2 class="text-uppercase text-center">
                            You have paid for NMA Building Levy
                        </h2>
                        <h3 class="text-center text-danger">
                            Note Kindly click on Next button to continue registration
                        </h3>
                    </div>

                </div>
            </div>

            <AddPayment
                v-bind:orderNumber="orderId"
                v-bind:app_id="id"
                v-bind:serviceType="serviceType"
                v-bind:application="application"
                v-bind:loadApplication="loadNMALevy"
                :removeAddPayment="removeAddPayment"
                v-if="! isLoadingBlade && showAddPayment"
            />

            <hr class="my-4"/>

            <div class="pl-lg-4">
                <base-button
                    type="danger"
                    @click="showPreviousPage()"
                >
                    <i class="fa fa-arrow-left"></i>
                    Previous
                </base-button>
                <div class="right" v-if="! showPaidBlade">
                    <base-button
                        type="success"
                        @click="showNextPage()"
                    >
                        Next
                        <i class="fa fa-arrow-right"></i>
                    </base-button>
                </div>
            </div>
        </div>

    </div>

</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import moment from 'moment';
    import AddPayment from './AddPayment';
    import NMALevyData from './NMALevyData';
    import swal from 'sweetalert2';// import sweet alert

    export default {
        name: 'NMALevy',
        components: {
            AddPayment,
            NMALevyData
        },
        props: ['displayAddQual', 'displayReg'],
        data() {
            return {
                application: null,
                records: [],
                user: null,
                serviceType: null,
                isLoadingBlade: true,
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isloading: false,
                showPaidBlade: false,
                showPaymentForm: false,
                orderId: null,
                showAddPayment: false,
                id: null,
                showNewPayment: false,
            }
        },
        filters: {
            formatPrice(value) {
                if(value) {
                    let val = (value/1).toFixed(2).replace(',', '.');
                    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")+ ' NGN';
                } else {
                    return 'N/A';
                }
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl', 'remitaPaymentUrl', 'remitaMerchantId',
                'remitaPaymentCallbackUrl', 'remitaApiKey', 'completeImageUrl', 'loadingBladeUrl']),
            gethash() {
                var sha512 = require('js-sha512');
                let text = this.remitaMerchantId+this.serviceType.remita_code+this.orderId
                    +this.application.has_payment.amount+this.remitaPaymentCallbackUrl+this.remitaApiKey;
                return sha512(text);
            },
            paymentRecords() {// get payment records
                let fullName = this.user.basic_profile.first_name+' '+this.user.basic_profile.last_name;
                return [
                    {'title' : 'merchantId', 'value' : this.remitaMerchantId},
                    {'title' : 'serviceTypeId', 'value' : this.serviceType.remita_code},
                    {'title' : 'amt', 'value' : this.application.has_payment.amount},
                    {'title' : 'responseurl', 'value' : this.remitaPaymentCallbackUrl},
                    {'title' : 'hash', 'value' : this.gethash},
                    {'title' : 'payerName', 'value' : fullName},
                    {'title' : 'paymenttype', 'value' : ''},
                    {'title' : 'payerEmail', 'value' : this.user.email},
                    {'title' : 'payerPhone', 'value' : this.user.basic_profile.phone},
                    {'title' : 'orderId', 'value' : this.orderId},
                ]
            }
        },
        methods: {
            ...mapActions(['createNewUserApplication', 'getOtherPayment', 'updateTransNo',
                            'getRemitaOrderPaymentStatus']),
            loadNMALevy() {
                this.getOtherPayment().then((res) => {
                    this.records = [];
                    this.showAddPayment = false;
                    this.isLoadingBlade = false;
                    this.showPaidBlade = res.status;
                    this.application = res.application;
                    if(res.previous_application) {
                        this.records.push(res.previous_application);// add previous applications
                        this.orderId = res.previous_application.trans_no; // app trans no
                        this.id = res.previous_application.id;// application id
                    }
                    this.user = res.user;
                    this.serviceType = res.serviceType;
                }).
                catch(() => {
                    this.isLoadingBlade = false;
                    this.showErrorStatus = true;
                    this.errorMsg = "Network Failure!, kindly reload the page...";
                    this.errorType = 'danger';
                });
            },
            showNewPaymentBlade() {// show new payemnt template
                this.showNewPayment = ! this.showNewPayment;
            },
            showPreviousPage() {// go to next page
                return this.displayAddQual(true);
            },
            showNextPage() {// go to next page
                return this.displayReg(true);
            },
            removeAddPayment() {// remove add payment
                return this.showAddPayment = false;
            },
            addPayment() {// add RRR
                this.showAddPayment = true;
            },
            showMsg(type, msg) {
                this.showErrorStatus = true;
                this.errorType = type;
                this.errorMsg = msg;
                var self = this;
                setTimeout(function() { self.showErrorStatus = false; }, 5000);
            },
            createApplication() {// create application
                this.selectedApp = null;
                this.preloader();// show loading
                if(this.orderId) {
                    this.updateTransNo({'id' : this.id}).then((res) => {//update trans no
                        this.loadNMALevy(); // reload page
                        this.orderId = res.trans_no;
                        this.showPaymentForm = true;
                        let message = 'Kindly click on Pay Now to make payment online';
                        swal.fire('Application Updated!', message, 'success');
                    }).
                    catch(() => {
                        let message = 'Network Failure!, kindly reload the page...';
                        swal.fire('Network Failure!', message, 'warning');
                    });
                } else {
                     return this.createNewApplication(); // create new application
                }
            },
            createNewApplication() {
                let data = {};
                data.type = this.applicationType;// get exact application type
                data.user_data_id = this.$store.state.user.userDataId;// get user data id
                data.application_type_id = this.application.id;// apllication type id
                data.trans_no = this.genRandomString();// generate trans no
                data.application_date = this.getCurrentDate();// get current date
                this.createNewUserApplication(data).then((res) => {// create application
                    if(res.status) {// update transaction
                        this.loadNMALevy(); // reload page
                        this.showPaymentForm = true;
                        this.orderId = data.trans_no;
                        let message = 'Kindly click on Pay Now to make payment online';
                        swal.fire('Application Updated!', message, 'success');
                    }
                }).
                catch(() => {
                    let message = 'While creating application, kindly reload and try again';
                    swal.fire('Error ocurred', message, 'warning');
                    return false;
                });
            },
            getCurrentDate() {// get current date time
                let date = new Date().toJSON().slice(0,10).replace(/-/g,'-');
                return moment(date).format('YYYY-M-D');
            },
            genRandomString() {
                var length = 12;
                var text = '';
                var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
                for (var i=0; i < length; i++) {
                    text += possible.charAt(Math.floor(Math.random() * possible.length));
                }
                return text;
            },
            reQueryTransaction(number) {
                this.preloader();// show loading
                this.getRemitaOrderPaymentStatus(number).then((res) => {// save transaction
                    if(res.status) {
                        swal.fire('Payment Successful', res.msg, 'success');
                    } else {
                        swal.fire('Payment Failed', res.msg, 'warning');
                    }
                    this.loadNMALevy();// load application
                }).
                catch(() => {
                    let message = 'Network Failure, kindly reload page and try again';
                    swal.fire('Error ocurred', message, 'warning');
                    this.loadNMALevy();// load application
                });
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        created() {
            this.loadNMALevy();// get nma levy
        },
    }
</script>

<style scoped>
.right {
    float: right;
}
</style>