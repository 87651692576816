<template>
    <div class="row"> 

        <div class="col-lg-12" v-if="isLoadingBlade">
            <div class="d-flex justify-content-center">
                <img :src="loadingBladeUrl" /> 
            </div>
        </div> 
        
        <div class="container" v-if='! showErrorStatus && ! isLoadingBlade'>
            <div class="row">

                <div class="col-lg-12">
                    <hr>
                    <h4 class="heading text-muted mb-4">
                        <!-- Upload Files &nbsp;&nbsp;&nbsp;&nbsp; -->
                        <span class="text-danger">
                            <i class="fa fa-info-circle"></i> Only PDF Files (Not More than 800KB) are supported for each file
                        </span>            
                    </h4>
                </div>

                <div 
                    class="col-sm-6 text-center borderLine" 
                    v-for="(document, index) in documents"
                    :key="index"
                >
                    <br>
                    <input 
                        type="file" 
                        id="file" style="display: none"
                        @change="onFileSelected"
                    >
                    <h4 class="text-capitalize height-50">
                        {{document.name}}
                    </h4><br>
                    <base-progress 
                        v-if="loadPercent.show && (selectedFileName == document.filename)"
                        :type="loadPercent.type" 
                        :height="10" 
                        :value="loadPercent.value"
                        :striped="true"
                        :animated="loadPercent.animated"
                    >
                    </base-progress>
                    <base-button 
                        size="sm" type="primary" 
                        v-if="! document.hasFile && (selectedFileName != document.filename)"
                        @click="selectFile(document.filename)"
                        :disabled="showReSendButton"
                    >
                        <i class="fa fa-hand-pointer"></i> Select File
                    </base-button>
                    <base-button 
                        tag="a" type="primary" 
                        size="sm" 
                        v-if="document.hasFile && ! showUploadButton || 
                            ((selectedFileName != document.filename) && document.hasFile)"
                        :href="document.url"
                        class="active" role="button" 
                        aria-pressed="true"
                        target="_blank"
                    >
                        <i class="fa fa-eye"></i> View File
                    </base-button>
                    <base-button 
                        size="sm" type="warning" 
                        v-if="document.hasFile && ! showUploadButton || 
                            ((selectedFileName != document.filename) && document.hasFile)"
                        @click="selectFile(document.filename)"
                        :disabled="showReSendButton"
                    >
                        <i class="fa fa-pen"></i> Change File
                    </base-button>
                    <base-button 
                        size="sm" type="success" 
                        v-if="showUploadButton && (selectedFileName == document.filename) && ! loadPercent.show"
                        @click="onUpload()"
                        :disabled="showReSendButton"
                    >
                        <i class="fa fa-upload"></i> Upload File
                    </base-button>
                    <base-button 
                        size="sm" type="danger" 
                        v-if="showUploadButton && (selectedFileName == document.filename) && ! loadPercent.show"
                        @click="removeFile()"
                    >
                        <i class="fa fa-times"></i> Cancel
                    </base-button>            
                    <br><br>                   
                </div>
            
            </div>  
            <hr class="my-4"/>
        </div>

        <div class="col-sm-12 text-center">
            <base-alert :type="errorType" v-if='showInputErrorStatus'>
                <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                <span class="alert-inner--text">
                    {{errorMsg}}
                </span>
            </base-alert>
        </div>  

        
        <div class="col-lg-12" v-if='! showErrorStatus && ! isLoadingBlade'>
            <br>
            <base-button 
                type="success"
                @click="proceedToPayment()"
                v-if="! showReSendButton"
                :disabled="! hasCompleteDocuments"
            >
                <i class="fa fa-save"></i> 
                {{isloading ? 'Please Wait...' : 'Save & Continue'}}
            </base-button>

            <base-button 
                type="success"
                @click="reSendApplication(applicationId)"
                v-if="showReSendButton"
            >
                <i class="fa fa-upload"></i> 
                Re-send Application
            </base-button>

            <div class="right">
                <base-button 
                    type="danger"
                    @click="editApplication()"
                    v-if="showReSendButton"
                >
                    <i class="fa fa-pen"></i> 
                    Edit Application
                </base-button>
            </div>
        </div>    

        <div class="col-sm-12 text-center">
            <base-alert :type="errorType" v-if='showErrorStatus'>
                <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                <span class="alert-inner--text">
                    {{errorMsg}}
                </span>
            </base-alert>
        </div>

    </div>
    
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import swal from 'sweetalert2';// import sweet alert

    export default {
        name: 'ApplyRevalidation',
        data() {
            return {
                id: this.$route.params.id,
                record: {
                    id: null,
                    user_data_id: null,
                    update: false
                },
                showUploadButton: false,
                savedEvent: null,
                uploadedFile: {
                    name : null,
                    file : null,
                    id : null,
                    app_id : null
                },
                selectedFileName: null,
                loadPercent: {
                    type: 'warning',
                    value: 10,
                    animated: true,
                    show: false
                },
                documents: [],
                showErrorStatus: false,
                showInputErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isloading: false,
                isLoadingBlade: true,
                updateStatus: false,
                isRejected: false,
                showReSendButton: false,
                applicationId: null,
                fileName: null
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl']),
            hasCompleteDocuments() {// check if all documents has been uploaded
                let status = [
                    this.documents[0].hasFile, this.documents[1].hasFile
                ];
                let checker = arr => arr.every(v => v === true);
                return checker(status);
            }
        },
        methods: {
            ...mapActions(['getRevalidationApplication', 'getApplication', 'uploadRevalidationDocuments', 
                'reSubmitApplication']),
            loadApplication() {
                if(this.id) {// load exact application
                     this.getExactApplication(this.id); 
                } else {// load any pending application
                    return this.loadPendingApplication();
                }
            },
            getExactApplication(id) {// load exact application
                this.getApplication({'id' : id}).then((res) => {
                    this.applicationId = res.id;
                    this.documents = res.revalidation_file ? res.revalidation_file.documents : [];
                    this.record.update = res.revalidation_file ? res.update : false;
                    this.isRejected = res.status == 'rejected' ? true : false;
                    this.isLoadingBlade = false;
                }).
                catch(() => {
                    this.isLoadingBlade = false;
                    this.showErrorStatus = true;
                    this.errorMsg = "Network Failure!", "Kindly reload the page...";
                    this.errorType = 'danger';
                });
            },
            loadPendingApplication() {// load details
                this.getRevalidationApplication().then((res) => {
                    this.documents = res.documents;
                    this.record.update = res.update;
                    this.record.id = res.record ? res.record.id : null;
                    this.isLoadingBlade = false;
                }).
                catch(() => {
                    this.isLoadingBlade = false;
                    this.showErrorStatus = true;
                    this.errorMsg = "Network Failure!", "Kindly reload the page...";
                    this.errorType = 'danger';
                });                
            },
            selectFile(name) {// eslint-disable-next-line
                $('#file').val('');
                this.selectedFileName = null;
                this.showUploadButton = false;// eslint-disable-next-line
                $('#file').click();
                this.fileName = name;
            },
            onFileSelected(event) {
                this.savedEvent = event;
                let status =  this.validateFile(event.target.files[0]);
                if(status) {
                    this.uploadedFile.name = this.fileName;// add file new name
                    this.selectedFileName = this.fileName;
                    this.fileName = null;
                }
                status ? this.showUploadButton = true : false;
                return status;
            },
            onUpload() {// upload document 
                this.uploadedFile.id = this.$store.state.user.userId;// add user id
                this.uploadedFile.app_id = this.isRejected ? this.applicationId : this.record.id;
                this.loadPercent.show = true;
                let event = this.savedEvent;// get store file event
                var fileReader = new FileReader();                
                fileReader.readAsDataURL(event.target.files[0]);
                fileReader.onload = (event) => {// convert file to binary
                    this.uploadedFile.file = event.target.result;
                    this.uploadRevalidationDocuments(this.uploadedFile).then((res) => {
                        this.uploadPercent(this.$store.state.user.uploadProgress);// show load progress
                        var self = this;
                        if(res.status) {                            
                            setTimeout(function() { 
                                self.resetRecords(self);
                            }, 3000);  
                            self.loadApplication();// refresh page                          
                        } else {
                            swal.fire("Error Occurred!", "An error occurred while uploading, try again", "warning");
                            setTimeout(function() { 
                                self.resetRecords(self);
                            }, 3000);
                            self.loadApplication();// refresh page
                        }                        
                    }).
                    catch(err => {
                        swal.fire(err.message+"!", "Reduce file size and try again...", "warning");
                        this.resetRecords(this);
                        this.loadApplication();// refresh page
                    });
                }
            },
            resetRecords(self) {// reset records
                self.loadPercent.show = false;
                self.loadPercent.type = 'warning';
                self.showUploadButton = false;
                self.loadPercent.value = 10;
                self.uploadedFile.name = null;
                self.selectedFileName = null;
                self.removeFile();
            },
            uploadPercent(percent) {// update upload progress percent
                this.loadPercent.value = percent;
                this.loadPercent.type = percent > 60 ? 'success' : 'warning';
            },
            removeFile() {// eslint-disable-next-line
                $('#file').val('');
                this.selectedFileName = null;
                this.uploadedFile.name = null;
                this.uploadedFile.id = null;
                this.uploadedFile.file = null;
                this.showUploadButton = false;
                this.savedEvent = null;
            },
            validateFile(file) {// check for valid file
                if(!file) {// update seleted file name
                    this.uploadedFile.name = null;
                    this.selectedFileName = null;
                }
                var fileExt = file.type.split('/').pop();
                let fileSize = file.size;
                if(fileExt == 'pdf' && fileSize <= 800000) {// for valid file and size
                    return true;
                } else if(fileExt != 'pdf') {// invalid file
                    this.uploadedFile.name = null;
                    this.selectedFileName = null;
                    swal.fire("Invalid File!", "Only PDF files are accepted", "warning");
                    return false;
                } else if(fileSize > 800000) {// big file
                    this.uploadedFile.name = null;
                    this.selectedFileName = null;
                    swal.fire("File is too large!", "File should not be more than 800KB", "warning");
                    return false;
                }
            },
            proceedToPayment() {// save change name
                if(this.isRejected) {
                    return this.showReSendButton = true;
                } else if(this.record.id && ! this.isRejected) {
                    return window.location.href='/application-payment/'+this.record.id;
                } else {
                    let msg = "An error occurred while proceeding to payment, try again";
                    swal.fire("Error Occurred!", msg, "warning");
                }
            },
            editApplication() {
                return this.showReSendButton = false;
            },
            reSendApplication(id) {// re-send rejected application
                swal.fire({
                    title: 'Are you sure?',
                    text: "You must have corrected the issue before proceeding to re-submit application!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, I have corrected it!'
                }).then((result) => {
                    if (result.value) {
                        this.makeReSubmitApiCall(id);// make api call
                    }
                });                
            },
            makeReSubmitApiCall(id) {
                this.preloader(); // show loading 
                this.reSubmitApplication(id).then((res) => {// get application record
                    if(res.status) {
                        swal.fire("Application Submitted!", res.msg, "success");
                        return window.location.href='/applications';
                    } else {
                        swal.fire("Error Occurred!", res.msg, "warning");
                    }
                }).
                catch(() => {
                    let msg = "Network Failure! , kindly reload the page...";
                    swal.fire("Network Failure!", msg, "warning"); 
                });
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b> ...',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        created() {
            this.loadApplication();// load records
        },
    }
</script>

<style scoped>
    .right {
        float: right;
    }
</style>