<template>
    <div>
      <base-header type="gradient-danger" class="pb-6 pb-8 pt-5 pt-md-8">
            <!-- Card stats -->

            <div class="row" v-if="! isLoadingBlade">
                <div class="col-lg-12" v-if="isProvisional">
                  <router-link
                      to="/new-application/full-reg-upgrade"
                      class="text-dark"
                  >
                    <marquee-text :duration="30">
                      For those who have completed their Internship Program,
                      kindly click on this text to apply for Full Registration
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <!-- <em style="padding: 0px 30px;">
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        Note: NMA Building Levy will be required for the approval of your Annual Practicing license
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </em> -->
                    </marquee-text>
                  </router-link>
                  <br>
                </div>
                <div class="col-lg-12 text-dark" v-if="! isProvisional">
                  <marquee-text :duration="30">
                    <!-- <em style="padding: 0px 30px;">
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      Note: NMA Building Levy will be required for the approval of your Annual Practicing license
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </em> -->
                  </marquee-text>
                  <br>
                </div>
                <div class="col-xl-4 col-lg-6"
                    v-if="dashbardInfo && dashbardInfo.profile"
                >
                    <stats-card title="Profile Completeness"
                                :type="'gradient-'+dashbardInfo.profile.color"
                                :sub-title="(dashbardInfo.profile.percent)+'%'"
                                icon="ni ni-circle-08"
                                class="mb-4 mb-xl-0"
                                :class="dashbardInfo.profile.color"
                    >

                        <template slot="footer">
                            <span class="text-success mr-2">
                              <i class="fa fa-arrow-right"></i> Next Action:</span>
                            <span class="text-nowrap">
                              <router-link
                                  :to="dashbardInfo.profile.url ? '/'+dashbardInfo.profile.url
                                    : '/'"
                                  class="text-dark text-capitalize"
                              >
                                {{ replaceString(dashbardInfo.profile.url) }}
                              </router-link>
                            </span>
                        </template>
                    </stats-card>
                </div>
                <div
                    class="col-xl-4 col-lg-6"
                    v-if="dashbardInfo && dashbardInfo.insurance"
                >
                  <router-link
                      to="/insurance-indemnity"
                  >
                      <stats-card title="Insurance Indemnity"
                                  type="'gradient-'+dashbardInfo.insurance.color"
                                  :sub-title="dashbardInfo.insurance.status"
                                  icon="ni ni-credit-card"
                                  class="mb-4 mb-xl-0"
                                  :class="dashbardInfo.insurance.color"
                      >

                          <template slot="footer">
                              <span class="text-success mr-2">
                                <i class="fa fa-arrow-right"></i> Expiry Date:</span>
                              <span class="text-nowrap text-dark">
                                  {{ dashbardInfo.insurance.expiry_date | getDateFormat }}
                              </span>
                          </template>
                      </stats-card>
                  </router-link>

                </div>
                <div
                    class="col-xl-4 col-lg-6"
                    v-if="dashbardInfo && dashbardInfo.license"
                >
                    <router-link to="/my-license">
                      <stats-card title="License Expiry Date"
                                  type="'gradient-'+dashbardInfo.license.color"
                                  :sub-title="dashbardInfo.license.expiry_date | getDateFormat"
                                  icon="ni ni-spaceship"
                                  class="mb-4 mb-xl-0"
                                  :class="dashbardInfo.license.color"
                      >

                          <template slot="footer">
                              <span class="text-success mr-2">
                                <i class="fa fa-arrow-right"></i> Issue Date:</span>
                              <span class="text-nowrap text-dark">
                                {{ dashbardInfo.license.issue_date | getDateFormat }}
                              </span>
                          </template>
                      </stats-card>
                    </router-link>

                </div>
            </div>
        </base-header>

        <!--Charts-->
        <div class="container-fluid mt--7 padding30">
            <div class="row">
                <div class="col-xl-12 order-xl-2 mb-5 mb-xl-0">

                    <div class="card card-profile shadow">

                        <div class="col-lg-12" v-if="isLoadingBlade">
                            <div class="d-flex justify-content-center">
                                <img :src="loadingBladeUrl" />
                            </div>
                        </div>

                        <div v-if="! isLoadingBlade">
                            <div class="row justify-content-center">
                              <div class="col-lg-3 order-lg-2">
                                  <div class="card-profile-image">
                                      <router-link to="/profile">
                                          <img
                                            :alt="fullname"
                                            :src="userPhotoUrl && basicProfile.photo ?
                                                    userPhotoUrl+basicProfile.photo : ''"
                                            class="rounded-circle image"
                                          >
                                      </router-link>
                                  </div>
                              </div>
                          </div>
                          <div class="card-header text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                              <div class="d-flex justify-content-between">
                                  <base-button to="/registration-guidelines" size="sm" type="info" class="mr-4">
                                    <router-link to="/registration-guidelines" class="text-white">
                                      Registration Guidelines
                                    </router-link>
                                  </base-button>
                                  <base-button
                                    size="sm" type="default"
                                    class="float-right"
                                    v-if="basicProfile.get_doctor_type"
                                  >
                                      {{ basicProfile.get_doctor_type.name }}
                                  </base-button>
                              </div>
                          </div>
                          <div class="card-body pt-0 pt-md-4">
                              <div class="row">
                                  <div class="col">
                                      <div class="card-profile-stats d-flex justify-content-center mt-md-5">
                                      </div>
                                  </div>
                              </div>
                              <div class="text-center">
                                  <h2 class="text-capitalize">
                                      {{ fullname }}
                                  </h2>
                                  <h3 class="text-capitalize">
                                      {{ basicProfile.has_sub_registration_type &&
                                        basicProfile.has_sub_registration_type.registration_type &&
                                        basicProfile.has_sub_registration_type.registration_type ?
                                        basicProfile.has_sub_registration_type.registration_type.name : 'N/A' }}
                                      <badge size="sm"
                                        :type="basicProfile.verify_status &&
                                          basicProfile.verify_status == 'approved' ? 'success' : 'warning'"
                                      >
                                        {{ basicProfile.verify_status }}
                                      </badge>
                                  </h3>
                                  <badge size="sm" type="success">
                                    {{ basicProfile.has_sub_registration_type &&
                                        basicProfile.has_sub_registration_type.registration_type ?
                                        basicProfile.has_sub_registration_type.name : 'N/A' }}
                                  </badge>
                                  <div
                                    class="h5 mt-4 fontSize15"
                                    v-if="basicProfile.folio_number"
                                  >
                                      <i class="ni business_briefcase-24 mr-2"></i>
                                        Folio Number
                                        <badge size="sm" type="info">
                                          {{basicProfile.folio_number}}
                                        </badge>
                                  </div>
                                  <div class="h5 mt-4 fontSize15">
                                      <i class="ni business_briefcase-24 mr-2"></i>
                                        Email Address
                                        <badge size="sm" type="success" class="text-lowercase">
                                          {{user.email}}
                                        </badge>
                                  </div>
                                  <div class="h5 mt-4 fontSize15 text-capitalize">
                                      <i class="ni business_briefcase-24 mr-2"></i>
                                        Gender
                                        <badge size="sm" type="info" class="text-capitalize">
                                          {{basicProfile.gender}}
                                        </badge>
                                  </div>
                                  <div
                                    class="h5 mt-4 fontSize15 text-capitalize"
                                    v-if="! isForiegner"
                                  >
                                      <i class="ni business_briefcase-24 mr-2"></i>
                                        LGA of Origin
                                        <badge size="sm" type="success">
                                          {{ basicProfile.has_lga && basicProfile.has_lga.name ?
                                            basicProfile.has_lga.name : ''}}
                                        </badge>
                                  </div>
                                  <div
                                    class="h5 mt-4 fontSize15 text-capitalize"
                                    v-if="! isForiegner"
                                  >
                                      <i class="ni business_briefcase-24 mr-2"></i>
                                        State of Origin
                                        <badge size="sm" type="info">
                                          {{basicProfile.has_state && basicProfile.has_state.name ?
                                            basicProfile.has_state.name : ''}}
                                        </badge>
                                  </div>
                                  <div
                                    class="h5 mt-4 fontSize15 text-capitalize"
                                    v-if="isForiegner && getCountryName(basicProfile.nationality)"
                                  >
                                      <i class="ni business_briefcase-24 mr-2"></i>
                                        Nationality
                                        <badge size="sm" type="success">
                                          {{ getCountryName(basicProfile.nationality) }}
                                        </badge>
                                  </div>
                                  <div
                                    class="h5 mt-4 fontSize15 text-capitalize"
                                    v-if="isForiegner"
                                  >
                                      <i class="ni business_briefcase-24 mr-2"></i>
                                        State / Province
                                        <badge size="sm" type="info">
                                          {{ basicProfile.foreign_state }}
                                        </badge>
                                  </div>
                              </div>
                          </div>

                          <!-- full reg upgrade modal template here -->
                          <div class="col-md-4" :show="modal && ! lock && ! GenerateModal && ! otherModel && ! inductionModal">
                            <modal :show="modal && ! lock && ! GenerateModal && ! otherModel && ! inductionModal"
                                  gradient="danger"
                                  modal-classes="modal-danger modal-dialog-centered">
                                <h4
                                  slot="header" class="modal-title"
                                  id="modal-title-notification"
                                >
                                  Importance Notice
                                </h4>

                                <div class="py-3 text-center">
                                    <i class="ni ni-bell-55 ni-3x"></i>
                                    <h4 class="heading mt-4">Profile Enabled!</h4>
                                    <p>
                                      Note that your profile has been enabled for editing,
                                      kindly make necessary corrections has your profile will
                                      be automatically locked down after 23:00 hours today
                                    </p>
                                </div>

                                <template slot="footer">
                                    <base-button type="link"
                                                text-color="white"
                                                class="ml-auto"
                                                @click="modal = false">
                                        Close
                                    </base-button>
                                </template>
                            </modal>
                        </div>

                        <!-- Generate RRR template here -->
                          <div class="col-md-4" :show="GenerateModal">
                            <modal :show="GenerateModal"
                                  gradient="danger"
                                  modal-classes="modal-danger modal-dialog-centered">
                                <h4
                                  slot="header" class="modal-title"
                                  id="modal-title-notification"
                                >
                                  Importance Notice
                                </h4>

                                <div class="py-3 text-center">
                                    <i class="ni ni-bell-55 ni-3x"></i>
                                    <h4 class="heading mt-4">Generate RRR Available!</h4>
                                    <p>
                                      <strong>Note:</strong> RRR is now generated from the portal at point of payment (by
                                      clicking on Pay Via RRR button) and it is now the  only valid means for generating RRR
                                      for MDCN portal.
                                    </p>
                                </div>

                                <template slot="footer">
                                    <!-- <base-button
                                      type="link"
                                      text-color="white"
                                      @click="goToLicense()"
                                    >
                                        Add Now
                                    </base-button> -->

                                    <base-button type="link"
                                                text-color="white"
                                                class="ml-auto"
                                                @click="GenerateModal = false">
                                        Close
                                    </base-button>
                                </template>
                            </modal>
                        </div>

                        <!-- Other Notice template here -->
                          <div class="col-md-4" :show="! GenerateModal && otherModel">
                            <modal :show="! GenerateModal && otherModel"
                                  gradient="danger"
                                  modal-classes="modal-danger modal-dialog-centered">
                                <h4
                                  slot="header" class="modal-title"
                                  id="modal-title-notification"
                                >
                                  Importance Notice
                                </h4>

                                <div class="py-3 text-center">
                                    <i class="ni ni-bell-55 ni-3x"></i>
                                    <h4 class="heading mt-4">Erroneous Selection!</h4>
                                    <p>
                                      <strong>Note:</strong> Erroneous selection of service description / type
                                      (refunds) on Remita platform by doctors during processing payment will
                                      no longer be refunded, thank you.
                                    </p>
                                </div>

                                <template slot="footer">
                                    <base-button type="link"
                                                text-color="white"
                                                class="ml-auto"
                                                @click="otherModel = false">
                                        Close
                                    </base-button>
                                </template>
                            </modal>
                          </div>


                          <!-- Induction ticket modal template here -->
                          <div class="col-md-4" :show="! GenerateModal && ! otherModel && inductionModal">
                            <modal :show="! GenerateModal && ! otherModel && inductionModal"
                                  gradient="danger"
                                  modal-classes="modal-danger modal-dialog-centered">
                                <h4
                                  slot="header" class="modal-title"
                                  id="modal-title-notification"
                                >
                                  Importance Notice
                                </h4>

                                <div class="py-3 text-center">
                                    <i class="ni ni-bell-55 ni-3x"></i>
                                    <h4 class="heading mt-4">Your Induction Ticket(s) are ready!</h4>
                                    <p>
                                      <strong>Note:</strong> Kindly click on the button below to print your Induction 
                                      Ticket(s) for you and your invitee(s).
                                    </p>
                                </div>

                                <template slot="footer">
                                    <router-link
                                      to="/induction-tickets"
                                      class="btn btn-white text-dark"
                                    >
                                      Print Tickets
                                    </router-link>

                                    <base-button type="link"
                                                text-color="white"
                                                class="ml-auto"
                                                @click="inductionModal = false">
                                        Close
                                    </base-button>
                                </template>
                            </modal>
                          </div>

                      </div>

                    </div>
                </div>
            </div>
            <!-- End charts-->
        </div>
    </div>
</template>
<script>
  // get user's details from store
  import { mapGetters, mapActions } from 'vuex';
  import moment from 'moment';
  import swal from 'sweetalert2';// import sweet alert
  import MarqueeText from 'vue-marquee-text-component'


  export default {
    name: 'Dashboard',
    components: {
      MarqueeText
    },
    filters: {
        getDateFormat(value) {
            if(value) {
              return moment(value).format('Do MMM, YYYY');
            } else {
              return 'N/A';
            }
        }
    },
    computed: {
      ...mapGetters(['basicProfile', 'userPhotoUrl', 'user', 'dashbardInfo', 'loadingBladeUrl', 'lock', 'locations']),
      isProvisional() {
        return (this.basicProfile.has_sub_registration_type &&
            ((this.basicProfile.has_sub_registration_type.id == 1) ||
            (this.basicProfile.has_sub_registration_type.id == 2)) );
      },
      lockHours() {
        let lockTime = localStorage.getItem('lock_time');// get lock time
        let currentTime = new Date().getTime();
        let hours = Math.ceil(Math.abs(lockTime - currentTime) / 3600000);
        return hours > 1 ? hours+' hours' : 'an hour';
      },
      isForiegner() {// check for foriegner
        return this.basicProfile && this.basicProfile.nationality != 164;
      },
      ticketing() {// induction ticketing
        return this.basicProfile ? this.basicProfile.ticketing : {};
      },
      fullname() {
        let profile = this.basicProfile;
        let name = profile.first_name + ' ';
        name += profile.last_name;
        return name.toLowerCase();
      }
    },
    data() {
      return {
        modal: false,
        profileInfo: [],
        isLoadingBlade: true,
        GenerateModal: true,
        otherModel: true,
        inductionModal: false
      };
    },
    methods: {
      ...mapActions(['getUpdateProfileInfo','fetchUserProfile', 'disableProfile']),
      checkStatus() {// check for user account status
        this.fetchUserProfile().then((res) => {
            this.isLoadingBlade = false;

            // let tempDoc = res.basic_profile && res.basic_profile.has_sub_registration_type && // check for temporary Doc
            //               res.basic_profile.has_sub_registration_type &&
            //               res.basic_profile.has_sub_registration_type.registration_type &&
            //               res.basic_profile.has_sub_registration_type.registration_type.code == "temporary-reg";
            
            // if(res.new_account == 'yes' && tempDoc) {// redirect to new temp account
            //   this.$router.push({ name: 'New Temporary Account' });
            // } else if(res.new_account == 'yes') {// redirect to new account
            //     this.$router.push({ name: 'New Account' });
            // } else {// check for profile incompleteness
            //   this.hasEmptyData();
            // }
            // show induction modal
            let ticket = this.basicProfile ? this.basicProfile.ticketing : false;
            this.inductionModal = ticket && ticket.status ? ticket.status : false;
        });
      },
      hasEmptyData() {// get profile incompleteness 
        this.getUpdateProfileInfo().then((res) => {
            if(res.status) {
              this.profileInfo = res;
            } else {
              this.$router.push({ name: 'Update Profile' });
            }
        });
      },
      replaceString(value) {
          if(value) {
            return value.replace("-", " ");
          } else {
            return 'N/A';
          }
      },
      showSessionMsg() {
          if(this.$route.query.status && this.$route.query.status == 1) {
              swal.fire("Payment Successful!", "Payment was made successfully", "success");
          } else if(this.$route.query.status && this.$route.query.status == 0) {
              swal.fire("Payment Failed!", "Payment failed, try again", "warning");
          }
      },
      showModalMessage() {// show profile lock modal
          var self = this;
          ! self.lock ? setTimeout(function() { self.modal = true; }, 2000) : false;
          // let lockTime = localStorage.getItem('lock_time');// get lock time
          // let timeDiff = lockTime ? lockTime - new Date().getTime() : 3000;// get curent time difference
          // let time = timeDiff > 0 ? timeDiff : 3000;// allocate lock time
          // if(lockTime && ! self.lock) {
          //   setTimeout(function() { self.lockUserProfile(); }, time);
          // }
      },
      lockUserProfile() {// lock user profile
        this.disableProfile().then((res) => {
            if(res.status) {
              localStorage.removeItem('lock_time');// remove lock time
            }
        });
      },
      getCountryName(id) {
        if(this.locations && this.locations.countries && this.locations.countries.length > 0) {
          for(let country of this.locations.countries) {
            if(country.id == id) {
              return country.name;
            }
          }
        } else {
          return false;
        }
      },
      goToLicense() {// go to license page
        return window.location.href = '/my-license';
      }
    },
    mounted() {
      // this.fetchUserProfile();// get user profile
      this.checkStatus();// check for new registration / empty profile data
      this.showSessionMsg();// show session msg
      this.showModalMessage(); // show modal message
    }
  };
</script>
<style scoped>
  .padding30 {
    padding: 30px;
  }
  .fontSize15 {
    font-size: 15px;
  }
  .red {
    background-color: #efd2d2;
  }
  .green {
    background-color: #bcf9c6;
  }
  .yellow {
    background-color: #e8eb4b;
  }
  .left {
    float: left;
  }
  .image {
    width: 170px;
    height: 170px;
  }
</style>

<style>
  .modal-footer {
    margin-top: -50px !important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding: 1.25rem;
    border-top: 0 solid #e9ecef;
    border-bottom-right-radius: 0.4375rem;
    border-bottom-left-radius: 0.4375rem;
  }
</style>
